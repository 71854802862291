import React, { useMemo } from 'react';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { NavMenu } from './NavMenu';
import { Gray } from 'metabux/theme/colors';
import { DefaultNavItemRenderer } from './DefaultNavItemRenderer';
import { useLocation } from 'react-router-dom';
import Link from "metabux/components/Link/Link";

export const NavItem = ({
	...props
}) => {

  const location = useLocation();

	const isActive = useMemo(() => {
    return props.href === location.pathname;
  }, [location, props.href]);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
	if (props.willRender === false) return null;
	if (props.children) return <NavMenu {...props} />
	return (
		<Link {...props} value={props.href} className={`${(props.active || isActive) && "active"}`} sx={{
			color: Gray[400],
			'&:hover': {
				color: 'white',
			},
			'&.active': {
				color: 'white',
				background: Gray[800],
			},
			padding: 2,
			borderRadius: 2,
			height: 48,
			justifyContent: isDesktop ? "auto" : "center",

		}}>
			<DefaultNavItemRenderer {...props} />
		</Link>
	)
}