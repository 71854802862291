import React from 'react';
import { LoginContainer } from '../../components/index.jsx';
import './Login.scss';

export const Login = (props) => {
	return (
		<div className="loginContainer" data-testid="loginView">
			<LoginContainer />
		</div>
	);
};

export default Login;
