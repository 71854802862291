import React, { useEffect } from "react";
import DiscoveryPanels from "../../discovery-panel/DiscoveryPanel";
import { Connector } from "../../discovery-panel/connector";
import { usePendo } from "../../hooks/usePendo";
import { useSelector } from "react-redux";
import { dataSelector } from "../../discovery-panel/redux/selectors/data.selector";

const DiscoveryPanelView = ({ match }) => {
	const {
		productType,
		projectId,
		sampleSetId
	} = match.params;

	const { sample } = useSelector(dataSelector);
	const pendo = usePendo();

	useEffect(() => {
		pendo.identify(sample.projectCode);
		return () => {
			pendo.identify();
		};
	}, [sample.projectCode]);

  return (
    <Connector productType={productType} projectId={projectId} sampleSetId={sampleSetId}>
      <DiscoveryPanels match={match} />
    </Connector>
  );
};

export default DiscoveryPanelView;
