import * as config from '../config/env.config';
import urljoin from "url-join";
import fileDownload from 'js-file-download';
import 'whatwg-fetch';

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";

export const getHeaders = () => {
  return {
    'Authorization': 'Bearer' + ' ' + getToken(), 
    'Accept': 'application/json',
    'X-Content-Type-Options': 'nosniff',
    'X-XSS-Protection': 1
  };
};

export const getToken = (key = "id_token") => localStorage.getItem(key) || null;

const getRequestConfig = (method, requestPayload, isJson = true) => {
  const config = {
    method,
    headers: getHeaders(),
  };
  if (requestPayload) {
    if (isJson) {
      config.body = JSON.stringify(requestPayload);
      config.headers['Content-Type'] = 'application/json';
    }
    else config.body = requestPayload;
  }
  return config;
};

export const get = async (endpoint, stringResponse=false) => {
  try {
    const response = await fetch(endpoint, getRequestConfig(GET, null));
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }    
    response.error = false;
    response.payload = stringResponse ? await response.text() : await response.json();
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const post = async (endpoint, requestPayload, isJson) => {  
  try {
    const response = await fetch(endpoint, getRequestConfig(POST, requestPayload, isJson));
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }    
    response.error = false;
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const put = async (endpoint, requestPayload, isJson) => {
  try {
    const response = await fetch(endpoint, getRequestConfig(PUT, requestPayload, isJson));
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }
    response.error = false;
    return response;

  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const getBlob = async (endpoint, fileName, method="get", payload=null) => {
  const token = getToken();
  const config = {
    method,
    headers: {
      'Authorization': 'Bearer' + ' ' + token,
      'X-Content-Type-Options': 'nosniff',
      'X-XSS-Protection': 1,
    },
  };
  if(payload){
    config.headers['Content-Type'] = 'application/json';
    config.body = JSON.stringify(payload);
  }
  try {
    const response = await fetch(endpoint, config);
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }
    const blob = await response.blob(response);
    if (blob.size > 0 && fileName) {
      fileDownload(blob, `${fileName}`);
    }
    response.error = false;
    response.payload = blob;
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const getBlobByIds = async (endpoint, fileName, fileIds) => {
  const token = getToken();
  const config = {
    method: 'post',
    headers: {
      'Authorization': 'Bearer' + ' ' + token, 
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-XSS-Protection': 1
    },
    body: JSON.stringify({ fileIds }),
  };
  try {
    const response = await fetch(endpoint, config);
    if (response && !response.ok) {
      response.error = true;
      return response;
    }
    
    const blob = await response.blob();
    if (blob.size > 0 && fileIds.length > 0 && fileName) {
      fileDownload(blob, `${fileName}`);
      response.error = false;
      response.payload = blob;
      return response;
    }
    response.error = "No Files Downloaded";
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const fetchCurrentUser = async (query = null) => {
  let endpoint = config.API_ENDPOINT_USERLOGIN;
  if (query) endpoint = urljoin(endpoint, query);

  return await get(endpoint);
};

export const callApi = async (endpoint, authenticated, method = 'GET', payload = null) => {
  // id_audit is the token used to talk to the identity service
  // this is used to get more information for example from the userinfo endpoint
  let token = localStorage.getItem('id_audit') || null;

  // id_token is the JWT which represents the tamper resistent
  // package to represent the authentication that has happened.
  let idToken = localStorage.getItem('id_token') || null;
  let config = {};
  
  if(authenticated) {
    if(token && idToken) {
      config = {
        headers: { 'Authorization': `Bearer ${idToken}`, 'X-Content-Type-Options': 'nosniff', 'X-XSS-Protection': 1   },
        method: method
      };

      if(method == 'POST' || method == 'PUT' && payload){
        config = {
          headers: {  'Authorization': 'Bearer' + ' ' + idToken,
          'Content-Type': 'application/json',  },
          method: method,
          body: JSON.stringify(payload)
        };
      }
    }
    else {
      throw "No token saved!";
    }
  }

  const response = await fetch(endpoint, config);

  try {
    if (response && !response.ok) {
      const error = await response.json();
      return Promise.reject(error);
    }
    return await response.json();
  }
  catch(err) {
    return Promise.reject(err.message);
  }
};
