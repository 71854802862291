import React from "react";
import { useSelector } from "react-redux";

import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import getDocStyle from "../../utils/getDocStyle";

const Defs = () => {
  const { mobile } = useSelector(viewPortSelector);
  const arrowSize = mobile ? 10 : 17;
  return (
    <defs>
      <marker
        id="arrowHeadLeft"
        viewBox="-10 -10 20 20"
        markerWidth={arrowSize}
        markerHeight={arrowSize}
        orient="auto"
      >
        <path d="M-6,-6 L 0,0 L -6,6" fill={getDocStyle("metabolon-base")} />
      </marker>
      <marker
        id="arrowHeadRight"
        viewBox="-10 -10 20 20"
        markerWidth={arrowSize}
        markerHeight={arrowSize}
        orient="auto-start-reverse"
      >
        <path d="M-6,-6 L 0,0 L -6,6" fill={getDocStyle("metabolon-base")} />
      </marker>
    </defs>
  );
};
export default Defs;
