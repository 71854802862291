export const initializeDownload = () => ({
    curvedText: true,
    isDownloading: false,
});


export const downloadReducer = () => ({
    downloadStraightenText: (state) => {
        state.download.curvedText = false;
        state.download.isDownloading = true;
    },
    downloadCurveText: (state) => {
        state.download.curvedText = true;
        state.download.isDownloading = false;
    },
    downloadSetCurvedText: (state, action) => {
        state.download.curvedText = !!action.payload;
    }
});
