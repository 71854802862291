import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "../../../components/controls/Select.jsx";
import { MultiSelect } from "../../../components/controls/MultiSelect.jsx";
import { interactionsUpdateSelection } from "../../redux/reducers/discoveryPanels.reducer";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger.js";

export const SPSelect = ({
  action,
  data,
  id,
  maxComparisonCount,
  multiComparisons,
  name,
  resetSelectionOnSelect,
  show,
  multiSelect,
  use,
  value,
  ...props
}) => {
  const dispatch = useDispatch();
  const logger = useLocalAuditLogger();

  const [selection, setSelection] = useState();

  useEffect(() => {
    setSelection(data.filter((d) => d[use] === value)[0]);
  }, [value]);

  const onSelect = useCallback(
    (option) => {
     dispatch(action(option[use]));
      setSelection(option);
      logger.logUserAction(`user selected ${props.label} ${option[use]}`);
      if (resetSelectionOnSelect) {
        dispatch(interactionsUpdateSelection({
          id: "default:override",
          source: "select"
        }));
      }
    },
    [action, use, resetSelectionOnSelect, props.label]
  );

  const onMultiSelect = useCallback(
    (selections) => {
      dispatch(action(selections));
      logger.logUserAction(`user selected ${selections.length} ${props.label}`);
    },
    [action, use, props.label]
  );

  if (!multiSelect) {
    return (
      <div className="select-wrapper" style={{ position: "relative" }}>
        <p className="input-label">{props.label}</p>
        <Select
          id={id}
          activeClass={props.activeClass}
          className={props.className}
          title={name}
          color="default"
          alt={true}
          selection={selection}
          options={data}
          optionClass="alt2"
          optionColor="default"
          displayKey={show}
          onSelect={onSelect}
        />
      </div>
    );
  } else {
    return (
      <div className="select-wrapper">
        <p className="input-label">{props.label}</p>
        <MultiSelect
          id={id}
          activeClass={props.activeClass}
          className={props.className}
          title={name}
          color="default"
          alt={true}
          options={data}
          optionClass="alt2"
          optionColor="default"
          displayKey={show}
          maxSelections={maxComparisonCount}
          valueKey={use}
          selections={multiComparisons}
          onItemToggle={onMultiSelect}
        />
      </div>
    );
  }
};
