import getDocStyle from "./getDocStyle";
import { scaleLinear } from "d3-scale";

const metabolonBlue = getDocStyle("metabolon-blue");
const metabolonRed = getDocStyle("metabolon-red");
const coldLightGray = getDocStyle("cold-gray-light");

export default function colorScale(val) {
  const colorScale = scaleLinear()
    .domain([0, 0.9999, 1, 3])
    .range([metabolonBlue, coldLightGray, coldLightGray, metabolonRed]);
  return colorScale(val);
}
