'use strict';
import React, { useEffect, useState } from 'react';
import { ProjectSearch } from '@/containers/ProjectSearch.jsx';
import { CollapsibleList, Link, Material } from '../../../components/index.jsx';
import { ProjectItem } from './ProjectItem.jsx';
import './MyProjects.scss';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import { Logger, Log } from '../../../utils/Logger';
import { AppInsightLogLevel } from '../../../enums/Enums';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '@/redux/reducers/sampleSetData.reducer.js';
import { projectsDiscoveryPanelsSelector } from '@/redux/selectors/projects.selector.js';
import { useProjectsQuery } from '@/redux/services/projects.api.js';

const logger = new Logger();

export const getProjectItems = (projectList, discoveryPanels, mapData) => {

	if (mapData) {
		projectList = projectList.map((data) => {
			Object.keys(mapData).forEach((key) => {
				data[key] = data[mapData[key]];
			});
			return data;
		});
	}

	//Alphabetically sort projects
	let sortedProjects = projectList.sort(function (a, b) {
		try {
			let nameA = a.projectCode.toLowerCase(), nameB = b.projectCode.toLowerCase();
			if (nameA < nameB) { return -1; }
			if (nameA > nameB) { return 1; }
			return 0;
		}
		catch (err) {
			let nullProjectId = a.projectCode == null ? a.projectId : b.projectId;
			throw new Error(
				`Missing ProjectCode for projectId: ${nullProjectId}, Exception: ${err}`
			);
		}
	});

	const projects = [];
	sortedProjects.forEach(project => {
		const isDiscoveryPanel = discoveryPanels ? project.projectId in discoveryPanels : false;
		if (isDiscoveryPanel) {
			projects.push(<ProjectItem key={project.projectId + "SP"} project={project} isDiscoveryPanel={isDiscoveryPanel} />);
		} else {
			projects.push(<ProjectItem key={project.projectId} project={project} />);
		}
	});

	return projects;
};

export const MyProjects = (props) => {
	const { data: projects, isLoading } = useProjectsQuery();
	const discoveryPanels = useSelector(projectsDiscoveryPanelsSelector);
	const [salesEmailAddress] = useState("sales@metabolon.com");
	const [salesHelpSubject] = useState("Metabolync Portal Demo");
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (window.location.pathname.indexOf("errortest") != -1) {
			throw new TypeError("ErrorTest");
		}
		dispatch(reset());
	}, [dispatch]);

	const loadingStyle = {
		container: {
			position: 'relative',
			textAlign: 'center',
			paddingTop: '10px'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
		},
	};

	const RefreshIndicatorExampleLoading = () => (
		<div style={loadingStyle.container}>
			<LoadingSpinner
				size={40}
				left={10}
				top={0}
				status="loading"
				style={loadingStyle.refresh}
			/>
		</div>
	);

	const logEvent = (property, value) => {
		//Log Issue
		let log = new Log();
		log.SetLevel(AppInsightLogLevel.ERROR);
		log.SetName(props.viewName);
		log.AddProperty(property, value);
		logger.doLog(log);
	};

	const renderProjects = () => {
		const projectItems = getProjectItems([...projects], discoveryPanels);

		return (
			<CollapsibleList className="my-projects-list" itemsToShow={5} incrementAmount={5} incrementShow={true}>
				{projectItems}
			</CollapsibleList>
		);
	};

	let emptyState = "";
	if (!isLoading && projects.length == 0) {
		emptyState = (
			<div className="emptyProjectsContainer">
				<div className="emptyProjects">
					<div>No projects yet.</div>
					<div>If you'd like a quote for our services, more information on sample types and handling procedures, need a letter of support, or simply have questions about how metabolomics can advance your research, please contact us today.</div>
					<Link history={history} size="normal" className="login" alt={true} color="primary">
						<a href={`mailto:${salesEmailAddress}?subject=${salesHelpSubject}`}>Contact Sales  <Material icon={'person'} color="primary" /> </a>
					</Link>
				</div>
				<i className="carton" />
			</div>
		);
	}

	return (
		<div data-testid="my-projects" className="outerContainer">
			<div className="listHeader">My Project List</div>
			<div className="innerContainer">
				<div className="header">
					<ProjectSearch id="my-projects-search" SearchDelay={true} dataModel={'projects'} iconColor={'tertiary'} hintText={'Jump to...'} showResults={true} />
				</div>
				<hr />
				<div>
					{isLoading ? RefreshIndicatorExampleLoading() : renderProjects()}
					{emptyState}
				</div>
			</div>
		</div>
	);
};

export default MyProjects;
