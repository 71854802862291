import React from "react";
import { Icon } from "../../../components/index.jsx";

export default function Toggle({
  label,
  className = "",
  onClick,
  size = "large",
  value,
}) {
  return (
    <span className={`ToggleVisibility ${className}`} onClick={onClick}>
      <span className="input-label">{label}</span>
      <div>
        <Icon
          size={size}
          color={value ? "" : "secondary"}
          icon={value ? "toggle-on" : "toggle-off"}
        />
      </div>
    </span>
  );
}
