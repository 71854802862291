import React from "react";
import getDocStyle from "../../utils/getDocStyle";
import colorScale from "../../utils/colorScale";
import * as styles from "./legend.module.scss";

const legendData = [0, 0.33, 0.66, 1, 1.33, 1.66, 3];
const showValue = [0, 1, 3];

const Legend = (props) => {
  const horizontal = props.stack === "horizontal";

  return (
    <div
      className={styles.legend}
      style={{
        position: props.position,
        margin: props.margin,
        display: horizontal ? "inline-flex" : "inline-block",
        flexDirection: horizontal ? "row-reverse" : "none",
        alignItems: horizontal ? "center" : "none",
      }}
    >
      {props.size && (
        <div
          className={styles.sizeWrapper}
          style={{
            width: props.colorWidth,
            marginBottom: horizontal ? 0 : "1rem",
          }}
        >
          <div className={styles.circleLg} />
          <div className={styles.circleMd} />
          <div className={styles.circleSm} />
          <div className={styles.indicator} />
          <div className={styles.label}>Anova main effect</div>
          <div className={styles.pValueSm} />
          <div className={styles.pValueSmLabel}> P-Value &#60;.001 </div>
          <div className={styles.pValueLg} />
          <div className={styles.pValueLgLabel}> P-Value &#62;.05</div>
          <p className={styles.caption}> Sized by significance</p>
        </div>
      )}

      {props.color && (
        <div style={{ width: props.colorWidth, margin: props.colorMargin }}>
          <div className={styles.colorWrapper}>
            {legendData.map((d, i) => {
              return (
                <div key={i}>
                  <div
                    style={{
                      backgroundColor: colorScale(d),
                    }}
                  />
                  {showValue.includes(d) && (
                    <p
                      className={styles.value}
                      style={{ color: getDocStyle("var(--metabolon-base)") }}
                    >
                      {" "}
                      {d}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
          <p
            className={styles.caption}
            style={{ color: getDocStyle("var(--metabolon-base)") }}
          >
            Colored by fold change <br />
            when below p-value threshold
          </p>
        </div>
      )}
    </div>
  );
};

export default Legend;
