'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Checkbox } from '../../../../components/index.jsx';
import SettingsMap from '../../../../utils/SettingsMap.js';
import { Logger, Log } from '../../../../utils/Logger';
import {AppInsightLogLevel} from '../../../../enums/Enums';
import { Auditor } from '@/components/util/Auditor.jsx';

const logger = new Logger();

export class Layers extends Auditor {

	constructor(props) {
		super(props);
		this.state = {
			...this.initState(),
			...super.state // pull in state from auditor to get the logger
		};

		this.onLayerUpdate = this.onLayerUpdate.bind(this);
	}

	componentDidMount() {
		SettingsMap.on(SettingsMap.LAYER_UPDATE, this.onLayerUpdate);
	}

	componentDidUpdate(prevProps, prevState) {
		/*if(this.props !== prevProps) {
			this.updateData(this.initState());
		}*/

		if(this.props.settingsKey !== prevProps.settingsKey) {
			this.updateData(this.initState(), () => {
				this.updateData(this.initState());
			});
		}
	}

	componentWillUnmount() {
		SettingsMap.off(SettingsMap.LAYER_UPDATE, this.onLayerUpdate);
	}

	onLayerUpdate() {
		this.updateData(this.initState());
	}

	updateData(data) {
		this.setState({...data});
	}

	initState() {
		const {multiOverlay, foldChangeScale, detailedView,
			hideCommonBiologics, hideFinalPathways, hideIntermediates,
			hideMinorMetabolites, hideCofactors, hideNonCoreElements} = SettingsMap.layers;

		let lastChange = dayjs().valueOf();
		//console.log(hideCofactors, lastChange);

		return {lastChange, multiOverlay, foldChangeScale, detailedView,
			hideCommonBiologics, hideFinalPathways, hideIntermediates,
			hideMinorMetabolites, hideCofactors, hideNonCoreElements};
	}

	generateClassNames() {
		const {className}  = this.props;
		let classNames = [this.constructor.name];
		if(className) classNames.push(className);
		return classNames;
	}

	generateClassString() {
		return this.generateClassNames().join(' ');
	}

	logEvent(data){
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		//logFilterChange.SetName(parent);
		//logFilterChange.AddProperty(property,value);
		logger.doLog(logFilterChange);
	}

	onChange = (e) => {
		const {name, isChecked} = e.target;
		const {onChange} = this.props;
		let data = {[name]:isChecked};
		if(onChange) onChange(data);
		
		SettingsMap.updateLayers(data);
		this.state.logger.logUserAction(`${name} layer changed`);
	};

	render() {
		let {multiOverlay, foldChangeScale, detailedView,
			hideCommonBiologics, hideFinalPathways, hideIntermediates,
			hideMinorMetabolites, hideCofactors, hideNonCoreElements, lastChange} = this.state;

		let className = this.generateClassString();
		return (
			<div className={className}>
				{super.render()/*call super.render() to add the auditor*/}
				<h3>Data Visibility</h3>
				<div>
					<p/>
					{/*<Checkbox name="multiOverlay" onChange={this.onChange} checked={multiOverlay} label="Multi-test data overlay" />*/}
					<Checkbox name="foldChangeScale" onChange={this.onChange} checked={foldChangeScale} label="Correlate node size to fold change" />

					<Checkbox name="hideFinalPathways" onChange={this.onChange} checked={hideFinalPathways} label="Hide final pathways" />

					<Checkbox name="hideCofactors" onChange={this.onChange} checked={hideCofactors} override={{checked:hideCofactors, lastChange}} label="Hide cofactors" />

					<Checkbox name="hideIntermediates" onChange={this.onChange} checked={hideIntermediates} label="Hide intermediates" />

					<Checkbox name="hideMinorMetabolites" onChange={this.onChange} checked={hideMinorMetabolites} label="Hide minor metabolites" />

					{/*<Checkbox name="detailedView" onChange={this.onChange} checked={detailedView} label="Detailed pathway view" />
					<Checkbox name="hideCommonBio" onChange={this.onChange} checked={hideCommonBio} label="Hide common biochemicals"/>
					<Checkbox name="hideNoncore" onChange={this.onChange} checked={hideNoncore} label="Hide non-core elements" />*/}
				</div>
			</div>
		);
	}
}
export default Layers;