import {
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import SubDropdownItem from "./SubDropdownItem";
import { Icon } from "metabux/components/Icon/Icon";
import { Gray } from "metabux/theme/colors";
import { ChevronDown, ChevronUp } from "lucide-react";

export const Dropdown = ({
  items,
  className,
  onChange,
  onClick,
  testId = "dropdown",
  open: opened = false,
  defaultValue = "",
  label = "Dropdown",
  size = "medium",
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedValue(defaultValue);
    setOpen(opened);
  }, [defaultValue, opened]);

  const handleChange = useCallback((e) => {
		const value = e.target.value;
    setSelectedValue(value);
    setOpen(false);
    if (onChange) onChange(value);
  }, [onChange]);

  const handleClick = useCallback((e) => {
    setOpen((prev) => !prev);
    if (onClick) onClick(e);
  }, [onClick])

  const renderValue = useCallback((value) => {
		return (
		<Typography
			sx={{ color: Gray[800] }}
			component='span'
		>
			{selectedValue === "" ? label : (value)}
		</Typography>
	)}, [selectedValue]);

  return (
    <Select
      value={selectedValue}
      displayEmpty
      open={open}
      data-testid={testId}
      className={`MuiSelect-dropdown-${size} MuiSelect-dropdown`}
      MenuProps={{
        PaperProps: {
          className: `MuiSelect-dropdown-paper MuiSelect-dropdown-paper-${size}`,
        },
      }}
      IconComponent={() => (
        <Icon
          icon={open ? ChevronUp : ChevronDown}
          sx={{ cursor: "pointer" }}
        />
      )}
      classes={{
        select: classNames(
          className,
          `MuiSelect-size${size.substring(0, 1).toUpperCase()}${size.substring(1)}`
        ),
      }}
      onChange={handleChange}
      onClick={handleClick}
      renderValue={renderValue}
      {...props}
    >
      {items.map((item) =>
        item.children ? (
          <SubDropdownItem
            key={item.key}
            handleClick={handleChange}
            item={item}
          />
        ) : (
          <MenuItem key={item.key} value={item.value}>
            {item.value}
          </MenuItem>
        )
      )}
    </Select>
  );
};
