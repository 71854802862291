'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import {Link} from './Link.jsx';
import './NavButton.scss';

/** A button component for a NavBar container */
export class NavButton extends Link {
	constructor(props) {
		super(props);
	}

	generateClassNames(base) {
		const {text} = this.props;
		let classNames = super.generateClassNames(base);
		if(text) classNames.push('text-'+text);
		if(this.props.active) classNames.push('active altTextColor');

		return classNames;
	}

	render() {
		const {children, type, to, exact, staticContext, alt, alt2, active, testId, ...props} = this.props;
		if(!to) return super.render();

		let className = this.generateClassNames(this.state.baseClass).join(' ');
		return (
			<NavLink data-testid={testId} to={to} exact={exact} className={className} type={type} onClick={this.onClick} {...props}>
				{children}
			</NavLink>
		);
	}
}

NavButton.propTypes = Object.assign({}, Link.propTypes, {
	/** The color of the button text */
	text: PropTypes.oneOf(['', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),
	
});
NavButton.defaultProps = Object.assign({}, Link.defaultProps, {
	text:'',
	 
	onClick:/* istanbul ignore next */ () => { console.log('NavButton custom onClick callback not defined!'); },
	 
});

export default withRouter(NavButton);