'use strict';
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";
import React, { useEffect } from "react";

export const AuditLoggerWrapper = ({callback}) => {
  const auditLogger = useLocalAuditLogger();

  useEffect(() => {
    if (auditLogger && callback)
      callback(auditLogger);

  }, [callback, auditLogger]);

  return <></>;
};