export const AppInsightLogLevel = {
  EVENT: "Event",
  ERROR: "Error",
};

export const SessionEvent = {
  LOGIN: "Login",
  LOGOUT: "Logout",
};

export const IDLESTATUS_AWAY = "AWAY";
export const IDLESTATUS_INACTIVE = "INACTIVE";
export const IDLESTATUS_EXPIRED = "EXPIRED";

export const IDLE_STATUSES = [
  IDLESTATUS_AWAY,
  IDLESTATUS_INACTIVE,
  IDLESTATUS_EXPIRED,
];

export const AUDITLOG_LOGTYPES = {
  USER_CREATE: "usercreate",
  USER_ACTION: "useraction",
  PROJECT_CREATE: "projectcreate",
  PROJECT_VIEW: "projectview",
  PROJECT_DOWNLOAD: "projectdownload",
  PROJECT_PERMISSIONS: "projectpermissions",
};
export const AUDITLOG_USERACTION_TYPES = {
  LOGIN: "login",
  LOGOUT: "logout",
  RESET_PASSWORD: "resetpassword",
  SESSION_EXPIRED: "sessionexpired",
};
export const AUDITLOG_PROJECTACTIONS_TYPES = {
  VIEW: "view",
  DOWNLOAD: "download",
  SAVE: "save",
  PUBLISH: "publish",
  ADD_CONTACT: "addcontact",
  REMOVE_CONTACT: "remnovecontact",
  SAVE_TITLE: "savetitle",
  SAVE_SYNOPSIS: "savesynopsis",
  SAVE_CONCLUSION: "saveconclusion"
};
export const AUDTILOG_PERMISSION_STATUS = { GRANT: "grant", REVOKE: "revoke" };

export const ROLES = {
  ADMIN: "Admin",
  BASIC: "Basic",
  DTS: "DTS",
  COE: "Client Onboarding Experience",
  METAIMD: "MetaIMD",
};

export const BIOSTRESS = "Biologicalstress";
export const MICROBIOME = "Microbiome";
export const LIVERFIBROSIS = "LiverFibrosis";
export const KIDNEYFUNCTION = "KidneyFunction";

export const DiscoveryPanelMap = {
	api: {
		[BIOSTRESS]: `DiscoveryPanel_${BIOSTRESS}`,
		[MICROBIOME]: `DiscoveryPanel_${MICROBIOME}`,
		[LIVERFIBROSIS]: `DiscoveryPanel_${LIVERFIBROSIS}`,
		[KIDNEYFUNCTION]: `DiscoveryPanel_${KIDNEYFUNCTION}`
	},
	ui: {
		[BIOSTRESS]: 'Biological Stress',
		[MICROBIOME]: 'Microbiome',
		[LIVERFIBROSIS]: 'Liver Fibrosis',
		[KIDNEYFUNCTION]: 'Kidney Function'
	},
	pathway: {
		[BIOSTRESS]: 'stress_pathways',
		[MICROBIOME]: 'microbiome_pathways',
		[LIVERFIBROSIS]: 'microbiome_pathways',
		[KIDNEYFUNCTION]: 'microbiome_pathways'
	},
};