import { ascending } from "d3";

const sortPathways = (pathways) => (
  pathways.sort((a, b) =>
    ascending(a.pathway_name, b.pathway_name)
  )
);

export const pathwaysSetter = (state, action) => {
  const { payload } = action;
  
  state.pathways.raw = payload.raw.pathways;
  state.pathways.list = sortPathways([...payload.raw.pathways]);
  state.pathways.isLoaded = true;
  state.pathways.error = payload.error;
  state.overview = payload.raw.overview;
};