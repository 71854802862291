'use strict';
import dayjs from 'dayjs';
import {convertDate} from './DateUtils';

class ProjectUtils {

	static getStakeHolders(project, stakeHolderGroup) {
		if(Object.getOwnPropertyNames(project).length <= 0)
			return;

		return project.stakeHolders.filter( function(stakeholder) {
			return (stakeholder.name.toUpperCase() == stakeHolderGroup.toUpperCase());
		})[0].members;
	}

  static getProjectManagers(project) {
		return ProjectUtils.getStakeHolders(project, 'projectManagers');
	}

	static getSalesPersons(project) {
		return ProjectUtils.getStakeHolders(project, 'SALESPERSONS');
	}

	static getStakeHolderEmails(project, stakeHolderGroup) {
		let stakeHolders = ProjectUtils.getStakeHolders(project, stakeHolderGroup);
		if(!stakeHolders) return;

		return stakeHolders.map(stakeHolder => {
			return stakeHolder.email;
		});
	}

	static normalizeDate(date, format='DD MMM YYYY') {
    return convertDate(date, format);
  }

  static getDateRange(date1, date2) {
    let start = ProjectUtils.normalizeDate(date1),
      end = ProjectUtils.normalizeDate(date2);

    if(start && end) return start +' - '+end;
    if(start || end) return start ? start : end;
    return '';
  }

  //milestone stuff
  static getSteps(project, userType) {
    if(userType=='external' || ['mVision', 'mSelect', 'mData'].indexOf(project.productType) < 0) {
      return ProjectUtils.getExternalSteps(project);
    }
    return ProjectUtils.getInternalSteps(project);
  }

  static getExternalSteps(project) {
    const normalizeDate = ProjectUtils.normalizeDate;
    let steps = [
      {title: "Project Created", date: normalizeDate(project.dates.projectActive,'d MMM YYYY')},
      {title:"Contract", title2: "Waiting on Contract", title3:"Contract Signed", date: normalizeDate(project.dates.contractDate)},
      {title:"Samples", title2: "Waiting on Samples", title3:"Samples Received", date:normalizeDate(project.dates.sampleReceiptDate || project.dates.estSampleReceiptDate)},
      {title: "Project Complete", title2:"Project In Progress", date: normalizeDate(project.dates.finalReport || project.dates.estDeliveryEnd)},
    ];
    
    if(steps[1].date && steps[2].date){
      if(!dayjs(steps[1].date).isBefore(dayjs(steps[2].date))) {
        [steps[1], steps[2]] = [steps[2], steps[1]];
      }
    }

    return steps;
  }

  static getInternalSteps(project) {
    const normalizeDate = ProjectUtils.normalizeDate;
    let steps = [
      {title:"Project Created", date: normalizeDate(project.dates.projectActive,'d MMM YYYY')},
      {title:"Contract", title2:"Waiting on Contract", title3:"Contract Signed", date: normalizeDate(project.dates.contractDate)},
      {title:"Samples", title2: "Waiting on Samples", title3:"Samples Received", date: normalizeDate(project.dates.sampleReceiptDate || project.dates.estSampleReceiptDate)},
      {title:"Accessioning", title2:"Sample Accessioning", title3:"Samples Accessioned", date: normalizeDate(project.dates.samplesAccessioned)},
      {title:"Platform", title2: "Platform in Progress", title3:"Platform Complete", date: normalizeDate(project.dates.qcComplete)},
      {title:"Curation", title2: "Curation in Progress", title3:"Curation Complete", date: normalizeDate(project.dates.curationComplete)},
      {title:"Stats", title2: "Stats in Progress", title3:"Stats Complete", date: normalizeDate(project.dates.statsComplete)},
      {title:"Data Interpretation", title2: "Data Interpretation in Progress", title3:"Data Interpretation Complete", date:normalizeDate(project.dates.finalReport || project.dates.estDeliveryEnd)},
      {title:"Project Complete", title2:"Project In Progress", date: normalizeDate(project.dates.finalReport || project.dates.estDeliveryEnd)},
    ];
    if(steps[1].date && steps[2].date){
      if(!dayjs(steps[1].date).isBefore(dayjs(steps[2].date))) {
        [steps[1], steps[2]] = [steps[2], steps[1]];
      }
    }

    return steps;
  }

  static getMilestonePreviews(project, userType) {
    let steps = ProjectUtils.getSteps(project, userType);

    if(project.finalReport) return [];
    else {
      let step = 0, s, n=steps.length;
      for(let i=0; i < n; i++) {
        let s = steps[i];
        if(s.date) {
          if(i == n-1) continue;
          step = i+1;
        } 
      }
      return [steps[step], steps[step+1]];
    }
  }
}
  
export default ProjectUtils;