'use strict';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DropdownContainer from './DropdownContainer.jsx';
import Input from '../components/controls/Input.jsx';
import Material from '../components/controls/Material.jsx';
import { enrichmentFilterSelector } from '../redux/selectors/enrichmentFilter.selector.js';
import { setMinCount } from '../redux/reducers/enrichmentFilter.reducer.js';
import './EnrichmentFilter.scss';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger.js';

export const EnrichmentFilter = (props) => {
	const dispatch = useDispatch();
	const { minCount } = useSelector(enrichmentFilterSelector);

	const [isOpen, setIsOpen] = useState(false);

	const logger = useLocalAuditLogger();
	const onFilterChange = useCallback((e) => {
		dispatch(setMinCount(e.target.value));
		logger.logUserAction(`enrichment filter change`);
	}, [dispatch]);

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<DropdownContainer className="EnrichmentFilter" onOpen={onOpen} onClose={onClose} {...props}>
			<div key={0} className={"Button primary"+(isOpen?'':' alt')}>Filter Results&nbsp;<Material icon="filter_list"/></div>
			<div key={1} className="">
				<span className="nowrap">Detected compounds (m) &ge;</span>&nbsp;
				<Input min="0" onChange={onFilterChange} placeholder={String(minCount)} type="number" htmlSize="3" value={minCount}/>
			</div>
		</DropdownContainer>
	);
};

EnrichmentFilter.propTypes = {

	/** Whether a caret is appended to the dropdown label */
	caret: PropTypes.bool,

	/** The color of the dropdown button */
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The size of the dropdown button */
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

	/** Whether the dropdown button is disabled */
	disabled: PropTypes.bool,

	/** Whether the dropdown button is in alt-color mode */
	alt: PropTypes.bool,

	/** Whether the dropdown button is in alt2-color mode */
	alt2: PropTypes.bool,

	/** type attribute applied to the dropdown button */
	type: PropTypes.string,
};

EnrichmentFilter.defaultProps = {
	renderMenu: true,
	caret: false,
	size: DropdownContainer.defaultProps.size,
	color: 'default',//DropdownContainer.defaultProps.color,
	disabled: DropdownContainer.defaultProps.disabled,
	alt: DropdownContainer.defaultProps.alt,
	alt2: true,
	title: 'Enrichment Filter',
	type: DropdownContainer.defaultProps.type,
};