import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { InputWrapper } from "metabux/components/InputWrapper/InputWrapper";

export const RadioButtons = ({
  inputs,
  label,
  defaultValue,
  fullWidth,
  row,  
  noMargin,
  value,
  testId = "radioButtons",
  ...props
}) => {

  return (
    <FormControl fullWidth={fullWidth}>
      {label !== "" && <FormLabel>{label}</FormLabel>}
      <RadioGroup
        row={row}
        name="row-radio-buttons-group"
        defaultValue={defaultValue}
        value={value}
        data-testid={testId}
        {...props}
      >
        {inputs.map((i) => (
          <InputWrapper
            key={i.value}
            value={i.value}
            label={i.label}
            disabled={i.disabled}
            sx={{ marginRight: noMargin ? 0 : 4 }}
          >
            <Radio />
          </InputWrapper>
        ))}
      </RadioGroup>
    </FormControl>
  );
};