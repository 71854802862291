import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { getColor } from '@/theme';

export const LoadingSpinner = ({
	color="primary",
	label=null,
	testId="loadingSpinner",
	size=100,
	...props 
}) => {
	const colorDeck = getColor(color),
		color0 = colorDeck ? colorDeck[500] : "#304369",
		color1 = colorDeck ? colorDeck[200] : "#F5F7FA";
	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} data-testid={testId}>
			<svg width="0" height="0">
				<defs>
					<linearGradient id={color} x1="0" y1="0" x2="1" y2="1">
						<stop offset="20%" stopColor={color0} />
						<stop offset="80%" stopColor={color1} />
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress size={size} {...props} sx={{
				'.MuiCircularProgress-circle': {
					stroke:  `url(#${color})`
				}
			}} />
			{label && <Typography component="span">{label}</Typography>}
		</Box>
	)
}
