import React, { useLayoutEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { group } from "d3";
import Table from "../../table/Table";
import { dataSelector } from "../../../redux/selectors/data.selector";
import mapToArray from "../../../utils/mapToArray";
import * as styles from "./defaultprofile.module.scss";
import * as profileStyles from "./pathwayprofile.module.scss";
import { DiscoveryPanelMap, BIOSTRESS } from "@/enums/Enums";
import { discoveryPanelsSelector } from "@/discovery-panel/redux/selectors/discoveryPanels.selector";

const DefaultProfile = () => {
  const { pathways, metabolites, sample } = useSelector(dataSelector);
  const { productType, overview } = useSelector(discoveryPanelsSelector);
  const productTypeDisplay = useMemo(() => DiscoveryPanelMap.ui[productType], [productType]);
  const productTablePathway = !productTypeDisplay ? "Pathway" : `${productTypeDisplay} Pathway`;

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const pathwayData = useMemo(() => {
    return mapToArray(group(metabolites, (d) => d.pathway_name)).map((d) => {
      return {
        total_metabolites: d.value.length,
        pathway_name: d.key,
      };
    });
  }, [metabolites]);

  const tableData = useMemo(() => {
    return {
      metabolites: [
        {
          label: "Metabolites",
          data: metabolites.concat(),
        },
      ],
      pathways: [
        {
          label: `${productTablePathway}s`,
          data: pathwayData.concat(),
        },
      ],
    };
  }, [metabolites, pathwayData, productTablePathway]);

  const { pathwayColumns, metabolitesColumns } = useMemo(() => {
    const pathwayColumns = [
      {
        key: "pathway_name",
        value: productTablePathway,
        width: 50,
        align: "left",
      },
      {
        key: "total_metabolites",
        value: "Total Metabolites",
        width: 50,
        align: "left",
      },
    ];

    const metabolitesColumns = [
      {
        key: "metabolite",
        value: "Metabolite",
        width: 50,
        align: "left",
      },
      {
        key: "pathway_name",
        value: productTablePathway,
        width: 50,
        align: "left",
      },
    ];

    return {
      pathwayColumns,
      metabolitesColumns,
    };
  }, [productTablePathway]);

  return (
    <div className={styles.defaultProfile}>
      <div className="copy">
        <h3>
          {sample.projectCode}<br />{sample.matrixType}
        </h3>
        <h2>
          The {productTypeDisplay} Discovery Panel covers {metabolites.length} metabolites
          across {pathways.length} pathways
        </h2>
        <p>
          Click on a metabolite or pathway to view their corresponding profile
          and learn more
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles[productType]} />
        <div className={styles.tables}>
          <Table
            tableData={tableData.metabolites}
            defaultSort={metabolitesColumns[0].key}
            defaultSortDirection={true}
            flow="column"
            equalSpacing={true}
            showTotal={false}
            showLabel={true}
            columns={metabolitesColumns}
            height={50}
          />

          <Table
            tableData={tableData.pathways}
            defaultSort={pathwayColumns[1].key}
            defaultSortDirection={false}
            flow="column"
            equalSpacing={true}
            showTotal={false}
            showLabel={true}
            columns={pathwayColumns}
            height={50}
            tableType={"pathway"}
          />
        </div>
      </div>
      { }
      <div dangerouslySetInnerHTML={{ __html: overview.text }}/>
    </div>
  );
};

export default DefaultProfile;
