import { Box, Typography } from "@mui/material";
import React from "react";
import IconButton from "metabux/components/IconButton/IconButton";
import { Gray, Yellow } from "metabux/theme/colors";
import { KPIMetric } from "./components/KPIMetric";
import { MoreHorizontal, Star } from "lucide-react";

export const KPI = ({
  title,
  metrics,
  favorite,
  onFavoriteClick,
  onMoreClick,
  sx,
  testId = "kpi",
  ...props
}) => {
  
  return (
    <Box
      sx={{ borderRadius: 3, padding: 4, background: "white", ...sx }}
      data-testid={testId}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 10,
        }}
      >
        <Typography variant='h5' sx={{ fontWeight: 600, color: Gray[800] }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {onFavoriteClick && (
            <IconButton
              size='large'
              icon={Star}
              iconColor={favorite ? Yellow[500] : "inherit"}
              iconFill={favorite ? Yellow[500] : "transparent"}
              onClick={onFavoriteClick}
            />
          )}
          {onMoreClick && (
            <IconButton
              size='large'
              icon={MoreHorizontal}
              onClick={onMoreClick}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        {metrics.map((metric, index) => (
          <KPIMetric
            key={`kpi-metric-${index}`}
            label={metric.label}
            value={metric.value}
            infoText={metric.infoText}
            noBorder={index === 0 ? true : false}
            type={metric.type}
            metricsCount={metrics.length}
          />
        ))}
      </Box>
    </Box>
  );
};