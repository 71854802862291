import React, {
  memo,
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import Table from "../../table/Table";
import Icon from "../../../../components/controls/Icon";
import { dataSelector } from "../../../redux/selectors/data.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import * as styles from "./center.module.scss";
import { filterSelector } from "../../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import { interactionsUpdateSelection } from "../../../redux/reducers/discoveryPanels.reducer";
import RouterLink from "@/components/buttons/RouterLink";

const metaboliteColumns = [
  {
    key: "association",
    value: "Association",
    width: 49,
    align: "left",
    click: "association",
  },
  {
    key: "association_table_significant",
    value: "%Sig",
    width: 17,
    align: "right",
  },
  {
    key: "association_table_upregulated",
    value: "%Up",
    width: 17,
    align: "right",
  },
  {
    key: "association_table_downregulated",
    value: "%Down",
    width: 17,
    align: "right",
  },
];

const associationColumns = [
  {
    key: "metabolite",
    value: "Metabolite",
    width: 49,
    align: "left",
    click: "metabolite",
  },
  {
    key: "metabolite_table_significant",
    value: "Total Sig.",
    width: 17,
    align: "right",
  },
  {
    key: "metabolite_table_upregulated",
    value: "Sig. Up",
    width: 17,
    align: "right",
  },
  {
    key: "metabolite_table_downregulated",
    value: "Sig. Down",
    width: 17,
    align: "right",
  },
];

const Center = () => {
  //import necessary context values
  const { association, associations: associationList, tabs } =
    useSelector(filterSelector);
  const { associations, results } = useSelector(dataSelector);
  const viewPort = useSelector(viewPortSelector);
  const selectedState = useSelector(interactionsSelector);
  const dispatch = useDispatch();

  const { width, smallScreen, mobile, innerRadius: radius } = viewPort;
  const tableHeight = radius / 2;
  const centerMinWidth = smallScreen ? 200 : 250;
  const centerMaxWidth = 310;
  const centerWidth = Math.min(radius / 1.5, centerMaxWidth);
  const centerHeight = Math.min(400,
    radius * (mobile ? 0.6 : smallScreen ? 1 : width > 1600 ? 0.75 : 1));

  //filter data down to the correct values for the selectedState
  const key =
    selectedState.type === "association" ? "association" : "chemical_id";
  const tableData = useMemo(() => {
    return associations.filter(
      (d) => selectedState.id === d[key] && d.type === association
    );
  }, [association, associations, key, selectedState.id]);

  // set up information for the center
  const labelAssociations = useMemo(() => {
    return associationList.filter((d) => {
      return d.value === association;
    })[0].label_short;
  }, [association, associationList]);

  const associationKey = useMemo(() => {
    return associationList.filter((d) => {
      return d.value === association;
    })[0].key;
  }, [association, associationList]);

  const selectedVal = selectedState.display;
  const label =
    selectedState.type === "association" ? labelAssociations : "Metabolite";

  // get height of label (to help with breaking to 1 or 2 liens)
  const [centerHedHeight, setCenterHedHeight] = useState(41);
  const centerHedRef = useRef(null);

  useEffect(() => {
    if (centerHedRef.current)
      setCenterHedHeight(centerHedRef.current.offsetHeight || 41);
  }, [selectedState]);

  const someDetected = results
    .filter((d) => d.chemical_id === selectedState.id)
    .some(d => d.detected === true);

  const goToPathwayProfile = useCallback(() => {
    const pathway = tableData[0].pathway_name;

    dispatch(interactionsUpdateSelection({
      id: pathway,
      display: pathway,
      type: "pathway",
      subtype: null,
      method: "click",
      source: "heliogram",
      sourceSpecific: null,
    }));
  }, [tableData, dispatch]);

  const buttonsList = [
    { label: "View Metabolite Profile", target: tabs[2].url, click: () => {} },
    {
      label: "View Pathway Profile",
      target: tabs[2].url,
      click: goToPathwayProfile,
    },
  ];

  const visibleButtons =
    tableData.length > 0 && tableData[0].pathway_name
      ? buttonsList
      : [buttonsList[1]];

  return (
    <div
      className={styles.center}
      style={{
        width: centerWidth,
        minWidth: centerMinWidth,
        height: centerHeight,
      }}
    >
      {selectedState.id === "default" && (
        <h3 className={styles.hedPlaceholder}>
          {mobile ? "Click" : "Hover"} to learn more
        </h3>
      )}

      {!mobile && selectedState.id !== "default" && tableData.length === 0 && (
        <p className={styles.hedPlaceholderDetection}>
          This metabolite is not associated with {associationKey}.
        </p>
      )}

      {!mobile && selectedState.id !== "default" && !someDetected && selectedState.type === "metabolite" && tableData.length > 0 &&  (
        <p className={styles.hedPlaceholderDetection}>
          This metabolite was not detected in the study.
        </p>
      )}

      {selectedState.id !== "default" && (
        <div
          className={styles.contentWrapper}
          style={{
            width: centerWidth,
            minWidth: centerMinWidth,
          }}
        >
          <div ref={centerHedRef}>
            <p className={styles.surHed}>{label}</p>
            <h3 className={styles.centerHed}>{selectedVal}</h3>
          </div>

          <div
            className={styles.tableWrapper}
            style={{
              top: centerHedHeight * 1.3,
              height: tableHeight,
              width: centerWidth,
              minWidth: centerMinWidth,
            }}
          >
            {selectedState.type === "metabolite" && !mobile && someDetected && (
              <Table
                tableData={[{ data: tableData }]}
                defaultSort={"association_table_significant"}
                columns={metaboliteColumns}
                height={100}
                tableType={"association"}
              />
            )}
            {selectedState.type === "association" && !mobile && (
              <Table
                tableData={[{ data: tableData }]}
                defaultSort={"metabolite_table_significant"}
                columns={associationColumns}
                height={100}
                tableType={"metabolite"}
                splitHeader={true}
              />
            )}
          </div>

          {selectedState.type === "metabolite" &&
            selectedState.method === "click" &&
            !smallScreen && (
              <div className={styles.buttons + " button-vertical-wrapper"}>
                {visibleButtons.map((d, i) => {
                  return (
                    <RouterLink
                      to={d.target}
                      onClick={d.click}
                      color="white"
                      className="transparent-link-button block slide"
                      key={i}
                    >
                      <div
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <p className="body-sm">{d.label}</p>
                        <Icon icon="angle-right" size="large" color="default" />
                      </div>
                    </RouterLink>
                  );
                })}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default memo(Center);
