import PropTypes from "prop-types";
import React, { memo } from "react";
import getDocStyle from "../../utils/getDocStyle";

const LinePath = ({ path }) => {
  return (
    <path
      d={path}
      fill="none"
      stroke={getDocStyle("var(--cold-gray-darkest)")}
      strokeWidth="1.3"
      pointerEvents="none"
    />
  );
};

LinePath.propTypes = {
  path: PropTypes.string,
};

LinePath.defaultProps = {
  path: "",
};

export default memo(LinePath);
