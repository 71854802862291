'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Card.scss';
import { withRouter } from 'react-router-dom';
import ProjectUtils from '../../utils/ProjectUtils.jsx';
import Label from '../../components/controls/Label.jsx';
import Button from '../../components/buttons/Button.jsx';
import Link from '../../components/buttons/Link.jsx';
import Icon from '../../components/controls/Icon.jsx';
import Mailto from '../../components/controls/Mailto.jsx';
import { connect } from 'react-redux';
import { fetchAllFiles } from '../../redux/actions/files';

export class ProjectCard extends Component {
  
  constructor(props) {
    super(props); 
    this.state = {
      project: PropTypes.object,
      userType: 'internal'
    };
  }

  componentWillMount() {

  }

  /* istanbul ignore next */
  componentWillReceiveProps(nextProps) { this.setState({project: nextProps.project}); }


  renderStepPreview(steps) {
    //step previews
    let step2 = steps[1] ? 
      <div>
        <Label size="small" className="next-step"><Icon icon="minus" className="fa-rotate-90" size="small"/></Label>
        <Label size="small" className="next-step"><Icon icon="circle" size="small"/> &nbsp;{steps[1].title}</Label>
      </div>
      : null;

    return (
      <div className="content">
        <Label color="primary" size="small" className="step"><Icon icon="circle-o" size="small"/> &nbsp;{steps[0].title2 || steps[0].title}</Label>
        {step2}
      </div>
    );

  }
  /**Handlers */
  viewResults = () =>   this.props.history.push(`project/${this.props.project.projectCode}/results`);
  viewCytoScape = () => window.open('https://www.youtube.com/watch?v=j-QnLgWiAdY','_blank');
  fetchAllFiles =() => this.props.fetchAllFiles(this.props.project);
    
  render() {
    const project = this.props.project;
    const {projectCode, description, status, dates} = project;
    
    let content, cal, action;

    let daterange = ProjectUtils.normalizeDate(project.dates.finalReport) || ProjectUtils.normalizeDate(dates.estDeliveryEnd);
    if(status != "complete") {
      //let start= ProjectUtils.normalizeDate(project.dates.estDeliveryStart), end= ProjectUtils.normalizeDate(project.dates.estDeliveryEnd);
      //daterange = start.concat(['-'].concat(end).join(' '));
      daterange = ProjectUtils.getDateRange(project.dates.estDeliveryStart, project.dates.estDeliveryEnd);
    }
    switch(status) {
      default: 
      case "ontime":
      case "hold":
        cal = <Icon icon="calendar-o" className="cal"/>;
        action = <Icon icon="bell"/>;
        content = this.renderStepPreview(ProjectUtils.getMilestonePreviews(project, this.state.userType));
        break;

      case "cancelled":
        cal = <Icon icon="calendar-times-o" className="cal"/>;
        action = <Icon icon="bell"/>;
        content = this.renderStepPreview(ProjectUtils.getMilestonePreviews(project, this.state.userType));
        break;

      case "complete":
        cal = <Icon icon="calendar-check-o" className="cal"/>;
        action =  <Link onClick={this.fetchAllFiles}  className="code download-icon" alt2={true} size="normal" color="primary"><Icon icon="download"/></Link> ;
        content = (
          <div className="content">
            <Button size="small" onClick = {this.viewResults} alt={true}>View Results</Button>
            <Button size="small" onClick = {this.viewCytoScape} alt={true}>Explore Data</Button>
          </div>
        );
        break;
    }

    return (
        <div id={projectCode} className={`ProjectCard ${status}`}>
          <div className="wrapper top">
            <Link to={`/home/project/${projectCode}`} className="code" alt2={true} size="normal" color="primary">{projectCode}</Link>
            <Label className="desc" size="small">{description}</Label>
            {content}
          </div>  
          <hr/>
          <div className="wrapper bot">
            <span className="date">
              {cal}
              <Label size="small" className="inline daterange">{daterange}</Label>
            </span>
            <span className="action-icons">
              {action}
              <Mailto to={ProjectUtils.getStakeHolderEmails(project, 'projectManagers')} subject={projectCode}><Icon icon="envelope"/></Mailto>
              
            </span>
          </div>
        </div>
    );
  }
}

ProjectCard.propTypes = {
    /** project*/
    project: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    hasErrored: state.files.projectsHasErrored,
    isLoading: state.files.projectsIsLoading,
    files: state.files.data
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAllFiles: (projectId) => dispatch(fetchAllFiles(projectId))
  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ProjectCard));

