import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { managedUsersSelector } from '@/redux/selectors/managedUsers.selector.js';
import {
	usePublishUserMutation,
	useUserQuery,
} from '@/redux/services/admin.api';
import SearchContainer from '@/containers/SearchContainer.jsx';
import {
	DynamicModalButton,
	Icon
} from '@/components/index.jsx';
import { AppInsightLogLevel } from '@/enums/Enums';
import * as permissions from '@/security/permissionTypes';
import { Logger, Log } from '@/utils/Logger';
import { generateGuid } from '@/utils/StringUtils';
import AddNewUser from './AddNewUser';
import ManagedUsersTable from './ManagedUsersTable';
import './UserManagementContainer.scss';
import '@/theme/theme.css';
import { usePermissions } from '@/hooks/permissions.hook';

const logger = new Logger();
const logUserManagment = (user, action) => {
	let logAddUser = new Log();
	logAddUser.SetLevel(AppInsightLogLevel.EVENT);
	logAddUser.SetName("PageEvent_UserManagment");
	logAddUser.AddProperty(action, JSON.stringify(user));
	logger.doLog(logAddUser);
};

export const UserManagementContainer = () => {
	const {
		data: managedUsers,
	} = useSelector(managedUsersSelector);

	const [publishUser] = usePublishUserMutation();

	const { checkUserHasPermissions } = usePermissions();

	const [newUser, setNewUser] = useState();
	const [editProjectPermissions, setEditProjectPermissions] = useState(false);
	const [editUserPermissions, setEditUserPermissions] = useState(false);
	const [addUserPermission, setAddUserPermission] = useState(false);

	const { data: newUserData, isLoading: newUserIsLoading } = useUserQuery(newUser?.email, { skip: !newUser?.email || newUserData?.email === newUser?.email });

	const setupNewUserObject = (user, userid) => ({
		userId: userid,
		username: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		projects: [],
		roles: [{ name: 'Basic', permissions: ['canViewAperture'] }]
	});

	useEffect(() => {
		setEditProjectPermissions(checkUserHasPermissions(permissions.CAN_EDIT_PROJECT_PERMISSIONS));
		setEditUserPermissions(checkUserHasPermissions(permissions.CAN_EDIT_USER_PERMISSIONS));
		setAddUserPermission(checkUserHasPermissions(permissions.CAN_ADD_USERS));
	}, []);

	const onAddUser = useCallback((user) => {
		setNewUser(user);
	}, []);

	useEffect(() => {
		if (newUserIsLoading || !newUser) return;
		if (newUserData) {
			let updatedUser = { ...newUserData };
			if (newUser) {
				updatedUser.firstName = newUser.firstName;
				updatedUser.lastName = newUser.lastName;
			}
			let updatedUserObject = {
				...updatedUser
			};
			publishUser(updatedUserObject, "Portal User Update info, automatic comment");
		}
		else {
			let userId = generateGuid();
			let newUserObject = setupNewUserObject(newUser, userId);
			publishUser(newUserObject, "Portal Add User, automated comment");
			logUserManagment(newUserObject, "AddUser");
		}
	}, [newUser, newUserData?.email, newUserIsLoading]);

	let userSearchResults = managedUsers;
	//This should only be needed for local json db, need more testing
	if (!Array.isArray(userSearchResults)) {
		userSearchResults = [managedUsers];
	}

	return (
		<div className="managedUsersContainer">
			<div className="tableHeader">Manage Users</div>
			<div className="searchInput">
				<SearchContainer className="UserSearch" iconColor={'tertiary'} showResults={false} SearchDelay={true} dataModel={'managedUsers'} />
				{
					addUserPermission && (
						<DynamicModalButton
							className="alt2 AddUser"
							modalId={`modal-add-user`}
							modalProps={{ title: 'Add New User', titleClass: 'title', renderClose: false, renderFooter: false, submitLabel: 'Add' }}
							component={AddNewUser}
							componentProps={{ onAddUser }
							}>
							<Icon color="primary" icon="plus-circle" /> Add User
						</DynamicModalButton>
					)
				}
			</div>
			<ManagedUsersTable
				users={userSearchResults}
				addUserPermission={addUserPermission}
				editProjectPermissions={editProjectPermissions}
				editUserPermissions={editUserPermissions}
				viewName={"PageEvent_ManagedUsers"}
			/>
		</div>
	);
};

export default UserManagementContainer;
