import React, {
  useContext,
  useLayoutEffect,
  memo,
  useEffect,
  useState,
	useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { dataSelector } from "../redux/selectors/data.selector";
import { filterSelector } from "../redux/selectors/filter.selector";
import { setActiveTab } from "../redux/reducers/discoveryPanels.reducer";
import CirclePackGraph from "../components/graphs/circlePack/CirclePackGraph";
import AssociationTables from "../components/containers/AssociationTables";
import { Loader } from "../components/controls/Loader";
import * as styles from "./explainer.module.scss";
import { DiscoveryPanelMap } from "@/enums/Enums";
import { discoveryPanelsSelector } from "../redux/selectors/discoveryPanels.selector";

const Explainer = ({ match }) => {
  const dispatch = useDispatch();
  const { metabolites, sample } = useSelector(dataSelector);
  const { tabs, comparison } = useSelector(filterSelector);
	const { productType } = useSelector(discoveryPanelsSelector);
	const productTypeDisplay = useMemo(() => DiscoveryPanelMap.ui[productType], [productType]);
  const [mounted, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
    dispatch(setActiveTab(tabs[0].url));
    return () => setMount(false);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!mounted) {
    return <Loader />;
  }
  return (
    <div className={styles.explainer + " fade-in"}>
      <section>
        <div className="copy">
          <h3>{sample.projectCode}<br />{sample.matrixType}</h3>
          <h2>
            The {productTypeDisplay} Discovery Panel covers {metabolites.length} metabolites
          </h2>
          <p>Use the filters to examine your results.</p>
        </div>

        <CirclePackGraph match={match} />
      </section>
      <section>
        <div className="copy">
          <h2>What do the results of {comparison} mean?</h2>
          <p>
            Explore significant associations with Disease &amp; Disorders,
            Biological Themes, and Pathways.
          </p>
        </div>
        <AssociationTables match={match} />
      </section>
    </div>
  );
};

export default memo(Explainer);
