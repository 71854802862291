import React, { useCallback } from "react";
import Modal from "metabux/components/Modal/Modal";
import { Button } from "metabux/components/Button/Button";
import Link from "metabux/components/Link/Link";
import { Stack, Tooltip, Typography } from "@mui/material";
import { CopyToClipboard } from "@/lenses/uilenses/components/CopyToClipboard/CopyToClipboard";
import DownloadIcon from '@mui/icons-material/Download';
import { useFetchSpectralMetaDataQuery, useFetchSpectralUrlQuery } from "@/redux/services/spectralData.api";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Gray } from "../../../theme/colors";
import { formatBytes } from "@/utils/StringUtils";

export function SpectralDataModal({
	projectId,
	open,
	setOpen
}) {
	const { data, isLoading } = useFetchSpectralMetaDataQuery(projectId);
	const { data: downloadLink, isLoading: linkIsLoading } = useFetchSpectralUrlQuery(projectId);
	const {
		fileSize,
		zipDecryptKey
	} = data || {};

	const mb = 1048576;

	const onClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={<>Retrieve Spectral Data [{formatBytes(fileSize*mb)}]</>}
		>
			<Stack
				spacing={2}
				direction="column"
				mb={4}
			>
				<CopyToClipboard width={300} label={<>Download Link <Tooltip title="Link to download spectral data" arrow placement="top"><InfoIcon fontSize="small" /></Tooltip></>}>
					{downloadLink}
				</CopyToClipboard>
				{zipDecryptKey && <CopyToClipboard label={<>Encryption Key <Tooltip title="Password for zip file download" arrow placement="top"><InfoIcon fontSize="small" /></Tooltip></>}>
					{zipDecryptKey}
				</CopyToClipboard>}
			</Stack>

			<Stack border="1px solid black" p={3}>
				<Typography variant="h4" color="error" fontWeight={600}>WARNING:</Typography>
				<Typography>Before proceeding please ensure you have the necessary storage space to store a <Typography fontWeight={600} component="span" color="error">{formatBytes(fileSize*mb)}</Typography> file. We recommend saving the file to an alternative location, such as</Typography>
				<Typography component="ul">
					<Typography component="li">An external hard drive</Typography>
					<Typography component="li">Cloud storage services like Google Drive, Dropbox, or Microsoft OneDrive</Typography>
					<Typography component="li">A designated folder on your device's secondary drive (if available)</Typography>
				</Typography>

				<Link bgcolor={Gray[900]} mt={3} external component={Button} href={downloadLink} target="_blank">Download Spectral Data <DownloadIcon /></Link>
			</Stack>
		</Modal>
	);
}
