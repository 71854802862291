import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from '../actions/actionTypes';

export const getInitialState = () => ({
    availableRolesIsLoading: false,
    availableRolesHasErrored: false,
    data: [],
    authenticated: false
});

export const availableRolesSlice = createSlice({
    name: 'availableRoles',
    initialState: getInitialState(),
    extraReducers: {
        [actionTypes.AVAILABLEROLES_HAS_ERRORED]: (state) => {
            state.availableRolesIsLoading = false;
            state.availableRolesHasErrored = true;
        },
        [actionTypes.AVAILABLEROLES_IS_LOADING]: (state) => {
            state.availableRolesIsLoading = true;
        },
        [actionTypes.AVAILABLEROLES_FETCH_DATA_SUCCESS]: (state, action) => {
            state.availableRolesIsLoading = false;
            state.authenticated = action.authenticated || false;
            state.data = Array.isArray(action.response) ? action.response : Object.values(action.response);
        }
    }
});

export const availableRolesReducer = availableRolesSlice.reducer;