import React, { memo } from "react";
import PropTypes from "prop-types";

const TableRow = ({ c, displayContent, color, active, enableFontWeight }) => {
  return (
    <p
      style={{
        width: c.width + "%",
        textAlign: c.align,
        color: c.color ? color : "inherit",
        fontWeight: active && enableFontWeight ? "bold" : "normal",
      }}
    >
      {displayContent}
    </p>
  );
};

TableRow.propTypes = {
  c: PropTypes.object,
  displayContent: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  enableFontWeight: PropTypes.bool,
};

TableRow.defaultProps = {
  c: {},
  displayContent: "",
  color: "inherit",
  active: false,
  enableFontWeight: false,
};

export default memo(TableRow);
