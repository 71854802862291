import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { group as d3group } from "d3";

import { SPSelect as Select } from "../components/controls/Select";
import SearchDropdown from "../../components/controls/SearchDropdown";
import Slider from "../components/controls/Slider";
import { filterSelector } from "../redux/selectors/filter.selector.js";
import { dataSelector, staticSelector } from "../redux/selectors/data.selector";
import { interactionsSelector } from "../redux/selectors/interactions.selector";

import { 
  setComparison,
  setFilterAssociation,
  setGroup,
  setInvertedFoldChange,
  setMultiComparisons,
  interactionsUpdateSelection
} from "../redux/reducers/discoveryPanels.reducer";

import mapToArray from "../utils/mapToArray";
import * as styles from "./filter.module.scss";
import Toggle from "../components/controls/Toggle";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";

const Filter = () => {
  const logger = useLocalAuditLogger();
  const dispatch = useDispatch();
  const {
    metabolites,
    pathways,
  } = useSelector(dataSelector);

  const {
    associations: staticAssociations,
  } = useSelector(staticSelector);
  
  const {
    tabs,
    activeTab,
    comparisons,
    comparison,
    maxComparisonCount,
    multiComparisons,
    group,
    groups,
    invertedFoldChange,
    associations,
    association,
  } = useSelector(filterSelector);

  const metaboliteSearchList = useMemo(() => {
    return metabolites.map((d) => {
      return {
        key: d.chemical_id,
        name: d.metabolite,
        type: "metabolite",
        subtype: "metabolite",
        sourceSpecific: { pathway_name: d.pathway_name, group: d[group] },
      };
    });
  }, [metabolites]);

  const pathwaySearchList = useMemo(() => {
    return pathways.map((d) => {
      return {
        key: d.pathway_name,
        name: d.pathway_name,
        type: "pathway",
        subtype: "pathway",
        sourceSpecific: null,
      };
    });
  }, [pathways]);

  const associationSearchList = useMemo(() => {
    const uniqueAssociations = mapToArray(
      d3group(staticAssociations, (d) => d.association + "|" + d.type)
    );
    return uniqueAssociations.map((d) => {
      const type = d.key.split("|")[1];
      return {
        key: d.key.split("|")[0],
        name: d.key.split("|")[0],
        type: "association",
        subtype_id: type,
        subtype:
          type === "Biological"
            ? "Biological Theme"
            : type === "Disease"
            ? "Disease & Disorder"
            : "Pathway",
        sourceSpecific: null,
      };
    });
  }, [staticAssociations]);

  const searchData = useMemo(() => {
    switch (activeTab) {
      case tabs[0].url:
        return metaboliteSearchList;
      case tabs[1].url:
        return associationSearchList.concat(metaboliteSearchList);
      case tabs[2].url:
        return pathwaySearchList.concat(metaboliteSearchList);
      default:
        return metaboliteSearchList;
    }
  }, [activeTab]);

  const selectedState = useSelector(interactionsSelector);

  const onClick = useCallback((e) => {
    if (e.type === "association" && association !== e.subtype_id) {
      dispatch(setFilterAssociation(e.subtype_id));
    }
    dispatch(interactionsUpdateSelection({
      id: e.key,
      display: e.name,
      type: e.type,
      subtype: e.subtype_id || null,
      method: "click",
      source: "search",
      sourceSpecific: e.sourceSpecific,
    }));
    logger.logUserAction(`user selected Find a metabolite`);
  });

  const resetSearch = useCallback((e) => {
    dispatch(interactionsUpdateSelection({
      id: "default:override",
    }));
  });

	const toggleFoldChange = useCallback(() => {
		dispatch(setInvertedFoldChange(!invertedFoldChange));
    logger.logUserAction(`user selected invert fold changes`);
	}, [dispatch, invertedFoldChange]);

  const searchValue = useMemo(() => {
    if (selectedState.id !== "default" && selectedState.method === "click") {
      return selectedState.display;
    } else if (selectedState.id === "default") {
      return "reset";
    } else {
      return null;
    }
  }, [selectedState]);

  return (
    (activeTab === tabs[1].url ||
      activeTab === tabs[0].url ||
      activeTab === tabs[2].url) && (
      <div className={styles["controls-wrapper"]}>
        <div className={`${styles.filters} filters`} style={{ zIndex: 100 }}>
          {activeTab !== tabs[2].url && (
            <Select
              activeClass={styles.active}
              action={activeTab === tabs[1].url ? setMultiComparisons : setComparison}
              data={comparisons}
              value={comparison}
              multiComparisons={multiComparisons}
              maxComparisonCount={maxComparisonCount}
              name="comparison-selector"
              id="comparison-selector"
              className={styles.comparisons}
              use="key"
              show="key"
              label="Select Comparison"
              multiSelect={activeTab === tabs[1].url ? true : false}
            />
          )}

          {activeTab !== tabs[2].url && (
            <Select
              activeClass={styles.active}
              action={setGroup}
              data={groups}
              value={group}
              name="group-selector"
              id="group-selector"
              className={styles.groups}
              label="Group By"
              use="value"
              show="key"
              multiSelect={false}
            />
          )}

          {activeTab !== tabs[2].url && activeTab !== tabs[0].url && (
            <Select
              activeClass={styles.active}
              action={setFilterAssociation}
              data={associations}
              key={association}
              value={association}
              name="association-selector"
              id="association-selector"
              className={styles.association}
              use="value"
              show="key"
              label="Association"
              multiSelect={false}
              resetSelectionOnSelect={true}
            />
          )}
      
          <Slider label="P-Value Threshold" />
          
          <Toggle 
            onClick={toggleFoldChange} 
            label={"Invert Fold Changes"} 
            value={invertedFoldChange} 
            className={styles.toggle}
          />

          <SearchDropdown
            className={classnames(styles.search, "input-wrapper Input")}
            data={searchData}
            label="Find a metabolite"
            labelClass="input-label"
            resultClass="alt2"
            resultColor="default"
            triggerClass=""
            wrapperClass="input-wrapper"
            searchKeys={["name"]}
            keySecondaryLabel={
              activeTab == tabs[1].url || activeTab == tabs[2].url
                ? ["subtype"]
                : null
            }
            keySecondaryLabelClass={styles.sublabel}
            onResultClick={onClick}
            resetIcon={true}
            onResetClick={resetSearch}
            resetClass={styles.reset}
            searchValue={searchValue}
          />
        </div>
      </div>
    )
  );
};

export default Filter;
