import React from 'react';

export function scrubHTML(text, replaceBr= false) {
  if(!text)
    return; 
  let Br = React.createElement('br');
  let regex = /(<br\>)/g;
  let scrubbedText = text.replace(/<(?!br\s*\/?)[^>]+>/g, '');

  if(!replaceBr){return text.replace(/<(?:[^>=]|='[^']*'|=""[^""]*""|=[^'""][^\s>]*)*>/g, "");}
  
  return scrubbedText.split(regex).map(function(line, index) {
      return line.match(regex) ? <Br key={"key_" + index} /> : line;
  });
}