import React, {useEffect, useState} from 'react';
import SettingsMap from '../../utils/SettingsMap';
import Heatmap from '../project/Heatmap/Heatmap.jsx';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import queryString from 'query-string';
import './StatsFetcher.scss';


export default function StatsFetcher({fileUrl}) {
	SettingsMap.initMap();
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [url, setUrl] = useState(fileUrl);

	const convertField = (obj, fieldName, newName) => {
		if(!(fieldName in obj)) return false;
		obj[newName] = obj[fieldName];
		return true;
	};
	
	useEffect(() => {
		const urlData = queryString.parse(location.search);
		setUrl(urlData.url);
	});

	useEffect(() => {
		if(!url) return;
		fetch(url)
		.then(res => {
			try {
				let json = res.json();
				return json;
			}
			catch(e) {
				onError(e);
			}
		})
		.then(
			(result) => {
				if(!Array.isArray(result)) result = [result];
				result.forEach(sampleSet => {
					if(!convertField(sampleSet, "Metabolites", "metabolites")) return onError();
					if(!convertField(sampleSet, "ProjectId", "projectID")) return onError();
					if(!convertField(sampleSet, "Sample", "sample")) return onError();
					if(!convertField(sampleSet, "Stats", "stats")) return onError();
				});
				setIsLoaded(true);
				setData(result);
			},
			onError
		);
	}, [url]);


	//local testing
	/*useEffect(() => {
		let result = testData;
		console.log(result)
		if(!Array.isArray(result)) result = [result];
		result.forEach(sampleSet => {
			convertField(sampleSet, "Metabolites", "metabolites");
			convertField(sampleSet, "ProjectId", "projectID");
			convertField(sampleSet, "Sample", "sample");
			convertField(sampleSet, "Stats", "stats");
		});
		setIsLoaded(true);
		setData(result);
	}, []);*/

	const onError = (e) => {
		setIsLoaded(true);
		setError(e);
	};

	const renderError = () => <p>Please provide a valid stats schema url <br/>Error:{error && error.toString()}</p>;

	return (
		<div>
			<div className="StatsFetcher">
				<div className="">
					{!isLoaded && <LoadingSpinner/>}
					{(isLoaded && error) && renderError()}
					{data && <Heatmap sampleSets={data} showHidden={true}/>}
				</div>
			</div>
		</div>
	);
}