'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/buttons/Button.jsx';
import DynamicTableCheckCell from '../../components/controls/DynamicTableCheckCell.jsx';
import Icon from '../../components/controls/Icon.jsx';

import './TableHeader.scss';

export class TableHeader extends Component {
  
  static propTypes = {
    /** project*/
    headers: PropTypes.array.isRequired,

    /** Current selected header cell*/
    current: PropTypes.number,

    /** Sort order */
    dir: PropTypes.number,

    /** Invoked upon header click*/
    onClick: PropTypes.func,

    limbo: PropTypes.bool,
    checked: PropTypes.bool,
  };

  static defaultProps = {
    dir: 1,
    current: -1
  };

  constructor(props) {
    super(props);
  }

  onClick = (headerName, n) => {
    if(this.props.onClick) this.props.onClick(headerName, n);
  }

  onToggleAll = ({target}) => {
    let {onToggleAll} = this.props;
    if(onToggleAll) onToggleAll(target.isChecked);
  }

  render() {
    const {activeIndex, headers, checked, limbo} = this.props;
    return (
        <ul className="TableHeader table-row">
          {headers.map((field, n) => {
            //default
            let displayText=field, name=field, className='header-cell table-cell', title;
            if(n == activeIndex) className += " active";

            if(typeof field !== "string") {
              if(field.type && field.type == 'select') {
                return (
                  <DynamicTableCheckCell testId="table-header-toggle-all" cellClass="header-cell table-cell" checked={checked} limbo={limbo} onChange={this.onToggleAll} key={n}/>
                );
              }
              
              name = field.name;
              if(field.header && typeof field.header !== "string") {
                displayText = field.header.text || name;
                className +=" "+field.header.className;
                title = field.header.title;
              }
              else {
                displayText = field.header || name;
                className +=" "+field.className;
                title = field.title;
              }
              
            }
            title = title || displayText;
             
            displayText  = <span dangerouslySetInnerHTML={{__html: displayText}}/>;

            let glyph, icon, display = <span>&nbsp;{displayText}</span>;
            if(this.props.current == n) {
              if(this.props.dir > 0) glyph = "long-arrow-up";
              else glyph = "long-arrow-down";
              display = <span><Icon icon={glyph}/>&nbsp;&nbsp;{displayText}</span>;
            }

            let self = this;
              
            return (
              <li key={n} className={className}>
                <Button title={title}
                  onClick={function(){self.onClick(name, n);}}
                  color="tertiary" size="inherit" alt2={true} >
                    {display}
                </Button>
              </li>
            );
              
          })}
        </ul>
    );
  }
}

export default TableHeader;