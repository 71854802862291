'use strict';
import React, { Component } from 'react';
import {Link} from './index.jsx';
import headerlogo from '../theme/svg/MyMetabolon-white.svg';
import './TransitionHeader.scss';


export class TransitionHeader extends Component {

  constructor(props) {
    super(props);
   
  }

  render() {
    const {match} = this.props;
    return (
      <div className="transition-header">
        <div className="main-nav">
          <Link className="logo-container white alt2" to="/home">
            <img src={headerlogo}/>
          </Link>
        </div>
      </div>
    );
  }
}

export default TransitionHeader;