'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Dropdown from './Dropdown.jsx';
import Label from './Label.jsx';
import Button from '../buttons/Button.jsx';
import Hollaback from '../Hollaback.jsx';
import './Select.scss';

export default class Select extends Component {
	static getDisplayKey(option, displayKey) {
		if (typeof displayKey === "string") return option[displayKey];
		return displayKey(option);
	}

	static propTypes = {

		/** Whether a caret is appended to the dropdown label */
		caret: PropTypes.bool,

		/** Whether the control is initally open */
		isOpen: PropTypes.bool,

		/** The color of the dropdown button */
		color: Button.propTypes.color,

		/** The size of the dropdown button */
		size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

		/** Whether the dropdown button is disabled */
		disabled: PropTypes.bool,

		/** Whether the dropdown button is in alt-color mode */
		alt: PropTypes.bool,

		/** Whether the dropdown button is in alt2-color mode */
		alt2: PropTypes.bool,

		/** type attribute applied to the dropdown button */
		type: PropTypes.string,

		/** Invoked when the control is opened */
		onOpen: PropTypes.func,

		/** Invoked when the control is closed */
		onClose: PropTypes.func,

		/** option property to display */
		displayKey: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.func,
		]),

		/** display label when there is no selection */
		defaultLabel: PropTypes.node,

		/** default selection */
		selection: PropTypes.object,

		/** class to apply to option buttons */
		optionClass: PropTypes.string,

		/** class to apply to option buttons */
		optionColor: Button.propTypes.color,

		/** whether to update the dropdown label on selection */
		updateLabel: PropTypes.bool,
	};

	static defaultProps = {
		caret: Dropdown.defaultProps.caret,
		isOpen: Dropdown.defaultProps.isOpen,
		size: Dropdown.defaultProps.size,
		color: Dropdown.defaultProps.color,
		disabled: Dropdown.defaultProps.disabled,
		alt: Dropdown.defaultProps.alt,
		alt2: Dropdown.defaultProps.alt2,
		type: Dropdown.defaultProps.type,
		 
		//onOpen: /* istanbul ignore next */ () => { console.warn('DropdownContainer custom onOpen callback not defined!'); },
		//onClose: /* istanbul ignore next */ () => { console.warn('DropdownContainer custom onClose callback not defined!'); },
		 
		displayKey: 'name',

		defaultLabel: 'Select an option...',
		optionClass: '',
		updateLabel: true,

	};

	constructor(props) {
		super(props);
		const { isOpen, selection } = this.props;
		this.state = this.initState({ isOpen, selection });
	}

	componentDidUpdate(prevProps) {
		if (this.props.selection !== prevProps.selection) {
			this.updateData({ selection: this.props.selection });
		}
	}

	updateData(data) {
		this.setState(data);
	}

	initState(state = {}) {
		if (!('isOpen' in state)) state.isOpen = false;
		state.blurTimer = null;
		state.selection = this.props.selection;
		return state;
	}

	reset(callback) {
		this.setState(this.initState(), callback);
	}

	open() {
		this.setState({ isOpen: !this.state.isOpen }, this.update);
	}

	close = () => {
		this.reset(this.update);
	};

	onClick = () => {
		if (!this.isOpen) this.open();
		else this.close();
	};

	onBlur = () => {
		this.blur();
	};

	onFocus = () => {
		this.focus();
	};

	blur() {
		this.setState({
			blurTimer: setTimeout(this.close, 100)
		});
	}

	focus() {
		let { blurTimer } = this.state;
		if (blurTimer) {
			clearTimeout(blurTimer);
			this.setState({ blurTimer: null });
		}
	}

	update = () => {
		if (this.isOpen && this.props.onOpen) this.props.onOpen();
		else if (this.props.onClose) this.props.onClose();
	};

	get isOpen() {
		return this.state.isOpen;
	}

	generateClassNames() {
		const { className } = this.props;
		let classNames = [this.constructor.name];
		if (className) classNames.push(className);

		return classNames;
	}

	onSelect = (n) => () => {
		const { options, keys } = this.props;
		let selection = options[n];
		if (this.props.onSelect) this.props.onSelect(selection, keys);
		this.setState({ selection });
		this.close();
	};

	renderChildren() {
		const { options, activeClass, defaultLabel, labelColor, displayKey, optionClass, optionColor, color, updateLabel } = this.props;
		const { selection } = this.state;
		let children = options.map((option, n) => {
			const optionLabel = Select.getDisplayKey(option, displayKey);
			return (
				<Button
					key={n + 1}
					onClick={this.onSelect(n)}
					className={classnames(optionClass, option.className, { [activeClass]: selection === option })} color={optionColor}
				>
					{optionLabel}
				</Button>
			);
		});
		if (updateLabel) {
			children.unshift(<Label color={labelColor} key={0}>{selection ? Select.getDisplayKey(selection, displayKey) : defaultLabel}</Label>);
		}
		else {
			children.unshift(<Label color={labelColor} key={0}>{defaultLabel}</Label>);
		}
		return children;
	}

	render() {
		const { caret, color, className, size, children, disabled, alt, alt2, type, title, id } = this.props;
		let dropProps = { caret, color, className, size, children, disabled, alt, alt2, type, title, id };
		return (
			<Dropdown {...dropProps} onBlur={this.onBlur} onFocus={this.onFocus} className={this.generateClassNames().join(' ')} isOpen={this.state.isOpen} onClick={this.onClick}>
				{this.renderChildren()}
			</Dropdown>
		);
	}
}