import React from "react"
import MUIButton from '@mui/material/Button';

export const Button = ({
	variant="contained",
	color="primary",
	...props
}) => {

  return (
    <MUIButton variant={variant} color={color} {...props} />
  )
}
