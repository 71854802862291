'use strict';
/** Component Utilities */
class ComponentUtils {

	/** Returns the element type of the given component instance */
	static getType(instance) {
		if(!instance) return null;
		let type = instance.type;
		if(typeof type === 'function') {
			if('WrappedComponent' in type) type = type.WrappedComponent;
			type = type.name;
		}
		return type;
	}
}

export default ComponentUtils;