'use strict';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import urljoin from 'url-join';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Microscope, BarChart4, Compass, Home } from "lucide-react";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AutoGraph from '@mui/icons-material/AutoGraph';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';

import { usePendo } from '../../hooks/usePendo';
import { useBiovizStatus } from '../../lenses/uilenses/hooks/biovizStatus/biovizStatus.hook';
import * as enums from '../../enums/Enums';
import { COE } from '../routes';
import Sort from '../../utils/Sort';
import SettingsMap from '../../utils/SettingsMap';
import { convertDate } from '../../utils/DateUtils';

import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import { Navigation } from '@/lenses/uilenses/components/Navigation/Navigation';
import { Button, Icon, Link, Toast } from '../../components/index';

import ProjectFiles from './files/ProjectFiles';
import PathwayExplorer from './pathways/PathwayExplorer';
//import PublishingContainer2 from '../admin/PublishingCenter/PublishingContainer_';
import PublishingContainer from '../admin/PublishingCenter/PublishingContainer';
import Heatmap from './Heatmap/Heatmap';
import { ManifestUpload } from '../../views/manifestUpload/ManifestUpload';
import { ClientOnboarding } from '../clientOnboarding/ClientOnboarding';
import ProjectHome from './Home/ProjectHome';

import '../../theme/theme.css';
import '../../theme/css/material-icons.css';
import './ProjectContainer.scss';
import { SpectralData } from '../spectralData/SpectralData';
import { useFetchSpectralMetaDataQuery } from '../../redux/services/spectralData.api';
import { usePermissions } from '../../hooks/permissions.hook';
import { useCurrentUser } from '../../redux/services/user.api';

export const Project = ({
	className,
	match,
	loading,
	adminProjectData,
	displayHeader,
	displayProjectId,
	displayTitle,
	enablePublishingButton,
	errormsg,
	lastUpdateDate,
	noAccessMessage,
	project,
	projectCode,
	projectId,
	projectEmptyState,
	isLoading,
	publishPreviewMode,
	publishingStatus,
	publishSummaryLoading,
	showPublishButton,
	showPublishingDialog,
	PublishIndicatorLoading,
	RefreshIndicatorPublishButtonLoading,
	onClickPublishingMode,
	clickPublishModal,
	onClickExitPreview,
	showToaster,
	sampleSets,
	sampleSetIndex,
	statsId,
	plants,
	animals,
	onClickPreview
}) => {
	const pendo = usePendo();
	const currentUser = useCurrentUser();
	const { status: biovizStatus, href } = useBiovizStatus(projectId);
	const { data: spectralData } = useFetchSpectralMetaDataQuery(projectId, { skip: !projectId});
	const hasSpectralData = useMemo(() => spectralData?.hasSpectralDataProject || false, [spectralData]);
	const location = useLocation();
	const { checkUserHasOneRole, checkUserHasRole } = usePermissions();

	const showCOE = useMemo(() => {
		if (projectEmptyState) return true;
		if (isLoading || !project || (project && project.lastPublishDate)) return false;
		if (checkUserHasRole([enums.ROLES.ADMIN, enums.ROLES.DTS])) return true;
		return false;
	}, [project, isLoading, projectEmptyState]);

	const items = useMemo(() => [
		{
			items: [
				{ icon: Home, label: 'Project Home', active: false, target: "_self", href: match.url, exact: true },
				{ icon: Microscope, label: 'Results', active: false, target: "_self", href: urljoin(match.url, "results"), willRender: !hasSpectralData },
				{ icon: Microscope, label: 'Results', active: false, target: "_self", href: urljoin(match.url, "results"), willRender: hasSpectralData, children: [
					{ icon: FilePresentOutlinedIcon, label: 'Deliverables', active: false, target: "_self", href: urljoin(match.url, "results") },
					{ icon: FolderZipOutlinedIcon, label: 'Spectral Data', active: false, target: "_self", href: urljoin(match.url, "spectral-data") }
				]},
				{ icon: BarChart4, label: 'Stats Table', active: false, target: "_self", href: urljoin(match.url, "statstable") },
				{ icon: Compass, label: 'Pathway Explorer', active: false, target: "_self", href: urljoin(match.url, "pathwayexplorer") },
				{ icon: ReceiptLongIcon, label: 'Client Onboarding', active: false, target: "_self", href: urljoin(match.url, COE), willRender: showCOE },
			],
		},
		{
			items: [
				{ icon: AutoGraph, label: 'Bioinformatics Tools', external: true, active: false, target: "_self", href, willRender: biovizStatus }
			],
		}
	], [match.url, biovizStatus, projectId, href, showCOE, hasSpectralData]);

	useEffect(() => {
		pendo.identify(match.params.projectCode);
		return () => {
			pendo.identify();
		};
	}, [match.params.projectCode]);

	const visibleSampleSets = sampleSets?.concat().filter(sampleSet => sampleSet.customerVisible);
	//update VisibleStats
	const visibleSampleSetsUpdated = visibleSampleSets?.map(sampleSet => {
		const temp = { ...sampleSet };
		temp.stats = sampleSet.stats.filter(stat => stat.customerVisible).sort(Sort.numeric('customerIndex', 1));
		return temp;
	});

	if (projectEmptyState === undefined) return <LoadingSpinner />;

	if (location?.pathname.indexOf(COE) < 0 && projectEmptyState && (checkUserHasOneRole(enums.ROLES.BASIC) || checkUserHasRole(enums.ROLES.COE))) {
		return <Redirect to={`/home/project/${match.params.projectCode}/${COE}`} />;
	}

	return (
		<>
			{location?.pathname.indexOf(COE) < 0 && <Navigation className="nav" items={items} sx={{ zIndex: 2 }} />}
			<div className={classNames("ProjectContainer", { wide: location?.pathname.indexOf(COE) >= 0 }, className)}>
				{(publishPreviewMode && showPublishingDialog) ? <div className="publishingFrame"><div>Publishing ... {PublishIndicatorLoading()}</div></div> : <div />}
				{publishPreviewMode ? <div className="Banner">
					<div className="BannerFunctions">
						<label>{project.projectCode}</label>
						<div className="BannerActions">
							<Button color={'white'} alt2={true} onClick={onClickExitPreview} ><Icon size={'medium'} icon={'times'} />&nbsp;Exit Preview</Button>

							{publishSummaryLoading ? RefreshIndicatorPublishButtonLoading() :
								<Button className="PublishButton" color={'white'} alt={true} title="" onClick={clickPublishModal('test')}>
									<Icon size={'medium'} icon={'check'} />&nbsp;
									Confirm &#38; Publish
								</Button>
							}

						</div>
					</div>
				</div> : <div />}
				<Switch>
					<Route path={urljoin(match.url, COE)} ><Link className="backToProjects tertiary alt2" to="/home">Back to Projects</Link></Route>
				</Switch>
				<CodeDisplay projectId={project.projectId} projectCode={project.projectCode} displayProjectId={displayProjectId} showToaster={showToaster} />

				<div className="projectHeader">
					<div className="title" >{displayTitle}</div>
					{displayHeader && (showPublishButton && !publishPreviewMode) ?
						<div className="EditFunctions" >
							<div>Publishing Status: {publishingStatus}</div>
							<span className="lastPublishDate">Last Published: {convertDate(project.lastPublishDate, 'MMM DD YYYY')}<br />
								Last Data Refresh: {convertDate(lastUpdateDate, 'MMM DD YYYY')} </span>

							<Button color={'quinary'} disabled={!enablePublishingButton} alt2={true} onClick={onClickPublishingMode} >
								<Icon size={'large'} color={'quinary'} icon={'podcast'} />&nbsp;
								Publishing
							</Button>

							<div className="errormsg">{errormsg}</div>
							<div className="errormsg">{noAccessMessage}</div>
						</div>
						: <div />}
				</div>

				{publishPreviewMode && publishSummaryLoading && RefreshIndicatorPublishButtonLoading()
				}
				<Switch>
					<Route path={match.url} exact>
						{sampleSets ? <ProjectHome sampleSets={sampleSets} match={match} /> : <LoadingSpinner />}
					</Route>
					<Route path={urljoin(match.url, "results")}>
						<ProjectFiles publishPreviewMode={publishPreviewMode} project={project} projectId={projectId} />
					</Route>
					<Route path={urljoin(match.url, "statstable")}>
						{visibleSampleSetsUpdated ?
							<Heatmap
								webWorker={true}
								publishPreviewMode={publishPreviewMode}
								project={project}
								sampleSets={visibleSampleSetsUpdated}
								sampleSetIndex={sampleSetIndex}
								statsId={statsId}
							/> :
							<LoadingSpinner />}
					</Route>
					<Route path={urljoin(match.url, "pathway-legacy")}>
						{((!visibleSampleSetsUpdated || loading || !(animals && plants)) ?
							<div className="PathwayExplorer"><LoadingSpinner /></div> :
							<PathwayExplorer
								webWorker={true}
								publishPreviewMode={publishPreviewMode}
								foldChangeScale={SettingsMap.layers.foldChangeScale}
								project={project}
								sampleSets={visibleSampleSetsUpdated}
								sampleSetIndex={sampleSetIndex}
								statsId={statsId}
								plants={plants}
								animals={animals}
							/>
						)}
					</Route>
					<Route path={urljoin(match.url, "pathwayexplorer")}>
						{((!visibleSampleSetsUpdated || loading || !(animals && plants)) ?
							<div className="PathwayExplorer"><LoadingSpinner /></div> :
							<PathwayExplorer
								webWorker={true}
								fireWorksLayout="d3"
								adminProjectData={adminProjectData}
								publishPreviewMode={publishPreviewMode}
								foldChangeScale={SettingsMap.layers.foldChangeScale}
								project={project}
								sampleSets={visibleSampleSetsUpdated}
								sampleSetIndex={sampleSetIndex}
								statsId={statsId}
								plants={plants}
								animals={animals
								} />
						)}
					</Route>

					<Route path={urljoin(match.url, "publishingcenter")}>
						<PublishingContainer currentUser={currentUser} onClickPreview={onClickPreview} project={project} projectCode={match.params.projectCode} />
					</Route>
					{/* <Route path={urljoin(match.url, "publishingcenter2")}>
						<PublishingContainer2 currentUser={currentUser} onClickPreview={onClickPreview} project={project} projectCode={match.params.projectCode} />
					</Route> */}
					<Route path={urljoin(match.url, "manifest-upload")}>
						<ManifestUpload projectCode={projectCode} />
					</Route>
					<Route path={urljoin(match.url, COE)}>
						{projectId && <ClientOnboarding match={match} projectCode={projectCode} projectId={projectId} />}
					</Route>
					<Route path={urljoin(match.url, "spectral-data")}>
						{projectId && <SpectralData match={match} projectCode={projectCode} projectId={projectId} />}
					</Route>
				</Switch>
				<Toast />
			</div>
		</>
	);
};

const CodeDisplay = ({ projectCode, projectId, displayProjectId, showToaster, ...props }) => {

	const [isHovering, setIsHovering] = useState(false);
	const { checkUserHasRole } = usePermissions();

	const onHover = useCallback(() => {
		setIsHovering(!isHovering);
	});

	const copyToClipboard = useCallback(() => {
		if (!checkUserHasRole(enums.ROLES.DTS)) { return; }
		let text = projectId;
		showToaster("Copying ProjectId ...", projectId);
		let dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = projectId;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	}, [projectId]);

	return (
		<div onClick={copyToClipboard} className="projCode" onMouseEnter={onHover} onMouseLeave={onHover}>
			{projectCode} &nbsp; {isHovering ? displayProjectId : ''}

		</div>
	);
};