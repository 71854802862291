import React, { useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ascending, descending, mean } from "d3";
import { dataSelector } from "../../../redux/selectors/data.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import * as styles from "./stats.module.scss";
import Heatmap from "./Heatmap";
import Legend from "../../containers/Legend";
import { Box } from "@mui/material";

const Stats = () => {
  const { results } = useSelector(dataSelector);
  const selectedState = useSelector(interactionsSelector);

  const resultsData = useMemo(() => {
    const filteredResults = results.filter(
      (d) => d.chemical_id === selectedState.id
    );

    let percentFillArray = [];
    if (filteredResults[0].detected) {
      filteredResults.map((d) =>
        d.percent_fill.map((p) => percentFillArray.push(p.percent_fill))
      );
    }
    const mostSig = filteredResults.sort((a, b) =>
      ascending(a.p_value, b.p_value)
    )[0];

    const largestUpReg = filteredResults.sort((a, b) =>
      descending(a.fold_change, b.fold_change)
    )[0];

    const largestDownReg = filteredResults.sort((a, b) =>
      ascending(a.fold_change, b.fold_change)
    )[0];

    return {
      allResults: filteredResults,
      detected: filteredResults[0].detected,
      mostSigComparison: mostSig.comparison,
      mostSigPValue: mostSig.pValueDisplay,
      largestUpRegComparison: largestUpReg.comparison,
      largestUpRegFold: largestUpReg.foldChangeDisplay,
      largestDownRegComparison: largestDownReg.comparison,
      largestDownRegFold: largestDownReg.foldChangeDisplay,
      percentFill: mean(percentFillArray),
      main_effects: filteredResults[0].main_effects,
    };
  }, [selectedState.id, results]);

  const heatmapWrapper = useRef();
  const [heatmapWidth, setHeatmapWidth] = useState(0);
  const [heatmapHeight, setHeatmapHeight] = useState(0);

  const resizeHeatmap = () => {
    if (heatmapWrapper.current) {
      setHeatmapHeight(heatmapWrapper.current.offsetHeight * 0.8);
      setHeatmapWidth(heatmapWrapper.current.offsetWidth);
    }
  };

  useEffect(() => {
    if (resultsData.detected && heatmapWrapper.current) resizeHeatmap();
    window.addEventListener("resize", resizeHeatmap);
    return () => window.removeEventListener("resize", resizeHeatmap);
  }, [resultsData.detected, heatmapHeight]);

  if (!resultsData.detected) {
    return (
      <p style={{ fontStyle: "italic" }}>
        This metabolite was not detected in the study.
      </p>
    );
  }

  if (resultsData.detected) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.stats}>
          <div className={styles.comparisons} ref={heatmapWrapper}>
            <div className={styles.infoWrapper}>
              <h3>Comparisons</h3>
              <div className={styles.infoGroup}>
                <Heatmap
                  data={resultsData}
                  heatmapWidth={heatmapWidth}
                  heatmapHeight={heatmapHeight}
                />
              </div>
            </div>
          </div>
          <div className={styles.highlights}>
            <div className={styles.infoWrapper}>
              <h3>Highlights</h3>
              <div className="flex-sm-screen">
                <div className={styles.infoGroup}>
                  <h5>Most Significant</h5>
                  <p>{resultsData.mostSigComparison}</p>
                  <p className="ban-sm">
                    {resultsData.mostSigPValue < 0.001
                      ? "<0.001"
                      : resultsData.mostSigPValue}
                  </p>
                </div>
                <div className={styles.infoGroup}>
                  <h5>Largest Fold Increase</h5>
                  <p className={styles.metabolonRed}>
                    {resultsData.largestUpRegComparison}{" "}
                  </p>
                  <p className={styles.metabolonRed + " ban-sm"}>
                    {resultsData.largestUpRegFold}
                  </p>
                </div>
                <div className={styles.infoGroup}>
                  <h5>Largest Fold Decrease</h5>
                  <p className={styles.metabolonBlue}>
                    {resultsData.largestDownRegComparison}
                  </p>
                  <p className={styles.metabolonBlue + " ban-sm"}>
                    {resultsData.largestDownRegFold}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.infoWrapper}>
              <h3>Detection</h3>
              <div className="flex-sm-screen">
                <div className={styles.infoGroup}>
                  <h5>Percent Fill</h5>
                  <p>Average</p>
                  <p className="ban-sm">{resultsData.percentFill.toFixed(2)}%</p>
                </div>
                <div className={styles.infoGroup}>
                  <h5>Main Effects</h5>
                  {resultsData.main_effects.map((d, i) => {
                    return (
                      <div key={i}>
                        <p className="bottom-margin-sm">{d.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Legend
            size={true}
            color={true}
            colorWidth="155px"
            stack="horizontal"
            margin="0 0 2rem"
            colorMargin="0 1.5rem 0 "
          />
        </Box>
      </div>
    );
  }
};

export default Stats;
