'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import LandingHeader from '../landing/LandingHeader.jsx';
import LandingFooter from '../landing/LandingFooter.jsx';
import { getPathwayAnimalMap, getPathwayPlantMap, putPathwayPlantMap, putPathwayAnimalMap, fetchPathwayAnimalMapData, fetchPathwayPlantMapData } from '../../redux/actions/pathwayMapData';
import { Button, Filedrop, NavBar, NavButton } from '../../components/index.jsx';
import FileUtils from '../../utils/FileUtils.js';
import PathwayMapViewer from './PathwayMapViewer.jsx';
import '../landing/LandingContainer.scss';

const attMap = {
	'ChemicalId': { name: 'chemicalId', type: 'integer' },
	'ClassId': { name: 'classId', type: 'integer' },
	'CompId': { name: 'compoundId', type: 'integer' },
	'CompoundType': 'compoundType',
	'CoreElement': 'coreElement',
	'EnzymeName': 'enzymeName',
	'InStudy': 'inStudy',
	'MetabolonChemical': 'metabolonChemical',
	'PathwayName': 'pathwayName',
};

const nodeMap = {
	w: 'width',
	h: 'height',
	'cy:borderLineType': 'border_style',
	'cy:nodeLabelFont': 'font_family',
	'cy:nodeLabel': 'content',
	width: 'border_width',
	outline: 'border_color',
	fill: 'background_color',
	'cy:nodeTransparency': 'background_opacity',
};

const edgeMap = {
	'cy:edgeLineType': 'line_style',
	'cy:edgeLabelFont': 'font_family',
	'cy:edgeLabel': 'content',
	'cy:curved': 'curve_style',
	'cy:sourceArrow': 'source_arrow_shape',
	'cy:targetArrow': 'target_arrow_shape',
	'cy:sourceArrowColor': 'source_arrow_color',
	'cy:targetArrowColor': 'target_arrow_color',
	fill: 'line_color',
	'cy:nodeTransparency': 'opacity',
};

const curveStyleMap = {
	CURVED_LINES: 'unbundled-bezier',
	STRAIGHT_LINES: 'haystack'
};

const lineStyleMap = {
	SOLID: 'solid',
	DOT: 'dotted',
	DASH_DOT: 'dashed',
	CONTIGUOUS_ARROW: 'dashed',
};

const fontWeightMap = {
	1: 'normal',
	3: 'bold',
};

const arrowMap = {
	0: 'none',
	3: 'triangle',
	6: 'triangle-tee'
};

export class UtilContainer extends Component {

	constructor(props) {
		super(props);
		let reader = new FileReader();

		this.state = {
			featureIndex: 0,
			reader,
			lastFile: null,
		};
	}

	onFeatureSelect = n => {
		this.setState({ featureIndex: n });
	};

	onPlantMapdrop = (fileDict) => {
		const { reader } = this.state;
		reader.onload = this.onPlantRead;
		Object.keys(fileDict).forEach((filename) => {

			let file = fileDict[filename];
			//console.log(filename, file);
			delete fileDict[filename];
			this.setState({ lastFile: filename });
			reader.readAsText(file);
		});
	};

	onPlantRead = async () => {
		const { reader, lastFile } = this.state;
		const response = await this.props.putPlantMap({ filename: lastFile, data: JSON.parse(reader.result), version: 1 });

		//console.log(response);
	};

	onAnimalMapdrop = (fileDict) => {
		const { reader } = this.state;
		reader.onload = this.onAnimalRead;
		Object.keys(fileDict).forEach((filename) => {

			let file = fileDict[filename];
			//console.log(filename, file);
			delete fileDict[filename];
			this.setState({ lastFile: filename });
			reader.readAsText(file);
		});
	};

	onAnimalRead = async () => {
		const { reader, lastFile } = this.state;
		const response = await this.props.putAnimalMap({ filename: lastFile, data: JSON.parse(reader.result), version: 1 });
		//console.log(response);
	};

	onFetchAnimalData = (e) => {
		this.props.fetchAnimalData().then(res => {
			let data = res.response;
			FileUtils.download(JSON.stringify(data, null, '\t'), data.pathwayType + '-MAP-DATA.json', 'json');
		});
	};

	onFetchPlantData = (e) => {
		this.props.fetchPlantData().then(res => {
			let data = res.response;
			FileUtils.download(JSON.stringify(data, null, '\t'), data.pathwayType + '-MAP-DATA.json', 'json');
		});
	};

	onFetchAnimalMap = (e) => {
		const { reader } = this.state;
		reader.onload = () => {
			let data = JSON.parse(reader.result);
			//console.log(data);

			FileUtils.download(JSON.stringify(data, null, '\t'), data.data.name);
		};

		this.props.fetchAnimalMap().then(res => {
			reader.readAsText(res);
		});
	};

	onFetchPlantMap = (e) => {
		const { reader } = this.state;
		reader.onload = () => {
			let data = JSON.parse(reader.result);
			//console.log(data);

			FileUtils.download(JSON.stringify(data, null, '\t'), data.data.name);
		};

		this.props.fetchPlantMap().then(res => {
			reader.readAsText(res);
		});
	};

	onFiledrop = (fileDict) => {
		const { reader } = this.state;
		reader.onload = this.onFileRead;
		Object.keys(fileDict).forEach((filename) => {
			//console.log(filename, fileDict[filename]);
			this.setState({ lastFile: filename });
			reader.readAsText(fileDict[filename]);
		});
		//console.log(Object.keys(fileDict));
	};

	onFileRead = () => {
		const { lastFile, reader } = this.state;

		let parts = lastFile.split('.');
		let ext = parts[parts.length - 1].toLowerCase();
		let data;
		//console.log(lastFile, ext);

		switch (ext) {
			case 'xgmml':
				data = this.parseXGMML(reader.result);
				break;

			default:
			case 'json':
				data = this.parseJSON(reader.result);
				break;
		}

		FileUtils.download(JSON.stringify(data, null, '\t'), data.data.name, 'json');
	};

	parseXGMML(fileData) {
		let parser = new DOMParser();
		let xml = parser.parseFromString(fileData, "text/xml");
		//console.log(xDOM);

		this.nodes = {};
		let nodeList = Array.from(xml.querySelectorAll('node')),
			edgeList = Array.from(xml.querySelectorAll('edge'));

		let nodes = nodeList.map(this.parseXGMNode),
			edges = edgeList.map(this.parseXGMNode);

		let data = {
			format_version: "1.0",
			generated_by: "metabolon-aperture",
			target_cytoscapejs_version: "~3.2.2",
			data: {
				name: xml.querySelector('graph').getAttribute('label'),
				shared_name: xml.querySelector('graph').getAttribute('label'),
				selected: true
			},
			elements: {
				nodes, edges
			}
		};
		//console.log(data);
		return data;
	}

	parseJSON(fileData) {
		let json = JSON.parse(fileData);

		this.nodes = {};
		this.edges = {};
		this.voids = { nodes: [] };

		this.locations = {};
		this.dupeMap = {};

		this.pathways = json.pathwayNodes.reduce(this.parseJSONNode, {});
		this.pathways = json.pathwayEdges.reduce(this.parseJSONEdge, this.pathways);
		this.pathways = this.resolveVoids(this.pathways);

		let data = {
			data: {
				name: `map-${json.pathwayType.toLowerCase()}-v6.json`,
				date: dayjs().format('L LT')
			},
			pathways: this.pathways
		};

		//console.log(Object.keys(this.pathways));

		return data;
	}

	/*
		static structures
			node dictionary
			edge dictionary ?
			pathway dictionary
			map of duplicate ids to original

		organize nodes/edges by pathwayName
		define duplicate node as having the same 
			canonicalName and x/y. chemicalId? yes.
	
		Store each node by id, map ids to x/y values
		when the first dupe is found, establish the existing as the original by...
			map the new id to the original

		For each edge, source and targets must be checked against the dupe table,
			reassign lookup values ids are found in dupe table 

		
		# SPECIAL NOTES
		Unlike direct xgmml exports, the Metabosync JSON has the following artifacts
		
		## VOID NODES
		Nodes with a compoundType of `void` are "middleman" nodes that typically have edges
		routed through what would otherwise be a valid connection between these nodes. They
		present as duplicates, having the same canonical names and coordinates, however they
		should NOT be combined. Instead these nodes should have their children parsed and
		connected directly. The void nodes themselves should not be included in the parsed data

		## DUPLICATE NODES
		Non-void nodes with the same canonical name and coordinates are dupiicates that should
		have all of their edges routed through a single instance of the node. The reest of the
		duplicates should not be included in the parsed data
	*/

	parseJSONNode = (pathways, node) => {
		this.nodes[node.nodeId] = node;

		let { pathwayName, x, y } = node;
		if (!(pathwayName in pathways)) {
			pathways[pathwayName] = { elements: { nodes: [], edges: [] } };
			this.locations[pathwayName] = { coords: {} };
		}
		let location = this.locations[pathwayName];

		let xy = x + ',' + y;
		//below code finds and combines duplicate nodes

		//not a dupe if the coord is empty, ignore void dupse
		if (this.nodeIsVoid(node) || !(xy in location.coords)) {
			location.coords[xy] = [node.nodeId];
			return this.releaseNode(node, pathways);
		}
		else {
			//may be a dupe! compare to existing xy's
			let existing = location.coords[xy];
			let len = existing.length;
			let original = null;

			//console.log("dupe?", node, existing);

			for (let i = 0; i < len; i++) {
				let o = this.nodes[existing[i]];
				//dupe iff chemicalId and canonicalName match!
				if (o.canonicalName == node.canonicalName &&
					o.chemicalId == node.chemicalId) {
					original = o.nodeId;
					break;
				}
			}
			//add to coord array if no exisiting node is found
			if (!original) location.coords[xy].push(node.nodeId);

			return this.releaseNode(node, pathways, original);
		}
	};

	releaseNode(node, pathways, dupeId = null, voidCheck = true) {
		let { x, y, nodeId, pathwayName, edges, connections, ...data } = node;

		if (dupeId) {
			this.dupeMap[node.nodeId] = dupeId;
		}
		else if (this.nodeIsVoid(node)) {
			if (voidCheck) {
				node.connections = [];
				this.voids.nodes.push(node.nodeId);
			}
		}
		else {
			data.id = nodeId;
			data.pathwayName = pathwayName;
			let n = {
				data,
				position: { x, y }
			};

			pathways[pathwayName].elements.nodes.push(n);
		}

		return pathways;
	}

	parseJSONEdge = (pathways, edge, voidCheck = true) => {
		//reassign edges to root duplicate nodes
		edge.pathwayNode1Id = this.dupeMap[edge.pathwayNode1Id] || edge.pathwayNode1Id;
		edge.pathwayNode2Id = this.dupeMap[edge.pathwayNode2Id] || edge.pathwayNode2Id;

		this.edges[edge.edgeId] = edge;

		if (this.edgeIsVoid(edge)) {
			if (voidCheck) {
				// when a void edge is encountered, and only one of the connected nodes is void
				// associate the edgeId and the non-void nodeId with the void node
				let void1 = this.voids.nodes.indexOf(edge.pathwayNode1Id) >= 0 ? edge.pathwayNode1Id : null;
				let void2 = this.voids.nodes.indexOf(edge.pathwayNode2Id) >= 0 ? edge.pathwayNode2Id : null;

				if (!(void1 && void2) && (void1 || void2)) {
					let voidId = void1 ? edge.pathwayNode1Id : edge.pathwayNode2Id;
					let otherId = void1 ? edge.pathwayNode2Id : edge.pathwayNode1Id;

					let voidNode = this.nodes[voidId];
					voidNode.connections.push({ edgeId: edge.edgeId, nodeId: otherId });
				}
			}
			return pathways;
		}
		return this.releaseEdge(edge, pathways);
	}

	releaseEdge(edge, pathways) {

		if (edge.pathwayNode1Id === edge.pathwayNode2Id) {
			edge['curve_style'] = 'bezier';
		}
		else if (!(edge.handleX == 0 && edge.handleY == 0)) {
			let x = edge.handleX,
				y = edge.handleY,
				{ x: p1x, y: p1y } = this.nodes[edge.pathwayNode1Id],
				{ x: p2x, y: p2y } = this.nodes[edge.pathwayNode2Id],
				control = this.convertHandle(x, y, { x: p1x, y: p1y }, { x: p2x, y: p2y });

			edge['control_point'] = control.controlPoint;
			edge['control_point_distances'] = control.distances;
			edge['control_point_weights'] = control.weights;
		}

		let { edgeId, pathwayName, pathwayNode1Id, pathwayNode2Id, ...data } = edge;

		data.id = edgeId;
		data.source = pathwayNode1Id;
		data.target = pathwayNode2Id;
		data.pathwayName = pathwayName;

		let e = {
			data
		};

		pathways[pathwayName].elements.edges.push(e);
		return pathways;
	}

	resolveVoids(pathways) {
		/**
			Loop through void nodes and connect their children directly where applicable
		*/
		let edges = [];
		this.voids.nodes.forEach((nodeId, n) => {
			let node = this.nodes[nodeId];
			if (node.connections.length < 2) return;
			let edge = this.edges[node.connections[0].edgeId];
			let replaceId = edge.pathwayNode1Id == nodeId ? 'pathwayNode1Id' : 'pathwayNode2Id';
			edge[replaceId] = node.connections[1].nodeId;
			pathways = this.parseJSONEdge(pathways, edge, false);
		});

		return pathways;
	}

	nodeIsVoid(node) {
		return node.compoundType.toLowerCase() === "void";
	}

	edgeIsVoid(edge) {
		return this.voids.nodes.indexOf(edge.pathwayNode1Id) >= 0 || this.voids.nodes.indexOf(edge.pathwayNode2Id) >= 0;
	}

	parseXGMNode = (node) => {
		let nodeData = {};
		let attr = node.attributes;
		let data = {};
		for (let i = 0; i < attr.length; i++) {
			let attribute = attr.item(i);
			data[attribute.nodeName.replace(' ', '_')] = attribute.nodeValue;
		}
		if (node.nodeName === 'node') this.nodes[data.id] = nodeData;

		if (node.hasChildNodes()) {
			let children = node.childNodes;

			for (let i = 0; i < children.length; i++) {
				let child = children.item(i);

				if (child.nodeName === 'att') {
					let attName = child.getAttribute('name');
					let type = child.getAttribute('type');
					if (attName in attMap) {
						let mapper = attMap[attName];
						if (typeof mapper === "string") attName = mapper;
						else {
							attName = mapper.name;
							type = mapper.type;
						}
					}
					let val = child.getAttribute('value');
					switch (type) {
						default:
						case 'string':
							break;
						case 'boolean':
							val = Boolean(val);
							break;
						case 'integer':
						case 'real':
							val = val == "" ? null : Number(val);
							break;
					}
					data[attName.replace(' ', '_')] = val;
				}
				else if (child.nodeName === 'graphics') {
					let style = data;
					let position = {};
					let attr = child.attributes;
					for (let i = 0; i < attr.length; i++) {
						let attribute = attr.item(i),
							name = attribute.nodeName,
							map = node.nodeName === 'node' ? nodeMap : edgeMap;
						name = map[name] || name.replace(' ', '_');
						if (name === 'type') {
							style.shape = attribute.nodeValue.toLowerCase();
						}
						else if (name === 'curve_style') {
							style[name] = curveStyleMap[attribute.nodeValue] || attribute.nodeValue.toLowerCase();
						}
						else if (name === 'line_style') {
							style[name] = lineStyleMap[attribute.nodeValue] || attribute.nodeValue.toLowerCase();
						}
						else if (name === 'source_arrow_shape' || name === 'target_arrow_shape') {
							style[name] = arrowMap[attribute.nodeValue] || attribute.nodeValue;
						}
						else if (['width', 'height', 'opacity', 'border_width', 'background_opacity', 'border_opacity'].indexOf(name) >= 0) {
							style[name] = Number(attribute.nodeValue);
						}
						else if (name === 'font_family') {
							let parts = attribute.nodeValue.split('-');
							style[name] = parts[0];
							if (parts.length >= 2) style['font_weight'] = fontWeightMap[parts[1]] || 'normal';
							if (parts.length >= 3) style['font_size'] = Number(parts[2]);
						}
						else if (name === 'x' || name === 'y') {
							position[name] = Number(attribute.nodeValue);
						}
						else style[name] = attribute.nodeValue;
					}
					//data.styles = style;
					nodeData.position = position;
					//nodeData.selected = false;

					//pull edgeBend data from graphics node
					if (node.nodeName === 'edge' && child.hasChildNodes()) {
						let gChildren = child.childNodes;
						let edgeBend = child.childNodes.item(1).childNodes.item(1);
						//console.log(edgeBend);
						let x = Number(edgeBend.getAttribute('x')),
							y = Number(edgeBend.getAttribute('y')),
							p1 = this.nodes[data.source].position,
							p2 = this.nodes[data.target].position,
							control = this.convertHandle(x, y, p1, p2);

						style['control_point'] = control.controlPoint;
						style['control_point_distances'] = control.distances;
						style['control_point_weights'] = control.weights;
						//console.log(p1, p2);
					}
				}
			}
		}
		nodeData.data = data;

		return nodeData;

	}

	convertHandle(x, y, p1, p2) {
		let cx = (p1.x + p1.x) / 2, //center
			cy = (p1.y + p2.y) / 2,
			//distance from p1 to p2
			d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)),
			//perpindicular distance from line to anchor
			pd = Math.abs((p2.y - p1.y) * x - (p2.x - p1.x) * y + p2.x * p1.y - p2.y * p1.x) / d,

			//find point of intersection
			//slope of line
			m = (p2.y - p1.y) / (p2.x - p1.x);
		//inverse slope of line

		//line equ: 
		//yi-p1.y = m*(xi-p1.x)
		//yi = m*(xi-p1.x) + p1.y
		//perpin line equ:
		//yi-y = mi(xi-x)
		//yi = mi*(xi-x) + y
		//y = ax +c = bx + d
		//so then...
		//yi = m*(xi-p1.x) + p1.y = mi*(xi-x) + y
		//xi*m - p1.x*m + p1.y = xi*mi - x*mi + y
		//xi(m-mi) =  m*p1.x - p1.y -mi*x + y 
		//===>
		let xi, yi, mi, di;
		if (m == 0 || m === 0) {
			xi = x;
			yi = p1.y;
			mi = 0;

		}
		else if (m === Infinity || m === -Infinity || m == null || isNaN(m)) {
			xi = p1.x;
			yi = y;
			mi = 0;
		}
		else {
			//inverse slope of line
			mi = -1 / m;
			xi = (m * p1.x - p1.y - mi * x + y) / (m - mi);
			yi = m * (xi - p1.x) + p1.y;
			pd *= Math.abs(m) / m;
		}

		//distance from p1 to intersection
		let d1 = Math.sqrt(Math.pow(xi - p1.x, 2) + Math.pow(yi - p1.y, 2)),
			//distance from p2 to intersection
			//d2 = Math.sqrt(Math.pow(xi-p2.x, 2) + Math.pow(yi-p2.y, 2)),
			//weight calculation
			w = d1 / d;

		if (isNaN(w)) w = 0.5;

		let controlPoint = {
			x, y, d, d1, m, mi, xi, yi
		};
		let distances = `${pd}`;
		let weights = `${w}`;

		return { controlPoint, distances, weights };
	}

	render() {
		let tabs = [
			(<div className="tab-content row" key={0}>
				<div className="col-md-12">
					<Filedrop onDrop={this.onFiledrop} body="xgmml or json to convert to Cytoscape JSON" />
				</div>
			</div>),
			(<div className="tab-content row" key={1}>
				<div className="col-md-12">
					<Filedrop onDrop={this.onPlantMapdrop} body="Plant JSON upload" />
				</div>
			</div>),
			(<div className="tab-content row" key={2}>
				<div className="col-md-12">
					<Filedrop onDrop={this.onAnimalMapdrop} body="Animal JSON upload" />
				</div>
			</div>),
			(<div className="tab-content row" key={3}>
				<Button className="col-md-6" onClick={this.onFetchAnimalData}>Fetch Animal data</Button>
				<Button className="col-md-6" onClick={this.onFetchAnimalMap}>Fetch Animal map</Button>
				<Button className="col-md-6" onClick={this.onFetchPlantData}>Fetch Plant data</Button>
				<Button className="col-md-6" onClick={this.onFetchPlantMap}>Fetch Plant map</Button>
			</div>),
			(<div className="tab-content row" key={2}>
				<div className="col-md-12">
					<PathwayMapViewer />
				</div>
			</div>),
		];

		return (
			<div className="LandingContainer app-content">
				<LandingHeader auth={this.props.auth} />
				<div className="content-wrapper">
					<div className="content-3">
						<NavBar size="normal" onClick={this.onFeatureSelect}>
							<NavButton>JSON or XGMML to CYJSON</NavButton>
							<NavButton>Upload Plant Map JSON</NavButton>
							<NavButton>Upload Animal Map JSON</NavButton>
							<NavButton>Animal and Plant Downloads</NavButton>
							<NavButton>Pathway Map Viewer</NavButton>
						</NavBar>
						<div className="selected-feature">
							{tabs[this.state.featureIndex]}
						</div>
					</div>
				</div>
				<LandingFooter />
			</div>
		);
	}
}

//export default withRouter(UtilContainer);
//export default UtilContainer;
/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchPlantMap: () => dispatch(getPathwayPlantMap()),
		fetchAnimalMap: () => dispatch(getPathwayAnimalMap()),
		fetchPlantData: () => dispatch(fetchPathwayPlantMapData()),
		fetchAnimalData: () => dispatch(fetchPathwayAnimalMapData()),
		putPlantMap: (payload) => dispatch(putPathwayPlantMap(payload)),
		putAnimalMap: (payload) => dispatch(putPathwayAnimalMap(payload))
	};
};

export default (connect(null, mapDispatchToProps)(UtilContainer));