'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './Material.scss';

/** A simple icon component using material */
const Material = ({ icon, color, hoverColor, size, disabled, className, ...restOfProps}) => {

	let generateClassNames = (base) => {
		let classNames = [color, size];
		classNames.unshift(base);
		if(className) classNames.unshift(className);
		if(hoverColor) classNames.push('hover-'+hoverColor);
		if(disabled) classNames.push('disabled');

		return classNames;
	};

	return (
		<i {...restOfProps} className={`${generateClassNames('Material').join(' ')}`}>
			{icon}
		</i>
	);
};

Material.propTypes = {

	/** The material icon to use */
	icon: PropTypes.string,//PropTypes.oneOf(['none', 'star','user']),

	/** The color of button */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info','white','selectedItem', 'inactive']),

	/** The size of the button */
	//size: PropTypes.oneOf(Theme.sizeList),
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	/** Hover Color */
	hoverColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info','white', 'inactive'])
};

Material.defaultProps = {
	icon: 'face', 
	size: 'normal',
};

export default Material;