import { useEffect, useState } from "react";

export const useBrowserInfo = () => {
  const [browser, setBrowser] = useState("Unknown");

  useEffect(() => {
    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
			setBrowser("msie");
      return;
		}
    let ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if(ua && ua.length > 0)
      setBrowser(ua[0].toLowerCase());

  }, []);

  return browser;
};