'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button.jsx';

import './Modal.scss';

/** A bootstrap Modal component */
export default class Modal extends Component {

	static propTypes = {

		/** className for the moda-body element **/
		bodyClass: PropTypes.string,

		/** className for cancel button **/
		cancelClass: PropTypes.string,

		/** Label for cancel button **/
		cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		/** className for the .modal-content element **/
		contentClass: PropTypes.string,

		/** className for close button **/
		closeClass: PropTypes.string,

		/** className for the .modal-dialog element **/
		dialogClass: PropTypes.string,

		/** className for the .modal-footer element **/
		footerClass: PropTypes.string,

		id: PropTypes.string.isRequired,
		
		/** Function invoked when the cancel button is clicked **/
		onCancel: PropTypes.func,

		/** Function invoked when the submit button is clicked **/
		onSubmit: PropTypes.func,

		/** function invoked when the modal is triggered to show*/
		onShow: PropTypes.func,
		/** function invoked after the modal is shown*/
		onShown: PropTypes.func,
		/** function invoked when the modal is triggered to hide*/
		onHide: PropTypes.func,
		/** function invoked after the modal hidden*/
		onHidden: PropTypes.func,

		/** Whether the modal will render a footer. NOTE: Footer contains cancel and submit buttons*/
		renderFooter: PropTypes.bool,

		/** Whether the modal will render a close button.*/
		renderClose: PropTypes.bool,

		/** className for submit button **/
		submitClass: PropTypes.string,

		/** Label for cancel button **/
		submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		/** Modal title **/
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		/** className for modal title **/
		titleClass: PropTypes.string,

		
	};

	static defaultProps = {
		cancelLabel: 'Cancel',
		submitLabel: 'Submit',
		onSubmit: null,
		renderFooter: false,
		renderClose: true,
		isOpen: false
	};

	constructor(props) {
		super(props);
		this.state = {
			isOpen: this.props.isOpen,
		};
	}

	componentDidMount() {
		const {id} = this.props;
		if(!id) return;

		let target = `#${id}`;

		$(target).on(`show.bs.modal`, this.onShow);
		$(target).on(`shown.bs.modal`, this.onShown);
		$(target).on(`hide.bs.modal`, this.onHide);
		$(target).on(`hidden.bs.modal`, this.onHidden);
	}

	componentDidUpdate(nextProps) {
		this.setState({componentProps: nextProps});
	}

	componentWillUnmount() {
		const {id} = this.props;
		if(!id) return;

		let target = `#${id}`;

		$(target).off(`show.bs.modal`, this.onShow);
		$(target).off(`shown.bs.modal`, this.onShown);
		$(target).off(`hide.bs.modal`, this.onHide);
		$(target).off(`hidden.bs.modal`, this.onHidden);

		this.hide();
	}

	onShow = e => {
		if(e && e.target !== e.currentTarget) return;
		
		this.setState({isOpen: true}, () => {
			const {onShow} = this.props;
			if(onShow) onShow(e);
		});
	};

	onShown = e => {
		if(e && e.target !== e.currentTarget) return;

		const {onShown} = this.props;
		if(onShown) onShown(e);
	};

	onHide = e => {
		if(e && e.target !== e.currentTarget) return;

		this.setState({isOpen: false}, () => {
			const {onHide} = this.props;
			if(onHide) onHide(e);
		});
	};

	onHidden = e => {
		if(e && e.target !== e.currentTarget) return;
		
		const {onHidden} = this.props;
		if(onHidden) onHidden(e);
	};

	generateClassNames(base) {
		const {className} = this.props;
		const {isOpen} = this.state;
		let classNames = [base];
		classNames.push(this.constructor.name);
		if(className) classNames.push(className);
		if(isOpen) classNames.push('open');

		return classNames;
	}

	show() {
		$(`#${this.props.id}`).modal('show');
	}

	hide() {
		$(`#${this.props.id}`).modal('hide');
	}

	generateClassString(base) {
		return this.generateClassNames(base).join(' ');
	}

	render() {
		const {children, cancelLabel, submitLabel, title, id, isOpen, closeClass, dialogClass, submitClass, cancelClass, bodyClass, contentClass, footerClass, titleClass, renderFooter, renderClose, onSubmit, onCancel, onHide, onHidden, onShow, onShown, ...props} = this.props;

		return (
			<div className={this.generateClassString("modal fade")} {...props} id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}-label`} aria-hidden="true">
				<div className={"modal-dialog" + (dialogClass ? " "+dialogClass : "")} role="document">
					<div className={"modal-content" + (contentClass ? " "+contentClass : "")}>
						<div className="modal-header">
							{title && <span className={"modal-title"+(titleClass ? " "+titleClass : "")} id={`${id}-label`}>{title}</span>}
							{renderClose &&  <button type="button" className={"close" + (closeClass ? " "+closeClass : "")} data-dismiss="modal" aria-label="Close">
								<i className="fa fa-times"/>
							</button>}
						</div>
						<div className={"modal-body" + (bodyClass ? " "+bodyClass : "")}>
							{children}
						</div>
						{renderFooter && <div className={"modal-footer" + (footerClass ? " "+footerClass : "")}>
							<Button className={cancelClass} onClick={onCancel} data-dismiss="modal">{cancelLabel}</Button>
							<Button className={submitClass} onClick={onSubmit} >{submitLabel}</Button>
						</div>}
					</div>
				</div>
			</div>
		);
	}
}