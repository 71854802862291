'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import urljoin from 'url-join';
import * as ErrorLogger  from '../../utils/ErrorLogger';
import { get, put, post } from '../../middleware/requests'; 

/** Action Creators for Managed Users */
export const managedUsersHasErrored = () => ({
  type: actionTypes.MANAGEDUSERS_HAS_ERRORED
});

export const managedUsersIsLoading = () => ({
  type: actionTypes.MANAGEDUSERS_IS_LOADING
});

export const managedUsersFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.MANAGEDUSERS_FETCH_DATA_SUCCESS,
  response,
  authenticated
});

/** Action Creators for Available Roles */
export const availableRolesHasErrored = () => ({
  type: actionTypes.AVAILABLEROLES_HAS_ERRORED
});

export const availableRolesIsLoading = () => ({
  type: actionTypes.AVAILABLEROLES_IS_LOADING
});

export const availableRolesFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.AVAILABLEROLES_FETCH_DATA_SUCCESS,
  response,
  authenticated
});

export function managedUsersFetchData(query = null) {
  let apiEndpoint = config.API_ENDPOINT_MANAGEDUSERS_SEARCH;
  if(query)
        apiEndpoint = urljoin(config.API_ENDPOINT_MANAGEDUSERS_SEARCH, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.MANAGEDUSERS_IS_LOADING, actionTypes.MANAGEDUSERS_FETCH_DATA_SUCCESS, actionTypes.MANAGEDUSERS_HAS_ERRORED]
    }
  };
}

export function managedUsersFetchAvailableRoles() {
  let apiEndpoint = config.API_ENDPOINT_USERS_AVAILABLEROLES;
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.AVAILABLEROLES_IS_LOADING, actionTypes.AVAILABLEROLES_FETCH_DATA_SUCCESS, actionTypes.AVAILABLEROLES_HAS_ERRORED]
    }
  };
}

export function fetchUserBydetails(query) {
  let apiEndpoint = config.API_ENDPOINT_USERS_DETAILS;
  if(query)
      apiEndpoint = urljoin(config.API_ENDPOINT_USERS_DETAILS, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.MANAGEDUSERS_IS_LOADING, actionTypes.MANAGEDUSERS_FETCH_DATA_SUCCESS, actionTypes.MANAGEDUSERS_HAS_ERRORED]
    }
  };
}

//deleting a user calls this
export const managedUsersUpdateData = (user) => {
  let endpoint = config.API_ENDPOINT_USERS_ADMIN_UPDATE;
  return async (dispatch) => {
    dispatch(managedUsersIsLoading(true));
    const response = await put(endpoint, user);
    const { error } = response;
    dispatch(managedUsersIsLoading(false));
    if (error) {
      ErrorLogger.logError('managedUsersUpdateData', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};

//adding an existing user calls this
export const fetchUserByEmail = (email) => {
  let endpoint = urljoin(config.API_ENDPOINT_USERS_EMAIL, email);
  return async (dispatch) => {
    if(!email) return Promise.reject("Missing email");
    //dispatch(selectedFilesIsLoading(true));
    const response = await get(endpoint);
    const { error } = response;
    //dispatch(selectedFilesIsLoading(false));
    if (error) {
      ErrorLogger.logError('fetchUsersByEmail', error.title, error.detail, error.instance, error.status, true);
    }
    return response.payload;
  };
};

//called on page load, 
export const fetchUserByUserId = (userIds) => {
  let endpoint = urljoin(config.API_ENDPOINT_USERS_USERIDS);
  return async (dispatch) => {
    if(!userIds) return Promise.reject("Empty User");
    const response = await post(endpoint, userIds);
    const { error } = response;
    
    if (error) {
      ErrorLogger.logError('fetchUserByUserId', error.title, error.detail, error.instance, error.status, true);
    }
    return response.payload;
  };
};

//deleting a user calls this
export const fetchAdminUserByUserEmail = (email) => {
  let endpoint = urljoin(config.API_ENDPOINT_USERS_ADMIN, email);
  return async (dispatch) => {
    if(!email) return Promise.reject("Empty User");
    const response = await get(endpoint);
    const { error } = response;
    
    if (error) {
      ErrorLogger.logError('fetchAdminUserByUserEmail', error.title, error.detail, error.instance, error.status, true);
    }
    return response.payload;
  };
};

//called on page load
export const fetchAdminStakeholders = (projectId) => {
  let endpoint = urljoin(config.API_ENDPOINT_STAKEHOLDERS_ADMIN, projectId);
  return async (dispatch) => {
    if(!projectId) return Promise.reject("Empty ProjectId");
    const response = await get(endpoint);
    const { error } = response;
    
    if (error) {
      ErrorLogger.logError('fetchAdminStakeholders', error.title, error.detail, error.instance, error.status, true);
    }
    return response.payload;
  };
};