'use strict';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Filedrop } from '@/components/index.jsx';
import { uploadAttachment } from '@/redux/actions/files';
import RefreshIndicator from '@/components/controls/RefreshIndicator.jsx';
import { Line } from 'rc-progress';
import * as enums from '@/enums/Enums';
import { Auditor } from '@/components/util/Auditor.jsx';
import { withPermissions } from '@/hooks/permissions.hook';
import './AttachmentUploader.scss';

export class AttachmentUploader extends Auditor {
	constructor(props) {
		super(props);

		this.state = {
			selectedFiles: [],
			loaded: 0,
			projectId: this.props.projectId,
			projectCode: this.props.projectCode,
			errormsg: '',
			uploadStatus: '',
			clearList: false,
			attachmentIsLoading: false,
			progress: 0,
			invalidFiles: [],
			forbiddenFiles: [],
			...super.state
		};

		this.checkUserHasRole = props.checkUserHasRole;
		this.FileDropRef = React.createRef();
		this.validateFiles = this.validateFiles.bind(this);
		this.handleBulkUpload = this.handleBulkUpload.bind(this);
	}

	handleSelectedFiles = (files) => {
		let uploads = files.target.value;

		let uploadFiles = [];
		Object.values(uploads).forEach((value, index) => {
			uploadFiles.push(value);
		});

		this.setState({
			selectedFiles: uploadFiles,
			loaded: uploadFiles.length,
			attachmentIsLoading: false,
			uploadStatus: '',
			errormsg: ''
		});
		this.validateFiles(uploadFiles);
	};

	validateFiles(uploadFiles) {
		let listOfInvalidFiles = [];
		let listOfForbiddenFiles = [];
		let self = this;
		uploadFiles.forEach((value, index) => {
			//Check to see if .exe extension
			if (!self.checkUserHasRole(enums.ROLES.ADMIN) && value.name && value.name.toLowerCase().includes('.exe')) {
				listOfForbiddenFiles = self.state.forbiddenFiles;

				listOfForbiddenFiles.push(`${value.name} - Unable to Upload Forbidden File Extension (.EXE), removed from uploader.`);
				self.setState({ forbiddenFiles: listOfForbiddenFiles });
				//remove file from filedrop
				self.FileDropRef.current.onFileDismiss(value.name);
				return;
			}

			if (!value.name.includes(self.props.projectCode)) {
				listOfInvalidFiles.push(`${value.name} - Missing Project Code in filename`);
			}

		});
		this.setState({ invalidFiles: [...listOfInvalidFiles, ...this.state.forbiddenFiles] });
	}

	handleBulkUpload = () => {
		let self = this;
		self.setState({ errormsg: '', isLoading: true, attachmentIsLoading: true }, async () => {
			//Check to see if file is ready to upload
			if (!this.state.selectedFiles || this.state.selectedFiles.length == 0) {
				this.setState({ errormsg: "Please Select a Valid File for Processing.", attachmentIsLoading: false });
				return;
			}
			let projectId = this.props.projectId;

			let totalNumberOfFiles = this.state.selectedFiles.length;
			let processedFiles = 0;

			this.state.selectedFiles.forEach((file) => {
				let fileData = file;
				let comment = "Modified by Portal UI!";

				//do not upload .exe file if not admin
				if (!this.checkUserHasRole(enums.ROLES.ADMIN) && fileData.name && fileData.name.toLowerCase().includes('.exe')) {
					return;
				}

				self.handleAttachmentUpload(projectId, fileData, comment, totalNumberOfFiles, processedFiles).then(() => {
					processedFiles++;
					self.setState({ uploadStatus: `${processedFiles} of ${totalNumberOfFiles} Files Uploaded!`, progress: (processedFiles / totalNumberOfFiles) * 100 });

					if (processedFiles == totalNumberOfFiles) {
						self.setState({ attachmentIsLoading: false, selectedFiles: [], invalidFiles: [], forbiddenFiles: [], uploadStatus: `${processedFiles} of ${totalNumberOfFiles} Files Successfully Uploaded!` });
						self.FileDropRef.current.clearList();
					}
				});
			});

			self.setState({ uploadStatus: `${this.state.selectedFiles.length} files uploading ...` });
		});
	}

	handleAttachmentUpload = async (projectId, attachment, comment, totalNumberOfFiles, processedFiles) => {

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {

			//Make API Call
			await this.props.uploadAttachment(projectId, attachment, comment).then(result => {

				if (result && !result.ok) {
					let msg = result.headers.get('X-HTTP-Error-Description');
					this.setState({ errormsg: 'ERROR: ' + msg });
					resolve(false);
				}

			}).finally(() => {
				resolve(true);
			});
		});
	}

	/**Loading Indicators */
	loadingStyle = {
		container: {
			display: 'inline-block',
			//position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto',
			right: '-29px'

		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	RefreshIndicatorUploadLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="publishLoading" color="disabled"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/></Button>
		</div>
	);

	render() {

		let AttachmentCount = " (" + this.state.loaded + " Files Selected for upload )";
		let attachmentLoading = this.state.attachmentIsLoading;
		let invalidFiles = this.state.invalidFiles;
		let uploadFile = this.state.uploadStatus;
		return (
			<div className="FormInput AttachmentUploader">
				{super.render()}
				<div className="FileUploadLabel">Upload New File Attachment:  {this.state.loaded == 0 ? '' : AttachmentCount}  </div>
				<div className="note">Limit to 40 uploads at a time (temporary restriction)</div>
				<Filedrop ref={this.FileDropRef} onChange={this.handleSelectedFiles} />
				<br />
				<Line percent={this.state.progress} strokeWidth="4" strokeColor="#FF4F58" />
				<div className="attachmentUploadMessage">{uploadFile}</div>
				<div className="attachmentErrorMsg">{this.state.errormsg}</div>

				{invalidFiles.length > 0 ?
					<div className="InvalidFiles">
						<label>Possible Invalid Files Found:</label> <br />
						<div className="InvalidFileList">
							{invalidFiles.map((file, index) => {

								return <div key={index}>{file}</div>;
							})}
						</div>
					</div> : <div />

				}
				{attachmentLoading ? this.RefreshIndicatorUploadLoading() :
					<Button disabled={!this.state.errormsg.length == 0} onClick={this.handleBulkUpload}>Upload Files</Button>}
			</div>

		);
	}
}

const mapStateToProps = state => {
	return {

	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		uploadAttachment: (projectId, file, comment) => dispatch(uploadAttachment(projectId, file, comment))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withPermissions(AttachmentUploader)));