'use strict';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DynamicTable from '../components/controls/DynamicTable';
import DynamicTableHelper from '../components/controls/DynamicTableHelper';
import DynamicTableCell from '../components/controls/DynamicTableCell';
import { Button, Icon, Label } from '../components/index';
import { fetchSingleFile, AdminFetchSingleFile } from '../redux/actions/files';
import * as enums from '../enums/Enums';
import { Logger, Log } from '../utils/Logger';
import { AppInsightLogLevel } from '../enums/Enums';
import './FilesTable.scss';
const logger = new Logger();

import RefreshIndicator from '../components/controls/RefreshIndicator';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import { Auditor } from '@/components/util/Auditor';
import { withPermissions } from '@/hooks/permissions.hook';

export class FilesTable extends Auditor {
	constructor(props) {
		super(props);

		this.checkUserHasRole = props.checkUserHasRole;
		this.state = {
			currentDownloadingFile: null,
			...super.state
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props != nextProps) {
			this.setState({ files: nextProps.files });
		}
	}

	/**Table Support Functions */
	createTableFields = () => {
		let tableFieldsArray = [];

		tableFieldsArray.push(
			DynamicTableHelper.createField('---', {}, { type: 'select' }),
			DynamicTableHelper.createField('type', { text: 'Type', className: 'type-cell' }, { className: 'type-cell', renderer: this.fileTypeRenderer }),
			DynamicTableHelper.createField('name', { text: 'Name', className: 'name-cell' }, { className: 'name-cell' }),
			DynamicTableHelper.createField('createdDate', { text: 'Date', className: 'createdDate' }, { className: 'createdDate', renderer: DynamicTableHelper.defaultDateRenderer }),
			DynamicTableHelper.createField('actions', { text: 'Actions', className: 'action-cell' }, { className: 'action-cell', renderer: this.actionsRenderer }),
		);

		return tableFieldsArray;
	}

	fileTypeRenderer(data, fieldName, n) {
		let fileType = FilesTable.getFileTypeIcon(data.name);
		return (
			<DynamicTableCell className="type-cell" key={n}>
				<Label title={fileType} color="primary"><Icon icon={fileType} size="large" color="selectedItem" /></Label>
			</DynamicTableCell>
		);
	}


	actionsRenderer = (data, fieldName, n) => {
		const { fileId, name, size } = data;

		let { isLoading, fetchSingleFile } = this.props;
		let { currentDownloadingFile } = this.state;

		const downloadFile = () => {
			let currProjectId = localStorage.getItem("CurrentFilesProjectId");
			this.setState({ currentDownloadingFile: fileId });

			if (this.checkUserHasRole(enums.ROLES.DTS)) {
				this.props.AdminFetchSingleFile(fileId, currProjectId, name).then(() => {
					this.setState({ currentDownloadingFile: "" });
				});
			} else {
				fetchSingleFile(fileId, currProjectId, name).then(() => {
					this.setState({ currentDownloadingFile: "" });
				});
			}

			let currProjectPublishDate = localStorage.getItem("CurrentProjectPublishDate");
			this.state.logger.logProjectFileDownload(currProjectId, [name]);

			let logDownload = new Log();
			logDownload.SetLevel(AppInsightLogLevel.EVENT);
			logDownload.SetName('PageEvent_FileDownload_Single');
			logDownload.AddProperty('Filesize', size);
			logDownload.AddProperty("PublishDate", currProjectPublishDate);
			logDownload.AddProperty('Count', 1);
			logDownload.AddProperty('ProjectId', currProjectId);
			logger.doLog(logDownload);
		};

		return (
			<DynamicTableCell key={n} className="action-cell">
				<span className="action-icons">
					{
						(isLoading && currentDownloadingFile == fileId)
							? <LoadingSpinner />
							: <Label title={"Download " + name} color="primary"><a onClick={downloadFile} >Download <Icon icon="download" /></a></Label>
					}
				</span>
			</DynamicTableCell>
		);
	}


	generateClassNames = (base = 'FilesTable') => {
		const { className } = this.props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (className) classNames.unshift(className);

		return classNames;
	};

	onItemToggle = (selections) => {
		if (this.props.onCheckboxClick) this.props.onCheckboxClick(selections);
	};

	render() {
		const { files } = this.props;
		let rowProps = (file) => {
			return { id: file.fileId };
		};
		return (
			<div className="FilesTable">
				{super.render()}

				<DynamicTable
					onItemToggle={this.onItemToggle}
					primaryKey="fileId"
					sorter="name"
					sortDir={1}
					index={2}
					className={this.generateClassNames().join(' ')}
					dataset={files}
					fields={this.createTableFields()}
					rowProps={rowProps}
					selections={this.props.selectedFileIds}
					{...this.props} />

			</div>
		);
	}
}


FilesTable.propTypes = {
	/** files*/
	files: PropTypes.array.isRequired
};

FilesTable.defaultProps = {
	files: []
};

const mapStateToProps = state => {
	return {
		hasErrored: state.files.filesHasErrored,
		isLoading: state.files.filesIsLoading,
		isSelectedLoading: state.files.selectedFilesIsLoading,
		// files: state.files.data
	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchSingleFile: (fileId, currProjectId, name) => dispatch(fetchSingleFile(fileId, currProjectId, name)),
		AdminFetchSingleFile: (fileId, currProjectId, name) => dispatch(AdminFetchSingleFile(fileId, currProjectId, name)),
	};
};

FilesTable.getFileTypeIcon = (fileName) => {
	let re = /(?:\.([^.]+))?$/;
	let extension = re.exec(fileName)[1];

	if (extension)
		extension = extension.toLowerCase();

	switch (extension) {
		case "xls":
		case "xlsx":
		case "csv":
			return "file-excel-o";
		case "doc":
		case "docx":
			return "file-word-o";
		case "png":
		case "gif":
			return "file-image-o";
		case "pdf":
			return "file-pdf-o";
		case "ppt":
		case "pptx":
			return "file-powerpoint-o";
		default:
			return "file-alt-o";
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FilesTable));
