'use strict';
import React, { useEffect, useCallback } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import './HomeContainer.scss';
import AdminContainer from '../admin/AdminContainer.jsx';
import MyProjects from './myprojects/MyProjects.jsx';
import UserSettings from '../userSettings/UserSettings.jsx';
import Permissions from '../Permissions.jsx';
import * as PermissionsTypes from '../../security/permissionTypes';
import RouteAuthorization from '../../security/RouteAuthorization.jsx';
import ProjectContainer from '../project/ProjectContainer';
import classNames from 'classnames';
import { COE } from '../routes';
import { useProjectsQuery } from '../../redux/services/projects.api.js';
import { useCurrentUserQuery } from '../../redux/services/user.api.js';
import { usePendo } from '@/hooks/usePendo';

export const HomeContainer = (props) => {
	const { initialize } = usePendo();

	const { data: projects, isLoading: projectsIsLoading } = useProjectsQuery();
	const currentUser = useCurrentUserQuery();
	const { match, history } = props;
	const location = useLocation();

	const initializePendo = useCallback(() => {
		initialize();
	}, [initialize]);

	/** Render Router components, ignoring routes */
	const SettingsPage = /* istanbul ignore next */ (props) => <UserSettings {...props} />;
	const PermissionsPage = /* istanbul ignore next */ (props) => <Permissions {...props} />;
	const ProjectPage = /* istanbul ignore next */ useCallback((props) => <ProjectContainer projects={projects} currentUser={currentUser} {...props} />, []);
	const AdminPage = /* istanbul ingore next */ useCallback((props) => (
		<RouteAuthorization
			allowedPermissions={PermissionsTypes.CAN_VIEW_ADMIN}
			WrappedComponent={AdminContainer}
			{...props}
		/>
	), []);

	useEffect(() => {
		if (currentUser.data) initializePendo();
	}, [currentUser.data]);

	return (
		<div className="HomeContainer app-content" data-testid="homeContainer">
			<div className={classNames("main", { wide: location?.pathname.indexOf(COE) >= 0 })}>
				<Switch>
					<Route exact path={match.url}>
						<MyProjects history={history} />
					</Route>
					<Route path={match.url + "/admin"} match={match} render={AdminPage} />
					<Route path={match.url + "/permissions"}>
						<PermissionsPage />
					</Route>
					<Route path={match.url + "/profilesettings"}>
						<SettingsPage />
					</Route>
					<Route path={match.url + "/project/:projectCode"} render={ProjectPage} />
				</Switch>
			</div>
		</div>
	);
};

export default withRouter(HomeContainer);
