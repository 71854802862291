import React, { useCallback, useEffect, useState } from 'react';
import { Button, CollapsibleList, Icon } from '@/components/index.jsx';
import { useProjectSummaryQuery } from '@/redux/services/projects.api';
import { useProjectContactsQuery } from '@/redux/services/admin.api';
import '@/theme/theme.css';

const PublishingConfirmation = (props) => {
	const { projectCode, projectId, projectTitle } = props.project;
	const { data: publishSummary, isLoading: publishSummaryIsLoading } = useProjectSummaryQuery(projectId);
	const { data: unpublishedUsers, isLoading: unpublishedUsersLoading } = useProjectContactsQuery(projectId);

	const [users, setUsers] = useState([]);
	const [userCount, setUserCount] = useState(0);
	const [sampleSets, setSampleSets] = useState([]);
	const [sampleSetCount, setSampleSetCount] = useState(0);
	const [invalidFiles, setInvalidFiles] = useState([]);
	const [invalidFileCount, setInvalidFileCount] = useState(0);
	const [files, setFiles] = useState([]);
	const [fileCount, setFileCount] = useState(0);
	const [disablePublish, setDisablePublish] = useState(false);

	const onClickPublish = useCallback(() => {
		if (props.onClickPublish) props.onClickPublish();
		setDisablePublish(true);
	}, [props.onClickPublish]);

	const formatSummary = useCallback(() => {
		if (publishSummaryIsLoading || unpublishedUsersLoading) return;
		const sampleSets = publishSummary.children.filter((child) => child.type === 'SampleSet');
		const files = publishSummary.children.filter((child) => child.type === 'File');
		const users = publishSummary.children.filter((child) => child.type === 'User');
		//Grab the unpublished users and add them to the list
		unpublishedUsers.forEach((user) => {
			users.push({
				id: 'Adding New User',
				name: user.email,
				type: 'User',
			});
		});

		setUsers(users);
		setUserCount(users.length);
		setFiles(files);
		setFileCount(files.length);
		setSampleSets(sampleSets);
		setSampleSetCount(sampleSets.length);
		fileNameCheck(files);
	}, [publishSummary, publishSummaryIsLoading, unpublishedUsers, unpublishedUsersLoading]);

	const fileNameCheck = useCallback((files) => {
		let listOfInvalidFiles = [];
		files.forEach((value) => {
			if (!value.name.includes(projectCode)) {
				listOfInvalidFiles.push(value.name);
			}
		});
		setInvalidFiles(listOfInvalidFiles);
		setInvalidFileCount(listOfInvalidFiles.length);
	}, [projectCode]);

	useEffect(() => {
		formatSummary();
	}, [formatSummary]);

	return (
		<div className="PublishingConfirmationContainerTest" style={{ maxHeight: '600px' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
				<div
					title={projectTitle}
					style={{
						color: '#005695',
						fontWeight: 'bolder',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxWidth: '420px',
					}}
				>
					{projectTitle}
				</div>
				<Icon style={{ cursor: 'pointer', color: '#E9ECEF' }} data-dismiss="modal" size={'large'} icon={'times'} />
			</div>

			<div
				className="details"
				style={{
					maxHeight: '500px',
					overflow: 'scroll',
					border: '2px solid #E9ECEF',
					borderRadius: '5px',
					padding: '10px',
					marginBottom: '25px',
				}}
			>
				<div className="fileDetails" style={{ maxWidth: '95%' }}>
					<CollapsibleList className="FileList" title={'Files'} count={fileCount} itemsToShow={0} showMoreButton={false}>
						{files.map((file, index) => {
							return (
								<div key={index}>
									<label
										title={file.name}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											maxWidth: '390px',
										}}
									>
										{file.name}
									</label>
								</div>
							);
						})}
					</CollapsibleList>
				</div>
				<div className="samplesetDetails" style={{ maxWidth: '95%' }}>
					<CollapsibleList
						className="SampleSetList"
						title={'SampleSets'}
						count={sampleSetCount}
						itemsToShow={0}
						showMoreButton={false}
					>
						{sampleSets.map((sampleSet, index) => {
							return (
								<div key={index}>
									<label
										title={sampleSet.name}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											maxWidth: '390px',
										}}
									>
										{sampleSet.name}
									</label>
									<CollapsibleList
										indent={true}
										className="StatList"
										title={'Stats'}
										count={sampleSet.children.length}
										itemsToShow={0}
										showMoreButton={false}
									>
										{sampleSet.children.map((stat, index) => {
											return (
												<div key={index}>
													<label
														title={stat.name}
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															maxWidth: '360px',
														}}
													>
														{stat.name}
													</label>
												</div>
											);
										})}
									</CollapsibleList>
								</div>
							);
						})}
					</CollapsibleList>
				</div>
				<div className="contactDetails" style={{ maxWidth: '95%' }}>
					<CollapsibleList className="UserList" title={'Contacts'} count={userCount} itemsToShow={0} showMoreButton={false}>
						{users.map((user, index) => {
							let visibleName = user.name.trim() + ' - (' + user.id + ')';
							return (
								<div key={index}>
									<label
										title={visibleName}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											maxWidth: '390px',
										}}
									>
										{visibleName}
									</label>
								</div>
							);
						})}
					</CollapsibleList>
				</div>

				{invalidFiles.length > 0 ? (
					<div className="fileErrors" style={{ maxWidth: '95%' }}>
						<CollapsibleList
							style={{ color: 'red' }}
							className="FileErrorList"
							title={'Warning: File Names Missing Project Code'}
							count={invalidFileCount}
							startExpanded={true}
							itemsToShow={0}
							incrementAmount={0}
							showMoreButton={false}
						>
							{invalidFiles.map((file, index) => {
								return (
									<div key={index}>
										<label
											title={file}
											style={{
												fontWeight: 'bold',
												color: 'orange',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												maxWidth: '390px',
											}}
										>
											{file}
										</label>
									</div>
								);
							})}
						</CollapsibleList>
					</div>
				) : (
					<div />
				)}
			</div>
			<div style={{ textAlign: 'center' }}>
				<Button disabled={disablePublish} title="" onClick={onClickPublish} data-dismiss="modal">
					<Icon size={'medium'} icon={'check'} />
					&nbsp;Publish to Client
				</Button>
			</div>
		</div>
	);
};

export default PublishingConfirmation;