import { createSlice } from '@reduxjs/toolkit';

export const getInitialState = () => ({
	isAuthenticated: false
});

export const AuthSlice = createSlice({
	name: 'auth',
	initialState: getInitialState(),
	reducers: {
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		}
	}
});

export const {
	setIsAuthenticated,
	userIsLoading,
	fetchUserFailed,
	fetchUserSuccess,
} = AuthSlice.actions;

export default AuthSlice.reducer;