'use strict';
import React from 'react';
import { connect } from 'react-redux';
import { generateGuid } from '@/utils/StringUtils';
import { withRouter } from 'react-router-dom';
import '../../../../theme/theme.css';
import '../../../../theme/css/material-icons.css';
import AdminUserManagement from './AdminUserManagement.jsx';
import { fetchProjectUsers, fetchUnpublishedProjectUsers, publishUser, unpublishUser, projectsSaveDataAdmin, projectsFetchDataAdminNoRedux } from '@/redux/actions/projects';
import { fetchUserByEmail, managedUsersUpdateData, fetchAdminUserByUserEmail, fetchAdminStakeholders } from '@/redux/actions/managedUsers';
import { DynamicModalSingleton, Button, Toaster, DynamicModal } from '@/components/index.jsx';
import './PublishContactsContainer.scss';
import { Logger, Log } from '@/utils/Logger';
const logger = new Logger();
import { toast } from 'react-toastify';

import * as enums from '@/enums/Enums';
import { Auditor } from '@/components/util/Auditor.jsx';
import { Box, Stack, Typography } from '@mui/material';

export class PublishContactsContainer extends Auditor {

	constructor(props) {
		super(props);
		this.state = {
			project: this.props.project,
			targetUser: null,
			removeUserModalOpen: false,
			currentUsers: [],
			currentUnpublishedUserEmails: [],
			isPublishLoading: false,
			isButtonDisabled: false,
			...super.state
		};
		this.getProjectUsersData = this.getProjectUsersData.bind(this);
		this.checkIfUserExistsInDatabase = this.checkIfUserExistsInDatabase.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
		this.onRemoveUserClick = this.onRemoveUserClick.bind(this);
		this.getStakeholders = this.getStakeholders.bind(this);
	}

	componentDidMount() {
		if (this.state.project) {
			const getUsersData = !this.props.getProjectUsersData ? this.getProjectUsersData : this.props.getProjectUsersData;
			getUsersData(this.state.project.projectId);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.project != this.state.project) {
			this.getProjectUsersData(nextProps.project.projectId);
			this.setState({ project: nextProps.project });

		}
	}

	getProjectUsersData(projectId) {
		this.setState({ isPublishLoading: true });
		this.props.fetchProjectUsers(projectId).then((result) => {
			return result;
		}).then((currentUsers) => {
			//Need to call to get unpublished Project UserID's, then take those id's and get the actual user objects, then combine with current users list
			this.props.fetchUnpublishedProjectUsers(projectId).then((users) => {
				let unpubUserList = [];
				if (users) {
					unpubUserList = users.filter(user => user.email);
					currentUsers = currentUsers.filter(cu => !unpubUserList.find(rm => (rm.email.toUpperCase() === cu.email.toUpperCase())));
				}
				let combinedUsers = [...unpubUserList, ...(currentUsers || [])];
				this.setState({
					currentUnpublishedUserEmails: unpubUserList.map(user => user.email),
					isPublishLoading: false,
					currentUsers: combinedUsers
				});
				this.getStakeholders(combinedUsers);
			});
		}).catch(err => {
			// eslint-disable-next-line
			//console.error(err);
		});
	}

	getStakeholders(combinedUsers) {

		this.props.fetchAdminStakeholders(this.state.project.projectId).then((result) => {

			let projectStakeholders = result;
			let combinedUserEmails = combinedUsers.map(user => user.email);
			projectStakeholders.forEach(async (email) => {
				let found = combinedUserEmails.find(item => item.toLowerCase() === email.toLowerCase());

				if (!found) {
					let user = { firstName: '', lastName: '', email: email };
					await this.onAddUser(user);
				}
			});
		});

	}

	//Add User to project
	onAddUser = (user) => {

		this.setState({ isButtonDisabled: true, currentUnpublishedUserEmails: [...this.state.currentUnpublishedUserEmails, user.email] });
		this.checkIfUserExistsInDatabase(user.email).then((result) => {

			if (result) {
				//Get Latest User By ID
				this.props.fetchAdminUserByUserEmail(user.email).then((result) => {
					let updatedUser = result;

					//if need to update firstname and last name
					if (user.firstName != result.firstName || user.lastName != result.lastName) {
						//Save User for publish
						updatedUser.firstName = user.firstName ? user.firstName : result.firstName;
						updatedUser.lastName = user.lastName ? user.lastName : result.lastName;
					}

					this.props.publishUser({ projectId: this.state.project.projectId, ...updatedUser });

					let newUserObject = this.setupExistingListUser(updatedUser);

					let currentListOfUsers = this.state.currentUsers.filter((user) => {
						return user.email.toUpperCase() != newUserObject.email.toUpperCase();
					});
					currentListOfUsers.unshift(newUserObject);
					this.showToaster("Add New User ...", `${newUserObject.firstName} ${newUserObject.lastName} ${newUserObject.email}`);
					this.setState({ currentUsers: currentListOfUsers, isButtonDisabled: false });
					this.logInfo("AddExistingUser");
				});
			}
			else {
				let guid = generateGuid();
				let newUser = this.setupNewUserObject(user, guid);
				//Save this new user
				this.props.publishUser({ projectId: this.state.project.projectId, ...newUser }).then(result => {
					//Saving userid to project list for publish
					//this.addUnpublishedUserToProject(user.email);
					let newUnpublishedUser = this.setupUnpublishedListUser(user);

					let currentListOfUsers = this.state.currentUsers;
					currentListOfUsers.unshift(newUnpublishedUser);
					this.showToaster("Add New User ...", `${newUnpublishedUser.firstName} ${newUnpublishedUser.lastName} ${newUnpublishedUser.email}`);
					this.setState({ currentUsers: currentListOfUsers, isButtonDisabled: false });
				});
			}
		}).catch(err => {
			// eslint-disable-next-line
			//console.error(err);
		});
	}

	removeUnpublishedUserFromProject(email) {
		this.props.unpublishUser({ projectId: this.state.project.projectId, email }).then(() => {
			this.removedStakeholderFromProject(email);
			this.removeUserFromVisibleList(email);
			this.logInfo("RemoveUnpublishedUser");
		});
	}

	removePublishedUserFromProject(userToRemove) {

		//get user by email
		this.props.fetchUserByEmail(userToRemove.email).then(user => {

			if (!user) return;

			let filteredProjects = user.projects.filter(proj => proj.projectId != this.state.project.projectId);
			user.projects = filteredProjects;
			let userPayload = { "instance": { ...user }, "comment": "PortalUI: Portal Remove Published User from project, Updated User Projects" };

			this.removedStakeholderFromProject(userToRemove.email);

			this.props.updateUserData(userPayload).then(result => {
				//need to remove from the visible list
				this.removeUserFromVisibleList(user.email);
				this.logInfo("RemovePublishedUser");
			}
			).catch(err => {
				// eslint-disable-next-line
				//console.error(err);
			});
		});
	}

	removedStakeholderFromProject(email) {

		//get latest projectObject
		this.props.fetchAdminProject(this.state.project.projectId).then(result => {
			let latestProject = result;

			latestProject.stakeHolders.forEach(stakeholder => {
				stakeholder.members = stakeholder.members.filter(s => s.email.toLowerCase() != email.toLowerCase());
			});

			let updatedProject = {
				instance: { ...latestProject },
				comment: "Portal Save project to remove stakeholder, automated comment"
			};

			this.props.saveAdminProject(updatedProject).then(result => {
				this.logInfo("RemoveStakeHolder");
			}).catch(err => {
				// eslint-disable-next-line
				//console.error(err);
			});
		});
	}

	removeUserFromVisibleList(email) {
		let updatedCurrentUsers = this.state.currentUsers.filter(e => e.email.toLowerCase() != email.toLowerCase());
		this.setState({ currentUsers: updatedCurrentUsers });
	}


	checkIfUserExistsInCurrentList(email) {
		return this.state.currentUsers.some((e) => {
			return e.username === email;
		});
	}

	checkIfUserExistsInDatabase(email) {
		return this.props.fetchUserByEmail(email).then(result => {
			return result;
		}).catch(err => {
			// eslint-disable-next-line
			//console.error(err);
		});
	}

	setupExistingListUser(user) {
		let projectId = this.state.project.projectId;
		let projectCode = this.state.project.projectCode;
		let newProject = { projectId: projectId, projectCode: projectCode };

		//Check to see if project exists
		if (!user.projects.some(e => e.projectId === projectId)) {
			user.projects.push(newProject);
		}
		return user;
	}

	setupUnpublishedListUser(user, userId) {
		let projectId = this.state.project.projectId;
		let newUser = {
			userId: userId,
			username: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			projects: [projectId],
			roles: [{ name: 'Basic', permissions: ['canViewAperture'] }]
		};
		return newUser;
	}

	setupNewUserObject(user, userId) {
		let newUser = {
			userId: userId,
			username: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			projects: [],
			roles: [{ name: 'Basic', permissions: ['canViewAperture'] }]
		};
		return newUser;
	}

	clickRemoveUserModal = (user) => {
		this.setState({ targetUser: user, removeUserModalOpen: true });
	}

	onRemoveUserModalClose = () => {
		this.setState({ removeUserModalOpen: false });
	}

	onRemoveUserClick = (email) => () => {
		let foundUser = this.state.currentUsers.find(e => e.email.toLowerCase() === email.toLowerCase());
		this.showToaster("Removing User ...", `${foundUser.firstName} ${foundUser.lastName} (${foundUser.email})`);

		//Does User Exist in unpublishedList
		if (this.state.currentUnpublishedUserEmails.includes(email)) {

			this.removeUnpublishedUserFromProject(email);
		}
		//Does this user exist in the published list?
		else if (this.state.currentUsers.some(e => e.email.toLowerCase() === email.toLowerCase())) {

			let user = this.state.currentUsers.filter(e => e.email.toLowerCase() === foundUser.email.toLowerCase())[0];
			if (user) {
				this.removePublishedUserFromProject(user);
			}

		}
		this.setState({ removeUserModalOpen: false });
	}

	onCancelClick() {
		if (this.props.onCancelClick) {
			this.props.onCancelClick();
		}
	}

	logInfo(action) {
		const currentProjectId = localStorage.getItem('ProjectId') || null;
		//Log subview change
		let logInfo = new Log();
		logInfo.SetLevel(enums.AppInsightLogLevel.EVENT);
		logInfo.SetName('PageEvent_PublishContactsContainer');
		logInfo.AddProperty('Action', action);
		logInfo.AddProperty('ProjectId', currentProjectId);
		logger.doLog(logInfo);
	}

	showToaster(title, body) {
		toast(<Toaster title={title} body={body} />);
	}

	render() {
		const { targetUser } = this.state;
		return (
			<div className="ContactsContainer">
				{super.render()}
				<AdminUserManagement project={this.state.project} onCancelClick={this.onCancelClick} isButtonDisabled={this.state.isButtonDisabled} isPublishLoading={this.state.isPublishLoading} onAddUser={this.onAddUser} onRemoveUserClick={this.clickRemoveUserModal} currentUsers={this.state.currentUsers} />

				<DynamicModal
					onClose={this.onRemoveUserModalClose}
					open={this.state.removeUserModalOpen}
					id={'remove-user-modal'}
					title="Are you sure you want to remove this user?"
				>
					{targetUser && (
						<>
							<Typography mb={2} fontWeight={800}>{targetUser.firstName} {targetUser.lastName}<br />{targetUser.email}</Typography>
							<Stack justifyContent={"end"} direction="row" spacing={2}>
								<Button onClick={this.onRemoveUserClick(targetUser.email)} data-dismiss="modal">Yes</Button>
							</Stack>
						</>
					)}
				</DynamicModal>

			</div>
		);
	}
}

PublishContactsContainer.propTypes = {};

PublishContactsContainer.defaultProps = {};

const mapStateToProps = state => {
	return {

	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchProjectUsers: (query) => dispatch(fetchProjectUsers(query)),
		fetchUnpublishedProjectUsers: (query) => dispatch(fetchUnpublishedProjectUsers(query)),
		fetchUserByEmail: (query) => dispatch(fetchUserByEmail(query)),
		fetchAdminUserByUserEmail: (query) => dispatch(fetchAdminUserByUserEmail(query)),
		fetchAdminStakeholders: (query) => dispatch(fetchAdminStakeholders(query)),
		fetchAdminProject: (projectId) => dispatch(projectsFetchDataAdminNoRedux(projectId)),
		publishUser: (query) => dispatch(publishUser(query)),
		unpublishUser: (query) => dispatch(unpublishUser(query)),
		saveAdminProject: (project) => dispatch(projectsSaveDataAdmin(project)),
		updateUserData: (user) => dispatch(managedUsersUpdateData(user))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishContactsContainer));