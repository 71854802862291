import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Modal from "metabux/components/Modal/Modal"
import { Box } from "@mui/material"
import { Button } from "metabux/components/Button/Button"
import { RadioButtons } from "metabux/components/RadioButtons/RadioButtons"
import { Input } from "metabux/components/Input/Input";

export const ManifestRemoveModal = ({
	open,
	setOpen,
	manifestItem,
	...props
}) => {

	const [reason, setReason] = useState("");
	const [other, setOther] = useState("");

	const onClose = useCallback(() => {
		setOpen(false)
	}, [setOpen]);

	const onSubmit = useCallback(() => {
		if (props.onSubmit) props.onSubmit(reason, other);
		setReason("");
		setOther("");
	}, [reason, other]);

	const onChange = useCallback((e) => {
		setReason(e.target.value)
	}, []);

	const onOtherChange = useCallback((val) => {
		setOther(val)
	}, []);

	const submitDisabled = useMemo(() => {
		if (!reason || (reason === "other" && !other)) return true;
		return false;
	}, [reason, other]);

	const inputs = [{
		label: "Error/duplicate in manifest",
		value: "Error/duplicate in manifest",
	}, {
		label: "Need to add/remove samples",
		value: "Need to add/remove samples",
	}, {
		label: "Other:",
		value: "other",
	}];

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={`Why are you removing ${manifestItem?.fileName}?`}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: 5,
					mt: 5,
				}}
			>
				<RadioButtons inputs={inputs} onChange={onChange} />
				<Input onChange={onOtherChange} value={other} disabled={reason !== "other"} type="textarea" />

				<Box sx={{ display: "flex", gap: "10px" }}>
					<Button variant={"secondary"} color={"black"} onClick={onClose} sx={{ width: "150px" }}>
						Cancel
					</Button>
					<Button disabled={submitDisabled} onClick={onSubmit} sx={{ width: "150px" }}>
						Submit
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

ManifestRemoveModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
}
