import urljoin from 'url-join';
import { API_MANIFEST } from '@/config/env.config';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';

export const manifestApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		webhook: builder.query({
			query: () => ({
				url: urljoin(API_MANIFEST, "file", "oneschema", "importwebhook"),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'POST',
			})
		}),
		token: builder.mutation({
			query: ({ ...params }) => ({
				url: urljoin(API_MANIFEST, "oneschema", "token"),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'GET',
				params,
				invalidatesTags: ['manifests'],
				responseHandler: (response) => response.text(),
			})
		}),
		download: builder.mutation({
			query: ({ projectId, manifestFileId, fileName }) => ({
				url: urljoin(API_MANIFEST, "file", "download", projectId, manifestFileId, `?fileName=${fileName}`),
				//url: `file/download/${projectId}/${manifestFileId}/?fileName=${fileName}`,
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'GET'
			})
		}),
		downloadTemplate: builder.mutation({
			query: ({ projectId }) => ({
				url: urljoin(API_MANIFEST, "file", "download", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'GET'
			})
		}),
		list: builder.query({
			query: ({ projectId }) => ({
				url: urljoin(API_MANIFEST, "file", "list", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'GET'
			}),
			providesTags: ['manifests']
		}),
		upload: builder.mutation({
			query: (body) => ({
				url: urljoin(API_MANIFEST, "file", "upload"),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'POST',
				body
			}),
			invalidatesTags: ['manifests']
		}),
		update: builder.mutation({
			query: ({ projectId, ...body }) => ({
				url: urljoin(API_MANIFEST, "file", "modify", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'PUT',
				body
			}),
			invalidatesTags: ['manifests']
		}),
		delete: builder.mutation({
			query: ({ projectId, ...body }) => ({
				url: urljoin(API_MANIFEST, "file", "delete", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'POST',
				body
			}),
			invalidatesTags: ['manifests']
		}),
	}),
});

export const {
	useWebhookQuery,
	useTokenMutation,
	useDownloadMutation,
	useDownloadTemplateMutation,
	useListQuery,
	useUploadMutation,
	useUpdateMutation,
	useDeleteMutation
} = manifestApi;