import { Gray } from "metabux/theme/colors";
import { FormControlLabel, Typography } from "@mui/material";
import React from "react";

export const InputWrapper = ({
  children,
  value,
  label,
  disabled,
  labelPlacement,
  testId = "inputWrapper", 
  ...props
}) => {
  return (
    <FormControlLabel
      control={children}
      value={value}
      data-testid={testId}
      label={
        <Typography
          variant="body2"
          className="input-label"
          sx={{ color: disabled ? Gray[400] : Gray[800] }}
        >
          {label}
        </Typography>
      }
      disabled={disabled}
      labelPlacement={labelPlacement}
      {...props}
    />
  );
};
