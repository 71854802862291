"use strict";

import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../components/controls/Dropdown.jsx";
import classNames from "classnames";

class DropdownContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initState({ isOpen: this.props.isOpen });
  }

  initState(state = {}) {
    if (!("isOpen" in state)) state.isOpen = false;
    state.blurTimer = null;
    return state;
  }

  reset(callback) {
    this.setState(this.initState(), callback);
  }

  open() {
    this.setState({ isOpen: !this.state.isOpen }, this.update);
  }

  close = () => {
    this.reset(this.update);
  };

  onClick = () => {
    if (!this.isOpen) this.open();
    else this.close();
  };

  onBlur = (e) => {
    if (!e?.currentTarget.contains(e.relatedTarget) && this.isOpen)
      this.handleBlur();
  };
  handleBlur() {
    this._blurTimer = setTimeout(() => {
      this.blur();
    }, 0);
  }
  blur() {
    this.setState({
      blurTimer: setTimeout(this.close, 100),
    });
  }

  onFocus = () => {
    this.handleFocus();
  };
  handleFocus() {
    clearTimeout(this._blurTimer);
    this.focus();
  }
  focus() {
    let { blurTimer } = this.state;
    if (blurTimer) {
      clearTimeout(blurTimer);
      this.setState({ blurTimer: null });
    }
  }

  update = () => {
    if (this.isOpen && this.props.onOpen) this.props.onOpen();
    else if (this.props.onClose) this.props.onClose();
  };

  get isOpen() {
    return this.state.isOpen;
  }

  renderChildren() {
    return this.props.children;
  }

  render() {
    const {
      id,
      caret,
      color,
      size,
      children,
      disabled,
      alt,
      alt2,
      type,
      title,
      renderLabel,
      triggerClass,
      triggerRenderer,
    } = this.props;
    let dropProps = {
      triggerClass,
      caret,
      color,
      size,
      children,
      disabled,
      alt,
      alt2,
      renderLabel,
      triggerRenderer,
      type,
    };

    return (
      <Dropdown
        title={title}
        id={id}
        {...dropProps}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        className={classNames("DropdownContainer", this.props.className)}
        isOpen={this.state.isOpen}
        onClick={this.onClick}
      >
        {this.renderChildren()}
      </Dropdown>
    );
  }
}

DropdownContainer.propTypes = {
  /** Whether a caret is appended to the dropdown label */
  caret: PropTypes.bool,

  /** Whether the control is initally open */
  isOpen: PropTypes.bool,

  /** Whether the menu is rendered when closed */
  renderMenu: PropTypes.bool,

  /** The color of the dropdown button */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "success",
    "warning",
    "danger",
    "info",
    "inactive",
  ]),

  /** The size of the dropdown button */
  size: PropTypes.oneOf(["small", "medium", "normal", "large"]),

  /** Whether the dropdown button is disabled */
  disabled: PropTypes.bool,

  /** Whether the dropdown button is in alt-color mode */
  alt: PropTypes.bool,

  /** Whether the dropdown button is in alt2-color mode */
  alt2: PropTypes.bool,

  /** type attribute applied to the dropdown button */
  type: PropTypes.string,

  /** Invoked when the control is opened */
  onOpen: PropTypes.func,

  /** Invoked when the control is closed */
  onClose: PropTypes.func,
};

DropdownContainer.defaultProps = {
  caret: Dropdown.defaultProps.caret,
  isOpen: Dropdown.defaultProps.isOpen,
  renderMenu: Dropdown.defaultProps.renderMenu,
  size: Dropdown.defaultProps.size,
  color: Dropdown.defaultProps.color,
  disabled: Dropdown.defaultProps.disabled,
  alt: Dropdown.defaultProps.alt,
  alt2: Dropdown.defaultProps.alt2,
  type: Dropdown.defaultProps.type,
   
  //onOpen: /* istanbul ignore next */ () => { console.warn('DropdownContainer custom onOpen callback not defined!'); },
  //onClose: /* istanbul ignore next */ () => { console.warn('DropdownContainer custom onClose callback not defined!'); },
   
};

export default DropdownContainer;
