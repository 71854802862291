import { createSelector } from '@reduxjs/toolkit';


export const managedUsersSelector = createSelector(
    (state) => state,
    (state) => state.managedUsers
);


export const managedUsersIsLoadingSelector = createSelector(
    managedUsersSelector,
    (managedUsers) => managedUsers.managedUsersIsLoading
);


export const managedUsersHasErroredSelector = createSelector(
    managedUsersSelector,
    (managedUsers) => managedUsers.managedUsersHasErrored
);
