import urljoin from 'url-join';
import { API_COE } from '@/config/env.config';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';

export const coeApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({

		deliveryDate: builder.query({
			query: (projectId) => ({
				url: urljoin(API_COE, "delivery-date", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
			})
		}),
		samplesReady: builder.mutation({
			query: (projectId) => ({
				url: urljoin(API_COE, "samples-ready", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'POST',
			})
		}),
		updateShipping: builder.mutation({
			query: ({ projectId, ...body }) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(API_COE, "update-shipping-date", projectId),
				method: 'POST',
				body
			})
		}),
		committed: builder.mutation({
			query: ({ projectId, ...body }) => ({
				url: urljoin(API_COE, "committed", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'POST',
				body
			})
		}),
		getOnboarding: builder.query({
			query: (projectId) => ({
				url: urljoin(API_COE, "state", projectId),
				headers: { 'authorization': `Bearer ${getToken()}` },
			}),
			providesTags: ['coe-state']
		}),
		updateOnboarding: builder.mutation({
			query: (body) => ({
				url: urljoin(API_COE, "state", "publish"),
				headers: { 'authorization': `Bearer ${getToken()}` },
				method: 'PUT',
				body: {
					instance: body,
					comment: "publish onboarding state"
				},
			}),
			invalidatesTags: ['coe-state']
		}),
	}),
});

export const {
	useDeliveryDateQuery,
	useSamplesReadyMutation,
	useUpdateShippingMutation,
	useCommittedMutation,
	useGetOnboardingQuery,
	useUpdateOnboardingMutation,
} = coeApi;