'use strict';

import React from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import DynamicTable from '../../../../components/controls/DynamicTable.jsx';
import DynamicTableHelper from '../../../../components/controls/DynamicTableHelper.jsx';
import { NavButton } from '../../../../components/index.jsx';
import ContentSwitch from '../../../../containers/ContentSwitch.jsx';

import './Explorer.scss';

const Explorer = (props) => {

	const {compounds, onMapSelect, onSubpathwaySelect, pathways, maps} = props;
	let subProps = sub => ({id:sub.name}),
		mapProps = map => ({id:map.name});
	return (

		<ContentSwitch className={classnames("Explorer", props.className)} parentView={"PageEvent_PathwayExplorer_Explorer"} auditIds={["Subpathway","Biochemical Maps"]}
			nav={[
				<NavButton key={0}>Subpathway</NavButton>,
				<NavButton key={1}>Biochemical Maps</NavButton>
			]}
			content={[
				<DynamicTable key={0}
					className="fixed subpathways"
					primaryKey="name"
					sorter="count"
					sortDir={1}
					index={0}
					onRowClick={onSubpathwaySelect}
					dataset={pathways}
					rowProps={subProps}
					listHeight={450}
					fields={[DynamicTableHelper.createField('name', 'Subpathway'), DynamicTableHelper.createField('count', 'Count', {comparator: "numeric"})]}
					wrapRows={true}
					viewName={"PageEvent_PathwayExplorer_Explorer_Subpathway"}
				/>,
				<DynamicTable key={1}
					className="fixed biomaps"
					primaryKey="name"
					sorter="name"
					sortDir={1}
					index={0}
					onRowClick={onMapSelect}
					dataset={maps}
					rowProps={mapProps}
					listHeight={450}
					fields={[DynamicTableHelper.createField('name', 'Name')]}
					wrapRows={true}
					viewName={"PageEvent_PathwayExplorer_Explorer_BiologicalMaps"}
				/>
			]}
		/>
	);
};

Explorer.propTypes = {
	compounds: PropTypes.array,
	pathways: PropTypes.array,
	maps: PropTypes.array,
	statsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Explorer.defaultProps = {
	compounds: [],
	pathways: [],
	maps: [],
	statsId: null,
};

export default Explorer;