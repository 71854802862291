import React, { memo, useCallback, useMemo, useState } from "react";
import { Stack, TableCell, TableRow } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Dropdown } from "metabux/components/Dropdown/Dropdown";
import IconButton from "metabux/components/IconButton/IconButton";
import { ShippingProviders, OTHER } from "metabux/components/ManifestUploadStepper/manifest.constants";
import { FormField } from "metabux/components/FormField/FormField";
import { Input } from "metabux/components/Input/Input";

const parseProvider = (value) => {
	if (!value || value.indexOf(OTHER) === -1) return [value]
	return value.split(':')
}
const getProvider = (value, other) => {
	if (value && value.indexOf(OTHER) === 0) return `${OTHER}:` + other
	return value
}
export const ManifestListItem = memo(({
	onRemove,
	testId = "manifestListItem",
	data,
	...props
}) => {

	const [provider, other = ''] = parseProvider(data.shippingProvider);
	const [shippingProvider, setShippingProvider] = useState(provider);
	const [otherProvider, setOtherProvider] = useState(other);
	const [manifestName, setManifestName] = useState(data.manifestName);
	const [trackingNumber, setTrackingNumber] = useState(data.trackingNumber);
	const trackingNumberValid = useMemo(() => trackingNumber && trackingNumber.length <= 64, [trackingNumber]);

	const onChange = useCallback((update) => {
		props.onChange({ ...data, manifestName, shippingProvider: getProvider(shippingProvider, otherProvider), trackingNumber, ...update });
	}, [manifestName, shippingProvider, trackingNumber, otherProvider]);

	const onProviderChange = useCallback((value) => {
		setShippingProvider(value);
		onChange({ shippingProvider: value });
	}, [onChange]);

	const onOtherProviderChange = useCallback((value) => {
		setOtherProvider(value);
		onChange({ shippingProvider: `${OTHER}:` + value });
	}, [onChange]);

	const onTrackingChange = useCallback((value) => {
		setTrackingNumber(value)
		onChange({ trackingNumber: value });
	}, [onChange]);

	const onNameChange = useCallback((value) => {
		setManifestName(value)
		onChange({ manifestName: value });
	}, [onChange]);

	const removeHandler = useCallback(() => {
		if (onRemove) onRemove({ ...data, shippingProvider, trackingNumber });
	}, [data, onRemove, shippingProvider, trackingNumber]);


	return (
		<TableRow
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell sx={{ verticalAlign: "top" }}>
				<IconButton icon={CloseIcon} onClick={removeHandler} />
			</TableCell>
			<TableCell sx={{ verticalAlign: "top" }}>
				<FormField error="Please input a manifest name" hasError={!manifestName}>
					<Input placeholder={"Manifest name"} value={manifestName} onChange={onNameChange} />
				</FormField>
			</TableCell>
			<TableCell sx={{ verticalAlign: "top" }}>
				<Stack direction={"column"} spacing={2}>
					<FormField error="Please select a shipping provider" hasError={!shippingProvider}>
						<Dropdown title="Shipping provider" label="--Choose Provider" onChange={onProviderChange} items={ShippingProviders} defaultValue={shippingProvider} />
					</FormField>

					{shippingProvider === OTHER &&
						<FormField error="Please input a shipping provider" hasError={!otherProvider}>
							<Input placeholder={"Shipping Provider"} onChange={onOtherProviderChange} value={otherProvider} />
						</FormField>
					}
				</Stack>
			</TableCell>
			<TableCell sx={{ verticalAlign: "top" }}>
				<Stack direction={"column"} spacing={2}>
					<FormField error="Please input a tracking number" hasError={!trackingNumberValid}>
						<Input placeholder={"Tracking Number"} value={trackingNumber} onChange={onTrackingChange} />
					</FormField>
				</Stack>
			</TableCell>
		</TableRow>
	);
})

export default ManifestListItem