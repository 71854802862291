'use strict';

import React from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import { scrubHTML } from '../../../../utils/JsxUtils';

const Info = ({project, ...props}) => {

	const generateSynopsis = () => {
		if(props.adminProjectData && props.adminProjectData.customerSynopsis){
			return props.adminProjectData.customerSynopsis;
		}
		else{
			if(project.customerSynopsis) return project.customerSynopsis;
			else
				return project.synopsis? scrubHTML(project.synopsis, true): "Metabolomics project";
		}
	};

	const generateConclusion = () => {
		if(props.adminProjectData && props.adminProjectData.customerConclusion){
			return props.adminProjectData.customerConclusion;
		}
		else{
			if(project.customerConclusion) return project.customerConclusion;
			else
				return project.conclusion? scrubHTML(project.conclusion, true): "Metabolomics project Conclusion";
		}
	};

	return (
		<div className={classnames("Info", props.className)} >
			<h3>Study Information</h3>
			<h5>{project.projectCode}</h5>
			<div style={{overflowY: 'auto', maxHeight: '550px', paddingBottom:'35px'}}>
				<div style={{paddingBottom:'35px'}}>
					<div><label>Synopsis:</label></div>
					{generateSynopsis()}
				</div>
				<div>
					<div><label>Conclusion:</label></div>
					{generateConclusion()}
				</div>
			</div>
		</div>
	);
};

Info.propTypes = {
	project: PropTypes.shape({projectCode:PropTypes.string, description:PropTypes.string}),
};

Info.defaultProps = {
	project: {description:'Default description'},
};

export default Info;