import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import {
  TextButton
} from '../../components/index.jsx';
import { Link } from "react-router-dom";
import { filterSelector } from "../redux/selectors/filter.selector";
import * as styles from "./nav.module.scss";
import urljoin from "url-join";

const Nav = ({ match }) => {
  const { tabs, activeTab } = useSelector(filterSelector);

  const goToProjects = useCallback(() => {
    window.location = "/home";
  });

  return (
    <header>
      <div> <TextButton onClick={goToProjects}>  Back to My Projects</TextButton></div>
      <nav>
        {tabs.map((d, i) => {
          return (
            <div
              key={i}
              className={classnames({
                [styles.active]: activeTab === d.url,
              })}
            >
              <Link to={urljoin(match.url, d.url)} className="bold">
                {d.label}
              </Link>
            </div>
          );
        })}
      </nav>
    </header>
  );
};
export default Nav;
