/** Static sort functions */

const getParseFloat = (...args) => {
	let func = Number.parseFloat;
	if(!func) func = parseFloat;
	return func(...args);
};

export default class Sort {

	static isNil(val) {
		return (val === null || val === '' || val === undefined);
	}

	static isZero(val) {
		return val === 0;
	}

	static isUnknown(val) {
		return Sort.isNil(val) || val.toLowerCase().indexOf('unknown') >= 0;
	}

	static nilCompare(a, b) {
		if(Sort.isNil(a) && Sort.isNil(b)) return 0;
		if(Sort.isNil(b)) return -1;
		if(Sort.isNil(a)) return 1;

		return null;
	}

	static nanCompare(a, b) {
		if(isNaN(a) && isNaN(b)) return 0;
		if(isNaN(b)) return -1;
		if(isNaN(a)) return 1;

		return null;
	}

	static zeroCompare(a, b) {
		if(Sort.isZero(a) && Sort.isZero(b)) return 0;
		if(Sort.isZero(b)) return -1;
		if(Sort.isZero(a)) return 1;

		return null;
	}

	static unknownCompare(a, b) {
		if(Sort.isUnknown(a) && Sort.isUnknown(b)) return 0;
		if(Sort.isUnknown(b)) return -1;
		if(Sort.isUnknown(a)) return 1;

		return null;
	}

	static compare(a, b, dir) {
		if(a > b) {
			return 1*dir;
		}
		if(a == b) {
			return 0;
		}
		return -1*dir;
	}

	static basic(sorter, dir) {
		return (itemA, itemB) => {
			let a = itemA[sorter], b = itemB[sorter];
			let nilValue = Sort.nilCompare(a, b);
			if(nilValue !== null) return nilValue;

			return Sort.compare(a.toLowerCase(), b.toLowerCase(), dir);
		};
	}

	static fixed(sorter, dir) {
		return (itemA, itemB) => {
			return 0;
		};
	}

	static numeric(sorter, dir) {
		return (itemA, itemB) => {
			let a = itemA[sorter], b = itemB[sorter];
			let nilValue = Sort.nilCompare(a, b);
			if(nilValue !== null) return nilValue;

			a = getParseFloat(a), b = getParseFloat(b);
			nilValue = Sort.nanCompare(a, b);
			if(nilValue !== null) return nilValue;

			return dir*(a-b);
        };
	}

	static pathwayOrder(sorter, dir) {
		return (itemA, itemB) => {
			let a = itemA[sorter], b = itemB[sorter];
			let nilValue = Sort.nilCompare(a, b);
			if(nilValue !== null) return nilValue;

			let chemA = itemA.chemicalName, chemB = itemB.chemicalName;
			nilValue = Sort.unknownCompare(chemA, chemB);
			if(nilValue !== null) return nilValue;

			a = getParseFloat(a), b = getParseFloat(b);
			nilValue = Sort.nanCompare(a, b);
			if(nilValue !== null) return nilValue;

			nilValue = Sort.zeroCompare(a, b);
			if(nilValue !== null) return nilValue;

			return dir*(a-b);
        };
	}

	static stats(sorter, dir, statsField="pValue") {
		return (itemA, itemB) => {
			let a = itemA[sorter], b = itemB[sorter];
			let nilValue = Sort.nilCompare(a, b);
			if(nilValue !== null) return nilValue;

			a = getParseFloat(a[statsField]), b = getParseFloat(b[statsField]);
			nilValue = Sort.nanCompare(a, b);
			if(nilValue !== null) return nilValue;

			return dir*(a-b);
		};
	}
}