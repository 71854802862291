import React, { useCallback, useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import queryString from 'query-string';
import loading from './loading.svg';
import TransitionHeader from '../components/TransitionHeader.jsx';
import * as enums from '../enums/Enums';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger.js';
import { getToken } from '@/middleware/requests';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/auth.hook.js';
import { Button } from '@/components';

export const Callback = ({ currentUser }) => {
	const history = useHistory();
	const { location } = history;
	const auth = useAuth();
	const [url, setUrl] = useState(null);
	const logger = useLocalAuditLogger();
	const db = new Cookies();

	useEffect(() => {
		if (!url) return;
		history.push(url);
	}, [url]);

	useEffect(() => {
		const parsedHash = queryString.parse(location.hash);
		//Error check for Rejection or Decision Decline
		/* istanbul ignore next */
		if (parsedHash.error) {
			if (parsedHash.error_description.includes("email we have sent you to continue to login.")) {
				history.replace("/verifyemail");
				return;
			}
			history.replace("/");
			return;
		}

		auth.setSession(parsedHash);
		
		const returnUrl = localStorage.getItem("returnUrl");
		if (returnUrl) {

			db.set(`binf_auth_cookie`, getToken(), {
				path: '/',
				sameSite: 'strict',
				domain: window.location.hostname === "localhost" ? "localhost" : "metabolon.com",
				secure: true
			});
			localStorage.removeItem("returnUrl");
			window.location = returnUrl;
			return;
		}

		let decodedToken = jwtDecode(parsedHash.id_token);
		/* istanbul ignore next */
		if (decodedToken["https://portal.metabolon.com/user_metadata"] && decodedToken["https://portal.metabolon.com/user_metadata"].signupSource) {
			let signupSource = decodedToken["https://portal.metabolon.com/user_metadata"].signupSource;
			localStorage.setItem('signup_source', signupSource);
		}

		localStorage.setItem('username', decodedToken.nickname);
		localStorage.setItem('email', decodedToken.name);
		localStorage.setItem('avatar', decodedToken.picture);
		setPermissions();
	}, [location.hash]);

	/* istanbul ignore next */
	const goTo = (route) => () => {
		history.replace(`/${route}`);
	};

	useEffect(() => {
		setPermissions();
	}, [currentUser]);

	const setPermissions = useCallback(() => {
		if (!currentUser) {
			return;
		}

		logUserLogin();
		if (history.location.state) {
			setUrl(history.location.state.from.pathname);
			return;
		}
		setUrl("/home");
	}, [currentUser]);

	const logUserLogin = useCallback(() => {
		if (localStorage.getItem("signupAction") == "create") {
			logger.logNewUser();
		} else if (localStorage.getItem("lastAuthAction") == "login") {
			logger.logUserAction(enums.AUDITLOG_USERACTION_TYPES.LOGIN);
		}
		localStorage.removeItem("lastAuthAction");
		localStorage.removeItem("signupAction");
	}, [logger]);

	const style = {
		position: 'absolute',
		display: 'block',
		justifyContent: 'center',
		height: '100vh',
		width: '100vw',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'white',
	};

	const buttonStyle = {
		width: '80px',
		height: '40px'
	};

	return (
		<div data-testid="callback">
			<TransitionHeader />
			<div style={style}>
				<div>
					<b>Working Hard To Configure Your Preferences ...</b>
				</div>
				<div>
					<img src={loading} alt="loading" />
				</div>
				<div>
					<Button style={buttonStyle} onClick={goTo('home')} >Home</Button>
				</div>
			</div>
		</div>

	);
};

export default Callback;