'use strict';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ProjectSearchBox } from '../components/controls/ProjectSearchBox.jsx';
import * as config from '../config/env.config';
import { searchResultsFetchData } from '../redux/actions/searchResults';
import * as actionTypes from '../redux/actions/actionTypes';
import { hashString } from '../utils/StringUtils';
import { Log, useLogger } from '../utils/Logger';
import { AppInsightLogLevel } from '../enums/Enums';
import { searchResultsSelector } from '../redux/selectors/searchResults.selector';
import { setSearchId } from '@/redux/reducers/searchResults.reducer.js';
import urljoin from 'url-join';

export const ProjectSearch = ({ dataModel, id, ...props }) => {
	const logger = useLogger();
	const timeoutRef = useRef(null);
	const [mapData, setMapData] = useState({});

	const [searchModel, setSearchModel] = useState({});
	const [searchText, setSearchText] = useState('');
	const [searchData, setSearchData] = useState([]);
	const dispatch = useDispatch();


	const logSearchMetric = (query) => {
		const logViewChange = new Log();
		logViewChange.SetLevel(AppInsightLogLevel.EVENT);
		logViewChange.SetName('Global_Search_Query');
		logViewChange.AddProperty('Query', hashString(query));
		logger.doLog(logViewChange);
	};

	useEffect(() => {
		setMapData({
			projectCode: 'title',
			projectTitle: 'detail',
			projectId: 'id'
		});
		setSearchModel({
			apiEndpoint: config.API_ENDPOINT_PROJECT,
			actionTypeSuccess: actionTypes.SEARCHRESULTS_FETCH_DATA_SUCCESS,
			pageDetailRoute: 'project'
		});
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [dataModel]);

	const getProjectsData = useCallback(async (text) => {
		logSearchMetric(text);
		dispatch(setSearchId(id));
		dispatch(searchResultsFetchData(urljoin(config.API_ENDPOINT_GLOBAL_SEARCH, text.toUpperCase())));
	}, [dispatch, id, searchModel]);

	const searchAPIForData = useCallback(async (text) => {
		setSearchText(text);
		if (text.length < props.minSearchChars && props.showResults) {
			setSearchData([]);
			return;
		}

		switch (dataModel) {
			case 'projects':
				await getProjectsData(text);
				break;
		}
	}, [dataModel, getProjectsData, props.showResults, props.minSearchChars]);

	const handleSearchText = useCallback((text) => {
		if (props.onChange) props.onChange(text);
		searchAPIForData(text);
	}, [props.onChange, searchAPIForData]);

	const keyUpHandler = useCallback((e) => {
		clearTimeout(timeoutRef.current);

		let text = '';

		if (e.target) {
			text = e.target.value;
		}

		if (props.SearchDelay) {
			timeoutRef.current = setTimeout(() => {
				handleSearchText(text);
			}, 800);
		} else {
			handleSearchText(text);
		}
	}, [handleSearchText]);

	const handleCancel = useCallback(() => {
		if (props.handleCancel) {
			props.handleCancel();
		}
	}, []);

	return (
		<ProjectSearchBox
		id={id}
			handleCancel={handleCancel}
			onKeyUp={keyUpHandler}
			{...props}
			hintText={props.hintText}
			showResults={props.showResults}
			mapData={mapData}
			itemClick={props.itemClick}
			dataSource={searchData}
		/>
	);
};

ProjectSearch.propTypes = {
	/** Unique identifier for this instance. */
	componentId: PropTypes.string,
	/** Array of discover panel project ids. */
	discoveryPanels: PropTypes.array,
	/**Data Model to Search */
	dataModel: PropTypes.oneOf(['projects', 'files', 'results', 'managedUsers', 'managedProjects']).isRequired,
	/** Least amount of charcters before search */
	minSearchChars: PropTypes.number,
	/**Hide Results dropdown */
	hideResults: PropTypes.bool,
	/**Function to handle text change in dropdown, outside of the api call */
	onChange: PropTypes.func,
	/**Function to handle search results click */
	itemClick: PropTypes.func,
	/** Show Search results in dropdown */
	showResults: PropTypes.bool,
	/** Search hint text */
	hintText: PropTypes.string,
	/**Delay Search */
	SearchDelay: PropTypes.bool
};

ProjectSearch.defaultProps = {
	discoveryPanels: [],
	minSearchChars: 3,
	hideResults: false,
	 
	onChange: (item) => { },
	 
	itemClick: (item) => { },
	showResults: true,
	hintText: 'Search',
	SearchDelay: false
};
