'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Card.scss';
import Label from '../../components/controls/Label.jsx';
import Icon from '../../components/controls/Icon.jsx';
import Material from '../../components/controls/Material.jsx';
import { Logger, Log } from '../../utils/Logger';
import {AppInsightLogLevel} from '../../enums/Enums';
const logger = new Logger();

export class ResourceCard extends Component {
  
  constructor(props) {
    super(props);
  }

  /** istanbul ignore next */
  logLink(logLink, location ){
    //Log outside link
    let logLinkClick = new Log();
    logLinkClick.SetLevel(AppInsightLogLevel.EVENT);
    logLinkClick.SetName('LinkClick');
    logLinkClick.AddProperty('Destination',logLink);
    logLinkClick.AddProperty('ClickLocation',location);
    logger.doLog(logLinkClick);
  }

  render() {
    const {title, desc, link} = this.props;
    const log1 = () => { this.logLink(link, 'heading');};
    const log2 = () => { this.logLink(link, 'arrow');};
     /*eslint-disable */
    return (
        <div className="ResourceCard">
          <a onClick={log1} target="_blank" href={link}><Label color="primary" size="large" className="title">{title}</Label></a>
          <div className="desc">{desc}</div>
          <a onClick={log2} className="link" target="_blank" href={link}><Material color="primary" size="large" icon="arrow_forward"/></a>
        </div>
    );
     /*eslint-enable */
  }
}

ResourceCard.propTypes = {
    /** The resource title*/
    title: PropTypes.string.isRequired,

    /** The resource descriptiion */
    desc: PropTypes.string,

    /** The resource link */
    link: PropTypes.string.isRequired
};

export default ResourceCard;
