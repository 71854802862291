'use strict';

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useResize } from '@/hooks/window.hooks.jsx';
import Select from '../controls/Select.jsx';
import ScrollCalculator from '../scroll/ScrollCalculator.jsx';
import DynamicTableHelper from './DynamicTableHelper.jsx';
import TableHeader from './TableHeader.jsx';
import VList from '../virtualization/VList.jsx';
import SettingsMap from '@/utils/SettingsMap.js';
import pdfMake from '@/utils/pdfMake';
import './DynamicTable.scss';
import { Logger, Log } from '@/utils/Logger';
import { AppInsightLogLevel } from '@/enums/Enums';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger.js';
const logger = new Logger();

const DynamicTable = ({ onItemToggle, ...props }) => {
	const [resizeListener, size] = useResize();
	const { logExportStatsTable } = useLocalAuditLogger();
	const [index, setIndex] = useState(props.index);
	const [selection, setSelection] = useState(props.selection || props.defaultSelection);
	const [selections, setSelections] = useState(props.selections || props.defaultSelections);
	const [sorter, setSorter] = useState(props.sorter);
	const [sortDir, setSortDir] = useState(props.sortDir);
	const [sorting, setSorting] = useState(false);
	const [data, setData] = useState(props.dataset);
	const [worker, setWorker] = useState(null);
	const [container, setContainer] = useState(null);

	useEffect(() => {
		if (props.webWorker && !worker) {
			try {
				const w = new Worker(new URL('./DynamicTable.worker.js', import.meta.url), { format: 'es', type: 'module' });
				w.onmessage = onWorkerMessage;
				setWorker(w);
			} catch (e) {
				console.log(e);
			}
		}

		return (() => {
			if (worker) worker.terminate();
		});
	}, []);

	useEffect(() => {
		setSorting(false);
		if (worker) {
			onHeaderClick(sorter, index, sortDir, true);
		}
	}, [worker]);

	useEffect(() => {
		updateData(props.dataset);
	}, [props.datasetId, props.dataset, props.activeId]);

	/* useEffect(() => {
		if (!sorting) onHeaderClick(sorter, index, sortDir);
	}, [data]); */

	useEffect(() => {
		setSelections(props.selections);
	}, [props.selections]);

	useEffect(() => {
		postToggle();
	}, [selections]);

	useEffect(() => {
		if (props.onRowClick && selection) props.onRowClick(selection);
	}, [selection]);

	useEffect(() => {
		if (!sorting) return;
		if (worker) {
			//worker sort
			let field = props.fields.find(f => f.name === sorter);
			let comparator = 'basic',
				comparatorOptions;
			if (field && field.comparator) {
				comparator = field.comparator;
				comparatorOptions = field.comparatorOptions;
			}
			//comparator = typeof comparator === "string" ? Sort[comparator](headerName, sortDir) : comparator(headerName, sortDir);

			worker.postMessage({
				type: 'sort',
				sorter: sorter,
				sortDir,
				data: props.dataset,
				comparator,
				comparatorOptions,
			});
		}
		else {
			setData(DynamicTableHelper.sort(props.dataset, props.fields, sorter, sortDir));
			setSorting(false);
		}
	}, [sorting, sorter, worker, props.testId]);

	const updateData = (dataset, callback) => {
		setData(dataset);
	};

	const onExportClick = (fileType) => {
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName("PageEvent_StatsTable");
		logFilterChange.AddProperty("ExportData", true);
		logger.doLog(logFilterChange);

		exportTable(fileType, null, null);
	};

	const exportTable = useCallback((fileType, headers = null, format = null) => {

		switch (fileType.name) {
			default:
			case 'pdf':
			case 'xlsx':
				if (props.webWorker) {
					if (!worker) break;

					headers = headers || props.fields.map(field => {
						let header = typeof field.header === "string" ? field.header : (field.header.text || field.header.name);
						return { name: field.name, value: field.fieldValue, header: header.replace(/\<br\/\>/g, " ") };
					});
					worker.postMessage({
						type: 'export',
						headers,
						format,
						fileType,
						data: DynamicTableHelper.filterData(data, props.filters),
						colors: SettingsMap.colors
					});
				}
				break;

			case 'jpg':
				DynamicTableHelper.HTMLConverter().downloadJPG(container, null, props.exportFilename);
				break;

			case 'png':
				DynamicTableHelper.HTMLConverter().downloadPNG(container, null, props.exportFilename);
				break;

			case 'svg':
				DynamicTableHelper.HTMLConverter().downloadSVG(container, null, props.exportFilename);
				break;
		}
		return onExport(fileType);
	}, [worker]);

	const onExport = fileType => {
		if (props.onExport) props.onExport();
		return fileType.name;
	};

	const onWorkerMessage = async (e) => {
		let { data } = e;
		switch (data.type) {
			case 'sorted':
				setData(data.data);
				setSorting(false);
				break;

			case 'exported':
				onExportComplete(data.data);
				break;

			case 'exportedPDF':
				onExportPDFComplete(data.data);
				break;
		}
	};

	const onExportComplete = async ({ columns, data }) => {
		//setExportData([data]);
		//Audit log
		let fileName = props.exportFilename.replace(/\s/g, '') + '.xlsx';
		let projectId = localStorage.getItem("ProjectId");
		await writeXlsxFile(data, { columns, fileName });
		logExportStatsTable(projectId, fileName);
	};

	const onExportPDFComplete = useCallback((data) => {
		pdfMake.createPdf(data).download(props.exportFilename + '.pdf');

		//Audit log
		let filename = props.exportFilename.replace(/\s/g, '') + '.pdf';
		let projectId = localStorage.getItem("ProjectId");
		logExportStatsTable(projectId, filename);
	}, [logExportStatsTable]);

	const generateClassNames = () => {
		const { className, wrapRows, customScroll } = props;
		let classNames = ['DynamicTable'];

		if (className) classNames.push(className);
		if (wrapRows) classNames.push('wrapped');
		if (customScroll) classNames.push('scroller');

		return classNames;
	};

	const generateClassString = () => {
		return generateClassNames().join(' ');
	};

	const onHeaderClick = (headerName, n, sortDirection, initialSort = false) => {
		sortDirection = sortDirection || (headerName == sorter ? sortDir * -1 : 1);

		let direction = sortDirection == 1 ? "ASC" : "DESC";
		if (!initialSort) {
			let logSort = new Log();
			logSort.SetLevel(AppInsightLogLevel.EVENT);
			logSort.SetName(props.viewName);
			logSort.AddProperty("HeaderSort", direction);
			logSort.AddProperty("HeaderName", headerName);
			logger.doLog(logSort);
		}

		setSorter(headerName);
		setSortDir(sortDirection);
		setIndex(n);
		setSorting(true);
		if (props.onHeaderClick) props.onHeaderClick(headerName, sortDirection);
	};

	const onToggleAll = useCallback((toggle) => {
		const { dataset, primaryKey } = props;

		const nextSelections = !toggle ? [] : dataset.map(item => item[primaryKey]);
		if (!props.selections) setSelections(nextSelections);
		else if (onItemToggle) onItemToggle(nextSelections);
	}, [onItemToggle, props.dataset, props.primaryKey, props.selections, props.onItemToggle]);

	const toggleItem = useCallback((itemKey) => {
		const currentSelections = props.selections || selections;
		const n = currentSelections.indexOf(itemKey);
		const isChecked = n >= 0;
		let nextSelections = currentSelections.concat();

		if (!isChecked) nextSelections.push(itemKey);
		else nextSelections.splice(n, 1);

		let newSelection = itemKey;

		if (!props.selections) setSelections(nextSelections);
		else {
			if (onItemToggle) onItemToggle(nextSelections);
		}

		if (!props.selection) updateSelection(newSelection);

	}, [onItemToggle, props.selection, props.selections, selections]);

	const postToggle = useCallback(() => {
		if (props.onItemToggle) props.onItemToggle(selections);
	}, [props.onItemToggle, selections]);

	const onRowClick = (id) => {
		//let id = e.currentTarget.id;
		toggleItem(id);
	};

	const updateSelection = (id) => {
		let n = selections.indexOf(id);
		if (props.onItemToggle && (n < 0 && id != null)) return;
		setSelection(id);
	};

	const createRowClicker = (onClick, id) => (e) => {
		onRowClick(id);
		if (onClick) onClick(id);
	};

	const itemRenderer = (data, n, itemProps) => {
		const { primaryKey, activeIndex } = props;
		let selected = false;
		if (primaryKey && selections.indexOf(data[primaryKey]) >= 0) selected = true;

		let fields = props.fields.map((fieldData, i) => {
			let fieldRenderer;
			if (fieldData.type && fieldData.type == 'select') {
				fieldRenderer = fieldData.renderer || DynamicTableHelper.defaultCheckRenderer;
				let checkProps = {
					showLabel: false,
					value: primaryKey ? data[primaryKey] : null,
					checked: selected,
				};
				return fieldRenderer(data, checkProps, i);
			}
			fieldRenderer = fieldData.renderer || DynamicTableHelper.defaultFieldRenderer;
			return fieldRenderer(data, fieldData.name, i);
		});

		let fullRowProps = props.rowProps ? props.rowProps(data) : {};
		let { testId, key, ...rowProps } = fullRowProps;
		rowProps.className = rowProps.className ? rowProps.className + ' table-row' : 'table-row';
		if (selected) rowProps.className += ' selected';
		if (activeIndex == n) rowProps.className += ' active';
		if (data[primaryKey] == selection) rowProps.className += ' current';
		key = key || data[primaryKey] || n;
		rowProps.tabIndex = n;
		rowProps.id = key;
		rowProps.onClick = createRowClicker(rowProps.onClick, key);
		return (
			<li key={key} data-testid={testId} {...rowProps} {...itemProps}>
				{fields}
			</li>
		);
	};

	const renderHeader = () => {
		const { activeColumn, fields, showHeader } = props;
		let checked = selections.length == data.length;
		let limbo = selections.length > 0 && !checked;

		if (!showHeader) return null;

		return <TableHeader
			activeIndex={activeColumn}
			key="header"
			checked={checked}
			limbo={limbo}
			current={index}
			dir={sortDir}
			headers={fields}
			onClick={onHeaderClick}
			onToggleAll={onToggleAll} />
	};

	const listRenderer = (items, listProps) => {
		return (
			<ul {...listProps}>
				{renderHeader()}
				{items}
			</ul>
		);
	};

	const { testId, canExport, customScroll, exportFilename, exportLabel, primaryKey, wrapRows, emptyLabel, resetScrollOnUpdate } = props;

	let statusLabel = null;
	if (!data.length)
		statusLabel = <h2 className="secondary empty-label">{emptyLabel}</h2>;
	else if (props.webWorker && sorting) {
		statusLabel = <h2 className="secondary empty-label">Operation in progress...</h2>;
	}

	let filteredData = DynamicTableHelper.filterData(data, props.filters);
	let table;
	if (wrapRows) {
		let dimensions = { ...size };
		dimensions.listHeight = props.listHeight;
		let headerStyle = !customScroll ? { width: `calc(100% - ${ScrollCalculator.scrollW}px)` } : null;
		table = (
			<div key={1} ref={setContainer} className={generateClassString()} data-testid={testId}>
				{props.children}
				<div className="header-wrapper">
					<ul className="header-table" style={headerStyle}>
						{resizeListener}
						{renderHeader()}
					</ul>
				</div>
				{statusLabel !== null && statusLabel}

				{statusLabel === null &&
					<VList
						dimensions={dimensions}
						primaryKey={primaryKey}
						dataset={filteredData}
						itemRenderer={itemRenderer}
					/>
				}
			</div>
		);
	}

	else {
		table = <div key={1} ref={setContainer} className={generateClassString()} data-testid={testId}>
			{props.children}
			{statusLabel !== null && renderHeader()}
			{statusLabel !== null && statusLabel}
			{statusLabel === null && listRenderer(filteredData.map((itemData, n) => {
				return itemRenderer(itemData, n);
			}), { className: 'item-list' })}
		</div>;
	}

	let formats = props.exportFormats.map(name => { return { name, className: name }; });

	return [
		(!canExport ? null :

			<Select
				key={0}
				className="export right primary"
				title="Export"
				defaultLabel={exportLabel}
				options={formats}
				updateLabel={false}
				onSelect={onExportClick} />

		),
		table
	];
};

DynamicTable.propTypes = {
	/** An array of objects representing the fields for each table entry*/
	dataset: PropTypes.array.isRequired,

	/** An array of objects that details how the fields from the dataset are rendered*/
	fields: PropTypes.arrayOf(PropTypes.shape({
		/** The name of the field */
		name: PropTypes.string.isRequired,
		/** The text to display in the header, defaults to field.name */
		header: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				text: PropTypes.string,
				className: PropTypes.string
			}),
		]),
		/** Function for custom rendering, should expect a data entry object, field name, and index*/
		renderer: PropTypes.func,

		/** Function for custom sorting, should expect two arguments that are each a field of a member of the dataset*/
		comparator: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	})).isRequired,

	/** index of the active column */
	activeColumn: PropTypes.number,

	/** Array of filter functions used to filter the data in the table*/
	filters: PropTypes.arrayOf(PropTypes.func),

	/** Primary key by which to reference dataset entries **/
	primaryKey: PropTypes.string,

	/** Function to invoke when an item is toggled **/
	onItemToggle: PropTypes.func,

	/** Function to invoke when a row is clicked **/
	onRowClick: PropTypes.func,

	/** function used to gather props to send to each table item container. Should expect a data entry object*/
	rowProps: PropTypes.func,

	/** array of primary keys representing items that should be pre-selected*/
	selections: PropTypes.array,

	/** data field by which table entries are sorted **/
	sorter: PropTypes.string,

	/** Integer indicating the direction of the sort **/
	sortDir: PropTypes.number,

	/** The active header index **/
	index: PropTypes.number,

	/** Whether the rows should be wrapped into a scrolling container **/
	wrapRows: PropTypes.bool,

	/** Message to display when the table dataset is empty **/
	emptyLabel: PropTypes.string,
	/** Name of Parent View for logging **/
	viewName: PropTypes.string.isRequired,

	/** file name prefix of the exported file*/
	exportFilename: PropTypes.string,

	/** Whether custom scroll bar is used over native */
	customScroll: PropTypes.bool,

	exportFormats: PropTypes.array,

	/** Whether the scroll position is reset when content is updated */
	resetScrollOnUpdate: PropTypes.bool,

	/** Whether this table has an export control*/
	canExport: PropTypes.bool,

	/** Label for the export button*/
	exportLabel: PropTypes.node,

	datasetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	showHeader: PropTypes.bool,

	webWorker: PropTypes.bool,
};

DynamicTable.defaultProps = {
	showHeader: true,
	activeColumn: -1,
	selections: [],
	sorter: null,
	datasetId: null,
	sortDir: 1,
	index: -1,
	filters: [],
	wrapRows: false,
	emptyLabel: "This dataset is empty",
	webWorker: false,
	viewName: "NotSet",
	canExport: false,
	exportFormats: ['pdf', 'xlsx'],
	customScroll: false,
	listHeight: 100,
	resetScrollOnUpdate: false,
	exportLabel: (<span className="white">Export Data&nbsp;&nbsp; <i className="fa fa-download" /></span>),
};

export default DynamicTable;