import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import loading from './loading.svg';
import TransitionHeader from '../../components/TransitionHeader.jsx';
import { usePendo } from '../../hooks/usePendo.js';
import ErrorPage from '../../views/Error.jsx';
import './SecurityCheck.scss';

export const SecurityCheck = ({ user, error, isLoading }) => {
	const pendo = usePendo();
	const history = useHistory();
	const [url, setUrl] = useState(null);

	const setPermissions = useCallback(() => {
		pendo.initialize();
		if (history.location?.state) {
			setUrl(history.location.state.from.pathname);
			return;
		}
		setUrl("/home");
	}, [history]);

	useEffect(() => {
		if (isLoading) return;
		if (localStorage.getItem('id_token')) setPermissions();
		else setUrl("/login");
	}, [isLoading, setPermissions, user]);

	useEffect(() => {
		if (!url) return;
		history.push(url);
	}, [url]);

	return (
		<div data-testid="security-check">
			<TransitionHeader />
			<div className="SecurityCheckContainer">
				{error ?
					<div> <ErrorPage history={history} /></div>
					:
					<div>
						<div><b>Working Hard To Configure Your Preferences ...</b></div>
						<div><img src={loading} alt="loading" /></div>
					</div>
				}
			</div>
		</div>
	);
};

export default SecurityCheck;

