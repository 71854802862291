'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import urljoin from 'url-join';
import { Logger, Log } from '../../utils/Logger';
import { getBlob, getBlobByIds, post, put } from '../../middleware/requests';
import * as ErrorLogger  from '../../utils/ErrorLogger';

const logger = new Logger();

/** Action Creators for files */
export function filesHasErrored(bool) {
    return {
        type: actionTypes.FILES_HAS_ERRORED,
        hasErrored: bool
    };
}

export function filesIsLoading(bool) {
    return {
        type: actionTypes.FILES_IS_LOADING,
        isLoading: bool
    };
}

export function selectedFilesIsLoading(bool) {
    return {
        type: actionTypes.SELECTED_FILES_IS_LOADING,
        selectedFilesIsLoading: bool
    };
}

export function filesFetchDataSuccess(response, authenticated = false) {
  return {
      type: actionTypes.FILES_FETCH_DATA_SUCCESS,
      response,
      authenticated
  };
}

/* istanbul ignore next */
export function filesFetchData(query = null) {
  let apiEndpoint = config.API_ENDPOINT_FILES;
  if (query) apiEndpoint = urljoin(config.API_ENDPOINT_FILES, query);

  return {
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.FILES_IS_LOADING, actionTypes.FILES_FETCH_DATA_SUCCESS, actionTypes.FILES_HAS_ERRORED]
    }
  };
}

/* istanbul ignore next */
export function filesFetchDataAdmin(query = null) {
  let apiEndpoint = config.API_ENDPOINT_FILES_ADMIN;
  if (query) apiEndpoint = urljoin(config.API_ENDPOINT_FILES_ADMIN, query);

  return {
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.FILES_IS_LOADING, actionTypes.FILES_FETCH_DATA_SUCCESS, actionTypes.FILES_HAS_ERRORED]
    }
  };
}

export const setFilesFilter = filter => {
  return {
    type: actionTypes.FILES_SET_FILTER,
    filter
  };
};

/* istanbul ignore next */
export const fetchSingleFile = (fileId, projectId, fileName, baseUrl = config.API_ENDPOINT_FILES_DOWNLOAD) => {
  let endpoint = urljoin(baseUrl, projectId, fileId);
  return async (dispatch) => {
    dispatch(filesIsLoading(true));
    const response = await getBlob(endpoint, fileName);
    const { error } = response;
    dispatch(filesIsLoading(false));
    if (error) {
      ErrorLogger.logError('fetchSingleFile', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};

/* istanbul ignore next */
export const AdminFetchSingleFile = (fileId, projectId, fileName) => {
  return fetchSingleFile(fileId, projectId, fileName, config.API_ENDPOINT_FILES_ADMIN_DOWNLOAD);
};

 /* istanbul ignore next */
export const fetchFilesByIds = (project, fileIds, baseUrl = config.API_ENDPOINT_FILES_DOWNLOAD) => {
  let endpoint = urljoin(baseUrl, project.projectId, 'fileids');
  return async (dispatch) => {
    dispatch(selectedFilesIsLoading(true));
    const response = await getBlobByIds(endpoint, `${project.projectCode}.zip`, fileIds);
    const { error } = response;
    dispatch(selectedFilesIsLoading(false));
    if (error) {
      ErrorLogger.logError('fetchFiles: No Files Downloaded');
    }
    return response;
  };
};

 /* istanbul ignore next */
export const fetchAllFiles = (project) => {
  let endpoint = urljoin(config.API_ENDPOINT_FILES_DOWNLOAD_ALL_BY_PROJECT, project.projectId);
  return async (dispatch) => {
    dispatch(filesIsLoading(true));
    const response = await getBlob(endpoint, `${project.projectCode}.zip`);
    const { error } = response;
    dispatch(filesIsLoading(false));
    if (error) {
      ErrorLogger.logError('fetchAllFiles', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};

export const filesSaveDataAdmin = (filesData) => {
  let endpoint = config.API_ENDPOINT_FILES_ADMIN_SAVE;
  if (!filesData) return;

  return async (dispatch) => {
    dispatch(filesIsLoading(true));
    const response = await put(endpoint, filesData);
    const { error } = response;
    dispatch(filesIsLoading(false));
    if (error) {
      ErrorLogger.logError('filesSaveDataAdmin', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};

export const uploadAttachment = (projectId, requestFile, comment) => {
  let endpoint =  urljoin(config.API_ATTACHMENT_UPLOAD, projectId);
  const formData = new FormData();
    formData.append('Instance', requestFile);
    formData.append('Comment', comment);

  return async (dispatch) => {
    const response = await post(endpoint, formData, false);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('uploadAttachment', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};