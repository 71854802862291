import React, { memo, useCallback, useMemo } from "react";
import {arc as d3arc} from "d3";
import { useSelector } from "react-redux";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";

const HoverArea = ({ handleEvents }) => {
  const { metaboliteCoords, associationCoords } =
  useSelector(explorerSelector);
    const viewPort = useSelector(viewPortSelector);

  const hoverArc = useCallback(
    (d, angle, type, length) => {
      const arc = d3arc()
        .innerRadius(() =>
          type === "metabolite"
            ? (viewPort.metabolitesRadius / viewPort.radiusOffset) * 0.9
            : viewPort.barOuterRadius * 0.9
        )
        .outerRadius(() =>
          type === "metabolite"
            ? viewPort.innerRadius
            : (viewPort.associationRadius / viewPort.radiusOffset) * 1.12
        )
        .startAngle(() =>
          type === "metabolite"
            ? angle
            : viewPort.blockWidth / 2 +
              angle -
              ((2 * Math.PI - viewPort.middleSpace) / length) * 0.5
        )
        .endAngle(() =>
          type === "metabolite"
            ? angle + viewPort.blockWidth
            : viewPort.blockWidth / 2 +
              angle +
              ((2 * Math.PI - viewPort.middleSpace) / length) * 0.5
        );
      return arc(d);
    },
    [
      viewPort.associationRadius,
      viewPort.barOuterRadius,
      viewPort.blockWidth,
      viewPort.innerRadius,
      viewPort.metabolitesRadius,
      viewPort.middleSpace,
      viewPort.radiusOffset,
    ]
  );

  const arcData = useMemo(() => {
    const m = metaboliteCoords.map((d) => {
      return {
        ...d,
        type: "metabolite",
        arc: hoverArc(d, d.angle, "metabolite", metaboliteCoords.length),
      };
    });
    const a = associationCoords.map((d) => {
      return {
        ...d,
        type: "association",
        arc: hoverArc(d, d.angle, "association", associationCoords.length),
      };
    });
    return m.concat(a);
  }, [associationCoords, hoverArc, metaboliteCoords]);

  return (
    <>
      {arcData.map((d, i) => {
        return (
          <path
            d={d.arc}
            key={i}
            stroke="red"
            fill="white"
            opacity="0"
            onMouseEnter={handleEvents}
            onClick={handleEvents}
            onMouseLeave={handleEvents}
            shapeRendering="optimizeSpeed"
            style={{ willChange: "d" }}
            data-value={
              d.type === "association" ? d.association : d.chemical_id
            }
            data-type={d.type}
            data-source={"hoverarea"}
            data-name={d.type === "association" ? d.association : d.metabolite}
          />
        );
      })}
    </>
  );
};

export default memo(HoverArea);
