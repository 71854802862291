import urljoin from 'url-join';
import {
	API_BASEPATH,
	API_ENDPOINT_PROJECT,
	API_ENDPOINT_SMART_PANELS_CDT,
	API_ENDPOINT_SMART_PANELS_FILE,
	API_ENDPOINT_SMART_PANELS_HEATMAP,
	API_ENDPOINT_SMART_PANELS_RESULTS,
	API_ENDPOINT_SMART_PANELS_SAMPLESETIDS
} from "../config/env.config";
import { callApi, getBlob } from "../middleware/requests";
import sample from "./data/rpdSample.json";
import { BIOSTRESS, LIVERFIBROSIS, MICROBIOME, KIDNEYFUNCTION } from "@/enums/Enums";

export const mockApi = {
	call: (mockResponseGenerator, fail = false, timeout = 50) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (fail) reject(mockResponseGenerator());
				else resolve(mockResponseGenerator());
			}, timeout);
		});
	},
};

export const fetchProject = (projectId) =>
	callApi(urljoin(API_ENDPOINT_PROJECT, projectId), true, 'GET');
	
export const fetchSample = (projectId, samplesetId) => mockApi.call(() => sample);

export const fetchSampleSets = async (projectId, productType = MICROBIOME) =>
	callApi(urljoin(API_ENDPOINT_SMART_PANELS_SAMPLESETIDS, `DiscoveryPanel_${productType}`, projectId), true, 'GET');

export const fetchAllSampleSets = async (projectId) => {
	let microbiome = [];
	try {
		microbiome = await fetchSampleSets(projectId);
		 
	} catch (e) { console.error(e); }

	let biostress = [];
	try {
		biostress = await fetchSampleSets(projectId, BIOSTRESS);
		 
	} catch (e) { console.error(e); }

	let liverfibrosis = [];
	try {
		liverfibrosis = await fetchSampleSets(projectId, LIVERFIBROSIS);
		 
	} catch (e) { console.error(e); }

	let kidneyfunction = [];
	try {
		kidneyfunction = await fetchSampleSets(projectId, KIDNEYFUNCTION);
		 
	} catch (e) { console.error(e); }

	return {
		[BIOSTRESS]: biostress,
		[MICROBIOME]: microbiome,
		[LIVERFIBROSIS]: liverfibrosis,
		[KIDNEYFUNCTION]: kidneyfunction,
	};
};

export const fetchResults = async (projectId, sampleSetId, productType = MICROBIOME) =>
	callApi(API_ENDPOINT_SMART_PANELS_RESULTS, true, 'POST', {
		"ProjectId": projectId,
		"SampleSetId": sampleSetId,
		"ProductType": `DiscoveryPanel_${productType}`,
	});
export const fetchMetabolites = async (productType = MICROBIOME) =>
	callApi(API_ENDPOINT_SMART_PANELS_FILE, true, 'POST', {
		"Type": "Metabolites",
		"FileVersion": "1.0",
		"Filename": "metabolites.json",
		"ProductType": `DiscoveryPanel_${productType}`
	});
export const fetchPathways = async (productType = MICROBIOME) =>
	callApi(API_ENDPOINT_SMART_PANELS_FILE, true, 'POST', {
		"Type": "Pathways",
		"FileVersion": "1.0",
		"Filename": "pathways.json",
		"ProductType": `DiscoveryPanel_${productType}`
	});
export const fetchAssociations = async (productType = MICROBIOME) =>
	callApi(API_ENDPOINT_SMART_PANELS_FILE, true, 'POST', {
		"Type": "Associations",
		"FileVersion": "1.0",
		"Filename": "associations.json",
		"ProductType": `DiscoveryPanel_${productType}`
	});

export const fetchAndDownloadCDTData = async (projectId, sampleSetId, productType = MICROBIOME) =>
	getBlob(API_ENDPOINT_SMART_PANELS_CDT, "Data Table.xlsx", 'POST', {
		"ProjectId": projectId,
		"SampleSetId": sampleSetId,
		"ProductType": `DiscoveryPanel_${productType}`
	});

export const fetchAndDownloadHeatmapData = async (projectId, sampleSetId, productType = MICROBIOME, invertedFoldChange = false) =>
	getBlob(API_ENDPOINT_SMART_PANELS_HEATMAP, "Heatmap.xlsx", 'POST', {
		"ProjectId" : projectId,
		"SampleSetId" : sampleSetId,
		"ProductType": `DiscoveryPanel_${productType}`,
		"DisplayExtraMeanPercent" : false,
		"FlipFoldChange" : invertedFoldChange
	});

export const fetchAndDownloadManifestTemplate = async (projectId) =>
	getBlob(urljoin(`${API_BASEPATH}`,'manifest', `file/download/${projectId}`), `ManifestTemplate.xlsx`);

