import stringHash from 'string-hash';
import DOMPurify from 'dompurify';

export function isGuid(stringToTest) {
	return /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi.test(stringToTest);
}

export function scrubHTML(html) {	
	return DOMPurify.sanitize(html);
}

export function hashString(stringToHash) {
	return stringHash(stringToHash);
}

export function strMapToObj(strMap) {
	let obj = Object.create(null);
	for (let [k, v] of strMap) {
		obj[k] = v;
	}
	return obj;
}

export function generateGuid() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

export function formatDisplayString(s) {
	if (s == null) return null;
	return s.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/');
}

export function makeNodeId(string) {
	return string.replace(/[\s,\^,\$,\\,\/,\(,\),\|,\?,\+,\*,\[,\],\{,\},\,,\.\;\:]+/g, '-');
}
export function dividendDivisorSwitch(string) {
	if (!string || string === "" || !string.includes("/")) return string;
	const [dividend, divisor] = string.split("/");
	return `${divisor}/${dividend}`;
}

export function formatBytes(bytes, decimals = 2) {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}