'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, EmptyState, Toaster } from '@/components/index.jsx';
import PublishFilesTable from './PublishFilesTable.jsx';
import { filesFetchDataAdmin, fetchAllFiles, fetchFilesByIds, filesSaveDataAdmin } from '@/redux/actions/files';
import './PublishingProjectFiles.scss';
import RefreshIndicator from '@/components/controls/RefreshIndicator.jsx';
import { Logger, Log } from '@/utils/Logger';
import { AppInsightLogLevel } from '@/enums/Enums';
const logger = new Logger();
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner.jsx';
import { Auditor } from '@/components/util/Auditor.jsx';

let timeout = null;
export class PublishingProjectFiles extends Auditor {

	constructor(props) {
		super(props);
		this.state = {
			project: props.project,
			selectedFileIds: [],
			visibleFiles: this.getUpdatedFiles(this.props.files),
			showFiles: false,
			showSearchDialog: false,
			fileChanges: [],
			isSavingFiles: false,
			errormsg: '',
			...super.state
		};
		//this.handleSearchKeyUp = this.keyUpHandler.bind(this);
		//this.onSearchChange = this.onSearchChange.bind(this);
		this.SaveAdminFiles = this.SaveAdminFiles.bind(this);
		this.updateFileChanges = this.updateFileChanges.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
		this.fetchAllFiles = this.fetchAllFiles.bind(this);
		this.fetchFilesById = this.fetchFilesById.bind(this);
	}

	componentDidMount() {
		if (this.props.project) {
			this.loadData(this.props);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.project?.projectId !== prevProps.project?.projectId) {
			this.loadData(this.props);
		}

		/* if (this.props.files !== this.state.visibleFiles) {
			const files = this.getUpdatedFiles(this.props.files);
			this.setState({
				visibleFiles: files,
				showFiles: true,
				publishFilesObject: files,
				preEditFilesState: { ...files }
			});
		} */
	}

	componentWillUnmount() {
		localStorage.removeItem("CurrentFilesProjectId");
		localStorage.removeItem("CurrentProjectPublishDate");
	}

	async loadData(currProps) {
		this.setState({ project: currProps.project });

		localStorage.setItem("CurrentFilesProjectId", currProps.project.projectId);
		localStorage.setItem("CurrentProjectPublishDate", currProps.project.publishDate);

		const filesRes = await this.props.fetchDataAdmin(currProps.project.projectId);
		const files = this.getUpdatedFiles(filesRes.response);
		this.setState({
			visibleFiles: files,
			showFiles: true,
			publishFilesObject: files,
			preEditFilesState: { ...files }
		});
	}

	getUpdatedFiles(files) {
		return files ? files.map(f => ({ ...f })) : [];
	}

	setSelectedFilesIds(selectedFiles) {
		this.setState({ selectedFileIds: selectedFiles });
	}

	/**API Methods */
	fetchAllFiles() {
		this.props.fetchAllFiles(this.props.project);
		let allFileNames = this.props.files.map(f => f.name);
		let allFileSizes = this.props.files.map(f => f.size);
		this.auditLogFileDownload(this.props.project.projectId, allFileNames);
		let logDownload = new Log();
		logDownload.SetLevel(AppInsightLogLevel.EVENT);
		logDownload.SetName('PageEvent_FileDownload_All');
		logDownload.AddProperty('Filesize', allFileSizes);
		logDownload.AddProperty("PublishDate", this.props.project.publishDate);
		logDownload.AddProperty('Count', allFileNames.length);
		logDownload.AddProperty('ProjectId', this.props.project.projectId);
		logger.doLog(logDownload);
	}

	fetchFilesById() {
		this.props.fetchFilesByIds(this.props.project, this.state.selectedFileIds);
		let filteredfileNames = this.props.files.filter(f => { if (this.state.selectedFileIds.includes(f.fileId)) { return f; } }).map(f => { return f.name; });
		let filteredFileSizes = this.props.files.filter(f => { if (this.state.selectedFileIds.includes(f.fileId)) { return f; } }).map(f => { return f.size; });
		this.auditLogFileDownload(this.props.project.projectId, filteredfileNames);
		let logDownload = new Log();
		logDownload.SetLevel(AppInsightLogLevel.EVENT);
		logDownload.SetName('PageEvent_FileDownload_Selected');
		logDownload.AddProperty('Filesize', filteredFileSizes);
		logDownload.AddProperty("PublishDate", this.props.project.publishDate);
		logDownload.AddProperty('Count', filteredFileSizes.length);
		logDownload.AddProperty('ProjectId', this.props.project.projectId);
		logger.doLog(logDownload);
	}

	/**Logging */
	auditLogFileDownload(projectId, listOfFileNames) {
		this.state.logger.logProjectFileDownload(projectId, listOfFileNames);
	}

	/**Actions */
	SaveAdminFiles() {
		this.setState({ isSavingFiles: true });
		let publishObject = this.state.publishFilesObject;
		publishObject.files = this.state.visibleFiles;
		let changedFiles = this.state.fileChanges;
		if (changedFiles.length < 1) { this.setState({ isSavingFiles: false }); return; }

		let newPubObject = { "instance": [...changedFiles], "comment": "PortalUI: Update from portal" };
		this.props.filesSaveDataAdmin(newPubObject).then(response => {
			if (response.error) {
				let msg = `Status: ${response.status}, Changes have failed to save.`;
				this.setState({ errormsg: 'ERROR: ' + msg });
				this.setState({ isSavingFiles: false });
				return;
			}
			//reload files after save
			this.refreshFiles(this.props.project.projectId).then(result => {
				this.setState({ isSavingFiles: false });
			});
		});

		this.showToaster("Saving File Edits", "Saving pre-published visibility settings for files.");
	}

	showToaster(title, body, autoClose = 2000) {
		toast(<Toaster title={title} body={body} />, { autoClose: autoClose });
	}

	//This passes the file changes to the parent
	updateFileChanges(fileChanges) {
		this.setState({ fileChanges: fileChanges });
		this.setState({ errormsg: "" });
	}

	refreshFiles(projectId) {
		//refresh files
		return new Promise((resolve, reject) => {
			this.props.fetchDataAdmin(projectId).then(fileRes => {
				const files = this.getUpdatedFiles(fileRes.response);
				this.setState({
					visibleFiles: files,
					showFiles: true,
				});
				resolve(true);
			});
		});
	}



	/**Loading Indicators */
	loadingStyle = {
		container: {
			display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	RefreshIndicatorDownloadAllButtonLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="downloadAll" color="primary"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/> </Button>
		</div>
	);

	RefreshIndicatorDownloadSelectedButtonLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button alt={true} className="downloadAll" color="primary"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/> </Button>
		</div>
	);

	RefreshIndicatorLoading = () => (
		<LoadingSpinner />
	);

	onCancelClick() {
		if (this.props.onCancelClick) {
			this.props.onCancelClick();
		}
	}

	render() {
		return (
			<div className="ProjectFiles">
				{super.render()}
				{this.state.visibleFiles ?
					<div className="TableView">
						{this.state.showFiles ? (
							<PublishFilesTable
								projectId={this.state.projectId}
								onCancelClick={this.onCancelClick}
								updateFileChanges={this.updateFileChanges}
								onSaveAdminFilesClick={this.SaveAdminFiles}
								isSavingFiles={this.state.isSavingFiles}
								viewName={"PageEvent_PublishFiles"}
								files={this.state.visibleFiles.concat()}
							/>) : <LoadingSpinner />
						}
					</div> : <EmptyState />
				}
			</div>
		);
	}
}

PublishingProjectFiles.propTypes = {
	/** project*/
	project: PropTypes.object,
	onCancelClick: PropTypes.func.isRequired
};

PublishingProjectFiles.defaultProps = {
	files: []
};

const mapStateToProps = state => {
	return {
		hasErrored: state.files.filesHasErrored,
		isLoading: state.files.filesIsLoading,
		isSelectedLoading: state.files.selectedFilesIsLoading
	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchDataAdmin: (params) => dispatch(filesFetchDataAdmin(params)),
		filesSaveDataAdmin: (params) => dispatch(filesSaveDataAdmin(params)),
		fetchAllFiles: (project) => dispatch(fetchAllFiles(project)),
		fetchFilesByIds: (project, fileIds) => dispatch(fetchFilesByIds(project, fileIds)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishingProjectFiles);
