'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormInput, Label } from '../../../components/index.jsx';
import './AddNewUser.scss';

export class AddNewUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.resetInputs = this.resetInputs.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

	componentWillReceiveProps(nextProps) {

		if(!nextProps.modalOpen){
      this.resetInputs();
		}
  }  

  
  changeFirstNameInput = (event) => { this.setState({firstName: event.target.value});}
  changeLastNameInput = (event) => { this.setState({lastName: event.target.value});}
  changeEmailInput = (event) => { this.setState({email: event.target.value});}
   
    onSubmit(){
      
      let user ={firstName: this.state.firstName.trim(), lastName: this.state.lastName.trim(), email: this.state.email.trim()};
  
      if(this.props.onAddUser) {
        this.props.onAddUser(user);
      }
      this.resetInputs();
    }

    onCancel(){
      this.resetInputs();
    }

    resetInputs() {
      document.querySelectorAll('.Input').forEach(function(input) {
        input.value = "";
      });
    }
    

  render() {
   
    return (
      <div className={"AddUsersContainer"}>
        <hr />
        <div className={"Name"} style={{display:'inline-block'}}><FormInput  width={'small'} placeHolder={'First Name'} onChange={this.changeFirstNameInput} /></div>  
        <div className={"Name"} style={{display:'inline-block', paddingLeft:'15px'}}><FormInput width={'small'} placeHolder={'Last Name'} onChange={this.changeLastNameInput} /></div>
        <div className={"Email"} ><FormInput width={'normal'} placeHolder={'Email'} onChange={this.changeEmailInput} /></div>
        <div className={"UserActions"}>
          <Button className={"alt"} onClick={this.onCancel} data-dismiss="modal">Cancel</Button>
          <Button  onClick={this.onSubmit} data-dismiss="modal" >Add User</Button>
        </div>
      </div>
    );
  }
}

AddNewUser.propTypes = {
    
        onCreateClick: PropTypes.func,
        onAddUser: PropTypes.func
    };
  
    AddNewUser.defaultProps = {
      onCreateClick: (item,e) => { 
         
        console.log('AddNewUser onCreateClick Event not defined!', item);
      },
  
      onAddUser: (user) => {
         
        console.log('AddNewUser onAddUser Event not defined!', user);
      }
  };


export default AddNewUser;
