'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import BinfButton from '@/components/buttons/BinfButton.jsx';

import { Link, UserOptions } from '../index.jsx';
import { ProjectSearch } from '@/containers/ProjectSearch.jsx';
import headerlogo from '../../theme/svg/MyMetabolon-white-small.svg';
import { Logger, Log } from '../../utils/Logger';
import * as enums from '../../enums/Enums';
import * as config from '../../config/env.config';
import './AppHeader.scss';
import { useLocation } from 'react-router';
const logger = new Logger();

export const AppHeader = (props) => {
	const location = useLocation();
	const [navIndex, setNavIndex] = useState(0);
	const [showUserOptions, setShowUserOptions] = useState(false);
	const [username] = useState(localStorage.getItem('username'));
	const [email] = useState(localStorage.getItem('email'));
	const [avatar] = useState(localStorage.getItem('avatar'));

	useEffect(() => {
		if (location.pathname.indexOf("resources") != -1) {
			setNavIndex(1);
		}
	}, [location.pathname])

	const handleClick = useCallback((e) => {
		e.stopPropagation()
		setShowUserOptions((prev) => !prev)
	}, [showUserOptions])

	const handleOutsideClick = useCallback((e) => {
		setShowUserOptions(false)
	}, [])

	useEffect(() => {	// Add event listener to handle clicks outside of the dropdown
		if (showUserOptions) document.addEventListener('click', handleOutsideClick, false);
		else document.removeEventListener('click', handleOutsideClick, false);
		return () => {
			document.removeEventListener('click', handleOutsideClick, false);
		}
	}, [showUserOptions]);

	const launchSurveyor = useCallback(() => {
		let logFilterChange = new Log();
		logFilterChange.SetLevel(enums.AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageView_Surveyor');
		logFilterChange.AddProperty('view', "Surveyor");
		logger.doLog(logFilterChange);

		/**LaunchSurveyor SSO */
		let surveyorURL = config.URL_SURVEYOR_SSO + '?token=' + localStorage.getItem("surveyor_token");
		window.open(surveyorURL);
	}, [])

	return (
		<div className="app-header">
			<div className="main-nav">
				<Link className="logo-container tertiary alt2" to="/home">
					<img src={headerlogo} alt="logo" />
				</Link>
			</div>
			<div className="sub-nav">
				<BinfButton alt2={true} color="white" />
				<div style={{ color: 'white', top: '-1px', paddingTop: '3px', paddingRight: '10px', margin: '0px', cursor: 'pointer' }} onClick={launchSurveyor} className=" primary normal text-tertiary launchSurveyor">Surveyor&#8482;</div>

				<div className="search" style={{ zIndex: '10' }}>
					<ProjectSearch id="header-search" searchDelay={true} dataModel={'projects'} iconColor={'common.white'} hintText={'Jump to...'} showResults={true} />
				</div>
				<div className="UserOptions">
					<Avatar onClick={handleClick} size={30} backgroundColor={'#ccc'} src={avatar} />
					{showUserOptions && <UserOptions {...props} username={username} email={email} />}
				</div>
			</div>

		</div>
	);
}

export default withRouter(AppHeader);