import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BIOVIZ_STATS } from '@/config/env.config';
import { getToken } from '@/middleware/requests';
import urljoin from 'url-join';

export const biovizApi = createApi({
	reducerPath: 'biovizApi',
	baseQuery: fetchBaseQuery({ baseUrl: urljoin(API_BIOVIZ_STATS) }),
	tagTypes: ['data'],
	endpoints: (builder) => ({
		fetchBiovizSampleSets: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: `projects/${projectId}/sample-sets`,
				method: 'GET',
			})
		})
	}),
});

export const {
	useFetchBiovizSampleSetsQuery,
} = biovizApi;