import urljoin from 'url-join';
import { API_BASEPATH } from '@/config/env.config';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';

export const spectralDataApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		fetchSpectralMetaData: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(API_BASEPATH, 'spectraldata', projectId),
				method: 'GET',
			})
		}),
		fetchSpectralUrl: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(API_BASEPATH, 'spectraldata', projectId, 'download'),
				method: 'GET',
			})
		}),
		
	}),
});

export const {
	useFetchSpectralMetaDataQuery,
	useFetchSpectralUrlQuery
} = spectralDataApi;