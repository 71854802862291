'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import DynamicTable from '@/components/controls/DynamicTable.jsx';
import DynamicTableHelper from '@/components/controls/DynamicTableHelper.jsx';
import DynamicTableCell from '@/components/controls/DynamicTableCell.jsx';
import { Button, Hollaback, Icon, Label, Toaster } from '@/components/index.jsx';
import './PublishFilesTable.scss';
import { toast } from 'react-toastify';

import RefreshIndicator from '@/components/controls/RefreshIndicator.jsx';
import { Auditor } from '@/components/util/Auditor.jsx';

export class PublishFilesTable extends Auditor {
	constructor(props) {
		super(props);

		this.state = {
			fileChanges: [],
			saveButtonDisabled: this.props.isSavingFiles,
			...super.state
		};

		this.onVisibilityClick = this.onVisibilityClick.bind(this);
		this.changeVisibility = this.changeVisibility.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
		this.registerChange = this.registerChange.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.files !== prevProps.files || this.props.isSavingFiles !== prevProps.isSavingFiles) {
			this.setState({ files: this.props.files });
			this.setState({ saveButtonDisabled: this.props.isSavingFiles });
		}
	}

	getFileTypeIcon(fileName) {
		let re = /(?:\.([^.]+))?$/;
		let extension = re.exec(fileName)[1];

		if (extension)
			extension = extension.toLowerCase();

		switch (extension) {
			case "xls":
			case "xlsx":
			case "csv":
				return "Xcel";
			case "doc":
			case "docx":
				return "Doc";
			case "png":
				return "PNG";
			case "gif":
				return "GIF";
			case "pdf":
				return "PDF";
			case "ppt":
			case "pptx":
				return "PPT";
			default:
				return extension;
		}
	}

	/**Click Handlers */
	onVisibilityClick(file) {
		file.customerVisible = !file.customerVisible;
		this.setState({ files: this.state.files }, this.changeVisibility(file, file.customerVisible));
	}

	changeVisibility(file, visibility) {
		let visSetting = visibility ? "Show" : "Hide";
		//Aggregates the change for logging/saving
		this.registerChange(file);
		// this.showToaster("Setting File Visibility","Setting Visibility ("+ visSetting +")" + " of" + file.name);
	}

	onSaveClick() {
		if (this.props.onSaveAdminFilesClick) {
			this.props.onSaveAdminFilesClick();
			//Reset file changes since now saved
			this.setState({ fileChanges: [] });
			this.state.logger.logProjectSave(this.props.projectId);
		}
	}

	onCancelClick() {
		if (this.props.onCancelClick) {
			this.props.onCancelClick();
		}
	}

	showToaster(title, body) {
		toast(<Toaster title={title} body={body} />);
	}

	fileTypeRenderer = (data, fieldName, n) => {
		let fileType = this.getFileTypeIcon(data.name);
		return (
			<DynamicTableCell className="type-cell" key={n}>
				<Label title={fileType} color="primary">{fileType}</Label>
			</DynamicTableCell>
		);
	};

	viewStatusRenderer = (data, fieldName, n) => {
		return (
			<DynamicTableCell className="type-cell" key={n}>
				<Hollaback data={data} onClick={this.onVisibilityClick}>
					<span className="ToggleVisibility">
						<span><Icon size={'xlarge'} color={data.customerVisible ? 'primary' : 'secondary'} icon={data.customerVisible ? 'toggle-on' : 'toggle-off'} /></span>
					</span>
				</Hollaback>
			</DynamicTableCell>
		);
	};

	//Aggregates the change for logging/saving
	registerChange(file) {
		let changes = this.state.fileChanges;

		let obj = changes.find(obj => obj.fileId == file.fileId);

		if (obj) {
			if (obj.customerVisible !== file.customerVisible) {
				return;
			} else {
				changes = changes.filter((e) => { return e.fileId !== file.fileId; });
			}
		}
		else { changes.push(file); }

		this.setState({ fileChanges: changes });
		//Pass changes up to parent
		if (this.props.updateFileChanges) {
			this.props.updateFileChanges(changes);
		}
	}

	/**Table Support Functions */
	createTableFields() {
		let tableFieldsArray = [];

		tableFieldsArray.push(
			DynamicTableHelper.createField('name', { text: 'Name', className: 'name-cell' }, { className: 'name-cell' }),
			DynamicTableHelper.createField('createdDate', { text: 'Date', className: 'createdDate' }, { className: 'createdDate', renderer: DynamicTableHelper.defaultDateRenderer }),
			DynamicTableHelper.createField('type', { text: 'Type', className: 'type-cell' }, { className: 'type-cell', renderer: this.fileTypeRenderer }),
			DynamicTableHelper.createField('view', { text: 'Status', className: 'type-cell' }, { className: 'type-cell', type: 'view', renderer: this.viewStatusRenderer }),
    /*{
        name: 'name',
        header: 'Name',
        // eslint-disable-next-line
        renderer: (data, fieldName, n) => {
    
          return (
            <DynamicTableCell className="name-cell" key={n}>
              <Label color="primary">{data.name}</Label>
            </DynamicTableCell>
          );
        }
      });
  
      tableFieldsArray.push( {
        name: 'createdDate',
        header: 'Date',
        // eslint-disable-next-line
        renderer: DynamicTableHelper.defaultDateRenderer
      });
     
    tableFieldsArray.push( {
      name: 'type', 
      header: 'Type',
      // CellRenderer: func - Should return a DynamicTableCell
      // data : Object - row data
      // fieldName: String - the row data key of this field
      // n : int - the index of this field
      //
      // eslint-disable-next-line
      renderer: (data, fieldName, n) => {
        let fileType = this.getFileTypeIcon(data.name);
        return (
          <DynamicTableCell className="type-cell" key={n}>
            <Label title={fileType} color="primary">{fileType}</Label>
          </DynamicTableCell>
        );
      }
    });

    tableFieldsArray.push( {
        type: 'view',//set type to `select` for a checkbox renderer
        name: 'Status', //must be non-null
         // eslint-disable-next-line
        renderer: (data, fieldName, n) => {

          return (
            <DynamicTableCell className="type-cell" key={n}>
              <Hollaback data={data} onClick={this.onVisibilityClick}>
                <span className="ToggleVisibility">
                    <span><Icon size={'xlarge'} color={data.customerVisible? 'primary': 'secondary'} icon={data.customerVisible? 'toggle-on': 'toggle-off'} /></span>
                </span>              
              </Hollaback>
            </DynamicTableCell>
          );
        }
      }*/);

		return tableFieldsArray;
	}

	generateClassNames = (base = 'PublishFilesTable') => {
		const { className } = this.props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (className) classNames.unshift(className);

		return classNames;
	};

	onItemToggle = (selections) => {
		const { onCheckboxClick } = this.props;
		if (onCheckboxClick) onCheckboxClick(selections);
	};

	/**Loading Indicators */
	loadingStyle = {
		container: {
			display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto'

		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	RefreshIndicatorUploadLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="downloadAll" color="disabled"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/></Button>
		</div>
	);


	render() {
		const { files } = this.props;
		let rowProps = (file) => {
			return { id: file.fileId };
		};
		return (
			<div className="PublishFilesTable">
				{super.render()}

				<div className="ActionButtons">
					<Button alt2={true} className="cancelBtn" disabled={this.state.saveButtonDisabled} onClick={this.onCancelClick} >
						<Icon size={'normal'} color={'primary'} icon={'times'} />&nbsp;
						<label>Cancel</label>
					</Button>
					<Button alt2={true} className="saveBtn" disabled={this.state.saveButtonDisabled} onClick={this.onSaveClick} >
						<Icon size={'normal'} color={'primary'} icon={'save'} />&nbsp;
						<label>Save Changes</label>
					</Button>
				</div>
				<DynamicTable
					onItemToggle={this.onItemToggle}
					primaryKey="fileId"
					sorter="name"
					sortDir={1}
					index={2}
					className={this.generateClassNames().join(' ')}
					dataset={files}
					fields={this.createTableFields()}
					rowProps={rowProps}
					listHeight={450}
					{...this.props} />


			</div>

		);
	}
}

PublishFilesTable.propTypes = {
	/** files*/
	files: PropTypes.array.isRequired,
	isSavingFiles: PropTypes.bool.isRequired,
	onSaveAdminFilesClick: PropTypes.func.isRequired,
	updateFileChanges: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired
};

PublishFilesTable.defaultProps = {
	files: []
};


export default PublishFilesTable;