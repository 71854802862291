'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './UserOptions.scss';
import { useAuth } from '@/hooks/auth.hook';
import * as enums from '@/enums/Enums';
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";
import { usePermissions } from "@/hooks/permissions.hook";

//make sure class component
//extend Auditor instead of React.Component/Component
export const UserOptions = (props) => {

	const [showAdmin, setShowAdmin] = useState(false);
	const logger = useLocalAuditLogger();
	const auth = useAuth();
	const history = useHistory();
	const { checkUserHasPermissions } = usePermissions();

	useEffect(() => {
		let viewAdmin = checkUserHasPermissions("canViewAdmin");
		setShowAdmin(viewAdmin);
	}, []);

	const adminClickHandler = useCallback(() => {
		history.push('/home/admin');
	}, [history]);

	const signOutClickHandler = useCallback(() => {
		//replace references to the old auditLogger with this.state.logger
		logger.logOutUser(enums.AUDITLOG_USERACTION_TYPES.LOGOUT, localStorage.getItem("id_audit"));
		auth.logout();
		history.push(`/`);
	}, [auth, logger, history]);


	return (
		<div className={"UserOptionsContainer"}>
			<div>{props.username}</div>
			<div>{props.email}</div>
			<hr />
			{
				showAdmin ? <div className="admin" onClick={adminClickHandler}>Admin</div> : ""
			}
			<hr />
			<div className="signout" onClick={signOutClickHandler}>Sign Out</div>
		</div>
	);
};

export default UserOptions;