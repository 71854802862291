import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import Table from "../../table/Table";
import * as styles from "./panel.module.scss";

const Panel = ({ label, total, tableData, tableColumns, height }) => {
  const { mobile, smallScreen } = useSelector(viewPortSelector);
  const articleMaxHeight = smallScreen ? "100%" : Math.max(height, 300);

  return (
    <article className={styles.panel} style={{ maxHeight: articleMaxHeight }}>
      <div className={styles.contentWrapper}>
        <h3>{label}</h3>
        <p className="ban-lg">{total}</p>
      </div>

      {tableData.map((table) => (
        <Fragment key={table.label}>
            <Table
              tableData={[table]}
              className={styles["sig-down-table"]}
              defaultSort={
                tableColumns.length > 0
                  ? tableColumns.filter((d) => d.defaultSort)[0].key
                  : null
              }
              defaultSortDirection={
                tableColumns.length > 0
                  ? tableColumns.filter(
                      (d) => d.defaultSortDirection !== undefined
                    )[0].defaultSortDirection
                  : false
              }
              flow="column"
              equalSpacing={mobile ? false : smallScreen}
              showTotal={tableData.length > 1 ? true : false}
              columns={tableColumns}
              updateOnHover={true}
              enableFontWeight={true}
            />
        </Fragment>
      ))}
    </article>
  );
};

Panel.propTypes = {
  label: PropTypes.string,
  total: PropTypes.number,
  tableData: PropTypes.array,
  tableColumns: PropTypes.array,
  height: PropTypes.number,
};

Panel.defaultProps = {
  label: "",
  total: 0,
  tableData: [],
  tableColumns: [],
  height: 0,
};

export default Panel;
