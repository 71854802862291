import { MICROBIOME } from '@/enums/Enums';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchAssociations } from '../../api';
import { 
  getAssociations,
  setAssociations
} from '../reducers/discoveryPanels.reducer';

export function* associationsSaga(action) {
	const { productType=MICROBIOME } = action?.payload || {};

  const payload = {
    list: [],
    static: [],
    raw: [],
    isLoaded: false,
    error: null
  };

  try {
    const data = yield call(fetchAssociations, productType);
    payload.raw = data;
  } catch (e) {
    payload.error = e.message;
  }
  
  yield put(setAssociations(payload));
}

export function* associationsSagas() {
  yield takeLatest(getAssociations, associationsSaga);
}