'use strict';
import React, { Component } from 'react';
import getStartedPDF from '../../Assets/guides/Metabolon_Client_Portal_Getting_Started_Guide_1.0.3.pdf';
import LandingFooter from '../landing/LandingFooter.jsx';
import LandingHeader from '../landing/LandingHeader.jsx';
import './faq.scss';

export class Faq extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const prodEula =
			<div id="faq" className="app-content faq">
				<p>
					<b>Welcome to the help information for Metabolon's client portal.  If you are new to the portal, be sure to check out the <a href={getStartedPDF} target="_blank" rel="noreferrer">Getting Started Guide</a> to help you get oriented.</b>
				</p>
				<h1>Frequently Asked Questions</h1>
				<p>
					<b>Q: How do I use the portal?</b><br />
					<b>A:</b> View the "<a href={getStartedPDF} target="_blank" rel="noreferrer">Getting Started Guide</a>" for helpful instructions.
				</p>
				<p>
					<b>Q: Which browsers are compatible with the portal? </b><br />
					<b>A:</b> Google Chrome, Mozilla Firefox, and Microsoft Internet Explorer 9 or later
				</p>
				<p>
					<b>Q: Can I view Pathway Visualizations on a Mac? </b><br />
					<b>A:</b> Yes, the visualizations are viewable from a Mac with any of the compatible browsers.
				</p>
				<p>
					<b>Q: How will I access my projects from several years ago? </b><br />
					<b>A:</b> All projects that were previously published to MetaboLync will be accessible in the updated portal.
				</p>
				<p>
					<b>Q: How do I login? </b><br />
					<b>A:</b> You can continue to use your current MetaboLync userid and password.
				</p>
				<p>
					<b>Q: What if I can't remember my password? </b><br />
					<b>A:</b> Use the password recovery capability on the sign in screen.
				</p>
				<p>
					<b>Q: What if I can't remember my userid? </b><br />
					<b>A:</b> It is typically your organizational email address.  If not, you can create your own login or use Microsoft, Google, or LinkedIn accounts.  See below.  Contact <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com</a> for assistance.
				</p>
				<p>
					<b>Q: How do I use my Microsoft, Google, or LinkedIn account? </b><br />
					<b>A:</b> On the sign in screen, choose to create an account and specify the account username that you want to use.  Contact your study director to grant access for your projects for that account.  Once created, click on the appropriate account to login with those credentials.
					<br /><i className="FAQ-login" />
				</p>
				<p>
					<b>Q: Why don't I see any projects after I log in? </b><br />
					<b>A:</b> Your account will need to be granted access to the appropriate projects. Contact <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com</a> (include your email address and project name) to request access for your account.
				</p>
				<p>
					<b>Q: When will someone respond when I contact <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com?</a> </b><br />
					<b>A:</b> Support is provided during business hours as follows: <b>Monday – Friday 8:00AM-5:00PM (US Eastern Time Zone)</b>
				</p>
				<p>
					<b>Q: What if I'm missing vital information or capabilities? </b><br />
					<b>A:</b> All project information and core capabilities have been provided in the updated portal.  If you are missing anything, please contact our support team at <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com.</a>
				</p>
				<p>
					<b>Q: Why didn't I receive an email to recover my password? </b><br />
					<b>A:</b> Check your spam, junk, or trash folder for a recovery password email from Metabolon.
				</p>
				<p>
					<b>Q: How do I access animal or plant biological pathway maps for visualizations? </b><br />
					<b>A:</b> The pathway maps are planned for a future release.
				</p>
				<p>
					<b>Q: How do I report a problem? </b><br />
					<b>A:</b> Please contact our support team at <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com</a> and include your contact info and project names.
				</p>
				<p>
					<b>Q: How do I share my projects with others? </b><br />
					<b>A:</b> Submit requests to <a href="mailto:portalsupport@metabolon.com">portalsupport@metabolon.com.</a>  Please be sure to include the project names(s) that you want to share and the email of the person with whom you'd like to share.
				</p>
				<p>
					<b>Q: What do the colors mean on the Pathway Explorer Map and Stats Table? </b><br />
					<b>A:</b> Red means the fold change for the metabolite is increased (test variable/control &gt;1) and p-value is less than alpha (0.05).
					<br />
					Pink means the fold change for the metabolite is increased (test variable/control &gt;1) and p-value is close to alpha (between 0.05 and 0.1).<br />
					Blue means the fold change for the metabolite is decreased (test variable/control &lt;1) and p-value is less than alpha (0.05). <br />
					Light blue means the fold change for the metabolite is decreased (test variable/control &lt;1) and p-value is close to alpha (between 0.05 and 0.1).<br />
					<i className="FAQ-legend" />
				</p>
				<p>
					<b>Q: I see a white page when I access the portal.  What should I do? </b><br />
					<b>A:</b> Try refreshing your browser.  If the problem persists, request that your IT team add .metabolon.com as a trusted site.
				</p>
				<p>
					<b>Q: What do I do if I zoom in / out too far and can no longer see any figures in the pathway explorer? </b><br />
					<b>A:</b> Click to choose the sample set again and the figures will be redrawn at the default scale.
				</p>
				<p>
					<b>Q: What does the Enrichment Chart mean?</b><br />
					<b>A:</b> For each individual pair-wise comparison, Pathway Enrichment displays the number of statistically significantly different compounds relative to all detected compounds in a subpathway, compared to the total number of statistically significantly different compounds relative to all detected compounds in the study. A pathway enrichment value greater than one indicates that the pathway contains more significantly changed compounds relative to the study overall, suggesting that the pathway may be a target of interest for further investigation.
				</p>
				<p>
					<b>Q: How is the Enrichment Value calculated?</b><br />
					<b>A:</b> Enrichment Value = (k/m)/((n-k)/(N-m)) where <br />
					<div style={{ paddingLeft: '30px' }}>
						m = number of metabolites in the pathway<br />
						k = number of significant metabolites in the pathway<br />
						n = total number of significant metabolites<br />
						N = total number of metabolites<br /></div>
				</p>
				<p>
					<b>Q: How is information secured when transferred with the portal?</b><br />
					<b>A:</b> Our portal uses a FIPS Compliant TLS certificate (using AES and SHA256) for the encryption of all in-transit data.
				</p>
				<p>
					<b>Q: What are the rules for creating or changing my password?</b><br />
					<b>A:</b> The portal password must be at least eight characters long and must include a combination of three of the following four types of characters: capital letters, lower case letters, numeric characters and special characters.
				</p>
				<p>
					<b>Q: I'm having trouble accessing Surveyor, and the error message mentioned a cookie problem:</b><br />
					<b>A:</b> Remove the cookie your browser has stored for surveyor.metabolon.com.
				</p>
				<p>
					<b>Q: How do I remove cookies from Chrome?</b><br />
					<b>A:</b> <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en" target="_blank" rel="noreferrer"> https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en</a>
				</p>
				<p>
					<b>Q: How do I remove cookies from Firefox browser?</b><br />
					<b>A:</b> <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank" rel="noreferrer">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
				</p>
				<p>
					<b>Q: How do I remove cookies from Internet Explorer?</b><br />
					<b>A:</b> <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" rel="noreferrer">https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
				</p>
			</div>;
		return (
			<div>
				<div style={{ backgroundColor: 'white' }} >
					<LandingHeader auth={this.props.auth} match={this.props.match} />
				</div>
				{prodEula}
				<LandingFooter match={this.props.match} />
			</div>
		);
	}
}
export default Faq;