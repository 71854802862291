import { Stepper as MUIStepper, Box, Step, StepLabel } from '@mui/material'
import classNames from 'classnames'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

export const Stepper = ({ steps, onChange, ...props }) => {
	const [activeStep, setActiveStep] = useState(props.current);
	const isComplete = useMemo(() => activeStep >= steps.length, [activeStep, steps.length]);

	const onStepClick = useCallback((step, disabled) => () => {
		if (isComplete || disabled) return;
		setActiveStep(step);
		onChange(step);
	}, [activeStep, onChange, isComplete]);

	useEffect(() => {
		setActiveStep(props.current);
	}, [props.current]);

	return (
		<Box sx={{ width: '100%' }}>
			<MUIStepper activeStep={activeStep} className={classNames({ complete: isComplete })} alternativeLabel>
				{steps.map(({ label, disabled = false }, index) => (
					<Step key={label} onClick={onStepClick(index, disabled)}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</MUIStepper>
		</Box>
	)
}

export default Stepper