'use-strict';
import React from 'react';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { Redirect } from 'react-router-dom';

import { useCurrentUserQuery } from '@/redux/services/user.api.js';

/**
 * HOC that Handles whether or not the user is allowed to see the page.
 */
export const RouteAuthorization = ({
  allowedPermissions,
  WrappedComponent,
  ...props
}) => {
	const { data: user, isLoading: userIsLoading } = useCurrentUserQuery();
	if (userIsLoading) return null;
  /* istanbul ignore next */
  if (localStorage.getItem('id_token') && !user ){
    return <Redirect to={{
      pathname: "/securitycheck",
      state: { from: props.location }
    }}/>;
  }

  if (!user) {
    return (
      <Redirect to={{
        pathname: "/login",
        state: { from: props.location }
      }}/>
    );
  }
  if (!user || !user.roles) {
    return <h1>Access Permissions Not Found!</h1>;
  }
  const permissionArray = flatten(map(user.roles, 'permissions'));

  if (intersection(allowedPermissions, permissionArray).length > 0 ) {
    return <WrappedComponent {...props} />;
  } else {
    return <h1>Access Permissions Not Found</h1>;
  }
};

export default RouteAuthorization;