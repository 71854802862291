// extracted by mini-css-extract-plugin
var _1 = "table_body";
var _2 = "table_button-wrapper";
var _3 = "table_clicked";
var _4 = "table_fader";
var _5 = "table_flow-column";
var _6 = "table_flow-row";
var _7 = "table_header";
var _8 = "table_icon-wrapper";
var _9 = "table_row";
var _a = "table_table";
var _b = "table_wrapper";
export { _1 as "body", _2 as "button-wrapper", _3 as "clicked", _4 as "fader", _5 as "flow-column", _6 as "flow-row", _7 as "header", _8 as "icon-wrapper", _9 as "row", _a as "table", _b as "wrapper" }
