import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from '../actions/actionTypes';

export const getInitialState = () => ({
    managedUsersIsLoading: false,
    managedUsersHasErrored: false,
    data: [],
    authenticated: false
});

export const managedUsersSlice = createSlice({
    name: 'managedUsers',
    initialState: getInitialState(),
    extraReducers: {
        [actionTypes.MANAGEDUSERS_HAS_ERRORED]: (state) => {
            state.managedUsersIsLoading = false;
            state.managedUsersHasErrored = true;
        },
        [actionTypes.MANAGEDUSERS_IS_LOADING]: (state) => {
            state.managedUsersIsLoading = true;
        },
        [actionTypes.MANAGEDUSERS_FETCH_DATA_SUCCESS]: (state, action) => {
            state.managedUsersIsLoading = false;
            state.data = Array.isArray(action.response) ? action.response : Object.values(action.response);
            state.authenticated = action.authenticated || false;
        }
    }
});

export const managedUsersReducer = managedUsersSlice.reducer;