import React, {useCallback, useState, useEffect} from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Button, DynamicModal, Material, Select } from '../../../components/index.jsx';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner'; 
import CytoD3 from '../../../components/visualization/CytoD3.jsx';
import MetaboliteTable from '../../../containers/MetaboliteTable.jsx';
import StatsDropdown from '../../../containers/StatsDropdown.jsx';
import PathwaySubview from './PathwaySubview.jsx';
import SettingsMap from '../../../utils/SettingsMap.js';
import {makeNodeId} from '../../../utils/StringUtils.js';
import SamplesetUtils, { nilId } from '../../../utils/SamplesetUtils.js';
import { Logger, Log } from '../../../utils/Logger';
import {AppInsightLogLevel} from '../../../enums/Enums';
import './PathwayExplorer.scss';

import * as actionTypes from '@/redux/actions/actionTypes';
import { sampleSetDataIsSelected } from '@/redux/reducers/sampleSetData.reducer.js';
import { useDispatch } from 'react-redux';

const logger = new Logger();

function PathwayExplorer(props) {
	const dispatch = useDispatch();
	const [project, setProject] = useState(props.project);
	const [sampleSets, setSampleSets] = useState(props.sampleSets);
	const [activeSampleSet, setActiveSampleSet] = useState(null);
	const [webWorker] = useState(props.webWorker);
	const [showHidden] = useState(props.showHidden);
	const [subpathwayId, setSubpathwayId] = useState("");
	const [pathwayLoading, setPathwayLoading] = useState(true);

	const [sampleSetIndex, setSampleSetIndex] = useState(0);
	const [activeStats, setActiveStats] = useState([]);
	const [stats, setStats] = useState([]);
	const [current, setCurrent] = useState(null);
	const [selection, setSelection] = useState(null);
	const [statsDict, setStatsDict] = useState({});
	const [metabolites, setMetabolites] = useState([]);
	const [isFullscreen, setIsFullscreen] = useState(false);

	const [metaboDeck, setMetaboDeck] = useState(null);
	const [graphElements, setGraphElements] = useState(null);
	
  const [compounds, setCompounds] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [count, setCount] = useState(0);
  const [sig, setSig] = useState(null);
  const [maxEnrichment, setMaxEnrichment] = useState(null);
	const [lastMapUpdate, setLastMapUpdate] = useState(null);

	const [layerSetting, setLayerSetting] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const [cy, setCy] = useState();
	const [map, setMap] = useState(null);
	const [cyMap, setCyMap] = useState(null);
	const [cyPath, setCyPath] = useState(1);
	const [trends, setTrends] = useState({});
	const [hideFinalPathways, setHideFinalPathways] = useState(false);
	const [hideIntermediates, setHideIntermediates] = useState(false);
	const [hideMinorMetabolites, setHideMinorMetabolites] = useState(false);
	const [hideCofactors, setHideCofactors] = useState(false);
	const [colorKey, setColorKey] = useState(props.colorKey);
	const [foldChangeScale, setFoldChangeScale] = useState(props.foldChangeScale);
	const [publishPreviewMode, setPublishPreviewMode] = useState(props.publishPreviewMode);

	useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setPublishPreviewMode(props.publishPreviewMode);
  }, [props.publishPreviewMode]);

	useEffect(() => {
    const {compounds, pathways, count, sig, maxEnrichment} = onStatsUpdate(current);

		setCompounds(compounds);
		setPathways(pathways);
		setCount(count);
		setSig(sig);
		setMaxEnrichment(maxEnrichment);

    updateHighlights();
  }, [current]);

  useEffect(() => {
		updateHighlights();
  }, [hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors, foldChangeScale]);

  /*useEffect(() => {
    console.log('sampleset update');
    onStatsUpdate(current);
    updateHighlights()
  }, [sampleSetIndex]);
*/
	useEffect(() => {
		if (!cy) return;
		setIsReady(true);
		if (subpathwayId !== "") showSubpathway();
		else resetPathwayView();

	}, [cy, subpathwayId]);

	const init = () => {
		let sampleSet = (sampleSets.length ? sampleSets[props.sampleSetIndex] : {sample:{}, stats:[], metabolites:[]});
    
		onSampleSetSelect(sampleSet, props.statsId, false);
	};

	const checkZoom = useCallback((target)  => {
		if(!cy) return;
		cy.fit(target);
		if(cy.zoom() > 2.2) {
			cy.zoom(2.2);
			cy.center(target);
		}
	}, [cy]);

	const setCyto = (data, selection) => {
		setCy(data.cy);
		if (data.map) setMap(data.map);
		if (data.cy && data.cy === cyMap) {
			updateHighlights();
			if (!selection) checkZoom(data.cy.$(':visible'));
			else {
				data.cy.nodes(selection).select();
				checkZoom(selection);
			}
		}
		else {
			resetPathwayView(true, false);
		}
	};

	const onSubmapSelect = useCallback((mapId, selector, group) => {
		logEvent("BiochemicalMap", mapId);
		if (!(mapId in cytoConfig.pathways)) {
			 
			console.warn(mapId, 'is not a valid submap');
			return;
		}

		let layers = SettingsMap.layers;
		if (group) {
			let key = `hide${group.split(' ').join('')}s`;
			if (layers[key]) {
				//show modal
				layers[key] = false;
				SettingsMap.updateLayers(layers);

				$('#pathway-modal').modal('show');
			}
		}
		setCyto({cy:cyMap, map:mapId});
		setSubpathwayId("");
		setLayerSetting(group);
		setHideCofactors(layers.hideCofactors);
		setHideIntermediates(layers.hideIntermediates);
		setHideFinalPathways(layers.hideFinalPathways);
		setHideMinorMetabolites(layers.hideMinorMetabolites);

		updateHighlights();
		if (!selector) checkZoom(cyMap.$(':visible'));
		else {
			cyMap.nodes(selector).select();
			checkZoom(selector);
		}
	}, [cyMap]);

	const updateHighlights = useCallback(() => {
		if(!activeSampleSet) return;

		const studyType = current ? statsDict[current].studyType : 'standard';
		if(cy == 1) return;
		if(!(cy && trends)) return;


		let highlights = {blank:[], trendUp:[], trendDown:[], sigUp:[], sigDown:[], nil:[], dead:[]};

		//aggregate trending ids from all active stats
		/*activeStats.forEach(id => {
			SamplesetUtils.gatherTrends(trends[id], highlights);
		});*/

		//only gather trends from the current stats test 
		highlights = SamplesetUtils.gatherTrends(trends[current], highlights);

		cy.resize();

		let getNodeScale = SamplesetUtils.getNodeScale(studyType, current, {metaboDeck, cy, cyMap, cyPath, foldChangeScale}),
			nameKey  = cy === cyMap ? "canonicalName" : 'chemicalName';

		let flags = {hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors};

		cy.nodes().forEach(node => {
			SamplesetUtils.highlightNode(node, nameKey, getNodeScale, current, studyType, highlights, flags, cy, onSubmapSelect);
		});
	}, [activeSampleSet, current, activeStats, cy, trends, metaboDeck, cyMap, cyPath, foldChangeScale, hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors, onSubmapSelect]);

	const logEvent = (property, value) => {
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageEvent_PathwayExplorer');
		if (activeSampleSet) logFilterChange.AddProperty('projectID', activeSampleSet.projectId);
		logFilterChange.AddProperty(property,value);
		logger.doLog(logFilterChange);
	};

	const onSampleSetSelect = useCallback((sampleSet, statsId=null, isUserEvent=true) => {

		const {testField, drawCompounds} = props;
		let index = props.sampleSets.indexOf(sampleSet);

		let statsData = SamplesetUtils.processStats(sampleSet, statsId);

		//iterate over metabolites, for each stats
		let sampleMetabolites = sampleSet ? sampleSet.metabolites : [];
		let metaboliteData = SamplesetUtils.processMetabolites(sampleMetabolites, statsData.stats, statsData.trends, statsData.statsDict, testField, drawCompounds);

		//enrichment calculation loop
		statsData.trends = SamplesetUtils.calculateEnrichments(statsData.trends);

		let pathwayData = onStatsUpdate(statsData.current, statsData.trends);

		setSampleSetIndex(index);
    setStats(sampleSet.stats);
    setActiveStats(statsData.activeStats);
    setCurrent(statsData.current);
    setSelection(statsData.selection);
    setStatsDict(statsData.statsDict);
    setTrends(statsData.trends);
    setMetaboDeck(metaboliteData.metaboDeck);
    setMetabolites(metaboliteData.metabolites);
    setGraphElements(metaboliteData.graphElements);
    setCompounds(pathwayData.compounds);
    setPathways(pathwayData.pathways);
    setCount(pathwayData.count);
    setSig(pathwayData.sig);
    setMaxEnrichment(pathwayData.maxEnrichment);
    setActiveSampleSet(sampleSet);

		dispatch(sampleSetDataIsSelected(index));
		if (!isUserEvent) return;

		if (props.onSampleSetSelect)
			props.onSampleSetSelect(index, statsData.current);
		
    updateHighlights();

		logEvent("SampleSetIDSelect",sampleSet ? sampleSet.projectSampleSetId : "Undefined");
	}, []);

	const resetPathwayView = useCallback((update=true, delay=true) => {
		if (update) updateHighlights();

		if (delay) return setTimeout(() => { resetPathwayView(false, false); }, 500);
		if (!cy) return;
		//cy.reset();
		//select amino acids
		//let id = makeNodeId("Amino Acid");
		//if(cy.$(`#${id}`).length == 0) id = makeNodeId("Amino acid");
		cy.$('*').unselect().removeClass('hidden');
		//cy.$(`#${id}`).select();
		let group = cy.$('*');//cy.$(`#${id}`).neighborhood();
		if (group) cy.fit(group);
	}, [cy]);

	const onStatsUpdate =useCallback((id, trendsArg) => {
		id = id || current;
		trendsArg = trendsArg || trends;

		let trend=trendsArg[id], pathways=[], compounds=[], count=0, sig=0, maxEnrichment=0;
		if (id && trend) {
			pathways = Object.values(trend.pathways).map(p => p);
			compounds = Object.values(trend.compounds).map(c => c);
			count = trend.count;
			sig = trend.sig;
			maxEnrichment = trend.maxEnrichment;
		}
		//pathways.unshift({name:"All Metabolic Pathways", count, sig, enrichment:1});

		return { compounds, pathways, count, sig, maxEnrichment };
	}, [current, trends]);

	const onToggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
	}, [isFullscreen]);

	const onNextArrow = useCallback(() => {
		let n = activeStats.indexOf(current);
		setCurrent(activeStats[n+1]);
		logEvent("Arrow","Next");
	}, [activeStats, current]);

	const onPrevArrow = useCallback(() => {
		let n = activeStats.indexOf(current);
		setCurrent(activeStats[n-1]);
		logEvent("Arrow","Previous");
	}, [activeStats, current]);

	const onStatsClick = useCallback((id) => {
		setCurrent(id);
	}, []);

	const onStatsToggle = useCallback((selections) => {
    if(selections[0] === activeStats[0] && selections.length === activeStats.length) return;
    setActiveStats(selections);
    updateHighlights();
  }, [activeStats.length, activeStats[0]]);

	const onLayout = useCallback(() => {
		if(!cy) return;
		checkZoom();
    onStatsUpdate(current);
    updateHighlights();
	}, [cy, current]);

	const onPathwayReady = useCallback((cyInstance, update=true) => {
		if(!update) {
			resetPathwayView();
			return;
		}
		setCyPath(cyInstance);
		setCy(cyInstance);
		setPathwayLoading(false);

		window.cy = () => cyInstance;
	}, []);

	const onMapReady = useCallback((cyInstance) => {
		setCyMap(cyInstance);
	}, []);

	const onPathSelect = useCallback(() => {
		logEvent("Pathway_Overview", "Selected");
		setCyto({cy:cyPath, mode:'fireworks'});
	}, [cyPath]);

	const onMapSelect = useCallback((mapId=null) => {
		logEvent("Pathway_Map", "Selected");
		if(!mapId) {
			if(activeSampleSet && activeSampleSet.sample && activeSampleSet.sample.organism) {
				let type = activeSampleSet.sample.organism.toLowerCase();
				if(type === "plant") {
					mapId = "Plant Metabolic Pathways";
				}
				else mapId = "Metabolic Pathways";
			}		
		}
		setCyto({cy:cyMap, mode:'map', map:mapId});
	}, [cyMap, activeSampleSet]);

	const onSubpathwaySelect = useCallback((subpathwayId) => {
		setSubpathwayId(subpathwayId);
	}, []);

	const showSubpathway = useCallback((id) => {
		logEvent("Subpathway", subpathwayId);

		if (subpathwayId === "All Metabolic Pathways") {
			cyPath.$('*').unselect().removeClass('hidden');
			cyPath.fit();
		}
		else {
			const subPathId = id ? id : makeNodeId(subpathwayId); 
			cyPath.$('*').unselect().addClass('hidden');
			cyPath.$(`#${subPathId}`).removeClass('hidden').select();
			let group = cyPath.$(`#${subPathId}`).neighborhood(`[type!='superpathway']`).removeClass('hidden');
			cyPath.fit(group);
		}
		setCy(cyPath);
		//setSubpathwayId("");
	}, [cyPath, subpathwayId]);

	const showSuperpathway = useCallback(superpathwayId => {
		logEvent("Superpathway", superpathwayId);

		if(cy !== cyPath) {
			setCy(cyPath);
			showSuperpathway(superpathwayId);
			return;
		}

		let id = makeNodeId(superpathwayId);
		cy.$('*').unselect();
		cy.$('*').addClass('hidden');
		let group = cy.$(`#${id}`);
				group = group.union(group.neighborhood()); //add subs
				group = group.union(group.neighborhood()); //add metabolites
		group.removeClass('hidden');
		cy.fit(group);
	}, [cy, cyPath]);

	const onFieldClick = useCallback((data, fieldName) => {
		let id, node, group, nodeId = data.metaboliteId;
		let val = data[fieldName], key = "chemicalId";

		if(fieldName == 'chemicalName') {
			if(data[key] === nilId) key = "metaboliteId";
			id = data[key];
			cy.$('*').unselect();
			group = cy.$(`[${key} = "${id}"]`);
			group.select().removeClass('hidden');
			checkZoom(group);
			/*if(!node.visible()) {
				cy.$('*').removeClass('hidden');
			}*/
			//if(!containsNode(node)) cy.center(node);

			return;
		}

		if(cy !== cyPath) {
			//setCy(cyPath);
			//onFieldClick(data, fieldName);
			return;
		}

		//if overview
		if(cy === cyPath) {
			switch(fieldName) {

				case 'subPathway': { //select subPathway
					const id = makeNodeId(val);
					showSubpathway(id);
					break;
				}
				case 'superPathway' : //select superPathway
					showSuperpathway(val);
					break;
			}
		}
	}, [cy, cyPath]);

	const onMetaboliteRowClick = useCallback(id => {
		//const {cy} = this.state;

		/*cy.$('*').unselect();
		cy.$('#'+id).select();*/
	}, []);

	const onLayersUpdate = useCallback(data => {
		if('hideCofactors' in data) setHideCofactors(data.hideCofactors);
		if('hideIntermediates' in data) setHideIntermediates(data.hideIntermediates);
		if('hideFinalPathways' in data) setHideFinalPathways(data.hideFinalPathways);
		if('hideMinorMetabolites' in data) setHideMinorMetabolites(data.hideMinorMetabolites);
		if('foldChangeScale' in data) setFoldChangeScale(data.foldChangeScale);

		//updateHighlights();
	}, []);

	const onSettingsUpdate = useCallback(data => {
		setColorKey(data.colorKey);
		updateHighlights();
	}, []);

	const onMapUpdate = useCallback(() => {
		if(cy === cyMap) {
			updateHighlights();
			setLastMapUpdate(dayjs().valueOf());
		}
	}, [cy, cyMap]);

	const getSelectKey = useCallback(option => option.sample.name, []);

	const renderModalContent = useCallback(() => {
		return (
			<div>
				The elements you've requested were hidden as part of the <pre className="d-inline">Hide {layerSetting}s</pre> setting in the Layers subview. This setting has been adjusted in order to display your results.
			</div>
		);
	}, []);

	const {fireWorksLayout, plants, animals} = props;
	//let stats = activeSampleSet ? activeSampleSet.stats : [];
	let cytoConfig = undefined;
	let pathwayMap = map;

	if(activeSampleSet && activeSampleSet.sample && activeSampleSet.sample.organism) {
		let type = activeSampleSet.sample.organism.toLowerCase();
		if(type === "plant") {
			cytoConfig = plants;
			if(!pathwayMap) pathwayMap = "Plant Metabolic Pathways";
		}
		else cytoConfig = animals;
	}

	let mapNames = cytoConfig ? Object.keys(cytoConfig.pathways).map(key => ({name:key})) : [];

	let mapNodes = [];
	if(cytoConfig) {
		for(let p in cytoConfig.pathways) {
			mapNodes = mapNodes.concat(cytoConfig.pathways[p].elements.nodes);
		}
	}
	mapNodes.forEach(node => {
		let ecNumber = node.data.ecNumber || '';
		if(node.data.compoundType.toLowerCase() === 'enzyme') {
			node.data.ecNumbers = ecNumber.split('///').map(s => s.trim());
		}
	});

	let hiddenMaps = [];//['Endocannabinoid Synthesis', 'Beta-oxidation', 'Phospholipid Metabolism', 'Sphingolipid Metabolism'];

	mapNames = mapNames.filter(mapObj => hiddenMaps.indexOf(mapObj.name) < 0);


  if(!activeSampleSet) return <p>No sampleset available</p>;

	let tableTitle = "Pathway Classification Overview";
	let pathwayConfig = /*(!isReady ? {elements:[]} :*/ {
		elements: graphElements,
	};//);
	let tableStats = current ? [current] : [];
	let statsIndex = current ? activeStats.indexOf(current) : -1;
	const studyType = current ? statsDict[current].studyType : 'standard';
	
	return (
		<div className="PathwayExplorer">
			<div className="header-ui">
				<Select
					className="pathway-viz"
					color="tertiary"
					selection={activeSampleSet} 
					options={sampleSets}
					optionClass="alt2 tertiary left"
					displayKey={getSelectKey}
					onSelect={onSampleSetSelect}/>

				<StatsDropdown
					className="pathway-viz"
					color="tertiary"
					sampleSet={activeSampleSet}
					stats={stats}
					activeStats={activeStats}
					selection={current}
					onItemToggle={onStatsToggle}
					onRowClick = {onStatsClick}/>

				<div className="stats-switcher">
				<Button data-testId="stats-prev" className="alt2 primary prev" title="Previous Test" disabled={!activeStats || statsIndex === 0} onClick={onPrevArrow}><Material size="large" icon="keyboard_arrow_left"/></Button>
				<Button data-testId="stats-next" className="alt2 primary next" title="Next Test" disabled={!activeStats || statsIndex === activeStats.length-1} onClick={onNextArrow}><Material size="large" icon="keyboard_arrow_right"/></Button>
				</div>

				{<div className="view-switcher">
					<Button data-testId="view-overview" className={cy === cyPath ? "alt2 primary": "alt2 tertiary"} title="Pathway Overview" onClick={onPathSelect}>Statistical</Button>
					<div className="divider" />
					<Button data-testId="view-map" className={cy === cyMap ? "alt2 primary": "alt2 tertiary"} 	title="Pathway Map" onClick={onMapSelect}>Pathway</Button>
				</div>}
				<span className="viewSwitchText">VIEW:</span>
			</div>
			{/*<Button className="alt2" title="Toggle Fullscreen" color={isFullscreen ? "tertiary" : "inactive"} onClick={onToggleFullscreen}><Material size="large" icon="aspect_ratio"/></Button>*/}

			<PathwaySubview
				onLayersUpdate={onLayersUpdate}
				onSettingsUpdate={onSettingsUpdate}
				onMapSelect={onSubmapSelect}
				onSubpathwaySelect={onSubpathwaySelect}
				sampleSet={activeSampleSet}
				maps={mapNames}
				maxEnrichment={maxEnrichment}
				pathways={pathways}
				compounds={compounds}
				mapNodes={mapNodes}
				count={count}
				sig={sig}
				statsId={current}
				project={project}
				explorerMode={cy === cyMap ? 'map' : 'fireworks'}
				adminProjectData={props.adminProjectData}
			/>

			{activeSampleSet && graphElements &&
				<>
				<CytoD3 className="path"
					type="stats"
					hidden={cy !== cyPath}
					onReady={onPathwayReady}
					onLayout={onLayout}
					layout={fireWorksLayout}
					colorKey={colorKey}
					studyType={studyType}
					config={pathwayConfig}
					webWorker={webWorker}/>
				{pathwayLoading &&
				<div className="pathway-loading-container">
					<LoadingSpinner />
				</div>
				}
				</>
			}

			{activeSampleSet &&
				<CytoD3 className="map"
					type="map"
					onUpdate={onMapUpdate}
					hidden={cy !== cyMap}
					onReady={onMapReady}
					colorKey={colorKey}
					studyType={studyType}
					config={cytoConfig}
					webWorker={webWorker}
					pathway={pathwayMap}/>
			}

			<h3 className="table-title">{tableTitle}</h3>
			{activeSampleSet && 
				<MetaboliteTable
					exportFilename={activeSampleSet.sample.name}
					className={"pathway-viz "+ studyType}
					studyType={studyType}
					metabolites={metabolites}
					stats={statsDict}
					currentStatsField={current}
					webWorker={webWorker}
					activeStats={activeStats}
					lastMapUpdate={lastMapUpdate}
					pathwayFilters={true}
					//onRowClick={onMetaboliteRowClick}
					onFieldClick={onFieldClick}/>
			}

			<DynamicModal id="pathway-modal" renderer={renderModalContent}/>
		</div>
	);
}

PathwayExplorer.propTypes = {
	/** project*/
	project: PropTypes.object.isRequired,
	sampleSets:
	PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			projectCode: PropTypes.string,
			projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			sample: PropTypes.shape({name:PropTypes.string, organism:PropTypes.string}),
			stats: PropTypes.array,
			metabolites: PropTypes.array,
			mode: PropTypes.string,
		})
	),

	/** index of the initial sample set */
	sampleSetIndex: PropTypes.number,

	/** id of the initial stats test */
	statsId: PropTypes.string,

	onItemToggle: PropTypes.func,
	webWorker: PropTypes.bool,

	/** Color to highlight nodes with positive trending deltas */
	trendUp: PropTypes.string,
	/** Color to highlight nodes with negative trending deltas */
	trendDown: PropTypes.string,
	/** Color to highlight nodes with positive significant deltas */
	sigUp: PropTypes.string,
	/** Color to highlight nodes with negative significant deltas */
	sigDown: PropTypes.string,

	/** Whether compound nodes are drawn*/
	drawCompounds: PropTypes.bool,

	/** the layout to use for the fireworks display */
	fireWorksLayout: PropTypes.string,

	multiOverlay: PropTypes.bool,
	detailedView: PropTypes.bool,

	/** Whether nodes are scaled based on foldchange*/
	foldChangeScale: PropTypes.bool,

	hideFinalPathways: PropTypes.bool,
	hideIntermediates: PropTypes.bool,
	hideMinorMetabolites: PropTypes.bool,
	hideCofactors: PropTypes.bool,
	hideCommonBiologics: PropTypes.bool,
	hideNoncoreElements: PropTypes.bool,
};

PathwayExplorer.defaultProps = {
	project: {},
	sampleSets: [{
		sample: {},
		stats:[],
		metabolites: []
	}],
	sampleSetIndex: 0,
	statsId: null,
	minUp: 0.001,
	maxUp:0.499,
	minDn: 0.5,
	maxDn: 1.0,
	webWorker: false,
	testField: "foldChange",
	trendUp: 'rgba(0, 255, 255, .2)',
	trendDown: 'rgba(255, 0, 0, .2)',
	sigUp: 'rgba(0, 255, 255, .8)',
	sigDown: 'rgba(255, 0, 0, .8)',
	drawCompounds: false,
	colorKey: null,
	fireWorksLayout: 'cose-bilkent',
	multiOverlay: false,
	detailedView: false,
	foldChangeScale: true,
	hideFinalPathways: false,
	hideIntermediates: true,
	hideMinorMetabolites: true,
	hideCofactors: true,
	hideCommonBiologics: true,
	hideNoncoreElements: true,
};

export default PathwayExplorer;