import { all } from 'redux-saga/effects';
import {
  sampleSetsSagas
} from './sampleSets.saga';
import {
  associationsSagas
} from './associations.saga';
import {
  metabolitesSagas 
} from './metabolites.saga';
import { 
  pathwaysSagas
} from './pathways.saga';
import { 
  resultsSagas
} from './results.saga';
import { initSagas } from './init.saga';
import { sampleSagas } from './sample.saga';

export function* discoveryPanelsSagas() {
  try {
    yield all([
      sampleSetsSagas(),
      initSagas(),
      associationsSagas(),
      metabolitesSagas(),
      pathwaysSagas(),
      resultsSagas(),
      sampleSagas(),
    ]);
  } catch (e) {  
    console.error("Error in DiscoveryPanels Saga: ", e.message);
  }
}