import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Modal from "metabux/components/Modal/Modal"
import { Button } from "metabux/components/Button/Button"
import { Stack } from "@mui/material"

export function ResourcesModal({
	open,
	setOpen,
	resources,
}) {

	const onClose = useCallback(() => {
		setOpen(false)
	}, [setOpen])

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Resources"
		>
			<Stack
				spacing={2}
				mt={5}
				direction="column"
			>
				{resources.map((resource) => {
					return (
						<Button target="_blank" key={resource.payload} href={resource.payload} name={resource.name}>
							{resource.label}
						</Button>
					)
				})}
			</Stack>
		</Modal>
	)
}

ResourcesModal.propTypes = {
	open: PropTypes.bool,
	setActiveStep: PropTypes.func,
	resources: PropTypes.array,
}
