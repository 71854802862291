'use strict';
import * as actionTypes from './actionTypes';
import { CALL_API } from '../../middleware/storeApi';
import urljoin from 'url-join';


/** Action Creators for files */
export const searchResultsHasErrored = () => ({
  type: actionTypes.SEARCHRESULTS_HAS_ERRORED
});


export const searchResultsIsLoading = () => ({
  type: actionTypes.SEARCHRESULTS_IS_LOADING
});


export const searchResultsFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.SEARCHRESULTS_FETCH_DATA_SUCCESS,
  response,
  authenticated
});


export function searchResultsFetchData(endpoint, query = null) {
    let apiEndpoint = endpoint;
    if(query)
        apiEndpoint = urljoin(apiEndpoint, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.SEARCHRESULTS_IS_LOADING, actionTypes.SEARCHRESULTS_FETCH_DATA_SUCCESS, actionTypes.SEARCHRESULTS_HAS_ERRORED]
    }
  };
}



