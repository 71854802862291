import urljoin from 'url-join';
import { API_SAMPLESETS_INFO } from "@/config/env.config";
import { getToken } from "@/middleware/requests";
import { portalApi } from "./index.api";

export const sampleSetsInfoApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSampleSetInfo: builder.query({
      query: (projectId, sampleSetId) => ({
        headers: { authorization: `Bearer ${getToken()}` },
				url: urljoin(API_SAMPLESETS_INFO, projectId, sampleSetId),
        method: "GET",
      })
    }),
    fetchSampleSetsInfo: builder.query({
      query: (projectId) => ({
        headers: { authorization: `Bearer ${getToken()}` },
				url: urljoin(API_SAMPLESETS_INFO, "project", projectId),
        method: "GET",
      })
    }),
    fetchSampleSetsInfoCount: builder.query({
      query: (projectId) => ({
        headers: { authorization: `Bearer ${getToken()}` },
				url: urljoin(API_SAMPLESETS_INFO, "project", projectId, "count"),
        method: "GET",
      })
    })
  })
});

export const {
	useFetchSampleSetInfoQuery,
	useFetchSampleSetsInfoQuery,
	useFetchSampleSetsInfoCountQuery } = sampleSetsInfoApi;
  