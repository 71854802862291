import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from '../actions/actionTypes';


export const getPublishSummaryInitialState = () => ({
	publishSummaryIsLoading: false,
	publishSummaryHasErrored: false,
	data: [],
	authenticated: false
});


export const publishSummarySlice = createSlice({
	name: 'publishSummary',
	initialState: getPublishSummaryInitialState(),
	extraReducers: {
		[actionTypes.PUBLISHSUMMARY_HAS_ERRORED]: (state) => {
			return { ...state, publishSummaryIsLoading: false, publishSummaryHasErrored: true };
		},
		[actionTypes.PUBLISHSUMMARY_IS_LOADING]: (state) => {
			return { ...state, publishSummaryIsLoading: true };
		},
		[actionTypes.PUBLISHSUMMARY_FETCH_DATA_SUCCESS]: (state, action) => {
			return {
				...state,
				publishSummaryIsLoading: false,
				data: action.response,
				authenticated: action.authenticated || false
			};
		}
	}
});


export const publishSummaryReducer = publishSummarySlice.reducer;



export const getProjectsInitialState = () => ({
	projectsIsLoading: false,
	projectsHasErrored: false,
	data: [],
	discoveryPanelsIsLoading: false,
	discoveryPanelsHasErrored: false,
	discoveryPanels: [],
	authenticated: false
});


export const projectsSlice = createSlice({
	name: 'projects',
	initialState: getProjectsInitialState(),
	reducers: {
		projectsHasErrored: (state) => {
			state.projectsIsLoading = false;
			state.projectsHasErrored = true;
		},
		projectsIsLoading: (state) => {
			state.projectsIsLoading = true;
		},
		projectsFetchDataSuccess: (state, action) => {
			state.data = action.response;
			state.projectsIsLoading = false;
			state.projectsHasErrored = true;
		},
		discoveryPanelsHasErrored: (state) => {
			state.discoveryPanelsIsLoading = false;
			state.discoveryPanelsHasErrored = true;
		},
		discoveryPanelsIsLoading: (state) => {
			state.discoveryPanelsIsLoading = true;
		},
		discoveryPanelsFetchDataSuccess: (state, action) => {
			const dict = {};

			action.response.forEach((panel) => {
				dict[panel.projectId] = panel;
			});
			state.discoveryPanelsIsLoading = false;
			state.discoveryPanels = dict;
			state.authenticated = action.authenticated || false;
		}
	}
});

export const projectsReducer = projectsSlice.reducer;
