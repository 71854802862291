import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchProject } from '../../api';
import { 
  setSample,
  getSample
} from '../reducers/discoveryPanels.reducer';

export function* sampleSaga(action) {
  const projectId = action.payload;
  const payload = {
    data: [],
    isLoaded: false,
    error: null
  };

  try {
    const data = yield call(fetchProject, projectId);
    payload.raw = data;
  } catch (e) {
    payload.error = e.message;
  }
  
  yield put(setSample(payload));
}

export function* sampleSagas() {
  yield takeLatest(getSample, sampleSaga);
}