'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon} from '../../components/index.jsx';
import '../../theme/theme.css';
import './CollapsibleList.scss';
import classNames from 'classnames';

export const CollapsibleList = ({ className, ...props }) => {
  const [children, setChildren] = useState(React.Children.toArray(props.children));
  const [itemsToShow, setItemsToShow] = useState(props.itemsToShow);
  const [expanded, setExpanded] = useState(props.startExpanded);
  const [incrementAmount, setIncrementAmount] = useState(props.incrementAmount);
  const [expandLink, setExpandLink] = useState(props.collapseText);

  const showMore = useCallback(() => {
    if (props.incrementShow) {
      setItemsToShow(itemsToShow + incrementAmount);
      setExpandLink("");

      if (children.length <= itemsToShow + incrementAmount) {
        setExpanded(true);
      }
    }
    else {
      setItemsToShow(children.length);
      setExpanded(true);
    }
  }, [incrementAmount, children, itemsToShow]);

  useEffect(() => {
    if(props.startExpanded) setItemsToShow(children.length);
  }, []);

  useEffect(() => {
    setChildren(React.Children.toArray(props.children));
  }, [props.children]);
  
  useEffect(() => {
    setItemsToShow(props.itemsToShow);
  }, [props.itemsToShow]);

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);
  
  useEffect(() => {
    setIncrementAmount(props.incrementAmount);
  }, [props.incrementAmount]);

  const displayShowMore = () => {
    if (children.length <= props.itemsToShow) {
      return false;
    }
    return true;
  };

  return (
    <div data-testid="collapsible-list" style={{marginLeft: props.indent? "40px":""}} className={classNames("CollapsibleContainer", className)}>
      <div style={{display: props.title? 'block': 'none'}}>
        <div data-testid="cl-title" className="TitleHeader"  onClick={showMore}>
          <div className="title" style={{fontSize: props.indent? "15px":"",fontWeight:'bold'}} >
            {props.title}&nbsp;
            ({props.count})
          </div>
          
          <div className="icon">
            {expanded ?
              <Icon size={'large'} color={'primary'} icon={'sort-down'} /> :
              <Icon size={'large'} color={'primary'} icon={'caret-right'} />
            }
          </div>
        </div>
        <hr style={{height:'3px',border:'none',color:'#E9ECEF',backgroundColor:'#E9ECEF'}} />
      </div>
      {children.slice(0, itemsToShow).map((item, i) => 
        <span key={i}>{item}</span>
      )}
      <a data-testid="cl-show-more" className="ShowHideButton" style={{display: displayShowMore() && props.showMoreButton? 'block': 'none', color:'#ff4f58'}} onClick={showMore}>
        {expanded ? (<span>{expandLink}</span>) : (<span>{props.expandText}</span>)}
      </a>
    </div>
  );
};

CollapsibleList.propTypes = {
    // Number of Items to show when collapsed 
    itemsToShow: PropTypes.number,
    // Whether or not to start out expanded 
    startExpanded: PropTypes.bool,
    // Whether to show the advanced increment function 
    incrementShow: PropTypes.bool,
    // Ammount to increment by in advanced increment function 
    incrementAmount: PropTypes.number,
    // Text to use to express expand link 
    expandText: PropTypes.string,
    // Text to use to express collapse link 
    collapseText: PropTypes.string
  };
  
  CollapsibleList.defaultProps = {
    itemsToShow: 3,
    incrementAmount: 3,
    startExpanded: false,
    incrementShow: false,
    expandText: 'load more',
    collapseText: 'less',
    showMoreButton: true
  };

export default CollapsibleList;
