import { createSlice } from '@reduxjs/toolkit';

export const getInitialState = () => ({
	userIsLoading: false,
	userHasErrored: false,
	data: null,
	authenticated: false
});

export const CurrentUserSlice = createSlice({
	name: 'currentUser',
	initialState: getInitialState(),
	reducers: {
		userHasErrored: (state) => {
			state.userIsLoading = false;
			state.userHasErrored = true;
		},
		userIsLoading: (state) => {
			state.userIsLoading = true;
		},
		fetchUserFailed: (state, action) => {
			const { payload, authenticated } = action.payload;
			state.userIsLoading = false;
			state.data = null;
		},
		fetchUserSuccess: (state, action) => {
			const { payload, authenticated } = action.payload;
			state.userIsLoading = false;
			state.data = payload;
			state.authenticated = authenticated || false;
		}
	}
});

export const {
	userHasErrored,
	userIsLoading,
	fetchUserFailed,
	fetchUserSuccess,
} = CurrentUserSlice.actions;
export default CurrentUserSlice.reducer;