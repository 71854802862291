import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PT from 'prop-types';
import OneSchemaImporter from "@oneschema/react";
import { ONE_SCHEMA_CLIENT_ID, ONE_SCHEMA_WEBHOOK_KEY, ONE_SCHEMA_TEMPLATE_KEY, ONE_SCHEMA_SECRET } from '../config/env.config';
import { Button } from "@lenses/uilenses/components/Button/Button";
import { useDebounce } from '../hooks/useDebounce';
import { Stack, Typography } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import { Blue, Gray, Red } from '@/theme/colors';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Download from '@mui/icons-material/Download';
import { fetchAndDownloadManifestTemplate } from "@/discovery-panel/api";
import { resources } from '@/Assets';
import keyBy from 'lodash/keyBy';
import { useTokenMutation } from '@/redux/services/manifest.api';

const resourcesByName = keyBy(resources, 'name');

export const OneSchemaButtonTypes = {
	Button: 'button',
	Link: 'link',
	Cloud: 'cloud'
};

export const OneSchemaButton = ({ isOpen, onSuccess, onError, onCancel, onRequestClose, parentId, clientId, webhookKey = "PreProdV2",
	templateKey, projectId, projectCode, userId, secret, onLaunched, type, setIsOpen, manifestName: name, ...props }) => {
	
	const [jwtToken, setJwtToken] = useState('');

	const manifestName = useDebounce(name);
	
	const [getToken] = useTokenMutation();

	const getJWTToken = async () => {
		if (!projectId || projectId === '') return;
		const token = await getToken({ ProjectId: projectId, ManifestName: manifestName }).unwrap();
		setJwtToken(token);
	};

	const handleButtonClick = useCallback(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		if (manifestName) getJWTToken();
	}, [clientId, userId, projectId, projectCode, secret, manifestName]);

	const handleData = useCallback((data) => {
		if (onSuccess) onSuccess(data);
	}, [onSuccess]);


	const handleCancel = useCallback((e) => {
		if (onCancel) onCancel();
	}, []);


	const handleError = useCallback((e) => {
		if (onError) onError(e);
	}, []);


	const handleRequestClose = useCallback(() => {
		if (onRequestClose) onRequestClose();
		setIsOpen(false);
	}, []);

	const onDownloadManifestClick = useCallback(async () => {
		await fetchAndDownloadManifestTemplate(projectId);
	}, [projectId]);

	const onSampleManifestClick = useCallback(() => {
		window.open(`${resourcesByName["sample-manifest"].payload}`, "_blank");
	}, [resources]);

	const importerStyle = useMemo(() => ({
		position: "fixed",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		border: "none",
		zIndex: 50501
	}), []);

	return (
		<>
			{type === OneSchemaButtonTypes.Button && (
				<Button sx={{ maxHeight: 32 }} onClick={handleButtonClick} disabled={props.disabled || isOpen}>Start manifest upload</Button>
			)}
			{type === OneSchemaButtonTypes.Cloud && (
				<Stack direction="column" alignItems="center" spacing={2} sx={{margin:"-32px -32px -25px"}}>
					<Stack onClick={props.disabled || isOpen ? null : handleButtonClick} sx={{ cursor: props.disabled || isOpen ? "inherit" : "pointer", width: "100%", alignItems: "center", borderBottom:`1px solid ${Gray[200]}`, paddingBottom:"20px" }}>
						<Typography color={Gray[300]} fontSize={160} sx={{lineHeight:"1"}}>
							<BackupIcon fontSize="160px" />
						</Typography>
						<Typography sx={{ lineHeight: 1 }} variant={"h4"}>
							Manifest Upload
						</Typography>
						<Typography sx={{ lineHeight: 3, color: props.disabled ? Red[400] : Blue[500], textDecoration: props.disabled ? "none":"underline" }}>
							{props.disabled ? "Manifest name required." : "Click to start upload"}
						</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" sx={{width:"100%"}}>
						<Stack sx={{display: "flex", flexDirection: "column", flexBasis:"100%", flex:"1", borderRight:`1px solid ${Gray[200]}`, cursor:"pointer"}} onClick={onDownloadManifestClick} >
							<Typography sx={{ lineHeight: 3, color: Blue[500], textAlign:"center"}}>
								<Download />&nbsp;Download Manifest Template
							</Typography>
						</Stack>
						<Stack sx={{display: "flex", flexDirection: "column", flexBasis:"100%", flex:"1", cursor:"pointer"}} onClick={onSampleManifestClick} >
							<Typography sx={{ lineHeight: 3, color: Blue[500], textAlign:"center"}}>
								<HelpOutline />&nbsp;Sample Manifest FAQ
							</Typography>
						</Stack>
					</Stack>
				</Stack >

			)}
{
	parentId && jwtToken && jwtToken !== '' && (
		<OneSchemaImporter
			inline={true}
			isOpen={isOpen}
			onRequestClose={handleRequestClose}
			clientId={clientId}
			userJwt={jwtToken}
			templateKey={templateKey}
			webhookKey={webhookKey === "development" ? "PreProdV2" : webhookKey}
			devMode={process.env.NODE_ENV !== "production"}
			className="oneschema-importer"
			style={importerStyle}
			onSuccess={handleData}
			onCancel={handleCancel}
			onError={handleError}
			onLaunched={onLaunched}
		/>
	)
}
		</>
	);
};


OneSchemaButton.propTypes = {
	type: PT.string.isRequired,
	text: PT.string.isRequired,
	clientId: PT.string.isRequired,
	webhookKey: PT.string.isRequired,
	templateKey: PT.string.isRequired,
	secret: PT.string.isRequired,
	className: PT.string.isRequired,
	userId: PT.string.isRequired,
	projectId: PT.string.isRequired,
	projectCode: PT.string.isRequired
};


OneSchemaButton.defaultProps = {
	type: OneSchemaButtonTypes.Button,
	text: 'One Schema Import',
	clientId: ONE_SCHEMA_CLIENT_ID,
	webhookKey: ONE_SCHEMA_WEBHOOK_KEY,
	templateKey: ONE_SCHEMA_TEMPLATE_KEY,
	secret: ONE_SCHEMA_SECRET,
	className: ''
};
