'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Input} from '../index.jsx';

class ValidatedInput extends Component {

  

  static propTypes = {
    /*Function invoked when this input is validated*/
    onValidate: PropTypes.func,
    /*An array of validators to run against this input*/
    validators: PropTypes.array,
    /*A value that this input must match to be valid*/
    match: PropTypes.string,
    /*The message displayed when there is a failing match*/
    matchMessage: PropTypes.string,
    /*The message to display when validation fails.*/
    message: PropTypes.string,
    /*The type attr on the input element, also the default validator where applicable*/
    htmlType: PropTypes.oneOf(['text', 'password', 'email', 'number', 'tel', 'url']),
    /* When true the form is not validated */
    noValidate: PropTypes.bool,
  };

  static defaultProps = {
    onValidate: null,
    validators: null,
    match: '',
    matchMessage: 'Must Match!',
    message: null,
    htmlType: null,
    noValidate: false,
  };

  constructor (props) {
    super(props);
    this.state = {
      focus: false,
      dirty: false,
      value: this.props.initialValue || '',
      match: this.props.match || '',
      invalid: false,
      errors: []
    };
  }

  componentDidMount() {
    this.validate(true, false);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.match != this.state.match) {
      this.setState({ match: nextProps.match }, this.validate);
    }
    if (nextProps.initialValue && (nextProps.initialValue != this.state.initialValue)) {
      this.setState({ initialValue: nextProps.initialValue });
      this.inputRef.value = nextProps.initialValue;
    }
  }

  componentWillUnmount() {
    const {name, onUnmount} = this.props;
    if(onUnmount) onUnmount(name);   
    //this.validate(true, false);
  }

static validators = {
    email: {
      //RFC2282 Email validation
      test: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
      message: 'Please enter a valid email address'
    },
    number: {
      test: /^[^\D]+$/,
      message: 'Please enter a number'
    },
    tel: {
      //muli-format phone number
      test: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
      message: 'Please enter a valid phone number'
    },
    url: {
      test: /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
      message: 'Please enter a valid website url'
    },
    required: {
      test:(value) => {
        if(value && value.length > 0) return true;
        return false;
      },
      message: 'This field is required'
    },
    password: [
      {
        test:/^.{8,30}$/,
        message: 'Your password must be between 8 and 30 characters'
      },
      {
        test: /[A-Z]+/,
        message: 'Your password must contain at least one capital letter'
      },
      {
        test: /[a-z]+/,
        message: 'Your password must contain at least one lowercase letter'
      },
      {
        test: /[0-9]+/,
        message: 'Your password must contain at least one numerical character'
      },
      {
        test: /([^A-z\s\d]|[\\\^])+/,
        message: 'Your password must contain at least one special character'
      }
    ]
  };


  validate(dispatch=true, update=true) {
    let validators = (this.props.validators || [this.props.type]).concat(),
      errors = [];
    if(this.props.required) validators.push('required');
    if(this.props.match) validators.push({
        message: this.props.matchMessage,
        test:this.checkMatch
    });

    for(let validator of validators) {
      //check string
      if(typeof validator === 'string') validator = ValidatedInput.validators[validator];
      if(validator) this.test(validator, errors);
    }
    let {message, name} = this.props;
    if(message) {
      if(message.indexOf('-=') == 0) errors.unshift(<span key={0}>message</span>);
      else if(message.indexOf('+=') == 0) errors.push(<span key={0}>message</span>);
      else errors = [<span key={0}>message</span>];
    }
    let valid = errors.length == 0;
    if(this.props.noValidate) {
      valid = true;
      errors = [];
    }
    if(this.props.validatepwnotempty){
      if(this.inputRef.value.length == 0){
        valid = false;
        errors =[<span key={0}>Please enter a valid password</span>];
      } else {
        valid = true;
        errors =[];
      }

    }
    if(update) this.setState({errors, invalid:!valid});
    if(dispatch && this.props.onValidate)
        this.props.onValidate(name, this.inputRef.value, valid);
    return valid;
  }

  test(validator, errors) {
    if(Array.isArray(validator)) {
      for(let v of validator) {
        this.test(v, errors);
      }
    }
    else {
      const {test, message} = validator;
      const value = this.inputRef.value;
      let invalid = false;
      if(test instanceof RegExp) {
        invalid = !test.test(String(value));
      }
      else {
        invalid = !test(value);
      }
      if(invalid) errors.push(<span key={errors.length+1}>{message}</span>);
    }
  }

  onChange = (e) => {
    let value = e.target.value;
    this.setState({value}, () => { 
      this.validate(); 
      if(this.props.onChange) this.props.onChange(value);
    });
  };

  onBlur = () => {
    this.setState({focus:false, dirty:true}, this.validate);
  };

  onFocus = () => {
    this.setState({focus:true});
  };

  setInputRef = el => {
    this.inputRef = el;
    if(this.inputRef){
      this.inputRef.value = this.props.initialValue || '';
    }
  };

  generateClassNames(base=null) {
    let classNames = [];
    const {value, focus, invalid} = this.state;
    classNames.unshift('ValidatedInput');
    if(!value.length) classNames.push('empty');
    if(focus) classNames.push('focused');
    if(invalid) classNames.push('error');
    return classNames;
  }

  get classNames() {
    return this.generateClassNames().join(' ');
  }

  get value() {
    return this.state.value;
  }

  checkMatch = (value) => {
    return value == this.props.match;
  };

  render() {
    //pull out props not intended for base input component
    const {ref, type, htmlType, onChange, onUnmount, onValidate, match, matchMessage, message, validators, initialValue, ...inputProps} = this.props;

    let displayType = htmlType || type;
    return <Input className={this.classNames}
      defaultValue={this.state.initialValue}
      inputRef={this.setInputRef}
      onBlur={this.onBlur}
      onChange={this.onChange}
      onFocus={this.onFocus}
      type={displayType}
      {...inputProps} >

      {this.state.errors.length > 0 &&
        <div className="error-messages">
          {this.state.errors}
        </div>
      }
      </Input>;
  }
}



export default ValidatedInput;