'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import { getBlob, put } from '../../middleware/requests';
import urljoin from 'url-join';

/** Action Creators for Pathway Maps */
export function pathwayPlantHasErrored(bool) {
    return {
        type: actionTypes.PLANT_HAS_ERRORED,
        hasErrored: bool
    };
}

export function pathwayAnimalHasErrored(bool) {
    return {
        type: actionTypes.ANIMAL_HAS_ERRORED,
        hasErrored: bool
    };
}

export function pathwayPlantIsLoading(bool) {
    return {
        type: actionTypes.PLANT_IS_LOADING,
        isLoading: bool
    };
}

export function pathwayAnimalIsLoading(bool) {
    return {
        type: actionTypes.ANIMAL_IS_LOADING,
        isLoading: bool
    };
}

export function pathwayPlantFetchDataSuccess(map) {
  return {
      type: actionTypes.PLANT_FETCH_DATA_SUCCESS,
      map
  };
}

export function pathwayAnimalFetchDataSuccess(map) {
  return {
      type: actionTypes.ANIMAL_FETCH_DATA_SUCCESS,
      map
  };
}


export function fetchPathwayPlantMapData() {
    return pathwayPlantFetchData(config.API_ENDPOINT_PATHWAY_PLANT);
}

export function fetchPathwayAnimalMapData() {
    return pathwayAnimalFetchData(config.API_ENDPOINT_PATHWAY_ANIMAL);
}

export function pathwayPlantFetchData(endpoint, query = null) {
    let apiEndpoint = endpoint;
    if(query)
        apiEndpoint = urljoin(apiEndpoint, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.PLANT_IS_LOADING, actionTypes.PLANT_FETCH_DATA_SUCCESS, actionTypes.PLANT_HAS_ERRORED]
    }
  };
}

export function pathwayAnimalFetchData(endpoint, query = null) {
    let apiEndpoint = endpoint;
    if(query)
        apiEndpoint = urljoin(apiEndpoint, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.ANIMAL_IS_LOADING, actionTypes.ANIMAL_FETCH_DATA_SUCCESS, actionTypes.ANIMAL_HAS_ERRORED]
    }
  };
}

export const getPathwayPlantMap = () => {
  const endpoint = config.API_ENDPOINT_PATHWAY_PLANT_MAP;
  return async (dispatch) => {
    const response = await getBlob(endpoint);
    return response.payload;
  };
};

export const getPathwayAnimalMap = () => {
  const endpoint = config.API_ENDPOINT_PATHWAY_ANIMAL_MAP;
  return async (dispatch) => {
    const response = await getBlob(endpoint);
    return response.payload;
  };
};

export const putPathwayPlantMap = (payload) => {
  //'Content-Type': 'application/octet-stream'
  const endpoint = config.API_ENDPOINT_PATHWAY_PLANT_PUT_MAP;
  return async (dispatch) => {
    return await put(endpoint, payload);
  };
};

export const putPathwayAnimalMap = (payload) => {
  //'Content-Type': 'application/octet-stream'
  const endpoint = config.API_ENDPOINT_PATHWAY_ANIMAL_PUT_MAP;
  return async (dispatch) => {
    return await put(endpoint, payload);
  };
};
