import { useEffect, useState } from "react";

export const useDebounce = (val, delay = 250) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(val);
    }, delay);

    return () => window.clearTimeout(timeout);
  }, [val, delay]);

  return value;
};