import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Modal from "metabux/components/Modal/Modal"
import { Box, Typography } from "@mui/material"
import { Button } from "metabux/components/Button/Button"

export function ManifestResponseModal({
	open,
	setOpen,
	onNext,
	onNextUpload
}) {

	const onClose = useCallback(() => {
		onNextUpload()
	}, [setOpen]);

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Upload Successful"
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: 5,
					mt: 5,
				}}
			>
				<Typography>Success! You uploaded your sample manifest: </Typography>
				<Box className="success">
					<Box className="bg-success" sx={{ borderRadius: "50%" }}>
						<i className="fa fa-check fa-5x icon text-white" />
					</Box>
				</Box>

				<Box sx={{ display: "flex", gap: "10px" }}>
					<Button variant={"secondary"} color={"black"} onClick={onClose} sx={{ width: "150px" }}>
						Upload another manifest
					</Button>

					<Button
						onClick={onNext}
						sx={{ width: "150px" }}
					>
						Next: Shipping Information
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

ManifestResponseModal.propTypes = {
	open: PropTypes.bool,
	setActiveStep: PropTypes.func,
	setOpen: PropTypes.func,
}
