import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box, Grid, Stack } from "@mui/material"

import { Button } from "metabux/components/Button/Button"
import { Tabber } from "metabux/components/Tabber/Tabber"
import {
	useUpdateShippingMutation,
	useUpdateOnboardingMutation,
	useSamplesReadyMutation
} from '@/redux/services/coe.api'
import { selectCoe, selectCoeOnboarding } from '@/views/clientOnboarding/redux/selector/coe.selector'
import { setDateChangeReason, setNewDate, setCurrent } from '@/views/clientOnboarding/redux/reducers/coe.reducer'
import CalendarSelect from "metabux/components/CalendarSelect/CalendarSelect"
import { Input } from "metabux/components/Input/Input"
import { formatDate } from "metabux/utils/DateUtils"
import dayjs from 'dayjs'
import { showToaster } from '@/utils/ToastController';

export const ShipDateConfirmation = ({
	projectId
}) => {
	const dispatch = useDispatch()
	const [updateShipping, { status: shippingStatus }] = useUpdateShippingMutation()
	const [mutateOnboarding, { status: onboardStatus }] = useUpdateOnboardingMutation()
	const [setSamplesReady, { status: sampleStatus }] = useSamplesReadyMutation()
	const { current, dateChangeReason, newDate, shipDate } = useSelector(selectCoe)
	const onboardingState = useSelector(selectCoeOnboarding)

	const day = useMemo(() => shipDate ? dayjs(shipDate) : dayjs(), [shipDate])
	const newDay = useMemo(() => newDate ? dayjs(newDate) : null, [newDate])

	const [publishReqStarted, setPublishReqStarted] = useState(false)

	const onNext = useCallback(() => {
		dispatch(setCurrent(current + 1))
	}, [current, dispatch])

	useEffect(() => {
		if (publishReqStarted) {
			if (shippingStatus !== 'pending' && onboardStatus !== 'pending' && sampleStatus !== 'pending') {
				setPublishReqStarted(false)
				showToaster('Success!', 'Sample Ship Date updated!', 5000, 'success.main')
				onNext()
			}
			return
		}
	}, [shippingStatus, onboardStatus, sampleStatus, publishReqStarted, onNext])

	const onReasonChange = useCallback(value => {
		dispatch(setDateChangeReason(value))
	}, [dispatch])

	const onDateSelect = useCallback(value => {
		dispatch(setNewDate(value))
	}, [dispatch])

	const onConfirmDate = useCallback(() => {
		setSamplesReady(projectId)
		setPublishReqStarted(true)
	}, [projectId, setSamplesReady])

	const onUpdateDate = useCallback(() => {
		setPublishReqStarted(true)
		updateShipping({ projectId, newShippingDate: newDay.format(), reasonForChange: dateChangeReason || 'init' })
		mutateOnboarding({ ...onboardingState, projectId, shipDate: newDay.format(), shipDateChangeReason: dateChangeReason || 'init' })
	}, [onboardingState, day, newDay, setSamplesReady, dateChangeReason, projectId])

	return (
		<>
			<Typography variant="h4" textAlign="center" mb={2}>What is your sample shipping status?</Typography>
			<Box className="step-content">

				<Tabber
					gap={0}
					className="switch"
					tabProps={{ variant: "body2", fontWeight: 600 }}
					tabs={[
						{ label: "I'm ready to ship" },
						{ label: "I need more time" },
					]}
				>
					<Box>
						<Typography variant="h5" textAlign="center" mb={25} mt={4}>
							Scheduled final shipment date: <Typography component="span" variant='inherit' color="error.main">{day && day.format('MMM. D, YYYY')}</Typography>
						</Typography>

						<Typography textAlign="center" color="error" mb={4}>Note: Capture multiple shipments in the Manifest Upload tab</Typography>

						<Button
							sx={{ mx: "auto", display: "flex" }}
							onClick={onConfirmDate}
							size="medium"
						>
							Submit Shipping Info
						</Button>
					</Box>

					<Box>
						<Typography variant="h5">Shipping Schedule</Typography>
						<Typography variant="body1">Scheduled ship date</Typography>
						<Typography variant="body1" fontWeight={600} color="error">{day && day.format('MMM. D, YYYY')}</Typography>

						<Grid container>
							<Grid item xs={12} md={6}>
								<CalendarSelect
									value={newDay}
									onChange={onDateSelect}
									sx={{ margin: 0 }}
									disablePast
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Input
									placeholder={"Why are you changing your ship date?"}
									onChange={onReasonChange}
									rows={8}
									multiline
									fullWidth
								/>
							</Grid>
						</Grid>

						<Button
							sx={{ mx: "auto", display: "flex" }}
							disabled={!newDate || !dateChangeReason}
							onClick={onUpdateDate}
						>
							Update to {newDate && formatDate(newDate)}
						</Button>
					</Box>
				</Tabber>
			</Box>
			<Stack width="100%" direction="row-reverse">
				<Button
					disabled={!shipDate}
					onClick={onNext}
				>
					Next: Manifest Upload
				</Button>
			</Stack>
		</>
	)
}