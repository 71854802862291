// extracted by mini-css-extract-plugin
var _1 = "bottom_content-wrapper";
var _2 = "bottom_donut-detected";
var _3 = "bottom_donut-label";
var _4 = "bottom_donut-metabolites";
var _5 = "bottom_donut-ring";
var _6 = "bottom_donut-segment";
var _7 = "bottom_left-wrapper";
var _8 = "bottom_right-wrapper";
var _9 = "bottom_svg-item";
export { _1 as "content-wrapper", _2 as "donut-detected", _3 as "donut-label", _4 as "donut-metabolites", _5 as "donut-ring", _6 as "donut-segment", _7 as "left-wrapper", _8 as "right-wrapper", _9 as "svg-item" }
