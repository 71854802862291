'use strict';

import React, { Component } from 'react';
import dayjs from 'dayjs';
//import Worker from './DynamicTable.worker.js?worker&module=true';
//import './DynamicTable.worker';

import DynamicTableCell from './DynamicTableCell.jsx';
import DynamicTableCheckCell from './DynamicTableCheckCell.jsx';
import { Label } from '../index.jsx';
import { convertDate } from '../../utils/DateUtils';
import HTMLConverter from '../../utils/HTMLConverter.js';
import SettingsMap from '../../utils/SettingsMap.js';
import Sort from '../../utils/Sort';
import { getWorkerUrl } from '@/utils/WebWorkerUtils.js';

export default class DynamicTableHelper extends Component {

	static createWorker = () => {
		const worker = new Worker(getWorkerUrl('./DynamicTable.worker.js'), { format: 'es', type: 'module' })
		return worker;
		//return new Worker();
	};

	static date = () => {
		return dayjs();
	};

	static HTMLConverter() { return HTMLConverter; }
//default cell renderers
	static defaultFieldRenderer = (data, fieldName, n) => {
		let val = data[fieldName];
		return <DynamicTableCell className={fieldName} key={n} title={val}><span >{val}</span></DynamicTableCell>;
	};

	static defaultCheckRenderer = (data, checkProps, n) => {
		return <DynamicTableCheckCell {...checkProps} key={n} />;
	};

	static defaultDateRenderer = (data, fieldName, n) => (
		<DynamicTableCell className={fieldName + " date"} key={n}>
			<Label>{convertDate(data[fieldName], 'DD MMM YYYY')}</Label>
		</DynamicTableCell>
	);

	static createDefaultFieldRenderer = (className) => {
		return (data, fieldName, n) => {
			let val = data[fieldName];
			return <DynamicTableCell className={className} key={n} title={val}><span >{val}</span></DynamicTableCell>;
		};
	};

	static createField(name, header, options = {}) {
		const className  = options.className = options.className || name;
		let defaultRenderer = (options.type && options.type === "select") ? this.defaultCheckRenderer : this.createDefaultFieldRenderer(className);
		options.renderer = options.renderer || defaultRenderer;
		return {
			name, header, ...options
		};
	}

	static createStaticField(name, header, content, options = {}, fieldValue) {
		fieldValue = fieldValue || content;
		const className  = options.className = options.className || name;
		options.renderer = options.renderer || ((data, fieldName, n) => (
			<DynamicTableCell key={n} className={className}>
				<span title={content}>{content}</span>
			</DynamicTableCell>
		));
		options.comparator = options.comparator || 'fixed';
		return {
			name, header, fieldValue, ...options
		};
	}

	static createOptionalFields(fields, fieldDict) {
		return fields.map(field => this.createField(field, fieldDict[field]));
	}

	static isNil(val) {
		return (val === null || val === '' || val === undefined);
	}

	static nilCompare(a, b) {
		if (this.isNil(a) && this.isNil(b)) return 0;
		if (this.isNil(b)) return -1;
		if (this.isNil(a)) return 1;

		return null;
	}

	static filterData = (data, filters) => {
		let filteredData = data.concat();
		filters.forEach((filter, n) => {
			filteredData = filteredData.filter(filter);
		});
		return filteredData;
	};

	static sort = (data, fields, sorter = null, sortDir = 1) => {
		if (!sorter) return data;

		const field = fields.find(f => f.name === sorter);
		let comparator = (field && field.comparator) ? field.comparator : 'basic';
		comparator = typeof comparator === "string" ? Sort[comparator](sorter, sortDir) : comparator(sorter, sortDir);
		let sortedData = data.sort(comparator);
		
		return sortedData;
	};

	static optionalFields = {};
}