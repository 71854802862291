import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { scaleRadial, arc } from "d3";
import PropTypes from "prop-types";
import { delimiter } from "@/discovery-panel/utils/constants";

import Square from "./Square";
import { dataSelector } from "../../redux/selectors/data.selector";
import getDocStyle from "../../utils/getDocStyle";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";

const Heatmap = ({ handleEvents, opacityList }) => {
  const { results } = useSelector(dataSelector);
  const { multiComparisons } = useSelector(filterSelector);
  const { metaboliteCoords } = useSelector(explorerSelector);
  const viewPort = useSelector(viewPortSelector);

  const arcFunction = useCallback(
    (angle, comparison) => {
      const yScale = scaleRadial()
        .domain([0, multiComparisons.length])
        .range([viewPort.innerRadius, viewPort.outerRadius]);

      const arcPath = arc()
        .innerRadius((d) => yScale(multiComparisons.indexOf(comparison)))
        .outerRadius((d) => yScale(multiComparisons.indexOf(comparison) + 1))
        .startAngle((d) => angle)
        .endAngle((d) => angle + viewPort.blockWidth);

      return arcPath();
    },
    [multiComparisons, viewPort]
  );

  const resultsAngle = useMemo(() => {
    return results.map((d) => {
      const angle = metaboliteCoords.find((e) => {
        return e.chemical_id === d.chemical_id;
      })?.angle || 0;
      return {
        ...d,
        arc: arcFunction(angle, d.comparison),
      };
    });
  }, [arcFunction, metaboliteCoords, results]);

  return (
    <g>
      {resultsAngle.map((d, i) => {
        return (
          <Square
            key={d.chemical_id + d.comparison + delimiter + i}
            path={d.arc}
            fill={d.color}
            stroke={
              opacityList.includes(d.chemical_id)
                ? getDocStyle("var(--metabolon-base)")
                : "white"
            }
            opacity={
              opacityList.includes(d.chemical_id) ||
              opacityList.includes("default")
                ? 1
                : viewPort.notSelectedOpacity
            }
            handleEvents={handleEvents}
            chemicalId={d.chemical_id}
            metabolite={d.metabolite}
            comparison={d.comparison}
            className={
              d.chemical_id +
              " _ " +
              d.comparison +
              " _ " +
              d.microbiome_pathway
            }
          />
        );
      })}
    </g>
  );
};

Heatmap.propTypes = {
  opacityList: PropTypes.array,
};

Heatmap.defaultProps = {
  opacityList: ["default"],
};

export default memo(Heatmap);
