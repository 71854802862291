'use strict';
import React, { useState, useEffect, useMemo, useCallback, useRef }  from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { projectFilesComponentSelector } from '../../../redux/selectors';
import { Button, EmptyState, Icon, Toaster } from '../../../components/index.jsx';
import FilesTable from '../../../containers/FilesTable.jsx';
import SearchContainer from '../../../containers/SearchContainer.jsx';
import './ProjectFiles.scss';
import RefreshIndicator from '../../../components/controls/RefreshIndicator.jsx';
import * as enums from '../../../enums/Enums';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger';
import { useLogger, Log } from '../../../utils/Logger';
import {AppInsightLogLevel} from '../../../enums/Enums';
import { toast  } from 'react-toastify';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import {
  filesFetchData as filesFetchDataActionCreator,
  filesFetchDataAdmin as filesFetchDataAdminActionCreator,
  fetchAllFiles as fetchAllFilesActionCreator,
  fetchFilesByIds as fetchFilesByIdsActionCreator,
  filesSaveDataAdmin as filesSaveDataAdminActionCreator,
  uploadAttachment as uploadAttachmentActionCreator
} from '../../../redux/actions/files';
import { usePermissions } from '@/hooks/permissions.hook';


export const ProjectFiles = (props) => {
  const timeoutRef = useRef(null);
  const logger = useLogger();
  const auditLogger = useLocalAuditLogger();
	const { checkUserHasRole } = usePermissions();
  const data = useSelector(projectFilesComponentSelector);
  const [ project, setProject ] = useState(props.project);
  const [ allFiles, setAllFiles] = useState([]);
  const [ visibleFiles, setVisibleFiles ] = useState(data.files);
  const [ selectedFileIds, setSelectedFileIds ] = useState([]);
  const [ showFiles, setShowFiles ] = useState(false);
  const [ showSearchDialog, setShowSearchDialog ] = useState(false);
  const [ fileChanges, setFileChanges ] = useState([]);
  const [ isSavingFiles, setIsSavingFiles ] = useState(false);
  const [ errormsg, setErrormsg ] = useState('');
  const [ publishFilesObject, setPublishFilesObject ] = useState(null);
  const [ preEditFilesState, setPreEditFilesState ] = useState(null);
  const [ attachmentIsLoading, setAttachmentIsLoading ] = useState(false);
  const dispatch = useDispatch();

  const fetchData = (params) => dispatch(filesFetchDataActionCreator(params));
  const fetchDataAdmin = (params) => dispatch(filesFetchDataAdminActionCreator(params));
  const filesSaveDataAdmin = (params) => dispatch(filesSaveDataAdminActionCreator(params));
  const fetchAllFilesAction = (project) => dispatch(fetchAllFilesActionCreator(project));
  const fetchFilesByIds = (project, fileIds) => dispatch(fetchFilesByIdsActionCreator(project, fileIds));
  const uploadAttachment = (projectId, file, comment) => dispatch(uploadAttachmentActionCreator(projectId, file, comment));

  const loadPreviewData = useCallback(async (projectId) => {
    if (!projectId) return;
    const files = await fetchDataAdmin(projectId);
    const onlyVisibleFiles = files.response.filter((e) => e.customerVisible == true);
    setVisibleFiles(onlyVisibleFiles);
    setAllFiles(onlyVisibleFiles);
    setShowFiles(true);
  }, []);

  const onSearchChange =  useCallback((text) => {
    if (text.length == 0) {
      setVisibleFiles(allFiles);
    }

    if (allFiles) {
      const searchTerm = text.toLowerCase();
      const fileList = allFiles.filter((file) => file.name.toLowerCase().includes(searchTerm));

      setVisibleFiles(fileList);
    }
  }, [allFiles]);

  const keyUpHandler = useCallback((e) => {
    clearTimeout(timeoutRef.current);

    let text = '';

    if (e.target) {
      text = e.target.value;
    }
    
    if (data.files.length > 1000){
      timeoutRef.current = setTimeout(() => {
        onSearchChange(text);
      }, 800);
    } else { 
      onSearchChange(text);
    }
  }, [allFiles]);

  const checkboxClickHandler = useCallback((selectedFiles) => {
    setSelectedFileIds(selectedFiles);
  }, []);

  const auditLogFileDownload = (projectId, listOfFileNames) => {
    auditLogger.logProjectFileDownload(projectId, listOfFileNames);
  };

  const fetchAllFiles = useCallback(() => {
    fetchAllFilesAction(project);

    const allFileNames = data.files.map((f) => f.name);
    const allFileSizes = data.files.map((f) => f.size);

    auditLogFileDownload(project.projectId, allFileNames);

    const logDownload = new Log();

    logDownload.SetLevel(AppInsightLogLevel.EVENT);
    logDownload.SetName('PageEvent_FileDownload_All');
    logDownload.AddProperty('Filesize', allFileSizes);
    logDownload.AddProperty("PublishDate", project.publishDate);
    logDownload.AddProperty('Count', allFileNames.length);
    logDownload.AddProperty('ProjectId', project.projectId);

    logger.doLog(logDownload);
  }, [project]);

  const fetchFilesById = useCallback(() => {
    fetchFilesByIds(project, selectedFileIds);

    const filteredFiles = data.files.filter((f) => selectedFileIds.includes(f.fileId));
    const filteredfileNames = filteredFiles.map((f) => f.name);
    const filteredFileSizes = filteredFiles.map((f) => f.size);

    auditLogFileDownload(project.projectId, filteredfileNames);

    const logDownload = new Log();

    logDownload.SetLevel(AppInsightLogLevel.EVENT);
    logDownload.SetName('PageEvent_FileDownload_Selected');
    logDownload.AddProperty('Filesize', filteredFileSizes);
    logDownload.AddProperty("PublishDate", project.publishDate);
    logDownload.AddProperty('Count', filteredFileSizes.length);
    logDownload.AddProperty('ProjectId', project.projectId);

    logger.doLog(logDownload);
  }, [project, selectedFileIds]);

  const showToaster = (title, body) => {
    toast(<Toaster title={title} body={body} />);
  };

  
  const updateFileChanges = useCallback((changes) => {
    setFileChanges(changes);
    setErrormsg('');
  }, []);

  const loadingStyle = useMemo(() => ({
    container: {
      display: 'inline-block',
      position: 'relative',
      textAlign:'center',
      width: 'auto'
    },
    refresh: {
      display: 'inline-block',
      position: 'relative',
      marginRight: '45px',
      marginLeft: '45px'
    }
  }), []);

  const refreshIndicatorDownloadAllButtonLoading = useCallback(() => (
    <div style={loadingStyle.container}>
      <Button className="downloadAll" color="tertiary">
        <RefreshIndicator
          size={'large'}
          left={0}
          top={0}
          status="loading"
          style={loadingStyle.refresh}
        /> 
      </Button>
    </div>
  ), [ loadingStyle ]);

  const refreshIndicatorDownloadSelectedButtonLoading = useCallback(() => (
    <div style={loadingStyle.container}>
      <Button alt={true} className="downloadselected" color="primary">
        <RefreshIndicator
          size={'large'}
          left={5}
          top={0}
          status="loading"
          style={loadingStyle.refresh}
      />
      </Button>
    </div>
  ), [ loadingStyle ]);

  const fetchDataAndSetFiles = async (query) => {
    const files = await fetchData(query);

    if (!files) {
      return;
    }
    setAllFiles(files.response);
    setVisibleFiles(files.response);
    setShowFiles(true);
  };

  const fetchAndSetAdminData = async (projectId) => {
    const files = await fetchDataAdmin(projectId);

    setVisibleFiles(files.response);
    setShowFiles(true);
    setPublishFilesObject(files.response);
    setPreEditFilesState({ ...files.response });
  };

  useEffect(() => {
  
    if(!project.projectId) {return;}
    if (project.projectId){
      if (props.publishPreviewMode){
        loadPreviewData(project.projectId);
      } else if (!project.lastPublishDate && checkUserHasRole(enums.ROLES.DTS)){
        loadPreviewData(project.projectId);
      } else {
        fetchData(project.projectId);
      }

      localStorage.setItem("CurrentFilesProjectId", project.projectId);
      localStorage.setItem("CurrentProjectPublishDate", project.publishDate);
    }  

    return () => {
      localStorage.removeItem("CurrentFilesProjectId");
      localStorage.removeItem("CurrentProjectPublishDate");
    };
  }, []);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    const projectId = project.projectId ? `${project.projectId}` : null;
    if(!project.projectId) {return;} 
    localStorage.setItem("CurrentFilesProjectId", project.projectId);
    localStorage.setItem("CurrentProjectPublishDate", project.publishDate);
    let previewMode = false;
    //getPreviewmode from local storage?
  if( localStorage.getItem('previewMode') == projectId){
    previewMode = true;
  }

    if (!previewMode) {
      if (!project.lastPublishDate && checkUserHasRole(enums.ROLES.DTS)) {
        loadPreviewData(projectId);
      } else {
        fetchDataAndSetFiles(projectId);
      }
    }
    else   if (previewMode && checkUserHasRole(enums.ROLES.DTS) ) {
      loadPreviewData(projectId);
    }
  }, [project, props.publishPreviewMode]);

  useEffect(() => {
    if (props.editMode && project.projectId) {
      fetchAndSetAdminData(project.projectId);
    }
  }, [ props.editMode ]);

  useEffect(() => {
    if (props.publishPreviewMode && project.projectId) {
      loadPreviewData(project.projectId);
    }
  }, [ props.publishPreviewMode ]);

  return (
    <div className="ProjectFiles" data-testid="projectFiles">
        {(visibleFiles) ? (
        <div className="TableView">
          <div className="header">
            <div className="searchInput"> 
                <SearchContainer
                  onKeyUp={keyUpHandler}
                  objectId={project.projectId}
                  iconColor={'tertiary'}
                  hintText={'Search Files'}
                  minSearchChars={3}
                  showResults={false}
                  dataModel={'files'}
                />

                {showSearchDialog && <div>Searching ...</div>}

                <div className="errormsg">{errormsg}</div>
            </div>
            
            <div className="headerActions">  
              <div className="selectDialog">
                {selectedFileIds.length > 0 && `${selectedFileIds.length} Selected`}
              </div>

              {data.isSelectedLoading ? (
                refreshIndicatorDownloadSelectedButtonLoading()
              ) : (
                <Button alt={true} size="normal" className="downloadSelected" color="tertiary" onClick={fetchFilesById}>Download Selected</Button>
              )}

              {data.isLoading ? (
                refreshIndicatorDownloadAllButtonLoading()
              ) : (
                <Button size="normal" className="downloadAll"  color="tertiary" onClick={fetchAllFiles}>Download All <Icon icon="download"/></Button>
              )}
            </div>
          </div>

          {showFiles ? (
            <FilesTable
              projectId={project.projectId}
              attachmentIsLoading={attachmentIsLoading}
              handleAttachmentUpload={undefined}
              updateFileChanges={updateFileChanges}
              onSaveAdminFilesClick={undefined}
              isSavingFiles={isSavingFiles} 
              editMode={props.editMode}
              viewName={"PageEvent_Results"}
              files={visibleFiles}
              onCheckboxClick={checkboxClickHandler}
              selections={selectedFileIds}
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>) : (
          <EmptyState />
        )
      }
    </div>
  );
};


ProjectFiles.propTypes = {
    project: PropTypes.object.isRequired,
    publishPreviewMode: PropTypes.bool,
    editMode: PropTypes.bool,
    projectId: PropTypes.string
};

export default ProjectFiles;
