
'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import SamplesetUtils from '../utils/SamplesetUtils.js';
import Sort from '../utils/Sort.js';
import { Icon, Material, Input } from '../components/index.jsx';
import './StatsList.scss';

export default class StatsList extends Component {
	static propTypes = {
		/** files*/
		sampleSet: PropTypes.object,
		stats: PropTypes.array,
		onItemToggle: PropTypes.func,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		sampleSet: {},
		stats: [],
		disabled: false,
	};

	constructor(props) {
		super(props);
		const { sampleSet, selection, stats } = this.props;
		this.state = {
			selection,
			stats: this.sortStats(stats.map(s => ({ ...s }))),
			sampleSet,
		};

		this.inputs = {};
		this.setInput = this.bind(this.setInput);
		this.reorder = this.bind(this.reorder);
		this.onDragEnd = this.bind(this.onDragEnd);
		this.sortStats = this.bind(this.sortStats);
		//this.createTableFields = this.bind(this.createTableFields);
	}

	/*componentWillReceiveProps(prevProps) {
		const {sampleSet, selection, stats} = this.props;
		if(sampleSet != prevProps.sampleSet) this.updateState({sampleSet, stats: this.sortStats(stats)});

		else if(stats !== prevProps.stats) this.updateState({stats: this.sortStats(stats)});
	}*/
	componentDidUpdate(prevProps, prevState) {
		const { sampleSet, selection, stats } = this.props;
		if (sampleSet && prevProps.sampleSet) {
			if (sampleSet != prevProps.sampleSet) this.updateState({ sampleSet, stats: stats.map(s => ({ ...s })) });
		}

		else if (stats && prevProps.stats) {
			if (stats.length != prevProps.stats.length) this.updateState({ stats: stats.map(s => ({ ...s })) });
		}
	}

	sortStats(stats) {
		return stats.sort(Sort.numeric('customerIndex', 1));
	}

	bind(func, ...args) {
		return func.bind(this, ...args);
	}

	updateState(data, callback) {
		this.setState(data, callback);
	}

	generateClassNames = (base = 'StatsList') => {
		const { className, disabled } = this.props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (disabled) classNames.unshift('disabled');
		if (className) classNames.unshift(className);

		return classNames;
	};

	onItemToggle = (selections) => {
		//console.log(selections);
		let { onItemToggle } = this.props;
		let { sampleSet } = this.state;
		if (onItemToggle) onItemToggle(sampleSet.id, selections);
	};

	onRowClick(id) {
		if (id === this.state.selection) return;

		this.setState({ selection: id }, () => {
			const { onRowClick } = this.props;
			if (onRowClick) onRowClick(id);
		});
	}

	onEyeClick(stat) {
		let { sampleSet, stats } = this.state,
			realStat = stats.find(s => s.statsId == stat.statsId);

		if (!realStat) return;

		sampleSet.hasChanged = true;
		realStat.customerVisible = !realStat.customerVisible;
		sampleSet.stats = stats;
		this.props.onSampleSetChange(sampleSet);
		this.setState({ stats });
	}

	onPenClick(stat) {
		let { stats } = this.state,
			realStat = stats.find(s => s.statsId == stat.statsId);
		if (!realStat) return;

		realStat.editing = true;
		let visibleLabel = stat.comparison;
		if (stat.customerName) visibleLabel = stat.customerName;
		else if (stat.reportName) visibleLabel = stat.reportName;
		else if (stat.foldChange) visibleLabel = stat.foldChange;

		this.setState({ stats }, () => { this.inputs[stat.statsId].focus(); this.inputs[stat.statsId].value = visibleLabel; });
	}

	onSaveClick(stat) {

		let { sampleSet, stats } = this.state,
			val = this.inputs[stat.statsId].value,
			realStat = stats.find(s => s.statsId == stat.statsId);

		if (!realStat) return;

		sampleSet.hasChanged = true;
		let trimmedValue = val.trim();
		realStat.customerName = trimmedValue;
		realStat.editing = false;
		sampleSet.stats = stats;
		this.props.onSampleSetChange(sampleSet);
		this.setState({ stats });
	}

	onCancelClick(stat) {
		let { stats } = this.state,
			realStat = stats.find(s => s.statsId == stat.statsId);
		if (!realStat) return;

		realStat.editing = false;
		//this.inputs[stat.statsId].value = realStat.customerName;
		this.setState({ stats });
	}

	onStatClick(stat) {
		const { onStatSelect } = this.props;
		if (onStatSelect) onStatSelect(stat);
		//update active css
	}

	reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const stats = this.reorder(
			this.state.stats,
			result.source.index,
			result.destination.index
		);

		let { sampleSet } = this.state;
		sampleSet.hasChanged = true;

		stats.forEach((stat, n) => {
			stat.customerIndex = n;
		});

		sampleSet.stats = stats;
		this.props.onSampleSetChange(sampleSet);

		this.setState({
			stats
		});
	}

	getDefaultLabel(data, fieldName, fallback = "Stats") {
		let label;
		if (fieldName && data[fieldName]) label = data[fieldName];
		else if (data.reportName) label = data.reportName;
		else if (data.foldChange) label = data.foldChange;
		else label = data.comparison;
		label = label ? label : fallback;
		return label;
	}

	renderListItem(data, n, parentCustomerVisible, active) {
		let classNames = ['customerName'], fieldName = 'customerName';
		if (active) classNames.push('active');
		let className = classNames.join(' ');
		let label = this.getDefaultLabel(data, fieldName), title = SamplesetUtils.getStatTitle(data);
		let { customerVisible, editing, statsId } = data;
		let eyeColor = customerVisible && parentCustomerVisible ? 'primary' : 'inactive';

		const eyeCb = this.bind(this.onEyeClick, data);
		const penCb = this.bind(this.onPenClick, data);
		const saveCb = this.bind(this.onSaveClick, data);
		const cancelCb = this.bind(this.onCancelClick, data);
		const clickCb = this.bind(this.onRowClick, statsId);

		return (
			<Draggable draggableId={statsId} key={statsId} index={n}>
				{provided => (
					<li className={className} onClick={clickCb} key={n} ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}>
						<Icon icon={'bars'} />
						<Material title="Toggle visibility" onClick={eyeCb} color={eyeColor} icon={customerVisible ? 'visibility' : 'visibility_off'} />

						<Input inputRef={this.setInput.bind(this, data.statsId)} wrapperClass={editing ? '' : 'd-none'} />
						<span title={title} className={editing ? 'label d-none' : 'label'} onClick={this.onStatClick.bind(this, data)}>{label}</span>
						<Material className={editing ? 'd-none' : ''} title="Edit name" onClick={penCb} icon={'edit'} />
						<Material className={editing ? '' : 'd-none'} title="Cancel" onClick={cancelCb} icon={'close'} />
						<Material className={editing ? '' : 'd-none'} title="Save" onClick={saveCb} icon={'save'} />
					</li>
				)}
			</Draggable>
		);
	}

	renderListItems(items) {
		let { sampleSet, selection } = this.state;

		return items.map((data, n) => {
			return this.renderListItem(data, n, sampleSet.customerVisible, data.statsId === selection);
		});
	}

	setInput(statsId, input) {
		this.inputs[statsId] = input;
		//console.log(statsId, input);
	}

	render() {
		const { stats } = this.state;
		let className = this.generateClassNames().join(' ');

		if (!stats.length) return <p className={className}>No Stats Available</p>;

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<ul className={className} ref={provided.innerRef} {...provided.droppableProps}>
							{this.renderListItems(stats)}

							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}