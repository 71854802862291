import history from '../history';
import { WebAuth } from 'auth0-js';
import { Logger, Log } from '../utils/Logger';
import { jwtDecode } from "jwt-decode";
import * as config from '../config/env.config';
import { hashString } from '../utils/StringUtils.js';
import React, { useCallback } from 'react';
import * as enums from '../enums/Enums';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../redux/reducers/auth.reducer';

const logger = new Logger();
//User Login Event
const logUserSession = (sessionEvent) => {

	let JWTToken = localStorage.getItem('surveyor_token');
	let emailHash = null;
	if (JWTToken) {
		let decodedToken = jwtDecode(JWTToken);
		emailHash = hashString(decodedToken.email);
	}
	let logViewChange = new Log();
	logViewChange.SetLevel(enums.AppInsightLogLevel.EVENT);
	logViewChange.SetName('User_Session');
	logViewChange.AddProperty('Type', sessionEvent);
	logViewChange.AddProperty('UserEmailHash', emailHash);
	logger.doLog(logViewChange);
};

const auth0 = new WebAuth({
	domain: config.AUTH_DOMAIN,
	clientID: config.AUTH_CLIENTID,
	redirectUri: config.AUTH_CALLBACKURL,
	audience: 'https://audit-system-api',
	responseType: 'token id_token',
	scope: 'openid email profile write:user write:user/action write:user/permission write:user/permission/project write:project/view write:project/download write:chemical/view write:chemical/download write:compound/view write:compound/download'
});

export const useAuth = () => {
	const dispatch = useDispatch();

	const setLocalReturnUrl = (returnUrl) => {
		if (returnUrl)
			localStorage.setItem("returnUrl", returnUrl);
	};

	const login = (username, password, callback, returnUrl) => {
		setLocalReturnUrl(returnUrl);
		auth0.login(
			{ realm: config.AUTH_DBCONNECTIONNAME, username, password },
			callback
		);
		localStorage.setItem("lastAuthAction", "login");
		logUserSession("login custom");
	};

	const reset = (email, callback) => {
		auth0.changePassword(
			{ realm: config.AUTH_DBCONNECTIONNAME, connection: config.AUTH_DBCONNECTIONNAME, email },
			callback
		);
		logUserSession("reset password");
	};

	const signup = (email, password, source, callback) => {
		auth0.signup({
			connection: config.AUTH_DBCONNECTIONNAME,
			email, password,
			user_metadata: { signupSource: source }
		}, callback);
		localStorage.setItem("signupAction", "create");
		logUserSession("signup");
	};

	const loginWithGoogle = (returnUrl) => {
		auth0.authorize({ connection: 'google-oauth2' });
		localStorage.setItem("lastAuthAction", "login");
		setLocalReturnUrl(returnUrl);
		logUserSession("login google");
	};

	const loginWithLinkedIn = (returnUrl) => {
		auth0.authorize({ connection: 'linkedin' });
		localStorage.setItem("lastAuthAction", "login");
		setLocalReturnUrl(returnUrl);
		logUserSession("login linkedin");
	};
	
	const loginWithMicrosoft = (returnUrl) => {
		auth0.authorize({ connection: 'windowslive' });
		localStorage.setItem("lastAuthAction", "login");
		setLocalReturnUrl(returnUrl);
		logUserSession("login windowslive");
	};

	const handleAuthentication = () => {
		auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				setSession(authResult);
				//history.push('/home');
			} else if (err) {
				// history.replace('/home');
				 
				console.error('AUTHENTICATION ERROR:', err);
				//alert(`Error: ${err.error}. Check the console for further details.`);
			}
		});
	};

	const setSession = useCallback((authResult) => {
		// Set the time that the access token will expire at
		let expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
		localStorage.setItem('id_audit', authResult.access_token);
		localStorage.setItem('id_token', authResult.access_token);
		localStorage.setItem('surveyor_token', authResult.id_token);
		localStorage.setItem('expires_at', expiresAt);
		dispatch(setIsAuthenticated(true));
	}, [dispatch]);

	const logout = () => {
		logUserSession("logout");
		// Clear access token and ID token from local storage
		clearStorage();
		// navigate to the home route
		history.replace('/home');
	};

	const clearStorage = useCallback(() => {
		//We need to leave the key 'activeWindows' in storage or else we get an exception
		let activeWindow = localStorage.getItem("activeWindows");
		localStorage.clear();
		localStorage.setItem("activeWindows", activeWindow);
		dispatch(setIsAuthenticated(false));
	}, [dispatch]);

	const isAuthenticated = () => {
		// Check whether the current time is past the 
		// access token's expiry time
		let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
		let isAuthenticated = new Date().getTime() < expiresAt;
		if (!isAuthenticated) {
			clearStorage();
		}
		return isAuthenticated;
	};

	return {
		login,
		signup,
		reset,
		loginWithGoogle,
		loginWithLinkedIn,
		loginWithMicrosoft,
		handleAuthentication,
		setSession,
		logout,
		isAuthenticated,
	};
};
