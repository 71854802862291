'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Theme from '../util/Theme.jsx';
import {Radio} from '../index.jsx';
import Label from './Label.jsx';


/**
 * A RadioGroup component for use in forms
 */
export class RadioGroup extends Component {

	static getCount() {
		return RadioGroup.count;
	}

	constructor(props) {
		super(props);
		this.state = {
			id: props.id || 'RadioGroup-'+RadioGroup.getCount(),
			value: props.value,
		};
		RadioGroup.count++;
	}

	onChange = (value) => {
		if(this.state.value === value) return;
		this.setState({value});
		if(this.props.onChange) this.props.onChange({target:{name:this.props.name, value}});
	}

	renderChildren() {
		return React.Children.map(this.props.children, (child) => {
			let {id, value, label, checked, children, ...childProps} = this.props;
			if(child.type === 'radio') {
				return <Radio {...child.props} {...childProps}  onChange={this.onChange} checked={this.state.value === child.props.value} id={this.state.id+'-'+child.props.value} />;
			}
			else return child;
		});
	}

	render() {
		const {size, disabled, name, required, label, className} = this.props;
		const labelProps = {size, disabled, name, required};

		return (
			<div className={`RadioGroup ${className}`} id={this.state.id}>
				{label && 
					<Label {...labelProps}>{label}</Label>
				}
				{this.renderChildren()}
			</div>
		);
	}
}

RadioGroup.count = 0;

RadioGroup.propTypes = {

	/**  value of selected option */
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]),

	/** The color theme of the RadioGroup */
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** RadioGroup font size */
	size: PropTypes.oneOf(['small', 'normal', 'large']),

	/**  RadioGroup id */
	id: PropTypes.string,

	/**  name attribute passed to Radio instances */
	name: PropTypes.string,

	/** Whether the RadioGroup is disabled */
	disabled: PropTypes.bool,

	/** Whether the input is required */
	required: PropTypes.bool,

	/**  RadioGroup label */
	label: PropTypes.string,

	/**  RadioGroup label color */
	labelColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),
};

RadioGroup.defaultProps = {
	color: 'primary',
	value: null,
	size: 'normal',
	labelColor: 'tertiary',
	disabled: false,
	required: false,
	styles: {},
};

export default RadioGroup;