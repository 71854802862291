/** Basic Theme Template obj */
class Theme {

	/** color management */
	static get colors() {
		return Theme._colors;
	}

	static get disabled() {
		return Theme._disabled;
	}

	static get text() {
		return Theme._text;
	}

	static get background() {
		return Theme._background;
	}

	static get colorList() {
		return Object.keys(Theme.colors);
	}

	static getColor(color) {
		return Theme.colors[color];
	}

	/** size management */
	static get sizes() {
		return Theme._sizes;
	}

	static get sizeList() {
		return Object.keys(Theme.sizes);
	}

	static getSize(size) {
		return Theme.sizes[size];
	}

	/** merges the newStyles into styles. Existing keys are pushed
		@param styles object that represents styles. Each key must represent an array
		@param newStyles
	 */
	static mergeStyles(styles, newStyles) {
		for(let rule in newStyles) {
			if(!(rule in styles))
				styles[rule] = [];
			Theme.mergeRules(styles[rule], newStyles[rule]);
		}
		return styles;
	}

	/** Pushes newRule into the rules array*/
	static mergeRules(rules, newRules) {
		rules.push(newRules);
		return rules;
	}

	static copy(base) {
		return JSON.parse(JSON.stringify(base));
	}
	
	//reduce/optimize this...
	static merge(base, merger) {
		let res = Theme.copy(base);
		if(!merger || merger === undefined)
			return res;

		for(let p in merger) {
			if(p in res) {
				if(typeof merger[p] === 'object') {
					if(typeof res[p] === 'object') {
						res[p] = Theme.merge(res[p], merger[p]);
					}
					else {
						res[p] = Theme.copy(merger[p]);
					}
				}
				else {
					res[p] = merger[p];
				}
			}
			else {
				res[p] = merger[p];
			}
		}
		return res;
	}
}

//color map
Theme._colors = {
	default: '#000000',
	primary: 'green',
	secondary: '#001D38',
	tertiary: '#001D38',
	label:'#808080',
	selectedItem: '#d3d3d3',
	danger: '#EA2E49',
	success: '#A9CF54',
	warning: '#F7E967',
	info: '#04BFBF',
	disabled: '#D3DFE7',
};

Theme._disabled = '#D3DFE7';
Theme._background = '#F9FAFB';
Theme._text = '#333333';

//size map
Theme._sizes = {
	small: '10px',
	normal: '14px',
	large: '22px',
	xlarge: '40px'
};

export default Theme;