import urljoin from 'url-join';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';

const DISCOVERY = "smartPanels";
const PROJECTS = "projects";

export const projectsApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		projects: builder.query({
			query: () => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: PROJECTS,
				method: 'GET',
			}),
			providesTags: ['projects']
		}),
		empty: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(PROJECTS, "empty", projectId),
				method: 'GET',
			})
		}),
		projectCode: builder.query({
			query: (projectCode) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(PROJECTS, "projectCode", projectCode),
				method: 'GET',
			})
		}),
		dpProjects: builder.query({
			query: (query) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin(DISCOVERY, query),
				method: 'GET',
			}),
			providesTags: ['projects']
		}),
	})
});

export const {
	useProjectsQuery,
	useEmptyQuery,
	useProjectCodeQuery,
	useDpProjectsQuery,
} = projectsApi;

export const adminProjectsApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		project: builder.query({
			query: (projectCode) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "projects", projectCode),
				method: 'GET',
			})
		}),
		saveProject: builder.mutation({
			query: (project, comment) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "projects", "save", project),
				method: 'PUT',
				body: { instance: project, comment }
			})
		}),
		publishStatus: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "projects", "publish", "status", projectId),
				method: 'GET',
			})
		}),
		stakeholders: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "projects", "stakeholders", projectId),
				method: 'GET',
			})
		}),
		projectSummary: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "projects", "summary", projectId),
				method: 'GET',
			})
		}),
	}),
});

export const {
	useProjectQuery, //projectsFetchData
	usePublishStatusQuery,
	useStakeholdersQuery,
	useSaveProjectMutation,
	useProjectSummaryQuery,
} = adminProjectsApi;