import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Head from "../components/containers/support/Head";
import Help from "../components/containers/support/Help";
import { Loader } from "../components/controls/Loader";
import { setActiveTab } from "../redux/reducers/discoveryPanels.reducer";
import { filterSelector } from "../redux/selectors/filter.selector";

function Support({ match }) {
  const dispatch = useDispatch();
  const [mounted, setMount] = useState(false);
  const { tabs } = useSelector(filterSelector);

  useEffect(() => {
    setMount(true);
    dispatch(setActiveTab(tabs[4].url));
    return () => setMount(false);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!mounted) {
    return <Loader />;
  }
  return (
    <div className="support fade-in">
      <section>
        <Head />
        <Help />
      </section>
    </div>
  );
}

export default Support;
