import { Box, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Icon } from 'metabux/components/Icon/Icon';
import { ChevronDown, ChevronUp } from 'lucide-react';

export const SubDropdownItem = ({ item, handleClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <MenuItem style={{ display: "flex", justifyContent: "space-between" }} key={item.key} value={item.value}>
        {item.value}
        {open ? <Icon icon={ChevronUp} /> : <Icon icon={ChevronDown} />}
      </MenuItem>
      {
        open &&
        <Box> {item.children?.map(i =>
          <MenuItem onClick={() => handleClick(i.value)} style={{ paddingLeft: 7.5 }} key={i.key} value={i.value}>{i.value}</MenuItem>
        )}
        </Box>
      }
    </Box>
  )
}

export default SubDropdownItem;
