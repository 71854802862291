import { createSelector } from '@reduxjs/toolkit';

export const selectCoe = createSelector(
    (state) => state,
    (state) => state.coe
);

export const selectCoeCurrent = createSelector(
	selectCoe,
	(state) => state.current
);

export const selectCoeisValid = createSelector(
	selectCoe,
	(state) => state.isValid
);

export const selectCoeTitle = createSelector(
	selectCoe,
	(state) => state.title
);

export const selectCoeOnboarding = createSelector(
	selectCoe,
	(state) => ({
		shipDate: state.newDate || state.shipDate,
		manifestUpload: state.manifestUpload,
		shipDateChangeReason: state.dateChangeReason
	})
);

export const selectCoeManifests = createSelector(
	selectCoe,
	(state) => state.manifests
);

export const selectCoeUpdatedManifests = createSelector(
	selectCoe,
	(state) => state.updatedManifests
);
