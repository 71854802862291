'use strict';
import React, { Component } from 'react';
import { Icon } from '../index.jsx';
import PropTypes from 'prop-types';
import '../../theme/theme.css';
import Theme from '../util/Theme.jsx';


export class ScrollList extends Component {

	static getCount() {
		return ScrollList.count;
	}
	constructor(props) {
		super(props);
		this.state = {
			id: props.id || 'ListItem-' + ScrollList.getCount(),
			color: props.color,
			showIcon: props.showIcon,
			options: props.options,
			onClick: props.onClick,
			children: props.children,
			hoverColor: props.hoverColor,
			deselectableItems: props.deselectableItems,
			altLabel: props.altLabel,
			//  listItems: props.options
		};

		this.deleteIcon = "";
		ScrollList.count++;
		this.ShowingDeleteIcon();
		let listItems = props.options;
	}

	getStyles() {

		return {
			backgroundColor: this.props.color,
			width: ScrollList.sizes[this.props.size],
			overflowY: 'auto',
			maxHeight: ScrollList.heights[this.props.height],
			paddingLeft: '10px',
			listItems: {
				width: '85%',
				display: 'inline-block',
				verticalAlign: 'middle',
				color: Theme.getColor(this.props.color),
			},
			deleteIcon: {
				display: 'inline-block',
				color: Theme.getColor('selectedItem'),
				verticalAlign: 'middle',
				paddingLeft: '15px',
				cursor: 'pointer',
				fontWeight: 'lighter',
			},
			outerContainer: {
				verticalAlign: 'middle',
				paddingLeft: '5px'
			}
		};
	}

	/** Methods */
	ShowingDeleteIcon(showIcon) {
		if (showIcon) {
			this.deleteIcon = <Icon fontWeight={'lighter'} icon={'times'} />;
		}
	}

	/** Bindings */
	handleClickBind = (id, option) => {
		return this.props.onClick.bind(this, [id, option]);
	}

	renderChildren(props) {
		const styles = this.getStyles();
		return React.Children.map(props.children, (child, index) => {
			const boundItemClick = this.handleClickBind(child.props.data.id, child.props.data);
			this.ShowingDeleteIcon(child.props.deselectableItems);

			return (
				<div key={index} style={styles.listItems} onClick={boundItemClick}  >
					<div style={styles.listItems}>
						{React.cloneElement(child)}
					</div>
					<div style={styles.deleteIcon} onClick={boundItemClick} >
						{this.deleteIcon}
					</div>
				</div>
			);
		});
	}

	render() {
		const styles = this.getStyles();
		return (
			<div className="ScrollList" style={styles} type="text">
				{this.renderChildren(this.props)}
			</div>
		);
	}
}

ScrollList.count = 0;

ScrollList.propTypes = {
	/** The color for the List items*/
	color: PropTypes.string,
	/** The size of the list items */
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	/**The Height of the Scrolllist */
	height: PropTypes.oneOf(['small', 'normal', 'large']),
	/** Show Leading Icon Type */
	showIcon: PropTypes.oneOf(['none', 'user']),
	/** Show Text Hover Color */
	hoverColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),
	/** Can deselect ListItem */
	deselectableItems: PropTypes.bool,
	/** Alternate First Label adds dark background*/
	altLabel: PropTypes.bool,
	/** callback invoked when item changes */
	onClick: PropTypes.func
};

ScrollList.defaultProps = {
	/**
	 * List Items label.
	 */
	color: 'tertiary',
	size: 'normal',
	height: 'normal',
	options: [{ shortName: "No Data Available" }],
	showIcon: 'none',
	hoverColor: 'primary',
	deselectableItems: false,
	altLabel: false,
	onClick: event => {
		 
		//	console.log('Select List Event not defined!', event);
	}
};

ScrollList.sizes = {
	small: '200px',
	normal: '300px',
	large: '400px',
};

ScrollList.heights = {
	small: '125px',
	normal: '200px',
	large: '300px',
};

export default ScrollList;
