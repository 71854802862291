import { createSelector } from '@reduxjs/toolkit';


export const idleSelector = createSelector(
    (state) => state,
    (state) => state.idle
);


export const idleStatusSelector = createSelector(
    idleSelector,
    (idle) => idle.idleStatus
);
