import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../components/buttons/Button.jsx";
import Icon from "../../../components/controls/Icon.jsx";
import * as styles from "./downloadSvg.module.scss";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { downloadStraightenText, downloadCurveText } from "../../redux/reducers/discoveryPanels.reducer";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger.js";
import { LegendSVG } from "../containers/LegendSVG.jsx";

const DownloadSVG = ({ id, name, textDisplay, projectId, location }) => {
  const logger = useLocalAuditLogger();
  const dispatch = useDispatch();
  const flattenText = () => dispatch(downloadStraightenText());
  const deFlattenText = () => dispatch(downloadCurveText());
  const { mobile } = useSelector(viewPortSelector);

  const download = useCallback(() => {
    logger.logUserAction(`download ${projectId} SVG`);
    const svg = document.getElementById(id);
    const initialBounding = svg.getBoundingClientRect();
    const fixedBounding = {
      width: location === "report" ? 1100 : 1080,
      height: location === "report" ? 1100 : 1200
    };
    svg.setAttribute("height", fixedBounding.height);
    svg.setAttribute("width", fixedBounding.width);
    svg.setAttribute("font-family", "Montserrat, Arial, Helvetica, sans-serif");

    const initialViewBox = svg.getAttribute("viewBox");

    if (textDisplay) {
      let text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      text.textContent = textDisplay;
      text.setAttribute("fill", "#00254d");
      text.setAttribute("text-anchor", "middle");
      text.setAttribute("font-size", "1.5em");
      text.setAttribute("font-weight", "bold");
      svg.appendChild(text);
      setTimeout(function () {
        svg.removeChild(text);
      }, 10);
    }

    const legendIds = {
      report: "legend-group",
      explorer: "legend-bottom-group"
    };

    const {width, height, x, y} = svg.viewBox.baseVal;
    const legendScale = Math.max(height / fixedBounding.height, width / fixedBounding.width);
    const scaledLegendWidth = 155 * legendScale;
    const scaledLegendHeight = 187 * legendScale;

    const legendSVG = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const legend = document.getElementById(legendIds[location]);
    const legendClone = legend.cloneNode(true);
    legendClone.setAttribute("transform", `scale(${legendScale})`);
    legendSVG.appendChild(legendClone);

    if(location === "report") {
      svg.setAttribute("viewBox", `${x} ${y} ${width} ${height + scaledLegendHeight}`);
      legendSVG.setAttribute("x", width - scaledLegendWidth - 5);
      legendSVG.setAttribute("y", height - scaledLegendHeight);
    } else if (location === "explorer") {
      svg.setAttribute("viewBox", `${x} ${y - scaledLegendHeight} ${width} ${height + scaledLegendHeight}`);
      legendSVG.setAttribute("x", -scaledLegendWidth / 2);
      legendSVG.setAttribute("y", y - height / 2 - scaledLegendHeight);
    }

    svg.appendChild(legendSVG);

    //straighten curved text to be visible in powerpoint
    flattenText();
    setTimeout(function () {
      svg.setAttribute("height", initialBounding.height);
      svg.setAttribute("width", initialBounding.width);
      svg.setAttribute("viewBox", initialViewBox);
      svg.removeChild(legendSVG);
      deFlattenText();
    }, 10);

    const svgDataURL = (svg) => {
      const svgAsXML = new XMLSerializer().serializeToString(svg);
      return "data:image/svg+xml," + encodeURIComponent(svgAsXML);
    };

    setTimeout(function () {
      const dataURL = svgDataURL(svg);
      const dl = document.createElement("a");
      document.body.appendChild(dl); //  work in Firefox.
      dl.setAttribute("href", dataURL);
      dl.setAttribute("download", name + ".svg");
      dl.click();
    }, 5);
  });

  return (
    <div className={styles["download-svg-wrapper"] + " download-base-button"}>
      <Button alt={true} color="primary" onClick={download}>
        <Icon
          className="no-pointer"
          icon="download"
          size={mobile ? "small" : "medium"}
        />
        <span className={styles["download-svg-label"] + " no-pointer"}>
          SVG
        </span>
      </Button>
      <LegendSVG />
    </div>
  );
};

DownloadSVG.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  textDisplay: PropTypes.string,
};

DownloadSVG.defaultProps = {
  id: "",
  name: "SVG",
  textDisplay: undefined,
};

export default DownloadSVG;
