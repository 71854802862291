import { now } from './TimeUtils';
import dayJs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayJs.extend(utc);

export function convertDate(value, format) {
	if (!value) return null;
	let date = new Date(value);
	return dayjs(date).format(format);
}

export const dayjs = () => dayJs(now());