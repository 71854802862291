import {scaleRadial, lineRadial, curveBasis }  from "d3";

export default function  createLinePath(d, type, settings){
  const curviness = 0.03; //the higher the number the more smooth the line
  const startDistance = 3; //higher it is, farther away from start point
  const fullCircle = 2 * Math.PI;

  let layerScale = scaleRadial()
    .range([settings.associationRadius / settings.radiusOffset, settings.metabolitesRadius / settings.radiusOffset]);
   

  let cw_layer = startDistance + d.layer,
    ccw_layer = startDistance + d.layer;

    let start =
        (type === "metabolite"
          ? d.coords_metabolite.angle
          : d.coords_association.angle) + settings.rotateOffset,
      end =
        (type === "metabolite"
          ? d.coords_association.angle
          : d.coords_metabolite.angle) + settings.rotateOffset,
      line_data = [];

    const direction =
      type === "metabolite"
        ? d.direction === 1
          ? "cw"
          : "ccw"
        : -d.direction === 1
        ? "cw"
        : "ccw";

    const layer_length =
      direction === "cw" ? d.direction_totals[0] : d.direction_totals[1];
    const endLayer = layer_length + startDistance * 2 - 1;
    
    layerScale.domain([0, endLayer]);

    line_data.push({
      x: start,
      y: type === "metabolite" ? endLayer : 0,
    });

    if (direction === "cw" && start < end) {
      for (let i = start; i < end; i = i + curviness) {
        line_data.push({ x: i, y: cw_layer });
      }
      line_data.push({
        x: end,
        y: cw_layer,
      });
      cw_layer = cw_layer + 1;
    } else if (direction === "cw" && start > end) {
      for (let i = start; i < fullCircle; i = i + curviness) {
        line_data.push({ x: i, y: cw_layer });
      }
      for (let i = 0; i < end; i = i + curviness) {
        line_data.push({ x: i, y: cw_layer });
      }
      line_data.push({
        x: end,
        y: cw_layer,
      });
      cw_layer = cw_layer + 1;
    } else if (direction === "ccw" && start < end) {
      for (let i = start; i > 0; i = i - curviness) {
        line_data.push({ x: i, y: ccw_layer });
      }
      for (let i = fullCircle; i > end; i = i - curviness) {
        line_data.push({ x: i, y: ccw_layer });
      }
      line_data.push({
        x: end,
        y: ccw_layer,
      });
      ccw_layer = ccw_layer + 1;
    } else if (direction === "ccw" && start > end) {
      for (let i = start; i > end; i = i - curviness) {
        line_data.push({ x: i, y: ccw_layer });
      }
      line_data.push({
        x: end,
        y: ccw_layer,
      });
      ccw_layer = ccw_layer + 1;
    }


    line_data.push({
      x: end,
      y: type === "metabolite" ? 0 : endLayer,
    });


    const line = lineRadial()
      .angle(function (d) {
        return d.x;
      })
      .radius(function (d) {
        return layerScale(d.y);
      })
      .curve(curveBasis);



    return line(line_data);
  }