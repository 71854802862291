"use strict";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import headerlogo from "../../theme/svg/MyMetabolon-color.svg";
import "./LandingHeader.scss";
import {
  Link,
  LoginButton,
} from "../../components/index.jsx";

export class LandingHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { history, match } = this.props;
    return (
      <div className="LandingHeader">
        <Link className="logo-container alt2" to="home">
          <img src={headerlogo} />
        </Link>
        <div className="nav">
          {/* <NavBar history={history} size="normal">
						<NavButton className="text-primary no-bars" to={"resources"} >Features</NavButton>
						<NavButton className="text-primary no-bars" to={"resources"} >Use Cases</NavButton>
						<NavButton className="text-primary no-bars" to={"resources"} >Resources</NavButton>
					</NavBar> */}
          <LoginButton history={this.props.history} auth={this.props.auth} />

          {/* <Link to="/login" history={history} size="normal" className="login" alt={true} color="primary">
						Login&nbsp;<Material icon={'person_outline'} color={'primary'}/>
					</Link> */}
        </div>
      </div>
    );
  }
}

LandingHeader.propTypes = {};

LandingHeader.defaultProps = {};

export default withRouter(LandingHeader);
