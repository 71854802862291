import { createSlice } from '@reduxjs/toolkit';


export const getInitialState = () => [];


export const formSlice = createSlice({
    name: 'form',
    initialState: getInitialState(),
    reducers: {
        submitForm: (state, { payload }) => {
            state.unshift(payload);
        }
    }
});


export const formReducer = formSlice.reducer;
