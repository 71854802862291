import React, { useCallback, useEffect, useState } from "react";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { projectsDataSelector, projectsIsLoadingSelector } from "@/redux/selectors/projects.selector";
import { projectsFetchData } from "@/redux/actions/projects";
import { Gray, Blue } from '@/lenses/uilenses/theme/colors';
import dayjs from 'dayjs';
import Link from "@/lenses/uilenses/components/Link/Link";
import { Button } from "@/lenses/uilenses/components/Button/Button";
import { SpectralDataModal } from "@/views/spectralData/components/SpectralDataModal";
import { useFetchSpectralMetaDataQuery } from "@/redux/services/spectralData.api";

export const SpectralData = ({ projectId }) => {

	const { data: spectralData, isLoading: spectralIsLoading } = useFetchSpectralMetaDataQuery(projectId);
	const projects = useSelector(projectsDataSelector);
	const isLoading = useSelector(projectsIsLoadingSelector) || spectralIsLoading;

	const dispatch = useDispatch();
	const expDate = dayjs(spectralData?.uploadDate).add(1, 'year'); // replace with actual expiration date
	const now = dayjs();
	const daysLeft = expDate.diff(now, 'day');
	const expired = daysLeft < 0;

	const [resourcesModalOpen, setResourcesModalOpen] = useState(false);

	useEffect(() => {
		if (projects?.length === 0 && !isLoading)
			dispatch(projectsFetchData());
	}, [projects]);

	const onSpectralClick = useCallback(() => {
		setResourcesModalOpen(true);
	});

	return (
		<Box className="ProjectHome" data-testid={"spectral-data"}>
			<Stack sx={{ background: Gray[100], p: 3 }} spacing={3}>
				<Box
					sx={{ borderRadius: 3, padding: 4, background: "white" }}
				>
					<Typography variant="h5" sx={{ fontWeight: 600, color: Gray[800], mb: 1 }}>Spectral Data</Typography>

					<Link mb={4} href="https://www.metabolon.com/why-metabolon/metabolomics-raw-spectral-data/" color="primary.dark" external={true} target="_blank" size="normal">Spectral Data FAQ</Link>

					{!expired ? (
						<>
							<Typography sx={{ mb: 2 }}>Click the button below to retrieve your spectral data</Typography>
							<Typography sx={{ mb: 3 }}>
								<Link onClick={onSpectralClick} component={Button} external={true} target="_blank" size="normal" color="white" bgcolor={Gray[900]}>Retrieve Spectral Data</Link>
							</Typography>
							<Typography>You have {daysLeft} days until {expDate.format('MMMM Do, YYYY')}, when your data is moved to cold storage. After that, reach out to <MuiLink
								display={'inline !important'}
								href="mailto: clientsuccess@metabolon.com"
								variant="caption"
								underline="none"
								color={Gray[500]}
								fontWeight={600}
							>
								clientsuccess@metabolon.com
							</MuiLink> to retrieve your data.</Typography>
						</>
					) : (
						<Typography>Your data has been moved to cold storage. Reach out to <MuiLink
							display={'inline !important'}
							href="mailto: clientsuccess@metabolon.com"
							underline="none"
							color={Blue[500]}
							fontWeight={600}
						>clientsuccess@metabolon.com
						</MuiLink> to retrieve your data.</Typography>)}
				</Box>
			</Stack>
			<SpectralDataModal
				projectId={projectId}
				open={resourcesModalOpen}
				setOpen={setResourcesModalOpen} />
		</Box>
	);
};