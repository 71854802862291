import React from "react";

export const LegendSVG = () => {
  return (
    <svg width="155" height="187" id="legend-svg" display="none">
      <g id="legend-group">
        <g id="legend-top-group">
          <circle cx="70" cy="50" r="30" stroke="#7a8fa7" fill="transparent" fillOpacity="0" />
          <path stroke="#7a8fa7" d="M70 20L105 20" />
          <text y="4" fill="#00254d" fontSize="10">
            <tspan x="110" dy="1.2em">
              P-Value
            </tspan>
            <tspan x="110" dy="1.2em">
              &lt;.001
            </tspan>
          </text>
          <circle
            cx="70"
            cy="62.5"
            r="17.5"
            stroke="#7a8fa7"
            fill="transparent"
            fillOpacity="0"
          />
          <path stroke="#7a8fa7" d="M70 45L113 45" />
          <text y="32" fill="#00254d" fontSize="10">
            <tspan x="120" dy="1.2em">
              P-Value
            </tspan>
            <tspan x="120" dy="1.2em">
              &gt;.05
            </tspan>
          </text>
          <circle cx="70" cy="67" r="1" stroke="#7a8fa7" />
          <path stroke="#7a8fa7" d="M38 67L70 67" />
          <text y="45" fill="#00254d" fontSize="10">
            <tspan x="6" dy="1.2em">
              Anova
            </tspan>
            <tspan x="6" dy="1.2em">
              main
            </tspan>
            <tspan x="6" dy="1.2em">
              effect
            </tspan>
          </text>
          <text x="30" y="100" fill="#00254d" fontSize="10">
            Sized by significance
          </text>
        </g>
        <g id="legend-bottom-group">
          <path fill="#3994EF" d="M0 115H20V135H0z" />
          <path fill="#75B3F1" d="M20 115H40V135H20z" />
          <path fill="#B1D2F4" d="M40 115H60V135H40z" />
          <path fill="#EFF2F6" d="M60 115H80V135H60z" />
          <path fill="#F2D7DC" d="M80 115H100V135H80z" />
          <path fill="#F4BCC2" d="M100 115H120V135H100z" />
          <path fill="#FF4F58" d="M120 115H140V135H120z" />
          <text x="7.5" y="150" fill="#00254d" fontSize="10px">
            0
          </text>
          <text x="67.5" y="150" fill="#00254d" fontSize="10">
            1
          </text>
          <text x="127.5" y="150" fill="#00254d" fontSize="10">
            3
          </text>
          <text y="160" fill="#00254d" fontSize="10">
            <tspan x="16" dy="1.2em">
              Colored by fold change
            </tspan>
            <tspan x="2" dy="1.2em">
              when below p-value threshold
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
