import React, { useMemo, useCallback, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as styles from "./metaboliteprofile.module.scss";
import { dataSelector } from "../../../redux/selectors/data.selector";
import Table from "../../table/Table";
import Icon from "../../../../components/controls/Icon.jsx";
import ExternalLink from "../../../../components/buttons/ExternalLink.jsx";
import Link from "../../../../components/buttons/Link.jsx";
import Stats from "./Stats";
import { filterSelector } from "../../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import { discoveryPanelsSelector } from "../../../redux/selectors/discoveryPanels.selector";
import {
  interactionsUpdateSelection,
  setFilterAssociation,
} from "../../../redux/reducers/discoveryPanels.reducer";
import urljoin from "url-join";
import { DiscoveryPanelMap, BIOSTRESS } from "@/enums/Enums";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";

const learnMoreButtons = ["PubChem", "KEGG", "HMDB"];

const columns = [
  {
    key: "association",
    value: "Association",
    width: 50,
    align: "left",
    click: "association",
  },
  {
    key: "association_table_significant",
    value: "% Sig",
    width: 15,
    align: "right",
  },
  {
    key: "association_table_upregulated",
    value: "% Up",
    width: 15,
    align: "right",
  },
  {
    key: "association_table_downregulated",
    value: "% Down",
    width: 15,
    align: "right",
  },
];

const MetaboliteProfile = ({ match }) => {
  const dispatch = useDispatch();
  const selectedState = useSelector(interactionsSelector);
  const { productType } = useSelector(discoveryPanelsSelector);
  const { metabolites, associations, results, associationsByMetabolite } = useSelector(dataSelector);
  const { tabs } = useSelector(filterSelector);
  const productTypeDisplay = useMemo(
    () => DiscoveryPanelMap.ui[productType],
    [productType]
  );
  const pathwayKey = useMemo(
    () => DiscoveryPanelMap.pathway[productType],
    [productType]
  );
  const productTablePathway = !productTypeDisplay
    ? "Pathway"
    : `${productTypeDisplay} Pathway`;

  const { smallScreen } = useSelector(viewPortSelector);
	const { pathways } = useSelector(dataSelector);

  const metaboliteInfo = useMemo(() => {
    return metabolites.filter((d) => d.chemical_id === selectedState.id)[0];
  }, [selectedState, metabolites]);

  const associationsList = useMemo(() => {
    return associationsByMetabolite[selectedState.id];
  }, [selectedState, associationsByMetabolite]);

  const resultsData = useMemo(() => {
    return results.filter((d) => d.chemical_id === selectedState.id);
  });

  const onClick = useCallback(
    (type) => () => {
      dispatch(setFilterAssociation(type));
    },
    []
  );

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const logger = useLocalAuditLogger();
  const goToPathwayProfile = useCallback(() => {
    dispatch(
      interactionsUpdateSelection({
        id: metaboliteInfo.pathway_name,
        display: metaboliteInfo.pathway_name,
        type: "pathway",
        subtype: null,
        method: "click",
        source: "metaboliteprofile",
        sourceSpecific: null,
      })
    );
    logger.logUserAction(`user clicked on Pathway Explorer`);
  }, [metaboliteInfo, dispatch]);

  //TODO: add pubchem, kegg, and hmdb into metabolites data so urls work
  const getUrl = useCallback(
    (type) => {
      let url = null;
      switch (type.toLocaleLowerCase()) {
        case "pubchem":
          url =
            "https://pubchem.ncbi.nlm.nih.gov/compound/" +
            metaboliteInfo.PUBCHEM;
          break;
        case "kegg":
          url =
            "https://www.genome.jp/dbget-bin/www_bget?cpd/" +
            metaboliteInfo.KEGG;
          break;
        case "hmdb":
          url = "https://hmdb.ca/metabolites/" + metaboliteInfo.HMDB;
          break;
      }
      return url;
    },
    [metaboliteInfo]
  );
	
  const globalSubPathways = useMemo(() => {
		const subPathways = [];
    pathways.filter(
      (d) => d.pathway_name === metaboliteInfo.microbiome_super_pathway
    ).forEach(
			d => subPathways.push(...d[pathwayKey])
		);
		return subPathways;
  }, [metaboliteInfo.microbiome_super_pathway, pathways, pathwayKey]);

  const associationTableData = useMemo(() => {
    const filteredAssociations = associations.filter(
      (d) => d.chemical_id === selectedState.id
    );
    return [
      {
        label: "Biological Themes",
        data: filteredAssociations.filter((d) => d.type === "Biological"),
        action: onClick("Biological"),
      },
      {
        label: "Disease & Disorders",
        data: filteredAssociations.filter((d) => d.type === "Disease"),
        action: onClick("Disease"),
      },
      {
        label: "Pathways",
        data: filteredAssociations.filter((d) => d.type === "Pathway"),
        action: onClick("Pathway"),
      },
    ];
  }, [selectedState.id, associations]);

  const buttonsList = [
    {
      label: "View Pathway Profile",
      target: tabs[2].url,
      click: goToPathwayProfile,
    },
    {
      label: "View in Impact Explorer",
      target: tabs[1].url,
      click: () => {
        logger.logUserAction(`user clicked on Impact Explorer`);
      },
    },
  ];

  const visibleButtons = metaboliteInfo.pathway_name
    ? buttonsList
    : [buttonsList[1]];

  const metaboliteDetails = (
    <div className={styles.top}>
      <div className={styles.infoWrapper}>
        <h3>{`${productTypeDisplay} Pathway`}</h3>
        <div className={styles.infoGroup}>
          {metaboliteInfo.microbiome_super_pathway && (
            <>
              <h5>{`${productTypeDisplay} Super Pathway`}</h5>
              {metaboliteInfo.microbiome_super_pathway}
            </>
          )}
        </div>
        <div className={styles.infoGroup}>
          <h5>{`${productTypeDisplay} Sub Pathway`}</h5>
          {metaboliteInfo.microbiome_pathway}
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <h3>Associations</h3>
        <div className={styles.infoGroup}>
          {associationsList.map( (a, i) => <p key={a+i}>{a}</p>)}
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <h3>Learn More</h3>
        {learnMoreButtons.map((d, i) => {
          return (
            <div key={i}>
              <ExternalLink
                color="white"
                href={getUrl(d)}
                className="transparent-link-button slide"
              >
                <div
                  className="flex"
                  style={{
                    marginBottom: "0.75rem",
                  }}
                >
                  <p className="body">{"Read more on " + d}</p>
                  <Icon icon="angle-right" size="large" color="default" />
                </div>
              </ExternalLink>
            </div>
          );
        })}
      </div>
    </div>
  );
  return (
    <div className={styles.metaboliteProfile + " fade-in"}>
      <div className={styles.copy}>
        <h2>{metaboliteInfo.metabolite}</h2>
        {metaboliteInfo.SMILES && <p>{metaboliteInfo.SMILES}</p>}
      </div>

      <div className={styles.buttonWrapper}>
        {visibleButtons.map((d, i) => {
          return (
            <Link
              color="white"
              onClick={d.click}
              to={d.target}
              className="transparent-link-button slide"
              key={i}
            >
              <div
                className="flex"
                style={{
                  justifyContent: smallScreen ? "flex-start" : "flex-end",
                  marginBottom: "0.5rem",
                }}
              >
                <p className="body">{d.label}</p>
                <Icon icon="angle-right" size="large" color="default" />
              </div>
            </Link>
          );
        })}
      </div>
      
      {metaboliteDetails}

      {resultsData.length > 0 && <Stats />}
      {resultsData[0]?.detected && (
        <div>
          <Table
            tableData={associationTableData}
            defaultSort={"association_table_significant"}
            columns={columns}
            tableType={"association"}
            flow="row"
            showLabel={true}
            showButton={true}
            buttonLabel="View in Impact Explorer"
            tableSwitchTabTo={urljoin(match.url, tabs[1].url)}
            match={match}
          />
        </div>
      )}
    </div>
  );
};

export default MetaboliteProfile;
