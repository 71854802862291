'use strict';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import { isRejected } from "@reduxjs/toolkit";
import { rootReducer } from '../redux/reducers';
import * as config from '../config/env.config';
import api from '../middleware/storeApi';
import { rootSagas } from '../redux/sagas';
import { biovizApi } from '@/redux/services/bioviz.api';
import { portalApi } from '@/redux/services/index.api';

const sagaMiddleWare = createSagaMiddleware();

const preloadedState = {
	configuration: config
};

export const queryErrorHandler =
	() => (next) => (action) => {
		if (isRejected(action)) {
			if (action.payload?.status === 401) {
				history.pushState({}, "", '/login');
				history.go();
			}
		}
		return next(action);
	};

const middleware = [portalApi.middleware, biovizApi.middleware, api, sagaMiddleWare, queryErrorHandler];

export const createAppStore = (initialState = preloadedState) => {
	let store = null;
	/**For our dev environment, add redux-logger so we can more easily debug */
	/* istanbul ignore next */
	if (process.env.NODE_ENV === `development` && config.CONSOLELOG_REDUXACTIONS) {
		store = configureStore({
			reducer: rootReducer,
			preloadedState: initialState,
			middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
		});
	}
	else {
		store = configureStore({
			reducer: rootReducer,
			preloadedState: initialState,
			middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
		});
	}
	return store;
};
const store = createAppStore();
sagaMiddleWare.run(rootSagas);

export default store;