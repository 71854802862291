import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Icon } from "metabux/components/Icon/Icon";
import Badge from "metabux/components/Badge/Badge";
import { Gray } from "metabux/theme/colors";
import { NavMenu } from "./NavMenu";
import Link from "metabux/components/Link/Link";

export const DefaultNavItemRenderer = ({
  icon,
  label,
  alerts,
  href,
  target = "_blank",
  exact,
  willRender = true,
  external = false,
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  if (!willRender) return null;
  if (props.children) return <NavMenu icon={icon} label={label} href={href} target={target} {...props} />

  return (
      <Box sx={{padding:1}}>
        <Typography
          color='inherit'
          underline='none'
          fontWeight={600}
          fontSize={14}
          title={label}
          sx={{
            display: "flex",
            gap: 3,
            width: isDesktop ? "100%" : "auto",
            "&:hover": {
              color: "white",
            },
          }}
        >
          {icon && <Icon color='inherit' icon={icon} size={24} />}
          {isDesktop && label}
          {alerts && isDesktop && (
            <Badge content={alerts} sx={{ marginLeft: "auto", marginRight: 8 }} />
          )}
        </Typography>
      </Box>
  );
};
