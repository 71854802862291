import React, { Component } from 'react';
import './Permissions.scss';

export class Permissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorState: false
		};
	}

	backTo = () => {
		// this.props.history.push("/home");
		this.props.history.go(-4);
	};

	render() {
		return (
			<div className="PermissionContainer">

				<div>
					<div className="access-denied" />
					<div className="access">Access Denied</div>
					<div>
						Sorry, but you don't have permission to access this page.
					</div>
					<div>
						<b>Questions?</b> Contact
						<a href={`mailto:support@metabolon.com?subject=Project Access Permissions`}>support</a>
						or return to the
						<a data-testid="permissions-back" onClick={this.backTo}>previous page.</a></div>
				</div>

			</div>
		);
	}
}


export default Permissions;