'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './Label.scss';

/**
 * A label component for use in forms in conjunction with other form elements
 */
const Label = ({ color, size, children, alt, htmlFor, required, disabled, hoverColor, className, title, showTitle }) => {

	let generateClassNames = (base) => {
		let classNames = [color, size];
		classNames.unshift(base);
		if(className) classNames.unshift(className);
		if(alt) classNames.push('alt');
		if(hoverColor) classNames.push('hover-'+hoverColor);
		if(disabled) classNames.push('disabled');
		if(required) classNames.push('required');

		return classNames;
	};

	let extraProps = {};
	if(htmlFor.length) extraProps.htmlFor = htmlFor;

	let titleText = title || children;
	if(!showTitle){titleText ="";}

	return (
		<label title={titleText} className={generateClassNames('Label').join(' ')} {...extraProps} >
			{children}
		</label>
	);
};

Label.propTypes = {
	/** Label text color */
	color: PropTypes.oneOf(['', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive', 'white']),

	/** Label font size */
	size: PropTypes.oneOf(['inherit', 'small', 'normal', 'medium', 'large', 'xlarge']),

	/**  Label's for attribute */
	htmlFor: PropTypes.string,

	/** Whether the target field is required */
	required: PropTypes.bool,

	/** Whether the target field is disabled */
	disabled: PropTypes.bool,
	/** Whether to populate Title */
	showTitle: PropTypes.bool
};

Label.defaultProps = {
	color: 'tertiary',
	htmlFor: '',
	size: 'normal',
	alt: false,
	disabled: false,
	required: false,
	showTitle: true
};

export default Label;