import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Circle from "./Circle";
import CurvedLabel from "./CurvedLabel";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { getSVGLetterCoords } from "@/discovery-panel/utils/getSVGLetterCoords";
import { downloadSelector } from "@/discovery-panel/redux/selectors/download.selector";

const labelCircle = (data) => {
  let length = 1;
  if (data.depth === 1) {
    length = data.data?.key?.length || 1;
  }
  const radius = data.r;
  return data.depth === 1 && (radius > 40 || radius / length > 4);
};

const CirclePack = ({
  size,
  circlePackData,
  id,
  packSize,
  handleEvents,
  selectedCircle,
  selectedMethod,
  selectedGroup,
}) => {
  const { smallScreen, mobile } = useSelector(viewPortSelector);
  const { group, comparison } = useSelector(filterSelector);
  const { isDownloading } = useSelector(downloadSelector);
  const anovaRadius = smallScreen ? 1 : 2;

  const cleanId = id.toLowerCase().replace(" ", "-");

  return (
    <svg
      id={cleanId + "-svg"}
      viewBox={[0, 0, size, size]}
      style={{
        overflow: "visible",
        height: packSize,
      }}
    >
      {circlePackData.length > 0 &&
        circlePackData.map((d, index) => {
          const value = d.data.chemical_id || d.data.key;
          const coordinates = getSVGLetterCoords(
            d.x,
            d.y,
            d.r + 5,
            d.data.key,
            9
          );
          return (
            <g key={index}>
              {labelCircle(d) && !isDownloading ? (
                <CurvedLabel
                  id={"explainer-curved-path-" + id.replace(/\s/g, "") + index}
                  text={d.data.key}
                  outerRadius={d.r + 5}
                  fontWeight="600"
                  opacity={
                    selectedCircle === "default" ||
                    selectedMethod === "hover" ||
                    (selectedMethod === "click" && selectedGroup == d.data.key)
                      ? 1
                      : 0.35
                  }
                  x={d.x}
                  y={d.y}
                />
              ) : (
                <g key={index}>
                  {coordinates?.map((coord, i) => {
                    if(!labelCircle(d)) return;
                    return (
                      <text
                        x={coord?.x}
                        y={coord?.y}
                        key={i}
                        className="curved-text-circle-pack"
                        fontSize="12px"
                        fontWeight="500"
                        transform={`rotate(${coord.rotation} ${coord?.x} ${coord?.y})`}
                        fontFamily="sans-serif"
                      >
                        {coord.character.toUpperCase()}
                      </text>
                    );
                  })}
                </g>
              )}

              <Circle
                radius={d.r}
                cx={d.x}
                cy={d.y}
                fill={d.color}
                strokeWidth={selectedCircle === value ? 5 : d.strokeWidth}
                opacity={
                  selectedMethod === "hover"
                    ? 1
                    : (selectedCircle === value &&
                        selectedMethod === "click") ||
                      selectedCircle === "default"
                    ? 1
                    : 0.3
                }
                handleEvents={handleEvents}
                name={d.data.metabolite}
                value={value}
                depth={d.depth}
                comparison={comparison}
                detected={d.detected}
                pathway_name={
                  d.depth === 2 ? d.data.metaboliteInfo.pathway_name : null
                }
                group={d.data[group]}
                section={id}
              />

              {d.depth === 2 && d.data.mainEffect.significant && (
                <Circle
                  radius={anovaRadius}
                  cx={d.x}
                  cy={d.y}
                  pointerEvents={"none"}
                  opacity={
                    selectedMethod === "hover"
                      ? 1
                      : (selectedCircle === value &&
                          selectedMethod === "click") ||
                        selectedCircle === "default"
                      ? 1
                      : 0.1
                  }
                />
              )}
            </g>
          );
        })}
    </svg>
  );
};

CirclePack.propTypes = {
  size: PropTypes.number,
  circlePackData: PropTypes.array,
  id: PropTypes.string,
  packSize: PropTypes.number,
  handleEvents: PropTypes.func,
  selectedCircle: PropTypes.string,
  selectedMethod: PropTypes.string,
  selectedGroup: PropTypes.string,
};

CirclePack.defaultProps = {
  size: 0,
  circlePackData: [],
  id: "",
  packSize: 0,
  handleEvents: undefined,
  selectedCircle: "",
  selectedMethod: "",
  selectedGroup: "",
};

export default memo(CirclePack);
