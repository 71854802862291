import { Modal as MUIModal, Box, Divider, Typography } from '@mui/material'
import React from 'react'
import IconButton from '../IconButton/IconButton'
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import MenuIcon from '@mui/icons-material/Menu';

export default function Modal({ open, onClose, children, title, subtitle, onMenuClick, favorite, onFavoriteClick }) {
  return (
    <MUIModal open={open} onClose={onClose} >
      <Box className="modal-box blue">
        <Box className="modal-header">
          <Box>
            <Typography variant='h3'>{title}</Typography>
            <Typography sx={{ marginTop: "10px" }} variant='body2'>{subtitle}</Typography>
          </Box>
          <Box className="modal-header-icons">
            {onMenuClick &&
              <IconButton size="large" icon={MenuIcon} onClick={onMenuClick} />
            }
            {onFavoriteClick &&
              <IconButton size="large" icon={StarIcon} color={favorite ? "#FFC554" : "transparent"} fill={favorite ? "#FFC554" : "transparent"} onClick={onFavoriteClick} />
            }
            {(onMenuClick || onFavoriteClick) && <Divider orientation='vertical' />}
            <IconButton size={"large"} icon={CloseIcon} onClick={onClose} />
          </Box>
        </Box>
        <Box className="modal-content">
          {children}
        </Box>
      </Box>
    </MUIModal>
  )
}