'use strict';
import React, {Component} from 'react';

import './ScrollCalculator.scss';

/**
 * Calculates scroll bar dimensions
 */
export default class ScrollCalculator extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {			
		ScrollCalculator.scrollW = Math.abs(this.tester0.clientWidth - this.tester1.clientWidth);
	}

	static scrollW = -1;

	setTest0 = (el) => {
		this.tester0 = el;
	};

	setTest1 = (el) => {
		this.tester1 = el;
	};

	render() {
		return (
			<div className="ScrollCalculator">
				<div ref={this.setTest0} key={0} className="tester-0" data-testid="tester-0"/>
				<div ref={this.setTest1} key={1} className="tester-1" data-testid="tester-1"/>
			</div>
		);
	}
}