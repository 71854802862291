'use strict';
import React, { Component } from 'react';
import './LoginButton.scss';
import { Link, Material } from '../../components/index.jsx';

export class LoginButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthenticated: props.auth.isAuthenticated()
		};
	}

	/**Click Handlers */
	login = () => () => {
		this.props.history.push("/login");
	}

	goHome = () => () => {
		this.props.history.push("/home");
	}

	render() {
		if (this.state.isAuthenticated) {
			return (
				<Link history={this.props.history} size="normal" className="login" alt={true} color="primary" onClick={this.goHome()}>
					Home&nbsp;<Material icon={'person_outline'} color={'primary'} />
				</Link>
			);

		}
		else {
			return (
				<Link history={this.props.history} size="normal" className="login" alt={true} color="primary" onClick={this.login()}>
					Sign In&nbsp;<Material icon={'person_outline'} color={'primary'} />
				</Link>
			);
		}
	}
}

export default LoginButton;