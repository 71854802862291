'use strict';
import { combineReducers } from 'redux';
import { configuration } from './configuration';
import { discoveryPanels } from '../../discovery-panel/redux/reducers/discoveryPanels.reducer';

import { filesReducer } from './files.reducer';
import { formReducer } from './form.reducer';
import { enrichmentFilterReducer } from './enrichmentFilter.reducer';
import currentUser from './currentUser.reducer';
import { managedUsersReducer } from './managedUsers.reducer';
import { availableRolesReducer } from './availableRoles.reducer';
import { managedProjectsReducer } from './managedProjects.reducer';
import { sampleSetDataReducer } from './sampleSetData.reducer';
import { searchResultsReducer } from './searchResults.reducer';
import authReducer from './auth.reducer';
import { publishSummaryReducer, projectsReducer } from './projects.reducer';
import { coeReducer } from '@/views/clientOnboarding/redux/reducers/coe.reducer';
import { portalApi } from '../services/index.api';
import { biovizApi } from '../services/bioviz.api';

export const rootReducer = combineReducers({
		auth: authReducer,
    availableRoles: availableRolesReducer,
    configuration,
		enrichmentFilter: enrichmentFilterReducer,
    files: filesReducer,
    form: formReducer,
    currentUser,
    managedUsers: managedUsersReducer,
    managedProjects: managedProjectsReducer,
    projects: projectsReducer,
    publishSummary: publishSummaryReducer,
    sampleSetData: sampleSetDataReducer,
    searchResults: searchResultsReducer,
    discoveryPanels,
		coe: coeReducer,
    [portalApi.reducerPath]: portalApi.reducer,
		[biovizApi.reducerPath]: biovizApi.reducer,
});
