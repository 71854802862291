'use strict';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Button.scss';

const RouterLink = ({
	children, target, type, to,
	...props
}) => {

	const logger = useLocalAuditLogger();
	let history = useHistory();

	const onClick = useCallback(
		(e) => {
			logger.logUserAction(`user navigated to ${to}`);
			if (props.onClick)
				props.onClick();
			history.push(to);
		},
		[to, props]
	);

	const generateClassNames = useCallback(
		(base="Link") => {
			const {color, size, disabled, alt, alt2, className} = props;
			let classNames = [color, size];
			classNames.unshift(base);
			if(className) classNames.unshift(className);
			if(alt) classNames.push('alt');
			if(alt2) classNames.push('alt2');
			if(disabled) classNames.push('disabled');

			return classNames;
		},
		[props]
	);

	return (
		<Link to={to} target={target} className={generateClassNames()} type={type} onClick={onClick}>
			{children}
		</Link>
	);
};

export default RouterLink;