import React, { useCallback, useEffect, useState } from 'react';
import { useManagedProjectsQuery, useUserQuery, usePublishUserMutation } from '@/redux/services/admin.api';
import { Button, Hollaback, Label, Icon } from '@/components/index.jsx';
import SearchContainer from '@/containers/SearchContainer.jsx';
import './ManageProjectPermissions.scss';
import '@/theme/theme.css';
import { LoadingSpinner } from '@/lenses/uilenses/components/LoadingSpinner/LoadingSpinner';

export const ManageProjectPermissions = (props) => {
	const [publishUser, { isLoading: userIsPublising }] = usePublishUserMutation();
	const { data: user, isLoading: userIsLoading } = useUserQuery(props.user?.email, { skip: !props.user?.email });
	const { data: currentProjects } = useManagedProjectsQuery();
	const [managedProjects, setManagedProjects] = useState([currentProjects]);
	const [shouldClear, setShouldClear] = useState(false);
	const [projects, setProjects] = useState([]);
	const [filteredManagedProjects, setFilteredManagedProjects] = useState([]);

	const sortProjects = useCallback((projectList) => {
		return projectList.sort(function (a, b) {
			try {
				let nameA = a.projectCode.toLowerCase(), nameB = b.projectCode.toLowerCase();
				if (nameA < nameB) { return -1; }
				if (nameA > nameB) { return 1; }
				return 0;
			}
			catch (err) {
				let nullProjectId = a.projectCode == null ? a.projectId : b.projectId;
				throw new Error(
					`Missing ProjectCode for projectId: ${nullProjectId}, Exception: ${err}`
				);
			}
		});

	}, []);

	const setUserProjects = useCallback(() => {
		if (!user || userIsLoading) return;
		let initialProjects = JSON.parse(JSON.stringify(user.projects));
		let sortedProjects = [];
		if (user.projects.length > 1) {
			sortedProjects = sortProjects(initialProjects);
		} else { sortedProjects = initialProjects; }
		setProjects(sortedProjects);
	}, [user, userIsLoading]);

	useEffect(() => {
		setUserProjects();
	}, [user]);

	useEffect(() => {
		setShouldClear(false);
		setManagedProjects(currentProjects);
	}, [currentProjects]);

	const onProjectRemoveClick = useCallback((selectedProject) => {
		let updatedProjects = projects.filter(item => item.projectId !== selectedProject.projectId);
		setProjects(updatedProjects);
	}, [projects]);

	const onProjectAddClick = useCallback((selectedProject) => {
		// Don't add if project already added
		if (projects.some(e => e.projectCode === selectedProject.projectCode)) return;
		const newProjects = [...projects, { projectId: selectedProject.projectId, projectCode: selectedProject.projectCode }];
		setProjects(newProjects);
	}, [projects]);

	const onSaveUserProjects = useCallback(() => {
		publishUser({ ...user, projects }, "PortalUI: Updated User Projects");
		//logUserManagment(user, "EditUserProjects");
	}, [projects, user]);

	const onProjectSearch = useCallback((projectCode) => {
		if (projectCode.length === 0) setFilteredManagedProjects([]);
		if (projectCode.length < 4) return;
		let filteredProjects = managedProjects.filter((project) => project.projectCode.toUpperCase().includes(projectCode.toUpperCase()));
		setFilteredManagedProjects(filteredProjects);
	}, [managedProjects]);

	const onKeyUp = useCallback((e) => {
		if (!e.target) return;
		let text = e.target.value;
		onProjectSearch(text);
	}, [onProjectSearch]);

	const handleCancel = useCallback(() => {
		setFilteredManagedProjects([]);
	}, []);

	if (!user || userIsLoading) return null;

	let displayName = 'Not Found';

	if (user.email && user.email.length > 0) {
		displayName = user.email;
	}
	if (user.firstName && user.lastName) {
		displayName = user.firstName + ' ' + user.lastName;
	}

	return (
		<div className="managedProjectPermissions">
			<div>Manage Project Permissions for <Label color="primary">{displayName}</Label></div>
			<hr />

			Current Projects:
			<div className="CurrentProjects">
				{projects.map((item, index) => (
					<div key={index}>
						<Hollaback data={item} onClick={onProjectRemoveClick}>
							<span className="RemoveProject">
								<Icon color="primary" icon="minus-circle" /><span title={item.projectCode}>{item.projectCode}</span>
							</span>

						</Hollaback>
					</div>
				))}
			</div>

			<div className={"UserActions"}>
				{userIsPublising && <LoadingSpinner size={30} />}
				<Button onClick={onSaveUserProjects} data-dismiss="modal" >Save</Button>
			</div>
			<hr />

			<div className="SearchProjects">
				Search Projects:
				{
					<SearchContainer handleCancel={handleCancel} clearText={shouldClear} onKeyUp={onKeyUp} className="managedProjectSearch" iconColor={'tertiary'} minSearchChars={3} showResults={false} dataModel={'managedProjects'} />
				}
				<hr />

				<div className="ProjectResults">
					{filteredManagedProjects && filteredManagedProjects.map((item, index) => (
						<div key={index}>
							<Hollaback data={item} onClick={onProjectAddClick}>
								<span className="AddProject">
									<Icon color="primary" icon="plus-circle" /><span title={item.projectCode}>{item.projectCode}</span>
								</span>
							</Hollaback>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ManageProjectPermissions;