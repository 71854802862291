'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './Contact.scss';
import {Icon, Mailto} from '../index.jsx';
import Avatar from '@mui/material/Avatar';

export class Contact extends React.Component {

    constructor(props) {
        super(props); 
      }

     getLetterAvatar(){
        return  <Avatar size={30} backgroundColor={'#ccc'}>{this.props.firstName.charAt(0).toUpperCase()} {this.props.lastName.charAt(0).toUpperCase()}</Avatar>;
    }

    renderAvatar(){
     if(!this.props.showAvatar){ return;}
     return <div className="Avatar" style={{display:'inline-block'}}>{this.getLetterAvatar()}</div>;
    }

	render(){
       return (
		<div className={"ContactContainer"}>
            {this.renderAvatar()}
            <div className="Name" style={{display:'inline-block'}}>
                <div>
                    <label>{this.props.firstName} {this.props.lastName}</label>
                    <Mailto to={[this.props.emailAddress]} subject={this.props.subjectInfo}><Icon color={'primary'} icon="envelope"/></Mailto>
                </div>
                <div className="Title">{this.props.title}</div>
            </div>
           
        </div>
       );
    }
}

Contact.propTypes = {
    /** Display First Name */
    firstName: PropTypes.string,
    /** Display Last Name */
    lastName: PropTypes.string,
    /** Display  Sub-Title */
    title: PropTypes.string,
    /** MailTo: address */
    emailAddress: PropTypes.string,
    /** Should Show Avatar left of the Contact Info */
    showAvatar: PropTypes.bool,
    /** Subject for email */
    subjectInfo: PropTypes.string
};

Contact.defaultProps = {
    showAvatar: false,
    subjectInfo: ""
};

export default Contact;