'use strict';
import React, { Component } from 'react';
//import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Theme from '../util/Theme.jsx';
import DismissibleItem from './DismissibleItem.jsx';
import { Box, Stack, Typography } from '@mui/material';

/** A drage/drop file component using Dropzone */
export class Filedrop extends Component {
	static getCount() {
		return Filedrop.count;
	}

	constructor(props) {
		super(props);
		this.onFileDismiss = this.onFileDismiss.bind(this);
		this.state = {
			id: props.id || 'Filedrop-' + Filedrop.getCount(),
			files: {},
			value: {},
			count: 0
		};
		Filedrop.count++;
	}

	/* istanbul ignore next */
	static revokeObjectURL = window.URL.revokeObjectURL || (() => { });

	//Clears the list, can be used as a parent Ref
	clearList() {
		this.setState({ files: {}, value: {}, count: 0 });
	}

	getStyles() {
		return {
			dz: {
				position: 'relative',
				cursor: 'pointer',
				color: Theme.disabled, textAlign: 'center',
				border: '1px solid ' + Theme.disabled, borderRadius: 10, padding: '15px 10px',
				height: 'auto', width: 'calc(100% - 20px)', backgroundColor: 'white',
			},
			title: {
				fontSize: Theme.getSize('large'),
				margin: 0
			},
			body: {
				fontSize: Theme.getSize('small'),
				margin: 0
			},
			icon: {
				color: Theme.getColor('primary'),
				fontSize: Theme.getSize('large'),
				marginTop: 1,
				marginRight: 5
			}
		};
	}

	onFileDrop = (files, rejects) => {

		//console.log('accepted:', files, '\nrejected:', rejects);

		let newFiles = {}, newValue = {};
		if (files && files !== undefined) {
			const self = this;
			let count = this.state.count;
			files.forEach((file, n) => {
				//destroy all previews
				count++;
				Filedrop.revokeObjectURL(file.preview);
				newValue[file.name] = file;
				newFiles[file.name] = <DismissibleItem key={n} label={file.name} onClose={self.onFileDismiss} />;
			});
			this.setState({
				count: count,
				files: { ...this.state.files, ...newFiles },
				value: { ...this.state.value, ...newValue },
			}, this.onChange);
		}
		//invoke function
		if (this.props.onDrop) this.props.onDrop(newValue);
	}

	onFileDismiss(filename) {

		//console.log('dismiss file', filename);

		if (filename in this.state.files) {
			delete this.state.files[filename];
			delete this.state.value[filename];
			this.setState({ count: this.state.count - 1, files: this.state.files }, this.onChange);
		}
	}

	onChange() {
		if (this.props.onChange)
			this.props.onChange({ target: { name: this.props.name, value: this.state.value } });
	}

	render() {
		const styles = this.getStyles();
		const { title, body, ...props } = this.props;

		return (
			<Box>
				<Dropzone className="Filedrop" onDrop={this.onFileDrop} style={styles.dz}>
					{({ getRootProps, getInputProps }) => (
						<Box>
							<Box {...getRootProps()} sx={{ border: '2px dashed grey', cursor: 'pointer' }} py={2}>
								<input {...getInputProps()} />
								<Stack justifyContent="center">
									<Typography textAlign="center" variant="h2">{title}</Typography>
									<Typography textAlign="center" variant="body">(or click)</Typography>
									<Typography textAlign="center" variant="body">{body}</Typography>
								</Stack>
							</Box>
						</Box>)}
				</Dropzone>
				<Box style={Object.keys(this.state.files).length ? {} : { display: 'none' }}>
					{Object.values(this.state.files)}
				</Box>
			</Box>
		);
	}

}

Filedrop.propTypes = {

	/** The material icon to use in the title */
	icon: PropTypes.string,

	/** The color of the icon */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The title text */
	title: PropTypes.string,

	/** The body text */
	body: PropTypes.string,

	/** The function to invoke when a file is dropped */
	onDrop: PropTypes.func,

	/** Function to invoke whenver the files change */
	onChange: PropTypes.func,

	/** The name of this form element */
	name: PropTypes.string,

};

Filedrop.count = 0;

Filedrop.defaultProps = {
	color: 'default',
	icon: 'check_circle',
	title: 'Drag & drop',
	body: 'to upload project files here.'
};

export default Filedrop;