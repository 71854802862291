
'use strict';
import React, { Component } from 'react';
import { Logger, Log } from '../utils/Logger';
import { AppInsightLogLevel } from '../enums/Enums';
import store from '../store/store';
import ErrorPage from '../views/Error.jsx';
const logger = new Logger();
import { hashString } from '../utils/StringUtils';

export class ErrorBoundary extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			errorMessage: 'no error'
		};
	}

	componentDidCatch(error, info) {
		console.log( 'ErrorBoundary: ', error, info);
		localStorage.removeItem('error');
		this.setState({ hasError: true });
		let errorInfo = info;
		let infoStack = '';
		if (info.componentStack) { infoStack = info.componentStack; }

		this.logError(error, errorInfo, infoStack);
		let userCode = '';
		try {
			const { currentUser } = store.getState();
			userCode = hashString(currentUser.data.userId);
		}
		catch { return; }
		localStorage.setItem('error', 'User code: ' + userCode);
	}

	logError(error, info, infoStack) {
		let logError = new Log();
		logError.SetLevel(AppInsightLogLevel.ERROR);
		logError.SetName('ApplicationError: ' + error);

		logError.AddProperty('error', error);
		logError.AddProperty('info', JSON.stringify(info));
		logError.AddProperty('infoStack', infoStack);

		logger.doLog(logError);
	}

	render() {
		if (this.state.hasError) {
			return <div> <ErrorPage history={this.props.history} /></div>;
		} else {
			return this.props.children;
		}
	}

}
export default ErrorBoundary;

