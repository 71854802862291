'use strict';

import React, { useCallback, useEffect, useState } from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import {Hollaback, Icon, Input, Label, Select, Toggler} from '../../../../components/index.jsx';

import {filter} from '../../../../utils/ObjectUtils';
import Sort from '../../../../utils/Sort';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger.js';

const Search = (props) => {
	const logger = useLocalAuditLogger();
	const [index, setIndex] = useState(props.index);
	const [query, setQuery] = useState(props.query);

	useEffect(() => {
		setIndex(props.index);
	}, [props.index]);

	useEffect(() => {
		setQuery(props.query);
	}, [props.query]);

	const getFuseKeys = useCallback(() => {
		switch(index) {
			default :
				return ['data.canonicalName'];
			case 'Cofactor' :
				return ['data.canonicalName', 'data.enzymeName'];
			case 'Enzyme' :
				return ['data.ecNumbers', 'data.enzymeName'];
		}
	}, [index]);

	const getSearchPlaceholder = useCallback(() => {
		switch(index) {
			default :
				return 'Search by chemical name';
			case 'Cofactor' :
				return 'Search by chemical or enzyme name';
			case 'Enzyme' :
				return 'Search by EC number or enzyme name';
		}
	}, [index]);

	const reduceItems = useCallback((items) => {
		if(index === "Enzyme") {
			return reduce(items, 'ecNumber');
		}
		return reduce(items, 'canonicalName');
	}, [index]);

	const reduce = (items, idKey) => {
		let results = [],
			ids = {};

		items.forEach(item => {
			let id = item.data[idKey],
				{compoundType, enzymeName, pathwayName} = item.data,
				label = createItemLabel(compoundType, id, enzymeName);
			if(!(id in ids)) {
				ids[id] = {label, data:item.data, maps:[pathwayName]};
				results.push(ids[id]);
			}
			else if(ids[id].maps.indexOf(pathwayName) < 0) {
				ids[id].maps.push(pathwayName);
			}
		});

		return results;
	};

	const createItemLabel = (compoundType, id, enzymeName) => {
		let label;
		switch(compoundType.toLowerCase()) {
			case 'enzyme' :
			case 'cofactor' :
				label = id + (enzymeName ? ' : '+enzymeName : '');
				break;

			default :
			case 'metabolite' :
				label = id.trim();// || '<unnamed>';
				break;
		}

		return label;
	};

	const sort = (items, key="label") => {
		items.forEach(item => {
			item.maps.sort();
		});

		return items.sort(Sort.basic('label', 1));
	};

	const onQueryChange = useCallback(e => {
		//move query and index to parent state and pass in as props for persistence...
		const {onQueryChange} = props;
		if(onQueryChange) onQueryChange(e.target.value);
		setQuery(e.target.value);
		logger.logUserAction(`subview search query change`);
	}, [props.onQueryChange]);

	const onIndexChange = useCallback(selection => {
		const {onIndexChange} = props;
		if(onIndexChange) onIndexChange(selection.name);
		logger.logUserAction(`subview search filter change`);
	}, [props.onIndexChange]);

	const onMapClick = useCallback(({id, idKey, map}) => {
		const {onMapSelect} = props;
		if(onMapSelect) onMapSelect(map, `[${idKey}="${id}"]`, index);
	}, [props.onMapSelect]);

	const renderListItem = (item, n) => {
		const {enzymeName} = item.data;
		let idKey;

		switch(index) {
			default :
				idKey = 'canonicalName';
				break;
			case 'Enzyme' :
				idKey = 'ecNumber';
				break;
		}

		let id = item.data[idKey];
		return (
			<div className="map-list" key={n}>
				<Toggler component="p" toggle="collapse" target={`#collapse-${n}`} isOpen={true} className="header" hasIcon={true} openIcon="folder-o" closeIcon="folder-open-o">&nbsp;{item.label}</Toggler>
				<div id={`collapse-${n}`} className="collapse show">
					{item.maps.map((map, n) => <Hollaback key={n} onClick={onMapClick} data={{map, idKey, id}}><p className="item"><Icon icon="level-up" className="arrow fa-rotate-90"/> <Icon icon="file-o"/> {map}</p></Hollaback>)}
				</div>
			</div>
		);
	};

	const renderResults = (list) => list.map((item, n) => renderListItem(item, n));

	const {className, data, filters, indices} = props;

	let filtersToUse = filters.concat();
	if(index !== 'Element') {
		filtersToUse.unshift(item => item.data.compoundType === index);
	}
	let filteredData = filter(data, filtersToUse);

	let fuse = new Fuse(filteredData, {keys:getFuseKeys(), distance:1000, threshold:0.1});
	let results = query && query.length >= 3 ? fuse.search(query) : filteredData;
	results = reduceItems(results);
	results = sort(results);
	let placeholder = getSearchPlaceholder();

	return (
		<div className={classnames("Search", className)}>
			<h3>Search</h3>

			<p className="d-inline pr-2">Search all available</p>
			<Select labelColor="" displayKey="display" selection={{name:index, display:index+"s"}} options={indices} onSelect={onIndexChange}/>
			<Input defaultValue={query} placeholder={placeholder} title={placeholder} onChange={onQueryChange} className="full-width" wrapperClass="fuse float-right d-inline"/>
			
			<div className="content">{renderResults(results)}</div>
		</div>
	);
};

Search.propTypes = {
	index: PropTypes.string,
	query: PropTypes.string,
};

Search.defaultProps = {
	index: 'Enzyme',
	query: '',
	filters: [
		//(item => item.data.compoundType === "Enzyme")
	],
	indices: [
		//{display:"Elements", name:"Element"},
		{display:"Enzymes", name:"Enzyme"},
		{display:"Metabolites", name:"Metabolite"},
		{display:"Minor Metabolites", name:"Minor Metabolite"},
		{display:"Cofactors", name:"Cofactor"},
		{display:"Intermediates", name:"Intermediate"},
		//{display:"FinalPathways", name:"FinalPathway"},
	]
};

export default Search;