import { createSelector } from '@reduxjs/toolkit';


export const filesSelector = createSelector(
    (state) => state,
    (state) => state.files
);


export const filesIsLoadingSelector = createSelector(
    filesSelector,
    (files) => files.filesIsLoading
);


export const filesHasErroredSelector = createSelector(
    filesSelector,
    (files) => files.filesHasErrored
);


export const filesDataSelector = createSelector(
    filesSelector,
    (files) => files.data
);


export const filesSelectedFilesIsLoadingSelector = createSelector(
    filesSelector,
    (files) => files.selectedFilesIsLoading  
);
