'use strict';
import { Logger, Log } from '../utils/Logger';
import {AppInsightLogLevel} from '../enums/Enums';
import * as toastController from '../utils/ToastController';

const logger = new Logger();

export function logError(componentName, title, description, instanceId, statusCode, showToaster = false){
        const currentProjectId = localStorage.getItem('ProjectId') || null;
		
        let logError = new Log();
        logError.SetLevel(AppInsightLogLevel.ERROR);
        logError.SetName(componentName);
        logError.AddProperty('ProjectId', currentProjectId);
         logError.AddProperty('title', title);
        logError.AddProperty('ErrorMsg',description);
        logError.AddProperty('instanceId',instanceId);
        logError.AddProperty('statusCode', statusCode);
        logger.doLog(logError);

		if(showToaster){
			toastController.showToaster(title, description, 20000);
		}
    }
