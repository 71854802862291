import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { Star } from "lucide-react";

export const Icon = React.forwardRef(
  (
    {
      icon = Star,
      component = "span",
      disabled = false,
      className,
      children,
      size = 18,
      sx,
      fill,
      testId = "icon",
      color,
      ...props
    },
    ref
  ) => {
    const Icon = icon;

    return (
      <Typography
        component={component}
        ref={ref}
        sx={{ ...sx, "&.MuiTypography-root": { display: "inline-flex" } }}
        color={color}
        {...props}
        data-testid={testId}
      >
        <Icon
          style={{
            width: size,
            height: size,
            color: "inherit",
            fill,
          }}
          className={classNames("Icon", disabled)}
        />
      </Typography>
    );
  }
);
