'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/Button.jsx';

export class SimpleForm extends Component {

	constructor(props) {
		super(props);
		this.state = {data:{}};
	}

	onSubmit = (e) => {
		this.props.onSubmit(this.state.data);
	};

	onInputChange = (e) => {
		const {name, value, isChecked} = e.target;
		let data = Object.assign({}, this.state.data);
		data[name] = value;
		
		if(isChecked !== undefined && !isChecked) delete data[name];

		this.setState({data}, function() {
			this.props.onChange(this.state.data);
		});
	};

	renderChildren() {
		return React.Children.map(this.props.children, (child, index) => {
			let type = child.type;
			if(typeof type === 'function') {
				if('displayName' in type) type = type.displayName;
				else type = type.name;
			}
			const isInput = SimpleForm.inputTypes.indexOf(type) >= 0;
			let newProps = isInput ? {onChange:this.onInputChange} : {};
			if(isInput) return React.cloneElement(child, {...newProps});
			return child;
		});
	}

	render() {
		let {id, className="SimpleForm"} = this.props;
		return (
			<form id={id} className={className}>
				{this.renderChildren()}
				<Button onClick={this.onSubmit} disabled={this.props.disabled}>{this.props.submitLabel}</Button>
			</form>
		);
	}
}

SimpleForm.inputTypes = [
	'Checkbox',
	'Filedrop',
	'Input',
	'RadioGroup'
];

SimpleForm.propTypes = {
	/** The id assigned to the form element*/
	id: PropTypes.string.isRequired,

	/** The submit button label*/
	submitLabel: PropTypes.string,

	/** Whether form is disabled */
	disabled: PropTypes.bool,

	/** Function to invoke when the form is submitted */
	onSubmit: PropTypes.func,

	/** Function to invoke whenever an input in the form changes */
	onChange: PropTypes.func,
};

SimpleForm.defaultProps = {
	disabled: false,
	submitLabel: "Submit",
	 
	onSubmit: () => {
		//console.log('SimpleForm custom onSubmit callback not defined!');
	},
	onChange: data => {
		//console.log('SimpleForm custom onChange callback not defined!', data.constructor.name);
	},
	 
};

export default SimpleForm;