import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import Nav from "./views/Nav";
import Profile from "./views/Profile";
import DataIntegration from "./views/DataIntegration";
import Explorer from "./views/Explorer";
import Explainer from "./views/Explainer";
import Support from "./views/Support";
import Filter from "./views/Filter";
import Footer from "./views/Footer";
import { 
  setDynamicResults,
  setDynamicAssociations,
  updateViewPort,
} from "./redux/reducers/discoveryPanels.reducer";
import { filterSelector } from "./redux/selectors/filter.selector";
import "./styles/index.scss";
import useWindowSize from "../hooks/useWindowSize";
import urljoin from "url-join";

const DiscoveryPanel = ({ match }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const {
    tabs,
    activeTab,
    comparison,
    multiComparisons,
    pValue,
    group,
    invertedFoldChange
  } = useSelector(filterSelector);
  
  useEffect(() => {
    dispatch(setDynamicResults());
    dispatch(setDynamicAssociations());
  }, [activeTab, comparison, multiComparisons.length, pValue, invertedFoldChange]);
  
  useEffect(() => {
    dispatch(updateViewPort(windowSize));
  }, [windowSize, group]);

  return (
      <div className="discovery-panels">
        <Nav match={match} />
        {(activeTab === tabs[0].url ||
          activeTab === tabs[1].url ||
          activeTab === tabs[2].url) && <Filter />}
        <main>
              <div>
                <Switch>
                  <Route exact path={match.url} match={match}>
                    <Explainer match={match} />
                  </Route>

                  <Route path={urljoin(match.url, tabs[0].url)} match={match}>
                    <Explainer match={match} />
                  </Route>

                  <Route path={urljoin(match.url, tabs[1].url)} match={match}>
                    <Explorer match={match} />
                  </Route>

                  <Route path={urljoin(match.url, tabs[2].url)} match={match}>
                    <Profile match={match} />
                  </Route>

                  <Route path={urljoin(match.url, tabs[3].url)} match={match}>
                    <DataIntegration match={match} />
                  </Route>

                  <Route path={urljoin(match.url, tabs[4].url)} match={match}>
                    <Support match={match} />
                  </Route>
                </Switch>
              </div>
        </main>
        <Footer />
      </div>
  );
  
};

export default DiscoveryPanel;
