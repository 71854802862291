'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import urljoin from 'url-join';

export const managedProjectsHasErrored = () => ({
  type: actionTypes.MANAGEDPROJECTS_HAS_ERRORED
});

export const managedProjectsIsLoading = () => ({
  type: actionTypes.MANAGEDPROJECTS_IS_LOADING
});

export const managedProjectsFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.MANAGEDPROJECTS_FETCH_DATA_SUCCESS,
  response,
  authenticated
});

export function managedProjectsFetchData(query = null) {

    let apiEndpoint = config.API_ENDPOINT_MANAGEDPROJECT;
    if(query)
        apiEndpoint = urljoin(config.API_ENDPOINT_MANAGEDPROJECT, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.MANAGEDPROJECTS_IS_LOADING, actionTypes.MANAGEDPROJECTS_FETCH_DATA_SUCCESS, actionTypes.MANAGEDPROJECTS_HAS_ERRORED]
    }
  };
}
