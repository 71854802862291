'use strict';
import React, { Component } from 'react';
import { Link, Router, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Label from '../../../components/controls/Label.jsx';

import './Resources.scss';
export class Resources extends Component {
  
  constructor(props) {
    super(props); 
    this.state = {
    };
  }

  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
  
  }

  render() {

    return (
     
        <div className="Resources">
          <Label size="large" color="tertiary" className="header">Resources</Label>
          <a href="http://www.metabolon.com/who-we-are/blog" target="_blank" rel="noreferrer">Metabolon Blog</a>
          <a href="http://www.metabolon.com/resources/publications" target="_blank" rel="noreferrer">Metabolon Publications</a>
          <a href="http://www.metabolon.com/resources/application-notes" target="_blank" rel="noreferrer">Application Notes</a>
          <a href="http://www.metabolon.com/resources/webinars-videos" target="_blank" rel="noreferrer">Webinars and Videos</a>
          <a href="http://www.metabolon.com/resources/ebooks" target="_blank" rel="noreferrer">eBooks and Editorials</a>
          <a href="http://metabolonwiki.azurewebsites.net/" target="_blank" rel="noreferrer">Metabolon Wiki</a>

          <Label size="normal" color="tertiary" className="header">Tutorials</Label>
          <a href="https://www.youtube.com/watch?v=j-QnLgWiAdY" target="_blank" rel="noreferrer">Cytoscape</a>
          <a href="https://www.youtube.com/watch?v=kJMUxIaSPrE" target="_blank" rel="noreferrer">Metabolync</a>
          <a href="https://www.youtube.com/watch?v=RRS9912cxfM" target="_blank" rel="noreferrer">Suryveyor</a>
          
        </div>
    );
  }
}

Resources.propTypes = {

};

export default Resources;
