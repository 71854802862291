'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Hollaback, Icon, Material, NavBar } from '../../../components/index.jsx';
import Layers from './subviews/Layers.jsx';
import Settings from './subviews/Settings.jsx';
import Search from './subviews/Search.jsx';
import Info from './subviews/Info.jsx';
import Explorer from './subviews/Explorer.jsx';
import Enrichment from './subviews/Enrichment.jsx';
import './PathwaySubview.scss';
import { Logger, Log } from '../../../utils/Logger';
import {AppInsightLogLevel} from '../../../enums/Enums';
import { Auditor } from '@/components/util/Auditor.jsx';
const logger = new Logger();

export class PathwaySubview extends Auditor {
	
	static propTypes = {
		project: PropTypes.shape({projectCode:PropTypes.string, description:PropTypes.string}),
		sampleSet: PropTypes.shape({
			name: PropTypes.string,
			projectCode: PropTypes.string,
			projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			sample: PropTypes.shape({name:PropTypes.string, organism:PropTypes.string}),
			stats: PropTypes.array,
			metabolites: PropTypes.array,
		}),
		viewId: PropTypes.string,
		statsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		maps: PropTypes.array,
		mapNodes: PropTypes.array,
		explorerMode: PropTypes.string,
	};

	static defaultProps = {
		project:{},
		sampleSet: {sample:{}},
		viewId: null,
		statsId: null,
		maps:[],
		mapNodes:[],
		settingsKey: null,
		explorerMode: 'fireworks',
	};

	constructor(props) {
		super(props);
		const {viewId, explorerMode} = this.props;

		this.state = {
			...this.initState({explorerMode, visible:false, viewClass:this.getViewClass(viewId), viewId}),
			...super.state // pull in state from auditor to get the logger
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.statsId !== this.props.statsId) {
			const {viewId, viewClass} = this.state;
			this.updateData({...this.initState(viewId, viewClass)});
		}
		else if(prevProps.explorerMode !== this.props.explorerMode) {
			this.updateData({explorerMode:this.props.explorerMode});
		}
	}

	initState(data={viewId:this.props.viewId}) {
		return data;
	}

	updateData(data) {
		this.setState({...data});
	}

	generateClassNames() {
		const {viewClass, visible}  = this.state;
		let classNames = [this.constructor.name];
		if(viewClass) classNames.push(viewClass);
		visible ? classNames.push('on') : classNames.push('off');

		return classNames;
	}

	generateClassString() {
		return this.generateClassNames().join(' ');
	}

	toggle() {
		let {visible} = this.state;
		this.setState({visible:!visible});
	}

	onToggle = () => {
		this.toggle();
	};

	onSwitch = viewId => {
		let viewClass = this.getViewClass(viewId);
		this.setState({viewId, viewClass});
		this.state.logger.logUserAction(`${viewId} subview`);
	};

	onMapSelect = (mapId, selector, ...rest) => {
		let {onMapSelect} = this.props;
		this.onSwitch(null);
		if(onMapSelect) onMapSelect(mapId, selector, ...rest);
	};

	onSubpathwaySelect = subPathwayId => {
		let {onSubpathwaySelect} = this.props;
		this.onSwitch(null);
		if(onSubpathwaySelect) onSubpathwaySelect(subPathwayId);
	}

	onSettingsUpdate = data => {
		let {onSettingsUpdate} = this.props;
		//this.onSwitch(null);
		if(onSettingsUpdate) onSettingsUpdate(data);
	};

	onSettingsReset = () => {
		this.setState({settingsKey:dayjs().valueOf()});
	};

	onLayersUpdate = data => {
		let {onLayersUpdate} = this.props;
		//this.onSwitch(null);
		if(onLayersUpdate) onLayersUpdate(data);
	};

	updateSearchQuery = query => {
		this.setState({query});
	};

	updateSearchIndex = index => {
		this.setState({index});
	};

	getViewClass(viewId) {
		let viewClass;
		switch(viewId) {
			default :
				viewClass = '';
				break;

			case 'explorer' :
			case 'layers' :
			case 'settings' :
				viewClass = 'half';
				break;

			case 'enrichment' :
			case 'search' :
			case 'info' :
				viewClass = 'full';
				break;
		}
		if(viewId){
			this.logEvent("Click", viewId);
		}
		return viewClass;
	}
	logEvent(property, value){
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('SubView_PathwayExplorer_' + value);
		logFilterChange.AddProperty(property,value);
		logger.doLog(logFilterChange);
	}

	render() {
		const {match, compounds, maps, mapNodes, maxEnrichment, pathways, project, count, sampleSet, sig, statsId, adminProjectData} = this.props;
		const {viewId, settingsKey, query, index, explorerMode, toggleClass, visible} = this.state;

		let toggleTitle = 'Expand side view',
			toggleIcon = 'caret-right';
		if(visible) {
			toggleTitle = 'Collapse side view';
			toggleIcon = 'caret-left';
		}
		return (
			<NavBar className={this.generateClassString()}>
				{super.render()/*call super.render() to add the auditor*/}
				<Hollaback onClick={this.onSwitch} data={null}><Button className="alt2 close" testId="pathwaySubNav-close" color="tertiary"><Material size="large" icon="close"/></Button></Hollaback>
				
				<div className="toggler" data-testId="pathwaySubNav-toggle" onClick={this.onToggle} title={toggleTitle}>
					<Icon className="large secondary" icon={toggleIcon}/>
				</div>

				<div className="nav-container top">
					<Hollaback onClick={this.onSwitch} data="info">
						<Button title="Study Information" className="alt2" testId="pathwaySubNav-info" color={viewId == "info" ? "primary" : "tertiary"}>
							<Material size="large" icon="error_outline"/>
							<span>INFO</span>
						</Button>
					</Hollaback>
					
					<Hollaback onClick={this.onSwitch} data="search">
						<Button  title="Search" className="alt2" testId="pathwaySubNav-search" color={viewId == "search" ? "primary" : "tertiary"}>
							<Material size="large" icon="find_in_page"/>
							<span>FIND</span>
						</Button>
					</Hollaback>
					
					<Hollaback onClick={this.onSwitch} data="explorer">
						<Button title="Pathway Counts" className="alt2" testId="pathwaySubNav-explorer" color={viewId == "explorer" ? "primary" : "tertiary"}>
							<Material size="large" icon="explore"/>
							<span>EXPLORE</span>
						</Button>
					</Hollaback>
					
					<Hollaback onClick={this.onSwitch} data="enrichment">
						<Button title="Pathway Enrichment" className="alt2" testId="pathwaySubNav-enrichment" color={viewId == "enrichment" ? "primary" : "tertiary"}>
							<Material size="large" icon="assessment"/>
							<span>ENRICH.</span>
						</Button>
					</Hollaback>
				</div>
				
				
				<div className="nav-container bot">
					<Hollaback onClick={this.onSwitch} data="layers">
						<Button title="Data Visibility" className="alt2" testId="pathwaySubNav-layers" color={viewId == "layers" ? "tertiary" : "secondary"}>
							<Material size="large" icon="layers"/>
							<span>OPT.</span>
						</Button>
					</Hollaback>

					<Hollaback onClick={this.onSwitch} data="settings">
						<Button title="Settings" className="alt2" testId="pathwaySubNav-settings" color={viewId == "settings" ? "tertiary" : "secondary"}>
							<Material size="large" icon="settings"/>
							<span>SET.</span>
						</Button>
					</Hollaback>
				</div>

				<Explorer sampleSet={sampleSet} statsId={statsId} maps={maps} onMapSelect={this.onMapSelect} onSubpathwaySelect={this.onSubpathwaySelect} compounds={compounds} pathways={pathways} className={viewId == "explorer" ? "explorer" : "explorer hidden"} maxEnrichment={maxEnrichment}/>

				<Enrichment sampleSet={sampleSet} statsId={statsId} count={count} sig={sig} compounds={compounds} pathways={pathways} onSubpathwaySelect={this.onSubpathwaySelect} className={viewId == "enrichment" ? "enrichment" : "enrichment hidden"} maxEnrichment={maxEnrichment}/>

				<Search query={query} index={index} onQueryChange={this.updateSearchQuery} onIndexChange={this.updateSearchIndex} data={mapNodes} on onMapSelect={this.onMapSelect} sampleSet={sampleSet} statsId={statsId} className={viewId == "search" ? "search" : "search hidden"}/>

				<Info sampleSet={sampleSet} adminProjectData={adminProjectData} statsId={statsId} project={project} className={viewId == "info" ? "info" : "info hidden"}/>

				<Layers settingsKey={settingsKey} sampleSet={sampleSet} statsId={statsId} onChange={this.onLayersUpdate} className={viewId == "layers" ? "layers" : "layers hidden"}/>

				<Settings settingsKey={settingsKey} sampleSet={sampleSet} onReset={this.onSettingsReset} statsId={statsId} onChange={this.onSettingsUpdate} className={viewId == "settings" ? "settings" : "settings hidden"} explorerMode={explorerMode}/>
				
			</NavBar>
		);
	}
}

export default PathwaySubview;