import Cookies from 'universal-cookie';
import { dayjs } from '@/utils/DateUtils.js';

import { clone, merge } from './ObjectUtils.js';

const db = new Cookies();
const cookieLabel = "metabolync-settings";

const defaultLayers = {
	foldChangeScale: true,
	multiOverlay: false,
	detailedView: false,

	hideFinalPathways: false,
	hideIntermediates: true,
	hideMinorMetabolites: true,
	hideCofactors: true,
	hideCommonBiologics: true,
	hideNoncoreElements: true,
};

const defaultColors = {
	standard: {
		sigUp: '#b01129',
		trendUp: '#fbd5da',
		sigDown: '#0D2685',
		trendDown: '#7DD6F7'
	},
	zscore: {
		sigUp: '#b01129',
		trendUp: '#fbd5da',
		sigDown: '#0072c6',
		trendDown: '#daecf9',
		dead: '#000000'
	},
	effect: {
		sigUp: '#0072c6',
		trendUp: '#daecf9',
		sigDown: '#0072c6',
		trendDown: '#daecf9'
	},
	defaults: {
		sigText: '#ffffff',
		node: '#ffffff',
		enzyme: '#ffffff',
		border: '#808080',
		edge: '#808080',
		dead: '#000000',
		undetected: '#cccccc',
		blank: '#ffffff',
		nil: '#ffffff',
		selection: '#ffff00',
		background: '#f0f0f0',
		font: '#808080'
	},
	black: '#000000',
	white: '#ffffff',

};

const defaultStyles = {
	'font-family': 'Times New Roman',
	node: {
		'font-size': 12
	},
	edge: {
		'font-size': 12
	},
	Label: {
		'font-size': 18,
	},
	fireworks: {
		superpathway: {
			shape: 'hexagon',
			width: 60,
			height: 70,
			'font-size': 12,
		},
		subpathway: {
			shape: 'ellipse',
			width: 45,
			height: 45,
			'font-size': 12,
		},
		metabolite: {
			shape: 'ellipse',
			width: 20,
			height: 20,
			'font-size': 12,
		},
	},
	compounds: {
		FinalPathway: {
			shape: 'triangle',
			width: 35,
			height: 35,
			'font-size': 12,
		},
		Metabolite: {
			shape: 'ellipse',
			width: 35,
			height: 35,
			'font-size': 12,
		},
		'Minor Metabolite': {
			shape: 'ellipse',
			width: 20,
			height: 20,
			'font-size': 10,
		},
		Cofactor: {
			shape: 'ellipse',
			width: 15,
			height: 15,
			'font-size': 8,
		},
		Intermediate: {
			shape: 'ellipse',
			width: 15,
			height: 15,
			'font-size': 8,
		},
		Enzyme: {
			shape: 'rectangle',
			width: 35,
			height: 20,
			'font-size': 8,
		},
		Class: {
			shape: 'hexagon',
			width: 35,
			height: 35,
			'font-size': 12,
		}
	}
};

export default class SettingsMap {

	static COLOR_UPDATE = "color_update";
	static COLOR_RESET = "color_reset";
	static LAYER_UPDATE = "layer_update";
	static LAYER_RESET = "layer_reset";
	static STYLE_UPDATE = "style_update";
	static STYLE_RESET = "style_reset";

	static get defaultColorMap() {
		return clone(defaultColors);
	}

	static get defaultStyleMap() {
		return clone(defaultStyles);
	}

	static get defaultLayerMap() {
		return clone(defaultLayers);
	}

	static signals = new mkr.SignalManager();

	static colorChange = true;
	static lastUpdate = dayjs().valueOf();
	static user;

	static initMap() {
		if (!this.user) this.user = localStorage.getItem('email')?.replace('@', '&');
		let settings = db.get(`${this.user}-${cookieLabel}`);
		if (!settings) {
			settings = {};
		}
		this.lastUpdate = dayjs().valueOf();
		let expires = dayjs().add(90, 'day').utc().toDate();
		db.set(`${this.user}-${cookieLabel}`, settings, {
			path: '/',
			expires,
			sameSite: 'strict'
		});

		//add colors for sig trending text
		this.updateSettings(settings);
		return settings;
	}

	static updateRule(selector, css) {
		if (!window || !('CSSRulePlugin' in window)) return;
		let rule = CSSRulePlugin.getRule(selector);
		if (!rule) {
			mkr.addRule(selector, css);
			return;
		}
		TweenMax.set(rule, { cssRule: css });
	}

	static updateSettings(settings) {
		settings.colors = merge(this.defaultColorMap, settings.colors);
		settings.styles = merge(this.defaultStyleMap, settings.styles);
		settings.layers = merge(this.defaultLayerMap, settings.layers);

		if(!this.colorChange) return;
		this.colorChange = false;

		let colors = settings.colors;
		this.updateRule(".Cytoscape", { backgroundColor: colors.defaults.background });
		this.updateRule(".table-cell.nil", { backgroundColor: colors.defaults.nil });
		//undetected compounds don't show up in stats tables
		//this.updateRule(".table-cell.undetected", {backgroundColor:colors.defaults.undetected});

		//standard
		let sel = ".MetaboliteTable:not(.zscore) .table-row .table-cell:not(.blank)";
		this.updateRule(sel + ".sigUp", {
			backgroundColor: colors.standard.sigUp,
			borderColor: colors.standard.trendUp
		});
		this.updateRule(sel + ".sigUp .Label", {
			color: colors.defaults.sigText
		});

		this.updateRule(sel + ".trendUp", {
			backgroundColor: colors.standard.trendUp,
			borderColor: colors.standard.sigUp
		});
		this.updateRule(sel + ".trendUp .Label", {
			color: colors.standard.sigUp
		});

		this.updateRule(sel + ".sigDown", {
			backgroundColor: colors.standard.sigDown,
			borderColor: colors.standard.trendDown
		});
		this.updateRule(sel + ".sigDown .Label", {
			color: colors.defaults.sigText
		});

		this.updateRule(sel + ".trendDown", {
			backgroundColor: colors.standard.trendDown,
			borderColor: colors.standard.sigDown
		});
		this.updateRule(sel + ".trendDown .Label", {
			color: colors.standard.sigDown
		});

		sel = ".MetaboliteTable:not(.zscore) .table-row .table-cell.blank:not(.effectTest)";
		this.updateRule(sel, {
			backgroundColor: colors.defaults.blank,
			borderColor: colors.defaults.blank
		});

		//standard legend
		this.updateRule(".StatsLegend .sigUp::before", {
			backgroundColor: colors.standard.sigUp,
			borderColor: colors.standard.trendUp
		});
		this.updateRule(".StatsLegend .trendUp::before", {
			backgroundColor: colors.standard.trendUp,
			borderColor: colors.standard.sigUp
		});
		this.updateRule(".StatsLegend .sigDown::before", {
			backgroundColor: colors.standard.sigDown,
			borderColor: colors.standard.trendDown
		});
		this.updateRule(".StatsLegend .trendDown::before", {
			backgroundColor: colors.standard.trendDown,
			borderColor: colors.standard.sigDown
		});

		//zscore
		sel = ".MetaboliteTable.zscore .table-row .table-cell:not(.blank)";
		this.updateRule(sel + ".sigUp", {
			backgroundColor: colors.zscore.sigUp,
			borderColor: colors.zscore.trendUp
		});
		this.updateRule(sel + ".sigUp .Label", {
			color: colors.defaults.sigText
		});

		this.updateRule(sel + ".trendUp", {
			backgroundColor: colors.zscore.trendUp,
			borderColor: colors.zscore.sigUp
		});
		this.updateRule(sel + ".trendUp .Label", {
			color: colors.zscore.sigUp
		});

		this.updateRule(sel + ".sigDown", {
			backgroundColor: colors.zscore.sigDown,
			borderColor: colors.zscore.trendDown
		});
		this.updateRule(sel + ".sigDown .Label", {
			color: colors.defaults.sigText
		});

		this.updateRule(sel + ".trendDown", {
			backgroundColor: colors.zscore.trendDown,
			borderColor: colors.zscore.sigDown
		});
		this.updateRule(sel + ".trendDown .Label", {
			color: colors.zscore.sigDown
		});

		/*this.updateRule(sel+".dead", {
			backgroundColor:colors.zscore.dead,
			borderColor: colors.zscore.dead
		});*/
		/*
		keep commented for now...
		will udate with color option for dead cell text
		this.updateRule(sel+".dead:not(.blank) .Label", {
			color: colors.zscore.dead
		});*/

		/*sel = ".MetaboliteTable.zscore .table-row .table-cell.blank:not(.effectTest)";
		this.updateRule(sel, {
			backgroundColor:colors.zscore.dead,
			borderColor: colors.zscore.dead
		});*/

		//zscore legend
		this.updateRule(".StatsLegend .zscore .sigUp::before", {
			backgroundColor: colors.zscore.sigUp,
			borderColor: colors.zscore.trendUp
		});
		this.updateRule(".StatsLegend .zscore .trendUp::before", {
			backgroundColor: colors.zscore.trendUp,
			borderColor: colors.zscore.sigUp
		});
		this.updateRule(".StatsLegend .zscore .sigDown::before", {
			backgroundColor: colors.zscore.sigDown,
			borderColor: colors.zscore.trendDown
		});
		this.updateRule(".StatsLegend .zscore .trendDown::before", {
			backgroundColor: colors.zscore.trendDown,
			borderColor: colors.zscore.sigDown
		});


		//effect
		sel = ".MetaboliteTable .table-row .table-cell:not(.blank).effectTest";
		this.updateRule(sel + ".sigUp, " + sel + ".sigDown", {
			backgroundColor: colors.effect.sigUp,
			borderColor: colors.effect.trendUp
		});
		this.updateRule(sel + ".trendUp, " + sel + ".trendDown", {
			backgroundColor: colors.effect.trendUp,
			borderColor: colors.effect.sigUp
		});
		this.updateRule(sel + ".trendUp .Label, " + sel + ".trendDown .Label", {
			color: colors.effect.sigUp
		});
	}

	static on(eventType, callback, context, priority) {
		this.signals.add(...arguments);
	}

	static once(eventType, callback, context, priority) {
		this.signals.addOnce(...arguments);
	}

	static off(eventType, callback, context) {
		this.signals.remove(...arguments);
	}

	static dispatch(...args) {
		this.signals.dispatch(...arguments);
	}

	static updateColors(value) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings.colors = merge(settings.colors || {}, value);
		this.map = settings;
		this.colorChange = true;
		this.dispatch(this.COLOR_UPDATE);
	}
	static resetColors() {
		//this.colors = this.defaultColors;
		this.colors = {};
		this.dispatch(this.COLOR_RESET);
	}

	static updateLayers(value) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings.layers = merge(settings.layers || {}, value);
		this.map = settings;
		this.dispatch(this.LAYER_UPDATE);
	}

	static resetlayers() {
		this.layers = {};
		this.dispatch(this.LAYER_RESET);
	}

	static updateStyles(value) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings.styles = merge(settings.styles || {}, value);
		this.map = settings;
		this.dispatch(this.STYLE_UPDATE);
	}
	static resetStyles() {
		//this.styles = this.defaultStyles;
		this.styles = {};
		this.dispatch(this.STYLE_RESET);
	}

	static get map() {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		if (!settings) return this.initMap();

		settings.colors = merge(this.defaultColorMap, settings.colors);
		settings.styles = merge(this.defaultStyleMap, settings.styles);
		settings.layers = merge(this.defaultLayerMap, settings.layers);

		return settings;
	}
	static set map(settings) {
		let expires = dayjs().add(90, 'day').utc().toDate();
		db.set(`${this.user}-${cookieLabel}`, settings, {
			path: '/',
			expires,
			sameSite: 'strict'
		});
		this.updateSettings(settings);
	}

	static get colors() {
		return this.map.colors;
	}
	static set colors(colors) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings["colors"] = clone(colors);
		this.map = settings;
		this.colorChange = true;
	}

	static get layers() {
		return this.map.layers;
	}
	static set layers(value) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings.layers = clone(value);
		this.map = settings;
	}

	static get styles() {
		return this.map.styles;
	}
	static set styles(value) {
		let settings = db.get(`${this.user}-${cookieLabel}`);
		settings.styles = clone(value);
		this.map = settings;
	}
}