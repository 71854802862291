'use strict';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './theme/css/bootstrap.css';
import './index.scss';
import './theme/theme.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App.jsx';
import { unregister } from './registerServiceWorker.js';
import WebFont from 'webfontloader';
import store from './store/store.js';
import { createBrowserHistory } from 'history';
import { ThemeContextProvider } from './theme/index.jsx';
const history = createBrowserHistory();
self.$RefreshReg$ = () => {};
self.$RefreshSig$ = () => () => {};

WebFont.load({
	google: {
		families: [
			'Inter:300,400,500,600,700,800',
			'Lato',
			'Space Mono',
			'Inconsolata',
			'Source Sans Pro',
			'Source Serif Pro',
			'EB Garamond',
			'sans-serif'
		]
	}
});

const root = createRoot(document.getElementById('root'));
root.render(
	(<Provider store={store}>
		<ThemeContextProvider><App history={history} /></ThemeContextProvider>
	</Provider>),
);
//registerServiceWorker();
//Unregistering service worker in order to bust caching
unregister();