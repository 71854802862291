// extracted by mini-css-extract-plugin
var _1 = "head_active";
var _2 = "head_button-wrapper";
var _3 = "head_download-button";
var _4 = "head_download-buttons-and-preview";
var _5 = "head_download-preview-wrapper";
var _6 = "head_floating-button-table-wrapper";
var _7 = "head_left-wrapper";
var _8 = "head_right-wrapper";
var _9 = "head_table-wrapper";
var _a = "head_top-left";
var _b = "head_top-right";
var _c = "head_top-wrapper";
var _d = "head_warning-popup";
export { _1 as "active", _2 as "button-wrapper", _3 as "download-button", _4 as "download-buttons-and-preview", _5 as "download-preview-wrapper", _6 as "floating-button-table-wrapper", _7 as "left-wrapper", _8 as "right-wrapper", _9 as "table-wrapper", _a as "top-left", _b as "top-right", _c as "top-wrapper", _d as "warning-popup" }
