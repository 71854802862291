import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { dataSelector } from "../../redux/selectors/data.selector";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import * as styles from "../containers/integration/bottom.module.scss";

const Left = () => {
  const { width } = useSelector(viewPortSelector);
  const { comparison, pValue, widthThreshold } = useSelector(filterSelector);
  const { metabolites, results } = useSelector(dataSelector);

  const filteredData = useMemo(() => {
    return results.filter((d) => d.detected && d.comparison === comparison);
  }, [comparison, pValue]);

  const percentDetected = (filteredData.length / metabolites.length) * 100;
  const percentRemaining = 100 - percentDetected;

  const radius = 13.5;

  const xStart = width <= widthThreshold ? 25 : 21;
  const yStart = 16;
  const strokeDashoffset = 141;
  const strokeWidth = 1.7;

  return (
    <div className={["svg-item"]}>
      <svg width="100%" height="100%" viewBox="0 1 50 35" className="donut">
        <circle
          cx={xStart}
          cy={yStart}
          r={radius}
          fill="#fff"
        />
        <circle
          className={styles["donut-ring"]}
          cx={xStart}
          cy={yStart}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
        />
        <circle
          className={styles["donut-segment"] + [" donut-segment"]}
          cx={xStart}
          cy={yStart}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={percentDetected + " " + percentRemaining}
          strokeDashoffset={strokeDashoffset}
        />
        <g className="donut-text donut-text-1">
          <text y={yStart - 6} transform="translate(0, 0)">
            <tspan
              x={xStart}
              textAnchor="middle"
              className={styles["donut-label"]}
            >
              Total Detected
            </tspan>
          </text>
          <text y={yStart} transform="translate(0, 2)">
            <tspan
              x={xStart}
              textAnchor="middle"
              className={styles["donut-detected"]}
            >
              {filteredData.length}
            </tspan>
          </text>
          <line
            x1={xStart - 8}
            x2={xStart + 8}
            y1={yStart + 5}
            y2={yStart + 5}
            stroke="var(--cold-gray-dark)"
            strokeWidth=".2"
          />
          <text y={yStart + 9} transform="translate(0, 0)">
            <tspan
              x={xStart}
              textAnchor="middle"
              className={styles["donut-metabolites"]}
            >
              {metabolites.length}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};
export default Left;
