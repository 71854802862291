'use strict';

import React, {useRef, useEffect} from 'react';
import {VariableSizeList as List} from 'react-window';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 60;
const ITEM_PADDING = 0;

export default function VList({dataset, itemRenderer, primaryKey, dimensions}) {
	const listRef = useRef(null);
	const scrollRef = useRef(null);

	const defaultItemRenderer = (item, index, itemProps) => {
		return (
			<li key={"i-" + index} {...itemProps}>
				Item #{item.index}
			</li>
		);
	};

	const renderer = itemRenderer || defaultItemRenderer;

	/*static propTypes = {
			customScroll: PropTypes.bool,
			dataset: PropTypes.array,
			pageBufferSize: PropTypes.number,
			itemRenderer: PropTypes.func,
			listRenderer: PropTypes.func,
			resetScrollOnUpdate: PropTypes.bool,
		};
		
	static defaultProps = {
		customScroll: false,
		dataset: [],
		resetScrollOnUpdate: false,
		itemRenderer: VirtualizedList.defaultItemRenderer,
		listRenderer: VirtualizedList.defaultListRenderer,
	};*/
	
	const renderItem = ({index, style}) => {
		let even = index % 2 === 0;
		let className = "list-item table-row " + (even ? "even" : "odd");
		let item = dataset[index];
		//console.log(item, index);
		return renderer(item, index, {className, style});
	};

	const getItemKey = (index) => {
		return dataset[index][primaryKey];
	};
	
	const getItemSize = (index) => {
		return ITEM_HEIGHT + ITEM_PADDING;
	};

	 
	return (
		<div className="body-wrapper ScrollContainer">
			<div className="scroll-wrapper">
				<List
					className="scroll-list"
					ref={listRef}
					outerRef={scrollRef}
					itemCount={dataset.length}
					itemKey={getItemKey}
					itemSize={getItemSize}
					estimatedItemSize={getItemSize(0)}
					overscanCount={12}
					width={dimensions.width}
					height={dimensions.listHeight}
				>
					{renderItem}
				</List>
			</div>
		</div>
	);
	 
}