import React from "react";
import { Gray } from "metabux/theme/colors";
import { Box, Typography } from "@mui/material";
import Link from "metabux/components/Link/Link";

export const ExploreMetric = ({
  label,
  icon,
  href,
  bodyText,
	external = false,
  metricsCount = 1,
}) => {

  const metricWidth = `${100 / metricsCount}%`;
	const LinkComponent = external ? 'a' : Link;

	return (
    <Box sx={{ width: metricWidth }}>
      <LinkComponent component="div" href={href} target={"_self"} style={{ fontSize: "3rem" }}>
        <Box sx={{ border:"4px solid", borderColor:Gray[200], color:Gray[800], position:"relative", width:"68px", height:"68px", borderRadius:"34px" }}>
          {React.cloneElement(icon, {sx:{position:"absolute", left:"7px", top:"7px"}})}
        </Box>
        <Typography
          variant='h4'
          sx={{
            fontWeight: 600,
            color: Gray[500],
            mb: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {label}
        </Typography>
      </LinkComponent>
      <Typography variant='body1' sx={{ color: Gray[800] }}>
        {bodyText}
      </Typography>
    </Box>
  );
}