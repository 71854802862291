import React from "react";
import { Chip, Typography } from "@mui/material";
import { Icon } from "metabux/components/Icon/Icon";
import { ArrowDown, ArrowUp } from "lucide-react";

export const Badge = ({
  content,
  icon=null,
  color,
  status="journal",
  label,
  variant,
	testId="badge",
  ...props
}) => {
  let badgeIcon = icon === null && status === "up" ? ArrowUp : icon;
  badgeIcon = icon === null && status === "down" ? ArrowDown: badgeIcon;

  const badgeLabel = (
    <>
      <Typography variant="caption">{label}</Typography>
      <Typography
        variant="caption"
        component={"span"}
        sx={{
          fontWeight: 600,
          marginLeft: 0
        }}
      >
        {content}
      </Typography>
      {badgeIcon && (
        <Icon size={12} sx={{ marginRight: -1 }} icon={badgeIcon} />
      )}
    </>
  );

  return (
    <Chip
      variant={variant}
      label={badgeLabel}
      color={color}
      className={`badge-${status} badge`}
      data-testid={testId}
      {...props}
    />
  );
}

export default Badge