export const initializeInteractions = () => ({
    id: "default",
    display: "default",
    type: null,
    subtype: null,
    method: null,
    source: null,
    sourceSpecific: null,
});


export const interactionsReducer = () => ({
    interactionsUpdateSelection: (state, action) => {
        const payload = action.payload;
        const currentState = state.interactions;
        const initState = initializeInteractions();

        if (payload.id === "default:override") {
            if (payload.source === "select" && currentState.type === "metabolite") {
                state.interactions = currentState;
            } else {
                state.interactions = initState;
            }
        } else if (currentState.method === "click" && payload.method !== "click") {
            state.interactions = currentState;
        } else if (currentState.method === "click" && payload.id === currentState.id) {
            state.interactions = initState;
            state.interactions.source = "doubleClick";
        } else if (payload.id && payload.id !== "default") {
            state.interactions = {
                id: payload.id,
                display: payload.display,
                type: payload.type,
                subtype: payload.subtype,
                method: payload.method,
                source: payload.source,
                sourceSpecific: payload.sourceSpecific
            };
        } else {
            state.interactions = initState;
        }
    }
});
