import SettingsMap from '../../utils/SettingsMap.js';

export default class CytoscapeStyle {

	static createEdgeData(id, source, target) {
		return {
			data: {
				id,
				source,
				target,
				font_family: "SansSerif",
				font_weight: "normal",
				weight: 1,
				font_size: 12,
				line_style: "solid",
				curve_style: "bezier",
				source_arrow_shape: 'none', 
				target_arrow_shape: 'triangle',
				width: 1,
			}
		};
	}

	static createMetaNodeData(metabolite, subId, superId, isUnknown, parent) {
		return {
			data: {
				...metabolite,
				parent,
				subId, superId,
				isUnknown: isUnknown.toString(),
				id: metabolite.metaboliteId,
				content: metabolite.chemicalName,
				type: 'metabolite',
				height: 20, width: 20, weight:1, shape:'ellipse',
				border_width: 1,
				font_family: "SansSerif",
				font_weight: "normal",
				font_size: 12,
				border_style: "solid",
			}
		};
	}

	static createSubNodeData(id, superId, content, parent) {
		return {
			data: {
				id,
				content,
				superId,
				parent,
				type: 'subpathway',
				height: 45, width: 45, weight:1, shape:'ellipse',
				border_width: 1,
				font_family: "SansSerif",
				font_weight: "normal",
				font_size: 12,
				border_style: "solid",
			}
		};
	}

	static createSuperNodeData(id, content) {
		return {
			data: {
				id,
				content,
				type: 'superpathway',
				height: 60, width: 70, weight:1, shape:'hexagon',
				border_width: 1,
				font_family: "SansSerif",
				font_weight: "normal",
				font_size: 12,
				border_style: "solid"
			}
		};
	}

	/** creates a mapper function for a given data field that clamps the weight-scaled
	* value between the given min and max values.
	*/
	static dynamicWeightScale(dataField) {
		return (el) => {
			let val = el.data(dataField);
			//console.log(val, el.data('weight'));
			if(!val) val = 1;
			let w = el.data('weight') || 1;
			return val * w;
		};
	}

	static weightScale(val) {
		return (el) => {
			//console.log(val, el.data('weight'));
			let w = el.data('weight') || 1;
			return val * w;
		};
	}

	static getEnzymeContent(el) {
		return el.data('ecNumber') || el.data('canonicalName');
	}

	static createColorStyles(colorMap) {
		return [
			...this.createStudyStyles(colorMap),
			...this.createStudyStyles(colorMap, "zscore"),
			...this.createStudyStyles(colorMap, "effect"),
			this.createStyle('node.nil', {
				'background-color': colorMap.defaults.nil,
			}),
			this.createStyle('node.blank', {
				'background-color': colorMap.defaults.blank,
			}),
			this.createStyle('node:selected', {
				'background-color': colorMap.defaults.selection,
				'z-index': 10,
			}),
			this.createStyle('edge:selected', {
				'line-color': colorMap.defaults.selection,
				'source-arrow-color': colorMap.defaults.selection,
				'target-arrow-color': colorMap.defaults.selection,
				'z-index': 10,
			})
		];
	}

	static createStyle(selector, style) {
		return {selector, style};
	}

	static createStudyStyle(target, study, colorMap, status) {
		return this.createStyle(`${target}.${study}.${status}`, {
			'background-color': colorMap[study][status] || colorMap[status]
		});
	}

	static createStudyStyles(colorMap, study="standard") {
		return Object.keys(colorMap[study]).map(status => this.createStudyStyle('node', study, colorMap, status));	
	}
	
	static createCompoundStyle(styleMap, compound) {
		let styles = styleMap.compounds[compound];
		return this.createStyle(`node[compoundType="${compound}"]`, {
			shape: styles.shape,
			width: this.weightScale(styles.width),
			height: this.weightScale(styles.height),
			'font-size': styles['font-size'],
		});
	}

	static createCompoundStyles(styleMap) {
		return [
			this.createStyle('node[compoundType="Label"]', {
				'font-size': styleMap.Label['font-size'],
			}),
			...Object.keys(styleMap.compounds).map(compound => this.createCompoundStyle(styleMap, compound))
		];
	}

	static createFireworkStyle(styleMap, compound) {
		let styles = styleMap.fireworks[compound];
		return this.createStyle(`node[type="${compound}"]`, {
			shape: styles.shape,
			width: this.weightScale(styles.width),
			height: this.weightScale(styles.height),
			'font-size': styles['font-size'],
		});
	}

	static createFireworkStyles(styleMap) {
		return [
			...Object.keys(styleMap.fireworks).map(compound => this.createFireworkStyle(styleMap, compound))
		];
	}

	static createStyles() {
		let map = SettingsMap.map;
		let colorMap = map.colors,
			styles = map.styles;

		return [{
			selector: 'node',
			style: {
				'text-valign': 'top',
				'text-halign': 'center',
				'text-wrap': 'wrap',
				'text-max-width': '185px',

				content:'data(content)',
				shape: 'data(shape)',
				width: 'data(width)',
				height: 'data(height)',
				color: colorMap.defaults.font,
				'background-color': colorMap.defaults.undetected,
				'background-opacity': 1,
				'border-style': 'data(border_style)',
				'border-width': 'data(border_width)',
				'border-color': colorMap.defaults.border,
				'border-opacity': 1,
				'font-size': styles.node['font-size'],
				'font-family': styles['font-family'],
				'font-weight': 'data(font_weight)',
				
				//'text-outline-color': 'white',
				//'text-outline-opacity': 1,
				//'text-outline-width': 2,
			}
		},
		{
			selector: 'node[type="metabolite"]',
			style: {
				width: CytoscapeStyle.dynamicWeightScale('width'),
				height: CytoscapeStyle.dynamicWeightScale('height'),
			}
		},
		{
			selector: 'node[type="subpathway"]',
			style: {
				'background-color': colorMap.defaults.node,
			}
		},
		{
			selector: 'node[type="superpathway"]',
			style: {
				'background-color': colorMap.defaults.node,
			}
		},
		{
			selector: '$node > node',
			css: {
				'padding-top': '0px',
				'padding-left': '0px',
				'padding-bottom': '0px',
				'padding-right': '0px',
				'text-valign': 'top',
				'text-halign': 'center',
				'background-color': 'transparent',
				'background-opacity': 0,
			}
		},
		{
			selector: 'edge',
			style: {
				//'line-color': 'data(default_color)',
				//content:'data(content)',
				//opacity: 'data(opacity)',
				width: 'data(width)',
				'curve-style': 'data(curve_style)',
				//'control-point-distances': 'data(control_point_distances)',
				'source-arrow-shape': 'data(source_arrow_shape)',
				'source-arrow-color': colorMap.defaults.edge,//'data(source_arrow_color)',
				'target-arrow-shape': 'data(target_arrow_shape)',
				'target-arrow-color': colorMap.defaults.edge,//'data(target_arrow_color)',
				'line-style': 'data(line_style)',
				'line-color': colorMap.defaults.edge,//'data(line_color)',
				//'font-family': 'data(font_family)',
				'font-size': styles.node['font-size'],
				color: colorMap.defaults.font,
				'font-weight': 'data(font_weight)',
			}
		},
		{
			selector: 'edge[curve_style="unbundled-bezier"]',
			style: {
				'control-point-distances': 'data(control_point_distances)',
				//'line-color': 'pink',
			}
		},
		{
			selector: 'node.hidden',
			style: {
				'display':'none',
			}
		},
		{
			selector: 'node.manually-hidden',
			style: {
				'display':'none',
			}
		},
		...this.createColorStyles(colorMap),
		...this.createFireworkStyles(styles),
		{
			selector: '.no-label',
			style: {
				'font-size': 0,
			}
		}];
	}

	static createMapStyles() {
		let map = SettingsMap.map;
		let colorMap = map.colors,
			styles = map.styles;
			
		return [{
			selector: 'node',
			style: {
				'text-valign': 'top',
				'text-halign': 'center',
				'text-wrap': 'wrap',
				'text-max-width': '185px',
				display: 'element',//'none',
				content:'data(canonicalName)',
				'background-color': colorMap.defaults.undetected,
				'background-opacity': 1,
				color: colorMap.defaults.font,
				'border-color': colorMap.defaults.border,
				'border-opacity': 1,
				'border-style': 'solid',
				'border-width': 1,
				'font-family': styles['font-family'],
				'font-size': styles.node['font-size'],
			}
		},
		{
			selector: 'node[pathwayName="Metabolic Pathways"]',
			style: {
				display: 'element',
			}
		},
		{
			selector: 'node[compoundType="VOID"]',
			css: {
				//display: 'none',
			}
		},
		{
			selector: 'edge',
			style: {
				'source-arrow-color': colorMap.defaults.edge,
				'target-arrow-color': colorMap.defaults.edge,
				color: colorMap.defaults.font,

				'line-style': 'solid',
				'line-color': colorMap.defaults.edge,
				'font-size': styles.edge['font-size'],
				/*'source-endpoint': '90deg',
				'target-endpoint': '45deg',*/
			}
		},
		{
			selector: 'edge[interaction="Product"]',
			style: {
				'target-arrow-shape': 'triangle',
			}
		},
		{
			selector: 'edge[interaction="Reactant"]',
			style: {
			}
		},
		{
			selector: 'edge[interaction="Reversible"]',
			style: {
				'target-arrow-shape': 'triangle',
				'source-arrow-shape': 'triangle',
			}
		},
		{
			selector: 'edge[interaction="Intermediate"]',
			style: {
				
			}
		},
		{
			selector: 'edge[interaction="Cofactor"]',
			style: {
				'line-style': 'dotted',
			}
		},
		{
			selector: 'edge[?control_point]',
			style: {
				'curve-style': 'unbundled-bezier',
				'control-point-distances': 'data(control_point_distances)',
				'control-point-weights': 'data(control_point_weights)',
				//'line-color': 'pink',
			}
		},
		{
			selector: 'node[compoundType="FinalPathway"]',
			css: {
				display: 'none',
				'text-valign': 'center',
				'background-color': colorMap.defaults.node,
			}
		},
		{
			selector: 'node[compoundType="Minor Metabolite"]',
			css: {
				display: 'none',
			}
		},
		{
			selector: 'node[compoundType="Cofactor"]',
			css: {
				display: 'none',
			}
		},
		{
			selector: 'node[compoundType="Intermediate"]',
			css: {
				display: 'none',
			}
		},
		{
			selector: 'node[compoundType="Enzyme"]',
			css: {
				content: CytoscapeStyle.getEnzymeContent,
				'background-color': colorMap.defaults.enzyme,
			}
		},
		{
			selector: 'node[compoundType="Label"]',
			css: {
				'text-valign': 'center',
				'background-opacity': 0,
				'border-opacity': 0,
			}
		},
		{
			selector: 'node.hidden',
			style: {
				'display':'none',
			}
		},
		{
			selector: 'node.show',
			style: {
				display: 'element',
			}
		},
		{
			selector: 'node.manually-hidden',
			style: {
				'display':'none',
			}
		},
		...this.createColorStyles(colorMap),
		...this.createCompoundStyles(styles),
		{
			selector: '.no-label',
			style: {
				'font-size': 0,
			}
		}];
	}
}