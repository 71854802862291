'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';

export default class Toggler extends Component {

	static propTypes = {
		target: PropTypes.string,
		toggle: PropTypes.string,
	};

	static defaultProps = {
		target: null,
		toggle: 'collapse',
		hasIcon: false,
		component: 'p',
	};

	constructor(props) {
		super(props);
		const {isOpen} = this.props;
		this.state = {
			isOpen,
		};
	}

	componentDidMount() {
		const {target, toggle} = this.props;

		$(target).on(`show.bs.${toggle}`, this.onShow);
		$(target).on(`shown.bs.${toggle}`, this.onShown);
		$(target).on(`hide.bs.${toggle}`, this.onHide);
		$(target).on(`hidden.bs.${toggle}`, this.onHidden);
	}

	componentWillUnmount() {
		const {target, toggle} = this.props;

		$(target).off(`show.bs.${toggle}`, this.onShow);
		$(target).off(`shown.bs.${toggle}`, this.onShown);
		$(target).off(`hide.bs.${toggle}`, this.onHide);
		$(target).off(`hidden.bs.${toggle}`, this.onHidden);
	}

	onShow = e => {
		if(e && e.target !== e.currentTarget) return;
		
		this.setState({isOpen: true}, () => {
			const {onShow} = this.props;
			if(onShow) onShow(e);
		});
	};

	onShown = e => {
		if(e && e.target !== e.currentTarget) return;

		const {onShown} = this.props;
		if(onShown) onShown(e);
	};

	onHide = e => {
		if(e && e.target !== e.currentTarget) return;

		this.setState({isOpen: false}, () => {
			const {onHide} = this.props;
			if(onHide) onHide(e);
		});
	};

	onHidden = e => {
		if(e && e.target !== e.currentTarget) return;
		
		const {onHidden} = this.props;
		if(onHidden) onHidden(e);
	};

	generateClassName() {
		let {className} = this.props;
		let classes = [];
		classes.push(className);

		return classes;
	}

	generateClassString() {
		return this.generateClassName().join(' ');
	}

	renderIcon() {
		const {isOpen} = this.state;
		const {hasIcon, openIcon, closeIcon} = this.props;

		let icon = isOpen ? closeIcon : openIcon ;

		return hasIcon ? <Icon key={0} icon={icon}/> : null;
	}

	renderLabel() { 
		const {isOpen} = this.state;
		const {openLabel, closeLabel} = this.props;

		return isOpen ? closeLabel : openLabel;
	}

	renderChildren() {
		const {children} = this.props;

		let kids = React.Children.map(children, child=>child) || [];

		let label = this.renderLabel();
		if(label) kids.unshift(label);

		let icon = this.renderIcon();
		if(icon) kids.unshift(icon);

		return kids;
	}

	render() {
		const {id, isOpen, children, component, toggle, target, className, hasIcon, openIcon, closeIcon, openLabel, closeLabel, onShow, onShown, onHide, onHidden, ...props} = this.props;

		return React.createElement(component, {
			id,
			children: this.renderChildren(),
			className: this.generateClassString(),
			'data-toggle': toggle,
			'data-target': target,
			...props
		});
	}
}