import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { scaleRadial, group as d3Group, arc } from "d3";

import CurvedLabel from "./CurvedLabel";
import StraightLabel from "./StraightLabel";
import mapToArray from "../../utils/mapToArray";
import getDocStyle from "../../utils/getDocStyle";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";
import { getSVGLetterCoords } from "@/discovery-panel/utils/getSVGLetterCoords";
import { downloadSelector } from "@/discovery-panel/redux/selectors/download.selector";

const Labels = ({ opacityList, groupList }) => {

  const { multiComparisons, association, associations, group } =
    useSelector(filterSelector);
  const { metaboliteCoords } = useSelector(explorerSelector);
  const viewPort = useSelector(viewPortSelector);
  const { isDownloading } = useSelector(downloadSelector);

  const yScale = scaleRadial()
    .domain([0, multiComparisons.length])
    .range([viewPort.innerRadius, viewPort.outerRadius]);

  const associationLabel = associations.filter((d) => {
    return d.value === association;
  })[0].key;

  const grouping = useMemo(() => {
    const arcPath = arc()
      .innerRadius(viewPort.innerRadius * 0.999)
      .outerRadius(viewPort.innerRadius * 0.999)
      .startAngle((d) => d.value[0].angle - 0.002)
      .endAngle((d) => d.value[d.value.length - 1].angle + 0.035 / 1.1);

    return mapToArray(d3Group(metaboliteCoords, (d) => d[group])).map(
      (d) => {
        return {
          ...d,
          arc: arcPath(d),
        };
      }
    );
  }, [metaboliteCoords, group, viewPort.innerRadius]);

  const comparisonsPathOffset = useMemo(() => {
    return grouping.length > 15 ? 3.7 : 2.5;
  }, [grouping]);

  const truncate = (input, max) => {
    if (input.length > max) {
       return input.substring(0, max) + '...';
    }
    return input;
 };

  const def = !groupList.includes("default");
  const color = !def
    ? getDocStyle("var(--cold-gray-darkest)")
    : getDocStyle("var(--metabolon-base)");

  return (
    <>
      {multiComparisons.map((d, i) => {
        return (
          <CurvedLabel
            key={i}
            innerRadius={yScale(i)}
            outerRadius={yScale(i)}
            startAngle={-viewPort.middleSpace / comparisonsPathOffset}
            endAngle={viewPort.middleSpace / comparisonsPathOffset}
            center={viewPort.smallScreen ? 0 : -3}
            id={"comparison-curved-path" + i}
            text={isDownloading ? "" : truncate(d, 30)}
            i={i}
            path="arc"
            fontSize="12px"
            stroke="var(--cold-gray-light)"
          />
        );
      })}

      {isDownloading && multiComparisons.map((d, i) => {
        const associationsFontSize = viewPort.width > 1750 ? 24 : 12;
        const associationsFontSpacing = viewPort.width > 1750 ? 12 : 8;
        const letterCoords = getSVGLetterCoords(0, 0, yScale(i) + 5, truncate(d, 30), associationsFontSpacing);
        return <>
          {letterCoords?.map((coord, index2) => {
            return (
              <text
                x={coord?.x}
                y={coord?.y}
                key={index2}
                fontSize={associationsFontSize}
								fontFamily="sans-serif"
                transform={`rotate(${coord.rotation} ${coord?.x} ${coord?.y})`}
              >
                {coord.character.toUpperCase()}
              </text>
            );
          })}
        </>;
      })}

      <CurvedLabel
        innerRadius={viewPort.barInnerRadius}
        outerRadius={viewPort.barInnerRadius}
        startAngle={-viewPort.middleSpace / 2.5}
        endAngle={viewPort.middleSpace / 2.5}
        id="bar-curved-path"
        text={associationLabel}
        path="arrow"
        center={3.5}
        stroke={"var(--cold-gray-light)"}
      />

      <CurvedLabel
        innerRadius={viewPort.metabolitesRadius / 3}
        outerRadius={viewPort.metabolitesRadius / 3}
        startAngle={-0.27}
        endAngle={0.27}
        id="metabolites-curved-path"
        text={"Metabolites"}
        center={3.5}
        path={"arrow"}
        stroke="var(--cold-gray-light)"
      />

      {opacityList.includes("default") === false &&
        opacityList[0] === "association" &&
        metaboliteCoords.map((d, i) => {
          return (
            opacityList.includes(d.chemical_id) && (
              <StraightLabel
                key={"straightlabel" + d.chemical_id}
                innerRadius={viewPort.outerRadius * 1.01}
                outerRadius={viewPort.outerRadius * 1.01}
                startAngle={d.angle + viewPort.blockWidth}
                endAngle={d.angle}
                text={d.metabolite}
                direction={"out"}
              />
            )
          );
        })}

      {grouping.map((d, i) => {
        return (
          (groupList.includes(d.key) || groupList.includes("default")) && (
            <g key={i}>
              <path d={d.arc} fill="none" stroke={color} strokeWidth="0.5" />
              <StraightLabel
                innerRadius={viewPort.outerRadius * 1.01}
                outerRadius={viewPort.outerRadius * 1.4}
                startAngle={d.value[0].angle + 0.009}
                endAngle={d.value[d.value.length - 1].angle + 0.035 / 1.5}
                text={d.key}
                fontSize={12}
                fontWeight={"800"}
                key={d.key}
                color={color}
                direction="out"
                showLine={true}
                lineColor={color}
                active={def}
              />
            </g>
          )
        );
      })}
    </>
  );
};

export default memo(Labels);
