'use strict';

import React from 'react';
import { useSelector } from 'react-redux';
import classnames from "classnames";
import PropTypes from 'prop-types';
import { enrichmentFilterSelector } from '../../../../redux/selectors/enrichmentFilter.selector.js';
import DynamicTable from '../../../../components/controls/DynamicTable.jsx';
import DynamicTableHelper from '../../../../components/controls/DynamicTableHelper.jsx';
import DynamicTableCell from '../../../../components/controls/DynamicTableCell.jsx';
import { NavButton, Label } from '../../../../components/index.jsx';
import ContentSwitch from '../../../../containers/ContentSwitch.jsx';
import { EnrichmentFilter } from '../../../../containers/EnrichmentFilter.jsx';

import './Enrichment.scss';

const Enrichment = (props) => {
	const { minCount } = useSelector(enrichmentFilterSelector);

	const createEnrichmentBarField = (name, header) => {
		const {count, sig, maxEnrichment} = props;

		let divisor = count !== 0 ? sig/count : 0;
		return {
			name, header,
			className: "penrich",
			comparator: 'numeric',
			renderer: (data, fieldName, n) => {
				let val = data[fieldName];
				let w1 = divisor == 0 ? '0%' : Math.min(70, 70*(val)/maxEnrichment)+'%';
				return (
					<DynamicTableCell className="penrich" key={n}>
							<div title={val.toFixed(2)} style={{width:w1}} className="bar green"/>
						<Label>{val.toFixed(2)}</Label>
					</DynamicTableCell>
				);
			}
		};
	};

	let {onSubpathwaySelect, webWorker} = props,
		subProps = sub => ({id:sub.name});
	const {count, sig, pathways, sampleSet, statsId} = props;
	return (

		<ContentSwitch className={classnames("Enrichment", props.className)} parentView={"PageEvent_PathwayExplorer_Enrichment"} auditIds={["Highlights","Enrichment Data"]}
			nav={[
				<NavButton key={0}>Highlights</NavButton>,
				<NavButton key={1}>Enrichment Data</NavButton>,
			]}
			content={[
				<div className="highlights-view" key={0}>
					<EnrichmentFilter />
					<DynamicTable
						datasetId={statsId}
						primaryKey="name"
						sorter="enrichment"
						sortDir={-1}
						index={1}
						onRowClick={onSubpathwaySelect}
						dataset={pathways}
						rowProps={subProps}
						webWorker={webWorker}
						listHeight={450}
						canExport={true}
						exportFilename={sampleSet.sample.name+"_PathwayEnrichment_Highlights"}
						viewName={"PageEvent_PathwayExplorer_PathwayEnrichment_Highlights"}
						className="highlights fixed"
						fields={[
							DynamicTableHelper.createField('name', 'Subpathway'),
							createEnrichmentBarField('enrichment', {text:'Enrichment', title:'(k/m)/((n-k)/(N-m))', className:'penrich'}),
							DynamicTableHelper.createField('count', {text:'Count', title:'total # of detected metabolites in pathway', className:'p-total'}, {comparator: "numeric"}),
						]}
						filters={[
							dataRow => dataRow.count >= minCount,
						]}
						wrapRows={true}
					/>
				</div>,
				<div className="data-view" key={1}>
					<DynamicTable
						datasetId={statsId}
						primaryKey="name"
						sorter="sig"
						sortDir={-1}
						index={1}
						onRowClick={onSubpathwaySelect}
						dataset={pathways}
						rowProps={subProps}
						webWorker={webWorker}
						listHeight={450}
						canExport={true}
						exportFilename={sampleSet.sample.name+"_PathwayEnrichment_Data"}
						viewName={"PageEvent_PathwayExplorer_PathwayEnrichment_EnrichmentData"}
						className="data-table fixed"
						fields={[
							DynamicTableHelper.createField('name', 'Category'),

							DynamicTableHelper.createField('sig', {text:'k', title:'# of significant metabolites in pathway', className:'p-significant'}, {comparator: "numeric", className:"p-significant"}),//show custom title

							DynamicTableHelper.createField('count', {text:'m', title:'total # of detected metabolites in pathway', className:'p-total'}, {comparator: "numeric", className:"p-total"}),

							DynamicTableHelper.createStaticField('sig-total', {text:'n', title:'total # of significant metabolites', className:'significant'}, sig),

							DynamicTableHelper.createStaticField('count-total', {text:'N', title:'total # of detected metabolites', className:'total'}, count),

							DynamicTableHelper.createField('enrichment', {text:'Enrichment', title:'(k/m)/((n-k)/(N-m))', className:'enrichment'}, {comparator: "numeric", className:'enrichment'}),//show custom title
						]}
						wrapRows={true}
					/>
				</div>,
				<Label key={1}>Enrichment Plot Placeholder</Label>
			]}
		>
			<h3>Pathway Enrichment</h3>
		</ContentSwitch>
	);
};

Enrichment.propTypes = {
	compounds: PropTypes.array,
	pathways: PropTypes.array,
	count: PropTypes.number,
	sig: PropTypes.number,
	maxEnrichment: PropTypes.number,
	minCount: PropTypes.number,
	statsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Enrichment.defaultProps = {
	compounds: [],
	pathways: [],
	count: 1,
	sig: 0,
	maxEnrichment: 0,
	minCount: 5,
	statsId: null,
	webWorker: true,
};

export default Enrichment;