import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Arrow from "../svg/Arrow";
import * as styles from "./table.module.scss";

const TableHeader = ({
  columns,
  headerClick,
  sortedOn,
  sortDirection,
  splitHeader,
}) => {
  const onClick = useCallback(
    (d) => () => {
      headerClick(d.key);
    },
    [headerClick]
  );

  return (
    <div className={classnames(styles.header)}>
      {columns.map((d, i) => {
        const h = d.key ? `<p> ja </p>` : "";
        return (
          <div
            key={d.key}
            style={{
              width: d.width + "%",
              textAlign: d.align,
            }}
            onClick={onClick(d)}
          >
            {d.key ? (
              d.value.includes(" ") && splitHeader ? (
                <p>
                  {d.value.split(" ")[0]} <br /> {d.value.split(" ")[1]}
                </p>
              ) : (
                <p>{d.value}</p>
              )
            ) : (
              ""
            )}
            {sortedOn === d.key && <Arrow direction={sortDirection} />}
          </div>
        );
      })}
    </div>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
  headerClick: PropTypes.func,
  sortedOn: PropTypes.string,
  sortDirection: PropTypes.bool,
  splitHeader: PropTypes.bool,
};

TableHeader.defaultProps = {
  columns: [],
  headerClick: "",
  sortedOn: "key",
  sortDirection: false,
  splitHeader: false,
};

export default TableHeader;
