'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import urljoin from 'url-join';
import { Route, Switch, withRouter } from 'react-router-dom';
import '../../../theme/theme.css';
import '../../../theme/css/material-icons.css';
import { Button, DynamicModalButton, Icon, NavBar, NavButton } from '@/components/index.jsx';
import RefreshIndicator from '@/components/controls/RefreshIndicator.jsx';
import { projectsFetchData, projectsFetchDataAdmin, projectsFetchDataByProjectCode } from '@/redux/actions/projects';
import { managedProjectsFetchData } from '@/redux/actions/managedProjects';
import { filesFetchDataAdmin, uploadAttachment } from '@/redux/actions/files';
import { sampleSetFetchDataAdmin } from '@/redux/actions/sampleSetData';
import PublishingProjectFiles from './Deliverables/PublishingProjectFiles.jsx';
import AdminSamplesets from '../../admin/PublishingCenter/Statistics/AdminSamplesets.jsx';
import PublishContactsContainer from './Contacts/PublishContactsContainer.jsx';
import AdminProjectInfo from './Info/AdminProjectInfo.jsx';
import AttachmentUploader from './Deliverables/AttachmentUploader.jsx';
import SettingsMap from '@/utils/SettingsMap.js';
import './PublishingContainer.scss';
import { Logger, Log } from '@/utils/Logger';
const logger = new Logger();
import * as enums from '@/enums/Enums';
import { isGuid } from '@/utils/StringUtils';
import { Auditor } from '@/components/util/Auditor.jsx';

export class PublishingContainer extends Auditor {

	constructor(props) {
		super(props);
		SettingsMap.initMap();
		this.state = {
			canViewProjectMilestoneDetails: true,
			projectCode: '',
			project: {},
			files: [],
			sampleSets: undefined,
			errormsg: '',
			showUpload: true,
			selectedFiles: [],
			disableUploadBtn: true,
			saving: false,
			...super.state
		};

		this.refreshFiles = this.refreshFiles.bind(this);
		this.setCurrentProject = this.setCurrentProject.bind(this);
		this.loadProjectFromAPI = this.loadProjectFromAPI.bind(this);
		this.logError = this.logError.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.loadProjectFromAPI();
		this.showHideUploadBtn();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_isMounted = false;
	showHideUploadBtn() {

		if (window.location.href.indexOf("samplesetsadmin") > 0
			|| window.location.href.indexOf("contacts") > 0
			|| window.location.href.indexOf("info") > 0) {
			this.setState({ showUpload: false });
		}
		else {
			this.setState({ showUpload: true });
		}

	}

	loadProjectFromAPI() {

		/* istanbul ignore next */
		let self = this;
		//need to call to get admin projects for users
		this.props.fetchAdminUserProjects().then(projects => {
			if (!projects) { this.setState({ errormsg: "Project Failed to load, Please Refresh the page." }); return; }
			let project = this.setCurrentProject(projects.response);
			if (!project) {
				let projectCode = self.props.projectCode;
				return this.getDTSProject(projectCode);
			}
			if (this._isMounted) {
				this.checkCurrentUserIsProjectOwner(project.projectId);
				this.setState({ currentProject: project, files: project.files || [], disableUploadBtn: false });
			}
		});
	}

	checkCurrentUserIsProjectOwner(projectId) {
		if (!this.props.currentUser.data) return;
		let isProjectOwner = this.props.currentUser.data.projects.some(project => project.projectId == projectId);
		this.setState({ saveButtonDisabled: !isProjectOwner });
	}

	//IF you have DTS role, then you can view any project even if you don't own it.
	getDTSProject(projectCode) {

		if (isGuid(projectCode)) {

			this.props.fetchDataAdmin(projectCode).then(projects => {

				let project = projects.response;
				if (!project) return this.props.history.push('/home/permissions');
				this.setState({ currentProject: project, files: project.files || [], disableUploadBtn: false, projectID: project.projectId });
				this.checkCurrentUserIsProjectOwner(project.projectId);
				//We have the one-off project in state, now let's fetch the original project list for the user
				this.props.fetchDataAdmin(projectCode);
			});
		} else {
			this.props.fetchProjectByProjectCode(projectCode).then(projects => {

				let project = projects.response;
				if (!project) return this.props.history.push('/home/permissions');
				this.setState({ currentProject: project, files: project.files || [], disableUploadBtn: false, projectID: project.projectId });
				this.checkCurrentUserIsProjectOwner(project.projectId);
				//We have the one-off project in state, now let's fetch the original project list for the user
				this.props.fetchDataAdmin(projectCode);
			});
		}
	}

	setupState(project, projects) {
		this.setState({ projects: projects, project: project, projectCode: project.projectCode }, () => {
			//Log Project View
			this.auditLogProjectView(project.projectId);
			this.logProjectInfo(project);
			/**This line of code should skip the api call but for some reason, it breaks the cytoscape component? */
			//if(this.props.sampleSets.length > 0) return this.setState({sampleSets: this.props.sampleSets});
		});

	}

	setCurrentProject(projects) {
		if (projects && projects.length > 0) {
			let projectID = localStorage.getItem('ProjectId');
			if (!projectID) return;
			return projects.filter((project) => project.projectId == projectID)[0];
		}

	}

	onSampleSetSelect = (sampleSetIndex, statsId) => {
		//console.log("update!", sampleSetIndex, statsId);
		this.setState({ sampleSetIndex, statsId });
	};

	setSaving = (value) => {
		this.setState({ saving: value });
	};

	/** Render Router components, ignoring routes */
	/* istanbul ignore next */
	renderFiles = () => { return <PublishingProjectFiles onCancelClick={this.cancel} files={this.state.files} project={this.state.currentProject} projectId={this.state.projectId} />; };
	/* istanbul ignore next */
	renderSamplesetsAdmin = () => {
		return (
			<AdminSamplesets
				setSaving={this.setSaving}
				onCancelClick={this.cancel}
				project={{ ...this.state.currentProject }}
				projectId={this.state.projectId}
			/>
		);
	};
	/* istanbul ignore next */
	renderContactsAdmin = () => { return (<PublishContactsContainer onCancelClick={this.cancel} project={this.state.currentProject} projectId={this.state.projectId} />); };
	/* istanbul ignore next */
	renderProjectInfoAdmin = () => { return (<AdminProjectInfo project={this.state.currentProject} projectId={this.state.projectId} />); };

	/**Logging */
	logViewChange(subviewName) {
		//Log subview change
		let logFilterChange = new Log();
		logFilterChange.SetLevel(enums.AppInsightLogLevel.EVENT);
		logFilterChange.SetName('Projects_Subview_change');
		logFilterChange.AddProperty('SubviewSelected', subviewName);
		logger.doLog(logFilterChange);
	}

	logError(errormsg, errorCode) {
		//Log Error
		let logError = new Log();
		logError.SetLevel(enums.AppInsightLogLevel.ERROR);
		logError.SetName('ProjectContainer');
		logError.AddProperty('UserId', this.props.currentUser.data.userId);
		logError.AddProperty('ProjectId', this.state.project.projectId);
		logError.AddProperty('ErrorMsg', errormsg);
		logError.AddProperty('errorCode', errorCode);
		logger.doLog(logError);
	}

	auditLogProjectView(projectId) {
		this.state.logger.logProjectView(projectId);
	}

	logProjectInfo(project) {
		//Log subview change
		let logInfo = new Log();
		logInfo.SetLevel(enums.AppInsightLogLevel.EVENT);
		logInfo.SetName('ProjectView');
		logInfo.AddProperty('ProjectId', project.projectId);
		logInfo.AddProperty('PublishedDate', project.publishDate);
		logger.doLog(logInfo);
	}

	/**Loading Indicators */
	loadingStyle = {
		container: {
			display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto',
			right: '-29px'

		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	RefreshIndicatorPublishLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="publishLoading" color="disabled"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/></Button>
		</div>
	);


	publishPreviewClick = () => {
		localStorage.setItem("previewMode", this.state.currentProject.projectId);
		if (this.props.onClickPreview) this.props.onClickPreview();
		this.props.history.push('/home/project/' + this.state.currentProject.projectId);
	}

	cancel = () => {
		localStorage.removeItem("previewMode");
		this.props.history.push('/home/project/' + this.state.currentProject.projectId);
	}


	refreshFiles(projectId) {
		//refresh files
		return new Promise((resolve, reject) => {
			this.props.fetchfilesDataAdmin(projectId).then(files => {
				//console.log("FILES", files);
				this.setState({ files: files.response });
				resolve(true);
			});
		});
	}

	render() {

		const { match } = this.props;
		const { editMode, publishPreviewMode, showEditPublish } = this.state;

		let displayTitle = "";

		if (this.state.project.projectTitle) {
			displayTitle = this.state.project.projectTitle.replace(this.state.project.projectCode, '');
		}
		const sampleSetAdmin = window.location.href.indexOf("samplesetsadmin") > 0;

		return (
			<div className={sampleSetAdmin ? "PublishingContainer altWidth" : "PublishingContainer"}>
				<div className="Title">PUBLISHING CENTER</div>
				{super.render()}

				<div className="HeaderFunctions">
					<div className="NavBar">
						<NavBar className="filter list" text="tertiary">
							<NavButton to={match.url} exact>Project Files</NavButton>
							<NavButton to={urljoin(match.url, "/samplesetsadmin")} >Statistics</NavButton>
							<NavButton to={urljoin(match.url, "/contacts")} >Contacts</NavButton>
							<NavButton to={urljoin(match.url, "/info")} >Project Overview</NavButton>
						</NavBar>
					</div>
					{this.state.showUpload && (
						<div className="Actions">
							<DynamicModalButton
								className="UploadButton"
								disabled={this.state.disableUploadBtn}
								onClose={() => { this.refreshFiles() }}
								modalId={`modal-upload-files`}
								modalProps={{ title: "Project files upload" }}
								component={AttachmentUploader}
								componentProps={{
									projectId: this.state.currentProject?.projectId,
									refreshFiles: this.refreshFiles,
									projectCode: this.state.currentProject?.projectCode
								}}>
								Upload
							</DynamicModalButton>
						</div>
					)}
				</div>
				<div>
					<div className="clientPreviewBTN">
						<Button disabled={this.state.saveButtonDisabled || this.state.saving} onClick={this.publishPreviewClick} >
							<Icon size={'large'} icon={'eye'} />&nbsp;
							Preview &#38; Publish</Button>
					</div>
				</div>
				<Switch>
					<Route path={match.url} exact render={this.renderFiles} />
					<Route path={urljoin(match.url, "/samplesetsadmin")} render={this.renderSamplesetsAdmin} />
					<Route path={urljoin(match.url, "/contacts")} render={this.renderContactsAdmin} />
					<Route path={urljoin(match.url, "/info")} render={this.renderProjectInfoAdmin} />

				</Switch>
			</div>
		);
	}
}

PublishingContainer.propTypes = {
	/** Project to display*/
	project: PropTypes.object,
	/** Show sidebar */
	showSideBar: PropTypes.bool,
	/** SideBar View to show */
	sideBarPage: PropTypes.number,
	/** function to hide side bar */
	onClickHideSideBar: PropTypes.func
};

PublishingContainer.defaultProps = {
	showSideBar: true,
	sideBarPage: -1,
	onClickHideSideBar: (item, e) => {
		// eslint-disable-next-line
		console.log('onClickHideSideBar Click Event not defined!', item);
	}
};

const mapStateToProps = state => {
	return {
		projects: Object.values(state.projects.data),
		hasErrored: state.projects.projectsHasErrored,
		isLoading: state.projects.projectsIsLoading,

		sampleSets: state.sampleSetData.data,
		sampleSetHasErrored: state.sampleSetData.sampleSetDataHasErrored,
		sampleSetIsLoading: state.sampleSetData.sampleSetDataIsLoading,
	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchData: (query) => dispatch(projectsFetchData(query)),
		fetchProjectByProjectCode: (query) => dispatch(projectsFetchDataByProjectCode(query)),
		fetchDataAdmin: (query) => dispatch(projectsFetchDataAdmin(query)),
		fetchAdminUserProjects: (query) => dispatch(managedProjectsFetchData(query)),
		fetchfilesDataAdmin: (params) => dispatch(filesFetchDataAdmin(params)),
		fetchSampleSetsDataAdmin: (params) => dispatch(sampleSetFetchDataAdmin(params)),
		uploadAttachment: (projectId, file, comment) => dispatch(uploadAttachment(projectId, file, comment))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishingContainer));