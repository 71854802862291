'use strict';
export { Log } from '../models/Log';
import * as config from '../config/env.config';
import { strMapToObj } from './StringUtils.js';
import store from '../store/store';
import { useRef } from 'react';

export class Logger {

	doLog(msg) {

		const msgLevel = msg.level;
		const msgName = msg.name;
		msg.AddProperty("env", config.ENV);
		let msgProperties = strMapToObj(msg.properties);
		msgProperties.UserId = this.getUserId();
		let client;
		if (client === undefined) {
			 
			//console.log("ApplicationInsights Not Initialized");
			return;
		}

		switch (msgLevel) {
			case 'Event':
				//client.trackEvent(msgName,msgProperties);
				break;
			case 'Error':
				//client.trackException(msgName,'',msgProperties);
				break;
			// case Fatal:
			// 	//client.trackException(msg.getError());
			// 	break;
			// case Warn:
			// 	//client.trackTrace(categories[0].name, SeverityLevel.Warning);
			// 	break;
			// case Info:
			//     break;
			// case Trace:
			//  break;
			default:
				//client.trackTrace(categories[0].name, SeverityLevel.Information);
				break;
		}
	}

	getUserId() {

		const { currentUser } = store.getState();

		if (currentUser.data != null) {
			//return hashString(currentUser.data.userId);
			return currentUser.data.userId;
		}
		return null;
	}
}


export const useLogger = () => {
	const loggerRef = useRef(new Logger());
	return loggerRef.current;
};
