import React from 'react';
import { useHistory } from 'react-router-dom';
import './NotFound.scss';

export const NotFound = () => {
	const history = useHistory();

	const onBack = () => {
		history.go(-3);
	};

	return (
		<div className="NotFoundContainer">
			<div>
				<div className="info">Whoops! Page Not Found</div>
				<div>Return to the <a data-testid="not-found-back" onClick={onBack}>previous page.</a></div>
			</div>
		</div>
	);
}
export default NotFound;