import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import * as config from '../../config/env.config';
import * as enums from '../../enums/Enums';
import { useAuth } from '@/hooks/auth.hook';
import { useLocalAuditLogger } from '@/hooks/useLocalAuditLogger';

const timeout = config.IDLETIMER_IDLESTATUS_EXPIRED * 1000
const promptBeforeIdle = config.IDLETIMER_COUNTDOWN * 1000

const IdleTimer = ({ history }) => {
  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const auth = useAuth();
  const idleMessageRef = useRef('');
  const auditLogger = useLocalAuditLogger();

	const logout = useCallback(() => {
    idleMessageRef.current = "You have been logged out!";
    auditLogger.logUserAction(enums.AUDITLOG_USERACTION_TYPES.SESSIONEXPIRED);
    auth.logout();
    history.push("/login/sessionexpired");
  }, [history, auth, auditLogger]);

  const onIdle = () => {
    logout()
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

	useEffect(() => {
		switch(state) {
			case "Active":
				idleMessageRef.current = "";
				break;
			case "Prompted":
				idleMessageRef.current = `You will be logged out in ${remaining} secconds`;
				break;
			default:
				idleMessageRef.current = "";
		}
	}, [state, remaining])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <div style={{position: 'absolute', top: '50px'}}>
      {open && idleMessageRef.current}
    </div>
  )
}

export default IdleTimer