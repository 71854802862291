import { current } from "@reduxjs/toolkit";
import { group } from "d3";
import { clone } from "../../../utils/ObjectUtils";
import { dividendDivisorSwitch } from "../../../utils/StringUtils";

import mapToArray from "../../utils/mapToArray";
import { BIOSTRESS, MICROBIOME, LIVERFIBROSIS, KIDNEYFUNCTION } from "@/enums/Enums";

const widthThreshold = 850;
const maxComparisonCount = 6;
const tabs = [
  {
    url: "discoverypanel-explainer",
    label: "Discovery Panel Report",
  },
  {
    url: "impact-exploration",
    label: "Impact Explorer",
  },
  {
    url: "profile",
    label: "Profile Finder",
  },
  {
    url: "data-integration",
    label: "Data & Integration",
  },
  {
    url: "support",
    label: "Support",
  },
];

const groups = [
  // {
  //   key: "Metabolite Origin",
  //   value: "plasma_grouping",
  // },
  {
    key: "Microbiome Pathway",
    value: "microbiome_pathway",
  },
  {
    key: "Biological Stress",
    value: "microbiome_pathway",
  },
  {
    key: "Liver Fibrosis",
    value: "microbiome_pathway",
  },
  {
    key: "Kidney Function",
    value: "microbiome_pathway",
  },
];

const associations = {
  [MICROBIOME]: [{
    key: "Biological Themes",
    value: "Biological",
    label_short: "Theme",
  },
  {
    key: "Disease & Disorders",
    value: "Disease",
    label_short: "Condition",
  },
  {
    key: "Pathways",
    value: "Pathway",
    label_short: "Pathway",
  }],
  [LIVERFIBROSIS]: [
  {
    key: "Pathways",
    value: "Pathway",
    label_short: "Pathway",
  }],
  [BIOSTRESS]: [
  {
    key: "Pathways",
    value: "Pathway",
    label_short: "Pathway",
  }],
  [KIDNEYFUNCTION]: [
    {
      key: "Pathways",
      value: "Pathway",
      label_short: "Pathway",
    }]
};

const groupKeys = {
  [BIOSTRESS]: "Biological Stress",
  [MICROBIOME]: "Microbiome Pathway",
  [LIVERFIBROSIS]: "Liver Fibrosis",
  [KIDNEYFUNCTION]: "Kidney Function"
};

export const initializeFilter = (productType=MICROBIOME) => ({
  associations:associations[productType],
  groups,
  maxComparisonCount,
  tabs,
  widthThreshold,
  activeTab: undefined,
  association: !productType ? "Pathway" : productType===MICROBIOME ? associations[productType][2].value : associations[productType][0].value,
  comparisons: "",
  comparison: "",
  group: groups[0].value,
  initialized: false,
  multiComparisons: [],
  pValue: 0.05,
  invertedFoldChange: false,
  search: {
    key: "default",
    name: "",
    type: "",
  },
  searchMatches: [],
});

export const filterReducer = () => ({
  initFilter: (state) => {
    const currentState = current(state);
    const { static: results } = currentState.results;
    const { productType } = currentState;
    const filter = initializeFilter(productType);
    const { maxComparisonCount } = filter;
    filter.comparisons = mapToArray(group(results, (d) => d.comparison));
    filter.multiComparisons = filter.comparisons.slice(0, maxComparisonCount).map((d) => d.key);
    filter.comparison = filter.comparisons[0]?.key || "";
    const groupKey = groupKeys[productType] || "Microbiome Pathway";
    filter.groups = filter.groups.filter(group => group.key === groupKey);
    filter.group = filter.groups[0].value;
    filter.initialized = true;
    state.filter = { ...filter };
  },

  setActiveTab: (state, action) => {
    state.filter.activeTab = action.payload;
  },
  setFilterAssociations: (state, action) => {
    state.filter.associations = action.payload;
  },
  setFilterAssociation: (state, action) => {
    state.filter.association = action.payload;
  },
  setComparison: (state, action) => {
    state.filter.comparison = action.payload;
  },
  setInvertedFoldChange: (state, action) => {
    state.filter.invertedFoldChange = action.payload;
    const {comparison, comparisons, multiComparisons }= current(state).filter;
    const newComp = clone(comparisons);
    newComp.map(c=>{
      c.key = dividendDivisorSwitch(c.key);
    });
    state.filter.comparisons = newComp;
    state.filter.comparison = dividendDivisorSwitch(comparison);
    state.filter.multiComparisons = multiComparisons.map(c=>dividendDivisorSwitch(c));
  },
  setGroup: (state, action) => {
    state.filter.group = action.payload;
  },
  setMultiComparisons: (state, action) => {
    const { filter } = current(state);
    const { maxComparisonCount } = filter;
    const { length } = action.payload;

    if (length <= maxComparisonCount && length > 0) 
      state.filter.multiComparisons = action.payload;
  },
  setPValue: (state, action) => {
    state.filter.pValue = action.payload;
  },
  setSearch: (state, action) => {
    state.filter.search = action.payload;
  },
  setSearchMatches: (state, action) => {
    state.filter.searchMatches = action.payload;
  },
});