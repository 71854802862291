'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { hashString } from '../../utils/StringUtils';
import { Logger, Log } from '../../utils/Logger';
import { AppInsightLogLevel } from '../../enums/Enums';
const logger = new Logger();

const Mailto = ({ to, bcc, subject, body, children }) => {
	let recipient0 = to[0] || '';
	let recipients = to.length > 1 ? to.slice(1).join(', ') : '';
	let blinds = bcc.length > 0 ? bcc.join(', ') : '';
	let href = `mailto:${recipient0}`;

	let n = 0;
	let add = (label, content) => {
		if (!content) return;

		href += `${n ? '&' : '?'}${label}=${content}`;
		n++;
	};

	add('cc', recipients);
	add('bcc', blinds);
	add('Subject', subject);
	add('Body', body);

	let logEmail = () => {
		//Log Email Send change
		let logEmailSend = new Log();
		logEmailSend.SetLevel(AppInsightLogLevel.EVENT);
		logEmailSend.SetName('Email_Send');
		logEmailSend.AddProperty('Email_Subject', subject);
		logEmailSend.AddProperty('Email_To_Address', hashString(recipient0));
		logger.doLog(logEmailSend);
	};

	return (
		<a data-testid="mailto" onClick={logEmail} href={href}>{children}</a>
	);
};

Mailto.propTypes = {

	/** An array of recipient emails */
	to: PropTypes.array,

	/** An array of bcc emails */
	bcc: PropTypes.array,

	/** The email subject */
	subject: PropTypes.string,

	/** The email body */
	body: PropTypes.string,
};

Mailto.defaultProps = {
	to: [],
	bcc: [],
	subject: '',
	body: '',
};

export default Mailto;