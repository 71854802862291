'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/Button.jsx';
import Checkbox from './Checkbox.jsx';

import './DynamicTableCell.scss';

export default class DynamicTableCheckCell extends Checkbox {

	static propTypes = {
		cellClass:PropTypes.string
	};

	static defaultProps = {
		labelColor: 'primary',
	};

	constructor(props) {
		super(props);
	}
	
	render() {
		const {cellClass} = this.props;
		return (
			<div className={"DynamicTableCell check-cell table-cell" +(cellClass ? " "+cellClass : "")} >
				{super.render()}
			</div>
		);

	}
}