import { current } from "@reduxjs/toolkit";
import { scaleThreshold } from 'd3';

import colorScale from "../../utils/colorScale";
import roundPvalue from "../../utils/roundPvalue";
import detectMainEffect from "../../utils/detectMainEffect";
import getDocStyle from "../../utils/getDocStyle";
import { dividendDivisorSwitch } from "../../../utils/StringUtils";

const pvalSize = scaleThreshold()
  .domain([0.001, 0.01, 0.05, 0.06])
  .range([8, 5, 2, 0.05]);

const getStaticResults = (results, metabolites) => {
  return results.map((d, index) => {
    return {
      ...d,
      metaboliteInfo: metabolites.filter(
        (m) => d.chemical_id === m.chemical_id
      )[0] || "",
      size: pvalSize(d.p_value),
      fold_change_category:
        d.fold_change > 1
          ? "Upregulated"
          : d.fold_change < 1
          ? "Downregulated"
          : "No Change",
      pValueDisplay: roundPvalue(d.p_value),
      foldChangeSort: d.fold_change
        ? d.fold_change < 1
          ? 1 - d.fold_change
          : d.fold_change
        : null,
      foldChangeDisplay: d.fold_change ? d.fold_change.toFixed(2) : null,
      color: getDocStyle("cold-gray-lightest"),
      opacity: "1",
      significant: false,
      type: "metabolite",
      comparison_number: d.comparison
        .split("/")[0]
        .trim()
        .replace(/[^0-9]+/g, ""),
      comparison_groups:
      d.comparison.split("-")[0].trim() +
        "-" +
        d.comparison.split("/")[1].split("-")[0].trim(),
    };
  });
};

const dynamicResults = ({staticResults, activeTab, comparison, multiComparisons, pValue, invertedFoldChange}) => {
  // if inverted fold change is active comparsion with d.comparison would fail
  const oldComparison = invertedFoldChange ? dividendDivisorSwitch(comparison) : comparison;
  const oldMultiComparison = invertedFoldChange ? multiComparisons.map(c=>dividendDivisorSwitch(c)) : multiComparisons;
  const data = staticResults.filter((d) =>
    activeTab === "discoverypanel-explainer"
      ? oldComparison === d.comparison
      : activeTab === "impact-exploration"
      ? oldMultiComparison.includes(d.comparison)
      : true
  );

  return data.map((d) => {
    const filter = data.filter(
      (e) =>
        e.chemical_id === d.chemical_id && e.p_value <= pValue && d.detected
    );
    const foldChange = invertedFoldChange && d.fold_change ? 1 / d.fold_change : d.fold_change;
    return {
      ...d,
      comparison: invertedFoldChange ? dividendDivisorSwitch(d.comparison) : d.comparison,
      color:
        (d.p_value <= pValue
          ? colorScale(foldChange)
          : getDocStyle("cold-gray-light")) ||
        getDocStyle("cold-gray-lightest"),
        fold_change_category:
        foldChange > 1
          ? "Upregulated"
          : foldChange < 1
          ? "Downregulated"
          : "No Change",
      pValueDisplay: roundPvalue(d.p_value),
      foldChangeSort: foldChange
        ? foldChange < 1
          ? 1 - foldChange
          : foldChange
        : null,
      foldChangeDisplay: foldChange ? foldChange.toFixed(2) : null,
      significant: d.p_value <= pValue,
      metabolite_significant: filter.length,
      metabolite_upregulated: filter.filter(
        (e) => e.fold_change_category === "Upregulated"
      ).length,
      metabolite_downregulated: filter.filter(
        (e) => e.fold_change_category === "Downregulated"
      ).length,
      mainEffect: detectMainEffect(d, pValue),
    };
  });
};

export const resultsSetter = (state, action) => {
  const { metabolites } = current(state);
  const { payload } = action;
  state.results.error = payload.error;
  state.results.raw = payload.raw.results;
	state.sample.raw.matrixType = payload.raw.matrix_type;
  state.results.static = getStaticResults(payload.raw.results.concat(), metabolites.list);
};

export const dynamicResultsSetter = (state) => {
  const { results, filter } = current(state);

  const { activeTab, comparison, multiComparisons, pValue, invertedFoldChange } = filter;
  state.results.list = dynamicResults({staticResults: results.static, activeTab, comparison, multiComparisons, pValue, invertedFoldChange});
  state.results.isLoaded = true;
};