import { current } from "@reduxjs/toolkit";

export const  initializeViewPort = () => ({
  joinKey: "chemical_id",
  outerKey: "chemical_id",
  innerKey: "association",
  middleSpace: 1.2,
  angleOffset: Math.PI / 2,
  rotateOffset: Math.PI / 180,
  radiusOffset: 3,
  blockWidth: 0.03,
  notSelectedOpacity: 0.35,
  width: 1,
  height: 1,
});

export const viewPortReducer = () => ({
  updateViewPort: (state, action) => {
    let width = action.payload.width || 500;
    let height = action.payload.height || 300;
    const smallScreen = width <= 850;
    const mobile = width <= 400;

    const heightOffset = width * 0.9 < height ? 0.6 : 1.05;
    const heightAdj = height * heightOffset;
    const radiusAdj = mobile ? 2 : smallScreen ? 2.5 : width > 1600 ? 2.3 : 2.75;
    const outerRadius = width / radiusAdj;
    const associationRadius = outerRadius * 1.65;
    const labelsOffset = 1.2;

    state.viewPort = {
      ...initializeViewPort(),
      height: heightAdj,
      width: width,
      viewBox: [[-width / 2 * labelsOffset, -heightAdj / 2, width * labelsOffset, heightAdj]],
      outerRadius: outerRadius,
      innerRadius: outerRadius / 1.3,
      metabolitesRadius: outerRadius * 2.17,
      associationRadius: associationRadius * 1.055,
      barInnerRadius: (associationRadius * 0.66) / 1.9,
      barOuterRadius: (associationRadius * 0.43) / 1.6,
      smallScreen,
      mobile,
    };
  }
});