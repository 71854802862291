'use strict';
import React from 'react';
import './EmptyState.scss';

export const EmptyState = () => {

	return (
		<div className="OuterContiner">
			<div> <img className="hourglass" /></div>
			{/* <div className="Quote">"Patience is bitter, but its fruit is sweet."</div> */}
			<br />
			<div className="SubText">
				<div>- Jean-Jacques Rousseau</div>
				<div>This is where your data and reports will arrive when analysis and interpretation is completed.</div>
				<div><span style={{ fontWeight: 'bold' }}>Questions?</span> Contact your <a>study director</a> or explore our <a href="/resources">Resources</a> section.</div>
			</div>
		</div>
	);
};



export default EmptyState;