import { Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const Link = ({
	href,
	target,
	children,
	exact,
	component = 'p',
	external = false,
	...props
}) => {

	return (
		<Typography component={component} {...props}>
			{external && <a href={href} target={target}>{children}</a>}
			{!external && <NavLink exact={exact} to={href} target={target}>
				{children}
			</NavLink>}
		</Typography>
	);
}

export default Link