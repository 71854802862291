'use strict';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './AdminContainer.scss';
import UserManagementContainer from './userManagement/UserManagementContainer.jsx';
import {NavBar, NavButton} from '../../components/index.jsx';
import urljoin from 'url-join';


export class AdminContainer extends Component {
	
	constructor(props) {
		super(props); 
		this.state = {
		};
	}

	/** Render Router components, ignoring routes */
	renderUserManagement  = /* istanbul ignore next */() => {  return <UserManagementContainer history={this.props.history} />;}

	render() {
		const {match} = this.props;
		return (
			<div className="AdminContainer app-content">
                <div>
                <NavBar history={this.props.history} controlled={true} size="small">
					<NavButton to={urljoin(match.url, `/usermanagement`)}  >User Management</NavButton>
				</NavBar>
                </div>
				<div className="main">
					<Switch >
						<Route  path={match.url+ "/"} render={this.renderUserManagement} />
					</Switch> 
				</div>
			</div>
		);
	}
}

export default withRouter(AdminContainer);
