'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../utils/ComponentUtils.js';
import './NavBar.scss';

/** A navbar component */
export class NavBar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {activeIndex:props.activeIndex};
	}

	generateClassNames(base) {
		const {color, size, className} = this.props;
		let classNames = [color, size];
		classNames.unshift(base);
		if(className) classNames.unshift(className);

		return classNames;
	}

	renderChildren(activeIndex) {
		const {children, size, color, text, controlled} = this.props;
		
		let n = 0, active;
		return React.Children.map(children, (child, index) => {
			if(utils.getType(child) === 'NavButton') {
				active = n === activeIndex;
				let textColor = text;
				if(active) textColor = 'tertiary';
				return React.cloneElement(child, {onClick:this.onClick.bind(this, n++, child.props.onClick), size, color,  active});
			}
			return child;
		}, this);
	}

	onClick(n, onChildClick) {
		//console.log(n);
		const {onClick, controlled} = this.props;
		if(!controlled) this.setState({activeIndex:n});

		if(onClick) onClick(n);
		if(onChildClick) onChildClick();
	}

	render() {
		return (
			<div className={this.generateClassNames('NavBar').join(' ')}>
				{this.renderChildren(this.props.controlled ? this.props.activeIndex : this.state.activeIndex)}
			</div>
		);
	}
}

NavBar.propTypes = {
	/** The color of the nav buttons */
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The color of the button text */
	text: PropTypes.oneOf(['', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The size of the nav buttons */
	size: PropTypes.oneOf(['small', 'normal', 'large']),

	/** The index of the active nav button */
	activeIndex: PropTypes.number,

	/** Whether the Navbar is controlled externally */
	controlled: PropTypes.bool,

	/** Invoked when a nav button is clicked */
	onClick: PropTypes.func,
};

NavBar.defaultProps = {
	size: 'normal',
	color: 'primary',
	activeIndex: -1,
	controlled: false,
};

export default NavBar;