import React from "react";
import { IconButton as MUIIconButton } from '@mui/material';

export const IconButton = ({
  icon,
  size="medium",
  iconProps,
  iconFill="transparent",
  iconColor="inherit",
  testId="iconButton",
  ...props }) => {
  const iconSizes = {
    small: 16,
    medium: 20,
    large: 24,
  }

	const Icon = icon;
	
  return (
    <MUIIconButton className='custom-icon-button' size={size} data-testid={testId} {...props}>
      <Icon sx={{display: "flex"}} icon={icon} size={iconSizes[size]} fill={iconFill} color={iconColor} {...iconProps}/>
    </MUIIconButton>
  )
}

export default IconButton