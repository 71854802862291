import { all } from "redux-saga/effects";
import { currentUserSagas } from "./currentUser.saga";
import { discoveryPanelsSagas } from "../../discovery-panel/redux/sagas/discoveryPanel.saga";

function* rootSagas() {
  try {
    yield all([
      //currentUserSagas(),
      discoveryPanelsSagas(),
    ]);
  } catch (e) {  
    console.error("Error in rootSagas: ", e.message);
  }
}

export { rootSagas };
