export function clamp(val, min, max) {
	return Math.max(min, Math.min(max, val)); 
}

/*export function toFixed(num) {
	if(!num) return num;
    return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
}*/

export function toFixed(num, digits=2) {
	if(num === null || num === undefined) return '0.'+'0'.repeat(digits);

	let sign = num/Math.abs(num) > 0 ? '' : '-';
	num = Math.abs(num);
	let whole = Math.floor(num), //get whole portion
		decimal = (Math.pow(10, digits)*(Number(num) - whole)); //move decimal digits left of decmial

	decimal = Math.round(decimal); //round to nearest whole number
  
	if(decimal >= Math.pow(10, digits)) {
		whole += Math.floor(decimal)/Math.pow(10, digits);
		decimal = '0'.repeat(digits);
	}
	else decimal = '0'.repeat(Math.max(0, digits - String(decimal).length)) + decimal; //restore leading zeroes

	let n = sign+whole+'.'+decimal;
	//if(n.length === 3) console.log(num, n, whole, decimal);

	return n;
}

export function distance(x1, y1, x2, y2) {
	let dx = x2-x1, dy = y2-y1; 
	return Math.sqrt(dx*dx + dy*dy);
}