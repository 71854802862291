import { createSelector } from '@reduxjs/toolkit';


export const publishSummarySelector = createSelector(
    (state) => state,
    (state) => state.publishSummary
);


export const publishSummaryIsLoadingSelector = createSelector(
    publishSummarySelector,
    (publishSummary) => publishSummary.publishSummaryIsLoading
);


export const publishSummaryHasErroredSelector = createSelector(
    publishSummarySelector,
    (publishSummary) => publishSummary.publishSummaryHasErrored
);


export const publishSummaryDataSelector = createSelector(
    publishSummarySelector,
    (publishSummary) => publishSummary.data
);
