'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './EmailContacts.scss';
import {Contact, Label} from '../index.jsx';

const EmailContacts = ({ sectionName, listOfContacts, showLetterAvatar, subjectInfo }) => {
    
	return (
		<div className="EmailContacts">
            <div><Label size={'small'} color={'tertiary'}>{sectionName}</Label></div>
            <div>
                {listOfContacts.map((contact, index) => 
                    <div key={index}>   
                        <Contact firstName={contact.firstName} lastName={contact.lastName} subjectInfo={subjectInfo} title={contact.title} emailAddress={contact.email} showAvatar={showLetterAvatar} />
                    </div>
                )}
            </div>
		</div>
	);
};

EmailContacts.propTypes = {
	/** Section Name for list of contacts */
    sectionName: PropTypes.string, 
    /** Array of Contacts to Display */
    listOfContacts: PropTypes.array, 
    /** Show Letter Avatar */
    showLetterAvatar: PropTypes.bool,
    /** Subject Info for email */
    subjectInfo: PropTypes.string
};

EmailContacts.defaultProps = {
    sectionName: "", 
    listOfContacts: [],
    showLetterAvatar: false,
    subjectInfo: ""
};

export default EmailContacts;