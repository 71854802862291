'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import urljoin from 'url-join';
import { withRouter } from 'react-router-dom';
import { isGuid } from '../../utils/StringUtils';
import '../../theme/theme.css';
import('../../theme/css/material-icons.css');
import { Button, Toaster } from '../../components/index';
import RefreshIndicator from '../../components/controls/RefreshIndicator';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import {
	projectsFetchData,
	projectsPublishDataAdmin,
	publishSummaryFetchData,
	fetchUnpublishedProjectUsers,
	projectsFetchDataByProjectCode,
	projectsFetchDataAdminNoRedux,
	projectsFetchPublishingStatus,
	projectsFetchProjectsUpdateStatus,
	ProjectIsEmpty,
} from '../../redux/actions/projects';
import { getPathwayAnimalMap, getPathwayPlantMap } from '../../redux/actions/pathwayMapData';
import { sampleSetDataFetchDataByProjectID, sampleSetFetchDataAdmin } from '../../redux/actions/sampleSetData';
import PublishingConfirmation from '../admin/PublishingCenter/PublishingConfirmation_.jsx';
import SettingsMap from '../../utils/SettingsMap';
import { convertDate } from '../../utils/DateUtils';
import './ProjectContainer.scss';
import { Logger, Log } from '../../utils/Logger';
const logger = new Logger();
import * as enums from '../../enums/Enums';
import { toast } from 'react-toastify';
import { Auditor } from '@/components/util/Auditor';
import { COE } from '../routes';
import { Project } from './Project';
import { withPermissions } from '@/hooks/permissions.hook';

export class ProjectContainer extends Auditor {

	constructor(props) {
		super(props);
		SettingsMap.initMap();
		this.state = {
			canViewProjectMilestoneDetails: true,
			projectCode: '',
			projectId: '',
			currentProjectId: '',
			displayTabs: false,
			project: {},
			sampleSets: undefined,
			publishMode: false,
			enablePublishingButton: true,
			showPublishButton: false,
			showUnSavedFilesEditChanges: false,
			isPublishLoading: false,
			errormsg: '',
			unpublishedUsers: [],
			showPublishingDialog: false,
			publishSummaryLoading: false,
			isHovering: false,
			isEmpty: false,
			publishingHubConnection: null,
			publishingStatus: '',
			lastUpdateDate: "",
			ismlimsUpdating: false,
			projectEmptyState: undefined,
			noAccessMessage: '',
			...super.state
		};

		this.checkUserHasRole = props.checkUserHasRole;
		this.checkUserHasPermissions = props.checkUserHasPermissions;
		this.auditLogProjectView = this.auditLogProjectView.bind(this);
		this.onClickEdit = this.onClickEdit.bind(this);
		this.onClickPublishingMode = this.onClickPublishingMode.bind(this);
		this.onClickPreview = this.onClickPreview.bind(this);
		this.onClickPublish = this.onClickPublish.bind(this);
		this.onClickExitPreview = this.onClickExitPreview.bind(this);
		this.onClickCancelPublishingMode = this.onClickCancelPublishingMode.bind(this);
		this.setupPreviewMode = this.setupPreviewMode.bind(this);
		this.onClickSampleSetsAdmin = this.onClickSampleSetsAdmin.bind(this);
		this.getUnpublishedUsers = this.getUnpublishedUsers.bind(this);
		this.getCurrentPublishingStatus = this.getCurrentPublishingStatus.bind(this);
		this.getCurrentProjectUpdateStatus = this.getCurrentProjectUpdateStatus.bind(this);
		this.loadCurrentProject = this.loadCurrentProject.bind(this);
		this.loadProjectFromAPI = this.loadProjectFromAPI.bind(this);
		this.setupState = this.setupState.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.loadCurrentProject();
	}

	componentDidUpdate(prevProps) {
		if (this.hasUrlParamChanged(prevProps, this.props)) {
			setTimeout(() => this.loadCurrentProject());
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_isMounted = false

	hasUrlParamChanged(prevProps, currProps) {
		return (
			prevProps.match.params.projectCode &&
			currProps.match.params.projectCode &&
			prevProps.match.params.projectCode !== currProps.match.params.projectCode
		);
	}

	setProjectPublishState(project) {

		this.props.fetchProjectIsEmpty(project.projectId).then(response => {
			let isDTS = this.checkUserHasRole(enums.ROLES.DTS);
			if (response) {
				this.setState({ projectEmptyState: true, displayTabs: isDTS ? true : false });
			}
			else {
				this.setState({ projectEmptyState: false, displayTabs: true });
			}
		});
	}

	getLastDataRefresh(projectId) {
		if (!this.checkUserHasRole(enums.ROLES.DTS)) { return; }
		this.props.fetchAdminProject(projectId).then((response) => {
			let project = response;
			this.setState({ lastUpdateDate: project.lastUpdateDate }); // {customerSynopsis:"testSynopsis"}});
		}).catch();
	}

	getCurrentPublishingStatus(projectId, lastPublishDate) {
		if (!projectId || this.state.ismlimsUpdating) return;

		this.props.fetchProjectStatusById(projectId).then((result) => {

			let status = result;
			let currentProjectPublishingStatus = 'Unknown';
			let enablePublishingButton = true;
			let errormsg = '';
			switch (status) {
				case "None":
					if (!lastPublishDate) { currentProjectPublishingStatus = 'UnPublished'; }
					else {
						currentProjectPublishingStatus = 'Project Published';
					}
					enablePublishingButton = true;
					break;
				case "Waiting":
					currentProjectPublishingStatus = "Project " + this.state.projectCode + " waiting to be published.";
					enablePublishingButton = false;
					break;
				case "Executing":
					currentProjectPublishingStatus = "Project " + this.state.projectCode + " is publishing.";
					enablePublishingButton = false;
					break;
				default:
					currentProjectPublishingStatus = "Unknown";
					break;
			}
			this.setState({ publishingStatus: currentProjectPublishingStatus, enablePublishingButton: enablePublishingButton });
		});
	}

	getCurrentProjectUpdateStatus(projectId) {
		if (!projectId) return;

		return this.props.fetchProjectUpdateStatusById(projectId).then((result) => {

			let status = result;
			let currentProjectPublishingStatus = this.state.publishingStatus;
			let enablePublishingButton = true;
			let errormsg = '';
			let mlimsStatus = false;
			let isProjectOwner = this.getIsProjectOwner(projectId);
			switch (status) {
				case true:
					currentProjectPublishingStatus = "Project " + this.state.projectCode + " is updating.";
					enablePublishingButton = false;
					errormsg = "Publishing Center disabled while updating.";
					mlimsStatus = true;
					break;
				default:
					enablePublishingButton = isProjectOwner ? true : false;
					errormsg = "";
					break;
			}

			this.setState({ ismlimsUpdating: mlimsStatus, publishingStatus: currentProjectPublishingStatus, enablePublishingButton: enablePublishingButton, errormsg: errormsg });
		});
	}

	//IF you have DTS role, then you can view any project even if you don't own it.
	getDTSProject(projectCode) {
		if (isGuid(projectCode)) {
			this.props.fetchAdminProject(projectCode).then(projects => {
				let project = projects;
				if (!project) return this.props.history.push('/home/permissions');

				this.getUnpublishedUsers(project.projectId);
				localStorage.setItem("ProjectId", project.projectId);
				this.setupPreviewMode(project.projectId).then((result) => {
					this.setupState(project, projects.response);
				}).catch();
				this.setShowPublishButton(project.projectId);
				//We have the one-off project in state, now let's fetch the original project list for the user
				//	this.props.fetchData();
				this.getLastDataRefresh(project.projectId);
				this.getCurrentProjectUpdateStatus(project.projectId).then(() => {
					this.getCurrentPublishingStatus(project.projectId, project.lastPublishDate);
				});
				this.setState({ projectId: project.projectId, projectCode: project.projectCode });
				this.getLastDataRefresh(project.projectId);
			}).catch();
		}
		else {
			this.props.fetchProjectByProjectCode(projectCode).then(projects => {
				let project = projects.response;
				if (!project) return this.props.history.push('/home/permissions');

				this.getUnpublishedUsers(project.projectId);
				localStorage.setItem("ProjectId", project.projectId);
				this.setupPreviewMode(project.projectId).then((result) => {
					this.setupState(project, projects.response);
				});
				this.setShowPublishButton(project.projectId);
				//We have the one-off project in state, now let's fetch the original project list for the user
				this.props.fetchData();
				this.getLastDataRefresh(project.projectId);
			}).catch();
		}
	}

	loadCurrentProject() {
		//Skip the api call if the data is already loaded in the store
		if (this.props.projects?.length > 0) {
			let project = this.setCurrentProject(this.props.projects);
			const projectCode = this.props.match.params.projectCode;
			if (!project) {
				if (this.checkUserHasRole(enums.ROLES.DTS)) {
					return this.getDTSProject(projectCode);
				} else {
					return this.props.history.push(`/home/project/${projectCode}/${COE}/`);
				}
			}

			if (this._isMounted) {
				this.getUnpublishedUsers(project.projectId);
				localStorage.setItem("ProjectId", project.projectId);
				this.setupPreviewMode(project.projectId).then((result) => {
					this.setupState(project, project);
				}).catch();
				this.setShowPublishButton(project.projectId);
			}
			this.getCurrentProjectUpdateStatus(project.projectId).then(() => {
				this.getCurrentPublishingStatus(project.projectId, project.lastPublishDate);
			});
			this.setState({ projectId: project.projectId, projectCode: project.projectCode });
			this.getLastDataRefresh(project.projectId);
			return;
		}
		//Fetch if we don't already have the data populated
		this.loadProjectFromAPI();
	}
	async loadProjectFromAPI() {
		let projects = [];
		try {
			projects = await this.props.fetchData();
		} catch (e) {
			 
			console.error("ProjectContainer loadProjectFromAPI fetchData", e);
		}

		let project = this.setCurrentProject(projects.response);
		if (!project) {
			if (this.checkUserHasRole(enums.ROLES.DTS)) {
				let projectCode = this.props.match.params.projectCode;
				return this.getDTSProject(projectCode);
			} else {
				return this.props.history.push('/home/permissions');
			}
		}
		if (this._isMounted) {
			this.getUnpublishedUsers(project.projectId);
			localStorage.setItem("ProjectId", project.projectId);
			this.setShowPublishButton(project.projectId);
			try {
				await this.setupPreviewMode(project.projectId);
			} catch (e) {
				 
				console.error("ProjectContainer loadProjectFromAPI setupPreviewMode", e);
			}
			this.setupState(project, projects.response);
			try {
				await this.getCurrentProjectUpdateStatus(project.projectId);
			} catch (e) {
				 
				console.error("ProjectContainer loadProjectFromAPI getCurrentProjectUpdateStatus", e);
			}
			this.getCurrentPublishingStatus(project.projectId, project.lastPublishDate);
		}
		this.getLastDataRefresh(project.projectId);
		this.setState({ projectId: project.projectId, projectCode: project.projectCode });
	}

	/* loadProjectFromAPI(){
		// istanbul ignore next
		this.props.fetchData().then( projects => {
			let project = this.setCurrentProject(projects.response);
			if(!project){ 
				if(this.checkUserHasRole(enums.ROLES.DTS)){
					let projectCode = this.props.match.params.projectCode;
					return this.getDTSProject(projectCode);
				} else {				
					return this.props.history.push('/home/permissions');}
			}
			if(this._isMounted){
				this.getUnpublishedUsers(project.projectId);
				localStorage.setItem("ProjectId", project.projectId);
				this.setShowPublishButton(project.projectId);
				this.setupPreviewMode(project.projectId).then(() =>{
					this.setupState(project, projects.response);
				}).catch();
				this.getCurrentProjectUpdateStatus(project.projectId).then( () =>{
					this.getCurrentPublishingStatus(project.projectId, project.lastPublishDate);
				});
			}
			this.getLastDataRefresh(project.projectId);
			this.setState({projectId:project.projectId, projectCode: project.projectCode});
			
		}).catch();
	} */

	setupPreviewMode(projectId) {
		if (!this.checkUserHasRole(enums.ROLES.DTS)) { Promise.resolve(true); }
		let previewMode = localStorage.getItem("previewMode");
		if (previewMode == projectId) {
			this.setState({ publishPreviewMode: true, projectId: projectId });
			this.getPublishSummaryData(projectId);
			this.getAdminProject(projectId);
		}
		return Promise.resolve(true);
	}

	getAdminProject(projectId) {
		this.props.fetchAdminProject(projectId).then((response) => {
			this.setState({ adminProjectData: response }); // {customerSynopsis:"testSynopsis"}});
		}).catch();
	}

	async getPublishSummaryData(projectId) {
		this.setState({ publishSummaryLoading: true });

		const users = await this.props.fetchUnpublishedProjectUsers(projectId);
		let unpubUserList = [];
		if (users) {
			unpubUserList = users.filter(user => user.email);
			this.setState({ unpublishedUsers: [...unpubUserList] });
		}

		this.getUnpublishedUsers(projectId).then((contacts) => {
			this.props.fetchPublishSummary(projectId).then((res) => {
				this.setState({ publishSummaryLoading: false });
			}).catch((err) => {
				// throw the caught error here
				throw err;
			});
		}).catch((err) => {
			// throw the caught error here
			throw err;
		});

	}

	setShowPublishButton(projectId) {
		let canPublish = this.checkUserHasPermissions("canEditPublishProjects");
		let projectOwner = this.getIsProjectOwner(projectId);
		this.loadAccessMessage(projectOwner);
		this.setState({ showPublishButton: canPublish, enablePublishingButton: projectOwner, });
	}

	getIsProjectOwner(projectId) {
		return this.checkCurrentUserIsProjectOwner(projectId);
	}

	loadAccessMessage(projectOwner) {
		if (!projectOwner) {
			this.setState({ noAccessMessage: "Publishing Center Unavailable: must be Project Owner/Study Manager." });
		}
	}

	checkCurrentUserIsProjectOwner(projectId) {
		return this.props.currentUser.data.projects.some(project => project.projectId == projectId);
	}

	setupState(project, projects) {
		this.setProjectPublishState(project);
		this.setState({ projects: projects, project: project, projectCode: project.projectCode }, () => {
			//Log Project View
			this.auditLogProjectView(project.projectId);
			this.logProjectInfo(project);
			if (this.state.publishPreviewMode || (!project.lastPublishDate && this.checkUserHasRole(enums.ROLES.DTS))) {

				this.props.fetchSampleSetsDataAdmin(project.projectId).then(result => {
					let sampleSetData = result.response;
					if (sampleSetData) this.setState({ sampleSets: sampleSetData });
				}).catch();
			} else {
				this.props.fetchSampleSetData(project.projectId).then(result => {
					let sampleSetData = result.response;
					if (sampleSetData) this.setState({ sampleSets: sampleSetData });
				}).catch();
			}

			/**This line of code should skip the api call but for some reason, it breaks the cytoscape component? */
			//if(this.props.sampleSets.length > 0) return this.setState({sampleSets: this.props.sampleSets});

			let plantReader = new FileReader(),
				animalReader = new FileReader(),
				self = this;

			plantReader.onload = () => {
				let data = JSON.parse(plantReader.result),
					map = data;
				if (map) this.setState({ plants: map });
			};

			animalReader.onload = () => {
				let data = JSON.parse(animalReader.result),
					map = data;
				if (map) this.setState({ animals: map });
			};

			self.props.fetchPlantMap().then(response => {
				self.props.fetchAnimalMap().then(response => {
					animalReader.readAsText(response);
				}).catch();
				plantReader.readAsText(response);
			});
		});
	}

	setCurrentProject(projects) {
		if (projects && projects.length > 0) {
			if (isGuid(this.props.match.params.projectCode)) {
				let project = projects.filter((project) => project.projectId == this.props.match.params.projectCode)[0];
				if (!project || !project.projectCode) { return; }
				this.props.history.push(`/home/project/${project.projectCode}`);
				return projects.filter((aProject) => aProject.projectCode == project.projectCode)[0];
			}
			else {
				return projects.filter((project) => project.projectCode == this.props.match.params.projectCode)[0];
			}
		}
	}

	navigateToSubView = (n) => {
		switch (n) {
			case 0:
				this.props.history.push(this.props.match.url);
				break;
			case 1:
				this.props.history.push(this.props.match.url + "/statstable");
				break;
			case 2:
				this.props.history.push(this.props.match.url + "/pathwayexplorer");
				break;
			default:
				this.props.history.push("");
		}
	};

	/**Logging */
	logViewChange(subviewName) {
		//Log subview change
		let logFilterChange = new Log();
		logFilterChange.SetLevel(enums.AppInsightLogLevel.EVENT);
		logFilterChange.SetName('Projects_Subview_change');
		logFilterChange.AddProperty('SubviewSelected', subviewName);
		logger.doLog(logFilterChange);
	}

	auditLogProjectView(projectId) {
		this.state.logger?.logProjectView(projectId);
	}

	logProjectInfo(project) {
		//Log subview change
		let logInfo = new Log();
		logInfo.SetLevel(enums.AppInsightLogLevel.EVENT);
		logInfo.SetName('ProjectView');
		logInfo.AddProperty('ProjectId', project.projectId);
		logInfo.AddProperty('PublishedDate', project.publishDate);
		logger.doLog(logInfo);
	}

	//** Click Handlers */
	onClickEdit() {
		this.props.history.push(urljoin(this.props.match.url, "/publishTest"));
	}
	onClickPublishingMode() {
		this.props.history.push(this.props.match.url + "/publishingcenter");
	}

	onClickPreview() {
		localStorage.setItem("previewMode", this.state.project.projectId);
		this.setState({ loading: true, publishMode: false, publishPreviewMode: true, projectClone: { ... this.state.project } });
		this.props.fetchSampleSetsDataAdmin(this.state.project.projectId).then((result) => {
			let sampleSetData = result.response;
			if (sampleSetData) this.setState({ loading: false, sampleSets: sampleSetData });
		}).catch();
	}

	onClickPublish() {
		//this.setState({isPublishLoading:true});
		this.publishProject();
		this.state.logger.logProjectPublish(this.state.project.projectId);
	}

	onClickExitPreview() {
		this.setState({ loading: true, publishPreviewMode: false }, () => {
			localStorage.removeItem("previewMode");
			this.props.history.push(urljoin(this.props.match.url, "/publishingcenter"));
		});
	}

	onClickCancelPublishingMode() {
		this.setState({ publishMode: false, publishPreviewMode: false });
		localStorage.removeItem("publishMode");
		localStorage.removeItem("previewMode");

		//We are now returning to Results page
		this.props.history.push(urljoin(this.props.match.url));
	}

	onClickSampleSetsAdmin() {
		this.props.history.push(urljoin(this.props.match.url, "/samplesetsadmin"));
	}


	/**Helper Methods */
	publishProject() {
		localStorage.removeItem("publishMode");
		localStorage.removeItem("previewMode");
		let updateObject = { "instance": this.state.project.projectId, "comment": "PortalUI: Publish from portal" };
		this.setState({ showPublishingDialog: true });
		this.props.projectsPublishDataAdmin(updateObject).then((response) => {
			if (response && !response.ok) {
				let msg = `Status: ${response.status}, Changes have failed to publish.`;
				this.setState({ errormsg: 'ERROR: ' + msg });
				this.setState({ isSavingFiles: false, isPublishLoading: false, showPublishingDialog: false, publishingStatus: 'Project Publish Failed' });
				return;
			}
			this.setState({ isPublishLoading: false, showPublishingDialog: false, publishingStatus: 'Project Publish Starting ...' });
			this.onClickCancelPublishingMode();
			this.loadProjectFromAPI();
		}).catch();
	}

	publishButton = () => (
		<div>
			{this.state.isPublishLoading ? <span /> : <Button alt2={true} onClick={this.onClickExitPreview} >Exit Preview</Button>}
			{this.state.isPublishLoading ? this.RefreshIndicatorPublishLoading() : <Button onClick={this.onClickPublish} >Publish</Button>}<br />
			<span className="lastPublishDate">Last Published: {convertDate(this.state.project.lastPublishDate, 'MMM DD YYYY')} </span>
			<span className="lastPublishDate">Last Data Refresh: {convertDate(this.state.project.lastUpdatedDate, 'MMM DD YYYY')} </span>
		</div>
	);

	loadingStyle = {
		container: {
			display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			width: 'auto'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '42px'
		}
	};

	RefreshIndicatorPublishLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="publishLoading" color="disabled"> <RefreshIndicator
				size={'medium'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/></Button>
		</div>
	);

	publishLoadingStyle = {
		container: {
			position: 'relative',
			textAlign: 'center',
			paddingTop: '10px'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
		},
	};

	PublishIndicatorLoading = () => (
		<div style={this.publishLoadingStyle.container}>
			<LoadingSpinner />
		</div>
	);

	clickPublishModal = (data) => {
		return () => {
			DynamicModalSingleton.updateState({
				component: PublishingConfirmation,
				renderClose: false,
				componentProps: {
					project: this.state.project,
					onClickPublish: this.onClickPublish,
				},
				modalProps: {
					bodyClass: ".PublishingConfirmationContainer",
				}
			}, DynamicModalSingleton.show);
		};
	}

	async getUnpublishedUsers(projectId) {
		if (!this.checkUserHasRole(enums.ROLES.DTS)) return;

		const users = await this.props.fetchUnpublishedProjectUsers(projectId);

		let unpubUserList = [];
		if (users) {
			unpubUserList = users.filter(user => user.email);
			this.setState({ unpublishedUsers: [...unpubUserList] });
		}
	}

	RefreshIndicatorPublishButtonLoading = () => (
		<div style={this.loadingStyle.container}>
			<Button className="downloadAll" color={'white'} alt={true}> <RefreshIndicator
				color={'primary'}
				size={'large'}
				left={5}
				top={0}
				status="loading"
				style={this.loadingStyle.refresh}
			/> </Button>
		</div>
	);

	getProjectId() {
		if (!this.checkUserHasRole(enums.ROLES.DTS)) { return; }
		return <label>{this.state.project.projectId}</label>;
	}

	showToaster(title, body) {
		toast(<Toaster title={title} body={body} />);
	}

	render() {
		const { match, isLoading } = this.props;
		const { adminProjectData, publishSummaryLoading, enablePublishingButton, errormsg, lastUpdateDate, noAccessMessage, project, publishPreviewMode, showPublishButton, showPublishingDialog, projectEmptyState, loading, publishingStatus, sampleSets, sampleSetIndex, statsId, animals, plants } = this.state;
		
		/* console.log(projectEmptyState, checkUserHasOneRole(enums.ROLES.BASIC), this.checkUserHasRole(enums.ROLES.COE));
		if (projectEmptyState && (checkUserHasOneRole(enums.ROLES.BASIC) || this.checkUserHasRole(enums.ROLES.COE))) {
			//this.props.history.push(`/home/project/${match.params.projectCode}/${COE}`);
			return <Redirect to={`/home/project/${match.params.projectCode}/${COE}`} />;
		} */
		
		let displayTitle = project.customerProjectTitle;
		let displayHeader = true;
		let displayProjectId = this.getProjectId();
		if (!displayTitle && project.projectTitle) {
			displayTitle = project.projectTitle.replace(project.projectCode, '');
		}

		if (adminProjectData && adminProjectData.customerProjectTitle)
			displayTitle = adminProjectData.customerProjectTitle;

		if (window.location.href.indexOf("publishingcenter") > 0 ||
			window.location.href.indexOf(COE) > 0) {
			displayHeader = false;
		}

		return (
			<>
				{super.render()}
				<Project
					className={this.props.className}
					match={match}
					loading={loading}
					isLoading={isLoading}
					adminProjectData={adminProjectData}
					displayHeader={displayHeader}
					displayProjectId={displayProjectId}
					displayTitle={displayTitle}
					enablePublishingButton={enablePublishingButton}
					errormsg={errormsg}
					lastUpdateDate={lastUpdateDate}
					noAccessMessage={noAccessMessage}
					project={project}
					projectCode={project.projectCode}
					projectId={project.projectId}
					projectEmptyState={projectEmptyState}
					publishPreviewMode={publishPreviewMode}
					publishingStatus={publishingStatus}
					publishSummaryLoading={publishSummaryLoading}
					showPublishButton={showPublishButton}
					showPublishingDialog={showPublishingDialog}
					PublishIndicatorLoading={this.PublishIndicatorLoading}
					RefreshIndicatorPublishButtonLoading={this.RefreshIndicatorPublishButtonLoading}
					onClickPublishingMode={this.onClickPublishingMode}
					clickPublishModal={this.clickPublishModal}
					onClickExitPreview={this.onClickExitPreview}
					showToaster={this.showToaster}
					onClickPreview={this.onClickPreview}
					sampleSets={sampleSets}
					sampleSetIndex={sampleSetIndex}
					statsId={statsId}
					plants={plants}
					animals={animals}
				/>
			</>
		);
	}
}

ProjectContainer.propTypes = {
	/** Project to display*/
	project: PropTypes.object,
	/** Show sidebar */
	showSideBar: PropTypes.bool,
	/** SideBar View to show */
	sideBarPage: PropTypes.number,
	/** function to hide side bar */
	onClickHideSideBar: PropTypes.func
};

ProjectContainer.defaultProps = {
	showSideBar: true,
	sideBarPage: -1,
	onClickHideSideBar: (item, e) => {
		 
		console.log('onClickHideSideBar Click Event not defined!', item);
	}
};

const mapStateToProps = state => {
	return {
		projects: Object.values(state.projects.data),
		hasErrored: state.projects.projectsHasErrored,
		isLoading: state.projects.projectsIsLoading,
		sampleSets: state.sampleSetData.data,
		sampleSetHasErrored: state.sampleSetData.sampleSetDataHasErrored,
		sampleSetIsLoading: state.sampleSetData.sampleSetDataIsLoading,
		publishSummary: state.publishSummary.data,
		publishSummaryHasErrored: state.publishSummaryHasErrored,
		publishSummaryIsLoading: state.publishSummaryIsLoading,
	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchData: () => dispatch(projectsFetchData()),
		fetchDataByProjectCode: (query) => dispatch(projectsFetchData(query)),
		fetchProjectIsEmpty: (query) => dispatch(ProjectIsEmpty(query)),
		fetchAdminProject: (projectId) => dispatch(projectsFetchDataAdminNoRedux(projectId)),
		fetchProjectByProjectCode: (query) => dispatch(projectsFetchDataByProjectCode(query)),
		fetchSampleSetData: (params) => dispatch(sampleSetDataFetchDataByProjectID(params)),
		fetchPlantMap: () => dispatch(getPathwayPlantMap()),
		fetchAnimalMap: () => dispatch(getPathwayAnimalMap()),

		projectsPublishDataAdmin: (params) => dispatch(projectsPublishDataAdmin(params)),
		fetchPublishSummary: (params) => dispatch(publishSummaryFetchData(params)),
		fetchSampleSetsDataAdmin: (params) => dispatch(sampleSetFetchDataAdmin(params)),
		fetchUnpublishedProjectUsers: (query) => dispatch(fetchUnpublishedProjectUsers(query)),
		fetchProjectStatusById: (query) => dispatch(projectsFetchPublishingStatus(query)),
		fetchProjectUpdateStatusById: (query) => dispatch(projectsFetchProjectsUpdateStatus(query))
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withPermissions(ProjectContainer)));