import React, { Component } from 'react';
import { Logger } from '../utils/Logger';
import './VerifyEmail.scss';

const logger = new Logger();

export class VerifyEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorState: false
		};
	}

	backTo = () => {
		this.props.history.push("/home");
		//this.props.history.go(-4);
	};

	render() {
		return (

			<div className="VerifyContainer">

				<div>
					<div className="info">Almost there, please check your email to verify your account.  Once verified, you will be returned to the portal.
					</div>
					<div><b>Questions?</b> Contact <a href={`mailto:support@metabolon.com?subject=Email Verification`}>support</a> or return to the <a data-testid="verifyemail-back" onClick={this.backTo}>previous page.</a></div>
				</div>

			</div>
		);
	}
}

export default VerifyEmail;