import { toFixed } from './MathUtils.js';
import SettingsMap from './SettingsMap.js';
import { formatDisplayString, makeNodeId } from './StringUtils.js';
import CytoscapeStyle from '../components/visualization/CytoscapeStyle.js';

export const nilId = "00000000-0000-0000-0000-000000000000";

/** Static Sample set utility functions */
export default class SamplesetUtils {

	static processStats(sampleSet, statsId) {
		let stats = [], activeStats = [], selection = null, current = statsId;
		if (sampleSet) {
			stats = [...sampleSet.stats];
			activeStats = stats.map(stat => stat.statsId);
			selection = activeStats.concat();
			if (activeStats && activeStats.length) current = activeStats[0];
		}

		let statsDict = {}, statsIds = [], trends = {};
		stats = stats.map(s => {
			let stat = { ...s };
			stat.studyType = SamplesetUtils.determineStudyType(stat);

			statsIds.push(stat.statsId);
			trends[stat.statsId] = { blank: [], trendUp: [], trendDown: [], sigUp: [], sigDown: [], nil: [], dead: [], pathways: {}, compounds: {}, count: 0, sig: 0 };
			statsDict[stat.statsId] = stat;
			return stat;
		});
		return { activeStats, current, selection, stats, statsDict, statsIds, trends };
	}

	static processMetabolites(setMetabolites, stats, trends, statsDict, testField = "foldChange", drawCompounds = false) {
		let superPathways = {}, subPathways = {}, metaboDeck = {};
		let graphElements = { nodes: [], edges: [] };

		let metabolites = setMetabolites.map((metabolite) => {
			if (metabolite.superPathway && metabolite.subPathway) {
				let superId = makeNodeId(metabolite.superPathway);
				let subId = makeNodeId(metabolite.subPathway);

				let superPath = superPathways[superId];
				if (!superPath) {
					superPath = CytoscapeStyle.createSuperNodeData(superId, metabolite.superPathway);

					superPathways[superId] = superPath;
					graphElements.nodes.push(superPath);
				}

				let subPath = subPathways[subId];
				if (!subPath) {
					subPath = CytoscapeStyle.createSubNodeData(subId, superId, metabolite.subPathway, drawCompounds ? superId : null);

					subPathways[subId] = subPath;
					graphElements.nodes.push(subPath);
					graphElements.edges.push(CytoscapeStyle.createEdgeData(
						superId + " - " + subId, superId, subId
					));
				}

				graphElements.edges.push(CytoscapeStyle.createEdgeData(
					subId + " - " + metabolite.metaboliteId, subId, metabolite.metaboliteId
				));
			}

			let chemName = metabolite.chemicalName,
				isUnknown = this.nodeIsUnknown(metabolite);//chemName.toLowerCase().includes("unknown");

			if (isUnknown) {
				chemName = "Unknown Compounds";
			}

			const m = SamplesetUtils.processMetaboliteStats(metabolite, isUnknown, testField, stats, statsDict, trends);
			let subId = m.subPathway ? makeNodeId(m.subPathway) : null;
			let superId = m.superPathway ? makeNodeId(m.superPathway) : null;
			let meta = CytoscapeStyle.createMetaNodeData(m, subId, superId, isUnknown, drawCompounds && m.subPathway ? makeNodeId(m.subPathway) : null);

			graphElements.nodes.push(meta);
			metaboDeck[metabolite.chemicalId + '-' + metabolite.chemicalName] = meta;

			return m;
		});

		return { metabolites, graphElements, superPathways, subPathways, metaboDeck };
	}

	static processStatDetails(metabolites, testField, stats, statsDict, trends) {
		return metabolites.map(metabolite => {

			let isUnknown = this.nodeIsUnknown(metabolite); //chemName.toLowerCase().includes("unknown");

			return SamplesetUtils.processMetaboliteStats(metabolite, isUnknown, testField, stats, statsDict, trends);
		});
	}

	static processMetaboliteStats(readOnlyMetabolite, isUnknown, testField, stats, statsDict, trends) {
		let metabolite = { ...readOnlyMetabolite };
		metabolite.statsDetails.forEach(mStats => {
			//mStats and stats[n] DO NOT MATCH!!!!
			let stat = statsDict[mStats.statsId];
			if (!stat) return;
			let id = stat.statsId, statsField = 'detail-' + id;
			metabolite[statsField] = { ...mStats };
			//get the statistical value being used, typically the pValue
			let status = SamplesetUtils.determineDetailStatus(mStats, stat, testField);

			metabolite[statsField].status = status;
			metabolite[statsField].effectTest = stat.selfCompare || stat.studyType.indexOf('effect') >= 0;
			metabolite[statsField].studyType = stat.studyType;

			//collect enrichment data
			SamplesetUtils.collectEnrichmentData(metabolite, trends[id], status, isUnknown);
		});
		return metabolite;
	}

	static nodeDataIsUnknown(node, nameKey = "chemicalName") {
		const name = node[nameKey];
		return (
			node.pathwaySortOrder === 0 ||
			(name && name.toLowerCase().includes("unknown")) ||
			(node.compoundType && node.compoundType.toLowerCase().includes("unknown"))
		);
	}

	static nodeIsUnknown(node, nameKey = "chemicalName") {
		if (!node.data) return this.nodeDataIsUnknown(node, nameKey);

		const name = node.data(nameKey);
		const { compoundType, pathwaySortOrder } = node.data();
		return (
			pathwaySortOrder === 0 ||
			(name && name.toLowerCase().includes("unknown")) ||
			(compoundType && compoundType.toLowerCase().includes("unknown"))
		);
	}

	static getNodeUUID(node) {
		const { chemicalId, id, metaboliteId } = node.data();
		if (chemicalId === nilId) {
			if (metaboliteId && metaboliteId !== nilId) return metaboliteId;
			return id;
		}
		return chemicalId;
	}

	static highlightNode(node, nameKey, getNodeScale, current, studyType, highlights, flags, cy, onSubmapSelect) {
		let chemId = node.data('chemicalId'), detail = node.data('detail-' + current), classes = [studyType];//this.getNodeId(node), 
		if (detail && detail.effectTest) {
			classes = ['effect'];
		}

		const { hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors } = flags;

		let chemName = node.data(nameKey), isUnknown = this.nodeIsUnknown(node, nameKey);
		if (isUnknown) {
			chemId = this.getNodeUUID(node);// node.data('metaboliteId');
		}

		if (node.data('compoundType') == 'Class') {
			SamplesetUtils.styleMultiSpeciesNode(node, chemName, studyType, highlights, cy);
			return;
		}

		if (chemId) {
			if (highlights.trendUp.indexOf(chemId) >= 0) classes.push('trendUp');
			else if (highlights.trendDown.indexOf(chemId) >= 0) classes.push('trendDown');
			else if (highlights.sigUp.indexOf(chemId) >= 0) classes.push('sigUp');
			else if (highlights.sigDown.indexOf(chemId) >= 0) classes.push('sigDown');
			else if (highlights.nil.indexOf(chemId) >= 0) classes.push('nil');
			else if (highlights.dead.indexOf(chemId) >= 0) classes.push('dead');
			else if (highlights.blank.indexOf(chemId) >= 0) classes.push('blank');
		}

		switch (node.data('compoundType')) {
			case "Intermediate":
				if (!hideIntermediates) classes.push('show');
				break;

			case "Cofactor":
				if (!hideCofactors) classes.push('show');
				break;

			case "FinalPathway":
				if (!hideFinalPathways) classes.push('show');
				node.off('click');
				node.on('click', () => {
					let mapId = node.data('canonicalName');
					onSubmapSelect(mapId);
				});
				break;

			case "Minor Metabolite":
				if (!hideMinorMetabolites) classes.push('show');
				break;
		}

		node
			.removeClass('effect zscore standard show trendUp sigUp trendDown sigDown blank nil dead')
			.data({ weight: getNodeScale(node) })
			.addClass(classes.join(' '));
	}

	static styleMultiSpeciesNode(node, chemName, studyType, highlights, cy) {
		if (node.data('compoundType') != 'Class') return;

		let colors = SettingsMap.colors;
		//lookup subpathways based on chemId
		//pie fill nodes with metabolites stats
		//use cytoscape to get stats data?
		let id = makeNodeId(chemName);
		let group = cy.$(`#${id}`).neighborhood(`node[type!='superpathway']`);
		let count = group.length,
			pie = {
				trendUp: 0, trendDown: 0, sigUp: 0, sigDown: 0,
				nil: 0, dead: 0, blank: 0
			},
			keys = [];

		//loop thru metabolites
		group.forEach(m => {
			let mId = m.data('chemicalId'), mName = m.data('chemicalName');
			if (mName && mName.toLowerCase().includes("unknown")) {
				mId = m.data('metaboliteId');
			}
			if (mId) {
				for (let key in highlights) {
					if (highlights[key].indexOf(mId) < 0) continue;
					pie[key]++;
					if (keys.indexOf(key) < 0) keys.push(key);
					break;
				}
			}
		});

		//console.log(id, count, pie);
		let style = {
			'pie-size': '75%'
		};
		keys.forEach((key, n) => {
			//console.log(id, key, pie[key]);
			style[`pie-${n + 1}-background-size`] = Math.round(100 * (pie[key] / count));
			style[`pie-${n + 1}-background-color`] = key in colors[studyType] ? colors[studyType][key] : colors.defaults[key];
		});
		node.style(style);
	}

	static getNodeScale(type, current, state) {
		if (!state) return SamplesetUtils.nodeBaseScale();
		const { metaboDeck, cy, cyMap, cyPath, foldChangeScale } = state;
		let nameKey = cy === cyMap ? "canonicalName" : 'chemicalName';

		if (!foldChangeScale) return SamplesetUtils.nodeBaseScale();
		if (type === "zscore") return SamplesetUtils.scaleZScoreNode(current, nameKey, metaboDeck);
		return SamplesetUtils.scaleNode(current, nameKey, metaboDeck);
	}

	static scaleZScoreNode(current, nameKey, metaboDeck) {
		return node => {
			let detail, chemId = node.data('chemicalId'), chemName = node.data(nameKey);
			if (!chemId || !chemName) {
				detail = node.data('detail-' + current);
			}
			else {
				let stats = metaboDeck[chemId + '-' + chemName];
				if (!stats) return 1;
				detail = stats.data['detail-' + current];
			}

			if (!detail) return 1;


			let val = Math.abs(detail.foldChange),
				factor = 2,
				max = 5,
				delta = Math.min(max, Math.floor(val));

			return Math.max(1, factor * delta);//*sign;
		};
	}

	static nodeBaseScale() {
		return node => 1;
	}

	static scaleNode(current, nameKey, metaboDeck) {
		return node => {
			let detail, chemId = node.data('chemicalId'), chemName = node.data(nameKey);
			if (!chemId || !chemName) {
				detail = node.data('detail-' + current);
			}
			else {
				let stats = metaboDeck[chemId + '-' + chemName];
				if (!stats) return 1;
				detail = stats.data['detail-' + current];
			}

			if (!detail) return 1;

			let val = detail.foldChange !== null ? detail.foldChange : 1;
			val = val >= 1 ? val : (val == 0 ? 0 : 1 / val);
			let factor = 1, max = 5;
			let delta = Math.min(max, Math.abs(val));

			return factor * delta;
		};
	}

	static gatherTrends(trends, highlights = {}) {
		if (!trends) return highlights;

		if (trends.trendUp.length) highlights.trendUp = highlights.trendUp.concat(trends.trendUp);
		if (trends.trendDown.length) highlights.trendDown = highlights.trendDown.concat(trends.trendDown);
		if (trends.sigUp.length) highlights.sigUp = highlights.sigUp.concat(trends.sigUp);
		if (trends.sigDown.length) highlights.sigDown = highlights.sigDown.concat(trends.sigDown);
		if (trends.dead.length) highlights.dead = highlights.dead.concat(trends.dead);
		if (trends.nil.length) highlights.nil = highlights.nil.concat(trends.nil);
		if (trends.blank.length) highlights.blank = highlights.blank.concat(trends.blank);

		return highlights;
	}

	static determineStudyType(stat) {
		let type = 'standard', selfCompare = false;

		if (stat.test && stat.test.toLowerCase() == 'zscore') type = 'zscore';
		if (stat.foldChange) {
			let fold = formatDisplayString(stat.foldChange);
			let fcParts = fold.split('/');
			selfCompare = fcParts[0] === fcParts[1];
			if (!selfCompare) {
				selfCompare = (stat.reportName && stat.reportName.toLowerCase().indexOf('effect') >= 0);
			}
			stat.selfCompare = selfCompare;
			if (selfCompare) type = 'effect';
		}
		else if (stat.test && stat.test.toLowerCase().indexOf('anova') >= 0) {
			type = 'effect';
		}

		return type;
	}

	static determineDetailStatus(detail, stat, testField) {
		const val = detail[testField], num = Number(val),
			pVal = detail['pValue'] == null ? detail['pValue'] : Number(detail['pValue']);

		//set trend status based on where the value falls within the mins/maxes and signifiers
		let status = 'nil';

		//detect zscore test...
		if (stat.test.toLowerCase() == 'zscore') {
			if (isNaN(val) || val === null) status = 'dead';
			else if (num >= 1.5) {
				if (num < 2.0) status = 'trendUp';
				else status = 'sigUp';
			}
			else if (num <= -1.5) {
				if (num > -2.0) status = 'trendDown';
				else status = 'sigDown';
			}

		}
		else {
			if (stat.studyType.indexOf('effect') >= 0) {
				if (0.05 >= pVal) status = 'sigUp';
				else if (0.05 < pVal && pVal < 0.1) status = 'trendUp';
			}
			else if (isNaN(val) || val === null) status = 'blank';
			else if (!isNaN(val) && pVal !== null) {
				if (num > 1) {
					if (0.05 >= pVal) status = 'sigUp';
					else if (0.05 < pVal && pVal < 0.1) status = 'trendUp';
				}
				else {
					if (0.05 >= pVal) status = 'sigDown';
					else if (0.05 < pVal && pVal < 0.1) status = 'trendDown';
				}
			}
		}

		return status;
	}
	static collectEnrichmentData(metabolite, trend, status, isUnknown) {
		//get an identifier for the metabolite to use as a lookup key
		let chemId = metabolite.chemicalId,
			chemName = isUnknown ? "Unknown Compounds" : metabolite.chemicalName,
			mId = metabolite.metaboliteId;

		if (isUnknown) trend[status].push(mId);
		else {
			trend[status].push(chemId);
			trend.count++;
		}
		//skip counts if unknown
		if (isUnknown) return;

		//register compounds and increment counts for totals
		if (!(chemId in trend.compounds)) {
			trend.compounds[chemId] = { name: chemName, count: 1 };
		}
		else {
			trend.compounds[chemId].count++;
		}

		//register pathways and increment counts for totals and significance
		if (metabolite.subPathway) {
			let sub = metabolite.subPathway;
			if (!(sub in trend.pathways)) {
				trend.pathways[sub] = { name: sub, count: 1, sig: 0 };
			}
			else {
				trend.pathways[sub].count++;
			}
			if (status.indexOf('sig') == 0) {
				trend.pathways[sub].sig++;
				trend.sig++;
			}
		}

		return trend;
	}

	static calculateEnrichments(trends) {
		let maxE = 0;
		for (let key in trends) {
			let trend = trends[key];
			for (let name in trend.pathways) {
				let p = trend.pathways[name];
				p.enrichment = SamplesetUtils.calculateEnrichment(p.sig, p.count, trend.sig, trend.count);
				maxE = Math.max(maxE, p.enrichment);
			}
			trend.maxEnrichment = maxE;
		}

		return trends;
	}

	//(k/m)/((n-k)/(N-m))
	static calculateEnrichment(k, m, n, N) {
		if (N == 0 || n == 0 || m == 0) return 0;
		//return parseFloat(toFixed((k/m)/(n/N))); //formula 1
		return parseFloat(toFixed((k / m) / ((n - k) / (N - m)))); //formula 2
	}



	static getStatTitle(data, fallback = "Stats") {
		let label = [];
		if (data.test) label.push(data.test);
		if (data.comparison) label.push(data.comparison);
		if (data.foldChange) label.push(data.foldChange);

		if (label.length <= 0) return fallback;

		return label.join(' ');
	}
}