'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicTable from '../components/controls/DynamicTable.jsx';
import DynamicTableHelper from '../components/controls/DynamicTableHelper.jsx';
import DynamicTableCell from '../components/controls/DynamicTableCell.jsx';
import { Material } from '../components/index.jsx';
import './SamplesList.scss';

export default class SamplesList extends Component {
	static propTypes = {
		/** files*/
		samples: PropTypes.array,
		disabled: PropTypes.bool,
		activeIndex: PropTypes.number
	};

	static defaultProps = {
		samples: [],
		disabled: false,
		activeIndex: 0
	};

	constructor(props) {
		super(props);
		const { samples } = this.props;
		this.state = {
			samples: samples.map(s => ({ ...s })),
		};
	}

	componentDidUpdate(prevProps) {
		const { samples } = this.props;
		if (samples && prevProps.samples) {
			if (samples.length != prevProps.samples.length) this.updateState({ samples });
		}
	}

	updateState(data, callback) {
		this.setState(data, callback);
	}

	generateClassNames = (base = 'SamplesList') => {
		const { className, disabled } = this.props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (disabled) classNames.unshift('disabled');
		if (className) classNames.unshift(className);

		return classNames;
	};

	onRowClick = (id) => {
		let { samples, selection } = this.state,
			realSample = samples.find(s => s.sampleSetId == id);

		if (realSample) {
			this.onSampleClick(realSample);
		}

		if (id === selection) return;
		this.setState({ selection: id }, () => {
			const { onRowClick } = this.props;
			if (onRowClick) onRowClick(id);
		});
	};

	onSampleClick(sampleSet) {
		const { onSampleSelect } = this.props;
		if (onSampleSelect) onSampleSelect(sampleSet);
		//update active css
	}

	onEyeClick(sample) {
		let { samples } = this.state,
			realSample = samples.find(s => s.sampleSetId == sample.sampleSetId);

		if (!realSample) return;

		realSample.hasChanged = true;
		realSample.customerVisible = !realSample.customerVisible;
		this.setState({ samples });

		const { onSampleVisibility } = this.props;
		if (onSampleVisibility) onSampleVisibility(realSample);
	}

	createTableFields() {
		return [
			DynamicTableHelper.createField('sampleName', 'Name', {
				renderer: (data, fieldName, n) => {
					let label, eye, drag, pen;

					label = data.sample.name;

					const eyeCb = this.onEyeClick.bind(this, data);

					let { customerVisible } = data;
					let eyeColor = customerVisible ? 'primary' : 'inactive';

					eye = <Material title="Toggle visibility" onClick={eyeCb} color={eyeColor} icon={customerVisible ? 'visibility' : 'visibility_off'} />;

					return (
						<DynamicTableCell className={fieldName} key={n}>
							{eye}
							<span title={label} className="label" >{label}</span>
						</DynamicTableCell>
					);
				}
			}),
		];
	}

	render() {
		const { samples, selection } = this.state;
		const { selections, rowProps } = this.props;
		let className = this.generateClassNames().join(' ');

		let fields = this.createTableFields();
		if (!samples.length) return <p className={className}>No samples Available</p>;

		let label = "Sample Sets";

		return (
			<DynamicTable
				showHeader={false}
				onItemToggle={this.onItemToggle}
				primaryKey="sampleSetId"
				defaultSelections={selections}
				defaultSelection={selection}
				sorter={null}
				sortDir={1}
				index={0}
				className={className}
				dataset={samples}
				fields={fields}
				rowProps={rowProps}
				customScroll={true}
				wrapRows={true}
				listHeight={450}
				onRowClick={this.onRowClick}>

				<div className="table-title">
					{label}
				</div>

			</DynamicTable>
		);
	}
}