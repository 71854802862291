'use strict';
import React, { useCallback } from "react";
import classNames from "classnames";
import "./Button.scss";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";

/** A ExternalLink component */
const ExternalLink = ({
  children,
  className,
  bgColor=null,
  color=null,
  target="_blank",
  href
}) => {
  const logger = useLocalAuditLogger();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      window.open(href, "_blank");
      logger.logUserAction(`user navigated to ${href}`);
    },
    []
  );
  
  return (
  <a onClick={onClick} href="#" target={target} rel="noreferrer noopener" className={classNames(className, "Link", bgColor, color)} >
    {children}
  </a>
  );
};

export default ExternalLink;