'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import DynamicTable from '@/components/controls/DynamicTable.jsx';
import DynamicTableCell from '@/components/controls/DynamicTableCell.jsx';
import { DynamicModalButton, Icon, Label } from '@/components/index.jsx';
import ManageUserPermissions from './ManageUserPermissions.jsx';
import ManageProjectPermissions from './ManageProjectPermissions.jsx';
import './ManagedUsersTable.scss';
import { Auditor } from '@/components/util/Auditor.jsx';

export default class ManagedUsersTable extends Auditor {
	static propTypes = {
		/** users*/
		users: PropTypes.array.isRequired,
		/**Permission set to allow editing Project Permissions */
		editProjectPermissions: PropTypes.bool,
		/**Permission set to allow editing User Role Permissions */
		editUserPermissions: PropTypes.bool

	};

	static defaultProps = {
		users: [],
		editProjectPermissions: false,
		editUserPermissions: false
	};

	constructor(props) {
		super(props);
		this.state = {
			isShowing: false,
			...super.state
		};
	}
	generateClassNames = (base = 'ManagedUsersTable') => {
		const { className } = this.props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (className) classNames.unshift(className);

		return classNames;
	};

	onItemToggle = (selections) => {
		if (this.props.onCheckboxClick) this.props.onCheckboxClick(selections);
	};

	createTableFields() {
		return [
			{
				name: 'firstName',
				header: 'Name',

				renderer: (data, fieldName, n) => {

					return (
						<DynamicTableCell className="name-cell" key={n}>
							<Label color="primary">{data.firstName} {data.lastName}</Label>
						</DynamicTableCell>
					);
				}
			},
			{
				name: 'email',
				header: 'Email',

				renderer: (data, fieldName, n) => {
					let email = '';

					if (data.email && data.email.length > 0) {
						email = data.email;
					}
					return (
						<DynamicTableCell className="email-cell" key={n}>
							<Label color="primary">{email}</Label>
						</DynamicTableCell>
					);
				}
			},
			{
				name: 'manage',
				header: 'Manage',

				renderer: (data, fieldName, n) => {
					const { userId, name, size, emails } = data;
					return (

						<DynamicTableCell key={n} className="action-cell">
							<span className="action-icons">
								{this.props.editProjectPermissions && (
									<DynamicModalButton
										className="alt2"
										title="Manage Projects"
										modalId={`modal-add-user`}
										modalProps={{ bodyClass: ".managedUsersProjectModal", renderClose: false }}
										component={ManageProjectPermissions}
										componentProps={{ 
											bodyClass: ".managedUsersProjectModal",
											user: data
										}}>
										<Icon color="primary" icon="plus-circle" />
									</DynamicModalButton>
								)}
								{this.props.editUserPermissions && (
									<DynamicModalButton
										className="alt2"
										title="Manage Permissions"
										modalId={`modal-add-user`}
										modalProps={{ renderClose: false }}
										component={ManageUserPermissions}
										componentProps={{ 
											user: data
										}}>
										<Icon color="primary" icon="lock" />
									</DynamicModalButton>
								)}
							</span>
						</DynamicTableCell>

					);
				}
			}
		];
	}

	render() {
		const users = [...this.props.users];
		let rowProps = (user) => {
			return { id: user.userId };
		};
		return (
			<div>
				{super.render()}
				<DynamicTable
					onItemToggle={this.onItemToggle}
					primaryKey="userId"
					sorter="name"
					sortDir={1}
					index={1}
					className={this.generateClassNames().join(' ')}
					dataset={users}
					fields={this.createTableFields()}
					rowProps={rowProps}
					emptyLabel="Begin Search for User"
					{...this.props}
				/>


			</div>
		);
	}
}