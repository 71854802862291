import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from '../actions/actionTypes';

export const getInitialState = () => ({
    managedProjectsIsLoading: false,
    managedProjectsHasErrored: false,
    data: [],
    authenticated: false
});

export const managedProjectsSlice = createSlice({
    name: 'managedProjects',
    initialState: getInitialState(),
    extraReducers: {
        [actionTypes.MANAGEDPROJECTS_HAS_ERRORED]: (state) => {
            return {
                ...state,
                managedProjectsIsLoading: false,
                managedProjectsHasErrored: true
            };
        },
        [actionTypes.MANAGEDPROJECTS_IS_LOADING]: (state) => {
            return {
                ...state,
                managedProjectsIsLoading: true
            };
        },
        [actionTypes.MANAGEDPROJECTS_FETCH_DATA_SUCCESS]: (state, action) => {
            return {
                ...state,
                managedProjectsIsLoading: false,
                data: action.response,
                authenticated: action.authenticated || false
            };
        }
    }
});

export const managedProjectsReducer = managedProjectsSlice.reducer;