'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Hollaback, NavBar } from '../components/index.jsx';
//import './ContentSwitch.scss';
import { Logger, Log } from '../utils/Logger';
import {AppInsightLogLevel} from '../enums/Enums';
import { Auditor } from '@/components/util/Auditor.jsx';
const logger = new Logger();
export default class ContentSwitch extends Auditor {
	
	static propTypes = {
		viewIndex: PropTypes.number,
		nav: PropTypes.array,
		content: PropTypes.array,
		auditIds: PropTypes.array,
	};

	static defaultProps = {
		viewIndex: 0,
		nav: [],
		content: [],
		auditIds: [],
	};

	constructor(props) {
		super(props);
		const {viewIndex,parentView} = this.props;
		
		this.state = {
			viewIndex,
			parentView,
			...super.state // pull in state from auditor to get the logger
		};
	}

	generateClassNames() {
		const {className} = this.props;
		let classNames = [this.constructor.name];
		if(className) classNames.push(className);
		return classNames;
	}

	generateClassString() {
		return this.generateClassNames().join(' ');
	}

	onSwitch = viewIndex => {
		this.setState({viewIndex});
		let navName = this.props.nav[viewIndex].props.children;
		this.logEvent(this.state.parentView,"NavItemClicked", navName);
		if (viewIndex < this.props.auditIds.length)
			this.state.logger.logUserAction(`view ${this.props.auditIds[viewIndex]}`);
	};

	logEvent(parent, property, value){
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName(parent);
		logFilterChange.AddProperty(property,value);
		logger.doLog(logFilterChange);
	}

	renderNav() {
		let {nav} = this.props;
		let {viewIndex} = this.state;
		return (
			<NavBar>
				{nav.map((b, n) => <Hollaback data={n} key={n} onClick={this.onSwitch}>{React.cloneElement(b, {className:n==viewIndex?'active':''})}</Hollaback>)}
			</NavBar>
		);
	}

	renderContent() {
		let {content} = this.props;
		let {viewIndex} = this.state;
		return (
			<div className="content">
				{content[viewIndex]}
			</div>
		);
	}

	render() {
		const {viewIndex} = this.state;

		return (
			<div className={this.generateClassString()}>
				{super.render()/*call super.render() to add the auditor*/}
				{this.props.children}
				{this.renderNav()}
				{this.renderContent()}
			</div>
		);
	}
}