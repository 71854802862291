import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import getDocStyle from "../../utils/getDocStyle";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";

const Anchor = ({ clicked, hovered, i, x, y, id, opacity, active, type }) => {
  const { width, mobile, smallScreen } = useSelector(viewPortSelector);

  let anchorSize, activeAnchor, clickedOuterAnchorSize, clickedInnerAnchorSize;

  if (mobile) {
    activeAnchor = 2;
    if (type === "association") {
      anchorSize = 1.25;
      clickedOuterAnchorSize = 2;
      clickedInnerAnchorSize = 1;
    } else {
      anchorSize = 1;
      clickedOuterAnchorSize = 3.5;
      clickedInnerAnchorSize = 2;
    }
  } else if (smallScreen) {
    activeAnchor = 2.5;
    clickedInnerAnchorSize = 2.5;
    if (type === "association") {
      clickedOuterAnchorSize = 4;
    } else {
      anchorSize = 1.5;
      clickedOuterAnchorSize = 5;
    }
  } else if (width > 1650) {
    activeAnchor = 5;
    clickedInnerAnchorSize = 5;
    if (type === "association") {
      clickedOuterAnchorSize = 8;
      clickedInnerAnchorSize = 5;
    } else {
      anchorSize = 3;
      clickedOuterAnchorSize = 9;
      clickedInnerAnchorSize = 6;
    }
  } else {
    activeAnchor = 3.5;
    if (type === "association") {
      clickedOuterAnchorSize = 6;
      clickedInnerAnchorSize = 3;
      activeAnchor = 2.5;
    } else {
      anchorSize = 2;
      clickedOuterAnchorSize = 7;
      clickedInnerAnchorSize = 3;
    }
  }

  return (
    <g>
      {clicked && (
        <circle
          key={i + "clickcircle"}
          cx={x}
          cy={y}
          r={clickedOuterAnchorSize}
          fill="white"
          stroke={getDocStyle("var(--metabolon-base)")}
          strokeWidth="1"
          id={id}
          opacity={opacity}
        />
      )}
      <circle
        key={i + "circle"}
        cx={x}
        cy={y}
        r={
          hovered || clicked
            ? clickedInnerAnchorSize
            : active
            ? activeAnchor
            : anchorSize
        }
        fill={getDocStyle("var(--metabolon-base)")}
        stroke="white"
        id={id}
        opacity={opacity}
        style={{ willChange: "opacity, cx, cy" }}
      />
    </g>
  );
};

Anchor.propTypes = {
  clicked: PropTypes.bool,
  hovered: PropTypes.bool,
  i: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  id: PropTypes.string,
  opacity: PropTypes.number,
  active: PropTypes.bool,
  type: PropTypes.string,
};

Anchor.defaultProps = {
  clicked: false,
  hovered: false,
  i: 0,
  x: 0,
  y: 0,
  id: "",
  opacity: 1,
  active: true,
  type: "",
};

export default memo(Anchor);
