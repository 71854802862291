import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Material, SearchResult, SearchResultItem } from '../index.jsx';
import './SearchBox.scss';

const getStyles = (props, state) => {
  const { disabled } = props;
  const { value, results } = state;
  const nonEmpty = value.length > 0;
  const hasResults = value.length > 0;

  return {

    iconButtonClose: {
      style: {
        transform: nonEmpty ? 'scale(1, 1)' : 'scale(0, 0)',
      }
    },
    iconButtonSearch: {
      style: {
        opacity: !disabled ? 0.54 : 0.38,
        transform: nonEmpty ? 'scale(0, 0)' : 'scale(1, 1)',
      }
    }
  };
};

export default class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      focus: true,
      value: this.props.value,
      active: false,
      dataSource: this.props.dataSource,
    };
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.dataSource !== nextProps.dataSource) {
      this.setState({ dataSource: nextProps.dataSource });
    }

    if (this.props.clearText != nextProps.clearText) {
      if (nextProps.clearText) {
        this.onClickCancel();
      }
    }
  }

  handleInput(e) {
    this.setState({ value: e });
    if (this.props.onChange) this.props.onChange(e);
  }

  handleCancel() {
    this.setState({ active: false, value: '' });
    if (this.props.onChange) { this.props.onKeyUp(''); }
    this.setState({ dataSource: [] });
    this.inputText.value = '';
    if (this.props.handleCancel) { this.props.handleCancel(); }
  }

  handleKeyDown(e) {
     
    // console.log(e.key);
  }

  onChange = (e) => {
    this.handleInput(e.target.value);
  };

  onKeyPress = (e) => {
    // this.handleKeyPressed(e);
  };

  onClickCancel = () => {
    this.handleCancel();
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  onFocus = () => {
    this.setState({ focus: true });
    this.inputText.focus();
  };

  render() {
    const { cursor } = this.state;
    const styles = getStyles(this.props, this.state);
    const { value, focus } = this.state;
    const {
      closeIcon,
      disabled,
      onRequestSearch,
      searchIcon,
      spellCheck,
      style,
      ...inputProps
    } = this.props;

    let inputClasses = ['searchContainer'];
    if (!value.length) inputClasses.push('empty');
    if (focus) inputClasses.push('focused');
    inputClasses = inputClasses.join(' ');

    return (
      <div className={inputClasses} onBlur={this.onBlur} onClick={this.onFocus} >
        <div className={"searchInput"}>
          <input
             
            ref={el => this.inputText = el}
            placeholder={this.props.hintText}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.props.onKeyUp} />

        </div>
        <div data-testid="searchbox-search" className={"icon-btn search" + (disabled ? ' disabled' : '')} onClick={onRequestSearch} style={styles.iconButtonSearch.style}>
          <Material icon={'search'} size="large" color={focus ? "tertiary" : this.props.iconColor} />
        </div>

        <div data-testid="searchbox-cancel" className="icon-btn close" onClick={this.onClickCancel} style={styles.iconButtonClose.style}>
          <Material icon={'close'} size="large" color="tertiary" />
        </div>

        <SearchResult showResults={this.props.showResults}>
          {this.state.dataSource.map((data, index) => {
            return <SearchResultItem key={index} itemClick={this.props.itemClick} primaryText={data[this.props.mapData.primaryText]} secondaryText={data[this.props.mapData.secondaryText]} value={String(data[this.props.mapData.value])} />;
          })}
        </SearchResult>

      </div>
    );
  }
}

SearchBox.defaultProps = {
  dataSource: [],
  dataSourceConfig: { text: 'text', value: 'value' },
  disabled: false,
  hintText: 'Search',
  spellCheck: false,
  value: '',
  iconColor: 'white',
  onKeyUp: () => { }
};

SearchBox.propTypes = {
  /** Array of strings or nodes used to populate the list. */
  dataSource: PropTypes.array,
  /** Config for objects list dataSource. */
  dataSourceConfig: PropTypes.object,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Sets hintText for the embedded text field. */
  hintText: PropTypes.string,
  /** Fired when the text value changes. */
  onChange: PropTypes.func.isRequired,
  /** Fired when the search icon is clicked. */
  onRequestSearch: PropTypes.func,
  /**mappingData */
  mapData: PropTypes.object.isRequired
};