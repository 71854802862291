import React, { useEffect, useState } from 'react'
import { Box, Tooltip, Typography } from "@mui/material";
import { Icon } from "metabux/components/Icon/Icon";
import { Gray } from 'metabux/theme/colors';
import { Info } from 'lucide-react';

export const KPIMetric = ({
  label,
  value,
  type = "bold",
  infoText,
  noBorder,
  metricsCount = 1,
}) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    setDisplayValue(value);

    if (isNaN(value))
      return;

    let valObj = { val: 0 };

    TweenMax.to(valObj, 1.5, { val:value, onUpdate: () => {
      setDisplayValue(Math.round(valObj.val));
    }});
  }, [value]);
  
  const borderStyles = !noBorder && {
    borderLeft: "1px solid",
    borderColor: Gray[200],
    paddingLeft: 10,
  };

  const metricWidth = `${100 / metricsCount}%`;

  return (
    <Box sx={{ ...borderStyles, width: metricWidth }}>
      <Typography
        variant='body2'
        sx={{
          fontWeight: 600,
          color: Gray[500],
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {label}
        {infoText && (
          <Tooltip title={infoText}>
            <Icon
              sx={{ display: "flex", alignItems: "center" }}
              icon={Info}
              size={12}
            />
          </Tooltip>
        )}
      </Typography>
      {type === "bold" ? (
        <Typography sx={{ color: Gray[800], typography: { sm: "h5", md: "h3" } }}>
          {displayValue}
        </Typography>
      ) : (
        <Typography variant='body1' sx={{ color: Gray[800] }}>
          {displayValue}
        </Typography>
      )}
    </Box>
  );
};