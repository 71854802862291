import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Checkbox, Filedrop } from '../../components/index.jsx';
import CytoD3 from '../../components/visualization/CytoD3.jsx';
import SettingsMap from '../../utils/SettingsMap.js';

const PathwayMapViewer = () => {
  const [cy, setCy] = useState(null);
  const [map, setMap] = useState(null);
  const [cytoConfig, setCytoConfig] = useState(null);
  const [selector, setSelector] = useState(null);
  const [hideFinalPathways, setHideFinalPathways] = useState(true);
  const [hideIntermediates, setHideIntermediates] = useState(false);
  const [hideMinorMetabolites, setHideMinorMetabolites] = useState(false);
  const [hideCofactors, setHideCofactors] = useState(false);
  const [lastMapUpdate, setLastMapUpdate] = useState(null);
  
  let reader = new FileReader();

  useEffect(() => {
    if(!cytoConfig) return;
    //console.log(cytoConfig);
    setMap(cytoConfig.data.name.toLowerCase().indexOf("plant") !== -1 ?
      "Plant Metabolic Pathways": "Metabolic Pathways"
    );
  }, [cytoConfig]);

  useEffect(() => {
    if(!cy) return;
    if(!selector) checkZoom(cy.$(':visible'));
    else {
      cy.nodes(selector).select();//.removeClass('manually-hidden');
      checkZoom(selector);
    }
    updateNodes();
  }, [map, cy]);

  useEffect(() => {
    if(!cy) return;
    updateNodes();
  }, [cy, hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors]);

  const updateNodes =(() => {
    cy.nodes().forEach(node => {
      let classes = ["standard"];
			switch(node.data('compoundType')) {
        case "Intermediate":
          if(!hideIntermediates) classes.push('show');
          else classes.push('hidden');
          break;
  
        case "Cofactor":
          if(!hideCofactors) classes.push('show');
          else classes.push('hidden');
          break;
  
        case "FinalPathway":
          if(!hideFinalPathways) classes.push('show');
          else classes.push('hidden');
          node.off('click');
          node.on('click', () => {
            let mapId = node.data('canonicalName');
            onSubmapSelect(mapId);
          });
          break;
  
        case "Minor Metabolite":
          if(!hideMinorMetabolites) classes.push('show');
          else classes.push('hidden');
          break;
      }
      node
        .removeClass('show hidden')
        .addClass(classes.join(' '));
		});
  });

  const checkZoom = (target) => {
		cy.fit(target);
		if(cy.zoom() > 2.2) {
			cy.zoom(2.2);
			cy.center(target);
		}
	};

  const onMapFileDrop = useCallback((fileDict) => {
    setCytoConfig(null);
		reader.onload = onMapFileRead;
		Object.keys(fileDict).forEach((filename) => {			
			let file = fileDict[filename];
			//console.log(filename, file);
			delete fileDict[filename];
			reader.readAsText(file);
		});
	}, []);

  const onMapFileRead = useCallback(() => {
    setCytoConfig(JSON.parse(reader.result));
	}, []);

  const onMapReady = useCallback((cyInstance) => {
    //setMap(JSON.parse(reader.result));
    setCy(cyInstance);
	}, []);

  const onMapUpdate = useCallback(() => {
		setLastMapUpdate(dayjs().valueOf());
	}, []);

  const onSubmapSelect = useCallback((mapId, selector, group) => {
    setSelector(selector);
    if(mapId in cytoConfig.pathways) setMap(mapId);
     
    else console.warn(mapId, 'is not a valid submap');
	}, [cytoConfig]);

  const onChange = useCallback((e) => {
		const {name, isChecked} = e.target;

    if(name === "hideFinalPathways") setHideFinalPathways(isChecked);
    else if(name === "hideIntermediates") setHideIntermediates(isChecked);
    else if(name === "hideMinorMetabolites") setHideMinorMetabolites(isChecked);
    else if(name === "hideCofactors") setHideCofactors(isChecked);
	}, []);

  let mapNames = cytoConfig ? Object.keys(cytoConfig.pathways).map(key => ({name:key})) : [];

  let mapNodes = [];
  if(cytoConfig) {
    for(let p in cytoConfig.pathways) {
      mapNodes = mapNodes.concat(cytoConfig.pathways[p].elements.nodes);
    }
  }
  mapNodes.forEach(node => {
    let ecNumber = node.data.ecNumber || '';
    if(node.data.compoundType.toLowerCase() === 'enzyme') {
      node.data.ecNumbers = ecNumber.split('///').map(s => s.trim());
    }
  });

  let hiddenMaps = [];//['Endocannabinoid Synthesis', 'Beta-oxidation', 'Phospholipid Metabolism', 'Sphingolipid Metabolism'];

  mapNames = mapNames.filter(mapObj => hiddenMaps.indexOf(mapObj.name) < 0);

  return (
    <div className="pathway-map-viewer">
      map viewer
      <Filedrop onDrop={onMapFileDrop} body="Map JSON upload"/>

      <div className="layer-settings">
        <Checkbox name="hideFinalPathways" onChange={onChange} checked={hideFinalPathways} label="Hide final pathways" />

        <Checkbox name="hideCofactors" onChange={onChange} checked={hideCofactors} label="Hide cofactors" />

        <Checkbox name="hideIntermediates" onChange={onChange} checked={hideIntermediates} label="Hide intermediates" />

        <Checkbox name="hideMinorMetabolites" onChange={onChange} checked={hideMinorMetabolites} label="Hide minor metabolites" />
      </div>

      {cytoConfig &&
        <CytoD3 className="map"
          onUpdate={onMapUpdate}
          onReady={onMapReady}
          colorKey={null}
          studyType={"standard"}
          config={cytoConfig}
          pathway={map}/>
      }
    </div>
  );
};

export default PathwayMapViewer;