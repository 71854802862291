'use strict';
import { fetchAllSampleSets } from '@/discovery-panel/api';
import { DiscoveryPanelMap } from '@/enums/Enums';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ProjectItem.scss';

export const ProjectItem = ({ isDiscoveryPanel = false, ...props }) => {
	const [project, setProject] = useState(props.project);
	const { projectCode, projectId, projectTitle, customerProjectTitle } = project;
	const [discoverySampleSets, setDiscoverySampleSets] = useState(null);

	useEffect(() => {
		setProject(props.project);
	}, [props.project]);

	useEffect(() => {
		const fetch = async () => {
			const sampleSets = await fetchAllSampleSets(projectId);
			setDiscoverySampleSets(sampleSets);
		};
		if (!isDiscoveryPanel) return;
		fetch();
	}, [isDiscoveryPanel]);


	let displayTitle = projectTitle ? projectTitle.replace(projectCode, '') : '';
		
	if(customerProjectTitle){ displayTitle = customerProjectTitle;} //Don't cringe... 

	if (!isDiscoveryPanel) {
		const id = project.publishDate ? projectCode : projectId;
		return (
			<div className="itemContainer">
				<Link to={"/home/project/" + id} className="code" alt2={"true"} size="normal" color="quinary">
					{projectCode}
				</Link>
				<label className="desc" size="small">{displayTitle}</label>
			</div>
		);
	}
	//const path = isDiscoveryPanel ? "/discovery-panels/" + projectId : 


	return (
		discoverySampleSets && Object.keys(discoverySampleSets).map((productType) => {

			return discoverySampleSets[productType].map((sampleSetId) =>
				<div className="itemContainer" key={sampleSetId}>
					<Link to={`/discovery-panels/${projectId}/${productType}/${sampleSetId}`} className="code" alt2={"true"} size="normal" color="quinary">
						{projectCode}
						{isDiscoveryPanel && ` DiscoveryPanel ${DiscoveryPanelMap.ui[productType]}`}
					</Link>
					<label className="desc" size="small">{displayTitle}</label>
				</div>
			);
		})

	);
};

ProjectItem.defaultProps = {
	isDiscoveryPanel: false,
};

export default ProjectItem;
