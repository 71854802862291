import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusSelector } from './redux/selectors/data.selector';
import { filterSelector } from './redux/selectors/filter.selector';
import { initDiscoveryPanels } from './redux/sagas/init.saga';
import { getSample, setSampleSetId, setProductType } from './redux/reducers/discoveryPanels.reducer';

export const Connector = ({ productType, projectId, sampleSetId, children }) => {
	const { hasError, isLoaded } = useSelector(statusSelector);
	const { initialized } = useSelector(filterSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!sampleSetId) return;
		dispatch(setProductType(productType));
		dispatch(setSampleSetId(sampleSetId));
		dispatch(getSample(projectId));
		dispatch(initDiscoveryPanels({projectId, samplesetId: sampleSetId, productType}));
	}, [sampleSetId]);

	return (
		<>
			{!initialized && !hasError && <div>Fetching data...</div>}
			{initialized && hasError && <div>Error during fetching data</div>}
			{initialized && isLoaded && children}
		</>
	);
};