'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './DynamicTableCell.scss';

export default class DynamicTableCell extends Component {

	static propTypes = {

	};

	static defaultProps = {

	};

	constructor(props) {
		super(props);
	}

	generateClassNames(base='DynamicTableCell table-cell') {
		const {className} = this.props;
		let classNames = [];
		if(base) classNames.unshift(base);
		if(className) classNames.unshift(className);

		return classNames;
	}

	render() {
		const {children, className, ...props} = this.props;
		return (
			<div {...props} className={this.generateClassNames().join(' ')}>
				{children}
			</div>
		);

	}
}