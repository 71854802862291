'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import '../../theme/css/font-awesome.css';
import './Icon.scss';

/** A simple Icon component using font awesome */
const Icon = (props) => {
	const { icon, color, size, disabled, className, children, ...restOfProps} = props;

	let generateClassNames = (base) => {
		let classNames = [base];
		if(color) classNames.push(color);
		if(size) classNames.push(size);
		if(className) classNames.unshift(className);
		if(disabled) classNames.push('disabled');

		return classNames;
	};

	return (
		<i {...restOfProps} className={`fa fa-${icon} ${generateClassNames('Icon').join(' ')}`}>
			{children}
		</i>
	);
};

Icon.propTypes = {

	/** The font-awesome icon to use */
	icon: PropTypes.string,//PropTypes.oneOf(['none', 'star','user']),

	/** The color of button */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['', 'default', 'primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'success', 'warning', 'danger', 'info','white', 'inactive', 'selectedItem','disabled']),

	/** The size of the button */
	//size: PropTypes.oneOf(Theme.sizeList),
	size: PropTypes.oneOf(['', 'small', 'medium', 'normal', 'large', 'xlarge']),
};

Icon.defaultProps = {
	icon: 'star', 
	size: '',
};

export default Icon;