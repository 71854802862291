import React, { useCallback, useContext, memo, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { delimiter } from "@/discovery-panel/utils/constants";

import CirclePack from "../../svg/CirclePack";
import Tooltip from "../../controls/Tooltip";
import getDocStyle from "../../../utils/getDocStyle";
import Icon from "../../../../components/controls/Icon";
import Link from "../../../../components/buttons/Link";
import { filterSelector } from "../../../redux/selectors/filter.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import { setTooltipX, setTooltipY, setShowTooltip, setTooltipValue, setTooltipType, interactionsUpdateSelection } from "../../../redux/reducers/discoveryPanels.reducer";
import urljoin from "url-join";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";

const metHighlightStroke = 4;
const groupHighlightStroke = 6;

const Figure = ({ size, circlePackData, id, packSize, match }) => {
  const dispatch = useDispatch();
  const { tabs } = useSelector(filterSelector);
  const selectedState = useSelector(interactionsSelector);
  const { width, mobile } = useSelector(viewPortSelector);

  const setXref = useRef((val) => dispatch(setTooltipX(val))).current;
  const setYref = useRef((val) => dispatch(setTooltipY(val))).current;
  const setShowTooltipref = useRef((val) => dispatch(setShowTooltip(val))).current;
  const setValueref = useRef((val) => dispatch(setTooltipValue(val))).current;
  const setTooltipTyperef = useRef((val) => dispatch(setTooltipType(val))).current;

  const bounds = getDocStyle("--bounds").slice(0, -2);
  const logger = useLocalAuditLogger();

  const handleEvents = useCallback(
    (e) => {
      const d = e.target.dataset;
      if (e.type === "mouseleave") {
        dispatch(interactionsUpdateSelection({
          id: "default"
        }));

        setShowTooltipref(false);
        e.target.style.removeProperty("stroke-width");
      } else {
        if (d.depth === "2") {
          dispatch(interactionsUpdateSelection({
            id: d.value,
            display: d.name,
            type: d.type,
            subtype: null,
            method: e.type === "mouseenter" ? "hover" : "click",
            source: d.source,
            sourceSpecific: {
              pathway_name: d.pathway_name,
              section: d.section,
              group: d.group,
            }
          }));
        }

        if (d.depth === "1" || d.depth === "2") {
          e.target.style.strokeWidth =
            d.depth === "1"
              ? groupHighlightStroke
              : d.depth === "2"
              ? metHighlightStroke
              : "1";

          const tooltipX = mobile
            ? 160
            : e.nativeEvent.clientX + 230 > width ||
              e.nativeEvent.clientX > bounds
            ? e.nativeEvent.offsetX - 230 / 2
            : e.nativeEvent.offsetX;

          setValueref(d.value + delimiter + d.comparison + delimiter + d.section);
          setXref(tooltipX);
          setYref(e.nativeEvent.offsetY + 16);
          setShowTooltipref(true);
          setTooltipTyperef(d.depth === "2" ? "metabolite" : "group");
        }
        if ((e.type === "click") && (d.depth === "2")) logger.logUserAction(`user clicked on ${d.type} ${d.name}`);
      }
    },
    [setShowTooltipref, setValueref, setXref, setYref, dispatch, width]
  );

  const goToPathwayProfile = useCallback(() => {
    const pathway = selectedState.sourceSpecific.pathway_name;

    dispatch(interactionsUpdateSelection({
      id: pathway,
      display: pathway,
      type: "pathway",
      subtype: null,
      method: "click",
      source: "explainer",
      sourceSpecific: null,
    }));
  }, [selectedState]);

  const metaboliteInSection =
    typeof circlePackData.find(
      (d) => d.data && d.data.chemical_id === selectedState.id
    ) === "object";

  const buttonsList = [
    {
      label: "View in Impact Explorer",
      target: urljoin(match.url, tabs[1].url),
      click: () => {
        logger.logUserAction(`user clicked on View in Impact Explorer link`);
      },
    },

    {
      label: "View Metabolite Profile",
      target: urljoin(match.url, tabs[2].url),
      click: () => {
        logger.logUserAction(`user clicked on View Metabolite Profile link`);
      },
    },
    {
      label: "View Pathway Profile",
      target: urljoin(match.url, tabs[2].url),
      click: () => {
        logger.logUserAction(`user clicked on View Pathway Explorer`);
        goToPathwayProfile();
      },
    },
  ];

  const visibleButtons =
    selectedState.sourceSpecific && selectedState.sourceSpecific.pathway_name
      ? buttonsList
      : buttonsList.slice(0, 1);

  return (
    <figure>
      <Tooltip section={id} />
      {selectedState.method === "click" && metaboliteInSection && (
        <div
          className="button-vertical-wrapper absolute-right"
          style={{ zIndex: 1, top: "8%" }}
        >
          {!mobile && (
            <>
              {visibleButtons.map((d, i) => {
                return (
                  <Link
                    to={d.target}
                    onClick={d.click}
                    color="white"
                    className="transparent-link-button block slide"
                    key={i}
                  >
                    <div
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      <p className="body-sm">{d.label}</p>
                      <Icon icon="angle-right" size="large" color="default" />
                    </div>
                  </Link>
                );
              })}
            </>
          )}
        </div>
      )}
      <CirclePack
        size={size}
        circlePackData={circlePackData}
        id={id}
        packSize={packSize}
        handleEvents={handleEvents}
        selectedCircle={selectedState.id}
        selectedMethod={selectedState.method}
        selectedGroup={
          selectedState.sourceSpecific ? selectedState.sourceSpecific.group : ""
        }
      />
    </figure>
  );
};

Figure.propTypes = {
  size: PropTypes.number,
  circlePackData: PropTypes.array,
  id: PropTypes.string,
  packSize: PropTypes.number,
};

Figure.defaultProps = {
  size: 0,
  circlePackData: [],
  id: "",
  packSize: 0,
};

export default memo(Figure);
