import React from 'react';
import PropTypes from 'prop-types';
import cytoscape from 'cytoscape';
import contextMenus from 'cytoscape-context-menus';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
//import Worker from './PathwayD3.worker.js?worker';

import { Button, DynamicModal, Label, Material } from '../index.jsx';
import Select from '../controls/Select.jsx';
import RefreshIndicator from '../controls/RefreshIndicator.jsx';
import FileUtils from '../../utils/FileUtils.js';
import pdfMake from '../../utils/pdfMake.js';
import SettingsMap from '../../utils/SettingsMap.js';
import CytoscapeStyle from './CytoscapeStyle.js';
import './Cytoscape.scss';
import { Logger, Log } from '../../utils/Logger';
import { AppInsightLogLevel } from '../../enums/Enums';

const logger = new Logger();
import coseBilkent from 'cytoscape-cose-bilkent';
import { Auditor } from '@/components/util/Auditor.jsx';
import { getWorkerUrl } from '@/utils/WebWorkerUtils.js';

cytoscape.use(coseBilkent);
cytoscape.use(contextMenus, $);

export default class Cytoscape extends Auditor {

	static propTypes = {
		type: PropTypes.string,

		/** Function to invoke when the cytoscape instance is ready */
		onReady: PropTypes.func,

		/** Type of cytoscape layout to use */
		layout: PropTypes.string,

		/** The pathway the map will display*/
		pathway: PropTypes.string,

		/** An array of cytoscape styles to apply to the graph*/
		style: PropTypes.array,

		studyType: PropTypes.string,

		wheelSensitivity: PropTypes.number,

		zoomTick: PropTypes.number,
		maxZoom: PropTypes.number,
		minZoom: PropTypes.number,
		webWorker: PropTypes.bool
	};

	static defaultProps = {
		onReady: null,
		layout: 'preset',
		style: null,
		pathway: 'Metabolic Pathways',
		studyType: 'standard',
		wheelSensitivity: 0.5,
		zoomTick: 0.01,
		maxZoom: 2.2,
		minZoom: 0.1,

		config: {
			data: { name: 'empty dataset' },
			pathways: {}
		},
		webWorker: false
	};

	constructor(props) {
		super(props);

		const { config, layout, pathway, webWorker } = this.props;
		this.pathways = {};

		this.state = {
			config, layout, pathway, webWorker,
			cy: null,
			capture: null,
			ready: false,
			style: CytoscapeStyle.createStyles(),
			working: true,
			enzymes: null,
			...super.state
		};
	}

	componentDidMount() {
		let { config, layout, pathway, style, webWorker } = this.state;
		if (layout === 'd3' && webWorker) {
			this.worker = new Worker(new URL('./PathwayD3.worker.js', import.meta.url), { format: 'es', type: 'module' });
			this.worker.onmessage = this.onWorkerMessage;
			this.runWorkerLayout(config.elements);
			//style = CytoscapeStyle.createStyles();
			return;
		}

		config.container = this.container;

		config.style = style;
		config.wheelSensitivity = this.props.wheelSensitivity;
		config.layout = {
			name: layout,
			ready: this.onCytoReady,
		};

		switch (layout) {
			default:
			case "preset":
				config.style = CytoscapeStyle.createMapStyles();
				config.elements = pathway in config.pathways ? config.pathways[pathway].elements : [];
				break;
			case "d3":
			case "cose-bilkent":
				Object.assign(config.layout, {
					nodeDimensionsIncludeLabels: true,
					refresh: 30,
					fit: false,
					padding: 5,
					nodeRepulsion: 10000,
					// Ideal (intra-graph) edge length
					idealEdgeLength: 100,
					// Divisor to compute edge forces
					edgeElasticity: 0.1,
					// Nesting factor (multiplier) to compute ideal edge length for inter-graph edges
					nestingFactor: .25,
					// Gravity force (constant)
					gravity: 0.25,
					// Maximum number of iterations to perform
					numIter: 2500,
					tile: true,
					tilingPaddingVertical: 15,
					// Amount of horizontal space to put between degree zero nodes during tiling (can also be a function)
					tilingPaddingHorizontal: 100,
					// Gravity range (constant)
					gravityRange: 1.9,
					// Initial cooling factor for incremental layout
					initialEnergyOnIncremental: 0.5
				});
				break;
		}
		this.updateData({ layout: config.layout }, () => {
			/** Before CytoscapeJS is initialized,
			 *  a delay is necessary to allow the component
			 *  time to render its preloader
			**/
			const layoutDelay = 1000;
			setTimeout(() => {
				this.startCytoscape(config);
			}, layoutDelay);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { config, cy, layout, webWorker } = this.state;
		let { pathway } = this.props;
		let shouldUpdateStyles = prevProps.colorKey !== this.props.colorKey;
		if (this.props.layout === 'preset') {
			if (prevProps.pathway !== pathway) {
				shouldUpdateStyles = true;
				config.elements = config.pathways[pathway].elements;
				if (cy) {
					//this.setState({working:true});

					let els = cy.elements().remove();
					if (prevProps.pathway) this.pathways[prevProps.pathway] = els;
					cy.add(this.pathways[pathway] || this.props.config.elements);
					cy.layout(layout).run();
					//setTimeout(this.updateElements, 100, this.props.config.elements);
				}
				this.updateData({ pathway: pathway }, this.onUpdate);
			}
		}
		else if (config.elements !== this.props.config.elements) {
			config.elements = this.props.config.elements;
			if (cy) {
				//this.setState({working:true});
				cy.elements().remove();
				if (this.props.layout === 'd3' && webWorker) {
					this.runWorkerLayout(config.elements);
				}
				else {
					cy.add(config.elements);
					setTimeout(this.updateConfig, 100, this.props.config);
				}
			}
			else this.updateConfig(this.props.config);
		}

		if (shouldUpdateStyles) {
			this.updateStyles();
		}
	}

	componentWillUnmount() {
		if (this.worker) this.worker.terminate();

		if (this.state.cy) {
			this.state.cy.destroy();
		}
	}

	updateConfig(config) {
		this.setState({ config });
	}

	runWorkerLayout(elements) {
		this.setState({ working: true });
		this.worker.postMessage({
			layout: 'preset',
			elements: elements,
			type: 'forceSim',
			strength: 1,
			distance: 20,
			tileDisconnectedNodes: true,
			tilingPaddingVertical: 15,
			tilingPaddingHorizontal: 100,
		});
	}

	onWorkerMessage = (e) => {
		const { style, ready, cy } = this.state;
		const { data } = e;
		const { maxZoom, minZoom, wheelSensitivity } = this.props;

		let config;
		switch (data.type) {
			case "progress":
				//console.log('d3progress:', data.progress);
				break;

			case "complete":
				//this.updateElements(data.elements);
				//this.onReady();
				//console.log(data.elements);
				config = {
					style: style,
					elements: data.elements,
					container: this.container,
					wheelSensitivity, maxZoom, minZoom,
					layout: {
						name: 'preset',
						ready: this.onCytoReady
					}
				};
				this.updateData({ layout: config.layout, supers: data.supers }, () => {
					if (ready && cy) {
						cy.add(config.elements);
						cy.layout(config.layout).run();
						if (this.props.onLayout) this.props.onLayout();
						return;
					}
					/** Before CytoscapeJS is initialized,
					*  a delay is necessary to allow the component
					*  time to render its preloader
					**/
					const layoutDelay = 1000;
					setTimeout(() => {
						this.startCytoscape(config);
					}, layoutDelay);
				});
				break;
		}
	}

	updateData(data, callback) {
		this.setState({ ...data }, callback);
	}

	onUpdate = () => {
		const { onUpdate } = this.props;
		if (onUpdate) onUpdate();
	};

	updateStyles() {
		const { cy } = this.state;
		if (!cy) return;
		cy.style().fromJson(
			this.props.layout === 'preset' ? CytoscapeStyle.createMapStyles() : CytoscapeStyle.createStyles()
		).update();
	}

	startCytoscape(config) {
		this.setState({
			cy: cytoscape(config)
		});
	}

	projectIntoViewport(clientX, clientY) {
		let cy = this.state.cy,
			offsets = this.findContainerClientCoords(),
			offsetLeft = offsets[0],
			offsetTop = offsets[1],
			scale = offsets[4],
			pan = cy.pan(),
			zoom = cy.zoom(),
			x = ((clientX - offsetLeft) / scale - pan.x) / zoom,
			y = ((clientY - offsetTop) / scale - pan.y) / zoom;

		return [x, y];
	}

	findContainerClientCoords() {
		if (this.containerBB) {
			return this.containerBB;
		}

		let container = this.state.cy.container();
		let rect = container.getBoundingClientRect();
		let style = window.getComputedStyle(container);
		let styleValue = function (name) { return parseFloat(style.getPropertyValue(name)); };

		let padding = {
			left: styleValue('padding-left'),
			right: styleValue('padding-right'),
			top: styleValue('padding-top'),
			bottom: styleValue('padding-bottom')
		};

		let border = {
			left: styleValue('border-left-width'),
			right: styleValue('border-right-width'),
			top: styleValue('border-top-width'),
			bottom: styleValue('border-bottom-width')
		};

		let clientWidth = container.clientWidth;
		let clientHeight = container.clientHeight;

		let paddingHor = padding.left + padding.right;
		let paddingVer = padding.top + padding.bottom;

		let borderHor = border.left + border.right;

		let scale = rect.width / (clientWidth + borderHor);

		let unscaledW = clientWidth - paddingHor;
		let unscaledH = clientHeight - paddingVer;

		let left = rect.left + padding.left + border.left;
		let top = rect.top + padding.top + border.top;

		return (this.containerBB = [
			left,
			top,
			unscaledW,
			unscaledH,
			scale
		]);
	}

	zoomToFit = (e) => {
		const { cy } = this.state;
		let group = cy.$(':visible');//cy.$(`#${id}`).neighborhood();
		if (group) cy.fit(group);
	};

	zoomIn = (e) => {
		this.updateZoom(this.state.cy.zoom() + this.props.zoomTick);
	};

	zoomOut = (e) => {
		this.updateZoom(this.state.cy.zoom() - this.props.zoomTick);
	};

	updateZoom(level) {
		const { cy } = this.state;
		let e = cy.extent(),
			c = cy.container();
		let pan = { x: $(c).width() / 2, y: $(c).height() / 2 };

		cy.zoom({ level, renderedPosition: pan });
	}
	hideNode = (e) => {
		//console.log('hide node', e);
		e.target.addClass('manually-hidden');
		e.target.successors().addClass('manually-hidden');
	};

	restoreNodes = (e) => {
		const { cy } = this.state;
		if (!cy) return;
		//console.log('hide node', e);
		cy.nodes('.manually-hidden').removeClass('manually-hidden');
		//e.target.addClass('manually-hidden');
	};

	hideLabels = (e) => {
		const { cy } = this.state;
		if (!cy) return;
		cy.nodes('node[type!="superpathway"]').addClass('no-label');
	};

	showLabels = (e) => {
		const { cy } = this.state;
		if (!cy) return;
		cy.nodes('.no-label').removeClass('no-label');
	};

	hideUnknowns = (e) => {
		const { cy } = this.state;
		if (!cy) return;
		cy.$('node[isUnknown="true"]').addClass('manually-hidden');
	};

	showUnknowns = (e) => {
		const { cy } = this.state;
		if (!cy) return;
		cy.$('node[isUnknown="true"]').removeClass('manually-hidden');

	};

	openEnymeLink = (e) => {
		let ecNumber = e.target.data('ecNumber');

		if (!ecNumber) return null;
		//console.log('BRENDA', ecNumber);
		if (ecNumber.indexOf('///') < 0) {
			window.open(`https://www.brenda-enzymes.info/enzyme.php?ecno=${ecNumber.trim()}`, "_blank");
			return 'single';
		}

		let numbers = ecNumber.split('///');

		let modalContent = numbers.map((num, n) => {
			let url = `https://www.brenda-enzymes.info/enzyme.php?ecno=${num.trim()}`;
			return <p key={n}><a className="NavLink primary" href={url} target="_blank" rel="noreferrer">{url}</a></p>;
		});

		this.setState({ modalContent }, () => {
			//console.log(this.modal);
			window.modal = this.modal;
			//$(`#${this.props.className}-enzymes`).modal('show');
			this.modal.show();
		});

		return 'multi';
	};

	onCytoReady = (e) => {
		let { ready, supers, cy } = this.state;

		if (ready) {
			//this.setState({working:false});
			this.postWorkerLayout(supers, cy, false);
			return;
		}

		if (!cy) cy = e.cy;

		cy.contextMenus({
			menuItems: [
				{ id: 'hide', content: 'hide', selector: 'node', show: true, onClickFunction: this.hideNode },
				{ id: 'restore', content: 'Restore manually hidden nodes', selector: 'node', show: true, coreAsWell: true, onClickFunction: this.restoreNodes },
				{ id: 'zoomFit', content: 'Zoom to fit', selector: false, show: true, coreAsWell: true, onClickFunction: this.zoomToFit },
				{ id: 'enzymeLink', content: 'BRENDA Enzyme link', selector: 'node[compoundType="Enzyme"][?ecNumber]', show: true, onClickFunction: this.openEnymeLink },
				{ id: 'hideLabels', content: 'Hide labels', selector: 'node', show: true, coreAsWell: true, onClickFunction: this.hideLabels },
				{ id: 'showLabels', content: 'Show labels', selector: 'node', show: true, coreAsWell: true, onClickFunction: this.showLabels },
				{ id: 'hideUnknowns', content: 'Hide unknowns', selector: 'node', show: true, coreAsWell: true, onClickFunction: this.hideUnknowns },
				{ id: 'showUnknowns', content: 'Show unknowns', selector: 'node', show: true, coreAsWell: true, onClickFunction: this.showUnknowns },
			]
		});

		cy.on('tap', 'node', this.onNodeSelect);

		this.setState({ ready: true, cy }, () => {
			//console.log('SUPERS', this.state.supers);
			this.postWorkerLayout(supers, cy);
		});
	}

	postWorkerLayout(supers, cy, update = true) {
		if (supers) {
			let sx = 0, sy = 0, grid = Math.ceil(supers.length / 2);
			let row = 0, col = 0, maxH = 0, box, g, superGroup;
			supers.forEach((superId, n) => {
				g = cy.$(`#${superId}`);
				g = g.union(g.neighborhood()); //add subs
				g = g.union(g.neighborhood()); //add metabolites
				superGroup = superGroup ? superGroup.union(g) : g;

				box = g.boundingBox();
				let r = Math.floor(n / grid),
					c = n % grid;

				if (r != row) {
					row++;
					sx = 0;
					sy += maxH;
					maxH = 0;
				}

				maxH = Math.max(maxH, box.h);

				g.shift({ x: -box.x1 + sx, y: -box.y1 + sy });

				sx += box.w;
			});

			if (superGroup) {
				let superBox = superGroup.boundingBox();

				g = cy.$('node[isUnknown="true"]');
				box = g.boundingBox();

				g.shift({ x: 0, y: -box.y1 + superBox.h });

			}
		}
		this.delayReady(cy, update);
	}

	onNodeSelect = (e) => {
		let node = e.target,
			type = node.data('type');

		if (type != "superpathway") return false;

		this.selectSuperPathway(node);
		return true;
	};

	selectSuperPathway = (node) => {
		const { cy } = this.state;
		let id = node.id();
		cy.$('*').unselect();
		cy.$('*').addClass('hidden');
		let group = cy.$(`#${id}`);
		group = group.union(group.neighborhood()); //add subs
		group = group.union(group.neighborhood()); //add metabolites
		group.removeClass('hidden');
		cy.$(`#${id}`).select();
		cy.fit(group);
	};

	delayReady(cy, update = true) {
		setTimeout(() => {
			this.setState({ working: false });
			if (this.props.onReady) this.props.onReady(cy, update);
		}, 1000);
	}

	getCy() {
		return this.state.cy;
	}

	onCapture = (format) => {
		this.logEvent("ExportingView", true);
		this.capture(format);
	};

	capture(format = { name: "png" }) {
		let capture;
		const { cy } = this.state,
			width = 5 * cy.width(),
			height = 5 * cy.height();

		let colors = SettingsMap.colors;

		switch (format.name) {
			default:
			case 'png':
				capture = cy.png({ output: 'blob', scale: 5, bg: colors.defaults.background });
				break;

			case 'jpg':
				capture = cy.jpg({ output: 'blob', scale: 5, bg: colors.defaults.background });
				break;

			case 'svg':
				capture = cy.png({ output: 'base64uri', scale: 5, bg: colors.defaults.background });
				capture = `
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}">       
            <image xlink:href="${capture}" width="${width}" height="${height}"/>    
          </svg>`;
				break;

			case 'pdf':
				capture = cy.png({ output: 'base64uri', scale: 5, bg: colors.defaults.background });
				break;
		}

		if (format.name === 'pdf') {
			pdfMake.createPdf({
				content: [{ image: capture, width, height }],
				pageOrientation: 'landscape',
				pageSize: { width, height },
				pageMargins: [0, 0, 0, 0],
			}).download('capture.pdf');
			return;
		}

		FileUtils.download(capture, 'capture.' + format.name, format.name);
		//Audit Log
		let imageType = format.name;
		let projectId = localStorage.getItem("ProjectId");
		this.state.logger.logExportCytoscapteImage(projectId, imageType);
	}

	logEvent(property, value) {
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageEvent_PathwayExplorer');
		logFilterChange.AddProperty(property, value);
		logger.doLog(logFilterChange);
	}

	generateClassNames = () => {
		const { ready, working } = this.state;
		const { className } = this.props;
		let classNames = [];
		classNames.unshift(this.constructor.name);
		if (className) classNames.push(className);
		if (!ready || working) classNames.push('hidden');

		return classNames;
	};

	containerRef = (container) => { this.container = container; };

	modalRef = (modal) => { this.modal = modal; };

	getModalContent = () => (this.state.modalContent);

	renderSelectLabel = () => <Label size="large" color="white">Export&nbsp;&nbsp;&nbsp;<Material icon="wallpaper" size="large" /></Label>;

	render() {
		let loader = null;
		let hidden = this.props.hidden ? ' hidden' : '';
		let formats = [
			{ name: 'png' }, { name: 'jpg' }, { name: 'svg' }, { name: 'pdf' },
		];

		const { enzymes, ready, working } = this.state;
		if (!ready || working) loader = <RefreshIndicator />;
		return (
			<div className={"cyto-wrapper" + hidden}>
				{super.render()}
				<Select
					className="cyto-capture right"
					color="primary"
					options={formats}
					defaultLabel={this.renderSelectLabel()}
					updateLabel={false}
					onSelect={this.onCapture} />

				<div className="zoom-controls">
					{/*<Button onClick={this.direct}>
              <Material icon="device_hub"/>
            </Button>*/}

					<Button data-testId="zoomFit" className="alt2" color={'tertiary'} onClick={this.zoomToFit} title="Zoom to fit">
						<Material size="large" icon="filter_center_focus" />
						<span className="secondary">FIT 1:1</span>
					</Button>

					<Button data-testId="zoomIn" className="alt2" color={'tertiary'} onClick={this.zoomIn} title="Zoom in">
						<Material size="large" icon="add_box" />
					</Button>

					<Button data-testId="zoomOut" className="alt2" color={'tertiary'} onClick={this.zoomOut} title="Zoom out">
						<Material size="large" icon="indeterminate_check_box" />
					</Button>
				</div>

				{loader}
				<div className={this.generateClassNames().join(' ')} ref={this.containerRef} />
				<DynamicModal title={<span className="large primary">Multiple links detected</span>} id={`${this.props.className}-enzymes`} modalRef={this.modalRef} renderer={this.getModalContent} />
			</div>
		);
	}
}