import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { group } from "d3";
import { delimiter } from "@/discovery-panel/utils/constants";

import Table from "../table/Table";
import mapToArray from "../../utils/mapToArray";
import { dataSelector } from "../../redux/selectors/data.selector";
import { setFilterAssociation } from "../../redux/reducers/discoveryPanels.reducer";
import * as styles from "./associationTables.module.scss";
import { filterSelector } from "../../redux/selectors/filter.selector";
import urljoin from "url-join";

const associationColumns = [
  {
    key: "association",
    value: "Association",
    width: 40,
    align: "left",
  },
  { key: "total", value: "Total Metabolites", width: 20, align: "right" },
  {
    key: "significant",
    value: "Significant Metabolites",
    width: 20,
    align: "right",
  },
  {
    key: "percentSignificant",
    value: "Percent Significant",
    width: 20,
    align: "right",
  },
];

const AssociationTables = ({ match }) => {
  const dispatch = useDispatch();
  const { associations } = useSelector(dataSelector);
  const { tabs } = useSelector(filterSelector);

  const onClick = useCallback(
    (type) => () => {
      dispatch(setFilterAssociation(type));
    },
    []
  );

  const tableData = useMemo(() => {
    const grouping = mapToArray(
      group(associations, (d) => d.association + delimiter + d.type)
    ).map((d) => {
      const [association, type] = d.key.split(delimiter);
      return {
        type,
        association,
        total: d.value.length,
        significant: d.value.filter((e) => e.metabolite_table_significant > 0)
          .length,
        percentSignificant: Math.round(
          (
            d.value.filter((e) => e.metabolite_table_significant > 0).length /
            d.value.length
          ).toFixed(2) * 100
        ),
      };
    });

    return [
      {
        label: "Biological Themes",
        data: grouping.filter((d) => d.type === "Biological"),
        action: onClick("Biological"),
      },
      {
        label: "Disease & Disorders",
        data: grouping.filter((d) => d.type === "Disease"),
        action: onClick("Disease"),
      },
      {
        label: "Pathways",
        data: grouping.filter((d) => d.type === "Pathway"),
        action: onClick("Pathway"),
      },
    ];
  }, [associations]);
  
  return (
    <div className={styles.associationTables}>
      <div className={styles.wrapper}>
        <Table
          tableData={tableData}
          defaultSort={"significant"}
          flow="row"
          showLabel={true}
          showButton={true}
          buttonLabel="View in Impact Explorer"
          columns={associationColumns}
          height={100}
          tableType="association"
          tableSwitchTabTo={urljoin(match.url, tabs[1].url)}
          splitHeader={true}
        />
      </div>
    </div>
  );
};

export default AssociationTables;
