import { createSelector } from '@reduxjs/toolkit';

export const availableRolesSelector = createSelector(
	(state) => state,
	(state) => state.availableRoles
);

export const availableRolesIsLoadingSelector = createSelector(
	availableRolesSelector,
	(availableRoles) => availableRoles.availableRolesIsLoading
);

export const availableRolesHasErroredSelector = createSelector(
	availableRolesSelector,
	(availableRoles) => availableRoles.availableRolesHasErrored
);
