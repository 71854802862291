import React from "react";
import { Box, Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import { NavItem } from "./components/NavItem";
import { Gray } from "metabux/theme/colors";
import { DefaultNavItemRenderer } from "./components/DefaultNavItemRenderer";

export const Navigation = ({ items, itemRenderer, sx, testId = "navigation", ...props }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

	return (
    <Stack
      sx={{
        background: Gray[900],
        height: "100vh",
        padding: 6,
        width: isDesktop ? 250 : "fit-content",
        ...sx,
      }}
      data-testid={testId}
      {...props}
    >
      {items.map((group, n) => {
        const isNotLast = n !== items.length - 1
        return (
          <Box key={n}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                ...group.sx,
              }}
            >
              {group.items.map((item, n) => (
                <NavItem
                  key={item.href + "-" + n}
                  {...item}
                />
              ))}
            </Stack>
            {isNotLast && <Divider sx={{ marginY: 2, borderColor: Gray[600], width: 24, marginX: isDesktop ? 2 : "auto" }} />}
          </Box>
        );
      })}
    </Stack>
  );
};
