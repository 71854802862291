'use strict';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from '../../../components/layout/AppHeader.jsx';
import {IdleTimer} from '../../../components/index.jsx';
import { 
  projectsFetchData,
  discoveryPanelsFetchData,
	discoveryPanelsMockData
} from '@/redux/actions/projects';
import HomeContainer  from '../../home/HomeContainer.jsx';
import '../../../theme/theme.css';
import { projectsDataSelector, projectsIsLoadingSelector, projectsDiscoveryPanelsSelector } from '../../../redux/selectors/projects.selector';
import('../../../theme/css/material-icons.css');

export const MainLayout = ({
  match,
  ...props
}) => {
  const projects = useSelector(projectsDataSelector);
  const isLoading = useSelector(projectsIsLoadingSelector);
  const discoveryPanels = useSelector(projectsDiscoveryPanelsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projects?.length === 0 && !isLoading) {
      dispatch(projectsFetchData());
      dispatch(discoveryPanelsFetchData());
    }
  }, [ projects ]);
  
  return (
    <div data-testid="mainLayout">
      <IdleTimer {...props} />
      <AppHeader />
      <HomeContainer
        {...props}
        match={match}
        isLoading={isLoading}
        projects={projects}
        discoveryPanels={discoveryPanels}
      />
      {/* <AppFooter /> */}
    </div>
  );
};
