'use strict';
import { useCurrentUserQuery } from '@/redux/services/user.api';
import { set } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export const usePendo = () => {
	const { data: user } = useCurrentUserQuery();
	const [initialized, setInitialized] = useState(false);
	const pendo = useRef();

	useEffect(() => {
		pendo.current = window.pendo;
	}, []);

	const initialize = useCallback((accountId="generic") => {
		if (!user || !pendo.current || initialized) return;
		let signup_source = localStorage.getItem("signup_source");
		if (signup_source == null) { signup_source = "direct"; }
		let isDemoUser = user.usingDemoProjects || false;
		let signupDate = user.signupDate || null;
		let convertedDate = user.convertedDate || null;

		 
		pendo.current.initialize({
			visitor: {
				id: user.userId,   // Required if user is logged in
				//email: user.emails ? user.emails[0] : []
				email: user.email
				// role:         // Optional

				// You can add any additional visitor level key-values here,
				// as long as it's not one of the above reserved names.
			},

			account: {
				id: accountId, // Highly recommended
				signup_source: signup_source,
				isDemo: isDemoUser,
				signupDate: signupDate, //Optional
				convertedDate: convertedDate
				// name:         // Optional
				// planLevel:    // Optional
				// planPrice:    // Optional

				// You can add any additional account level key-values here,
				// as long as it's not one of the above reserved names.
			}
		});
		setInitialized(true);
	}, [user]);

	const identify = useCallback((accountId="generic") => {
		pendo.current.identify({account: {id: accountId}});
	}, [user]);

	return {
		pendo: pendo.current,
		identify,
		initialize,
		initialized
	};
};
