'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@/lenses/uilenses/components/Modal/Modal';

/** A Modal component tha displays dynamic content, 
	content can be created with any combination of
	the children, component, or renderer props */
export const DynamicModal = (props) => {

	const { children, component, componentProps, renderer, modalRef, ...restProps } = props;
	return (
		<Modal {...restProps} >
			{children}
			{component &&
				React.createElement(component, componentProps)
			}
			{renderer &&
				renderer()
			}
		</Modal>
	);
}

DynamicModal.propTypes = {
	/** The component class used to render the modal content*/
	component: PropTypes.func,

	/** Props passed down to the modal content component*/
	componentProps: PropTypes.object,

	/** Renderer function used to render the modal content*/
	renderer: PropTypes.func,
};

export default DynamicModal