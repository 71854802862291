'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './Logo.scss';

/** A simple Icon component using font awesome */
const Logo = ({color, size, className}) => {
	
	let generateClassNames = (base) => {
		let classNames = [];
		classNames.push(size);
		classNames.unshift(base);
		if(className) classNames.unshift(className);

		return classNames;
	};

	return (
		<svg className={generateClassNames('Logo').join(' ')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<g>
				<path className={'fill-'+color} d="M25.967,3.5A10.724,10.724,0,0,1,28.1,5.367C16.278,18.789,13.344,13.1,12.544,12.033,10.322,9.278,5.078,10.078.9,11.678A15.679,15.679,0,0,1,2.411,8.389c4.889-1.156,8.356-.267,9.778,1.867,2.844,4.356,9.6-1.689,13.778-6.756" transform="translate(-0.1 -0.389)"/>
				<path className={'fill-'+color} d="M16.2,19.467C14.244,16.533,8.289,16,.2,19.911a21.052,21.052,0,0,0,.711,3.022c3.556-2.311,12.533-5.422,15.644-1.6.8,1.067,3.822,6.756,15.644-6.756a16.135,16.135,0,0,0-.978-3.378C27.311,16.444,19.489,24.267,16.2,19.467" transform="translate(-0.022 -1.244)"/>
				<path className={'fill-'+color} d="M18.511,24.311c-2.044-3.022-8.267-3.467-16.711.8,0,.089.089.178.178.267A20.094,20.094,0,0,0,3.4,27.689c3.556-2.311,12.533-5.333,15.556-1.511.8.978,3.378,6.044,13.422-4.267a16.64,16.64,0,0,0,.444-4.711c-4.267,5.156-11.289,11.644-14.311,7.111" transform="translate(-0.2 -1.911)"/>
				<path className={'fill-'+color} d="M20.878,29.522c-1.956-2.933-8-3.467-16.178.533a17.3,17.3,0,0,0,2.311,2.133c4.089-2.133,11.556-4.178,14.311-.8a3.99,3.99,0,0,0,3.289,2.044A15.672,15.672,0,0,0,31.278,26.5c-3.822,3.733-8.178,6.311-10.4,3.022" transform="translate(-0.522 -2.944)"/>
				<path className={'fill-'+color} d="M9.4,34.049a15.565,15.565,0,0,0,3.822,1.511c3.289-1.067,7.022-1.689,9.422-.356a6.171,6.171,0,0,0,1.156-.444c-.089-.089-.178-.267-.267-.356-1.778-2.756-7.022-3.378-14.133-.356" transform="translate(-1.044 -3.561)"/>
				<path className={'fill-'+color} d="M10.789,7.689c.8.978,3.556,6.311,14.311-5.244A12.255,12.255,0,0,0,22.433,1.2c-4.089,4.444-9.422,8.444-12,4.622A5.7,5.7,0,0,0,6.7,3.6,15.321,15.321,0,0,0,4.3,6c2.667-.356,5.156,0,6.489,1.689" transform="translate(-0.478 -0.133)"/>
				<path className={'fill-'+color} d="M21.022.444A16.223,16.223,0,0,0,17.467,0c-3.022,2.489-6.133,4-8.178,2.044-.267.178-.622.356-.889.533a2.705,2.705,0,0,1,.711.711c.8.889,3.2,5.511,11.911-2.844" transform="translate(-0.933 0)"/>
				<path className={'fill-'+color} d="M14.044,14.711C12.267,12.044,7.2,11.422.178,14.178.089,15.333,0,16.4,0,17.467c4.089-2.133,11.644-4.356,14.489-.889.889,1.067,3.911,6.933,16.089-7.289A17.415,17.415,0,0,0,28.889,6.8c-4,5.244-11.556,12.711-14.844,7.911" transform="translate(0 -0.756)"/>
			</g>
		</svg>
	);
};

Logo.propTypes = {
	/** The color of the logo  */
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive', 'white']),

	/** The size of the logo */
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large', 'xlarge']),
};

Logo.defaultProps = {
	color: 'white', 
	size: 'normal',
};

export default Logo;