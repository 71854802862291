import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.css';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import './Toast.scss';

const POSITION = {
	TOP_RIGHT: 'top-right',
	TOP_CENTER: 'top-center',
	TOP_LEFT: 'top-left',
	BOTTOM_RIGHT: 'bottom-right',
	BOTTOM_CENTER: 'bottom-center',
	BOTTOM_LEFT: 'bottom-left'
};

class Toast extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="ToastContainer">
				<ToastContainer className="Toast" {... this.props} />
			</div>
		);
	}
}

Toast.propTypes = {
	progressClassName: PropTypes.string,
	transition: PropTypes.oneOf([Slide, Zoom, Flip, Bounce]),
	draggable: PropTypes.bool,
	autoClose: PropTypes.number,
	position: PropTypes.oneOf([POSITION.TOP_RIGHT, POSITION.TOP_CENTER, POSITION.TOP_LEFT, POSITION.BOTTOM_RIGHT, POSITION.BOTTOM_CENTER, POSITION.BOTTOM_LEFT])
};

Toast.defaultProps = {
	progressClassName: 'progressbar',
	transition: Zoom,
	draggable: false,
	autoClose: 2000,
	position: POSITION.BOTTOM_RIGHT
};

export default Toast;