import React, { useCallback } from "react";
import { useCurrentUserQuery } from "../redux/services/user.api";
import { map, flatten, intersection } from 'lodash';

export const usePermissions = () => {
	const { data: user } = useCurrentUserQuery();

	const checkUserHasPermissions = useCallback((permissionToCheck) => {
		if (!user || !user.roles) return false;
		const userPermissionArray = flatten(map(user.roles, 'permissions'));

		//If permissionToCheck is array of permissions, look for an intersection else, check if single permission exists
		if (Array.isArray(permissionToCheck)) {
			return intersection(userPermissionArray, permissionToCheck).length > 0;

		} else {
			return userPermissionArray.indexOf(permissionToCheck) !== -1;
		}
	}, [user]);

	const checkUserHasRole = useCallback((roleToCheck) => {

		if (!user || !user.roles) return false;
		const userRoleArray = flatten(map(user.roles, 'name'));

		//If permissionToCheck is array of permissions, look for an intersection else, check if single permission exists
		if (Array.isArray(roleToCheck)) {
			return intersection(userRoleArray, roleToCheck).length > 0;
		}
		else {
			return userRoleArray.indexOf(roleToCheck) !== -1;
		}

	}, [user]);

	const checkUserHasOneRole = useCallback((roleToCheck) => {

		if (!user || !user.roles) return false;
		const userRoleArray = flatten(map(user.roles, 'name'));

		return userRoleArray.length === 1 && userRoleArray.indexOf(roleToCheck) === 0;

	}, [user]);


	return {
		checkUserHasPermissions,
		checkUserHasRole,
		checkUserHasOneRole
	};
};

export const withPermissions = (Component) => ((props) => {
	const permissions = usePermissions();
	return <Component {...props} {...permissions} />;
});