import { put, take, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';

import { 
  getAssociations,
  setAssociations,
  getMetabolites,
  setMetabolites,
  getPathways,
  setPathways,
  setResults,
  initFilter,
  getResults, 
  setDynamicAssociations,
  setDynamicResults} from '../reducers/discoveryPanels.reducer';

export const initDiscoveryPanels = createAction('discoveryPanels/init');

function* initSaga(action) {
  if (action?.payload) {
    yield put(getPathways(action.payload));
    while (yield take(setPathways().type)) {
      yield put(getMetabolites(action.payload));
      while (yield take(setMetabolites().type)) {
        yield put(getResults(action.payload));
        while (yield take(setResults().type)) {
          yield put(getAssociations(action.payload));
          while (yield take(setAssociations().type)) {
            yield put(initFilter());
            yield put(setDynamicResults());
            yield put(setDynamicAssociations());
          }
        }
      }
    }
  }
}

export function* initSagas() {
  yield takeLatest(initDiscoveryPanels, initSaga);
}