'use strict';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import DropdownContainer from './DropdownContainer.jsx';
import Label from '../components/controls/Label.jsx';
import StatsTable from './StatsTable.jsx';
import './StatsDropdown.scss';

export const StatsDropdown = ({ className, onItemToggle, onRowClick, ...props }) => {

	const toggleHandler = useCallback((selections) => {
		//console.log('onItemToggle', selections);
		//const {onItemToggle} = this.props;
		if (onItemToggle) onItemToggle(selections);
	}, [onItemToggle]);

	const rowClickHandler = useCallback((id) => {
		if (onRowClick) onRowClick(id);
	}, [onRowClick]);

	const { activeStats, selection, stats, color, showHidden } = props;
	let rowProps = {
		//onFocus: this.onFocus,
	};
	let children = [
		(<StatsTable key={1}
			rowProps={rowProps}
			//selection={selection}
			selections={activeStats}
			stats={stats}
			onItemToggle={toggleHandler}
			//showHidden={showHidden}
			//onRowClick={rowClickHandler}
		/>
		),
	];

	let label, title;
	if (selection) {
		let stat = stats[stats.findIndex(stat => { return stat.statsId == selection; })];
		if (stat.reportName) label = stat.reportName;
		else if (stat.foldChange) label = stat.foldChange;
		else label = stat.comparison;
		title = label;
		label = stat.customerName || label;
	}

	label = label ? label.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/') : "Stats";
	children.unshift(<Label title={title} key={0} color={color}>{label}</Label>);

	return (
		<DropdownContainer className={`${className} StatsDropdown`} {...props}>
			{children}
		</DropdownContainer>
	)
}

export default StatsDropdown;

StatsDropdown.propTypes = {

	/** Whether a caret is appended to the dropdown label */
	caret: PropTypes.bool,

	/** The color of the dropdown button */
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The size of the dropdown button */
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

	/** Whether the dropdown button is disabled */
	disabled: PropTypes.bool,

	/** Whether the dropdown button is in alt-color mode */
	alt: PropTypes.bool,

	/** Whether the dropdown button is in alt2-color mode */
	alt2: PropTypes.bool,

	/** type attribute applied to the dropdown button */
	type: PropTypes.string,

	/** Array of the active stats ids */
	activeStats: PropTypes.array,
	/** the parent sample set */
	sampleSet: PropTypes.object,
	/** the current stats selection*/
	selection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** the stats lookup table */
	stats: PropTypes.array,
	/** function to invoke when stats a row is checked on/off */
	onItemToggle: PropTypes.func,
	showHidden: PropTypes.bool,
};

StatsDropdown.defaultProps = {
	caret: DropdownContainer.defaultProps.caret,
	size: DropdownContainer.defaultProps.size,
	color: 'secondary',
	disabled: DropdownContainer.defaultProps.disabled,
	alt: DropdownContainer.defaultProps.alt,
	alt2: DropdownContainer.defaultProps.alt2,
	type: DropdownContainer.defaultProps.type,

	activeStats: [],
	sampleSet: {},
	selection: null,
	stats: [],
	onItemToggle: null,
	showHidden: true,
};