import { createSelector } from "@reduxjs/toolkit";

import { discoveryPanelsSelector } from "./discoveryPanels.selector";

export const dataSelector = createSelector(
  discoveryPanelsSelector,
  (state) => ({
    associations: state.associations.list,
    associationsByMetabolite: state.associationsByMetabolite,
    metabolites: state.metabolites.list,
    pathways: state.pathways.list,
    results: state.results.list,
    sampleSets: state.sampleSets.raw,
    sample: state.sample.raw,
  }),
);

export const rawSelector = createSelector(
  discoveryPanelsSelector,
  (state) => ({
    associations: [...state.associations.raw],
    associationsByMetabolite: {...state.associationsByMetabolite},
    metabolites: [...state.metabolites.raw],
    pathways: [...state.pathways.raw],
    results: [...state.results.raw],
  }),
);

export const staticSelector = createSelector(
  discoveryPanelsSelector,
  (state) => ({
    associations: [...state.associations.static],
    associationsByMetabolite: {...state.associationsByMetabolite},
    metabolites: [...state.metabolites.list],
    pathways: [...state.pathways.list],
    results: [...state.results.static],
  }),
);

export const statusSelector = createSelector(
  discoveryPanelsSelector,
  (state) => {
    const isLoaded =
      state.associations?.isLoaded &&
      state.metabolites?.isLoaded &&
      state.pathways?.isLoaded &&
      state.results?.isLoaded;
    
    const hasError =
      state.associations?.error ||
      state.metabolites?.error ||
      state.pathways?.error ||
      state.results?.error;

    return { isLoaded, hasError };
  }
);