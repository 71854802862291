import { createSlice, createAction } from "@reduxjs/toolkit";
import * as actionTypes from '../actions/actionTypes';


export const getInitialState = () => ({
    filesIsLoading: false,
    filesHasErrored: false,
    authenticated: false,
    selectedFilesIsLoading: false,
    data: []
});


export const filesSlice = createSlice({
    name: 'files',
    initialState: getInitialState(),
    extraReducers: {
        [actionTypes.FILES_FETCH_DATA_SUCCESS]: (state, action) => {
            return {
                ...state,
                filesIsLoading: false,
                selectedFilesIsLoading: false,
                data: Object.values(action.response),
                authenticated: action.authenticated || false
            };
        },
        [actionTypes.FILES_IS_LOADING]: (state, action) => {
            return { ...state, filesIsLoading: action.isLoading };
        },
        [actionTypes.SELECTED_FILES_IS_LOADING]: (state, action) => {
            return { ...state, selectedFilesIsLoading: action.selectedFilesIsLoading };
        },
        [actionTypes.FILES_HAS_ERRORED]: (state, action) => {
            return { ...state, filesHasErrored: action.hasErrored, filesIsLoading: false };
        }
    }
});


export const filesReducer = filesSlice.reducer;