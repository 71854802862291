'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkR } from 'react-router-dom';
import Button from './Button.jsx';
import './Button.scss';

/** A Button component for navigation */
export class Link extends Button {
	constructor(props) {
		super(props);
	}

	onClick() {
		super.onClick();
	}

	render() {
		const {children, target, type, to} = this.props;
		if(!to) return super.render();

		let className = this.generateClassNames(this.state.baseClass).join(' ');
		return (
			<LinkR to={to} target={target} className={className} type={type} onClick={this.onClick}>
				{children}
			</LinkR>
		);
	}
}

Link.propTypes = Object.assign({}, Button.propTypes, {
	history: PropTypes.object,
});
Link.defaultProps = Object.assign({}, Button.defaultProps, {
	 
	onClick: /* istanbul ignore next */e => {console.log('Link custom onClick callback not defined!'); },
	 
});

export default Link;