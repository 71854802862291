import React, { memo, useCallback } from "react";
import TableRow from "./TableRow";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as styles from "./table.module.scss";
import { interactionsSelector } from "../../redux/selectors/interactions.selector";
import { useSelector } from "react-redux";

const TableBody = ({
  columns,
  tableData,
  color,
  tableEvents,
  updateOnHover,
  enableFontWeight,
  tableSwitchTabTo,
}) => {
  const selectedState = useSelector(interactionsSelector);

  const onEvent = useCallback(
    (d, eventType) => () => {
      tableEvents(d, eventType);
    },
    [tableEvents]
  );
  const goTo = useCallback((event) => {
    if (!tableSwitchTabTo) event.preventDefault();
  }, []);

  const groupExist =
    selectedState.sourceSpecific && selectedState.sourceSpecific.group
      ? true
      : false;

	const RowWrapper = tableSwitchTabTo ? Link : 'div';
  return (
    <RowWrapper
      className={styles.body}
      to={tableSwitchTabTo}
      onClick={goTo}
    >
      {tableData.map((d, index) => {
        return (
          <div
            className={styles.row}
            key={index}
            onClick={onEvent(d, "click")}
            onMouseEnter={updateOnHover ? onEvent(d, "hover") : null}
            onMouseLeave={updateOnHover ? onEvent() : null}
          >
            {columns.map((c, i) => {
              const display = c.display || c.key;
              const displayContent = d[display] ? d[display].toString() : "";
              const active =
                selectedState.method === "click" &&
                selectedState.id === d.chemical_id &&
                groupExist;
              return (
                <TableRow
                  key={i}
                  c={c}
                  displayContent={displayContent}
                  active={active}
                  color={color}
                  enableFontWeight={enableFontWeight}
                />
              );
            })}
          </div>
        );
      })}
    </RowWrapper>
  );
};

TableBody.propTypes = {
  columns: PropTypes.array,
  tableData: PropTypes.array,
  color: PropTypes.string,
  tableSwitchTabTo: PropTypes.string,
  capitalize: PropTypes.bool,
  tableEvents: PropTypes.func,
  updateOnHover: PropTypes.bool,
  enableFontWeight: PropTypes.bool,
};

TableBody.defaultProps = {
  columns: [],
  tableData: [],
  color: "inherit",
  tableSwitchTabTo: undefined,
  capitalize: false,
  tableEvents: undefined,
  updateOnHover: false,
  enableFontWeight: false,
};

export default memo(TableBody);
