'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Hollaback extends Component {

	constructor(props) {
		super(props);
	}

	onClick = (data, callback=null) => {
		if(this.props.onClick) {
			this.props.onClick(data);
		}
		if(callback) callback();	
	};

	render() {
		let {children, data} = this.props;
		return React.cloneElement(children, {onClick:this.onClick.bind(this, data, children.props.onClick)});
	}
}

Hollaback.propTypes = {
	children: PropTypes.element.isRequired,

	/** Invoked when the control is clicked */
	onClick: PropTypes.func,

	/** params passed to the onClick callback */
	//data: PropTypes.any,
};

export default Hollaback;