export default function getDocStyle(val) {
  if (val.includes("--")) {
    const value = "--" + val.split("--")[1].split(")")[0].trim();

    return getComputedStyle(document.documentElement)
      .getPropertyValue(value)
      .trim();
  } else
    return getComputedStyle(document.documentElement)
      .getPropertyValue("--" + val)
      .trim();
}
