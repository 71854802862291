import { createSelector } from '@reduxjs/toolkit';


export const projectsSelector = createSelector(
    (state) => state,
    (state) => state.projects
);


export const projectsIsLoadingSelector = createSelector(
    projectsSelector,
    (projects) => projects.projectsIsLoading
);


export const projectsHasErroredSelector = createSelector(
    projectsSelector,
    (projects) => projects.projectsHasErrored
);


export const projectsDataSelector = createSelector(
    projectsSelector,
    (projects) => Object.values(projects?.data||{})
);


export const projectsDiscoveryPanelsSelector = createSelector(
    projectsSelector,
    (projects) => projects.discoveryPanels
);


export const selectProjectIdByProjectCode = createSelector(
    projectsDataSelector,
    (_, code) => code,
    (projects, projectCode) => {
        const project = projects.find((project) => project?.projectCode === projectCode);
        return !project ? null : project.projectId;
    }
);