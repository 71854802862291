export const Blue = {
	100: '#e7f2fe',
	200: '#d7e8fe',
	300: '#a5cbfd',
	400: '#82b7fc',
	500: '#3987ef',
	600: '#1768d3',
	700: '#0c51ac',
	800: '#003c8a',
	900: '#002657',
}

export const Red = {
	100: '#fff0f1',
	200: '#fed7d8',
	300: '#fda5a8',
	400: '#fd8c90',
	500: '#f94c52',
	600: '#390c14',
	700: '#AC0c11',
	800: '#8f0a0f',
	900: '#6e080b',
}

export const Green = {
	100: '#ecfdf3',
	200: '#d5f6e3',
	300: '#b7f0cf',
	400: '#9aeabb',
	500: '#57db8e',
	600: '#28bd73',
	700: '#12824a',
	800: '#0e673b',
	900: '#04522b',
}

export const Yellow = {
	100: '#fef9f1',
	200: '#fae9c6',
	300: '#fbecd0',
	400: '#fbd284',
	500: '#ffc554',
	600: '#d88e18',
	700: '#936710',
	800: '#754e10',
	900: '#ffc554',
}

export const Purple = {
	100: '#f9f0ff',
	200: '#f0dcfe',
	300: '#e3bffd',
	400: '#daabfc',
	500: '#cc85ff',
	600: '#9121ed',
	700: '#4a0da5',
	800: '#350085',
	900: '#230057',
}

export const Gray = {
	100: '#f5f7fa',
	200: '#e1e6ef',
	300: '#ccd3e0',
	400: '#9facc6',
	500: '#60759f',
	600: '#45587d',
	700: '#304369',
	800: '#172f5e',
	900: '#0a1b3e',
}

export const Text = {
	100: Gray[100],
	200: Gray[200],
	300: Gray[400],
	400: Gray[600],
	500: Gray[800],
	600: Gray[800],
	700: Gray[800],
	800: Gray[800],
	900: Gray[900],
}