'use strict';
import React, { useMemo } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { KPI } from '@/lenses/uilenses/components/KPI';
import { Gray } from '@/lenses/uilenses/theme/colors';
import { AutoGraph, ContentCopy, ScatterPlot } from '@mui/icons-material';
import { ExploreMetric } from '@/lenses/uilenses/components/Explore/ExploreMetric';
import urljoin from 'url-join';
import { useSelector } from 'react-redux';
import { sampleSetDataCurrentSelector, sampleSetDataDataSelector } from '@/redux/selectors/sampleSetData.selector.js';
import { useBiovizStatus } from '../../../lenses/uilenses/hooks/biovizStatus/biovizStatus.hook';
import { useFetchSampleSetsInfoQuery } from "@/redux/services/samplesetsinfo.api";
import { LoadingSpinner } from '@/lenses/uilenses/components/LoadingSpinner/LoadingSpinner';

export const ProjectHome = ({ sampleSets, match }) => {
	const theme = useTheme();
	const sampleSetData = useSelector(sampleSetDataDataSelector);
	const current = useSelector(sampleSetDataCurrentSelector);
	const projectId = sampleSetData[current]?.projectId;
	const {status: biovizStatus, href} = useBiovizStatus(projectId);
	
	const { data: sampleSetsInfo, isLoading: sampleIsLoading } = useFetchSampleSetsInfoQuery(projectId);
	const metrics = useMemo(() => {

		let metabolites = [], stats = 0;
		let sampleSetsCount=0;
		let metabolitesCount=0;
		let groupsCount=0;
		sampleSetsInfo?.forEach((sampleSet) => {
			sampleSetsCount+=sampleSet.numSamples;
			metabolitesCount+=sampleSet.numMetabolites;
			groupsCount+=sampleSet.numComparison;
		});

		sampleSets.forEach((sampleSet) => {
			metabolites.push(...sampleSet.metabolites);
			stats += sampleSet.stats.length;
			[...new Set(metabolites)];
		});

		return ({
			studySummary: [{
				label: "Samples",
				value: sampleIsLoading?<LoadingSpinner size={25} />:sampleSetsCount,
				infoText: "Number of samples in the sample set.",
			},
			{
				label: "Metabolites",
				value: sampleIsLoading?<LoadingSpinner size={25} />:metabolitesCount,
				infoText: "Number of detected metabolites.",
			},
			{
				label: "Groups",
				value: sampleIsLoading?<LoadingSpinner size={25} />:groupsCount,
				infoText: "Number of statistical contrasts.",
			}],
			explore: [{
				icon: <ContentCopy fontSize="inherit" />,
				label: "Results",
				bodyText: "Click to download data deliverables for your project.",
				href: urljoin(match.url, "results")
			},
			{
				icon: <ScatterPlot fontSize="inherit" />,
				label: "Pathway Explorer",
				bodyText: "Explore the interactive pathway map.",
				href: urljoin(match.url, "pathwayexplorer")
			},
			{
				icon: <AutoGraph fontSize="inherit" />,
				label: "Bioinformatics Tool",
				bodyText: "Visualize your data in our bioinformatics platform.",
				href,
				external: true,
				willRender: biovizStatus
			}]
		});
	}, [sampleSets, biovizStatus, sampleSetsInfo, sampleIsLoading, projectId, href]);

	return (
		<Box className="ProjectHome">
			<Stack sx={{ background: Gray[100], p: 3 }} spacing={3}>
				{/*<KPI metrics={metrics.studySummary} title='Study summary' />*/}

				<Box
					sx={{ borderRadius: 3, padding: 4, background: "white" }}
					data-testid={"explore"}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 8,
						}}
					>
						<Typography variant="h5" sx={{ fontWeight: 600, color: Gray[800] }}>
							Explore
						</Typography>
					</Box>
					<Box sx={{ display: "flex" }}>
						{metrics.explore.map(({ willRender = true, ...metric }, index) => (
							willRender &&
							<ExploreMetric
								key={`explore-metric-${index}`}
								metricsCount={metrics.length}
								{...metric}
							/>
						))}
					</Box>
				</Box>
			</Stack>
		</Box>
	);
};

export default ProjectHome;