'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../controls/Icon.jsx';
import DynamicModal from '../controls/DynamicModal.jsx';
import Button from './Button.jsx';
import classNames from 'classnames';

/** A Button component designed to create and launch Modals
	with dynamic content.content can be created with any combination
	of the children, component, or renderer props */
export const DynamicModalButton = ({ hasIcon = false, onShow, onHide, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isOpen && onShow) onShow();
		if (!isOpen && onHide) onHide();
	}, [isOpen, onShow, onHide]);

	const { id, children, className, openIcon, closeIcon, openLabel, closeLabel, modalProps, component, componentProps, renderer, modalId, ...btnProps } = props;

	let dynamicProps = { component, componentProps, renderer };

	const onClose = useCallback(() => {
		setIsOpen(false)
		if (props.onClose) props.onClose();
	}, [props.onClose]);

	const onToggle = useCallback(() => {
		setIsOpen((prev) => !prev);
		if (isOpen) onClose();
	}, [isOpen, onClose]);

	return <>
		<Button testId="modal-button" id={id}
			{...btnProps}
			onClick={onToggle}
			className={classNames(props.className)}
		>
			{hasIcon && <Icon icon={isOpen ? closeIcon : openIcon} />}
			{isOpen ? closeLabel : openLabel}
			{children}
		</Button>

		<DynamicModal onClose={onClose} open={isOpen} {...dynamicProps} id={modalId} {...modalProps} />
	</>
}

export default DynamicModalButton;

DynamicModalButton.propTypes = {
	/** id assigined to the button*/
	id: PropTypes.string,
	/** custom class assigned to the button*/
	className: PropTypes.string,

	/** Whether the button has a state-managed icon*/
	hasIcon: PropTypes.bool,
	/** icon to show when the modal is open, should be an FA icon string*/
	openIcon: PropTypes.string,
	/** icon to show when the modal is closed, should be an FA icon string*/
	closeIcon: PropTypes.string,
	/** button label to show when the modal is open*/
	openLabel: PropTypes.string,

	/** button label to show when the modal is closed*/
	closeLabel: PropTypes.string,

	/** function invoked when the modal is triggered to show*/
	onShow: PropTypes.func,
	/** function invoked after the modal is shown*/
	onShown: PropTypes.func,
	/** function invoked when the modal is triggered to hide*/
	onHide: PropTypes.func,
	/** function invoked after the modal hidden*/
	onHidden: PropTypes.func,

	/** props passed to the modal, see the Modal component for details*/
	modalProps: PropTypes.object,

	/** The component class used to render the modal content*/
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

	/** Props passed down to the modal content component*/
	componentProps: PropTypes.object,

	/** Renderer function used to render the modal content*/
	renderer: PropTypes.func,

	/** The id assigned to the modal, used to target the modal for launch*/
	modalId: PropTypes.string.isRequired,
};