import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import circlePackDataPrep from "../../../utils/circlePackDataPrep";
import Panel from "./Panel";
import Figure from "./Figure";
import Legend from "../../containers/Legend";
import DownloadSVG from "../../../components/controls/DownloadSVG";
import { filterSelector } from "../../../redux/selectors/filter.selector";
import * as styles from "./section.module.scss";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";

const Section = ({
  label,
  circlePackData,
  tableData,
  tableColumns,
  proportion,
  showLegend,
  showDownloadSvg,
  match
}) => {
  const { group } = useSelector(filterSelector);
  const { width, height, smallScreen, mobile } = useSelector(viewPortSelector);
  const packWidth = (width / (mobile ? 1 : 1.5)) * proportion;
  const mobileSizeAdj = mobile ? 1.6 : 1.13;
  const packHeight = Math.max(600, mobile ? height / 2 : height);
  const packSize = (packHeight / mobileSizeAdj) * proportion;

  const data = useMemo(() => {
    return circlePackDataPrep(
      circlePackData,
      packWidth,
      width,
      packWidth,
      group,
      proportion
    );
  }, [circlePackData, packWidth, group, proportion]);

  return (
    <div className={styles.section}>
      <Panel
        label={label}
        total={circlePackData.length}
        tableData={tableData}
        tableColumns={tableColumns}
        height={packSize}
        proportion={proportion}
      />
      <div
        className={styles.figureWrapper}
        style={{
          marginBottom:
            showLegend && mobile
              ? "8rem"
              : showLegend && smallScreen
              ? "6rem"
              : 0,
        }}
      >
        {showDownloadSvg && (
          <DownloadSVG id="detected-metabolites-svg" name="DiscoveryPanelReport" projectId={match.params.projectId} location="report" />
        )}
        {showLegend && (
          <Legend
            size={true}
            color={true}
            position="absolute"
            colorWidth="140"
          />
        )}
        <Figure
          size={packWidth}
          circlePackData={data}
          id={label}
          packSize={packSize}
          match={match}
        />
      </div>
    </div>
  );
};

Section.propTypes = {
  label: PropTypes.string,
  circlePackData: PropTypes.array,
  tableData: PropTypes.array,
  tableColumns: PropTypes.array,
  proportion: PropTypes.number,
  showLegend: PropTypes.bool,
  showDownloadSvg: PropTypes.bool,
};

Section.defaultProps = {
  label: "",
  circlePackData: [],
  tableData: [],
  tableColumns: [],
  proportion: 1,
  showLegend: false,
  showDownloadSvg: false,
};

export default Section;
