'use strict';
import React from 'react';
import '../../../../theme/theme.css';
import '../../../../theme/css/material-icons.css';
import { Button, FormInput, Icon } from '@/components/index.jsx';
import './AdminUserManagement.scss';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';
import { showToaster } from '@/utils/ToastController';
import { Auditor } from '@/components/util/Auditor.jsx';
import { Logger } from '@/utils/Logger';
const logger = new Logger();

export class AdminUserManagement extends Auditor {

	constructor(props) {
		super(props);
		this.state = {
			projectCode: '',
			projectId: '',
			currentUsers: this.props.currentUsers,
			firstName: '',
			lastName: '',
			email: '',
			isPublishLoading: false,
			isButtonDisabled: false,
			...super.state
		};

		this.onRemoveUserClick = this.onRemoveUserClick.bind(this);
		this.resetInputs = this.resetInputs.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
	}

	componentDidMount() {

	}

	componentWillReceiveProps(nextProps) {

		if (nextProps.currentUsers != this.state.currentUsers) {
			this.setState({ currentUsers: nextProps.currentUsers });
		}

		if (nextProps.isPublishLoading != this.state.isPublishLoading) {
			this.setState({ isPublishLoading: nextProps.isPublishLoading });
		}

		if (nextProps.isButtonDisabled != this.state.isButtonDisabled) {
			this.setState({ isButtonDisabled: nextProps.isButtonDisabled });
		}
	}

	onRemoveUserClick = (email) => {
		if (this.props.onRemoveUserClick) {
			this.props.onRemoveUserClick(email);
			this.state.logger.logProjectRemoveContact(this.props.projectId);
		}
	}
	/**Add User Functions */
	changeFirstNameInput = (event) => { this.setState({ firstName: event.target.value }); }
	changeLastNameInput = (event) => { this.setState({ lastName: event.target.value }); }
	changeEmailInput = (event) => { this.setState({ email: event.target.value }); }

	resetInputs() {
		document.querySelectorAll('.Input').forEach(function (input) {
			input.value = "";
		});
	}
	validateEmail(email) {
		return !!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
	}

	onSubmit() {
		if (!this.validateEmail(this.state.email)) {
			showToaster("Error", "Invalid Email");
			return;
		}

		let user = { firstName: this.state.firstName.trim(), lastName: this.state.lastName.trim(), email: this.state.email.trim().toLowerCase() };
		if (this.props.onAddUser) {
			this.props.onAddUser(user);
		}
		this.resetInputs();
		this.state.logger.logProjectAddContact(this.props.projectId);
	}


	/**Loading Indicators */
	loadingStyle = {
		container: {
			//display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto',
			right: '-29px'

		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	onCancelClick() {
		if (this.props.onCancelClick) {
			this.props.onCancelClick();
		}
	}

	getEmail(user) {
		return user.emails ? user.emails[0] : [];
	}

	render() {
		let { currentUsers } = this.state;
		return (
			<div className="AdminUserManagement">
				{super.render()}
				<div className="ActionButtons">
					<Button alt2={true} className="cancelBtn" onClick={this.onCancelClick} >
						<Icon size={'normal'} color={'primary'} icon={'times'} />&nbsp;
						<label>Cancel</label>
					</Button>
					{/* <Button alt2={true} className="saveBtn" disabled={this.state.isButtonDisabled}   onClick={this.onSaveClick} > 
						<Icon size={'normal'} color={'primary'} icon={'save'} />&nbsp;
						<label>Save Changes</label>
					</Button> */}
				</div>
				<div className="contacts">
					<div className="addContact">
						<label className="listHeader">Add Contact</label>
						<div className="addUser">
							<div className={"Name"} ><FormInput width={'normal'} placeHolder={'First Name'} onChange={this.changeFirstNameInput} /></div>
							<div className={"Name"} ><FormInput width={'normal'} placeHolder={'Last Name'} onChange={this.changeLastNameInput} /></div>
							<div className={"Email"} ><FormInput width={'normal'} placeHolder={'Email'} onChange={this.changeEmailInput} /></div>
							<div className={"UserActions"}>
								<Button className={"alt"} onClick={this.resetInputs} >Clear</Button>
								<Button disabled={this.state.isButtonDisabled} onClick={this.onSubmit}  >Add User</Button>
							</div>
						</div>
					</div>
					<div className="currentContacts">
						<label className="listHeader">Project Contacts</label>

						{this.state.isPublishLoading ? <LoadingSpinner /> :
							<div className="currentContactsList">
								{currentUsers.map((user, index) => {
									return <div key={index} >
										<label className="currentUserInfo" title={user.name} >
											{/*{user.firstName} {user.lastName}<br /> ({this.getEmail(user)}) */}
											{user.firstName} {user.lastName}<br /> ({user.email})
										</label>
										<Button alt2={true} className="cancelBtn" // eslint-disable-next-line
											onClick={() => { this.onRemoveUserClick(user) }} >
											<Icon size={'large'} color={'primary'} icon={'times'} />&nbsp;
										</Button>
									</div>;
								})
								}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default AdminUserManagement;