import { createSlice } from '@reduxjs/toolkit';


export const getInitialState = () => ({
	minCount: 5
});


export const enrichmentFilterSlice = createSlice({
	name: 'enrichmentFilter',
	initialState: getInitialState(),
	reducers: {
		setMinCount: (state, action) => {
			state.minCount = action.payload;
		},
	}
});

export const {
	setMinCount,
} = enrichmentFilterSlice.actions;

export const enrichmentFilterReducer = enrichmentFilterSlice.reducer;
