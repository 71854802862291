'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import Theme from '../util/Theme.jsx';

/**
 * A FormInput component for use in forms
 */
const FormInput = ({ color, size, width, value, name, onChange, placeHolder, styles }) => {
	let getStyles = () => {
		return {
			padding: '.5em',
			height: '31px',
			verticalAlign: 'middle',
			borderRadius: '0.6em',
			border: '2px solid #ccc',
			outline: 'none',
			fontSize: Theme.getSize(size),
			color:'grey',
			width: FormInput.widths[width]
		};
	};

	const css = getStyles();
	styles = {...css, ...styles};
	//if(width === 'large') styles.push(css.large);


	return (
		<input type="text" className="Input" style={styles} name={name} onChange={onChange} placeholder={placeHolder} value={value}/>
	);
};

FormInput.propTypes = {
	/** The color theme of the input */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** Input font size */
	size: PropTypes.oneOf(['xsmall','small', 'normal', 'large']),

	/** The width of the search input */
	width: PropTypes.oneOf(['xsmall', 'small', 'normal', 'large']),
	
	/**  Input value */
	value: PropTypes.string,

	/**  Input name */
	name: PropTypes.string,

	/**  Input placeholder text */
	placeHolder: PropTypes.string,
};

FormInput.defaultProps = {
	width: 'normal',
	disabled: false,
    required: false,
    placeholder: 'Enter Search'
};

FormInput.widths = {
	xsmall: '50px',
	small: '200px',
	normal: '250px',
	large: '400px',
};

export default FormInput;