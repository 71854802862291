'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicTable from '../components/controls/DynamicTable.jsx';
import DynamicTableHelper from '../components/controls/DynamicTableHelper.jsx';
import DynamicTableCell from '../components/controls/DynamicTableCell.jsx';
import Hollaback from '../components/Hollaback.jsx';
import StatsLegend from './StatsLegend.jsx';
import SamplesetUtils from '../utils/SamplesetUtils.js';
import Sort from '../utils/Sort.js';
import { toFixed } from '../utils/MathUtils.js';
import { Button, Icon, Label, Material } from '../components/index.jsx';
import './MetaboliteTable.scss';
import { Stack } from '@mui/material';

export default class MetaboliteTable extends Component {
	static getMetaboliteIdentifier(metabolite) {
		return metabolite.metaboliteId;
	}

	static createTableFields() {
		return [
			DynamicTableHelper.createField('pathwaySortOrder', 'Sort', { comparator: "numeric" }),
			DynamicTableHelper.createField('superPathway', 'Superpathway'),
			DynamicTableHelper.createField('subPathway', 'Subpathway'),
			DynamicTableHelper.createField('chemicalName', 'Biochemical'),
		];
	}

	static propTypes = {
		/** files*/
		metabolites: PropTypes.array.isRequired,
		pathwayFilters: PropTypes.bool,
		stats: PropTypes.object,
		/** Array of additional fields to display */
		optionalFields: PropTypes.array,
		activeStats: PropTypes.array,
		currentStatsField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		testField: PropTypes.string,
		exportName: PropTypes.string,
		showHidden: PropTypes.bool,
		webWorker: PropTypes.bool,
	};

	static defaultProps = {
		metabolites: [],
		pathwayFilters: false,
		stats: {},
		optionalFields: [],//['mass', 'compoundId'],
		activeStats: [],
		testField: "foldChange",
		exportFilename: 'Stats_Table',
		canExport: true,
		showHidden: false,
		webWorker: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			exportData: null,
			widthClass: null,
			usePathwayFilters: false,
			...this.initState()
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentStatsField !== this.props.currentStatsField ||
			prevProps.lastMapUpdate !== this.props.lastMapUpdate) {
			this.updateState(this.initState());
		}
	}

	static optionalFields = {
		compoundId: 'Compound ID',
		compoundType: 'Compound Type',
		hmdb: 'HMDB',
		inChiKey: 'InChiKey',
		kegg: 'Kegg',
		mass: 'Mass',
		metaboliteId: 'Metabolite ID',
		pathwayType: 'Pathway Type',
	};

	initState() {
		return {
			currentStatsField: this.props.currentStatsField,
			lastMapUpdate: this.props.lastMapUpdate,
		};
	}

	updateState(state, callback) {
		this.setState(state, callback);
	}

	generateClassNames() {
		const { className } = this.props;
		const { widthClass } = this.state;
		let classNames = [this.constructor.name];
		if (className) classNames.unshift(className);
		if (widthClass) classNames.push(widthClass);

		return classNames;
	}

	generateClassString() {
		return this.generateClassNames().join(' ');
	}

	setTableRef = (table) => {
		this.table = table;
	};

	export(format = null) {
		this.table.export(this.getFields().map(header => ({ name: header.name, header: header.header })), format);
	}

	onWidthSwitch = (widthIndex) => {
		this.setState({ widthClass: [null, 'w1', 'w2'][widthIndex] });
	};

	onFieldClick = (data, fieldName) => {
		const { onFieldClick } = this.props;
		if (onFieldClick) onFieldClick(data, fieldName);
	};


	createField(name, header, options) {
		return DynamicTableHelper.createField(name, header, {
			...options,
			renderer: (data, fieldName, n) => {
				let val = data[fieldName];
				const cb = data[fieldName] ? () => {
					this.onFieldClick(data, fieldName);
				} : null;
				return <DynamicTableCell onClick={cb} className={fieldName} key={n} title={val}><span>{val}</span></DynamicTableCell>;

			}
		});
	}

	createTableFields() {
		return [
			this.createField('pathwaySortOrder', 'Sort', { comparator: "pathwayOrder" }),
			this.createField('superPathway', 'Superpathway'),
			this.createField('subPathway', 'Subpathway'),
			this.createField('chemicalName', 'Biochemical'),
		];
	}

	createStatsField(statsId, header, statsField = "pValue") {
		const name = 'detail-' + statsId;
		return {
			name, header, className: "stats-cell",
			comparator: 'stats',
			comparatorOptions: { statsField },
			renderer: (data, fieldName, n) => {
				let className = statsId === this.state.currentStatsField ? ' active' : '';
				if (!(fieldName in data)) {
					return (
						<DynamicTableCell className={"stats-cell" + className} key={n}>
							{null}
						</DynamicTableCell>
					);
				}
				const val = parseFloat(data[fieldName][statsField]),
					pVal = Number(data[fieldName]['pValue']);
				let status = data[fieldName].status;
				let effectTest = data[fieldName].effectTest;
				let displayVal = (val === null || isNaN(val)) ? "" : toFixed(val);
				let classes = ["stats-cell", className];
				if (status) classes.push(status);
				if (effectTest) classes.push('effectTest');
				if (!displayVal) {
					displayVal = '-';
				}

				return (
					<DynamicTableCell className={classes.join(' ')} key={n}>
						<Label color="tertiary" size="inherit">{displayVal}</Label>
					</DynamicTableCell>
				);
			}
		};
	}

	createStatsFields(statsIds) {
		const { showHidden, stats, testField } = this.props;

		let statVals = Object.values(stats);
		statVals.sort(Sort.numeric('customerIndex', 1));
		let fields = [];

		statVals.forEach(stat => {
			let id = stat.statsId;
			if ((!showHidden && !stat.customerVisible) || statsIds.indexOf(id) < 0) return;

			let header;
			if (stat.reportName) header = stat.reportName;
			else if (stat.foldChange) header = stat.foldChange;
			else header = stat.comparison;
			let text = stat.customerName || header;

			header = header ? header.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/') : "Stats";
			header = {
				name: header,
				title: SamplesetUtils.getStatTitle(stat, header),
				text: text.replace(/[\/]+/g, '/<br/>'),
				className: 'stats-cell',

			};
			//console.log(header);

			fields.push(this.createStatsField(id, header, testField));
		});

		return fields;

		/*return statsIds.map(id => {

				let header;
				let stat = stats[id];
				if(stat.reportName) header = stat.reportName;
				else if(stat.foldChange) header = stat.foldChange;
				else header = stat.comparison;
				let text = stat.customerName || header;

				header = header ? header.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/') : "Stats";
				header = {
					name: header,
					title: header,
					text: text.replace(/[\/]+/g, '/<br/>'),
					className: 'stats-cell',

				};
				//console.log(header);

				return this.createStatsField(id, header, testField);
		});*/
	}

	getFields() {
		const { activeStats, optionalFields } = this.props;
		let fields = this.createTableFields();
		if (optionalFields.length)
			fields = fields.concat(DynamicTableHelper.createOptionalFields(optionalFields, MetaboliteTable.optionalFields));
		if (activeStats.length)
			fields = fields.concat(this.createStatsFields(activeStats));

		return fields;
	}

	togglePathwayFilters = () => {
		let usePathwayFilters = !this.state.usePathwayFilters;
		this.setState({ usePathwayFilters });
	};

	getPathwayFilters() {
		if (!window.cy) return [];
		const cy = window.cy();
		if (!cy || cy == 1) return [];
		let metaboliteIds = [];

		cy.nodes().forEach(node => {
			let id = node.data('chemicalId');
			let chemName = node.data('chemicalName');
			if (chemName && chemName.toLowerCase().includes("unknown")) {
				id = node.data('metaboliteId');
			}

			if (id && node.visible()//!node.hasClass('hidden') 
				&& id !== '00000000-0000-0000-0000-000000000000'
				&& metaboliteIds.indexOf(id) < 0) {
				metaboliteIds.push(id);
			}

		});
		//console.log(metaboliteIds);
		const { usePathwayFilters, filterIds } = this.state;
		return usePathwayFilters ? [(row => metaboliteIds.indexOf(row.chemicalId) >= 0)] : [];
	}

	render() {
		const { activeStats, canExport, exportFilename, onRowClick, metabolites, studyType, webWorker, pathwayFilters } = this.props;
		const { exportData, widthClass, currentStatsField, usePathwayFilters } = this.state;
		let rowProps = (metabolite) => {
			return { id: metabolite.metaboliteId };
		};
		let fields = this.getFields();
		let activeColumn = activeStats.indexOf(currentStatsField) + 4;

		return (
			<div className="TableContainer">
				<Stack direction="row" alignItems="center" spacing={2}>
					<strong className="secondary">Stats Table</strong>
					<StatsLegend studyType={studyType} />
					<div className="width-switcher">
						<Hollaback data={0} onClick={this.onWidthSwitch}><Button title="narrow stats columns" className="w0 alt2" disabled={widthClass == null}><Material className="w0" icon="view_column" /></Button></Hollaback>
						<Hollaback data={1} onClick={this.onWidthSwitch}><Button title="balanced stats columns" className="w1 alt2" disabled={widthClass == "w1"}><Material className="w1" icon="view_column" /></Button></Hollaback>
						<Hollaback data={2} onClick={this.onWidthSwitch}><Button title="wide stats columns" className="w2 alt2" disabled={widthClass == "w2"}><Material className="w2" icon="view_column" /></Button></Hollaback>
					</div>
				</Stack>

				{pathwayFilters &&
					<Button onClick={this.togglePathwayFilters} title={(usePathwayFilters ? "Dis" : "En") + "able Pathway filters"} alt2 color={usePathwayFilters ? "primary" : "off"} ><Icon icon="filter" /></Button>}

				<DynamicTable
					activeColumn={activeColumn}
					//ref={this.setTableRef}
					canExport={canExport}
					customScroll={true}
					exportFilename={exportFilename}
					testId="metabolite-table"
					primaryKey="metaboliteId"
					sorter="pathwaySortOrder"
					sortDir={1}
					index={0}
					filters={this.getPathwayFilters()}
					className={this.generateClassString()}
					dataset={metabolites}
					fields={fields}
					rowProps={rowProps}
					onRowClick={onRowClick}
					wrapRows={true}
					resetScrollOnUpdate={true}
					webWorker={webWorker}
					listHeight={350}
					viewName="PageEvent_StatsTable" />
			</div>
		);
	}
}