import React from 'react';
import './Error.scss';
import * as config from '../config/env.config';
import { useHistory } from 'react-router-dom';

const Error = (props) => {
	const history = useHistory();

	const backToHome = () => {
		history.push('/home');
		history.go();
	};

	const signOutAction = () => {
		clearStorage();
		history.push('/');
		history.go();
	};

	const clearStorage = () => {
		//We need to leave the key 'activeWindows' in storage or else we get an exception
		let activeWindow = localStorage.getItem("activeWindows");
		localStorage.clear();
		localStorage.setItem("activeWindows", activeWindow);
	};

	const contactSupport = () => {
		let subject = "Client Portal Error Page";
		let userError = localStorage.getItem('error');
		let now = new Date();
		let utc_timestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
		let readable = new Date(utc_timestamp);
		let body_message = "Error Timestamp: " + readable + "%0D%0A" + userError + "%0D%0A%0D%0APlease add any comments below.%0D%0A==========================================================%0D%0A";
		let mailto_link = 'mailto:' + config.supportEmailAddress + '?subject=' + subject + '&body=' + body_message;
		window.location.href = mailto_link;
	};

	return (
		<div className="ErrorContainer">
			<div>
				<div className="info">Sorry, something went wrong while loading the portal. <br /> Please click on one of the options below.</div>
				<div className="options"><a data-testid="error-home" onClick={backToHome}>Try Again</a></div>
				<div className="options"><a data-testid="error-signout" onClick={signOutAction}>Sign Out</a></div>
				<div className="options"><a data-testid="error-support" onClick={contactSupport}>Contact Support</a></div>
			</div>
		</div>
	);
};

export default Error;
