'use strict';
import React, { Component } from 'react';
import LandingHeader from './LandingHeader.jsx';
import LandingFooter from './LandingFooter.jsx';
import './LandingContainer.scss';
import { Link, NavBar, NavButton } from '../../components/index.jsx';
import imgResults from '../../theme/results-2020.png';
import imgSurveyor from '../../theme/surveyor-2020.png';
import imgVisualize from '../../theme/visualize-2020.png';
import clipboard from '../../theme/svg/clipboard.svg';
import box from '../../theme/svg/box.svg';
import molecule from '../../theme/svg/molecule.svg';
import '../../theme/theme.css';
import * as config from '../../config/env.config';
import { Logger, Log } from '../../utils/Logger';
import * as enums from '../../enums/Enums';
const logger = new Logger();

export class LandingContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			featureIndex: 0
		};
	}
	onFeatureSelect = n => {
		this.setState({ featureIndex: n });
	};

	logView() {
		//Log View
		let logFilterChange = new Log();
		logFilterChange.SetLevel(enums.AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageView_Landing');
		logFilterChange.AddProperty('view', "LANDING");
		logger.doLog(logFilterChange);
	}

	dispatchToaster() {
		const { addToast } = this.props.actions;
		addToast({ title: "Project Created", body: "Your study has been created and will be displayed in you Recent Queue. The Study Director assigned has been notified." });
	}

	IdleMessage = "";

	handleDemoClick = (event) => {
		//window.location = `mailto:${config.MAILTO_DEMO_REQUEST}?subject=Request Metabolon Portal Demo&body=Demo Requested From: ${event.email}`;
		this.props.history.push(`/login?email=${event.email}`);
	}

	launchDemo = () => {
		let logDemo = new Log();
		logDemo.SetLevel(enums.AppInsightLogLevel.EVENT);
		logDemo.SetName('PageView_GetADemo');
		logDemo.AddProperty('view', "GetADemo");
		logger.doLog(logDemo);
		window.open(config.getADemoLink, '_blank');
	}

	render() {
		let tabs = [
			(<div className="tab-content row" key={0} data-testid="lc-tab-results">
				<div className="center">
					<div>
						<h4>Results</h4>
						Your projects and their data are available where and when you need them.
					</div>
					<img src={imgResults} />
				</div>
			</div>),
			(<div className="tab-content row" key={1} data-testid="lc-tab-pathway">
				<div className="center">
					<div>
						<h4>Pathway Visualizations</h4>
						Gain insight and understanding of your project data through visualization, statistics integration, and metabolite reaction connectivity.
					</div>
					<img src={imgVisualize} />
				</div>
			</div>),
			(<div className="tab-content row" key={2} data-testid="lc-tab-surveyor">
				<div className="center">
					<div>
						<h4>Surveyor&#8482;</h4>
						Advanced lipidomics visualization and analysis allows you to explore your data, build heatmaps and box-plots, and generate reports.
					</div>
					<img src={imgSurveyor} />
				</div>
			</div>),
		];

		return (
			<div className="LandingContainer app-content">
				<div className="header-wrapper">
					<LandingHeader auth={this.props.auth} match={this.props.match} />
				</div>
				<div className="content-wrapper">
					<div className="content-0">
						<div className="row">
							<div className="col-md-7">
								<h1 className="bold tertiary">Take your research to the next level.</h1>
								<p className="tertiary">Your gateway to expert analysis of thousands of metabolites and biochemical pathways. Use your own data or collaborate with other investigators - you have instant access to relevant information, articles, and interpretation that will move your research forward.</p>


								<Link history={this.props.history} size="normal" className="getDemo" alt={false} color="primary" onClick={this.launchDemo} >
									Get a Demo
								</Link>


								{/* //Removing validated get demo form until we have actual email send capability
								<Translate>
									{translate =>  
										
										<ValidatedForm id="startForm" onChange={this.handleChange}   submitLabel={translate("cta") || "Get a Demo"}  onSubmit={this.handleDemoClick} className="Demoinputs">
											<ValidatedInput name="email" type="email" placeholder="Email Address" />
										</ValidatedForm>
									}
								</Translate> */}
							</div>

							<div className="col-md-5 images">
								<img src={molecule} />
								<img src={clipboard} />
								<img src={box} />
							</div>
						</div>
					</div>
					{/* <Label size="small" className="content-1"><Translate id="block2">THE GOLD STANDARD IN BIOINFORMATICS</Translate></Label>
					 <div className="content-2">
						<div>
							<Label>Social Proof/Logo</Label>
							<Label>Social Proof/Logo</Label>
							<Label>Social Proof/Logo</Label>
							<Label>Social Proof/Logo</Label>
						</div>
					</div> */}
					<div className="content-2">
						<NavBar className="features" activeIndex={0} size="normal" onClick={this.onFeatureSelect}>
							<NavButton testId="lc-results">Results</NavButton>
							<NavButton testId="lc-pathway">Pathway Visualizations</NavButton>
							<NavButton testId="lc-surveyor">Surveyor</NavButton>
						</NavBar>
						<div className="selected-feature">
							{tabs[this.state.featureIndex]}
						</div>
					</div>
				</div>
				<LandingFooter match={this.props.match} />
				<div id="app-version" style={{ display: 'none' }}>{process.env.VERSION}</div>
			</div>
		);
	}
}
export default LandingContainer;
