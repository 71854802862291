import { BIOSTRESS, MICROBIOME } from '@/enums/Enums';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchSampleSets } from '../../api';
import {
	getSampleSets,
	setSampleSets
} from '../reducers/discoveryPanels.reducer';

const createSampleSetSaga = (productType = MICROBIOME) => {
	return function* (action) {
		const projectId = action.payload;
		const payload = {
			data: [],
			isLoaded: false,
			error: null
		};

		try {
			const data = yield call(fetchSampleSets, projectId, productType);

			payload.raw = data;
		} catch (e) {
			payload.error = e.message;
		}

		yield put(setSampleSets(payload));
	};
};

export const sampleSetsSaga = createSampleSetSaga();
export const biostressSetsSaga = createSampleSetSaga(BIOSTRESS);

export function* sampleSetsSagas() {
	yield takeLatest(getSampleSets, sampleSetsSaga);
	yield takeLatest(getSampleSets, biostressSetsSaga);
}