import { useCallback, useEffect, useState } from "react";

export const useDelayedState = (initialVal, delay = 1000) => {
  const [value, setValue] = useState(initialVal);
  const [timeoutId, setTimeoutId] = useState(-1);

	useEffect(() => {
		return () => window.clearTimeout(timeoutId);
	}, [timeoutId]);

  const setter = useCallback((val) => {
		window.clearTimeout(timeoutId);
    const id = setTimeout(() => {
      setValue(val);
    }, delay);
		setTimeoutId(id);

  }, [timeoutId, delay]);

  return [value, setter, setValue];
};