'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UserSettings.scss';
import * as config from '../../config/env.config';


export class UserSettings extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    return (
        <div >
            User Settings Coming Soon! - ish
        </div>
    );
  }
}

UserSettings.propTypes = {
   
};

export default UserSettings;
