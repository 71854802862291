import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import PT from 'prop-types';
import { Input } from "metabux/components/Input/Input";
import { showManifestUpload } from '@/config/env.config';
import { OneSchemaButton, OneSchemaButtonTypes } from '@/components/OneSchemaButton.jsx';
import { WelcomeMessage } from './WelcomeMessage.jsx';

export const UPLOAD_STATUS = {
	NotStarted: 'not_started',
	Uploading: 'uploading',
	Success: 'success',
	Error: 'error'
};

export const ManifestUpload = ({ projectCode, onUpload, projectId, setStatus, status, userEmail }) => {
	const [isOpen, setIsOpen] = useState(false);
	const refreshTimeoutRef = useRef(null);
	const [manifestName, setManifestName] = useState("");

	useEffect(() => () => {
		if (refreshTimeoutRef) {
			clearTimeout(refreshTimeoutRef);
		}
	}, []);

	const onSuccess = useCallback((data) => {
		setManifestName("");
		if (onUpload) onUpload();
		setStatus(UPLOAD_STATUS.Success);
	}, [onUpload]);

	const onError = useCallback(() => {
		setManifestName("");
		setStatus(UPLOAD_STATUS.Error);
	}, []);

	const onChange = useCallback((value) => {
		setManifestName(value);
	}, []);

	if (!showManifestUpload) return <WelcomeMessage />;

	return (
		<Box>
			<Typography variant="h4" textAlign="center" mb={4}>Upload your sample manifest document.</Typography>

			<Input sx={{ display: 'block', maxWidth: "300px", textAlign: 'center', mx: "auto", mb: 4 }} inputProps={{ maxLength: 64 }} size="large" placeholder={"Enter manifest name"} onChange={onChange} value={manifestName} />

			{status !== UPLOAD_STATUS.Error && (
				<Box id="upload-container" className="upload step-content">
					<OneSchemaButton
						mx="auto"
						manifestName={manifestName}
						disabled={!manifestName}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						text="choose file"
						className="link"
						type={OneSchemaButtonTypes.Cloud}
						userId={userEmail}
						projectId={projectId}
						parentId={"upload-container"}
						projectCode={projectCode}
						onSuccess={onSuccess}
						onError={onError}
					/>
				</Box>
			)}

			{status === UPLOAD_STATUS.Error && (
				<Box className="error step-content">
					<Box>
						<i className="fa fa-times fa-5x icon text-white bg-error" style={{ borderRadius: "50%" }} />
					</Box>
				</Box>
			)}
		</Box>
	);
};
