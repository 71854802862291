import React, { memo } from "react";
import Anchor from "./Anchor";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";
import { interactionsSelector } from "../../redux/selectors/interactions.selector";

const Anchors = ({ opacityList }) => {
  const selectedState = useSelector(interactionsSelector);

  const { metaboliteCoords, associationCoords, combinedCoords } =
  useSelector(explorerSelector);
  const viewPort = useSelector(viewPortSelector);

  const def = opacityList.includes("default");
  return (
    <g>
      {metaboliteCoords.map((d, i) => {
        const active = opacityList.includes(d.chemical_id);
        return (
          <Anchor
            x={d.x}
            y={d.y}
            i={i}
            key={d.chemical_id}
            active={active}
            clicked={
              selectedState.id === d.chemical_id &&
              selectedState.method === "click"
            }
            hovered={selectedState.value === d.chemical_id}
            opacity={
              def
                ? combinedCoords.find((e) => e.chemical_id === d.chemical_id)
                  ? 1
                  : viewPort.notSelectedOpacity
                : opacityList.includes(d.chemical_id)
                ? 1
                : viewPort.notSelectedOpacity
            }
            type={"metabolite"}
          />
        );
      })}

      {associationCoords.map((d, i) => {
        const active = opacityList.includes(d.association);
				
        return (
          <Anchor
            x={d.x}
            y={d.y}
            i={i}
            key={d.association}
            active={active}
            clicked={
              selectedState.id === d.association &&
              selectedState.method === "click"
            }
            hovered={selectedState.value === d.association}
            opacity={
              opacityList.includes(d.association) ||
              opacityList.includes("default")
                ? 1
                : viewPort.notSelectedOpacity
            }
            type={"association"}
          />
        );
      })}
    </g>
  );
};

Anchors.propTypes = {
  opacityList: PropTypes.array,
};

Anchors.defaultProps = {
  opacityList: ["default"],
};

export default memo(Anchors);
