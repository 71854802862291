import React, { memo } from "react";
import PropTypes from "prop-types";
import getDocStyle from "../../utils/getDocStyle";

const Circle = ({
  radius,
  cx,
  cy,
  fill,
  strokeWidth,
  pointerEvents,
  handleEvents,
  opacity,
  name,
  value,
  depth,
  comparison,
  detected,
  pathway_name,
  section,
  group,
}) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={radius}
      stroke={getDocStyle("metabolon-base")}
      strokeWidth={strokeWidth}
      fill={fill}
      opacity={opacity}
      onMouseEnter={handleEvents}
      onMouseLeave={handleEvents}
      onClick={handleEvents}
      pointerEvents={pointerEvents}
      data-value={value}
      data-type={"metabolite"}
      data-source={"circlepack"}
      data-name={name}
      data-depth={depth}
      data-comparison={comparison}
      data-detected={detected}
      data-pathway_name={pathway_name}
      data-section={section}
      data-group={group}
    />
  );
};

Circle.propTypes = {
  radius: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number,
  pointerEvents: PropTypes.string,
  handleEvents: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  depth: PropTypes.number,
  comparison: PropTypes.string,
  detected: PropTypes.string,
  pathway_name: PropTypes.string,
  section: PropTypes.string,
  group: PropTypes.string,
};

Circle.defaultProps = {
  radius: 1,
  cx: 0,
  cy: 0,
  fill: getDocStyle("metabolon-base"),
  pointerEvents: "auto",
  strokeWidth: 0,
  handleEvents: undefined,
  name: null,
  value: null,
  depth: 1,
  comparison: null,
  detected: null,
  pathway_name: null,
  section: null,
  group: null,
};

export default memo(Circle);
