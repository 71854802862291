import React, { useCallback, useEffect, useState } from 'react';

export const Range = ({
	className,
	defaultValue,
	keys,
	label,
	labelRenderer,
	onChange,
	onChangeComplete,
	...props
}) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleChange = useCallback(e => {
		const val = e.target.value;
		setValue(val);
		if(onChange) onChange(val, keys);
	}, [onChange, keys]);

	const handleChangeComplete = useCallback(() => {
		if(onChangeComplete) onChangeComplete(value, keys);
	}, [onChangeComplete, keys, value]);

	const renderLabel = useCallback(() => {
		return labelRenderer ? 
			labelRenderer(value) :
			(label && <span className="label">{label}:&nbsp;&nbsp;{value}</span>);
	}, [label, labelRenderer, value]);

	return (
	<>
		{renderLabel()}
		<input
			data-testid="range-input"
			className={className}
			type="range"
			value={value}
			onChange={handleChange}
			onTouchEnd={handleChangeComplete}
			onMouseUp={handleChangeComplete}
			{...props} />
		</>
	);
};

Range.defaultProps = {
	defaultValue: 0,
	labelRenderer: null,
	min: 1,
	max: 75,
	step: 1,
};

export default Range;