'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Checkbox, Select, Button, NavButton } from '../../../../components/index.jsx';
import ColorPicker from '../../../../components/controls/ColorPicker.jsx';
import Range from '../../../../components/controls/Range.jsx';
import Wrapper from '../../../../components/controls/Wrapper.jsx';
import ContentSwitch from '../../../../containers/ContentSwitch.jsx';
import SettingsMap from '../../../../utils/SettingsMap.js';
import "./Settings.scss";
import { Auditor } from '@/components/util/Auditor.jsx';

export class Settings extends Auditor { 
	
	static propTypes = {
		nodeHover: PropTypes.bool,
		edgeHover: PropTypes.bool,
		label: PropTypes.string,
		fontSize: PropTypes.number,
		edgeWidth: PropTypes.number,
	};

	static defaultProps = {
		nodeHover: false,
		edgeHover: false,
		label: 'EC Number',
		fontSize: 12,
		edgeWidth: 2,
		settingsKey: null,
		labelOptions:[
			{name:'EC Number'},
			{name:'Metabolite ID'},
			{name:'Compound ID'},
			{name:'kegg'},
			{name:'hmdb'},
		],
		fontOptions: [
			{name:'Arial'}, //common sans
			{name:'EB Garamond'}, //common serif
			{name:'Helvetica'}, //common sans
			{name:'Inconsolata'}, //monospace
			{name:'Lato'}, //sans (site font face)
			{name:'Source Sans Pro'}, //sans
			{name:'Source Serif Pro'}, //serif
			{name:'Space Mono'}, //monospace
			{name:'Times New Roman'}, //default serif
		],
		edgeOptions: [
			{name:1},
			{name:2},
			{name:3},
			{name:4},
			{name:5},
		],
	};


	constructor(props) {
		super(props);
		this.state = {
			...this.initState(),
			...super.state // pull in state from auditor to get the logger
		};
	}

	componentDidUpdate(prevProps, prevState) {
		SettingsMap.initMap();
		if(this.props.settingsKey !== prevProps.settingsKey) {
			this.updateData(this.initState(), () => {
				this.updateData(this.initState());
			});
		}
	}

	updateData(data, callback) {
		this.setState({...data}, callback);
	}

	initState() {
		let map = SettingsMap.map;
		let colorMap = map.colors;
		let styles = map.styles;
		const {nodeHover, edgeHover, label, fontSize, edgeWidth} = this.props;
		return {nodeHover, edgeHover, label, fontSize, edgeWidth, colorMap, styles};
	}

	generateClassNames() {
		const {className}  = this.props;
		let classNames = [this.constructor.name];
		if(className) classNames.push(className);
		return classNames;
	}

	generateClassString() {
		return this.generateClassNames().join(' ');
	}

	onChange = (color, keys) => {
		let [key0, key1] = keys;

		let colors = {};
		colors[key0] = {};
		colors[key0][key1] = color;

		if(key0 === 'effect') {
			if(key1 === 'trendUp') colors[key0]['trendDown'] = color;
			else colors[key0]['sigDown'] = color;
		}
		SettingsMap.updateColors(colors);
		const colorMap = SettingsMap.map.colors;

		this.setState({colorMap}, () => {
			const {onChange} = this.props;
			if(onChange) onChange({colorKey:dayjs().valueOf()});
		});

		this.state.logger.logUserAction(`change color ${keys}`);
	};

	onStyleChange = (value, keys) => {
		let val = typeof value === "object" ? value.name : value;

		let [key0, key1, key2] = keys;
		let style = {[key0] : {}};

		if(key0 == 'compounds' || key0 == 'fireworks') {
			style[key0][key1] = {};
			style[key0][key1][key2] = val;
		}
		else if(keys.length > 1) style[key0][key1] = val;
		else style[key0] = val;
		SettingsMap.updateStyles(style);

		this.setState({styles:SettingsMap.styles}, () => {
			const {onChange} = this.props;
			if(onChange) onChange({colorKey:dayjs().valueOf()});
		});

		this.state.logger.logUserAction(`change style ${keys}`);
	};

	resetStyles = () => {
		SettingsMap.resetStyles();
		let styles = SettingsMap.defaultStyleMap;
		this.setState({styles}, () => {
			const {onChange} = this.props;
			if(onChange) onChange({colorKey:dayjs().valueOf()});
		});

		this.reset();

		this.state.logger.logUserAction(`reset styles`);
	};

	resetColors = () => {
		SettingsMap.resetColors();
		let colors = SettingsMap.defaultColorMap;
		this.setState({colors}, () => {
			const {onChange} = this.props;
			if(onChange) onChange({colorKey:dayjs().valueOf()});
		});

		this.reset();

		this.state.logger.logUserAction(`reset colors`);
	};

	reset() {
		const {onReset} = this.props;
		if(onReset) onReset();
	}

	renderFireworksControls() {
		let {styles} = this.state,
			{fontOptions} = this.props;
			
		return (
			<div className="controls">
				<div className="Range">
					<span className="label">Font Family</span>
					<Select className="pathway-viz right p-0" keys={['font-family']} onSelect={this.onStyleChange} selection={{name:styles['font-family']}} options={fontOptions} optionClass="alt2 tertiary left"/>
				</div>

				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['node', 'font-size']} defaultValue={styles.node['font-size']} label="Default font size" />
				</Wrapper>

				<p className="bold my-1">Superpathway</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'superpathway', 'font-size']} defaultValue={styles.fireworks.superpathway['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'superpathway', 'width']} defaultValue={styles.fireworks.superpathway.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'superpathway', 'height']} defaultValue={styles.fireworks.superpathway.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Subpathway</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'subpathway', 'font-size']} defaultValue={styles.fireworks.subpathway['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'subpathway', 'width']} defaultValue={styles.fireworks.subpathway.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'subpathway', 'height']} defaultValue={styles.fireworks.subpathway.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Biochemical</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'metabolite', 'font-size']} defaultValue={styles.fireworks.metabolite['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'metabolite', 'width']} defaultValue={styles.fireworks.metabolite.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['fireworks', 'metabolite', 'height']} defaultValue={styles.fireworks.metabolite.height} label="height" />
				</Wrapper>
			</div>
		);
	}

	renderMapControls() {
		let {styles} = this.state,
			{fontOptions} = this.props;

		return (
			<div className="controls">
				<div className="Range">
					<span className="label">Font Family</span>
					<Select className="pathway-viz right p-0" keys={['font-family']} onSelect={this.onStyleChange} selection={{name:styles['font-family']}} options={fontOptions} optionClass="alt2 tertiary left"/>
				</div>

				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['node', 'font-size']} defaultValue={styles.node['font-size']} label="Default font size" />
				</Wrapper>

				<p className="bold my-1">Metabolite</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Metabolite', 'font-size']} defaultValue={styles.compounds.Metabolite['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Metabolite', 'width']} defaultValue={styles.compounds.Metabolite.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Metabolite', 'height']} defaultValue={styles.compounds.Metabolite.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Enzyme</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Enzyme', 'font-size']} defaultValue={styles.compounds.Enzyme['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Enzyme', 'width']} defaultValue={styles.compounds.Enzyme.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Enzyme', 'height']} defaultValue={styles.compounds.Enzyme.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Minor Metabolite</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Minor Metabolite', 'font-size']} defaultValue={styles.compounds['Minor Metabolite']['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Minor Metabolite', 'width']} defaultValue={styles.compounds['Minor Metabolite'].width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Minor Metabolite', 'height']} defaultValue={styles.compounds['Minor Metabolite'].height} label="height" />
				</Wrapper>

				<p className="bold my-1">Cofactor</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Cofactor', 'font-size']} defaultValue={styles.compounds.Cofactor['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Cofactor', 'width']} defaultValue={styles.compounds.Cofactor.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Cofactor', 'height']} defaultValue={styles.compounds.Cofactor.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Intermediate</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Intermediate', 'font-size']} defaultValue={styles.compounds.Intermediate['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Intermediate', 'width']} defaultValue={styles.compounds.Intermediate.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Intermediate', 'height']} defaultValue={styles.compounds.Intermediate.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Class</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Class', 'font-size']} defaultValue={styles.compounds.Class['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Class', 'width']} defaultValue={styles.compounds.Class.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'Class', 'height']} defaultValue={styles.compounds.Class.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Final Pathway</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'FinalPathway', 'font-size']} defaultValue={styles.compounds.FinalPathway['font-size']} label="font size" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'FinalPathway', 'width']} defaultValue={styles.compounds.FinalPathway.width} label="width" />
				</Wrapper>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['compounds', 'FinalPathway', 'height']} defaultValue={styles.compounds.FinalPathway.height} label="height" />
				</Wrapper>

				<p className="bold my-1">Label</p>
				<Wrapper className="Range">
					<Range debounce={1} onChangeComplete={this.onStyleChange} keys={['Label', 'font-size']} defaultValue={styles.Label['font-size']} label="font size" />
				</Wrapper>
			</div>
		);
	}

	render() {
		let {nodeHover, edgeHover, label, fontSize, edgeWidth, colorMap, styles} = this.state,
			{labelOptions, fontOptions, edgeOptions, settingsKey, explorerMode} = this.props;
		let className = this.generateClassString();
		return (
			
			<ContentSwitch className={className} parentView={"settings"} auditIds={["Colors","Compound Styles"]}
				nav={[
					<NavButton key={0}>Colors</NavButton>,
					<NavButton key={1}>Compound Styles</NavButton>
				]}
				content={[
					<div key={0}>
						{/*<Checkbox checked={nodeHover} label="Edge data hover panel" />
						<Checkbox checked={edgeHover} label="Node data hover panel"/>
						<Select className="pathway-viz" selection={{name:label}} options={labelOptions} optionClass="alt2 tertiary left"/>
						<Select className="pathway-viz" selection={{name:fontSize}} options={fontOptions} optionClass="alt2 tertiary left"/>
						<Select className="pathway-viz" selection={{name:edgeWidth}} options={edgeOptions} optionClass="alt2 tertiary left"/>*/}
						{/*<p className="bold my-1">Universal</p>*/}
						<Button className="reset alt" onClick={this.resetColors}>Reset colors</Button>
						<div className="controls">
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'background']} color={colorMap.defaults.background}  label="Pathway Background" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'font']} color={colorMap.defaults.font} label="Font:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'enzyme']} color={colorMap.defaults.enzyme}    label="Enzymes:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'undetected']} color={colorMap.defaults.undetected} label="Undetected:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'blank']} color={colorMap.defaults.blank} label="Unknown:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'nil']} color={colorMap.defaults.nil} label="Insignificant:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'selection']} color={colorMap.defaults.selection}  label="Selection:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'edge']} color={colorMap.defaults.edge} label="Edge color:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['defaults', 'sigText']} color={colorMap.defaults.sigText} label="Significant text:" />

							<p className="bold my-1">Standard Tests</p>
							<ColorPicker debounce={1} onChange={this.onChange} keys={['standard', 'trendUp']} color={colorMap.standard.trendUp} label="Positive trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['standard', 'sigUp']} color={colorMap.standard.sigUp} label="Significant positive trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['standard', 'trendDown']} color={colorMap.standard.trendDown} label="Negative trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['standard', 'sigDown']} color={colorMap.standard.sigDown}  label="Significant negative trend:" />

							<p className="bold my-1">Z-Score Tests</p>
							<ColorPicker debounce={1} onChange={this.onChange} keys={['zscore', 'trendUp']} color={colorMap.zscore.trendUp}   label="Positive trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['zscore', 'sigUp']} color={colorMap.zscore.sigUp}     label="Significant positive trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['zscore', 'trendDown']} color={colorMap.zscore.trendDown} label="Negative trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['zscore', 'sigDown']} color={colorMap.zscore.sigDown}   label="Significant negative trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['zscore', 'dead']} color={colorMap.zscore.dead}   label="Unknown:" />

							<p className="bold my-1">Main Effect(ANOVA) Tests</p>
							<ColorPicker debounce={1} onChange={this.onChange} keys={['effect', 'trendUp']} color={colorMap.effect.trendUp} label="Trend:" />
							<ColorPicker debounce={1} onChange={this.onChange} keys={['effect', 'sigUp']} color={colorMap.effect.sigUp}label="Significant trend:" />
						</div>
					</div>,
					<div key={0}>
						<Button className="reset alt" onClick={this.resetStyles}>Reset styles</Button>
						{explorerMode == "map" && this.renderMapControls()}
						{explorerMode == "fireworks" && this.renderFireworksControls()}
					</div>
				]}
			>
			{super.render()/*call super.render() to add the auditor*/}
				<h3>Settings</h3>
			</ContentSwitch>
		);
	}
}

export default Settings;