import { MICROBIOME } from '@/enums/Enums';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchResults } from '../../api';
import { 
  getResults,
  setResults
} from '../reducers/discoveryPanels.reducer';

export function* resultsSaga(action) {
  const { projectId="", samplesetId="", productType=MICROBIOME } = action?.payload || {};

  const payload = {
    list: [],
    static: [],
    raw: [],
    isLoaded: false,
    error: null
  };

  try {
    const data = yield call(fetchResults, projectId, samplesetId, productType);
    payload.raw = data;
  } catch (e) {
    payload.error = e.message;
  }
  
  yield put(setResults(payload));
}

export function* resultsSagas() {
  yield takeLatest(getResults, resultsSaga);
}