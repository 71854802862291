import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from '../actions/actionTypes';


export const getInitialState = () => ({
    sampleSetDataIsLoading: false,
    sampleSetDataHasErrored: false,
    sampleSetDataIsSaving: false,
    current: 0,
    data: [],
    authenticated: false
});


export const sampleSetDataSlice = createSlice({
    name: 'sampleSetData',
    initialState: getInitialState(),
    extraReducers: {
        [actionTypes.SAMPLESETDATA_HAS_ERRORED]: (state) => {
            return { ...state, sampleSetDataIsLoading: false, sampleSetDataHasErrored: true };
        },
        [actionTypes.SAMPLESETDATA_IS_LOADING]: (state) => {
            return { ...state, sampleSetDataIsLoading: true };
        },
        [actionTypes.SAMPLESETDATA_FETCH_DATA_SUCCESS]: (state, action) => {
            return {
                ...state,
                sampleSetDataIsLoading: false,
                data: Array.isArray(action.response) ? action.response : Object.values(action.response),
                authenticated: action.authenticated || false
            };
        },
        [actionTypes.SAMPLESETDATA_IS_SAVING]: (state, action) => {
            return { ...state, sampleSetDataIsSaving: action.isSaving };
        }
    },
    reducers: {
        sampleSetDataIsSelected: (state, action) => {
            return { ...state, current: action.payload };
        },
        reset: () => {
            return getInitialState();
        }
    }
});

export const sampleSetDataReducer = sampleSetDataSlice.reducer;
export const { sampleSetDataIsSelected, reset } = sampleSetDataSlice.actions;