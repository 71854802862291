'use strict';
import React from 'react';
import { connect } from 'react-redux';
import '../../../../theme/theme.css';
import '../../../..//theme/css/material-icons.css';
import { Button, Label, Material, Toaster } from '@/components/index.jsx';
import './AdminProjectInfo.scss';
import { scrubHTML } from '@/utils/StringUtils';
import { projectsFetchDataAdminNoRedux, projectsSaveDataAdmin } from '@/redux/actions/projects';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';

import { Auditor } from '@/components/util/Auditor.jsx';

export class AdminProjectInfo extends Auditor {

	constructor(props) {
		super(props);
		const { project } = this.props;
		this.textInput = React.createRef();
		this.state = {
			...super.state,
			editSynopsis: false,
			editConclusion: false,
			editTitle: false,
			visibleLabelSynopsis: '',
			visibleLabelConclusion: '',
			visibleLabelTitle: '',
			synopsisIsLoading: true,
			conclusionIsLoading: true,
			titleIsLoading: true,
			errormsg: ''
		};

		this.inputs = {};
		this.getSynopsis = this.getSynopsis.bind(this);
		this.onPenClickSynopsis = this.onPenClickSynopsis.bind(this);
		this.onSaveClickSynopsis = this.onSaveClickSynopsis.bind(this);
		this.onCancelSynopsisClick = this.onCancelSynopsisClick.bind(this);

		this.onPenClickConclusion = this.onPenClickConclusion.bind(this);
		this.onSaveClickConclusion = this.onSaveClickConclusion.bind(this);
		this.onCancelConclusionClick = this.onCancelConclusionClick.bind(this);

		this.onPenClickTitle = this.onPenClickTitle.bind(this);
		this.onSaveClickTitle = this.onSaveClickTitle.bind(this);
		this.onCancelTitleClick = this.onCancelTitleClick.bind(this);

		this.handleChangeSynopsis = this.handleChangeSynopsis.bind(this);
		this.handleChangeConclusion = this.handleChangeConclusion.bind(this);
		this.handleChangeTitle = this.handleChangeTitle.bind(this);
	}

	componentDidMount() {
		this.getSynopsis();
		this.getConclusion();
		this.getTitle();
	}

	componentDidUpdate(prevProps) {
		let { project } = this.props;

		if (project !== prevProps.project) {
			this.getSynopsis();
			this.getConclusion();
			this.getTitle();
		}
	}

	/** Handlers */
	onPenClickSynopsis() {
		this.setState({ errormsg: '', isButtonSynopsisDisabled: false });
		if (!this.props.project || !this.textInput.current) return;
		this.setState({ editSynopsis: true }, () => { this.textInput.current.value = this.state.visibleLabelSynopsis; });
	}

	onPenClickConclusion() {
		this.setState({ errormsg: '', isButtonConclusionDisabled: false });
		if (!this.props.project || !this.textInput.current) return;
		this.setState({ editConclusion: true }, () => { this.textInput.current.value = this.state.visibleLabelConclusion; });
	}

	onPenClickTitle() {
		this.setState({ errormsg: '', isButtonConclusionDisabled: false });
		if (!this.props.project) return;
		this.setState({ editTitle: true }, () => { this.textInput.current.value = this.state.visibleLabelTitle; });
	}

	onSaveClickSynopsis() {
		this.state.logger.logProjectSaveSynopsis(this.props.project.projectId);
		//get the admin project to update
		this.props.fetchAdminProject(this.props.project.projectId).then((response) => {
			if (!response) { this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to retrieving project', isButtonSynopsisDisabled: false }); }

			this.setState({ errormsg: '', isButtonSynopsisDisabled: true });
			this.showToaster("Saving Synopsis ...", "");
			this.setState({ editSynopsis: false });

			let project = { ...response };
			project.customerSynopsis = this.state.visibleLabelSynopsis;

			let updatedProjectData = { "instance": { ...project }, "comment": "PortalUI: Portal Save customerSynopsis" };
			//Call API to save
			this.props.saveProjectAdmin(updatedProjectData).then(result => {

				if (result && !result.ok) {
					let msg = result.headers.get('X-HTTP-Error-Description');
					this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to save project synopsis', isButtonSynopsisDisabled: false });
					//revert synopsis
					this.getSynopsis();
					return;
				}
			});
		});
	}

	onSaveClickConclusion() {
		this.showToaster("Saving Conclusion ...", "");
		this.state.logger.logProjectSaveConclusion(this.props.project.projectId);

		//get the admin project to update
		this.props.fetchAdminProject(this.props.project.projectId).then((response) => {
			if (!response) { this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to retrieving project', isButtonConclusionDisabled: false }); }

			this.setState({ errormsg: '', isButtonConclusionDisabled: true });

			this.setState({ editConclusion: false });

			let project = { ...response };
			project.customerConclusion = this.state.visibleLabelConclusion;

			let updatedProjectData = { "instance": { ...project }, "comment": "PortalUI: Portal Save customerConclusion" };
			//Call API to save
			this.props.saveProjectAdmin(updatedProjectData).then(result => {

				if (result && !result.ok) {
					let msg = result.headers.get('X-HTTP-Error-Description');
					this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to save project conclusion', isButtonConclusionDisabled: false });
					//revert synopsis
					this.getConclusion();
					return;
				}
			});
		});
	}

	onSaveClickTitle() {
		this.showToaster("Saving Title ...", "");
		this.state.logger.logProjectSaveTitle(this.props.project.projectId);

		//get the admin project to update
		this.props.fetchAdminProject(this.props.project.projectId).then((response) => {
			if (!response) { this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to retrieving project', isButtonTitleDisabled: false }); }

			this.setState({ errormsg: '', isButtonTitleDisabled: true });

			this.setState({ editTitle: false });

			let project = { ...response };
			project.customerProjectTitle = this.state.visibleLabelTitle;

			let updatedProjectData = { "instance": { ...project }, "comment": "PortalUI: Portal Save customerProjectTitle" };
			//Call API to save
			this.props.saveProjectAdmin(updatedProjectData).then(result => {

				if (result && !result.ok) {
					let msg = result.headers.get('X-HTTP-Error-Description');
					this.setState({ errormsg: 'ERROR: ' + 'There was an Error while trying to save project conclusion', isButtonTitleDisabled: false });
					//revert title
					this.getTitle();
					return;
				}
			});
		});
	}
	onCancelSynopsisClick(stat) {
		this.setState({ editSynopsis: false }, this.getSynopsis());
	}

	onCancelConclusionClick(stat) {
		this.setState({ editConclusion: false }, this.getConclusion());
	}

	onCancelTitleClick(stat) {
		this.setState({ editTitle: false }, this.getTitle());
	}

	getSynopsis() {
		if (!this.props.project) return;
		this.setState({ synopsisIsLoading: true });
		this.props.fetchAdminProject(this.props.project.projectId).then((result) => {
			let adminProject = result;
			if (adminProject.customerSynopsis) { this.setState({ visibleLabelSynopsis: adminProject.customerSynopsis, synopsisIsLoading: false }); }
			else {
				let defaultLabel = adminProject.synopsis ? scrubHTML(adminProject.synopsis, true)[0] : "Metabolomics project";
				this.setState({ visibleLabelSynopsis: defaultLabel, synopsisIsLoading: false });
			}
		});
	}

	getConclusion() {

		if (!this.props.project) return;
		this.setState({ conclusionIsLoading: true });
		this.props.fetchAdminProject(this.props.project.projectId).then((result) => {
			let adminProject = result;
			if (adminProject.customerConclusion) { this.setState({ visibleLabelConclusion: adminProject.customerConclusion, conclusionIsLoading: false }); }
			else {
				let defaultLabel = adminProject.conclusion ? scrubHTML(adminProject.conclusion, true)[0] : "Metabolomics project";
				this.setState({ visibleLabelConclusion: defaultLabel, conclusionIsLoading: false });
			}
		});
	}

	getTitle() {

		if (!this.props.project) return;
		this.setState({ titleIsLoading: true });
		this.props.fetchAdminProject(this.props.project.projectId).then((result) => {
			let adminProject = result;
			if (adminProject.customerProjectTitle) { this.setState({ visibleLabelTitle: adminProject.customerProjectTitle, titleIsLoading: false }); }
			else {
				let defaultLabel = adminProject.projectTitle ? scrubHTML(adminProject.projectTitle) : "Metabolomics project Title";
				this.setState({ visibleLabelTitle: defaultLabel, titleIsLoading: false });
			}
		});
	}

	handleChangeSynopsis(event) {
		this.setState({ visibleLabelSynopsis: event.target.value });
	}

	handleChangeConclusion(event) {
		this.setState({ visibleLabelConclusion: event.target.value });
	}

	handleChangeTitle(event) {
		this.setState({ visibleLabelTitle: event.target.value });
	}

	showToaster(title, body, autoClose = 2000) {
		toast(<Toaster title={title} body={body} />, { autoClose: autoClose });
	}

	render() {

		let { editSynopsis, editConclusion, editTitle, visibleLabelSynopsis, visibleLabelConclusion, visibleLabelTitle } = this.state;
		return (
			<div className="AdminProjectInfo">
				{super.render()}
				<div className={'infoSection'}>
					<div><Label className={'infoTitle'}>Project Title:</Label></div>
					<textarea id="txtAreaEditProjectTitle" title="txtAreaEditProjectTitle" rows="5" ref={this.textInput} className={editTitle ? 'textarea ' : 'd-none textarea'} value={visibleLabelTitle} onChange={this.handleChangeTitle} />
					<span id="lblProjectTitle" title={"title"} className={editTitle ? 'label d-none' : 'label'} >{visibleLabelTitle}</span>
					{this.state.titleIsLoading ? <LoadingSpinner size={25} /> : ''}
					<Material id="actionEditTitle" className={editTitle ? 'd-none editPen' : 'editPen'} title="Edit name" onClick={this.onPenClickTitle} icon={'edit'} />
					<div className={"UserActions"}>
						<Button id="btnCancelEditTitle" className={editTitle ? 'alt ' : 'd-none'} onClick={this.onCancelTitleClick} >Cancel</Button>
						<Button id="btnSaveEditTitle" className={editTitle ? '' : 'd-none'} disabled={this.state.isButtonTitleDisabled} onClick={this.onSaveClickTitle}  >Save</Button>
					</div>
				</div>
				<div className={'infoSection'}>
					<div><Label className={'infoTitle'}>Project Synopsis:</Label></div>
					<textarea id="txtAreaEditProjectSynopsis" title="txtAreaEditProjectSynopsis" rows="5" ref={this.textInput} className={editSynopsis ? 'textarea ' : 'd-none textarea'} value={visibleLabelSynopsis} onChange={this.handleChangeSynopsis} />
					<span id="lblProjectSynopsis" title={"title"} className={editSynopsis ? 'label d-none' : 'label'} >{visibleLabelSynopsis}</span>
					{this.state.synopsisIsLoading ? <LoadingSpinner size={25} /> : ''}
					<Material id="actionEditSynopsis" className={editSynopsis ? 'd-none editPen' : 'editPen'} title="Edit name" onClick={this.onPenClickSynopsis} icon={'edit'} />
					<div className={"UserActions"}>
						<Button id="btnCancelEditSynopsis" className={editSynopsis ? 'alt ' : 'd-none'} onClick={this.onCancelSynopsisClick} >Cancel</Button>
						<Button id="btnSaveEditSynopsis" className={editSynopsis ? '' : 'd-none'} disabled={this.state.isButtonSynopsisDisabled} onClick={this.onSaveClickSynopsis}  >Save</Button>
					</div>
				</div>
				<div>
					<div><Label className={'infoTitle'}>Project Conclusion:</Label></div>
					<textarea id="txtAreaEditProjectConclusion" title="txtAreaEditProjectConclusion" rows="5" ref={this.textInput} className={editConclusion ? 'textarea ' : 'd-none textarea'} value={visibleLabelConclusion} onChange={this.handleChangeConclusion} />
					<span id="lblProjectConclusion" title={"title"} className={editConclusion ? 'label d-none' : 'label'} >{visibleLabelConclusion}</span>
					{this.state.conclusionIsLoading ? <LoadingSpinner size={25} /> : ''}
					<Material id="actionEditConclusion" className={editConclusion ? 'd-none editPen' : 'editPen'} title="Edit name" onClick={this.onPenClickConclusion} icon={'edit'} />
					<div className={"UserActions"}>
						<Button id="btnCancelEditConclusion" className={editConclusion ? 'alt ' : 'd-none'} onClick={this.onCancelConclusionClick} >Cancel</Button>
						<Button id="btnSaveEditConclusion" className={editConclusion ? '' : 'd-none'} disabled={this.state.isButtonConclusionDisabled} onClick={this.onSaveClickConclusion}  >Save</Button>
					</div>
				</div>
				<div className="errormsg">{this.state.errormsg}</div>
			</div>
		);
	}
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchAdminProject: (projectId) => dispatch(projectsFetchDataAdminNoRedux(projectId)),
		saveProjectAdmin: (projectData) => dispatch(projectsSaveDataAdmin(projectData))
	};
};

export default connect(null, mapDispatchToProps)(AdminProjectInfo);