'use strict';
import React from 'react';
import { AuditLoggerWrapper } from './AuditLoggerWrapper.jsx';

export class Auditor extends React.Component {
  
  constructor(props) {
    super(props); 
		this.getAuditLogger = this.getAuditLogger.bind(this);

    this.state = {
      logger: null,
    };
  }

  getAuditLogger(logger) {
		// eslint-disable-next-line
		this.state.logger = logger;
  }

  render() {
    return <AuditLoggerWrapper callback={this.getAuditLogger} />;
  }
}