'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { Blue, Gray } from '@/theme/colors';

const Toaster = ({
	icon = 'check-circle',
	title = '',
	body = '',
	color = 'primary',
	children
}) => {
	const theme = useTheme();
	return (
		<Box className="Toaster bg" sx={{
			position: "relative",
			borderRadius: 2,
			padding: 2.5,
			width: "270px",
			backgroundColor: "white",
			
		}}>
			<Typography variant="h1" className="title" sx={{ fontSize: "22px" }}>
				<Typography component="i" className={`icon fa fa-${icon}`} color={color} sx={{
					marginTop: "1px",
					marginRight: "5px",
					fontFamily: "FontAwesome",
					fontSize: "1.6rem"
				}} />{title}
			</Typography>
			<Typography color={theme.palette.text.primary}>
				{body}
			</Typography>
			{children}
		</Box>
	);
};

Toaster.propTypes = {

	/** The material icon to use in the title */
	icon: PropTypes.string,

	/** The color of the icon */
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The title text */
	title: PropTypes.string,

	/** The body text */
	body: PropTypes.string,
};

export default Toaster;