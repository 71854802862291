export class Log {
    
    constructor() {
        this.level = "";
        this.name = "";
        this.properties = new Map();
        this.measurements = new Map();
    }

    SetLevel(level){
        this.level = level;
    }
    SetName(name){
        this.name = name;
    }
    AddProperty(index,value){
        this.properties.set(index,value);
    }
    AddMeasuremnet(index,value){
        this.measurements.set(index,value);
    }
}