import {ascending, descending }  from "d3";
import createLinePath from "./createLinePath";
export default function  createLineData(dataset, id, type, settings){
    const data = dataset.filter((d) =>
      type === "metabolite"
        ? d.chemical_id === id
        : d.association.replace(/\s/g, "") === id.replace(/\s/g, "")
    );

    const direction = data.filter((d) => d.direction > 0).length;
    const directions =
      type === "metabolite"
        ? [direction, data.length - direction]
        : [data.length - direction, direction];

    let cwLayer = -1;
    let ccwLayer = -1;

    const newData = data
      .sort(function (x, y) {
        return type === "metabolite"
          ? ascending(x.sort, y.sort)
          : descending(x.sort, y.sort);
      })
      .map((d) => {
        if (d.direction === 1) cwLayer= cwLayer + 1;
        else ccwLayer = ccwLayer + 1;
        return { ...d, 
          direction_totals: directions, 
          layer: d.direction === 1 ? cwLayer : ccwLayer };
      });

      const withPath = newData.map((d)=> {
          return {
            ...d,
            path: createLinePath(d, type, settings)
          };

      });


    return withPath;
  }