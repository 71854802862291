import { Box, Tab, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export const Tabber = ({
	children,
	tabs,
	defaultTab = 0,
	sx,
	panelProps,
	onChange,
	tabProps,
	...props
}) => {
	const [tab, setTab] = useState(defaultTab)

	useEffect(() => {
		if (props.tab !== undefined) setTab(props.tab)
	}, [props.tab])

	const handleChange = useCallback((e, value) => {
		if (onChange) props.onChange(value)
		else setTab(value)
	}, [onChange])

	return (
		<>
			<Tabs value={tab} label={tabs[tab].label} onChange={handleChange} centered {...props}>
				{tabs.map((tab, index) => {
					return <Tab key={index} variant="tab" {...tabProps} {...tab} />
				})}
			</Tabs>
			<Box {...panelProps}>
				{React.Children.map(children, (child, index) => {
					if (tab !== index) return null;
					return <>{child}</>
				})}
			</Box>
		</>
	)
}