import { createAction } from '@reduxjs/toolkit';

/**Form Actions */
// export const SUBMIT_FORM = "SUBMIT_FORM";

/** Managed Users Actions */

export const MANAGEDUSERS_HAS_ERRORED = createAction('managedUsers/managedUsersHasErrored').toString();
export const MANAGEDUSERS_IS_LOADING = createAction('managedUsers/managedUsersIsLoading').toString();
export const MANAGEDUSERS_FETCH_DATA_SUCCESS = createAction('managedUsers/managedUsersFetchDataSuccess').toString();

/** Available Roles */
export const AVAILABLEROLES_HAS_ERRORED = createAction('availableRoles/availableRolesHasErrored').toString();
export const AVAILABLEROLES_IS_LOADING = createAction('availableRoles/availableRolesIsLoading').toString();
export const AVAILABLEROLES_FETCH_DATA_SUCCESS = createAction('availableRoles/availableRolesFetchDataSuccess').toString();


/** Files Actions */
export const FILES_HAS_ERRORED = createAction('files/filesHasErrored').toString();
export const FILES_IS_LOADING = createAction('files/filesIsLoading').toString();
export const SELECTED_FILES_IS_LOADING = createAction('files/selectedFilesIsLoading').toString();
export const FILES_FETCH_DATA_SUCCESS = createAction('files/filesFetchDataSuccess').toString();
export const FILES_SET_FILTER = 'FILES_SET_FILTER';
export const FILES_SET_VISIBLE = 'FILES_SET_VISIBLE';
export const FILES_IS_DOWNLOADING = 'FILES_IS_DOWNLOADING';


/** LoggedInUser Actions */
export const LOGGEDINUSER_HAS_ERRORED = createAction('loggedInUser/loggedInUserHasErrored').toString();
export const LOGGEDINUSER_IS_LOADING = createAction('loggedInUser/loggedInUserIsLoading').toString();
export const LOGGEDINUSER_FETCH_DATA_SUCCESS = createAction('loggedInUser/loggedInUserFetchDataSuccess').toString();

/** ProjectManagers Actions */
export const PROJECTMANAGERS_HAS_ERRORED = 'PROJECTMANAGERS_HAS_ERRORED';
export const PROJECTMANAGERS_IS_LOADING = 'PROJECTMANAGERS_IS_LOADING';
export const PROJECTMANAGERS_FETCH_DATA_SUCCESS = 'PROJECTMANAGERS_FETCH_DATA_SUCCESS';

/** Projects Actions */
export const PROJECTS_HAS_ERRORED = createAction('projects/projectsHasErrored').toString();
export const PROJECTS_IS_LOADING = createAction('projects/projectsIsLoading').toString();
export const PROJECTS_FETCH_DATA_SUCCESS = createAction('projects/projectsFetchDataSuccess').toString();

/** DiscoveryPanels Actions */
export const SMART_PANELS_HAS_ERRORED = createAction('projects/discoveryPanelsHasErrored').toString();
export const SMART_PANELS_IS_LOADING = createAction('projects/discoveryPanelsIsLoading').toString();
export const SMART_PANELS_FETCH_DATA_SUCCESS = createAction('projects/discoveryPanelsFetchDataSuccess').toString();

/** Projects Summary Actions */
export const PUBLISHSUMMARY_HAS_ERRORED = createAction('publishSummary/publishSummaryHasErrored').toString();
export const PUBLISHSUMMARY_IS_LOADING = createAction('publishSummary/publishSummaryIsLoading').toString();
export const PUBLISHSUMMARY_FETCH_DATA_SUCCESS = createAction('publishSummary/publishSummaryFetchDataSuccess').toString();

/** Managed Projects Actions */
export const MANAGEDPROJECTS_HAS_ERRORED = createAction('managedProjects/managedProjectsHasErrored').toString();
export const MANAGEDPROJECTS_IS_LOADING = createAction('managedProjects/managedProjectsIsLoading').toString();
export const MANAGEDPROJECTS_FETCH_DATA_SUCCESS = createAction('managedProjects/managedProjectsFetchDataSuccess').toString();

/** SalesPersons Actions */
export const SALESPERSONS_HAS_ERRORED = 'SALESPERSONS_HAS_ERRORED';
export const SALESPERSONS_IS_LOADING = 'SALESPERSONS_IS_LOADING';
export const SALESPERSONS_FETCH_DATA_SUCCESS = 'SALESPERSONS_FETCH_DATA_SUCCESS';

/** SampleSetData Actions */
export const SAMPLESETDATA_HAS_ERRORED = createAction('sampleSetData/sampleSetDataHasErrored').toString();
export const SAMPLESETDATA_IS_LOADING = createAction('sampleSetData/sampleSetDataIsLoading').toString();
export const SAMPLESETDATA_IS_SAVING = createAction('sampleSetData/sampleSetDataIsSaving').toString();
export const SAMPLESETDATA_IS_SELECTED = createAction('sampleSetData/sampleSetDataIsSelected').toString();
export const SAMPLESETDATA_FETCH_DATA_SUCCESS = createAction('sampleSetData/sampleSetDataFetchDataSuccess').toString();

/** SearchResults Actions */
export const SEARCHRESULTS_HAS_ERRORED = createAction('searchResults/searchResultsHasErrored').toString();
export const SEARCHRESULTS_SET_VISIBLE = createAction('searchResults/searchResultsSetVisible').toString();
export const SEARCHRESULTS_IS_LOADING = createAction('searchResults/searchResultsIsLoading').toString();
export const SEARCHRESULTS_FETCH_DATA_SUCCESS = createAction('searchResults/searchResultsFetchDataSuccess').toString();
export const SEARCHRESULTS_SET_FILTER = createAction('searchResults/searchResultsSetFilter').toString();

/** Session Actions */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

/** Pathway Map Actions */
export const ANIMAL_HAS_ERRORED = 'ANIMAL_HAS_ERRORED';
export const ANIMAL_IS_LOADING = 'ANIMAL_IS_LOADING';
export const ANIMAL_FETCH_DATA_SUCCESS = 'ANIMAL_FETCH_DATA_SUCCESS';
export const ANIMAL_PUT_DATA_SUCCESS = 'ANIMAL_PUT_DATA_SUCCESS';

export const PLANT_HAS_ERRORED = 'PLANT_HAS_ERRORED';
export const PLANT_IS_LOADING = 'PLANT_IS_LOADING';
export const PLANT_FETCH_DATA_SUCCESS = 'PLANT_FETCH_DATA_SUCCESS';
export const PLANT_PUT_DATA_SUCCESS = 'PLANT_PUT_DATA_SUCCESS';

/**Toast Actions */
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
