import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scaleLinear } from "d3";

import Range from "../../../components/controls/Range.jsx";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { setPValue } from "../../redux/reducers/discoveryPanels.reducer";
import { useDebounce } from "../../../hooks/useDebounce.js";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger.js";

const min = 0.001;
const max = 0.103;
const step = 0.005;
const values = [0.001, 0.05, 0.1];

const scale = scaleLinear().domain([min, max]).range([0, 98]);
const calcPos = (v) => {
  return Number(scale(v));
};

const getDecimalCount = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
};

const powPoint = (d) => {
  return Math.pow(10, getDecimalCount(d));
};

function round(x) {
  if (x * powPoint(x) === 1) return min;
  else if ((x * powPoint(x)) % 5 === 0) {
    return (Math.floor((x * powPoint(x)) / 5) * 5) / powPoint(x);
  } else {
    return (Math.floor((x * powPoint(x)) / 5) * 5) / powPoint(x);
  }
}

const Slider = (props) => {
  const logger = useLocalAuditLogger();
  const dispatch = useDispatch();
  const { pValue } = useSelector(filterSelector);

  const [value, setValue] = useState(pValue);
  const [displayValue, setDisplayValue] = useState(pValue);
  const debouncedValue = useDebounce(value);
  let pos = calcPos(value);

  useEffect(() => {
    dispatch(setPValue(debouncedValue));
  }, [debouncedValue, dispatch]);

  const onChange = useCallback((val) => {
    setDisplayValue(round(val));
  }, []);

  const onChangeComplete = useCallback((val) => {
    setValue(val);
    logger.logUserAction(`user set P slider to ${val}`);
  }, []);

  const labelRenderer = useCallback(() => {
    return null;
  });

  return (
    <div className="slider-wrapper">
      <p className="input-label">{props.label}</p>
      <p className="range-value" style={{ left: calcPos(displayValue) + "%" }}>
        {displayValue}
      </p>
      <div className="range">
        <div className="range-ticks-wrapper">
          {values.map((d, i) => {
            return (
              <div
                className="range-tick"
                key={i}
                style={{ left: calcPos(d) + "%" }}
              >
                <div className="range-tick-indicator" />
                <p>{d}</p>
              </div>
            );
          })}
        </div>
        <Range
          defaultValue={displayValue}
          labelRenderer={labelRenderer}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />
      </div>
    </div>
  );
};
export default Slider;
