import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Link,
  ResetPassword,
  TextButton,
  ValidatedInput,
  ValidatedForm,
} from '../index.jsx';
import microsoftLogo from '../../theme/svg/MyMetabolon-Login-Microsoft.svg';
import googleLogo from '../../theme/svg/MyMetabolon-Login-Google.svg';
import linkedinLogo from '../../theme/svg/MyMetabolon-Login-LinkedIn.svg';
import queryString from 'query-string';
import headerlogo from '../../theme/svg/MyMetabolon-color.svg';
import { Logger, Log } from '../../utils/Logger';
import { AppInsightLogLevel } from '../../enums/Enums';
import { useAuth } from '@/hooks/auth.hook';
import './Login.scss';

const logger = new Logger();

const Login = (props) => {
  const history = useHistory();
  const location = useLocation();
	const auth = useAuth();
  const [match, setMatch] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [createMode, setCreateMode] = useState(false);
  const [forgotLoginMode, setForgotLoginMode] = useState(false);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [creds, setCreds] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [returnUrl, setReturnUrl] = useState(null);

  useEffect(() => {
    let creation = false, emailValue = '';
    let forgotLogin = (history && location && location.pathname.includes("forgotpassword"));
    const { email, return_url } = queryString.parse(location.search);
    if (email) {
      emailValue = email;
      creation = true;
    }

    if (history && location && location.pathname.includes("sessionexpired")) {
      setErrorMessage("Your session has timed out.  Please sign in again.");
    }

    setEmailInputValue(emailValue);
    if (forgotLogin || creation || emailValue) {
      setForgotLoginMode(forgotLogin);
      setCreateMode(creation);
    }
    setReturnUrl(return_url);
  }, [emailInputValue]);

  const handleChange = useCallback((e) => {
    setEmailInputValue(event);
  });

  const logError = (errorInfo) => {
    //Log error
    let logError = new Log();
    logError.SetLevel(AppInsightLogLevel.EVENT);
    logError.SetName('LOGINERROR');
    logError.AddProperty('Error_Message', errorInfo);

    logger.doLog(logError);
  };

  const loginResultsCB = (err, authResult) => {
    if (err) {
      setErrorMessage(err.description);
      setIsLoading(false);
      if (err.code == "access_denied") {
        logError("Login Failure");
      }
      if (err.code == "request_error") {
        setClearCookieError();
        logError("Cross Domain Request Error: " + err.error_description);
      }
      return;
    }
  };

  const setClearCookieError = () => {
    let error = "We're having trouble getting you connected. Please clear your browser cookies, and try again. <br /> See <a href=\"https://portal.metabolon.com/help\" target=\"_blank\">https://portal.metabolon.com/help</a> for information about clearing cookies.";
    setErrorMessage(error);
    setIsLoading(false);
  };

  const setPasswordLengthError = () => {
    let error = "Password too long! <br /> Please input a password with 128 Characters or less.";
    setErrorMessage(error);
    setIsLoading(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage('');
  };

  const clearEmail = () => {
    let loginForm = document.getElementById("loginForm");
    if (loginForm) loginForm.reset();
  };

  const login = useCallback((data) => {
    setIsLoading(true);
    clearErrorMessage();
    if (data.password.length > 128) {
      setPasswordLengthError();
      return;
    }
    auth.login(data.email, data.password, loginResultsCB, returnUrl);
  }, [emailInputValue, auth.login, returnUrl]);

  const signup = useCallback((data) => {
    clearErrorMessage();
    if (data.password.length > 128) {
      setPasswordLengthError();
      return;
    }

    let email = data.email;
    if (!email && emailInputValue) {
      email = emailInputValue;
      data.email = email;
    }
    setIsLoading(true);
    let source = 'direct';
    if (queryString.parse(location.search).src) {
      source = queryString.parse(location.search).src;
    }
    auth.signup(email, data.password, source, signUpResultsCB(email, data.password));
  }, [emailInputValue, auth.signup]);

  const signUpResultsCB = (email, password) => (err, authResult) => {
    if (err) {
      if (err.code && err.code == "invalid_password") {
        setErrorMessage("Invalid Password Combination<br /><div class=\"Criteria\">* You must have at least 12 characters in length<br />* Contain at least 3 of the following 4 types of characters:<br /><div class=\"CriteriaList\">- lower case letters (a-z)<br />- upper case letters (A-Z)<br />- numbers (i.e. 0-9)<br />- special characters (e.g. !@#$%^&*)</div></div>");
        setIsLoading(false);
        return;
      }
      if (err.code && err.code == "user_exists") {
        setErrorMessage("WE'RE SORRY, SOMETHING WENT WRONG WHEN ATTEMPTING TO SIGN UP.");
        setIsLoading(false);
        return;
      }
      setErrorMessage("WE'RE SORRY, SOMETHING WENT WRONG WHEN ATTEMPTING TO SIGN UP.");
      setIsLoading(false);
      return;
    }
    auth.login(email, password, loginResultsCB);
  };

  const loginWithGoogle = useCallback(() => {
    clearErrorMessage();
    auth.loginWithGoogle(returnUrl);
  }, [auth.loginWithGoogle, returnUrl]);

  const loginWithLinkedIn = useCallback(() => {
    clearErrorMessage();
    auth.loginWithLinkedIn(returnUrl);
  }, [auth.loginWithLinkedIn, returnUrl]);

  const loginWithMicrosoft = useCallback(() => {
    clearErrorMessage();
    auth.loginWithMicrosoft(returnUrl);
  }, [auth.loginWithMicrosoft, returnUrl]);

  const toggleCreate = useCallback(() => {
    clearErrorMessage();
    clearEmail();
    setCreateMode(!createMode);
    setForgotLoginMode(false);
  }, [createMode]);

  const updateCreateMode = useCallback(() => {
    clearErrorMessage();
    clearEmail();
    setCreateMode(true);
    setForgotLoginMode(false);
  });

  const toggleForgotLogin = useCallback(() => {
    clearErrorMessage();
    clearEmail();
    setForgotLoginMode(!forgotLoginMode);
    setCreateMode(true);
  }, [forgotLoginMode]);

  const onPasswordChange = useCallback((val) => {
    setMatch(val);
  });

  const goToTerms = useCallback(() => {
    window.open("/terms", "_blank");
  });

  const goToPrivacyPolicy = useCallback(() => {
    window.open("http://www.metabolon.com/utilities/privacy-policy", "_blank");
  });

  const goToLanding = useCallback(() => {
    window.location = "https://www.metabolon.com";
  });

  const signInDialog = RenderDialogSignIn(toggleCreate);
  const createDialog = RenderDialogCreate(toggleCreate);
  const resetPasswordDialog = RenderDialogResetPassword(updateCreateMode);

  return (
    <div className="OuterContainer" data-testid="login">
      <div> <TextButton className="backButton" size="small" color="primary" onClick={goToLanding}>back to metabolon.com</TextButton> </div>

      <div>
        <div className="LogoHeader">
          <img src={headerlogo} />
        </div>
        {
          !forgotLoginMode ?
            (
              <LoginForm
                login={login}
                toggleForgotLogin={toggleForgotLogin}
                goToTerms={goToTerms}
                goToPrivacyPolicy={goToPrivacyPolicy}
                createMode={createMode}
                signup={signup}
                match={match}
                history={history}
                loginWithMicrosoft={loginWithMicrosoft}
                loginWithGoogle={loginWithGoogle}
                loginWithLinkedIn={loginWithLinkedIn}
                isLoading={isLoading}
                emailInputValue={emailInputValue}
                errorMessage={errorMessage}
                onPasswordChange={onPasswordChange}
              />
            )
            :
            <ResetPassword auth={auth} history={history} />
        }
        {
          !forgotLoginMode ?
            createMode ?
              signInDialog
              :
              createDialog
            :
            resetPasswordDialog
        }
      </div>
    </div>
  );
};

export default Login;

const RenderDialogSignIn = (toggleCreate) => (
  <div className="CreateAccountTrigger" onClick={toggleCreate}>
    <div>Already have an account?</div>
    <div>Sign in</div>
  </div>
);

const RenderDialogCreate = (toggleCreate) => (
  <div className="CreateAccountTrigger" onClick={toggleCreate}>
    <div>Don't have an account?</div>
    <div>Create Account</div>
  </div>
);

const RenderDialogResetPassword = (updateCreateMode) => (
  <div className="CreateAccountTrigger" onClick={updateCreateMode}>
    <div>Don't have an account?</div>
    <div>Create Account</div>
  </div>
);

const LoginForm = ({
  login,
  toggleForgotLogin,
  goToTerms,
  goToPrivacyPolicy,
  createMode,
  signup,
  match,
  history,
  loginWithMicrosoft,
  loginWithGoogle,
  loginWithLinkedIn,
  isLoading,
  emailInputValue,
  errorMessage,
  onPasswordChange,
}) => {

  let matchPass, createInfo,
    submitLabel = "Secure Sign In",
    lockIcon = "lock",
    onSubmit = login,
    forgotPassword = (
      <div className="ForgotLogin">
        <TextButton size='small' color='primary' onClick={toggleForgotLogin}>Forgot login information?</TextButton>
      </div>
    );

  if (createMode) {
    submitLabel = "Create Account";
    lockIcon = "",
      onSubmit = signup;
    matchPass = <ValidatedInput name="match" htmlType="password" placeholder="Confirm Password" match={match} matchMessage="Passwords must match!" />;
    createInfo = (<div className="CreateInfo">
      <div>
        <div> By clicking Create Account, I agree to the </div>
        <div> <TextButton onClick={goToTerms}>Terms of Service</TextButton> and <TextButton onClick={goToPrivacyPolicy}>Privacy and Cookie Policy</TextButton></div>
      </div>
    </div>);
  }
  return (
    <div className="InnerContainerLogin" >
      <div className="headerText"> Sign in with </div>
      <div className="SSOLinks" style={{ height: '50px' }}>
        <Link history={history} size="normal" className="login" alt={true} color="tertiary" onClick={loginWithMicrosoft}>
          Microsoft
          <img src={microsoftLogo} />
        </Link>
        <Link history={history} size="normal" className="login" alt={true} color="tertiary" onClick={loginWithGoogle}>
          Google
          <img src={googleLogo} />
        </Link>
        <Link history={history} size="normal" className="login" alt={true} color="tertiary" onClick={loginWithLinkedIn}>
          LinkedIn
          <img src={linkedinLogo} />
        </Link>
      </div>
      <div className="headerText">Secured by <div className="auth0-logo" /> </div>
      <span className="SocialBreak"> or </span>

      <ValidatedForm
        id="loginForm"
        isLoading={isLoading}
        buttonIcon={lockIcon}
        submitLabel={submitLabel}
        onSubmit={onSubmit}
        className="Inputs"
      >
        <ValidatedInput
          name="email"
          type="email"
          placeholder="Email Address"
          initialValue={emailInputValue}
        />

        { }
        <ValidatedInput
          validatepwnotempty={"true"}
          name="password"
          type="password"
          onChange={onPasswordChange}
          placeholder="Password"
        />
        {  }

        {matchPass}
        {createInfo}
      </ValidatedForm>

      {forgotPassword}
      <div className="ErrorMessage" >
        { 
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        }
      </div>
    </div>
  );
};