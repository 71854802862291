'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class SearchResult extends Component {

	constructor(props) {
		super(props);
		this.state = {
			children: this.props.children
		};
	}

	componentWillReceiveProps(nextProps) {

		if (this.props.children !== nextProps.children) {
			this.setState({ ...this.state, children: nextProps.children });
		}
	}

	getStyles = (styles) => {
		let hasResults = this.state.children.length > 0 && this.props.showResults;

		return {
			results: {
				display: hasResults ? 'block' : 'none',
				maxWidth: '100%',
				backgroundColor: 'rgb(255,255,255)',
				marginTop: '10px',
				marginLeft: '5px',
				border: "2px solid #ccc",
				borderRadius: ".5em",
				maxHeight: "339px",
				overflowY: "auto",
				minWidth: "306px",
				...styles
			}
		};
	};

	render() {
		let styles = this.getStyles(this.props.styles);
		return (
			<div style={styles.results}>
				{this.props.children}
			</div>
		);
	}
}

SearchResult.propTypes = {
	/** Show results in dropdown */
	showResults: PropTypes.bool
};

SearchResult.defaultProps = {
	children: [],
	showResults: false
};

export default SearchResult;