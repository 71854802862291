import React, {
  useEffect,
  useLayoutEffect,
  useState,
  memo,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Explorer.scss";
import HeliogramGraph from "../components/graphs/heliogram/HeliogramGraph";
import { Loader } from "../components/controls/Loader";
import Legend from "../components/containers/Legend";
import DownloadSVG from "../components/controls/DownloadSVG";
import { dataSelector } from "../redux/selectors/data.selector";
import { explorerSelector } from "../redux/selectors/explorer.selector";
import { filterSelector } from "../redux/selectors/filter.selector";
import { viewPortSelector } from "../redux/selectors/viewPort.selector";
import { interactionsSelector } from "../redux/selectors/interactions.selector";

import {
  setActiveTab,
  updateGroupList,
  updateAssociationCoords,
  updateCombinedCoords,
  updateMetaboliteCoords,
} from "../redux/reducers/discoveryPanels.reducer";
import ExternalLink from "../../components/buttons/ExternalLink.jsx";
import { discoveryPanelsSelector } from "../redux/selectors/discoveryPanels.selector";
import { DiscoveryPanelMap } from "@/enums/Enums";

const Explorer = ({ match }) => {
  const projectId = match.params.projectId;
  const dispatch = useDispatch();
  const [mounted, setMount] = useState(false);
  const {
    association,
    associations: filterAssociations,
    group,
    tabs,
  } = useSelector(filterSelector);
  const viewPort = useSelector(viewPortSelector);
  const { associationCoords, metaboliteCoords, groupList } =
    useSelector(explorerSelector);
  const { associations, metabolites, sample } = useSelector(dataSelector);
	const { productType } = useSelector(discoveryPanelsSelector);
	const productTypeDisplay = useMemo(() => DiscoveryPanelMap.ui[productType], [productType]);

  const selectedState = useSelector(interactionsSelector);

  const type = useMemo(() => {
    return filterAssociations.filter((d) => {
      return d.value === association;
    })[0].key;
  }, [association]);

  const svgDownloadText = useMemo(() => {
    if (selectedState.id === "default") return "Powered by Metabolon";
    else return selectedState.display;
  }, [selectedState]);

  useEffect(() => {
    setMount(true);
    dispatch(setActiveTab(tabs[1].url));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    return () => setMount(false);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(updateGroupList());
  }, [group, metabolites]);

  useEffect(() => {
    dispatch(updateMetaboliteCoords());
  }, [groupList, viewPort]);

  useEffect(() => {
    dispatch(updateAssociationCoords());
  }, [association, associations, groupList, viewPort]);

  useEffect(() => {
    dispatch(updateCombinedCoords());
  }, [associationCoords, metaboliteCoords]);

  if (!mounted) {
    return <Loader />;
  }

  return (
    <div className="explorer fade-in">
      <section>
        <div className="copy">
          <h3>
            {sample.projectCode}<br />{sample.matrixType}
          </h3>
          <h2>
            The {productTypeDisplay} Discovery Panel covers {metaboliteCoords.length}{" "}
            metabolites across {associationCoords.length} different {type}
          </h2>
          <p>
            The outer ring consists of the {metaboliteCoords.length} metabolites
            in the {} Discovery Panel.
          </p>
          <Legend
            size={false}
            color={true}
            colorWidth="205px"
            margin="1.5rem auto 0 auto"
          />
          <ExternalLink
            color="white"
            href={
              "https://www.metabolon.com/support/portal/impact-explorer-heliogram/"
            }
            className="transparent-link-button slide"
          >
            <div className="impact-link-wrapper">
              <p className="body">Impact Explorer FAQ</p>
            </div>
          </ExternalLink>
          <DownloadSVG
            id="heliogram-svg"
            name="ImpactExplorer"
            projectId={projectId}
            textDisplay={svgDownloadText}
            location="explorer"
          />
        </div>

        <HeliogramGraph projectId={projectId} />
      </section>
    </div>
  );
};
export default memo(Explorer);
