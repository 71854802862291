import React from "react";
import PropTypes from "prop-types";

const Arrow = ({ direction }) => {
  const path = direction
    ? "M5.5 0L10.2631 8.25H0.73686L5.5 0Z"
    : "M5.5 9L0.73686 0.75H10.2631L5.5 9Z";

  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "8px",
        height: "6px",
        paddingRight: "1px",
        position: "absolute",
        bottom: "3px",
        overflow: "visible",
      }}
    >
      <path d={path} fill="var(--cold-gray-darkest)" />
    </svg>
  );
};

Arrow.propTypes = {
  direction: PropTypes.bool,
};

Arrow.defaultProps = {
  direction: "down",
};

export default Arrow;
