// extracted by mini-css-extract-plugin
var _1 = "stats_comparisons";
var _2 = "stats_highlights";
var _3 = "stats_infoGroup";
var _4 = "stats_infoWrapper";
var _5 = "stats_metabolonBlue";
var _6 = "stats_metabolonRed";
var _7 = "stats_stats";
var _8 = "stats_wrapper";
export { _1 as "comparisons", _2 as "highlights", _3 as "infoGroup", _4 as "infoWrapper", _5 as "metabolonBlue", _6 as "metabolonRed", _7 as "stats", _8 as "wrapper" }
