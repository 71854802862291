export function clone(target) {
	return JSON.parse(JSON.stringify(target));
}

export function merge(target, source, overwrite=true) {
	return mkr.merge(target, source, overwrite);
}

export function filter(data=[], filters=[]){
	let filteredData = data.concat();
	filters.forEach((filter, n) => {
		filteredData = filteredData.filter(filter);
	});
	return filteredData;
}