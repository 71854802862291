import ManifestUploadStepper from "metabux/components/ManifestUploadStepper/ManifestUploadStepper";
import React, { useCallback, useEffect, useMemo } from "react";
import { resources } from "@/Assets";
import {
	Box,
	Tab,
	Tabs,
	Divider,
	Stack,
	Link,
	Typography,
} from "@mui/material";
import { Resources } from "@/lenses/uilenses/components/ManifestUploadStepper/components/Resources";
import { Blue } from "@/lenses/uilenses/theme/colors";
import { ArrowRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { projectsDataSelector, projectsIsLoadingSelector } from "@/redux/selectors/projects.selector";
import { projectsFetchData } from "@/redux/actions/projects";

export const ClientOnboarding = ({ projectId, ...props }) => {
	const [value, setValue] = React.useState(0);

	const { projectCode } = props.match.params;

	const projects = useSelector(projectsDataSelector);
	const isLoading = useSelector(projectsIsLoadingSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		if (projects?.length === 0 && !isLoading)
			dispatch(projectsFetchData());
	}, [projects]);

	const handleTabChange = useCallback((event, newValue) => {
		setValue(newValue);
	}, []);

	const tabs = [
		{
			label: "Study Setup",
			content: (
				<ManifestUploadStepper
					resources={resources}
					projectId={projectId}
					projectCode={projectCode}
				/>
			),
			id: "study-setup",
		},
		{
			label: "Resources",
			content: <Resources resources={resources} projectId={projectId} />,
			id: "resources",
		},
		// hide tracking tab for now
		/* {
			label: "Tracking",
			content: <p>Tracking</p>,
			id: "tracking",
			disabled: true,
		}, */
	];

	const tabItems = useMemo(() => (
		tabs.map((tab, index) => (
			<Tab
				label={tab.label}
				value={index}
				key={tab.id}
				disabled={tab.disabled}
			/>
		)
		)), [tabs]);

	if (!projectId) return null;

	return (
		<div>
			<Stack direction="row" justifyContent="space-between">
				<Tabs value={value} onChange={handleTabChange}>
					{tabItems}
				</Tabs>
				<Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
					<Typography>Questions?</Typography>
					<Link
						href="mailto: samplemanager@metabolon.com"
						variant="caption"
						underline="none"
						color={Blue[500]}
						fontWeight={600}
					>
						Contact us
						<ArrowRight size={16} />
					</Link>
				</Stack>
			</Stack>
			<Divider as="div" />
			{tabs.map((_tab, index) => (
				<Box display={value === index ? "block" : "none"} key={`tab-content-${index}`}>
					{tabs[value]?.content}
				</Box>
			))}
		</div>
	);
};
