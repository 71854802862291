import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import { Stack, Tooltip, Typography, IconButton } from "@mui/material";
import { useClipboard } from '@/lenses/uilenses/hooks/clipboard/clipboard.hook';
import { Blue } from '../../../../theme/colors';

export const CopyToClipboard = ({
	label,
	children,
	width="auto"
}) => {
	const { copy, hasCopied } = useClipboard(children);

	return (
		<Stack>
			<Typography variant="body2">{label}</Typography>
			<Stack direction="row" alignItems="center">
				<Typography borderRadius={1} p={1} bgcolor={Blue[100]} width={width} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">{children}</Typography>
				<Tooltip arrow placement="top" title={hasCopied ? "Copied to clipboard!" : "Copy to clipboard"}>
					<IconButton onClick={copy}><ContentCopyIcon/></IconButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
};