import React, { Component } from 'react';
import './ResetPassword.scss';
import { Link, Mailto, Material, ValidatedForm, ValidatedInput } from '../index.jsx';
import * as config from '../../config/env.config';

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessage: '',
			salesEmailAddress: 'sales@metabolon.com',
			salesHelpSubject: 'Login Troubleshooting Inquiry',
			emailSent: false
		};
	}

	loginResultsCB = (err, authResult) => {
		if (err) {
			this.setState({ errorMessage: err.description });
			return;
		}
	}

	forgotPassword = (data) => {
		this.props.auth.reset(data.email, this.loginResultsCB);
		this.setState({ emailSent: true });
	}

	render() {
		return (
			<div className="outerContainerForgotPW">
				<div className="sectionHeader">Reset your password</div>
				<hr />
				{this.state.emailSent ?
					<div className="info">An email has been sent to your email address. It can take a few minutes before you have it in your inbox. Click on the link to metabolon.auth0.com to reset your password.</div>
					:
					<div>
						<div className="info">Provide the email address that you used when you signed up for your metabolon account. We will send you an email that will allow you to reset your password.</div>

						<ValidatedForm id="loginForm" submitLabel="Send Link" onSubmit={this.forgotPassword} className="inputs">
							<ValidatedInput name="email" type="email" placeholder="Email Address" />
						</ValidatedForm>
					</div>
				}
				<div className="sectionHeader">Trouble with your account?</div>
				<hr />
				<Mailto to={[config.supportEmailAddress]} subject={"Need help to reset password"}>
					<Link history={this.props.history} size="normal" className="getDemo" alt={true} color="primary" >
						Contact Support  <Material icon={'person'} color="primary" />
					</Link>
				</Mailto>
			</div>
		);
	}
}

export default ResetPassword;
