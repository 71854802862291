import React, { useRef, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import Section from "./Section";
import { dataSelector } from "../../../redux/selectors/data.selector";
import getDocStyle from "../../../utils/getDocStyle";

const CirclePackGraph = ({ match }) => {
  const { results } = useSelector(dataSelector);

  const detectedColumns = useRef([
    { key: "metabolite", value: "Metabolite", width: 50, align: "left" },
    {
      key: "p_value",
      value: "P-Value",
      width: 25,
      align: "right",
      display: "pValueDisplay",
    },
    {
      key: "foldChangeSort",
      value: "Fold",
      width: 25,
      align: "right",
      display: "foldChangeDisplay",
      color: true,
      defaultSort: true,
      defaultSortDirection: false,
    },
  ]).current;

  const tableData = useMemo(() => {
    const detected = results.filter((d) => d.detected);

    const detectedUpregulated = detected.filter(
      (d) => d.significant && d.fold_change_category === "Upregulated"
    );

    const detectedDownregulated = detected.filter(
      (d) => d.significant && d.fold_change_category === "Downregulated"
    );

    const detectedInsig = results.filter((d) => d.detected && !d.significant);


    return {
      detected: detected,
      tableDetectedData: [
        {
          label: "Significantly More Abundant",
          data: detectedUpregulated,
          color: getDocStyle("var(--metabolon-red)"),
        },
        {
          label: "Significantly Less Abundant",
          data: detectedDownregulated,
          color: getDocStyle("var(--metabolon-blue)"),
        },
        {
          label: "Nonsignificant",
          data: detectedInsig,
          color: getDocStyle("var(--cold-gray-darkest)"),
        },
      ],
    };
  }, [results]);

  return (
      <Section
        label={"Detected Metabolites"}
        circlePackData={tableData.detected}
        tableData={tableData.tableDetectedData}
        tableColumns={detectedColumns}
        proportion={1}
        key="detected"
        showLegend={true}
        showDownloadSvg={true}
        match={match}
      />
     
  );
};

export default memo(CirclePackGraph);
