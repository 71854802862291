import { createSelector } from "@reduxjs/toolkit";

export const discoveryPanelsSelector = createSelector(
  state => state,
  state => {
    if("discoveryPanels" in state) return state.discoveryPanels;
    return state;
  }
);

export const productTypeSelector = createSelector(
  discoveryPanelsSelector,
  state => state.productType,
  state => state.overview
);