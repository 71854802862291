'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './Radio.scss';
import Icon from './Icon.jsx';
import {Label} from '../index.jsx';

/**
 * A Radio component for use in forms
 */
const Radio = (props) => {

	let {labelColor, color, size, styles, disabled, className,
		name, onChange, checked, id, label, value, ...iProps} = props;
	 
	return (
		<div className={`Radio check-radio ${className}`}>
			<input type="radio" onClick={function(){ onChange(value);}} {...iProps} id={id}
				name={name} defaultChecked={checked} value={value}/>
			
			<Label htmlFor={id}
				size={size}
				disabled={disabled}
				color={labelColor}>
				<Icon icon={checked ? 'dot-circle-o' : 'circle-o'}
					size={size}
					disabled={disabled}
					color={color}/>
				{label || value}
			</Label>
		</div>
	);
	  
};

Radio.propTypes = {
	/** The color theme of the Radio */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'info', 'inactive', 'white']),

	/** Radio font size */
	size: PropTypes.oneOf(['small', 'normal', 'large']),

	/**  Radio value */
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]),

	/**  Radio id */
	id: PropTypes.string.isRequired,

	/**  CSS display setting */
	display: PropTypes.string,

	/**  Radio name */
	name: PropTypes.string,

	/** Whether the field is checked */
	checked: PropTypes.bool,

	/** Whether the field is required */
	required: PropTypes.bool,

	/** Whether the Radio is disabled */
	disabled: PropTypes.bool,

	/**  Radio label */
	label: PropTypes.string,

	/**  Radio label color */
	labelColor: PropTypes.string,

	/** Gets called when the user clicks on the radio */
	onChange: PropTypes.func,
};

Radio.defaultProps = {
	checked: false,
	color: 'primary',
	disabled: false,
	label: null,
	labelColor: 'tertiary',
	required: false,
	size: 'normal',
};

export default Radio;