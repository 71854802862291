import React from 'react';
import PropTypes from 'prop-types';
import RefreshIndicator from './controls/RefreshIndicator.jsx';
import './Toast.scss';
import { Box } from '@mui/material';

class LoadingIndicator extends React.Component {
	constructor(props) {
		super(props);
	}

	/**Loading Indicators */
	loadingStyle = {
		container: {
			display: 'inline-block',
			position: 'relative',
			textAlign: 'center',
			// paddingTop:'10px', 
			width: 'auto'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
			marginRight: '45px',
			marginLeft: '35px'
		}
	};

	render() {

		return (
			<Box sx={this.loadingStyle.container}>
				<RefreshIndicator
					size={this.props.size}
					status={this.props.status}
					style={this.loadingStyle.refresh}
					color={this.props.color}
				/>
			</Box>
		);
	}
}


LoadingIndicator.propTypes = {
	size: PropTypes.oneOf(['', 'small', 'medium', 'normal', 'large', 'xlarge']),
	status: PropTypes.string,
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),
};

LoadingIndicator.defaultProps = {
	size: 'large',
	status: 'loading',
	color: 'primary'
};

export default LoadingIndicator;