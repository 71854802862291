import { createSelector } from '@reduxjs/toolkit';
import { projectsDataSelector, projectsHasErroredSelector, projectsIsLoadingSelector, projectsDiscoveryPanelsSelector } from './projects.selector';
import { loggedInUserSelector } from './loggedInUser.selector';
import { filesHasErroredSelector, filesIsLoadingSelector, filesDataSelector, filesSelectedFilesIsLoadingSelector } from './files.selector';
import { idleStatusSelector } from './idle.selector';
import { searchResultsSelector, searchResultsIsLoadingSelector, searchResultsHasErroredSelector } from './searchResults.selector';
import { sampleSetDataDataSelector, sampleSetDataIsLoadingSelector, sampleSetDataHasErroredSelector } from './sampleSetData.selector';
import { managedProjectsSelector, managedProjectsHasErrored, managedProjectsIsLoading } from './managedProjects.selector';
import { managedUsersSelector, managedUsersHasErroredSelector, managedUsersIsLoadingSelector } from './managedUsers.selector';
import { availableRolesSelector } from './availableRoles.selector';
import { publishSummaryDataSelector, publishSummaryHasErroredSelector, publishSummaryIsLoadingSelector } from './publishSummary.selector';


export const callbackComponentSelector = createSelector(
    projectsDataSelector,
    projectsHasErroredSelector,
    projectsIsLoadingSelector,
    loggedInUserSelector,
    (projects, hasErrored, isLoading, loggedInUser) => ({ projects, hasErrored, isLoading, loggedInUser })
);


export const projectCardComponentSelector = createSelector(
    filesHasErroredSelector,
    filesIsLoadingSelector,
    filesDataSelector,
    (hasErrored, isLoading, files) => ({ hasErrored, isLoading, files })
);


export const idleTimerComponentSelector = createSelector(
    idleStatusSelector,
    (idleStatus) => ({ idleStatus })
);


export const filesTableComponentSelector = createSelector(
    filesHasErroredSelector,
    filesIsLoadingSelector,
    filesSelectedFilesIsLoadingSelector,
    (hasErrored, isLoading, isSelectedLoading) => ({ hasErrored, isLoading, isSelectedLoading })
);


export const searchContainerComponentSelector = createSelector(
    searchResultsHasErroredSelector,
    searchResultsIsLoadingSelector,
    searchResultsSelector,
    (hasErrored, isLoading, searchResults) => ({ hasErrored, isLoading, searchResults })
);


export const publishingCenterComponentSelector = createSelector(
    projectsHasErroredSelector,
    projectsIsLoadingSelector,
    sampleSetDataDataSelector,
    sampleSetDataHasErroredSelector,
    sampleSetDataIsLoadingSelector,
    loggedInUserSelector,
    (hasErrored, isLoading, sampleSets, sampleSetsHasErrored, sampleSetsIsLoading, loggedInUser) => ({ hasErrored, isLoading, sampleSets, sampleSetsHasErrored, sampleSetsIsLoading, loggedInUser })
);


export const publishingProjectFilesComponentSelector = createSelector(
    filesHasErroredSelector,
    filesIsLoadingSelector,
    filesSelectedFilesIsLoadingSelector,
    filesDataSelector,
    (hasErrored, isLoading, isSelectedLoading, files) => ({ hasErrored, isLoading, isSelectedLoading, files })
);


export const adminSamplesetsComponentSelector = createSelector(
    sampleSetDataHasErroredSelector,
    sampleSetDataIsLoadingSelector,
    sampleSetDataDataSelector,
    (hasErrored, isLoading, adminSampleSetData) => ({ hasErrored, isLoading, adminSampleSetData })
);


export const manageProjectPermissionComponentSelector = createSelector(
    managedProjectsSelector,
    managedProjectsHasErrored,
    managedProjectsIsLoading,
    managedUsersSelector,
    managedUsersHasErroredSelector,
    managedUsersIsLoadingSelector,
    (managedProjects, managedProjectsHasErrored, managedProjectsIsLoading, managedUsers, managedUsersHasErrored, managedUsersIsLoading) => ({
        managedProjects,
        managedProjectsHasErrored,
        managedProjectsIsLoading,
        managedUsers,
        managedUsersHasErrored,
        managedUsersIsLoading
    })
);


export const userManagementContainerComponentSelector = createSelector(
    managedUsersSelector,
    managedUsersHasErroredSelector,
    managedUsersIsLoadingSelector,
    managedProjectsSelector,
    managedProjectsHasErrored,
    managedProjectsIsLoading,
    loggedInUserSelector,
    availableRolesSelector,
    (managedUsers, managedUsersHasErrored, managedUsersIsLoading, managedProjects, managedProjectsHasErrored, managedProjectsIsLoading, loggedInUser, availableRoles) => ({
        managedUsers,
        managedUsersHasErrored,
        managedUsersIsLoading,
        managedProjects,
        managedProjectsHasErrored,
        managedProjectsIsLoading,
        loggedInUser,
        availableRoles
    })
);


export const mainLayoutComponentSelector = createSelector(
    projectsDataSelector,
    projectsDiscoveryPanelsSelector,
    projectsHasErroredSelector,
    projectsIsLoadingSelector,
    (projects, discoveryPanels, hasErrored, isLoading) => ({ projects, discoveryPanels, hasErrored, isLoading })
);


export const projectContainerComponentSelector = createSelector(
    projectsDataSelector,
    projectsHasErroredSelector,
    projectsIsLoadingSelector,
    sampleSetDataDataSelector,
    sampleSetDataHasErroredSelector,
    sampleSetDataIsLoadingSelector,
    publishSummaryDataSelector,
    publishSummaryHasErroredSelector,
    publishSummaryIsLoadingSelector,
    loggedInUserSelector,
    (projects, hasErrored, isLoading, sampleSets, sampleSetHasErrored, sampleSetIsLoading, publishSummary, publishSummaryHasErrored, publishSummaryIsLoading, loggedInUser) => ({
        projects,
        hasErrored,
        isLoading,
        sampleSets,
        sampleSetHasErrored,
        sampleSetIsLoading,
        publishSummary,
        publishSummaryHasErrored,
        publishSummaryIsLoading,
        loggedInUser
    })
);


export const projectFilesComponentSelector = createSelector(
    filesHasErroredSelector,
    filesIsLoadingSelector,
    filesSelectedFilesIsLoadingSelector,
    filesDataSelector,
    (hasErrored, isLoading, isSelectedLoading, files) => ({ hasErrored, isLoading, isSelectedLoading, files })  
);


export const projectResultsComponentSelector = createSelector(
    filesHasErroredSelector,
    filesIsLoadingSelector,
    filesDataSelector,
    (hasErrored, isLoading, files) => ({ hasErrored, isLoading, files })
);


export const securityCheckComponentSelector = createSelector(
    loggedInUserSelector,
    (loggedInUser) => ({ loggedInUser })
);


export const routeAuthorizationComponentSelector = createSelector(
    loggedInUserSelector,
    (loggedInUser) => ({ loggedInUser })
);
