'use strict';

import React, { Component } from 'react';
import { HexColorPicker } from 'react-colorful';

import './ColorPicker.scss';

export default class ColorPicker extends Component {
	static defaultProps = {
		color: '#ff0000',
		debounce: 0,
	};

	state = {
		displayPicker: false,
		color: this.props.color,
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.color !== prevProps.color) {
			this.updateData({ color: this.props.color });
		}
	}

	updateData(data) {
		this.setState({ ...data });
	}

	handleClick = () => {
		this.setState({ displayPicker: !this.state.displayPicker });
	};

	handleClose = () => {
		this.setState({ displayPicker: false });
	};

	handleChange = (color) => {
		this.setState({ color });
		const { debounce } = this.props;

		TweenMax.killDelayedCallsTo(this.debounceChange);
		TweenMax.delayedCall(debounce, this.debounceChange);
	};

	debounceChange = () => {
		TweenMax.killDelayedCallsTo(this.debounceChange);

		let { onChange, keys } = this.props;
		if (onChange) onChange(this.state.color, keys);
	}

	render() {
		const { label } = this.props;
		return (
			<div className="ColorPicker">
				<div className="select">
					<span className="label">{label}</span>
					<div className="swatch" onClick={this.handleClick}>
						<div data-testid="color-picker-button" style={{ background: `${this.state.color}` }} className="color" />
					</div>
				</div>
				{this.state.displayPicker &&
					<div data-testid="color-picker-overlay" className="popover" >
						<div data-testid="color-picker-cover" className="cover" onClick={this.handleClose} />
						<HexColorPicker color={this.state.color} onChange={this.handleChange} />
					</div>}

			</div>
		);
	}
}