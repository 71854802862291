import React, { useRef, useContext } from "react";
import { useSelector } from "react-redux";
import * as styles from "./tooltip.module.scss";
import { dataSelector } from "../../redux/selectors/data.selector";
import { filterSelector } from "../../redux/selectors/filter.selector";
import { tooltipSelector } from "../../redux/selectors/tooltip.selector";
import { delimiter } from "@/discovery-panel/utils/constants";

let tooltipContent, tooltipWidth;

const Tooltip = (props) => {
  const { x, y, showTooltip, value, type } = useSelector(tooltipSelector);
  const { group } = useSelector(filterSelector);
  const { results } = useSelector(dataSelector);
  const ref = useRef(undefined);

  let opacity = showTooltip ? 1 : 0;

  if (props.section && value) {
    if (props.section !== value.split(delimiter)[2]) {
      opacity = 0;
    }
  }

  const tooltipData = value
    ? type === "metabolite"
      ? results.find(
          (d) =>
            d.chemical_id === value.split(delimiter)[0] &&
            d.comparison === value.split(delimiter)[1]
        )
      : type === "group"
      ? results.filter(
          (d) =>
            d[group] === value.split(delimiter)[0] &&
            d.comparison === value.split(delimiter)[1] &&
            d.detected === !!value.split(delimiter)[2]
        )
      : null
    : null;
	
  const tooltipGroup = () => {
    return (
      <div className={styles.wrapper}>
        <h4 className={styles.head}>{value.split(delimiter)[0]}</h4>
        <div className={styles.body}>
          <p>{tooltipData.length} metabolites</p>
        </div>
      </div>
    );
  };

  const tooltipMetabolite = () => {
    return (
      <div className={styles.wrapper}>
        {tooltipData.detected && (
          <div
            className={styles.topBar}
            style={{ backgroundColor: tooltipData.color }}
          />
        )}
        <h4 className={styles.head}>{tooltipData.metabolite}</h4>
        {!tooltipData.detected && (
          <div className={styles.body}>
            <p style={{ fontStyle: "italic" }}>
              This metabolite was not detected in the study.
            </p>
          </div>
        )}
        {tooltipData.detected && (
          <div className={styles.body}>
            <p>
              <span className={styles.label}>Comparison </span>{" "}
              {tooltipData.comparison}
            </p>
            <p>
              <span className={styles.label}>Fold Change: </span>
              {tooltipData.foldChangeDisplay}
            </p>
            <p>
              <span className={styles.label}>P-Value: </span>
              {tooltipData.pValueDisplay}
            </p>
            <p>
              <span className={styles.label}>Main Effect: </span>
              {tooltipData.mainEffect.significant_label || ""}
            </p>
          </div>
        )}
      </div>
    );
  };

  if (tooltipData)
    type === "metabolite"
      ? (tooltipContent = tooltipMetabolite())
      : type === "group"
      ? (tooltipContent = tooltipGroup())
      : (tooltipContent = null);

  if (ref.current) tooltipWidth = ref.current.offsetWidth;

  return (
    <div
      className={styles.tooltip}
      style={{
        transform: "translate(" + (x - tooltipWidth / 2) + "px, " + y + "px)",
        opacity,
      }}
      ref={ref}
    >
      {tooltipContent}
    </div>
  );
};

export default Tooltip;
