import React from 'react';
import { FormControl, Typography, useTheme } from '@mui/material';

export const FormField = ({
	children,
	error,
	hasError = false,
	label
}) => {
	const theme = useTheme();

	return (
		<FormControl sx={{ display: 'flex', width: '100%' }}>
			<Typography>{label}</Typography>
			{children}
			{hasError && <Typography variant="caption" color="error" fontWeight={theme.typography.fontWeightRegular} >{error}</Typography>}
		</FormControl>
	)
}