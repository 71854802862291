'use strict';

import React, { useCallback, useEffect, useState } from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import DropdownContainer from '../../containers/DropdownContainer.jsx';
import Label from './Label.jsx';
import Button from "../buttons/Button.jsx";
import DynamicTable from './DynamicTable.jsx';
import DynamicTableCell from './DynamicTableCell.jsx';
import DynamicTableHelper from './DynamicTableHelper.jsx';

export const MultiSelect = ({ className, activeClass, id, defaultLabel, displayKey, displayName=displayKey, maxSelections, valueKey, rowProps={}, ...props }) => {
  const [selections, setSelections] = useState(props.selections);

  useEffect(() => {
    setSelections(props.selections);
  }, [props.selections]);
  
	const onItemToggle = useCallback((proposedSelections) => {
    if (proposedSelections.length > maxSelections)
      return;
    
		if(props.onItemToggle) 
      props.onItemToggle(proposedSelections);
    
    setSelections(proposedSelections);
	}, [setSelections, props.onItemToggle]);

  const createRowProps = useCallback((option) => {
    const {className, ...rest} = rowProps;
    const rowClass = classnames(className, {
      [activeClass]: selections.includes(option[valueKey])
    });
    return {
      testId: "multiselect-option",
      className: rowClass,
      ...rest
    };
  }, [rowProps, className, activeClass, valueKey, selections]);

  const createTableFields = () => {
    return [
      DynamicTableHelper.createField('---', null, {type:'select'}),
      DynamicTableHelper.createField(displayKey, displayName, {
        renderer: (data, fieldName, n) => {
          return <DynamicTableCell
            className={fieldName}
            tabIndex={n}
            key={n}>
              <span title={title}>{data[fieldName]}</span>
            </DynamicTableCell>;
        }
      })
    ];
  };


	const renderChildren = () => {
		const { options, labelColor } = props;
		let children = [
			(<DynamicTable key={1}
        testId="multiselect-table"
        showHeader={false}
				onItemToggle={onItemToggle}
        primaryKey={valueKey}
				selections={selections}
				dataset={options} 
        rowProps={createRowProps}
        fields={createTableFields()}
         />
			),
		];
		
		let label = selections.length ? `${selections.length} Selected` : defaultLabel;
    if (selections.length === maxSelections) label += ` (Maximum)`;
		children.unshift(<Label title={label} key={0} color={labelColor}>{label}</Label>);
    
		return children;
	};

	const { caret, color, size, children, disabled, alt, alt2, type, title } = props;
  let dropProps = { caret, color, size, children, disabled, alt, alt2, type, title, id };
  return (
    <DropdownContainer {...dropProps} className={classnames('Select', className)}>
      {renderChildren()}
    </DropdownContainer>
  );
};

MultiSelect.propTypes = {

  /** Whether a caret is appended to the dropdown label */
  caret: PropTypes.bool,

  /** The color of the dropdown button */
  color: Button.propTypes.color,

  /** The size of the dropdown button */
  size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

  /** Whether the dropdown button is disabled */
  disabled: PropTypes.bool,

  /** Whether the dropdown button is in alt-color mode */
  alt: PropTypes.bool,

  /** Whether the dropdown button is in alt2-color mode */
  alt2: PropTypes.bool,

  /** type attribute applied to the dropdown button */
  type: PropTypes.string,

  /** option property to display */
  displayKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  /** display label when there is no selection */
  defaultLabel: PropTypes.node,
  /** the current selections*/
  selections: PropTypes.array,
  /** the possible selections */
  data: PropTypes.array,
  /** function to invoke when a row is checked on/off */
  onItemToggle: PropTypes.func,

  /** class to apply to option buttons */
  optionClass: PropTypes.string,

  /** class to apply to option buttons */
  optionColor: Button.propTypes.color,
};

MultiSelect.defaultProps = {
  caret: DropdownContainer.defaultProps.caret,
  size: DropdownContainer.defaultProps.size,
  color: 'secondary',
  disabled: DropdownContainer.defaultProps.disabled,
  alt: DropdownContainer.defaultProps.alt,
  alt2: DropdownContainer.defaultProps.alt2,
  type: DropdownContainer.defaultProps.type,

  selections: [],
  data: [],
  onItemToggle: null,
};