'use strict';
import React, { Component } from 'react';
import {Button, Icon, Label} from '../index.jsx';
import PropTypes from 'prop-types';
import './ListItem.scss';

const ListItem = (props) => {
	const {showIcon, altLabel, data, hoverColor, className} = props;

	let generateClassNames = (base) => {
		let classNames = [];
		classNames.unshift(base);
		if(className) classNames.unshift(className);
		if(hoverColor) classNames.push('hover-'+hoverColor);
		if(!data.shortName) classNames.push('full-name-only');

		return classNames;
	};
	
  return (
    <div className={generateClassNames('ListItem').join(' ')}>
		<Icon icon={showIcon}/> 
		<Label className="Label-0" alt={altLabel}>&nbsp;{data.shortName}&nbsp;</Label> 
		<Label className="Label-1">{data.fullName}</Label>
    </div>
  ); 
};

ListItem.propTypes = {
	/** The color for the List items*/
	color: PropTypes.string,
	/** The size of the list items */
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	/** Show Leading Icon Type */
	showIcon: PropTypes.oneOf(['none','user']),
	/** Show Text Hover Color */
	hoverColor:  PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),
	/** Alternate First Label adds dark background*/
	altLabel: PropTypes.bool,
	/** inline style overrides*/	
	styles: PropTypes.object
};

ListItem.defaultProps = {
	color: 'tertiary',
	size: 'large',
	data: [],
	showIcon: 'none',
	altLabel: false,
	styles: {},
	onClick: event => {
         
		//console.log('List Item Event not defined!', event.target);
    }
};

ListItem.sizes = {
	small: '125px',
	normal: '280px',
	large: '325px',
};

export default ListItem;