import { createAction, createSlice } from "@reduxjs/toolkit";
import { associationsSetter, dynamicAssociationsSetter } from "./associations.reducer";
import { initializeFilter, filterReducer } from "./filter.reducer";
import { metabolitesSetter } from "./metabolites.reducer";
import { pathwaysSetter } from "./pathways.reducer";
import { sampleSetsGetter, sampleSetsSetter } from "./sampleSets.reducer";
import { resultsSetter, dynamicResultsSetter } from "./results.reducer";
import { initializeViewPort, viewPortReducer } from "./viewPort.reducer";
import { initializeExplorer, explorerReducer } from "./explorer.reducer";
import { initializeTooltip, tooltipReducer } from "./tooltip.reducer";
import { initializeDownload, downloadReducer } from "./download.reducer";
import { initializeInteractions, interactionsReducer } from "./interactions.reducer";
import { sampleSetter } from "./sample.reducer";
import { MICROBIOME } from "@/enums/Enums";

export const initialDataState = {
  list: [],
  static: [],
  raw: [],
  isLoaded: false,
  error: null
};

export const getInitialState = () => ({
  metabolites: { ...initialDataState },
  results: { ...initialDataState },
  associations: { ...initialDataState },
  associationsByMetabolite: null,
  pathways: { ...initialDataState },
  sampleSets: { ...initialDataState },
  sample: { ...initialDataState, raw: {
		matrixType: 'MatrixType', projectCode: 'PROJ-XXXX'
	} },
  explorer: initializeExplorer(),
  filter: initializeFilter(MICROBIOME),
  viewPort: initializeViewPort(),
  tooltip: initializeTooltip(),
  download: initializeDownload(),
  interactions: initializeInteractions(),
	productType: null,
	overview: {text:""},
	sampleSetId: null,
});

const discoveryPanelsSlice = createSlice({
  name: "discoveryPanels",
  initialState: getInitialState(),
  reducers: {
    getSampleSets: sampleSetsGetter,
    setSampleSets: sampleSetsSetter,
    setSample: sampleSetter,
    setAssociations: associationsSetter,
    setDynamicAssociations: dynamicAssociationsSetter,
    setMetabolites: metabolitesSetter,
    setPathways: pathwaysSetter,
    setResults: resultsSetter,
    setDynamicResults: dynamicResultsSetter,
		setProductType: (state, action) => {
			state.productType = action.payload;
		},
		setOverview: (state, action) => {
			state.overview = action.payload;
		},
		setSampleSetId: (state, action) => {
			state.sampleSetId = action.payload;
		},
    ...filterReducer(),
    ...viewPortReducer(),
    ...explorerReducer(),
    ...tooltipReducer(),
    ...downloadReducer(),
    ...interactionsReducer()
  }
});

export const {
  getSampleSets,
  setSampleSets,
  setSample,
  setAssociations,
  setDynamicAssociations,
  setMetabolites,
  setPathways,
  setResults,
  setDynamicResults,
  initFilter,
  setActiveTab,
  setFilterAssociations,
  setFilterAssociation,
  setComparison,
  setGroup,
  setInvertedFoldChange,
  setMultiComparisons,
  setPValue,
  setSearch,
  setSearchMatches,
  updateViewPort,
  updateGroupList,
  updateAssociationCoords,
  updateCombinedCoords,
  updateMetaboliteCoords,
  setTooltipX,
  setTooltipY,
  setShowTooltip,
  setTooltipData,
  setTooltipType,
  setTooltipValue,
  downloadStraightenText,
  downloadCurveText,
  downloadSetCurvedText,
  interactionsUpdateSelection,
	setProductType,
	setOverview,
	setSampleSetId,
} = discoveryPanelsSlice.actions;

export const getAssociations = createAction("discoveryPanels/getAssociations");
export const getMetabolites = createAction("discoveryPanels/getMetabolites");
export const getPathways = createAction("discoveryPanels/getPathways");
export const getResults = createAction("discoveryPanels/getResults");
export const getSample = createAction("discoveryPanels/getSample");

export const discoveryPanels = discoveryPanelsSlice.reducer; 