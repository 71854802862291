export default function getCoords(
  index,
  length,
  radius,
  group,
  middleSpaceNormalizer,
  gapNormalizer,
  settings
) {
  let coords = {};
  const g = group || 0;
  const gapOffset =  (g * Math.PI) / 180; //changes  left side of the arc

  const angle =
    (((2 * Math.PI) / length) * index - Math.PI / middleSpaceNormalizer) /
      settings.middleSpace +
    settings.rotateOffset +
    gapOffset -
    gapNormalizer;

  const x =
    (radius / settings.radiusOffset) * Math.cos(angle + settings.rotateOffset);
  const y =
    (radius / settings.radiusOffset) * Math.sin(angle + settings.rotateOffset);

  coords.x = x;
  coords.y = y;
  coords.angle = angle + settings.angleOffset;
  coords.gap = gapOffset;

  return coords;
}
