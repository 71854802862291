import { MICROBIOME } from '@/enums/Enums';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchMetabolites } from '../../api';
import { 
  getMetabolites, 
  setMetabolites
} from '../reducers/discoveryPanels.reducer';

export function* metabolitesSaga(action) {
	const { productType=MICROBIOME } = action?.payload || {};
	
	const payload = {
    list: [],
    static: [],
    raw: [],
    isLoaded: false,
    error: null
  };  

  try {
    const data = yield call(fetchMetabolites, productType);
    payload.raw = data;
  } catch (e) {
    payload.error = e.message;
  } finally {
    payload.isLoaded = true;
  }

  yield put(setMetabolites(payload));
}

export function* metabolitesSagas() {
  yield takeLatest(getMetabolites, metabolitesSaga);
}