import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import LinePath from "./LinePath";
import createLineData from "../../utils/createLineData";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";
import { interactionsSelector } from "../../redux/selectors/interactions.selector";

const Lines = () => {
  const selectedState = useSelector(interactionsSelector);
  const { metaboliteCoords, associationCoords, combinedCoords } =
  useSelector(explorerSelector);
  const viewPort = useSelector(viewPortSelector);

  const metabolitesWithLines = useMemo(() => {
    return metaboliteCoords
      .filter((d) => {
        return selectedState.id === d.chemical_id;
      })
      .map((d) => {
        return {
          ...d,
          lines: createLineData(
            combinedCoords,
            d.chemical_id,
            "metabolite",
            viewPort
          ),
        };
      });
  }, [combinedCoords, metaboliteCoords, selectedState.id, viewPort]);

  const associationsWithLines = useMemo(() => {
    return associationCoords
      .filter((d) => {
        return selectedState.id === d.association;
      })
      .map((d) => {
        return {
          ...d,
          lines: createLineData(
            combinedCoords,
            d.association,
            "association",
            viewPort
          ),
        };
      });
  }, [associationCoords, combinedCoords, selectedState.id, viewPort]);

  return (
    <g>
      {selectedState.type === "metabolite" &&
        metabolitesWithLines.map((d, i) => {
          return (
            d.lines.length > 0 &&
            d.lines.map((e, i) => {
              return (
                <LinePath
                  key={"linemetabolite" + e.chemical_id + e.association}
                  path={e.path}
                />
              );
            })
          );
        })}
      {selectedState.type === "association" &&
        associationsWithLines.map((d, i) => {
          return (
            d.lines.length > 0 &&
            d.lines.map((e, i) => {
              return (
                <LinePath
                  key={"lineassociation" + e.chemical_id + e.association}
                  path={e.path}
                />
              );
            })
          );
        })}
    </g>
  );
};

export default memo(Lines);
