'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Material}  from '../index.jsx';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';

export class ValidatedForm extends Component {

	constructor(props) {
		super(props);
		this.refs = {};
		this.tempState = {};

		this.state = {
			data: null,
			flags: {},
			invalid: true,
			validatorCount: 0
		};
	}

	componentWillMount() {
		this.setState({
			data: this.tempState.data,
			validatorCount: this.tempState.validatorCount,
		});
	}

	onSubmit = (e) => {
		this.props.onSubmit(this.state.data);
	};
	
	enterPressed = (e) => {
		const code = e.keyCode || e.which;
		if(code === 13) {
			this.onSubmit();
		} 
	}
	
	onInputChange = (e) => {
		const {name, value, isChecked} = e.target;
		let data = Object.assign({}, this.state.data);
		data[name] = value;
		
		if(isChecked !== undefined && !isChecked) delete data[name];

		this.setState({data}, function() {
			this.props.onChange(this.state.data);
		});
	};

	setRef = ref => {
		if(!ref) return;
		let {name} = ref.props;
		let refs = Object.assign({}, this.refs);
		refs[name] = ref;
		this.refs = refs;
	};

	onChildUnmount = name => {
		let refs = Object.assign({}, this.refs);
		delete refs[name];
		this.refs = refs;
		this.validate();
	};

	onValidate = (name, value, valid) => {
		let data = Object.assign({}, this.state.data);
		let flags = Object.assign({}, this.state.flags);

		if(!valid) delete flags[name];
		else flags[name] = true;
		data[name] = value;
		let invalid = Object.keys(flags).length !== Object.keys(data).length;//Object.keys(flags).length !== Object.keys(refs).length;
		this.setState({invalid, flags, data});
	};

	validate() {
		let data = Object.assign({}, this.state.data);
		let flags = Object.assign({}, this.state.flags);

		for(let name in data) {
			if(name in this.refs)
				data[name] = this.refs[name].validate(false, false);
			else {
				delete data[name];
				delete flags[name];
			}
		}

		let invalid = Object.keys(flags).length !== Object.keys(data).length;//Object.keys(flags).length !== Object.keys(refs).length;
		this.setState({invalid, flags, data});
	}

	renderChildren() {
		let data = {}, validatorCount = 0, formnovalidate = this.props.noValidate;
		let children = React.Children.map(this.props.children, (child, index) => {
			if(!child) return child;
			let type = child.type;
			if(typeof type === 'function') {
				if('displayName' in type) type = type.displayName;
				else type = type.name;
			}
			if(ValidatedForm.inputTypes.indexOf(type) >= 0) {
				data[child.props.name] = '';
				validatorCount++;

				return React.cloneElement(child, {
					ref:this.setRef,
					onUnmount:this.onChildUnmount,
					onValidate:this.onValidate,
					noValidate: formnovalidate ? formnovalidate : child.props.noValidate,
				});
			}
			return child;
		});

		if(!this.tempState.data || this.tempState.validatorCount != validatorCount) {
			this.tempState.data  = data;
			this.tempState.validatorCount = validatorCount;
		}

		return children;
	}

	loadingStyle = {
		container: {
			position: 'relative',
			textAlign:'center',
			paddingTop:'10px'
		},
		refresh: {
			display: 'inline-block',
			position: 'relative',
		},
		};
	
	RefreshIndicatorExampleLoading = () => (
	<div style={this.loadingStyle.container}>
		<LoadingSpinner />
	</div>
	);
	

	render() {
		const {id, noValidate, className="SimpleForm", submitLabel} = this.props;
		let invalid = noValidate ? false : this.state.invalid;
		const buttonIcon = this.props.buttonIcon != '' ? <Material icon={this.props.buttonIcon} color={this.props.buttonIconColor}/> : '';
		return (
			<form id={id} className={className}  onKeyPress={this.enterPressed}>
				{this.renderChildren()}

				{this.props.isLoading?
				<div className="loading">{this.RefreshIndicatorExampleLoading()}</div>
				:
				<Button color={this.props.buttonColor} onClick={this.onSubmit} disabled={invalid}>
					{submitLabel}
					{buttonIcon}
				</Button>
				}
			</form>
		);
	}
}

ValidatedForm.inputTypes = [
	'ValidatedInput'
];

ValidatedForm.propTypes = {
	/** The id assigned to the form element*/
	id: PropTypes.string.isRequired,

	/** The submit button label*/
	submitLabel: PropTypes.string,

	/** Function to invoke when the form is submitted */
	onSubmit: PropTypes.func,

	/** Function to invoke whenever an input in the form changes */
	onChange: PropTypes.func,

	/* When true the form is not validated */
	noValidate: PropTypes.bool,

	/* Flip submit button to loading indicator */
	isLoading: PropTypes.bool
};

ValidatedForm.defaultProps = {
	noValidate: false,
	submitLabel: "Submit",
	buttonColor: "primary",
	buttonIcon: '',
	buttonIconColor: 'white',
	isLoading: false,
	 
	onSubmit: (data) => {
		/*console.log('SimpleForm custom onSubmit callback not defined!', data);*/
	},
	onChange: data => {
		/*console.log('SimpleForm custom onChange callback not defined!', data.constructor.name);*/
	},
	 
};

export default ValidatedForm;