import React from "react";

const Footer = () => {
  return (
    <footer>
      <div>
        <p>For research use only. Not for use in diagnostic procedures.</p>
      </div>
    </footer>
  );
};
export default Footer;
