'use strict';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SearchBox } from '../components/index.jsx';
import * as config from '../config/env.config';
import { searchResultsFetchData } from '../redux/actions/searchResults';
import { managedProjectsFetchData } from '../redux/actions/managedProjects';
import { filesFetchData } from '../redux/actions/files';
import { managedUsersFetchData, fetchUserBydetails } from '../redux/actions/managedUsers';
import * as actionTypes from '../redux/actions/actionTypes';
import { hashString } from '../utils/StringUtils';
import { scrubHTML } from '../utils/JsxUtils';
import { Log, useLogger } from '../utils/Logger';
import { AppInsightLogLevel } from '../enums/Enums';
import { searchResultsSelector } from '../redux/selectors/searchResults.selector';
import urljoin from 'url-join';

export const SearchContainer = (props) => {
	const logger = useLogger();
	const timeoutRef = useRef(null);
	const [mapData, setMapData] = useState({});
	const [searchModel, setSearchModel] = useState({});
	const [searchText, setSearchText] = useState('');
	const [searchData, setSearchData] = useState([]);
	const { data } = useSelector(searchResultsSelector);
	const dispatch = useDispatch();

	const fetchSearchData = (url) => dispatch(searchResultsFetchData(url));
	const fetchResultsPageData = (url) => dispatch(filesFetchData(url));
	const fetchUserData = (query) => dispatch(managedUsersFetchData(query));
	const fetchUserByDetails = (query) => dispatch(fetchUserBydetails(query));
	const fetchManagedProjectsData = (query) => dispatch(managedProjectsFetchData(query));

	const logSearchMetric = (query) => {
		const logViewChange = new Log();
		logViewChange.SetLevel(AppInsightLogLevel.EVENT);
		logViewChange.SetName('Global_Search_Query');
		logViewChange.AddProperty('Query', hashString(query));
		logger.doLog(logViewChange);
	};

	const initSearchModel = useCallback((dataModel) => {
		switch (dataModel) {
			case 'projects':
				setMapData({
					primaryText: 'title',
					secondaryText: 'detail',
					value: 'id'
				});
				setSearchModel({
					apiEndpoint: config.API_ENDPOINT_PROJECT,
					actionTypeSuccess: actionTypes.SEARCHRESULTS_FETCH_DATA_SUCCESS,
					pageDetailRoute: 'project'
				});
				break;

			case 'managedUsers':
				setMapData({
					primaryText: 'email',
					secondaryText: 'username',
					value: 'userId'
				});
				setSearchModel({
					apiEndpoint: config.API_ENDPOINT_USERS,
					actionTypeSuccess: actionTypes.MANAGEDUSERS_FETCH_DATA_SUCCESS,
					pageDetailRoute: 'usermanagement'
				});
				break;

			case 'results':
				setMapData({
					primaryText: 'projectCode',
					secondaryText: 'description',
					value: 'projectId'
				});
				setSearchModel({
					apiEndpoint: config.API_ENDPOINT_FILES,
					actionTypeSuccess: actionTypes.FILES_FETCH_DATA_SUCCESS,
					pageDetailRoute: 'project'
				});
				break;
		}
	}, []);
	
	useEffect(() => {
		if (!props.dataModel) return;
		initSearchModel(props.dataModel);
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [props.dataModel]);

	const getProjectsData = useCallback(async (text) => {
		const apiFilterCall = urljoin(config.API_ENDPOINT_GLOBAL_SEARCH, text.toUpperCase());
		logSearchMetric(text);

		try {
			const { type, response } = await fetchSearchData(apiFilterCall);
			if (type === searchModel.actionTypeSuccess) {
				const projectResults = response
					.filter((res) => res.type === 'Project')
					.map((res) => ({
						...res,
						detail: scrubHTML(res.detail)
					}));
				setSearchData(projectResults);
			}
		} catch (err) { 
			console.error(err);
		}
	}, [data, searchModel]);

	const getManagedProjectsData = (text) => { };

	const getUsersData = async (text) => {
		logSearchMetric(text);

		try {
			const result = await fetchUserByDetails(text.toUpperCase());

			if (result.type == searchModel.actionTypeSuccess) {
				setSearchData(data);
			}
		} catch (err) { 
			console.error(err);
			throw err;
		}
	};

	const getResultsPageData = useCallback(async (text) => {
		let apiFilterCall = `${props.objectId}/search/${text}`;

		if (text.length == 0) {
			apiFilterCall = `${props.objectId}`;
		}

		try {
			const result = await fetchResultsPageData(apiFilterCall);

			if (result.type == searchModel.actionTypeSuccess) {
				setSearchData(data);
			}
		} catch (err) { 
			console.error(err);
		}
	}, [props.objectId]);

	const searchAPIForData = useCallback(async (text) => {
		setSearchText(text);

		if (text.length < props.minSearchChars && props.showResults) {
			setSearchData([]);
			return;
		}

		switch (props.dataModel) {
			case 'projects':
				await getProjectsData(text);
				break;

			case 'managedUsers':
				getUsersData(text);
				break;

			case 'managedProjects':
				getManagedProjectsData(text);
				break;

			case 'results':
				getResultsPageData(text);
				break;
		}
	}, [props.dataModel, getProjectsData, props.showResults, props.minSearchChars]);

	const goToDetailPage = (id) => {
		const pageRoute = `/${searchModel.pageDetailRoute}/${id}`;

		if (props.history) {
			props.history.push(pageRoute);
		}
	};

	const handleSearchText = useCallback((text) => {
		if (props.onChange) props.onChange(text);
		searchAPIForData(text);
	}, [props.onChange, searchAPIForData]);

	const handleSelectedSearchItem = (selectedItem) => {
		goToDetailPage(selectedItem.projectCode);
	};

	const keyUpHandler = useCallback((e) => {
		clearTimeout(timeoutRef.current);

		let text = '';

		if (e.target) {
			text = e.target.value;
		}

		if (props.SearchDelay) {
			timeoutRef.current = setTimeout(() => {
				handleSearchText(text);
			}, 800);
		} else {
			handleSearchText(text);
		}
	}, [handleSearchText]);

	const handleCancel = useCallback(() => {
		if (props.handleCancel) {
			props.handleCancel();
		}
	}, []);
	
	return (
		<SearchBox
			handleCancel={handleCancel}
			onKeyUp={keyUpHandler}
			{...props}
			hintText={props.hintText}
			showResults={props.showResults}
			mapData={mapData}
			itemClick={props.itemClick}
			dataSource={searchData}
		/>
	);
};

SearchContainer.propTypes = {
	/**Data Model to Search */
	dataModel: PropTypes.oneOf(['projects', 'files', 'results', 'managedUsers', 'managedProjects']).isRequired,
	/** Least amount of charcters before search */
	minSearchChars: PropTypes.number,
	/**Hide Results dropdown */
	hideResults: PropTypes.bool,
	/**Function to handle text change in dropdown, outside of the api call */
	onChange: PropTypes.func,
	/**Function to handle search results click */
	itemClick: PropTypes.func,
	/** Show Search results in dropdown */
	showResults: PropTypes.bool,
	/** Search hint text */
	hintText: PropTypes.string,
	/**Delay Search */
	SearchDelay: PropTypes.bool
};

SearchContainer.defaultProps = {
	minSearchChars: 3,
	hideResults: false,
	 
	onChange: (item) => { },
	 
	itemClick: (item) => { },
	showResults: true,
	hintText: 'Search',
	SearchDelay: false
};


export default SearchContainer;
