import { createSelector } from '@reduxjs/toolkit';


export const loggedInUserSelector = createSelector(
    (state) => state,
    (state) => state.loggedInUser
);


export const loggedInUserIsLoadingSelector = createSelector(
    loggedInUserSelector,
    (loggedInUser) => loggedInUser.loggedInUserIsLoading  
);


export const loggedInUserHasErroredSelector = createSelector(
    loggedInUserSelector,
    (loggedInUser) => loggedInUser.loggedInUserHasErrored
);
