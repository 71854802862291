import { createSelector } from '@reduxjs/toolkit';


export const managedProjectsSelector = createSelector(
    (state) => state,
    (state) => state.managedProjects
);


export const managedProjectsIsLoading = createSelector(
    managedProjectsSelector,
    (managedProjects) => managedProjects.managedProjectsIsLoading
);


export const managedProjectsHasErrored = createSelector(
    managedProjectsSelector,
    (managedProjects) => managedProjects.managedProjectsHasErrored
);
