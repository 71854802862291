import { createSelector } from '@reduxjs/toolkit';


export const sampleSetDataSelector = createSelector(
    (state) => state,
    (state) => state.sampleSetData
);

export const sampleSetDataIsLoadingSelector = createSelector(
    sampleSetDataSelector,
    (sampleSetData) => sampleSetData.sampleSetDataIsLoading
);

export const sampleSetDataHasErroredSelector = createSelector(
    sampleSetDataSelector,
    (sampleSetData) => sampleSetData.sampleSetDataHasErrored
);

export const sampleSetDataDataSelector = createSelector(
    sampleSetDataSelector,
    (sampleSetData) => sampleSetData.data
);

export const sampleSetDataCurrentSelector = createSelector(
    sampleSetDataSelector,
    (sampleSetData) => sampleSetData.current
);