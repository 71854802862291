'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Theme from '../util/Theme.jsx';
import Label from './Label.jsx';
import './Input.scss';

/**
 * A Input component for use in forms
 */
class Input extends Component {

	constructor(props) {
		super(props);
	}
	
	generateClassNames(base=null) {
		const { alt, textColor, color, size, disabled, required, className } = this.props;
		let classNames = [size];
		if(base) classNames.unshift(base);
		if(className) classNames.unshift(className);
		if(alt) classNames.unshift('alt');
		if(color) classNames.push('border-'+color);
		if(textColor) classNames.push(textColor);
		if(disabled) classNames.push('disabled');
		if(required) classNames.push('required');

		return classNames;
	}

	getWrapperClass() {
		const {wrapperClass} = this.props;
		let classes = ["Input"];
		if(wrapperClass) classes.push(wrapperClass);

		return classes.join(' ');
	}

	renderInput() {

	}
	render() {
		const {size, disabled, required, alt, alt2, textColor, color, className, children, label, labelClass, htmlSize, value, name, placeholder, type, inputRef, renderWrapper, wrapperClass, ...inputProps } = this.props;

		const labelProps = {size, disabled, name, required, className:labelClass};

		const input = (
			<>
				{label && 
					<Label {...labelProps}>{label}</Label>
				}

				<input className={this.generateClassNames().join(' ')}
					ref={inputRef}
					size={htmlSize}
					type={type}
					name={name}
					placeholder={placeholder}
					{...inputProps} value={value}/>
					
				{children}
			</>
		);
		if(renderWrapper) {
			return (
				<div className={this.getWrapperClass()}>
					{input}
				</div>
			);
		}
		return input;
	}
}

Input.propTypes = {
	/** The border color of the input */
	color: PropTypes.oneOf(['primary', 'secondary', 'selectedItem', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The text color of the input */
	textColor: PropTypes.oneOf(['primary', 'secondary', 'selectedItem', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** Input font size */
	size: PropTypes.oneOf(['small', 'normal', 'large']),

	/** Input size attribute */
	htmlSize: PropTypes.string,

	/**  Input label */
	label: PropTypes.string,

	/**  Function to invoke when then input changes */
	onChange: PropTypes.func,

	/**  Function to assign the input DOM reference */
	inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

	/** Alternate style flag */
	alt: PropTypes.bool,

	/**  Input name */
	name: PropTypes.string,

	/**  Input placeholder text */
	placeholder: PropTypes.string,

	/** Whether the field is required */
	required: PropTypes.bool,

	/** Whether the input is disabled */
	disabled: PropTypes.bool,

	renderWrapper: PropTypes.bool,

	/**input type */
	type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'tel', 'url'])
};

Input.defaultProps = {
	color: 'secondary',
	textColor: 'tertiary',
	type: 'text',
	size: 'normal',
	disabled: false,
	required: false,
	alt: false,
	renderWrapper: true,
};

export default Input;