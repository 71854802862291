import React, { useMemo, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { delimiter } from "@/discovery-panel/utils/constants";
import { MICROBIOME, BIOSTRESS, LIVERFIBROSIS, KIDNEYFUNCTION } from "@/enums/Enums";

import Table from "../../table/Table";
import * as styles from "./pathwayprofile.module.scss";
import { dataSelector } from "../../../redux/selectors/data.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import { group } from "d3";
import mapToArray from "../../../utils/mapToArray";

import Benzoate_Metabolism from "../../../static/assets/images/profile/Microbiome/Benzoate_Metabolism.svg";
import BAM from "../../../static/assets/images/profile/Microbiome/Bile_Acid_Metabolism.svg";
import Carbohydrate_Metabolism from "../../../static/assets/images/profile/Microbiome/Carbohydrate_Metabolism.svg";
import Choline_and_Carnitine_Metabolism from "../../../static/assets/images/profile/Microbiome/Choline_and_Carnitine_Metabolism.svg";
import Histidine_Metabolism from "../../../static/assets/images/profile/Microbiome/Histidine_Metabolism.svg";
import Milk_Sugar_Metabolism from "../../../static/assets/images/profile/Microbiome/Milk_Sugar_Metabolism.svg";
import Nitrogen_Metabolism from "../../../static/assets/images/profile/Microbiome/Nitrogen_Metabolism.svg";
import Non_Aromatic_Amino_Acids_Peptides_and_Neurotransmitters from "../../../static/assets/images/profile/Microbiome/Non_Aromatic_Amino_Acids_Peptides_and_Neurotransmitters.svg";
import Phenylalanine_and_Tyrosine_Metabolism from "../../../static/assets/images/profile/Microbiome/Phenylalanine_and_Tyrosine_Metabolism.svg";
import Phytoestrogens_and_Urolithins from "../../../static/assets/images/profile/Microbiome/Phytoestrogens_and_Urolithins.svg";
import Sulfur_Metabolism from "../../../static/assets/images/profile/Microbiome/Sulfur_Metabolism.svg";
import Tryptophan_Metabolism from "../../../static/assets/images/profile/Microbiome/Tryptophan_Metabolism.svg";
import Vitamin_B_Metabolism from "../../../static/assets/images/profile/Microbiome/Vitamin_B_Metabolism.svg";

import inflammation_and_immunity from "../../../static/assets/images/profile/Biologicalstress/Inflammation_and_Immunity.svg";
import injury_and_circulation from "../../../static/assets/images/profile/Biologicalstress/Injury_and_Circulation.svg";
import metabolism_and_glycemic_stress from "../../../static/assets/images/profile/Biologicalstress/Metabolism_and_Glycemic_Stress.svg";
import oxidative_stress from "../../../static/assets/images/profile/Biologicalstress/Oxidative_Stress.svg";
import protein_catabolism_and_decay from "../../../static/assets/images/profile/Biologicalstress/Protein_Catabolism_and_Decay.svg";
import stress_signaling_molecule from "../../../static/assets/images/profile/Biologicalstress/Stress_Signaling_Mediators.svg";
import vitamins from "../../../static/assets/images/profile/Biologicalstress/vitamins.svg";

import Bile_Acid_Metabolism from "../../../static/assets/images/profile/Liverfibrosis/Bile_Acid_Metabolism.svg";
import Ceramide_Metabolism from "../../../static/assets/images/profile/Liverfibrosis/Ceramide_Metabolism.svg";
import ECM_Remodeling from "../../../static/assets/images/profile/Liverfibrosis/ECM_Remodeling.svg";
import Energetics from "../../../static/assets/images/profile/Liverfibrosis/Energetics.svg";
import Inflammation from "../../../static/assets/images/profile/Liverfibrosis/Inflammation.svg";
import Lipid_Metabolism from "../../../static/assets/images/profile/Liverfibrosis/Lipid_Metabolism.svg";
import Oxidative_Stress from "../../../static/assets/images/profile/Liverfibrosis/Oxidative_Stress.svg";

import Amino_Acids_and_Derivatives from "../../../static/assets/images/profile/Kidneyfunction/Amino_Acids_and_Derivatives.svg";
import CM from "../../../static/assets/images/profile/Kidneyfunction/Carbohydrate_Metabolism.svg";
import LM from "../../../static/assets/images/profile/Kidneyfunction/Lipid_Metabolism.svg";
import Microbial_Contributed from "../../../static/assets/images/profile/Kidneyfunction/Microbial_Contributed.svg";
import Nucleotide_Metabolism from "../../../static/assets/images/profile/Kidneyfunction/Nucleotide_Metabolism.svg";
import Protein_Catabolism_and_Decay from "../../../static/assets/images/profile/Kidneyfunction/Protein_Catabolism_and_Decay.svg";
import Nicotinamide_Metabolism from "../../../static/assets/images/profile/Kidneyfunction/Nicotinamide_Metabolism.svg";


import { discoveryPanelsSelector } from "../../../redux/selectors/discoveryPanels.selector";

const removeFileSuffix = (fileName) => {
  const keyParts = fileName.split('.');
  const lastPart = keyParts[keyParts.length - 1];
  if (keyParts.length > 1 && lastPart.length > 1 && lastPart.length <= 4) {
     //Sometimes we get dashes in the filename, replace with underscore
     return fileName.slice(0, -(lastPart.length + 1)).replace(/-/g, delimiter);
    }
  
  return fileName;
};

const images = {
  [MICROBIOME]: {
    Benzoate_Metabolism,
    Bile_Acid_Metabolism: BAM,
    Carbohydrate_Metabolism,
    Choline_and_Carnitine_Metabolism,
    Histidine_Metabolism,
    Milk_Sugar_Metabolism,
    Nitrogen_Metabolism,
    Non_Aromatic_Amino_Acids_Peptides_and_Neurotransmitters,
    Phenylalanine_and_Tyrosine_Metabolism,
    Phytoestrogens_and_Urolithins,
    Sulfur_Metabolism,
    Tryptophan_Metabolism,
    Vitamin_B_Metabolism
  },
  [BIOSTRESS]: {
    inflammation_and_immunity,
    protein_catabolism_and_decay,
    oxidative_stress,
    injury_and_circulation,
    stress_signaling_molecule,
    vitamins,
    metabolism_and_glycemic_stress
  },
  [LIVERFIBROSIS]: {
    bile_acid_metabolism: Bile_Acid_Metabolism,
    ceramide_metabolism: Ceramide_Metabolism,
    ecm_remodeling: ECM_Remodeling,
    energetics_: Energetics,
    inflammation: Inflammation,
    lipid_metabolism: Lipid_Metabolism,
    oxidative_stress_: Oxidative_Stress
  },
  [KIDNEYFUNCTION]: {
    amino_acids_and_derivatives: Amino_Acids_and_Derivatives,
    carbohydrate_metabolism: CM,
    lipid_metabolism: LM,
    microbial_contributed: Microbial_Contributed,
    nucleotide_metabolism: Nucleotide_Metabolism,
    protein_catabolism_and_decay: Protein_Catabolism_and_Decay,
    nicotinamide_metabolism: Nicotinamide_Metabolism
  }
};

const columns = [
  {
    key: "metabolite",
    value: "Metabolite",
    width: 40,
    align: "left",
    click: "chemical_id",
  },
  {
    key: "metabolite_significant",
    value: "Total Significant",
    width: 20,
    align: "right",
  },
  {
    key: "metabolite_upregulated",
    value: "Sig. Up",
    width: 20,
    align: "right",
  },
  {
    key: "metabolite_downregulated",
    value: "Sig. Down",
    width: 20,
    align: "right",
  },
];

const PathwayProfile = () => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const { pathways, results } = useSelector(dataSelector);
	const { productType } = useSelector(discoveryPanelsSelector);
  const selectedState = useSelector(interactionsSelector);

  const pathwayInfo = useMemo(() => {
    return pathways.filter((d) => d.pathway_name === selectedState.id)[0];
  }, [selectedState.id]);

  const tableData = useMemo(() => {
    const m = mapToArray(
      group(
        results.filter(
          (d) => d.metaboliteInfo.pathway_name === selectedState.id
        ),
        (d) => d.chemical_id
      )
    ).map((d) => {
      return {
        metabolite: d.value[0].metabolite,
        chemical_id: d.value[0].chemical_id,
        metabolite_significant: d.value[0].metabolite_significant,
        metabolite_upregulated: d.value[0].metabolite_upregulated,
        metabolite_downregulated: d.value[0].metabolite_downregulated,
      };
    });
    return [
      {
        label: "Metabolites",
        data: m,
      },
    ];
  }, [results, selectedState.id]);


  const flowChartPath = useMemo(
    () => images[productType][removeFileSuffix(pathwayInfo.pathway_map)],
    [ pathwayInfo.pathway_map ]
  );
  return (
    <div className={styles.pathwayProfile + " fade-in"}>
      <div className={styles.copy}>
        <h2>{pathwayInfo.pathway_name}</h2>
      </div>
      <div className={styles.topSection}>
        <div className={styles.infoWrapper}>
          <h3>Pathway Map </h3>
          <div className={styles.flowChart}>
            <img src={flowChartPath} alt="pathway-profile-flow-chart" />
          </div>
        </div>
        <div className={styles.table}>
          <h3>Metabolites</h3>
          <Table
            tableData={tableData}
            defaultSort={"metabolite_significant"}
            columns={columns}
          />
        </div>
      </div>

      <div className={styles.middleSection}>
        <div className={styles.infoWrapper}>
          <h3>Metabolism</h3>
          { }
          <div className={styles.infoGroup} dangerouslySetInnerHTML={{ __html: pathwayInfo.metabolism }}/>
        </div>
       {productType === MICROBIOME && <div className={styles.infoWrapper}>
          <h3>Impact on Host</h3>
          { }
          <div className={styles.infoGroup} dangerouslySetInnerHTML={{ __html: pathwayInfo.host_health }}/>
        </div>}
      </div>
    </div>
  );
};

export default PathwayProfile;
