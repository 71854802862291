import { useCallback, useState } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useBrowserInfo } from "@lenses/uilenses/hooks/browserInfo/browserInfo.hook";
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const useAuditLogger = (userDetails, config, enums) => {
  const { userId, userName } = userDetails;
  const browser = useBrowserInfo();
  const [logoutAccessToken, setLogoutAccessToken] = useState(null);

  const generateAuditLogObject = useCallback(
    (logType, clientIP, timestamp, additionalPrimaryData, customMetadata) => {
      let AuditLoginfo = {
        timestamp,
        userId,
        ...additionalPrimaryData,
        metadata: {
          ip: clientIP,
          browser,
          ...customMetadata,
        },
      };

      if (logType == enums.AUDITLOG_LOGTYPES.USER_CREATE) {
        delete AuditLoginfo.timestamp;
        delete AuditLoginfo.metadata;
        AuditLoginfo.userName = userName;
      }

      if (logType == enums.AUDITLOG_LOGTYPES.PROJECT_CREATE) {
        delete AuditLoginfo.timestamp;
        delete AuditLoginfo.userId;
        delete AuditLoginfo.metadata;
      }

      return AuditLoginfo;
    },
    [userId, userName]
  );

  const getEndpoint = (logType) => {
    switch (logType) {
      case enums.AUDITLOG_LOGTYPES.USER_CREATE:
        return config.API_AUDITTRAIL_USER;
      case enums.AUDITLOG_LOGTYPES.USER_ACTION:
        return config.API_AUDITTRAIL_USER_ACTION;
      case enums.AUDITLOG_LOGTYPES.PROJECT_DOWNLOAD:
        return config.API_AUDITTRAIL_PROJECT_DOWNLOAD;
      case enums.AUDITLOG_LOGTYPES.PROJECT_VIEW:
        return config.API_AUDITTRAIL_PROJECT_VIEW;
      case enums.AUDITLOG_LOGTYPES.PROJECT_PERMISSIONS:
        return config.API_AUDITTRAIL_PROJECT_PERMISSIONS;
    }
  };

  const sendAuditLog = useCallback(
    (AuditLogInfo, endpoint) => {
      let token = localStorage.getItem("id_audit") || null;

      if (!token) {
        token = logoutAccessToken;
        setLogoutAccessToken(null);
      }
      let config = null;

      if (token) {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Content-Type-Options": "nosniff",
            "X-XSS-Protection": 1,
          },
          method: "POST",
          body: JSON.stringify(AuditLogInfo),
        };
      } else {
        throw "AuditLogger: No Access Token Found!";
      }
      return fetch(endpoint, config)
        .then((response) =>
          response.json().then((text) => ({ text, response }))
        )
        .then(({ text, response }) => {
          if (!response.ok) {
            return; // Promise.reject(text);
          }
          return Promise.resolve(text);
        })
        .catch((err) => {
          return; //Promise.reject(err.message);
        });
    },
    [logoutAccessToken]
  );

  const setupLog = useCallback(
    (logType, additionalPrimaryData, customMetadata, clientIPAddress) => {
      let endpoint = null;
      let timestamp = dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
      let auditLogInfo = generateAuditLogObject(
        logType,
        clientIPAddress,
        timestamp,
        additionalPrimaryData,
        customMetadata
      );

      endpoint = getEndpoint(logType);

      //need to log this
      if (!endpoint || !auditLogInfo) {
        throw "Endpoint null";
      }
      sendAuditLog(auditLogInfo, endpoint);
    },
    [generateAuditLogObject, getEndpoint, sendAuditLog]
  );

  const doAuditLog = useCallback(
    (logType, additionalPrimaryData = null, customMetadata = null) => {
      fetch(`https://api.ipify.org?format=json`)
        .then((response) => response.json())
        .then((clientIP) => {
          let clientIPAddress = null;
          if (clientIP && clientIP.ip) {
            clientIPAddress = clientIP.ip;
          }

          setupLog(
            logType,
            additionalPrimaryData,
            customMetadata,
            clientIPAddress
          );
          return Promise.resolve();
        })
        .catch((err) => {
          return; // Promise.reject(err.message);
        });
    },
    [setupLog]
  );

  return { doAuditLog, setLogoutAccessToken };
};
