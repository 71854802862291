import React, { memo, useMemo, useContext, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Center from "./Center.jsx";
import Tooltip from "../../controls/Tooltip";
import Lines from "../../svg/Lines";
import Labels from "../../svg/Labels";
import Heatmap from "../../svg/Heatmap";
import Anchors from "../../svg/Anchors";
import Bar from "../../svg/Bar";
import HoverArea from "../../svg/HoverArea";
import { dataSelector } from "../../../redux/selectors/data.selector";
import { interactionsSelector } from "../../../redux/selectors/interactions.selector";
import * as styles from "./heliogramgraph.module.scss";
import { filterSelector } from "../../../redux/selectors/filter.selector";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import { setTooltipX, setTooltipY, setShowTooltip as setShowTooltipAction, setTooltipValue, setTooltipType as setTooltipTypeAction, interactionsUpdateSelection } from "../../../redux/reducers/discoveryPanels.reducer";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";
import { delimiter } from "@/discovery-panel/utils/constants";

const HeliogramContainer = () => {
  const logger = useLocalAuditLogger();
  const dispatch = useDispatch();
  //import necessary context values
  const { associations, metabolites } = useSelector(dataSelector);
  const viewPort = useSelector(viewPortSelector);
  const selectedState = useSelector(interactionsSelector);
  const { association, group } = useSelector(filterSelector);

  const setX = useRef((val) => dispatch(setTooltipX(val))).current;
  const setY = useRef((val) => dispatch(setTooltipY(val))).current;
  const setShowTooltip = useRef((val) => dispatch(setShowTooltipAction(val))).current;
  const setValue = useRef((val) => dispatch(setTooltipValue(val))).current;
  const setTooltipType = useRef((val) => dispatch(setTooltipTypeAction(val))).current;

  //based upon the selected (active) value, create a list of which associations and metabolites are connected and need to be highlighted
  const opacityList = useMemo(() => {
    let list =
      selectedState.id === "default"
        ? ["default"]
        : [selectedState.type, selectedState.id];
    associations
      .filter((d) => d.type === association)
      .forEach((d) => {
        if (
          d.chemical_id === selectedState.id ||
          d.association === selectedState.id
        ) {
          list.push(d.chemical_id);
          list.push(d.association);
        }
      });
    return list;
  }, [association, associations, selectedState.id, selectedState.type]);

  //based upon the selected (active) value, create a list of which groups need to stay in view
  const groupList = useMemo(() => {
    let list = selectedState.id === "default" ? ["default"] : [];
    metabolites
      .filter((d) => opacityList.includes(d.chemical_id))
      .map((d) => list.push(d[group]));
    return list;
  }, [metabolites, opacityList, selectedState.id, group]);

  //handle hovering and clicking over different heliogram pieces, including showing tooltip where appropriate
  const handleEvents = useCallback((e) => {
    const d = e.target.dataset;
    if (e.type === "mouseleave") {
      dispatch(interactionsUpdateSelection({
        id: "default"
      }));
    } else {
      dispatch(interactionsUpdateSelection({
        id: d.value,
        display: d.name,
        type: d.type,
        subtype: null,
        method: e.type === "mouseenter" ? "hover" : "click",
        source: d.source,
        sourceSpecific: null
      }));
      if (e.type === "click")
      logger.logUserAction(`Discovery Panel - Impact Explorer ${d.type} ${d.name} clicked`);
    }

    if (d.source === "heatmap" && e.type === "mouseenter") {
      setX(e.nativeEvent.offsetX);
      setY(e.nativeEvent.offsetY + 16);
      setShowTooltip(true);
      setValue(d.value + delimiter + d.comparison);
      setTooltipType("metabolite");
    } else {
      setShowTooltip(false);
    }
  }, []);

  const figureHeightAdj = viewPort.width < viewPort.height ? 1 : 1.1;

  return (
    <figure
      className={styles.figure}
      style={{
        height: viewPort.height * figureHeightAdj,
        position: "relative",
      }}
    >
      {selectedState.method === "click" && <Tooltip type="metabolite" />}
      <Center />

      <svg viewBox={viewPort.viewBox} id="heliogram-svg" className={styles.svg}>
        {selectedState.id !== "default" && <Lines />}
        <Bar opacityList={opacityList} />
        <Anchors opacityList={opacityList} />
        <Heatmap opacityList={opacityList} handleEvents={handleEvents} />
        <Labels opacityList={opacityList} groupList={groupList} />
        <HoverArea handleEvents={handleEvents} />
      </svg>
    </figure>
  );
};

export default memo(HeliogramContainer);
