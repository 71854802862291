'use strict';
import React, {Component} from 'react';
//import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Theme from '../util/Theme.jsx';
import Material from './Material.jsx';

/** A dismissible componet */
export class DismissibleItem extends Component {
	static getCount() {
		return DismissibleItem.count;
	}

	constructor(props) {
		super(props);
		this.state = {
			id: props.id || 'DismissibleItem-'+DismissibleItem.getCount()
		};
		DismissibleItem.count++;
	}

	getStyles() {
		const clr = Theme.getColor('tertiary');
		return {
			icon: {
				color: Theme.getColor('primary'),
				fontSize: Theme.getSize('large'),
				marginTop:1,
				marginRight:5
			},
			label:{
				color: clr,
				margin:0,
				textAlign: 'left',
				display: 'inline-flex',
				fontSize: Theme.getSize('large')
			},
			close:{
				position:'absolute', cursor:'pointer',
				top: 3, right:0,
				color: clr,
				userSelect: 'none',
				fontSize: Theme.getSize('large')
			}
		};
	}

	onCloseClick = () => {
		 
		//console.log(e, 'close clicked!');
		 
		//invoke function
		if(this.props.onClose) this.props.onClose(this.props.label);
		return false;
	}

	render() {
		const styles = this.getStyles();
		const {icon, label, color, ...props} = this.props;
		return (
			<div className="DismissibleItem" style={{position:'relative'}}>
				<h1 style={styles.label}><Material icon={icon} style={styles.icon}/>{label}</h1>
				<div data-testid="dismissible-trigger" onClick={this.onCloseClick}><Material style={styles.close} icon="close"/></div>
			</div>
		);
	}

}

DismissibleItem.propTypes = {

	/** The material icon to use*/
	icon: PropTypes.string,

	/** The color of the icon */
	//color: PropTypes.oneOf(Theme.colorList),
	color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The body text */
	label: PropTypes.string,

	/** The function to invoke when a file is dropped */
	onClose: PropTypes.func,

};

DismissibleItem.count = 0;

DismissibleItem.defaultProps = {
	color: 'tertiary',
	icon: 'description',
	label: 'Some label text.'
};

export default DismissibleItem;