import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack } from "@mui/material";
import { ManifestResponseModal } from './ManifestResponseModal';
import { ManifestUpload } from '@/views/manifestUpload/ManifestUpload';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent, setManifestUpload } from '@/views/clientOnboarding/redux/reducers/coe.reducer'
import { selectCoe, selectCoeOnboarding } from '@/views/clientOnboarding/redux/selector/coe.selector'
import { useUpdateOnboardingMutation } from '@/redux/services/coe.api';
import { Button } from "metabux/components/Button/Button"
import { useCurrentUserQuery } from '@/redux/services/user.api';

export function ManifestStep({
	projectCode,
	projectId,
}) {
	const dispatch = useDispatch()

	const { current } = useSelector(selectCoe)
	const onboardingState = useSelector(selectCoeOnboarding)
	const [modalOpen, setModalOpen] = useState(false)
	
	const { data: currentUser } = useCurrentUserQuery()
	const [status, setStatus] = useState('not_started')
	const [mutateOnboarding] = useUpdateOnboardingMutation()

	const onNext = useCallback(() => {
		dispatch(setCurrent(current + 1))
	}, [current, dispatch])

	const onPrev = useCallback(() => {
		dispatch(setCurrent(current - 1))
	}, [current, dispatch])

	const onUpload = useCallback(() => {
		dispatch(setManifestUpload(true))
		mutateOnboarding({ projectId, ...onboardingState, manifestUpload: true })
		setModalOpen(true)
	}, [dispatch, onboardingState])

	const onNextUpload = useCallback(() => {
		setStatus('not_started')
		setModalOpen(false)
	}, [])

	return (
		<Box width="100%">
			<ManifestUpload projectId={projectId} projectCode={projectCode} {...{ onUpload, setStatus, status, userEmail: currentUser?.email }} />
			
			<Stack width="100%" direction="row" justifyContent="space-between">
				<Button
					onClick={onPrev}
					color="gray"
					variant="dark"
				>
					Back: Sample Shipping
				</Button>
				<Button
					disabled={!onboardingState.manifestUpload}
					onClick={onNext}
				>
					Next: Tracking &amp; Receipt
				</Button>
			</Stack>

			<ManifestResponseModal
				open={modalOpen}
				onNext={onNext}
				setOpen={setModalOpen}
				onNextUpload={onNextUpload}
			/>
		</Box>
	)
}