import { useCurrentUser } from "@/redux/services/user.api";
import { useEffect, useState } from "react";

export const useUserDetails = () => {
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
	const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser?.data != null) {
      setUserId(currentUser.data.userId);
      if (currentUser.data.email.length > 0) {
        setUserName(currentUser.data.email);
      }
    }
  }, [userId, userName]);

  return { userId, userName };
};
