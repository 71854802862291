'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

/** A simple Button component */
class Button extends React.Component {

	constructor(props) {
		super(props);
		this.state = this.initState();
		this.onClick = this.onClick.bind(this);
	}

	initState() {
		const baseClass = this.constructor.name;
		return { baseClass };
	}

	generateClassNames(base) {
		const { color, size, disabled, alt, alt2, className } = this.props;
		let classNames = [color, size];
		classNames.unshift(base);
		if (className) classNames.unshift(className);
		if (alt) classNames.push('alt');
		if (alt2) classNames.push('alt2');
		if (disabled) classNames.push('disabled');

		return classNames;
	}

	onClick() {
		const { onClick } = this.props;
		/* istanbul ignore else  */
		if (onClick) onClick();
	}

	render() {
		const { children, active, setRef, color, size, disabled, alt, alt2, className, onClick, dispatch, staticContext, testId, href, ...props } = this.props;

		let classes = this.generateClassNames(this.state.baseClass).join(' ');

		return (
			<>
				{href &&
					<a href={href} ref={setRef} data-testid={testId} className={classes} {...props}>{children}</a>}
				{!href &&
					<button ref={setRef} className={classes} onClick={this.onClick} {...props} data-testid={testId}>
						{children}
					</button>}
			</>
		);
	}
}

Button.propTypes = {

	/** The color of the button */
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'success', 'warning', 'danger', 'info', 'inactive', 'off', 'white', 'disabled']),

	/** The size of the button */
	size: PropTypes.oneOf(['inherit', 'small', 'medium', 'normal', 'large']),

	/** Button label */
	//children: PropTypes.node.isRequired,

	/** Whether button is disabled */
	disabled: PropTypes.bool,

	/** Whether button is in alt-color mode */
	alt: PropTypes.bool,

	/** Whether button is in alt2-color mode */
	alt2: PropTypes.bool,

	/** type attribute applied to the button element */
	type: PropTypes.string,

	/** Gets called when the user clicks on the button */
	onClick: PropTypes.func,
};

Button.defaultProps = {
	size: 'normal',
	color: 'primary',
	disabled: false,
	alt: false,
	alt2: false,
	type: 'button',
	 
	onClick: /* istanbul ignore next */ () => { console.log('Button custom onClick callback not defined!'); },
	 
};

export default Button;