import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import PathwayProfile from "../components/containers/profile/PathwayProfile";
import MetaboliteProfile from "../components/containers/profile/MetaboliteProfile";
import DefaultProfile from "../components/containers/profile/DefaultProfile";
import { Loader } from "../components/controls/Loader";
import { filterSelector } from "../redux/selectors/filter.selector";
import { interactionsSelector } from "../redux/selectors/interactions.selector";
import { setActiveTab } from "../redux/reducers/discoveryPanels.reducer";
import { dataSelector } from "../redux/selectors/data.selector";

const ProfileContainer = ({ match }) => {
  const dispatch = useDispatch();
  const [mounted, setMount] = useState(false);
  const selectedState = useSelector(interactionsSelector);
  const { tabs } = useSelector(filterSelector);
  const { sample } = useSelector(dataSelector);

  useEffect(() => {
    setMount(true);
    dispatch(setActiveTab(tabs[2].url));
    return () => setMount(false);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!mounted) {
    return <Loader />;
  }
  return (
    <div className="profile fade-in">
      <section>
        {(selectedState.type === "pathway" && <PathwayProfile match={match} />) ||
          (selectedState.type === "metabolite" && <MetaboliteProfile match={match} />) || (
            <DefaultProfile />
          )}
      </section>
    </div>
  );
};

export default memo(ProfileContainer);
