import { createSelector } from '@reduxjs/toolkit';

export const searchResultsSelector = createSelector(
	(state) => state,
	(state) => state.searchResults
);

export const searchResultsIsLoadingSelector = createSelector(
	searchResultsSelector,
	(searchResults) => searchResults.searchResultsIsLoading
);

export const searchResultsHasErroredSelector = createSelector(
	searchResultsSelector,
	(searchResults) => searchResults.searchResultsHasErrored
);
