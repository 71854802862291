import React, { useState, useCallback, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { scaleSqrt } from "d3";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import Icon from "../../../components/controls/Icon.jsx";
import Link from "../../../components/buttons/Link.jsx";
import { filterSelector } from "../../redux/selectors/filter.selector";
import {
  setActiveTab,
  setFilterAssociation,
  setFilterAssociations,
  interactionsUpdateSelection
} from "../../redux/reducers/discoveryPanels.reducer";

import * as styles from "./table.module.scss";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger";

const grow = scaleSqrt().domain([20, 1]).range([10, 1]).clamp(true);

const Table = ({
  tableData,
  className,
  defaultSort,
  defaultSortDirection,
  columns,
  flow,
  equalSpacing,
  showTotal,
  showButton,
  buttonLabel,
  showLabel,
  height,
  tableType,
  tableSwitchTabTo,
  updateOnHover,
  enableFontWeight,
  splitHeader
}) => {
  const dispatch = useDispatch();
  const { tabs, association } =
    useSelector(filterSelector);

  const [sort, setSort] = useState(defaultSort);
  const [direction, setDirection] = useState(defaultSortDirection);

  const updateTableSort = useCallback(
    (key) => {
      setDirection(key === sort ? !direction : defaultSortDirection);
      setSort(key);
    },
    [sort, direction]
  );

  const sortTable = useCallback(
    (data) => {
      return data.sort(function (a, b) {
        const sortOrder = sort || defaultSort;
        const x = typeof a[sortOrder] === "string" ? {...a, [sortOrder]: a[sortOrder]?.toLowerCase()} : a;
        const y = typeof b[sortOrder] === "string" ? {...b, [sortOrder]: b[sortOrder]?.toLowerCase()} : b;
        return direction
          ? (x[sortOrder] === null) - (y[sortOrder] === null) ||
              +(x[sortOrder] > y[sortOrder]) ||
              -(x[sortOrder] < y[sortOrder])
          : (x[sortOrder] === null) - (y[sortOrder] === null) ||
              -(x[sortOrder] > y[sortOrder]) ||
              +(x[sortOrder] < y[sortOrder]);
      });
    },
    [sort, direction]
  );

  const tableDataSorted = useMemo(() => {
    return tableData.map((d) => {
      return {
        ...d,
        data: sortTable(d.data),
      };
    });
  }, [tableData, sortTable]);

  const tableEvents = useCallback(
    (d, eventType) => {
      if (d) {
        if (tableSwitchTabTo) {

          const activeGroup = association;
          //TODO: fix this if statement, it's garbage but I have no idea what the hell i'm doing, sincerely Shawn
          if (tableSwitchTabTo.indexOf(tabs[1].url) !== -1){
            if (d.type && d.type !== activeGroup) {
              dispatch(setFilterAssociation(d.type));
            }
          }
        }
        if (tableType === "metabolite") {
          dispatch(interactionsUpdateSelection({
            id: d.chemical_id,
            display: d.metabolite,
            type: "metabolite",
            subtype: null,
            method: eventType,
            source: "table",
            sourceSpecific: {
              group: d.plasma_grouping,
              pathway_name:
                (d.metaboliteInfo && d.metaboliteInfo.pathway_name) ||
                d.pathway_name ||
                null,
            },
          }));
        } else if (tableType === "pathway") {
          dispatch(interactionsUpdateSelection({
            id: d.pathway_name,
            display: d.pathway_name,
            type: "pathway",
            method: eventType,
            source: "table",
            sourceSpecific: null,
          }));
        } else if (tableType === "association") {
          dispatch(interactionsUpdateSelection({
            id: d.association,
            display: d.association,
            type: "association",
            subtype: d.type || "",
            method: eventType,
            source: "table" + d.type || "",
            sourceSpecific: null,
          }));
        }
      } else {
        dispatch(interactionsUpdateSelection({
          id: "default",
        }));
      }
    },
    [tableType, tableSwitchTabTo]
  );
  const logger = useLocalAuditLogger();

  const buttonFuncHandler = useCallback((item) => {
      return () => {
        item.action();
        logger.logUserAction(`user clicked on ${item.label}`); };
      },
    []
  );

  return (
    <div
      className={classnames(styles.table, styles["flow-" + flow], className)}
      style={{ height: height + "%"}}
    >
      {tableDataSorted.map((d, i) => {
        const label = d.label || "";
        const data = d.data || [];
        const color = d.color || "inherit";
        const buttonFunc = buttonFuncHandler(d);
        return (
          data.length > 0 && (
            <div
              className={styles.wrapper}
              style={{
                flex: equalSpacing ? 1 : grow(data.length),
              }}
              key={label || i}
            >
              {showTotal && (
                <div className="ban-wrapper">
                  <h4 className="light-txt sm">{label}</h4>
                  <p className="ban-sm" style={{ color: color }}>
                    {data.length}
                  </p>
                </div>
              )}
              {showLabel && (
                <div className="ban-wrapper no-border">
                  <h3 className="">{label}</h3>
                </div>
              )}
              <TableHeader
                columns={columns}
                headerClick={updateTableSort}
                sortedOn={sort}
                sortDirection={direction}
                splitHeader={splitHeader}
              />
              <TableBody
                columns={columns}
                tableData={data}
                color={color}
                tableEvents={tableEvents}
                updateOnHover={updateOnHover}
                rerenderOn={sort + direction}
                enableFontWeight={enableFontWeight}
                tableSwitchTabTo={tableSwitchTabTo}
              />
              <div className={styles.fader} />
              {showButton && (
                <Link
                  to={tableSwitchTabTo}
                  onClick={buttonFunc}
                  color="white"
                  className="transparent-link-button block slide"
                >
                  <div>
                    <p className="body">{buttonLabel}</p>
                    <Icon icon="angle-right" size="large" color="default" />
                  </div>
                </Link>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

Table.propTypes = {
  tableData: PropTypes.array,
  className: PropTypes.string,
  defaultSort: PropTypes.string,
  defaultSortDirection: PropTypes.bool,
  columns: PropTypes.array,
  flow: PropTypes.string,
  equalSpacing: PropTypes.bool,
  showTotal: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  height: PropTypes.number,
  tableType: PropTypes.string,
  tableSwitchTabTo: PropTypes.string,
  updateOnHover: PropTypes.bool,
  enableFontWeight: PropTypes.bool,
  splitHeader: PropTypes.bool,
};

Table.defaultProps = {
  tableData: [],
  className: "",
  defaultSort: "key",
  defaultSortDirection: false,
  columns: [],
  flow: "column",
  equalSpacing: true,
  showTotal: false,
  showButton: false,
  buttonLabel: "",
  showLabel: false,
  height: 90,
  tableType: "metabolite",
  tableSwitchTabTo: null,
  updateOnHover: false,
  enableFontWeight: false,
  splitHeader: false,
};

export default memo(Table);
