'use strict';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal.jsx';

/** A Modal component tha displays dynamic content, 
	content can be created with any combination of
	the children, component, or renderer props */
export default class DynamicModalSingleton extends Component {
	
	static getInstance() {
		return DynamicModalSingleton.instance;
	}
	static updateState(data, callback) {
		let instance = DynamicModalSingleton.getInstance();
		if(!instance) return;
		instance.setState(data, callback);
	}

	static show() {
		let instance = DynamicModalSingleton.getInstance();
		if(!instance) return;
		
		instance.modal.show();
	}

	static hide() {
		let instance = DynamicModalSingleton.getInstance();
		if(!instance) return;
		
		instance.modal.hide();
	}

	static propTypes = {
		/** The component class used to render the modal content*/
		component: PropTypes.func,

		/** Props passed down to the content component*/
		componentProps: PropTypes.object,

		/** Props passed down to the modal component*/
		modalProps: PropTypes.object,

		/** Renderer function used to render the modal content*/
		renderer: PropTypes.func,
		/** Prop used to show/hide the close button in top right */
		renderClose: PropTypes.bool
	};

	static defaultProps = {
		id: "modal-singleton",
	};

	constructor(props) {
		super(props);
		const {component, componentProps, modalProps, renderer} = this.props;
		this.state = {
			component,
			componentProps,
			modalProps,
			renderer,
		};

		DynamicModalSingleton.instance = this;
	}
	
	componentDidMount() {

	}

	componentDidUpdate(prevProps) {
		const {component, componentProps, modalProps, renderer} = this.props;
		let data = {};

		if(prevProps.component !== component) data.component = component;
		if(prevProps.componentProps !== componentProps) data.componentProps = componentProps;
		if(prevProps.modalProps !== modalProps) data.modalProps = modalProps;
		if(prevProps.renderer !== renderer) data.renderer = renderer;

		if(Object.keys(data).length) DynamicModalSingleton.updateState(data);
	}

	setRef = (modal) => {
		this.modal = modal;

		if(this.props.modalRef) this.props.modalRef(modal);
	};

	render() {
		let {children, modalRef, ...props} = this.props;
		delete props.componentProps;
		delete props.component;
		delete props.modalProps;
		delete props.renderer;

		const {component, componentProps, modalProps, renderer} = this.state;

		return (
			<Modal ref={this.setRef} {...props} {...modalProps} renderClose={false} >
				{children}
				{component && 
					React.createElement(component, componentProps)
				}
				{renderer && 
					renderer()
				}
			</Modal>
		);

	}
}