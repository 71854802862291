'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import { useUserQuery, usePublishUserMutation, useRolesQuery } from '@/redux/services/admin.api';
import { Button, Checkbox, Label } from '@/components/index.jsx';
import './ManageUserPermissions.scss';
import '@/theme/theme.css';
import { LoadingSpinner } from '@/lenses/uilenses/components/LoadingSpinner/LoadingSpinner';

export const ManageUserPermissions = (props) => {
	const [selectedPermissions, setSelectedPermissions] = useState([]);
	const [publishUser, { isLoading: userIsPublising }] = usePublishUserMutation();
	const { data: availableRoles, isLoading } = useRolesQuery();
	const { data: user, isLoading: userIsLoading } = useUserQuery(props.user?.email, { skip: !props.user?.email });

	useEffect(() => {
		if (isLoading || userIsLoading || !user || !availableRoles) return;
		if (!user.roles) {
			setSelectedPermissions([]);
			return;
		}

		setSelectedPermissions(user.roles.map(role => role.name));
	}, [availableRoles, isLoading, user, userIsLoading]);

	const onPermissionClick = useCallback((e) => {
		let currentPermissions = [...selectedPermissions];

		if (e.target.isChecked) {
			currentPermissions.push(e.target.value);
			setSelectedPermissions(currentPermissions);
		}
		else if (!e.target.isChecked) {
			currentPermissions = currentPermissions.filter(item => item !== e.target.value);
			setSelectedPermissions(currentPermissions);
		}
	}, [selectedPermissions]);

	const onSaveUserPermissions = useCallback(() => {
		publishUser({ ...user, roles: availableRoles.filter(role => selectedPermissions.includes(role.name)) }, "PortalUI: Updated User Permissions");
		//logUserManagment(user, "EditUserPermissions");
	}, [availableRoles, selectedPermissions, user]);

	const onCancel = useCallback(() => {
		setSelectedPermissions(user.roles.map(role => role.name));
	}, [user]);

	if (!user || userIsLoading || !availableRoles || isLoading) return null;

	let displayName = 'Not Found';

	if (user.email.length > 0) {
		displayName = user.email;
	}
	if (user.firstName && user.lastName) {
		displayName = user.firstName + ' ' + user.lastName;
	}

	return (
		<div className="managedUsersPermissions">
			<div>Manage User Permissions for <Label color="primary">{displayName}</Label></div>
			{userIsPublising}
			<hr />

			<div className="roles">
				{availableRoles.map((role, index) => (
					<div key={`${role.name}-${index}`}>
						<Checkbox checked={selectedPermissions.includes(role.name)} value={role.name} label={role.name} onChange={onPermissionClick} />
					</div>
				))}
			</div>
			<div className={"UserActions"}>
				{userIsPublising && <LoadingSpinner size={30} />}
				<Button disabled={userIsPublising} onClick={onSaveUserPermissions} data-dismiss="modal" >Save</Button>
			</div>
		</div>
	);
};

export default ManageUserPermissions;