import React, { } from 'react';

export const WelcomeMessage = () => {

	return (
		<div className="WelcomeMessage">
			<h3>Welcome to the MyMetabolon portal!</h3>
			<p>We're excited to have you here and we hope you find everything you're looking for.</p>
			<p>Our platform is designed to make it easy for you to access the latest and most comprehensive metabolomics data available.</p>
			<p>Feel free to explore the different tools and resources on our portal, and don't hesitate to reach out to us if you have any questions or feedback. We're here to help you make the most of your metabolomics research journey!</p>
		</div>
	);
};

