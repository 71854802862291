export const getWorkerUrl = (url) => {
	const moduleUrl = new URL(url, import.meta.url)
	const blob = new Blob([moduleUrl], { "type": 'application/javascript' });
	const urlFunc = window.URL || window.webkitURL;
	const workerUrl = urlFunc.createObjectURL(blob);
	console.log('blob', blob);
	console.log('moduleUrl', moduleUrl);
	console.log('workerUrl', workerUrl);
	return workerUrl;

	//return new URL(url, import.meta.url);
}

export const createWorker = (url) => {
	const worker = new Worker(getWorkerUrl(url), { format: 'es', type: 'module' })
	return worker;
	//return new Worker();
};