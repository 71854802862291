import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';
import { ManifestList } from 'metabux/components/ManifestList/ManifestList';
import { ManifestRemoveModal } from './ManifestRemoveModal';
import { Box, Typography, Stack, TableContainer, useTheme, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectCoe, selectCoeManifests, selectCoeUpdatedManifests } from '@/views/clientOnboarding/redux/selector/coe.selector';
import { setManifests, updateManifest, clearManifestUpdates, getInvalidItems } from '@/views/clientOnboarding/redux/reducers/coe.reducer';
import {
	useListQuery,
	useDownloadMutation,
	useUpdateMutation,
	useDeleteMutation
} from "@/redux/services/manifest.api"
import { useCommittedMutation } from '@/redux/services/coe.api';
import { setCurrent, getManifestId } from '@/views/clientOnboarding/redux/reducers/coe.reducer';
import { Button } from "metabux/components/Button/Button"
import { showToaster } from '@/utils/ToastController';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';

export const ShippingInfo = ({
	projectId,
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { current } = useSelector(selectCoe)
	const items = useSelector(selectCoeManifests)
	const updatedManifests = useSelector(selectCoeUpdatedManifests)

	const { isLoading, data, refetch } = useListQuery({ projectId })
	const [downloadManifest, manifestsDownloadResult] = useDownloadMutation()
	const [updateManifests, { status: updateStatus }] = useUpdateMutation()
	const [setCommitted, { status: committedStatus }] = useCommittedMutation()
	const [deleteManifests, { status: deleteStatus }] = useDeleteMutation()

	const [removeTarget, setRemoveTarget] = useState(null)
	const [invalidItems, setInvalidItems] = useState(getInvalidItems(items))
	const [removeModalOpen, setRemoveModalOpen] = useState(false)
	const [hasChanged, setHasChanged] = useState(false)
	
	const itemsArray = useMemo(() => items ? Object.values(items) : [], [items])
	const itemCount = useMemo(() => itemsArray.length, [itemsArray])
	const savedIinvalidItems = useMemo(() => getInvalidItems(keyBy(data, getManifestId)), [data])
	

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (deleteStatus === 'fulfilled')
			showToaster('Success!', 'Manifest deleted!', 5000, 'success.main')
		if (deleteStatus === 'failed')
			showToaster('An error has occurred', 'The selected Manifest was not deleted', 5000, 'error.main')
	}, [deleteStatus]);

	const onNext = useCallback(() => {
		dispatch(setCurrent(current + 1))
	}, [current, dispatch]);

	useEffect(() => {
		if (committedStatus === 'fulfilled') {
			showToaster('Success!', 'Onboarding completed!', 5000, 'success.main')
			onNext()
		}
		if (committedStatus === 'failed')
			showToaster('An error has occurred', 'An error has occurred.', 5000, 'error.main')
	}, [committedStatus, onNext]);

	useEffect(() => {
		if (updateStatus === 'fulfilled')
			showToaster('Success!', 'Manifests updated!', 5000, 'success.main')
		if (updateStatus === 'failed')
			showToaster('An error has occurred', 'The Manifests were not updated', 5000, 'error.main')
	}, [updateStatus]);

	const onSave = useCallback(() => {
		const metaDatas = Object.entries(items)
			.filter(([id, value]) => updatedManifests.indexOf(id) >= 0)
			.map(([id, value]) => value);

		updateManifests({ projectId, metaDatas });
		setHasChanged(false)
	}, [items, updatedManifests]);

	const onFinish = useCallback(() => {
		const { trackingNumber, shippingProvider } = itemsArray[0]
		setCommitted({ projectId, trackingNumber, shippingProvider })
	}, [projectId, itemsArray]);

	const onPrev = useCallback(() => {
		dispatch(setCurrent(current - 1))
	}, [current, dispatch]);

	useEffect(() => {
		if (!data) return;

		dispatch(clearManifestUpdates());
		dispatch(setManifests(data));
	}, [dispatch, data]);

	const onSaveClick = useCallback(() => {
		onSave();
	}, [invalidItems, onSave]);

	const onItemChange = useCallback((value) => {
		setHasChanged(true)
		dispatch(updateManifest(value))
	}, [dispatch]);

	useEffect(() => {
		setInvalidItems(getInvalidItems(items))
	}, [items])

	const onItemRemove = useCallback((value) => {
		setRemoveTarget(value);
		setRemoveModalOpen(true);
	}, [setRemoveModalOpen]);

	const onRemoveConfirm = useCallback((reason, other) => {
		if (!removeTarget) return;

		deleteManifests({ projectId, comment: other ? `${reason} ${other}` : reason, manifestFileIds: [removeTarget.manifestFileId] })
		setRemoveTarget(null);
		setRemoveModalOpen(false);
	}, [removeTarget]);

	return (

		<>
			<Typography variant="h4" textAlign="center" mb={2}>Tracking &amp; Receipt</Typography>
			<Typography color="error" textAlign="center" mb={2}>Please include a hard copy of the completed manifest inside your sample shipment but outside of the Styrofoam container.</Typography>

			{!isLoading && <TableContainer className='step-content' sx={{ p: theme.spacing(0, 5, 4) }}>
				<ManifestList
					onSaveClick={onSaveClick}
					onChange={onItemChange}
					onRemove={onItemRemove}
					items={itemsArray}
				/>
				{itemCount > 0 && (
					<Stack justifyContent="flex-end" direction="row">
						<Button disabled={!hasChanged} onClick={onSaveClick} align="right">Save</Button>
					</Stack>
				)}

				{invalidItems.length > 0 && (
					<Typography width="100%" variant="caption" textAlign="center" color="error" component="p">You may save your progress however you must correct the errors above before completing this process</Typography>
				)}
			</TableContainer>}
			{isLoading && <Box className='step-content' textAlign="center"><LoadingSpinner /></Box>}

			<Stack width="100%" direction="row" justifyContent="space-between">
				<Button
					onClick={onPrev}
					color="gray"
					variant="dark"
				>
					Back: Manifest Upload
				</Button>
				<Box>
					{committedStatus === 'pending' ? <CircularProgress/> : null}
					<Button
						disabled={savedIinvalidItems.length > 0 || invalidItems.length > 0 || itemCount <= 0}
						onClick={onFinish}
					>
						Finish
					</Button>
				</Box>
			</Stack>

			<ManifestRemoveModal
				open={removeModalOpen}
				manifestItem={removeTarget}
				setOpen={setRemoveModalOpen}
				onSubmit={onRemoveConfirm}
			/>

		</>
	)
}