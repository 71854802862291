import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/buttons/Button.jsx';
import Select from '../../../components/controls/Select.jsx';
import Material from '../../../components/controls/Material.jsx';
import MetaboliteTable from '../../../containers/MetaboliteTable.jsx';
import StatsDropdown from '../../../containers/StatsDropdown.jsx';
import SamplesetUtils from '../../../utils/SamplesetUtils.js';
import './Heatmap.scss';
import { Logger, Log } from '../../../utils/Logger';
import { AppInsightLogLevel } from '../../../enums/Enums';
import { useDispatch } from 'react-redux';
import { sampleSetDataIsSelected } from '@/redux/reducers/sampleSetData.reducer.js';
const logger = new Logger();

function Heatmap(props) {
	const dispatch = useDispatch();
	const [sampleSets, setSampleSets] = useState(props.sampleSets);
	const [activeSampleSet, setActiveSampleSet] = useState(null);
	const [showHidden] = useState(props.showHidden);

	const [sampleSetIndex, setSampleSetIndex] = useState(0);
	const [activeStats, setActiveStats] = useState([]);
	const [stats, setStats] = useState([]);
	const [current, setCurrent] = useState(null);
	const [selection, setSelection] = useState(null);
	const [statsDict, setStatsDict] = useState(null);
	const [metabolites, setMetabolites] = useState([]);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		//console.log(metabolites)
	}, [metabolites]);

	const init = () => {
		let sampleSet = (sampleSets.length ? sampleSets[props.sampleSetIndex] : { sample: {}, stats: [], metabolites: [] });

		onSampleSetSelect(sampleSet, props.statsId, false);
	};

	const onSampleSetSelect = useCallback((sampleSet, statsId = null, isUserEvent = true) => {
		const { testField } = props;

		let index = props.sampleSets.indexOf(sampleSet);

		let statsData = SamplesetUtils.processStats(sampleSet, statsId);

		//iterate over metabolites, for each stats
		let sampleMetabolites = sampleSet ? sampleSet.metabolites : [];
		statsData.metabolites = SamplesetUtils.processStatDetails(sampleMetabolites, testField, statsData.stats, statsData.statsDict, statsData.trends);

		setSampleSetIndex(statsData.sampleSetIndex);
		setStats(sampleSet.stats);
		setActiveStats(statsData.activeStats);
		setCurrent(statsData.current);
		setSelection(statsData.selection);
		setStatsDict(statsData.statsDict);
		setMetabolites(statsData.metabolites);
		setActiveSampleSet(sampleSet);
		dispatch(sampleSetDataIsSelected(index));

		if (!isUserEvent) return;

		if (props.onSampleSetSelect)
			props.onSampleSetSelect(statsData.sampleSetIndex, statsData.current);

		logEvent("SampleSetIDSelect", sampleSet ? sampleSet.projectSampleSetId : "Undefined");
	}, []);

	const onNextArrow = useCallback(() => {
		let n = activeStats.indexOf(current);
		setCurrent(activeStats[n + 1]);
		logEvent("Arrow", "Next");
	}, [activeStats, current]);

	const onPrevArrow = useCallback(() => {
		let n = activeStats.indexOf(current);
		setCurrent(activeStats[n - 1]);
		logEvent("Arrow", "Previous");
	}, [activeStats, current]);

	const onItemToggle = useCallback((selections) => {
		setActiveStats(selections);
	}, []);

	const onRowClick = useCallback((id) => {
		setCurrent(id);
		logEvent("StatSelectID", id);
	}, []);

	const sortedStats = useMemo(() => {
		return [...stats].sort((a, b) => a.customerIndex - b.customerIndex);
	}, [stats]);

	const sortedActiveStats = useMemo(() => {
		return sortedStats
			.filter((stat) => activeStats.includes(stat.statsId))
			.map((stat) => stat.statsId);
	}, [activeStats, sortedStats]);

	const getSelectKey = useCallback(option => option.sample.name, []);

	const logEvent = (property, value) => {
		//Log event
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageEvent_HeatMap');
		logFilterChange.AddProperty(property, value);
		logger.doLog(logFilterChange);
		//console.log('LOG', property, value)
	};

	if (!activeSampleSet) return <p>No sampleset available</p>;

	let statsIndex = current ? activeStats.indexOf(current) : -1;
	const studyType = current ? statsDict[current].studyType : 'standard';

	return (
		<div className="Heatmap">
			<Select
				className="pathway-viz"
				color="tertiary"
				selection={activeSampleSet}
				options={sampleSets}
				optionClass="alt2 tertiary"
				displayKey={getSelectKey}
				onSelect={onSampleSetSelect} />

			<StatsDropdown
				className="pathway-viz"
				color="tertiary"
				//sampleSet={activeSampleSet}
				stats={sortedStats}
				activeStats={sortedActiveStats}
				selection={current}
				onItemToggle={onItemToggle}
				showHidden={showHidden}
				onRowClick={onRowClick} />

			<div className="stats-switcher">
				<Button className="alt2 primary prev" title="Previous Test" disabled={!activeStats || statsIndex === 0} onClick={onPrevArrow}><Material size="large" icon="keyboard_arrow_left" /></Button>
				<Button className="alt2 primary next" title="Next Test" disabled={!activeStats || statsIndex === activeStats.length - 1} onClick={onNextArrow}><Material size="large" icon="keyboard_arrow_right" /></Button>
			</div>

			{props.editMode ? <Button onClick={null} >Save</Button> : <div />}

			{activeSampleSet &&
				<MetaboliteTable
					exportFilename={activeSampleSet.sample.name}
					className={"pathway-viz " + studyType}
					studyType={studyType}
					metabolites={metabolites}
					showHidden={showHidden}
					stats={statsDict}
					currentStatsField={current}
					webWorker={true}
					activeStats={activeStats} />
			}
		</div>
	);
}

Heatmap.propTypes = {
	/** project*/
	project: PropTypes.object,
	sampleSets: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			projectCode: PropTypes.string,
			projectID: PropTypes.string,
			sample: PropTypes.object,
			stats: PropTypes.array,
			metabolites: PropTypes.array
		})
	),

	/** index of the initial sample set */
	sampleSetIndex: PropTypes.number,

	/** id of the initial stats test */
	statsId: PropTypes.string,

	onItemToggle: PropTypes.func,
	webWorker: PropTypes.bool,
	showHidden: PropTypes.bool,
};

Heatmap.defaultProps = {
	project: null,
	sampleSets: [{
		sample: {},
		stats: [],
		metabolites: []
	}],
	sampleSetIndex: 0,
	statsId: null,
	minUp: 0.001,
	maxUp: 0.499,
	minDn: 0.5,
	maxDn: 1.0,
	showHidden: true,
	testField: "foldChange",
};

export default Heatmap;