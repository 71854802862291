import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Head from "../components/containers/integration/Head";
import Bottom from "../components/containers/integration/Bottom";
import * as styles from "./dataIntegration.module.scss";
import { Loader } from "../components/controls/Loader";
import { filterSelector } from "../redux/selectors/filter.selector";
import { setActiveTab } from "../redux/reducers/discoveryPanels.reducer";

function DataIntegration({ match }) {
  const dispatch = useDispatch();
  const [mounted, setMount] = useState(false);
  const { tabs } = useSelector(filterSelector);

  useEffect(() => {
    setMount(true);
    dispatch(setActiveTab(tabs[3].url));
    return () => setMount(false);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!mounted) {
    return <Loader />;
  }
  return (
    <div className="data-integration fade-in">
      <section>
        <div id={styles["top-wrapper"]}>
          <div className={styles["copy"] + " copy"}>
            <h2>Data Quality &amp; Power</h2>
            <p>Detection, Instrument and Process Validation </p>
          </div>
        </div>
        <Head match={match} />
        <Bottom />
      </section>
    </div>
  );
}

export default DataIntegration;
