import React, { useCallback, useEffect, useRef, useState } from "react"

export const useWindow = () => {
	const [win, setWin] = useState()

	useEffect(() => {
		setWin(window)
	}, [])

	return win
}

export const useResize = () => {
	const ref = useRef()
	const [size, setSize] = useState({
		width: undefined,
		height: undefined
	})

	const onResize = useCallback((target) => {
		if (!ref.current) return
		setSize({
			width: ref.current.offsetWidth,
			height: ref.current.offsetHeight
		})
	}, [])

	const observer = useRef(new ResizeObserver(onResize))


	useEffect(() => {
		if (!ref.current || !observer.current) return
		observer.current.observe(ref.current)

		return () => {
			observer.current.disconnect()
		}
	}, [])

	return [
		(
			<div key={0} ref={ref} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: "100%", height: "100%", pointerEvents: 'none', visibility: 'hidden' }} />
		),
		size,
		onResize,
		setSize,
		ref
	]
}