import { mockApi } from "../discovery-panel/api";
import { callApi } from "./requests";

export const CALL_API = Symbol('Call API');

export default store => next => action => {
  if(!action)
    return;
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { isMock, types, authenticated } = callAPI;
  const [ loadingType, successType, errorType ] = types;
  
  //Invoke loading action if exist
  if(loadingType != null){
    next({type:loadingType, isLoading:true});
  }

  if (isMock) {
    const { generator, fail, timeout } = callAPI;
    return mockApi.call(generator, fail, timeout).then(
      response =>
      next({
        response,
        authenticated,
        type: successType,
      }),
      error => next({
        error: error ? error : 'There was an unknown error.',
        type: errorType
      })
    );
  }

  const { endpoint, method, payload } = callAPI;

  return callApi(endpoint, authenticated, method, payload).then(
  response =>
    next({
      response,
      authenticated,
      type: successType,
    }),
    error => next({
      error: error ? error : 'There was an unknown error.',
      type: errorType
    })
  );
};
