import { call, put, takeLatest } from "redux-saga/effects";
import { createAction } from '@reduxjs/toolkit';
import {
  fetchUserFailed,
  fetchUserSuccess,
} from "../reducers/currentUser.reducer";
import { fetchCurrentUser } from "../../middleware/requests";

export const fetchUser = createAction("currentUser/fetchUser");

export function* currentUserSaga(action) {
  try {
    const response = yield call(
      fetchCurrentUser
    );
    const { payload, error } = response;
    if (error || !payload) yield put(fetchUserFailed(response));
    else yield put(fetchUserSuccess(response));
  } catch (e) {
    yield put(fetchUserFailed({error:e}));
  }
}

export function* currentUserSagas() {
  yield takeLatest(fetchUser, currentUserSaga);
}