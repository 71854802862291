export const initializeTooltip = () => ({
    x: 0,
    y: 100,
    showTooltip: false,
    tooltipData: undefined,
    type: 'metabolite',
    value: null
});


export const tooltipReducer = () => ({
    setTooltipX: (state, action) => {
        state.tooltip.x = action.payload;
    },
    setTooltipY: (state, action) => {
        state.tooltip.y = action.payload;
    },
    setShowTooltip: (state, action) => {
        state.tooltip.showTooltip = action.payload;
    },
    setTooltipData: (state, action) => {
        state.tooltip.tooltipData = action.payload;
    },
    setTooltipType: (state, action) => {
        state.tooltip.type = action.payload;
    },
    setTooltipValue: (state, action) => {
        state.tooltip.value = action.payload;
    }
});