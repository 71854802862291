import mapToArray from "../utils/mapToArray";
import getDocStyle from "./getDocStyle";
import { pack, hierarchy, group as d3group } from "d3";

export default function prepData(
  data,
  width,
  portWidth,
  height,
  group,
  proportion
) {
  const groupData = mapToArray(d3group(data, (d) => d[group])).map((d) => {
    return {
      ...d,
      count: d.value.length,
      children: d.value,
    };
  });

  const hierarchyData = {
    name: "Overall",
    children: groupData,
  };

  const sortBy = (a, b) => b.value - a.value;
  const computePadding = (d) => {
    return d.depth === 0
      ? (portWidth <= 850 ? 25 : 50) / proportion
      : (portWidth <= 850 ? 7.5 : 15) / proportion;
  };

  const packer = pack()
    .size([width, height])
    .padding((d) => computePadding(d));

  const root = hierarchy(hierarchyData)
    .sum((d) => d.size || 1)
    .sort(sortBy);

  const packed = packer(root);
  const descendants = packed.descendants();

  const color = descendants.map((d) => {
    return {
      ...d,
      color:
        d.depth === 2
          ? d.data.detected
            ? d.data.color
            : "#fff"
          : d.depth === 0
          ? getDocStyle("var(--cold-gray-lightest)")
          : "#fff",
      strokeWidth: d.depth === 1 ? 2 : 1,
    };
  });
  return color;
}
