'use strict';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sampleSetFetchDataAdmin, putAdminSampleSetSaveData } from '@/redux/actions/sampleSetData';
import { Icon, Button, Toaster } from '@/components/index.jsx';
import StatsList from '@/containers/StatsList.jsx';
import SamplesList from '@/containers/SamplesList.jsx';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'metabux/components/LoadingSpinner/LoadingSpinner';

import './AdminSamplesets.scss';
import { Logger, Log } from '@/utils/Logger';
const logger = new Logger();
import * as enums from '@/enums/Enums';
import SamplesetUtils from '@/utils/SamplesetUtils';
import { Auditor } from '@/components/util/Auditor.jsx';

export class AdminSamplesets extends Auditor {

	static propTypes = {
		/** project*/
		project: PropTypes.object.isRequired,
		sampleSets: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				projectCode: PropTypes.string,
				projectID: PropTypes.string,
				sample: PropTypes.object,
				stats: PropTypes.array,
				metabolites: PropTypes.array,
				showSampleSets: false
			})
		),
		setSaving: PropTypes.func,
		/** index of the initial sample set */
		sampleSetIndex: PropTypes.number,
		/** id of the initial stats test */
		statsId: PropTypes.string,
		useWorker: PropTypes.bool,
	};

	static defaultProps = {
		project: null,
		sampleSets: null,
		sampleSetIndex: 0,
		statsId: null,
		useWorker: true,
		testField: "foldChange",
	};

	constructor(props) {
		super(props);

		this.state = {
			...this.initState(),
			...super.state
		};

		this.onSave = this.onSave.bind(this);
		this.onSampleSetChange = this.onSampleSetChange.bind(this);
		this.onStatSelect = this.onStatSelect.bind(this);
		this.updateProject = this.updateProject.bind(this);
		//this.updateSampleSet = this.updateSampleSet.bind(this);
		//this.renderSampleSet = this.renderSampleSet.bind(this);
		this.onSampleSetSelect = this.onSampleSetSelect.bind(this);
		//this.onSampleVisibility = this.onSampleVisibility.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
		this.logError = this.logError.bind(this);
		this.onSampleSetVisibilityChange = this.onSampleSetVisibilityChange.bind(this);
	}

	componentDidMount() {
		if (this.props.project && !this.props.isLoading) {
			this.updateProject();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.project && this.state.project) {
			if (nextProps.project.projectId == this.state.project.projectId) return;
		}

		if (!nextProps.project && nextProps.isLoading) return;
		this.updateData({ ...this.initState(), project: nextProps.project }, this.updateProject);
	}

	initState() {
		const { project, sampleSets, sampleSetIndex, statsId } = this.props;

		let activeSampleSet = sampleSets && sampleSets.length ? sampleSets[sampleSetIndex] : null;
		return { project, sampleSets, ...this.onSampleSetSelect(activeSampleSet, statsId, false) };
	}

	showToaster(title, body, autoClose = 2000) {
		toast(<Toaster title={title} body={body} />, { autoClose: autoClose });
	}

	updateProject(sampleSetIndex = -1) {
		const { project } = this.state;
		if (!project) {
			this.props.setSaving(false);
			return;
		}
		this.setState({ showSampleSets: false });
		/* istanbul ignore next */
		if (project.projectId) {
			this.props.fetchDataAdmin(project.projectId).then(result => {
				if (result.error) {
					let msg = `Status: Error, Failed to update project. Info: result.error`;
					this.showToaster("Error", msg, 20000);
					this.props.setSaving(false);
					return;
				}
				if (result.response) {
					if (sampleSetIndex > -1) {
						this.setState({
							saving: false,
							sampleSetIndex,
							activeSampleSet: result.response[sampleSetIndex],
							sampleSets: result.response,
							showSampleSets: true
						});
					}
					else {
						this.setState({
							saving: false,
							sampleSets: result.response,
							showSampleSets: true
						});
					}
				} else {
					this.setState({ showSampleSets: true });
				}
			}).finally(() => {
				if (this.props.setSaving) this.props.setSaving(false);
			});
		}
	}

	updateData(data, callback) {
		this.setState({ ...data }, callback);
	}

	setCurrentStat(id) {
		this.setState({ current: id });
	}

	onSampleSetSelect = (sampleSet, statsId = null, isUserEvent = true) => {
		const { testField } = this.props;

		let sampleSets = this.state?.sampleSets?.concat() || [];

		let sampleSetIndex = sampleSets && sampleSets.length ? sampleSets.indexOf(sampleSet) : 0;
		let statsData = SamplesetUtils.processStats(sampleSet, statsId);

		//iterate over metabolites, for each stats
		let sampleMetabolites = sampleSet ? sampleSet.metabolites : [];
		statsData.metabolites = SamplesetUtils.processStatDetails(sampleMetabolites, testField, statsData.stats, statsData.statsDict, statsData.trends);

		let data = {
			activeSampleSet: sampleSet,
			sampleSetIndex: sampleSetIndex,
			stats: statsData.stats,
			current: statsData.current,
			selection: statsData.selection,
			activeStats: statsData.activeStats,
			statsDict: statsData.statsDict,
			studyType: statsData.studyType,
			metabolites: statsData.metabolites,
		};

		if (!isUserEvent) return data;

		this.updateData(data);

		if (this.props.onSampleSetSelect)
			this.props.onSampleSetSelect(statsData.sampleSetIndex, statsData.current);

		//logEvent("SampleSetIDSelect",sampleSet ? sampleSet.projectSampleSetId : "Undefined");
	};

	onStatSelect(stat) {
		//console.log(stat);
		this.setCurrentStat(stat.statsId);
		/*if(stat) this.setState({current:stat.id});*/
	}

	onSampleSetChange(sampleSet) {
		let sampleSets = this.state?.sampleSets?.concat() || [];
		const index = sampleSets.findIndex((s) => s.sampleSetId === sampleSet.sampleSetId);
		sampleSets[index] = sampleSet;
		this.setState({ sampleSets, activeSampleSet: sampleSet });
	}

	onSampleSetVisibilityChange(sampleSet) {
		const { sampleSets } = this.state;
		const changedIndex = sampleSets.findIndex((s) => s.sampleSetId === sampleSet.sampleSetId);

		if (changedIndex >= 0 && sampleSets[changedIndex]) {
			const nextSampleSets = [...sampleSets];
			nextSampleSets[changedIndex] = sampleSet;

			this.setState({ sampleSets: nextSampleSets });
		}
	}

	renderSampleSets() {
		const { sampleSets, saving, sampleSetIndex } = this.state;
		if (!sampleSets) return null;

		return (
			<SamplesList
				disabled={saving}
				activeIndex={sampleSetIndex}
				onSampleSelect={this.onSampleSetSelect}
				onSampleVisibility={this.onSampleSetVisibilityChange}
				samples={sampleSets}
			/>
		);
	}

	renderStats() {
		const { activeSampleSet, saving } = this.state;

		return activeSampleSet ? (
			<StatsList
				disabled={saving}
				onStatSelect={this.onStatSelect}
				onSampleSetChange={this.onSampleSetChange}
				sampleSet={activeSampleSet}
				stats={activeSampleSet.stats.concat()}
			/>
		) : null;
	}

	onCancelClick() {
		if (this.props.onCancelClick) {
			this.props.onCancelClick();
		}
	}

	onSave() {
		let { sampleSets, sampleSetIndex } = this.state;
		if (!sampleSets || sampleSets.length == 0) return;
		let changedSamples = [];
		sampleSets.forEach(s => {
			if (s.hasChanged) {
				s.metabolites = null;
				changedSamples.push(s);
			}
		});
		if (changedSamples.length === 0) return;
		this.props.setSaving(true);

		this.showToaster("Saving Sampleset Edits", "Saving pre-published visibility settings for Samplesets.");

		//activeSampleSet.metabolites = null;
		this.setState({ saving: true });
		this.state.logger.logProjectSave(this.state.project.projectId);
		this.props.saveDataAdmin({ "instance": changedSamples, "PortalUI: comment": "update" })
			.catch(() => {
				this.props.setSaving(false);
			})
			.then(result => {
				//console.log('SampleSet Saved?', result);
				this.updateProject(sampleSetIndex);
			});
	}

	logError(errormsg, errorCode) {
		//Log Error
		let logError = new Log();
		logError.SetLevel(enums.AppInsightLogLevel.ERROR);
		logError.SetName('ProjectContainer');
		logError.AddProperty('UserId', this.props.currentUser.data.userId);
		logError.AddProperty('ProjectId', this.state.project.projectId);
		logError.AddProperty('ErrorMsg', errormsg);
		logError.AddProperty('errorCode', errorCode);
		logger.doLog(logError);
	}

	render() {

		const { activeSampleSet, activeStats, current, project, sampleSets, metabolites, statsDict, studyType, isDownloading } = this.state;
		const stats = activeSampleSet && activeSampleSet.stats ? activeSampleSet.stats : [];
		const { useWorker } = this.props;

		let currDict = { [current]: statsDict[current] },
			currStats = [current];

		return (
			<div className={"AdminSamplesetsContainer"}>
				{super.render()}

				<div className="ActionButtons">
					<Button alt2={true} className="cancelButton" disabled={this.state.saveButtonDisabled} onClick={this.onCancelClick} >
						<Icon size={'normal'} color={'primary'} icon={'times'} />&nbsp;
						<label>Cancel</label>
					</Button>
					<Button alt2={true} className="saveButton" disabled={this.state.saveButtonDisabled} onClick={this.onSave} >
						<Icon size={'normal'} color={'primary'} icon={'save'} />&nbsp;
						<label>Save Changes</label>
					</Button>

				</div>
				<div className="list-container">

					{
						this.state.showSampleSets ? <div className="samples-wrapper">{this.renderSampleSets()}</div> : <LoadingSpinner />
					}
					<div className="stats-wrapper">{this.renderStats()}</div>
				</div>

				{/* {activeSampleSet && current && <MetaboliteTable
          canExport={false}
          className={"pathway-viz "+studyType}
          studyType={studyType}
          metabolites={metabolites}
          stats={currDict}
          currentStatsField={current}
          showHidden={true}
          useWorker={useWorker}
          activeStats={currStats}/>
        } */}
			</div>
		);
	}
}

AdminSamplesets.propTypes = {

};


AdminSamplesets.defaultProps = {
	fetchDataAdmin: (params) => Promise.resolve({}),
	saveDataAdmin: (params) => Promise.resolve({})
};

const mapStateToProps = state => {
	return {
		hasErrored: state.sampleSetData.samplesetDataHasErrored,
		isLoading: state.sampleSetData.sampleSetDataIsLoading,
		adminSampleSetData: state.sampleSetData.data
	};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchDataAdmin: (params) => dispatch(sampleSetFetchDataAdmin(params)),
		saveDataAdmin: (params) => dispatch(putAdminSampleSetSaveData(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSamplesets);



