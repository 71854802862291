'use strict';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './theme/theme.css';
import('./theme/css/material-icons.css');
import { MainLayout } from './views/layouts/mainLayout/MainLayout.jsx';
import UtilPage from './views/util/UtilContainer.jsx';
import Login from './views/login/Login.jsx';
import Faq from './views/faq/faq.jsx';
import TermsContainer from './views/terms/TermsOfServiceContainer.jsx';
import LandingContainer from './views/landing/LandingContainer.jsx';
import NotFound from './views/NotFound.jsx';
import { ErrorBoundary, DynamicModalSingleton } from './components/index.jsx';
import ScrollCalculator from './components/scroll/ScrollCalculator.jsx';
import RouteAuthorization from './security/RouteAuthorization.jsx';
import VerifyEmail from './views/VerifyEmail.jsx';
import StatsFetcher from './views/public/StatsFetcher.jsx';
import DiscoveryPanels from './views/discovery-panels/DiscoveryPanels.jsx';
import * as config from './config/env.config.js';
import * as permissions from './security/permissionTypes.js';
import { Resources } from './views/home/resources/Resources.jsx';
import { useAuth } from './hooks/auth.hook';
import Callback from './callback/Callback.jsx';
import SecurityCheck from './views/util/SecurityCheck.jsx';
import { Logger, Log } from './utils/Logger.js';
import { AppInsightLogLevel } from './enums/Enums.js';
const logger = new Logger();
import './App.scss';
import initializeAnalytics from './utils/AnalyticsTools.js';
import { useCurrentUser } from './redux/services/user.api.js';
import { setIsAuthenticated } from './redux/reducers/auth.reducer.js';

//Initialize our browser analytics
initializeAnalytics(config.DATADOG_TOKEN, config.DATADOG_URL, config.ENV, history);

/* istanbul ignore next */
const App = (props) => {
	const dispatch = useDispatch();
	const auth = useAuth();

	const { data: user, isLoading: userIsLoading, error } = useCurrentUser();

	useEffect(() => {
		if (user) dispatch(setIsAuthenticated(true));
	}, [user]);

	useEffect(() => {
		auth.isAuthenticated();
	}, []);

	useEffect(() => {
		const unlisten = props.history.listen((location, action) => {
			if (location.pathname.toLowerCase().indexOf("statstable") != -1) {
				logViewLoad("StatsTable");
			} else if (location.pathname.toLowerCase().indexOf("pathwayexplorer") != -1) {
				logViewLoad("PathwayExplorer");
			} else if (location.pathname.toLowerCase().indexOf("home/project") != -1) {
				logViewLoad("Results");
			}
			else if (location.pathname.toLowerCase().lastIndexOf("home") != -1) {
				logViewLoad("ProjectsHome");
			}
		});
		return unlisten;
	}, [location.pathname]);

	const logViewLoad = (viewName) => {
		//Log View
		let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageView_' + viewName);
		logFilterChange.AddProperty('view', viewName);
		logger.doLog(logFilterChange);
	};

	/** Render Router components, ignoring routes */
	const renderLandingPage = ({ match }) => { return <LandingContainer match={match} auth={auth} {...props} />; };
	const renderUtilPage = ({ match }) => { return <UtilPage match={match} auth={auth} {...props} />; };
	const renderTerms = ({ match }) => { return <TermsContainer match={match} auth={auth} {...props} />; };
	const renderResources = () => { return <Resources {...props} />; };
	const renderNotFound = ({ match }) => { return <NotFound auth={auth} {...props} />; };
	const renderFAQ = ({ match }) => { return <Faq match={match} auth={auth} {...props} />; };
	const renderVerifyEmail = ({ match }) => { return <VerifyEmail match={match} auth={auth} {...props} />; };
	const renderStats = ({ match }) => { return <StatsFetcher match={match} auth={auth} {...props} />; };

	const { match } = props;
	if (userIsLoading) return null;
	return (
		<BrowserRouter>
			<ErrorBoundary history={props.history}>
				<ScrollCalculator />
				<Root>
					<Switch>
						<Route path={"/"} exact render={renderLandingPage} />
						<Route path={"/home"} match={match} component={AuthMain} />
						<Route path={"/version"} match={match} component={AuthMain} />
						<Route path={"/errortest"} match={match} component={AuthMain} />
						<Route path={"/discovery-panels/:projectId/:productType/:sampleSetId"} match={match} component={AuthSP} />
						<Route path={"/login"} render={function (props) {
							return <Login {...props} />;
						}} />
						<Route path={"/callback"} render={function (props) {
							return <Callback currentUser={user} {...props} />;
						}} />
						<Route path={"/securitycheck"}>
							<SecurityCheck user={user} isLoading={userIsLoading} error={error} />
						</Route>
						<Route path={"/terms"} exact render={renderTerms} />
						<Route path={"/help"} exact render={renderFAQ} />
						<Route path={"/verifyemail"} exact render={renderVerifyEmail} />
						<Route path={"/resources"} exact render={renderResources} />
						<Route path={"/stats/table"} exact render={renderStats} />
						{/*<Route path={"/utils"} exact render={renderUtilPage} />*/}
						<Route render={renderNotFound} />
					</Switch>
				</Root>
				<DynamicModalSingleton />
			</ErrorBoundary>
		</BrowserRouter>
	);
	 
};

const AuthMain = (props) => {
	const allowedPermissions = permissions.CAN_VIEW_APERTURE;
	return (
		<RouteAuthorization
			allowedPermissions={allowedPermissions}
			WrappedComponent={MainLayout}
			{...props}
		/>
	);
};

const AuthSP = (props) => {
	const allowedPermissions = permissions.CAN_VIEW_APERTURE;
	return (
		<RouteAuthorization
			allowedPermissions={allowedPermissions}
			WrappedComponent={DiscoveryPanels}
			{...props}
		/>
	);
};



const Root = (props) => (
	<div className="app" data-testid="app" {...props} />
);

export default App;
