"use strict";

import { useCallback } from "react";
import * as enums from "../enums/Enums";
import * as config from "../config/env.config";
import { useUserDetails } from "./useUserDetails";
import { useAuditLogger } from "@lenses/uilenses/hooks/auditLogger/auditLogger.hook";

export const useLocalAuditLogger = () => {
	const userDetails = useUserDetails();
  const { doAuditLog, setLogoutAccessToken } = useAuditLogger(userDetails, config, enums);

  const logNewUser = useCallback(() => {
    doAuditLog(enums.AUDITLOG_LOGTYPES.USER_CREATE);
  }, [doAuditLog]);

  const logOutUser = useCallback((userActionType, accessToken) => {
    setLogoutAccessToken(accessToken);
    const primaryData = { action: userActionType };
    return doAuditLog(enums.AUDITLOG_LOGTYPES.USER_ACTION, primaryData);
  }, [doAuditLog]);

  const logUserAction = useCallback((userActionType) => {
    const primaryData = { action: userActionType };
    doAuditLog(enums.AUDITLOG_LOGTYPES.USER_ACTION, primaryData);
  }, [doAuditLog]);

  /*Publishing Center Logging 
  */

  const logProjectSave = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.SAVE, primaryData);
  }, [doAuditLog]);

  const logProjectPublish = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.PUBLISH, primaryData);
  }, [doAuditLog]);

  const logProjectAddContact = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.ADD_CONTACT, primaryData);
  }, [doAuditLog]);

  const logProjectRemoveContact = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.REMOVE_CONTACT, primaryData);
  }, [doAuditLog]);

  const logProjectSaveTitle = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.SAVE_TITLE, primaryData);
  }, [doAuditLog]);

  const logProjectSaveSynopsis = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.SAVE_SYNOPSIS, primaryData);
  }, [doAuditLog]);

  const logProjectSaveConclusion = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_PROJECTACTIONS_TYPES.SAVE_CONCLUSION, primaryData);
  }, [doAuditLog]);

  const logNewProject = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_LOGTYPES.PROJECT_CREATE, primaryData);
  }, [doAuditLog]);

  const logProjectView = useCallback((projectId) => {
    const primaryData = { projectId: projectId };
    doAuditLog(enums.AUDITLOG_LOGTYPES.PROJECT_VIEW, primaryData);
  }, [doAuditLog]);

  const logProjectFileDownload = useCallback((projectId, listOfFileNames) => {
    const primaryData = { projectId: projectId };
    const metadata = { files: listOfFileNames };
    doAuditLog(enums.AUDITLOG_LOGTYPES.PROJECT_DOWNLOAD, primaryData, metadata);
  }, [doAuditLog]);

  const logExportStatsTable = useCallback((projectId, filename) => {
    const primaryData = { projectId: projectId };
    const metadata = { fileName: filename };
    doAuditLog(enums.AUDITLOG_LOGTYPES.PROJECT_DOWNLOAD, primaryData, metadata);
  }, [doAuditLog]);

  const logExportCytoscapteImage = useCallback((projectId, imageType) => {
    const primaryData = { projectId: projectId };
    const metadata = { imageType: imageType };
    doAuditLog(enums.AUDITLOG_LOGTYPES.PROJECT_DOWNLOAD, primaryData, metadata);
  }, [doAuditLog]);

  const logUsermanagmentEdit = useCallback(
    (userManagementAction, userObject) => {
      const primaryData = { action: userManagementAction };
      const metadata = { user: userObject };
      return doAuditLog(
        enums.AUDITLOG_LOGTYPES.USER_ACTION,
        primaryData,
        metadata
      );
    }, [doAuditLog]);

  const logUserManagementCreate = useCallback(
    (userManagementAction, userObject) => {
      const primaryData = { action: userManagementAction };
      const metadata = { user: userObject };
      return doAuditLog(
        enums.AUDITLOG_LOGTYPES.USER_CREATE,
        primaryData,
        metadata
      );
    }, [doAuditLog]);

  const logProjectPermissions = useCallback(
    (permissionStatus, projectId, grantUserId) => {
      const primaryData = {
        grantUserId: grantUserId,
        projectId: projectId,
        permissionStatus: permissionStatus,
      };
      return doAuditLog(
        enums.AUDITLOG_LOGTYPES.PROJECT_PERMISSIONS,
        primaryData
      );
    },[doAuditLog]);

    return {
      logNewUser,
      logOutUser,
      logUserAction,
      logNewProject,
      logProjectView,
      logProjectFileDownload,
      logExportStatsTable,
      logExportCytoscapteImage,
      logUsermanagmentEdit,
      logUserManagementCreate,
      logProjectPermissions,
      logProjectSave, 
      logProjectPublish,
      logProjectAddContact,
      logProjectRemoveContact,
      logProjectSaveTitle,
      logProjectSaveSynopsis,
      logProjectSaveConclusion
    };
};