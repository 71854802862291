import { fetchAndDownloadManifestTemplate } from "@/discovery-panel/api";
import { Stack } from "@mui/material";
import { Button } from "metabux/components/Button/Button";
import React, { useCallback } from "react";

export const Resources = ({ resources, projectId }) => {
  const downloadTemplate = useCallback(async () => {
    await fetchAndDownloadManifestTemplate(projectId);
  }, [projectId]);

  return (
    <Stack spacing={2} mt={20} direction='column' alignItems='center'>
        <Button
            target='_blank'
            key="resources-download-template"
            onClick={downloadTemplate}
            name="Download Manifest Template"
            sx={{ width: "50%" }}
          >
            Download Manifest Template
          </Button>
      {resources.map((resource) => {
        return (
          <Button
            target='_blank'
            key={resource.payload}
            href={resource.payload}
            name={resource.name}
            sx={{ width: "50%", "&:hover": {color: "white"} }}
          >
            {resource.label}
          </Button>
        );
      })}
    </Stack>
  );
};
