'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import DropdownContainer from './DropdownContainer.jsx';
import Icon from '../components/controls/Icon.jsx';
import './StatsLegend.scss';

export const StatsLegend = ({ className, studyType, ...props }) => {
	let children;
	if (studyType && studyType.indexOf('zscore') >= 0) {
		children = [
			<Icon key={0} size="large" color="secondary" icon="info-circle" />,
			<div key={1} className="zscore">
				<p className="normal secondary"><strong>Legend</strong></p>
				<p className="medium box sigUp">Up z &ge; 2</p>
				<p className="medium box trendUp">Up z &ge; 1.5 z &lt; 2</p>
				<p className="medium box sigDown">Down z &le; -2</p>
				<p className="medium box trendDown">Down z &le; -1.5 z &gt; -2</p>
			</div>
		];
	} else {
		children = [
			<Icon key={0} size="large" color="secondary" icon="info-circle" />,
			<div key={1} className="">
				<p className="normal secondary"><strong>Legend</strong></p>
				<p className="medium box sigUp">Up p &le; 0.05</p>
				<p className="medium box trendUp">Up 0.05 &lt; p &lt; 0.10</p>
				<p className="medium box sigDown">Down p &le; 0.05</p>
				<p className="medium box trendDown">Down 0.05 &lt; p &lt; 0.10</p>
			</div>
		];
	}

	return (
		<DropdownContainer className={`StatsLegend ${className} ${studyType}`} {...props}>
			{children}
		</DropdownContainer>
	)
}

export default StatsLegend

StatsLegend.propTypes = {

	/** Whether a caret is appended to the dropdown label */
	caret: PropTypes.bool,

	/** The color of the dropdown button */
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The size of the dropdown button */
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

	/** Whether the dropdown button is disabled */
	disabled: PropTypes.bool,

	/** Whether the dropdown button is in alt-color mode */
	alt: PropTypes.bool,

	/** Whether the dropdown button is in alt2-color mode */
	alt2: PropTypes.bool,

	/** type attribute applied to the dropdown button */
	type: PropTypes.string,
};

StatsLegend.defaultProps = {
	caret: false,
	size: DropdownContainer.defaultProps.size,
	color: 'default',//DropdownContainer.defaultProps.color,
	disabled: DropdownContainer.defaultProps.disabled,
	alt: DropdownContainer.defaultProps.alt,
	alt2: true,
	title: 'Legend',
	type: DropdownContainer.defaultProps.type,

};