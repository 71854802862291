import React, { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getDocStyle from "../../utils/getDocStyle";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";

const Square = ({
  path,
  fill,
  stroke,
  opacity,
  className,
  chemicalId,
  metabolite,
  comparison,
  id,
  handleEvents,
}) => {
  const { mobile, smallScreen } = useSelector(viewPortSelector);

  const strokeSize = mobile ? 0 : smallScreen ? 1 : 2;

  return (
    <path
      d={path}
      stroke={stroke}
      strokeWidth={stroke === "white" ? strokeSize : 1}
      shapeRendering={stroke === "white" ? "optimizeSpeed" : "auto"}
      fill={getDocStyle(fill) || fill}
      opacity={opacity}
      className={className}
      id={id}
      style={{ willChange: "opacity, d" }}
      onMouseEnter={handleEvents}
      onMouseLeave={handleEvents}
      onClick={handleEvents}
      data-value={chemicalId}
      data-type={"metabolite"}
      data-source={"heatmap"}
      data-name={metabolite}
      data-comparison={comparison}
    />
  );
};

Square.propTypes = {
  path: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  opacity: PropTypes.number,
  className: PropTypes.string,
  chemicalId: PropTypes.string,
};

Square.defaultProps = {
  path: "",
  fill: "white",
  stroke: "white",
  opacity: 1,
  className: "",
  chemicalId: "",
};

export default memo(Square);
