export default function  detectMainEffect(d, pValue) {
    const data = d.main_effects;
    let effects, labels;
    if  (data.length > 0) {
     const filter = data.filter( (e) => {
        return  e.p_value <= pValue;
      });
    effects = filter.length > 0 ? true : false;
    labels = filter.length > 0 ? filter.map((e)=> {return e.name;}).join(", ") : "none";
    } else {
      effects = false;
      labels = "none";
    }
     return {significant: effects, significant_label: labels };
  }