export default [{
    "projectId" : 1,
    "projectCode" : "ABCD-01-23EF+",
    "clients":[
      {id:0, shortName: '3VBI', fullName: '3V Biosciences Inc.', type: 'none' },
      {id:1, shortName: 'AAUH', fullName: 'Aalborg University Hospital blah blah 1', type: 'none' }
    ],
    "investigators":[
      {id:0, firstName: 'Emmett', lastName: 'Brown', suffix:'Ph.D', email:'', phone:''},
      {id:1, firstName: 'Rick', lastName: 'Sanchez', suffix:'', email:'', phone:''},
    ],
    "projectManagers":[
      {id:0, firstName: 'Commercial', lastName: 'Team', suffix:'', type:'group' },
      {id:1, firstName: 'Academic', lastName: 'Team', suffix:'', type:'group' },
      {id:2, firstName: 'Bill', lastName: 'Lumbergh', suffix:'', type:'individual' },
    ],
    "salesPersons":[
      {id:3, firstName: 'Kurt', lastName: 'Vonnegut', suffix:''  },
    ]
  }];

  export const discovery_panel = {
    "projectId": "1560905a-535a-483d-90f4-268e652168d6",
    "projectTitle": "LEIU-01-19VW_PHNX (Metabolic analysis of resistant and susceptible Chrysanthemum leaves)",
    "customerProjectTitle": null,
    "synopsis": "The goal of this study was to identify metabolomic differences between chrysanthemum plants that are resistant or sensitive to thrips.",
    "customerSynopsis": null,
    "conclusion": "The results of this study show widespread effects of leaf age on metabolite abundances that indicate reduced nutritional value of older leaves, as well as changes in some secondary metabolites.  Although the metabolic effects of leaf age were more apparent in this study, many metabolic effects of resistance status were also discernible.  These effects converged on secondary metabolites that may directly affect insect resistance.  Likewise, several signaling molecules were altered with resistance status, and these molecules may further affect insect resistance through their signaling pathways.  Future experiments could aim to recapitulate insect resistance phenotypes through application of candidate metabolites that may impact insect resistance, either applied individually or in combination.  Likewise, genes encoding the enzymes that generate these metabolites may be targets for improving insect resistance.",
    "customerConclusion": null,
    "projectCode": "LEIU-01-19VW_PHNX",
    "productType": null,
    "principalInvestigatorName": null,
    "principalInvestigatorEmail": null,
    "stakeHolders": [],
    "projectSource": {
        "type": "test",
        "location": "Raleigh"
    },
    "publishDate": "2022-03-07T15:00:45.3279902Z",
    "lastPublishDate": "2022-03-07T15:00:45.3279902Z",
    "lastUpdateDate": "2022-03-07T15:00:45.3279902Z"
};
