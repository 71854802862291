import { createSlice, current } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import dayjs from 'dayjs';
import { OTHER } from "metabux/components/ManifestUploadStepper/manifest.constants";

export const mockData = {
	isValid: false,
	manifests: [
		{ manifestFileId: 0, fileName: 'fileA.xlsx', shippingProvider: "UPS", trackingNumber: 0 },
		{ manifestFileId: 1, fileName: 'fileB.xlsx', shippingProvider: "USPS", trackingNumber: 1 },
		{ manifestFileId: 2, fileName: 'fileC.xlsx', shippingProvider: "DHL", trackingNumber: 2 },
		{ manifestFileId: 3, fileName: 'fileD.xlsx', shippingProvider: "Fedex", trackingNumber: 3 },
		{ manifestFileId: 4, fileName: 'fileE.xlsx', shippingProvider: "UPS", trackingNumber: 4 },
	],
	date: null,
	shipDate: null,
	shipDateChangeReason: 'init',
	manifestUpload: false,
};

export const getManifestId = (item) => `${item.manifestFileId}-${item.fileName}`;
export const validateProvider = (item) => {
	let result = true;
	const { shippingProvider } = item;
	if (!shippingProvider ||
		shippingProvider.indexOf(OTHER) === 0 && shippingProvider.length < 7) result = false;
	return result;
};
export const validateItem = (item) => !(validateProvider(item) && item.trackingNumber);
export const getInvalidItems = (items) => {
	if (!items) return {};
	return Object.values(items).filter(validateItem);
};

export const getInitialState = () => ({
	shipDate: null,
	newDate: null,
	dateChangeReason: '',
	manifestUpload: false,
	manifests: [],
	updatedManifests: [],
	subStep: 0,
	current: 0,
	isValid: false,
});

export const coeSlice = createSlice({
	name: 'coe',
	initialState: getInitialState(),
	reducers: {
		setOnboardingState: (state, action) => {
			const { shipDate, manifestUpload, shipDateChangeReason } = action.payload;
			state.shipDate = shipDate;
			state.newDate = shipDate || dayjs().format();
			state.manifestUpload = manifestUpload;
			state.dateChangeReason = shipDateChangeReason;
		},
		setManifestUpload: (state, action) => {
			state.manifestUpload = action.payload;
		},
		setShipDate: (state, action) => {
			state.shipDate = action.payload;
		},
		setNewDate: (state, action) => {
			state.newDate = action.payload;
		},
		setDateChangeReason: (state, action) => {
			state.dateChangeReason = action.payload;
		},
		setManifests: (state, action) => {
			state.manifests = keyBy(action.payload, getManifestId);
		},
		updateManifest: (state, action) => {
			const manifest = action.payload;
			const { manifests, updatedManifests } = current(state);
			const manifestId = getManifestId(manifest);

			state.manifests = { ...manifests, [manifestId]: manifest };
			if (updatedManifests.indexOf(manifestId) <= 0)
				state.updatedManifests = [...updatedManifests, manifestId];
		},
		setCurrent: (state, action) => {
			state.current = action.payload;
			state.isValid = false;
			state.subStep = 0;
		},
		setSubStep: (state, action) => {
			state.subStep = action.payload;
		},
		setIsValid: (state, action) => {
			state.isValid = action.payload;
		},
		clearManifestUpdates: (state) => {
			state.updatedManifests = [];
		},
	}
});
export const { setManifestUpload, setShipDate, setNewDate, setDateChangeReason, setOnboardingState, setManifests, setCurrent, setSubStep, setIsValid, updateManifest, clearManifestUpdates } = coeSlice.actions;
export const coeReducer = coeSlice.reducer;
