'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import Button from '../buttons/Button.jsx';
import Icon from './Icon.jsx';
import './Dropdown.scss';

class Dropdown extends Component {
	constructor(props) {
		super(props);
	}

	generateClassNames() {
		const {className} = this.props;
		let classNames = [this.constructor.name];
		if(className) classNames.push(className);
		if(this.props.isOpen) classNames.push('open');

		return classNames;
	}

	get isOpen() {
		return this.props.isOpen;
	}

	onClick = () => {
		if(this.props.onClick) this.props.onClick();
	};

	onBlur = (e) => {
		if(this.props.onBlur) this.props.onBlur(e);
	};

	onFocus = () => {
		if(this.props.onFocus) this.props.onFocus();
	};

	renderTrigger = (selection) => {
		const { triggerClass, triggerRenderer, size, color, disabled, alt, alt2, type, isOpen } = this.props;
		const triggerProps = { size, color, disabled, alt, alt2, type, className:classnames("Dropdown-trigger", triggerClass) };

		if(triggerRenderer) return triggerRenderer(isOpen, this.onClick, triggerProps, selection);

		let caret;
		if(this.props.caret) {
			caret = <Icon icon={this.props.isOpen?"caret-up":"caret-down"}/>;
		}

		return (
			<Button {...triggerProps} onClick={this.onClick} testId="dropdown-trigger">{selection} {caret}</Button>
		);
	};

	render() {
		const { title, id, renderLabel } = this.props;
		let label;
		let children = React.Children.toArray(this.props.children);
		if(renderLabel) {
			label = children.shift();		
		}
		return (
			<div data-testid="dropdown" title={title} id={id} onBlur={this.onBlur} onFocus={this.onFocus} className={this.generateClassNames().join(' ')} >
				{this.renderTrigger(label)}
				
				<div data-testid="dropdown-menu" className="Dropdown-menu">
					{children}
				</div>
			</div>
		);
	}
}

Dropdown.propTypes = {

	/** Whether a caret is appended to the dropdown label */
	caret: PropTypes.bool,

	/** Whether the dropdown is currently open */
	isOpen: PropTypes.bool,

	/** The color of the dropdown button */
	color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

	/** The size of the dropdown button */
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),

	/** Dropdown button label followed by dropdown items*/
	children: PropTypes.node.isRequired,

	/** Whether the dropdown button is disabled */
	disabled: PropTypes.bool,

	/** Whether the dropdown button is in alt-color mode */
	alt: PropTypes.bool,

	/** Whether the dropdown button is in alt2-color mode */
	alt2: PropTypes.bool,

	/** type attribute applied to the dropdown button */
	type: PropTypes.string,

	/** Invoked when the dropdown button is clicked */
	onClick: PropTypes.func,

	/** Invoked when the dropdown button is blurred */
	onBlur: PropTypes.func,

	/** Invoked when the dropdown button is focused */
	onFocus: PropTypes.func,

	/** Whether to render the label */
	renderLabel: PropTypes.bool,
};

Dropdown.defaultProps = {
	caret: true,
	isOpen: false,
	size: Button.defaultProps.size,
	color: Button.defaultProps.color,
	disabled: Button.defaultProps.disabled,
	alt: Button.defaultProps.alt,
	alt2: Button.defaultProps.alt2,
	renderLabel: true,
	type: Button.defaultProps.type,
	 
	onClick: /* istanbul ignore next */ () => { console.log('Dropdown custom onClick callback not defined!'); },
	 
};

export default Dropdown;