import html2canvas from 'html2canvas';
import FileUtils from './FileUtils';

export default class HTMLConverter {
	static toCanvas = (el, options, onComplete) => {
		html2canvas(el, options).then((canvas) => {
			if(onComplete) onComplete(canvas);
		});
	};

	static toPNG = (el, options, onComplete) => {
		HTMLConverter.toCanvas(el, options, (canvas) => {
			const imgData = canvas.toBlob((blob) => {
				if(onComplete) onComplete(blob);
			},'image/png');
		});
	};

	static toJPG = (el, options, onComplete) => {
		HTMLConverter.toCanvas(el, options, (canvas) => {
			const imgData = canvas.toBlob((blob) => {
				if(onComplete) onComplete(blob);
			},'image/jpg', 1);
		});
	};

	static downloadPNG = (el, options, filename) => {
		HTMLConverter.toPNG(el, options, (imgData) => {		
			FileUtils.download(imgData, filename+'.png', 'png');
		});
	};

	static downloadJPG = (el, options, filename) => {
		HTMLConverter.toJPG(el, options, (imgData) => {		
			FileUtils.download(imgData, filename+'.jpg', 'jpg');
		});
	};

	static downloadSVG = (el, options, filename) => {
		HTMLConverter.toJPG(el, options, (imgData) => {		
			FileUtils.download(imgData, filename+'.svg', 'svg');
		});
	};
}