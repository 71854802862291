import OrderingSamplePdf from "./resources/Sample Handling Kit Instructions - SP031 Att1 v1.2.pdf";
import SampleManifestPdf from "./resources/Completing Manifest Directions SP031 Att2 v1.2.pdf";
import SamplePreparationPdf from "./resources/Metabolon-SampleSubmissionGuide-SSGv3.1-1.pdf";

export const resources = [
  {
    label: "Do I need an import permit",
    payload: "https://www.cdc.gov/orr/ipp/etool.htm",
    name: "ordering-sample-kits",
  },
  {
    label: "Sample Manifest",
    payload: SampleManifestPdf,
    name: "sample-manifest",
  },
  {
    label: "Sample Preparation",
    payload: SamplePreparationPdf,
    name: "sample-preparation",
  },
  {
    label: "Ordering Sample Kits",
    payload: OrderingSamplePdf,
    name: "ordering-sample-kits",
  },
];