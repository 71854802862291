import React, { useEffect } from 'react';
import Link from '@/components/buttons/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getToken } from '@/middleware/requests.js';
import { useSelector } from 'react-redux';
import { sampleSetDataCurrentSelector, sampleSetDataDataSelector } from '@/redux/selectors/sampleSetData.selector.js';
import { useBiovizStatus } from '../../lenses/uilenses/hooks/biovizStatus/biovizStatus.hook.js';
import Cookies from 'universal-cookie';

export const BinfButton = ({ ...props }) => {
  const domain = window.location.hostname === "localhost" ? "localhost" : "metabolon.com";
  const sampleSetData = useSelector(sampleSetDataDataSelector);
  const current = useSelector(sampleSetDataCurrentSelector);
  const projectId = sampleSetData[current]?.projectId;
  const sampleSetId = sampleSetData[current]?.sampleSetId;
	const {status: biovizStatus, href} = useBiovizStatus(projectId);
	const db = new Cookies();

  useEffect(() => {
		db.set(`binf_auth_cookie`, getToken(), {
			path:'/',
			sameSite:'strict',
			domain,
			secure: true
		});
  }, []);
  if (!biovizStatus) return null;

  return (
    <Link href={href} target="_self" rel="noopener noreferrer" underline="none" {...props} >BioInformatics {<OpenInNewIcon color="white" viewBox={"0 0 30 30"} />}</Link>);
};

export default BinfButton;