// extracted by mini-css-extract-plugin
var _1 = "defaultprofile_Biologicalstress";
var _2 = "defaultprofile_KidneyFunction";
var _3 = "defaultprofile_LiverFibrosis";
var _4 = "defaultprofile_Microbiome";
var _5 = "defaultprofile_defaultProfile";
var _6 = "defaultprofile_flowChart";
var _7 = "defaultprofile_tables";
var _8 = "defaultprofile_wrapper";
export { _1 as "Biologicalstress", _2 as "KidneyFunction", _3 as "LiverFibrosis", _4 as "Microbiome", _5 as "defaultProfile", _6 as "flowChart", _7 as "tables", _8 as "wrapper" }
