import { current } from "@reduxjs/toolkit";

import { groups, scaleBand, ascending } from "d3";

import mapToArray from "../../utils/mapToArray";
import getCoords from "../../utils/getCoords";

export const initializeExplorer = () => ({
  metaboliteCoords: [],
  associationCoords: [],
  combinedCoords: [],
  groupList: [],
});

export const explorerReducer = () => ({
  updateGroupList: (state) => {
    const currentState = current(state);
    const { group } = currentState.filter;
    const metabolites = currentState.metabolites.list.concat();
    const sortedMetabolites = metabolites.sort((a, b) =>
      ascending(a[group], b[group])
    );

    state.explorer.groupList = 
      groups(sortedMetabolites, (d) => d[group] || "none");
  },

  updateAssociationCoords: (state) => {
    const currentState = current(state);
    const { viewPort } = currentState;
    const { groupList } = currentState.explorer;
    const associations = currentState.associations.list.concat();
    const { association } = currentState.filter;

    const filterAssociations = associations.filter(
      (d) => d.type === association
    );
  
    const list = mapToArray(groups(filterAssociations, (d) => d.association));
  
    const gapNormalizer = ((groupList.length / 2) * Math.PI) / 180;
    const middleSpaceNormalizer = viewPort.middleSpace * 2.05;
  
    state.explorer.associationCoords = list.map((d, i) => {
      const coords = getCoords(
        i,
        list.length,
        viewPort.associationRadius,
        (groupList.length * viewPort.middleSpace) / 2,
        middleSpaceNormalizer,
        gapNormalizer,
        viewPort
      );
  
      return {
        ...d.value[0],
        association: d.key,
        x: coords.x,
        y: coords.y,
        angle: coords.angle,
        metabolites: d.value,
      };
    });
  },
  updateMetaboliteCoords: (state) => {
    const currentState = current(state);
    const { viewPort } = currentState;
    const { groupList } = currentState.explorer;
    const metabolites = currentState.metabolites.list.concat();
    const { group } = currentState.filter;

    const sortedMetabolites = metabolites.sort((a, b) =>
      ascending(a[group], b[group])
    );

    const scaleGroup = scaleBand()
      .domain(groupList.map((d) => d[0]))
      .range([0, groupList.length]);

    const gapNormalizer = ((groupList.length / 2) * Math.PI) / 180;
    const middleSpaceNormalizer = viewPort.middleSpace * 2.05;

    state.explorer.metaboliteCoords = sortedMetabolites.map((d, i) => {
      const coords = getCoords(
        i,
        metabolites.length,
        viewPort.metabolitesRadius,
        scaleGroup(d[group]),
        middleSpaceNormalizer,
        gapNormalizer,
        viewPort
      );

      return {
        ...d,
        x: coords.x,
        y: coords.y,
        angle: coords.angle,
      };
    });
  },
  updateCombinedCoords: (state) => {
    const currentState = current(state);
    const { associationCoords, metaboliteCoords } = currentState.explorer;
    const { list: associations } = currentState.associations;
    const { association } = currentState.filter;

    const filterAssociations = associations.filter(
      (a) => a.type === association
    );
		
    const combinedCoords = [];
		
		filterAssociations.forEach((d) => {
      const coords_metabolite = metaboliteCoords.find((e) => {
        return e.chemical_id === d.chemical_id;
      });
			if (!coords_metabolite) return;

  
      const coords_association = associationCoords.find((e) => {
        return e.association === d.association;
      });
  
      const difference = Math.abs(
        coords_metabolite.angle - coords_association.angle
      );
  
      const direction =
        (coords_metabolite.angle < coords_association.angle &&
          difference <= Math.PI) ||
        (coords_metabolite.angle > coords_association.angle &&
          difference > Math.PI)
          ? 1
          : -1;
  
      combinedCoords.push({
        ...d,
        coords_metabolite: coords_metabolite,
        coords_association: coords_association,
        sort: difference > Math.PI ? 2 * Math.PI - difference : difference,
        direction,
      });
    });

		state.explorer.combinedCoords = combinedCoords;
  },
});