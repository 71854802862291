import urljoin from 'url-join';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';

export const adminApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		user: builder.query({
			query: (email) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", email),
				method: 'GET',
			}),
			providesTags: (result, err, arg) => ['user', { type: 'user', id: arg }]
		}),
		fetchUser: builder.mutation({
			query: (email) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", email),
				method: 'GET',
			}),
		}),
		publishUser: builder.mutation({
			query: (user, comment) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "publish"),
				body: { instance: { ...user }, comment },
				method: 'PUT',
			}),
			invalidatesTags: (result, error, arg) => [
				'roles',
				'projects',
				{ type: 'user', id: arg?.email }
			]
		}),
		roles: builder.query({
			query: () => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "roles"),
				method: 'GET',
			}),
			providesTags: ['roles']
		}),
		managedProjects: builder.query({
			query: (projectId = '') => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "projects", projectId),
				method: 'GET',
			}),
			providesTags: ['projects']
		}),
		projectContacts: builder.query({
			query: (projectId) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "project", projectId, "queue"),
				method: 'GET',
			}),
			providesTags: ['contacts']
		}),
		addProjectContact: builder.mutation({
			query: ({ projectId, ...user }) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "project", projectId, "queue", "add"),
				method: 'POST',
				body: user
			}),
			invalidatesTags: ['contacts', 'projects']
		}),
		removeProjectContact: builder.mutation({
			query: ({ projectId, email }) => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin("admin", "users", "project", projectId, "queue", "remove", email),
				method: 'POST',
			}),
			invalidatesTags: ['projects', 'dp-projects', 'contacts']
		}),
	}),
});

export const {
	useUserQuery,//fetchUserByEmail
	useFetchUserMutation,
	usePublishUserMutation,//managedUsersUpdateData
	useRolesQuery,//managedUsersFetchAvailableRoles
	useManagedProjectsQuery,//managedProjectsFetchData
	useProjectContactsQuery,//fetchUnpublishedProjectUsers
	useAddProjectContactMutation,//managedUsersUpdateData
	useRemoveProjectContactMutation,//unpublishUser
} = adminApi;