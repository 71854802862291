import urljoin from 'url-join';

export const ENV = "beta";
export const API_BASEPATH = "https://apibeta.metabolon.com/api/v1";
export const API_AUDITTRAIL_BASEPATH = "https://betaaudittrailservice.azurewebsites.net/api";
export const DATADOG_TOKEN = "pub7a985fabd02f77bc806f6d94b9505c6e";
export const AUTH_DOMAIN = "auth0-dev.metabolon.com";
export const AUTH_CLIENTID = "643L16b94CjMhTNuhcDWIxrB0JQjWuNe";
export const AUTH_CALLBACKURL = "https://portalbeta.metabolon.com/callback";
export const IDLETIMER_IDLESTATUS_AWAY = 1;
export const IDLETIMER_IDLESTATUS_INACTIVE = 1;
export const IDLETIMER_IDLESTATUS_EXPIRED = 840000;
export const IDLETIMER_COUNTDOWN = 30;
export const URL_SURVEYOR_SSO = "https://surveyor-dev.metabolon.com/sso";
export const API_METAIMD_DOWNLOAD = "https://metafilesgeneratorbeta.metabolon.com/api/v1/metaimd";
export const API_BIOVIZ = "https://globalviz-dev.metabolon.com";
export const ONE_SCHEMA_CLIENT_ID = "61a3a136-b248-44f6-9e74-1304020a7af0";
export const ONE_SCHEMA_TEMPLATE_KEY = "sample_manifest_template";
export const ONE_SCHEMA_SECRET = "QXNffVZ2p255NCynfRMrmQgudFQiVBRpIPFbKx+huCk=";


console.log("ENV", ENV, process.env.NODE_ENV);

//export const ENV = process.env.NODE_ENV;

/**API Hosts */
//export const API_BASEPATH = "https://apibeta.metabolon.com/api/v1";

/**Hub Hosts */
export const HUBS_BASEPATH = API_BASEPATH.replace("api/v1", "hubs");

/** Manifest upload */
export const API_MANIFEST_UPLOAD = `${API_BASEPATH}/manifest/file/upload`;
export const API_MANIFEST_DOWNLOAD = `${API_BASEPATH}/manifest/file/download`;
export const API_MANIFEST_GET_JWT = `${API_BASEPATH}/manifest/oneschema/token`;

/** Audit Trail */
//export const API_AUDITTRAIL_BASEPATH = "https://betaaudittrailservice.azurewebsites.net/api";
export const API_AUDITTRAIL_USER_ACTION = urljoin(`${API_AUDITTRAIL_BASEPATH}`, 'user/action');
export const API_AUDITTRAIL_USER = urljoin(`${API_AUDITTRAIL_BASEPATH}`, 'user');
export const API_AUDITTRAIL_PROJECT_VIEW = urljoin(`${API_AUDITTRAIL_BASEPATH}`, 'project/view');
export const API_AUDITTRAIL_PROJECT_DOWNLOAD = urljoin(`${API_AUDITTRAIL_BASEPATH}`, 'project/download');
export const API_AUDITTRAIL_PROJECT_PERMISSIONS = urljoin(`${API_AUDITTRAIL_BASEPATH}`, 'user/permission/project');

/**Console Log */
export const CONSOLELOG_REDUXACTIONS = false;
export const CONSOLELOG_IDLEACTIONS = false;

/** Authentication Configuration */

//export const AUTH_DOMAIN = "auth0-dev.metabolon.com";
//export const AUTH_CLIENTID = "643L16b94CjMhTNuhcDWIxrB0JQjWuNe";

//export const AUTH_CLIENTID = "vfZvdsz0h0DpSIC8bhjLBU953Dtul6Yt"; 
//export const AUTH_DOMAIN = "auth0.metabolon.com";
//export const AUTH_CALLBACKURL = `https://portalbeta.metabolon.com/callback`;
//export const AUTH_CALLBACKURL = `http://localhost:3000/callback`;
export const AUTH_CONTAINER = "auth0-login-container";
export const AUTH_DBCONNECTIONNAME = "Username-Password-Authentication";


/** Idle Timer Config */
//export const IDLETIMER_IDLESTATUS_AWAY = 1;
//export const IDLETIMER_IDLESTATUS_INACTIVE = 1;
//export const IDLETIMER_IDLESTATUS_EXPIRED = 900000; /* 15 minutes */
//export const IDLETIMER_COUNTDOWN = 30;



/**OneSchema */
//export const ONE_SCHEMA_CLIENT_ID = '61a3a136-b248-44f6-9e74-1304020a7af0';
//export const ONE_SCHEMA_TEMPLATE_KEY = 'sample_manifest_template';
export const ONE_SCHEMA_WEBHOOK_KEY = ENV;
//export const ONE_SCHEMA_SECRET = 'QXNffVZ2p255NCynfRMrmQgudFQiVBRpIPFbKx+huCk=';

/**Data Dog */
//export const DATADOG_TOKEN = "pub7a985fabd02f77bc806f6d94b9505c6e";
export const DATADOG_URL = "datadoghq.com";

/**SurveyorURL */
//export const URL_SURVEYOR_SSO = "https://surveyor-dev.metabolon.com/sso";

/**Endpoints  */
export const API_ENDPOINT_FILES = urljoin(`${API_BASEPATH}`, 'files/project/');
export const API_ENDPOINT_FILES_DOWNLOAD = urljoin(`${API_BASEPATH}`, 'files/download');
export const API_ENDPOINT_FILES_DOWNLOAD_ALL_BY_PROJECT = API_ENDPOINT_FILES_DOWNLOAD;
export const API_ENDPOINT_GLOBAL_SEARCH = urljoin(`${API_BASEPATH}`, 'search/simple/');
export const API_ENDPOINT_MANAGEDUSERS = urljoin(`${API_BASEPATH}`, 'users');

export const API_ENDPOINT_MANAGEDUSERS_SEARCH = urljoin(`${API_BASEPATH}`, 'users/email/contains');
export const API_ENDPOINT_MANAGEDPROJECT = urljoin(`${API_BASEPATH}`, 'admin/users/projects');
export const API_ENDPOINT_MANAGEDPROJECT_SEARCH = urljoin(`${API_BASEPATH}`, 'search/simple/');
export const API_ENDPOINT_USERLOGIN = urljoin(`${API_BASEPATH}`, 'users/current');
export const API_ENDPOINT_PROJECT = urljoin(`${API_BASEPATH}`, 'projects');
export const API_ENDPOINT_PROJECT_BYPROJECTCODE = urljoin(`${API_BASEPATH}`, 'projects/projectCode');

//export const API_BIOVIZ = "https://globalviz-dev.metabolon.com";
export const API_BIOVIZ_STATS = urljoin(`${API_BIOVIZ}`, 'stats');
export const API_SAMPLESETS_INFO = urljoin('samplesetsinfo');

/**Client Onboarding */
export const API_COE = urljoin(`${API_BASEPATH}`, 'client-onboarding');
export const API_MANIFEST = urljoin(`${API_BASEPATH}`, 'manifest');

export const API_ENDPOINT_SMART_PANELS = urljoin(`${API_BASEPATH}`, 'smartPanels');
export const API_ENDPOINT_SMART_PANELS_SAMPLESETIDS = urljoin(`${API_BASEPATH}`, 'smartPanels/samplesetids');
export const API_ENDPOINT_SMART_PANELS_FILE = urljoin(`${API_BASEPATH}`, 'smartPanels/files/download');
export const API_ENDPOINT_ADMIN_SMART_PANELS_FILE = urljoin(`${API_BASEPATH}`, 'admin/smartPanels/files/download');
export const API_ENDPOINT_SMART_PANELS_RESULTS = urljoin(`${API_BASEPATH}`, 'smartPanels/Visualization/download');
export const API_ENDPOINT_SMART_PANELS_CDT = urljoin(`${API_BASEPATH}`, 'smartpanels/cdt/download');
export const API_ENDPOINT_SMART_PANELS_HEATMAP = urljoin(`${API_BASEPATH}`, 'smartpanels/heatmap/download');

export const API_ENDPOINT_SAMPLESETDATA = urljoin(`${API_BASEPATH}`, 'samplesets');
export const API_ENDPOINT_PROJECTS_ISEMPTY = urljoin(`${API_BASEPATH}`, 'projects/empty');

/** Pathway Map endpoints */
export const API_ENDPOINT_PATHWAY_PLANT = urljoin(`${API_BASEPATH}`, 'pathwaymaps/PLANT');
export const API_ENDPOINT_PATHWAY_ANIMAL = urljoin(`${API_BASEPATH}`, 'pathwaymaps/ANIMAL');

export const API_ENDPOINT_PATHWAY_PLANT_MAP = urljoin(`${API_BASEPATH}`, 'pathwaymaps/file/get/map-plant-v5.json');
export const API_ENDPOINT_PATHWAY_ANIMAL_MAP = urljoin(`${API_BASEPATH}`, 'pathwaymaps/file/get/map-animal-v5.json');

export const API_ENDPOINT_PATHWAY_PLANT_PUT_MAP = urljoin(`${API_BASEPATH}`, 'pathwaymaps/file/put/map-plant-v5.json');
export const API_ENDPOINT_PATHWAY_ANIMAL_PUT_MAP = urljoin(`${API_BASEPATH}`, 'pathwaymaps/file/put/map-animal-v5.json');

/**Admin Endpoints */
export const API_ENDPOINT_FILES_ADMIN = urljoin(`${API_BASEPATH}`, 'admin/files/project');
export const API_ENDPOINT_FILES_ADMIN_DOWNLOAD = urljoin(`${API_BASEPATH}`, 'admin/files/download');
export const API_ENDPOINT_FILES_ADMIN_SAVE = urljoin(`${API_BASEPATH}`, 'admin/files/save/collection');
export const API_ENDPOINT_SAMPLESETDATA_ADMIN = urljoin(`${API_BASEPATH}`, 'admin/samplesets/project/');
export const API_ENDPOINT_SAMPLESETDATA_ADMIN_SAVE = urljoin(`${API_BASEPATH}`, 'admin/samplesets/save/single');
export const API_ENDPOINT_SAMPLESETDATA_ADMIN_SAVE_ALL = urljoin(`${API_BASEPATH}`, 'admin/samplesets/save/collection');
export const API_ENDPOINT_SAMPLESETDATA_ADMIN_PUBLISH_PROJECT = urljoin(`${API_BASEPATH}`, 'admin/samplesets/publish/project');
export const API_ENDPOINT_PROJECT_ADMIN = urljoin(`${API_BASEPATH}`, 'admin/projects/');
export const API_ENDPOINT_PROJECT_ADMIN_SAVE = urljoin(`${API_BASEPATH}`, 'admin/projects/save');
export const API_ENDPOINT_PROJECT_ADMIN_PUBLISH = urljoin(`${API_BASEPATH}`, 'admin/projects/publish/project');
export const API_ENDPOINT_PROJECT_PUBLISH_SUMMARY = urljoin(`${API_BASEPATH}`, 'admin/projects/summary');
export const API_ENDPOINT_PROJECT_ADMIN_CREATE = urljoin(`${API_BASEPATH}`, 'admin/projects/publish/convert');
export const API_ENDPOINT_PROJECT_USERS = urljoin(`${API_BASEPATH}`, 'admin', 'users', 'project');
export const API_ENDPOINT_USERS = urljoin(`${API_BASEPATH}`, 'users');
export const API_ENDPOINT_USERS_ADMIN = urljoin(`${API_BASEPATH}`, 'admin/users');
export const API_ENDPOINT_USERS_UPDATE = urljoin(`${API_BASEPATH}`, 'admin/users/update');
export const API_ENDPOINT_USERS_ADMIN_UPDATE = urljoin(`${API_BASEPATH}`, 'admin/users/publish');
export const API_ENDPOINT_USERS_AVAILABLEROLES = urljoin(`${API_BASEPATH}`, 'admin/users/roles');
export const API_ENDPOINT_USERS_EMAIL = urljoin(`${API_BASEPATH}`, 'admin/users');
export const API_ENDPOINT_USERS_DETAILS = urljoin(`${API_BASEPATH}`, 'users/details/contains');
export const API_ENDPOINT_USERS_USERIDS = urljoin(`${API_BASEPATH}`, 'admin/users/collection');
export const API_ENDPOINT_PUBLISHING_STATUS = urljoin(`${API_BASEPATH}`, 'admin/projects/publish/status');
export const API_ENDPOINT_PROJECTS_UPDATE_STATUS = urljoin(`${API_BASEPATH}`, 'admin/projects/update/status/');
export const API_ENDPOINT_STAKEHOLDERS_ADMIN = urljoin(`${API_BASEPATH}`, 'admin/projects/stakeholders');

/**File Uploader Endpoints */
export const API_STATSFILE_UPLOAD = urljoin(`${API_BASEPATH}`, '/admin/samplesets/publish/single/convert');
export const API_ATTACHMENT_UPLOAD = urljoin(`${API_BASEPATH}`, '/admin/files/upload/single/');

/**DemoEmail */
export const demoEmailAddress = "portaldemo@metabolon.com";
export const supportEmailAddress = "portalsupport@metabolon.com";
export const demoSubject = "Request for demo";
export const demoBody = "(Please provide the information below so we connect you with the appropriate demo.)%0D%0A%0D%0AFirst Name:%0D%0ALast Name:%0D%0AEmail:%0D%0ACompany:%0D%0AIndustry:%0D%0AAddress:%0D%0AZip Code:%0D%0ACity:%0D%0AState or Territory:%0D%0ACounty Code (2 digit):";
export const getADemoLink = "https://www.metabolon.com/get-started/";
export const webpackversion = "4.0";

/**Manifest Upload */
export const showManifestUpload = true;