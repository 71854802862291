'use strict';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import './PrePublishState.scss';
import { ManifestUpload } from '../views/manifestUpload/ManifestUpload.jsx';
import { ResourcesModal } from "@lenses/uilenses/components/ManifestUploadStepper/components/ResourcesModal";
import { resources } from "@/Assets";

const PrePublishState = ({ projectCode }) => {
	const [resourcesModalOpen, setResourcesModalOpen] = useState(false);

	const openResourcesModal = useCallback(() => {
		setResourcesModalOpen(true);
	});

	return (
		<div className="PrePublishedContainer">

			<ResourcesModal
				open={resourcesModalOpen}
				resources={resources}
				setOpen={setResourcesModalOpen}
			/>

			<div className="InnerHeader">
				<div className="Headline">
					<h2>{projectCode}</h2>
				</div>
			</div>

			<div className="InnerContent">
				<div className="ContentTab">
					<div className="ProjectSetupTab">
						<div>
							<ManifestUpload projectCode={projectCode} />
						</div>
					</div>
				</div>

				<div className="Questions">
					<h3>
						<a target="_blank" onClick={openResourcesModal}>Click for Resources</a>
						<a target="_blank" href="mailto:clieantsuccess@metabolon.com" rel="noreferrer">Questions? Contact us</a>
					</h3>
				</div>
			</div>
		</div>
	);
};


PrePublishState.propTypes = {
	projectCode: PropTypes.string
};


export default PrePublishState;