import { discoveryPanelsSelector } from "@/discovery-panel/redux/selectors/discoveryPanels.selector";
import { DiscoveryPanelMap, BIOSTRESS, MICROBIOME, LIVERFIBROSIS, KIDNEYFUNCTION } from "@/enums/Enums";
import { dataSelector } from "../../../redux/selectors/data.selector";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import DetectionDonut from "../../svg/DetectionDonut";
import * as styles from "./bottom.module.scss";

const Bottom = () => {
  const { pathways } = useSelector(dataSelector);
	const { productType } = useSelector(discoveryPanelsSelector);
	const productTypeDisplay = useMemo(() => DiscoveryPanelMap.ui[productType], [productType]);

	return (
		<div>
			<div id={styles["content-wrapper"]} className="flex">
				<div className={styles["left-wrapper"]}>
					<DetectionDonut />
				</div>
				<div className={styles["right-wrapper"]}>
					<h3>
						How did you select these metabolites and small molecules for the {productTypeDisplay} Discovery Panel?
					</h3>
					<div className="html-content">
						<p style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}>
							{productType === BIOSTRESS && <>Metabolon performed extensive analyses to curate this unique list of metabolites and small molecules for our {productTypeDisplay} Discovery Panel.  This process included coupling institutional knowledge with a review of published works within the field to select metabolites that were often highlighted as being relevant to the study of various aspects of biological stress (causes and consequences).  This selection of metabolites was then cross-referenced for frequency of detection on Metabolon’s platform.  As such, the Metabolon {productTypeDisplay} Discovery Panel gives a robust picture of the impact on {pathways.length} pathways with strong relevance to {productTypeDisplay.toLowerCase()}.</>}

							{productType === MICROBIOME && <>Metabolon conducted an extensive process to curate this unique list of metabolites and small molecules for our {productTypeDisplay} Discovery Panel. We reviewed internal studies from over 20 years of work as well as published studies in the field to select a panel of metabolites that is biologically relevant for study the {productTypeDisplay.toLowerCase()}. The metabolites have been detected with great frequency in our studies and give a robust picture of the impact on {pathways.length} pathways with strong {productTypeDisplay.toLowerCase()} relevance.</>}
							
							{productType === LIVERFIBROSIS && <>Metabolon has meticulously identified a unique list of analytes specifically for our {productTypeDisplay} Discovery Panel. The selection of metabolites for this panel was guided by a thorough understanding of the underlying biology and pathways associated with this complex condition. This panel represents an advanced resource for investigating the metabolic alterations associated with liver dysfunction.</>}
							
							{productType === KIDNEYFUNCTION && <>Metabolon performed extensive analyses to curate this unique list of metabolites and small molecules for our Kidney Function Discovery Panel. This process included coupling institutional knowledge with a review of published works within the field to select metabolites that were often highlighted as being relevant to the study of various aspects of kidney function or disease. This selection of metabolites was then cross-referenced for frequency of detection on Metabolon's platform. Accordingly, the Metabolon Kidney Function Discovery Panel provides a robust picture of the impact of several biochemical pathways with strong relevance to kidney function or disease.</>}
						</p>
					</div>
					{productType === MICROBIOME && <><h3>How is the bacterial/host origin determined?</h3>
						<div className="html-content">
							<p style={{ marginTop: "0.5rem" }}>
								Bacterial/host origin groupings are based on our internal knowledge base and represent the most common origin of these metabolites. Because these metabolites are often produced by both bacteria and the host, our groupings can only represent a general picture and may not be definitive.
							</p>
						</div></>}
				</div>
			</div>
		</div>
	);
};

export default memo(Bottom);
