import { Gray } from "metabux/theme/colors";
import {
  Box,
  Collapse,
  MenuItem,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "metabux/components/Icon/Icon";
import { NavItem } from "./NavItem";
import { ChevronDown, ChevronUp } from "lucide-react";
import { DefaultNavItemRenderer } from "./DefaultNavItemRenderer";
import { useLocation } from 'react-router-dom';

export const NavMenu = ({ children, ...props }) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  
  const shouldOpen = useMemo(() => {
  
    const matches = children.filter(child => {
      return child.href === location.pathname;
    });
    setOpen(false);
    return matches.length > 0;
  }, [location, children]);

  const toggleOpen = useCallback(() => {
    if (shouldOpen)
      return;
    setOpen( (prev) => !prev );
  }, [shouldOpen]);

  return (
    <Box>
      <Stack 
      justifyContent="space-between"
      alignItems="center"
      direction="row"
       onClick={() => toggleOpen()}
        sx={{
          position:"relative",
          color: open ? "white" : Gray[400],
          "&:hover": {
            color: "white",
          },
          "&.active": {
            color: "white",
            background: Gray[800],
          },
          padding: 2,
          borderRadius: 2,
          height: 48,
          width: isDesktop ? "auto" : 48
        }}
        key={props.href}
        value={props.href}
        className={`${(props.active || shouldOpen) && "active"}`}
      >
        <DefaultNavItemRenderer {...props} />
        {isDesktop && 
          <Icon
            icon={ open || shouldOpen ? ChevronUp : ChevronDown}
            sx={{ color: "inherit" }}
          />
        }
      </Stack>
      {isDesktop && (
          <Collapse in={open || shouldOpen} sx={{ pl: 10 }}>
            <Stack mt={2} gap={2}>
            {children?.map((i, n) => (
              <NavItem
                mt={0}
                key={i.href + "-" + n}
								{...i}
              />
            ))}
            </Stack>
          </Collapse>
      )}
    </Box>
  );
};
