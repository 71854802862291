import React from "react";
import * as styles from "./loader.module.scss";

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <p>Loading, please hold...</p>
    </div>
  );
};
