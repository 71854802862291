import urljoin from 'url-join';
import { getToken } from '@/middleware/requests';
import { portalApi } from './index.api';
import { isAuthenticatedSelector } from '../selectors/auth.selector';
import { useSelector } from 'react-redux';

export const currentUserApi = portalApi.injectEndpoints({
	endpoints: (builder) => ({
		currentUser: builder.query({
			query: () => ({
				headers: { 'authorization': `Bearer ${getToken()}` },
				url: urljoin('users', 'current'),
				method: 'GET',
			}),
			providesTags: (result, err, arg) => [{ type: 'user', id: arg?.email }],
		}),
	})
});

export const {
	useCurrentUserQuery,
} = currentUserApi;

export const useCurrentUser = () => {
	const isAuthenticated = useSelector(isAuthenticatedSelector);
	return useCurrentUserQuery('', { skip: !isAuthenticated });
};
//TODO user login auth
//security redirect
//user selector
//managed projects
//publishing center