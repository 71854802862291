import { DiscoveryPanelMap, MICROBIOME } from '@/enums/Enums';
import { current } from '@reduxjs/toolkit';
import { ascending } from 'd3';

const getPathwayWriteup = (panelSubPathway, pathways, productType = MICROBIOME) => {
  const pathwayFilter = pathways.filter((e) => {
    return e[DiscoveryPanelMap.pathway[productType]].includes(panelSubPathway);
  });
  return pathwayFilter.length > 0 ? pathwayFilter[0].pathway_name : null;
};

const parseMetabolites = (metabolites, pathways, productType) => (
  metabolites.map((data) => ({
    ...data,
    pathway_name: getPathwayWriteup(data.microbiome_pathway, pathways, productType),
  }))
  .sort((a, b) => ascending(a.pathway_sort_order, b.pathway_sort_order))
);

export const metabolitesSetter = (state, action) => {
  const { pathways, productType } = current(state);
  const { ...payload } = action.payload;

  state.metabolites.raw = payload.raw;
  state.metabolites.error = payload.error;
  state.metabolites.isLoaded = payload.isLoaded;
  state.metabolites.list = parseMetabolites(payload.raw.concat(), pathways.list, productType);
};