import React, { useCallback } from "react";
import Icon from "../../../../components/controls/Icon.jsx";
import ExternalLink from "../../../../components/buttons/ExternalLink.jsx";
import * as styles from "./links.module.scss";

const sections = [
  {
    sectionUrl: "portal",
    sectionLabel: "Getting Started",
    icon: "check-circle",
    sectionLinks: [
      { label: "Overview & FAQs", endpoint: "" },
      { label: "Common How-to’s", endpoint: "how-to" },
      { label: "DiscoveryPanel Report", endpoint: "discovery-panel-report" },
      { label: "Impact Explorer & Heliogram™", endpoint: "impact-explorer-heliogram"},
      { label: "Profile Finder", endpoint: "profile-finder" },
    ],
  },
  {
    sectionUrl: "portal",
    sectionLabel: "Knowledge Base",
    icon: "book",
    sectionLinks: [
      { label: "Product Glossary", endpoint: "glossary" },
      { label: "Experimental Procedures", endpoint: "experimental-procedures" },
      { label: "Statistical Methods and Terminology", endpoint: "statistics"}
    ],
  },
  {
    sectionUrl: "portal",
    sectionLabel: "Data Privacy & Security",
    icon: "lock",
    sectionLinks: [
      { label: "Data Security, Access and Sharing", endpoint: "data" },
      { label: "HIPPA Compliance", endpoint: "hippa" }
    ],
  },
];

const Links = () => {
  const goToUrl = useCallback(
    (url) => () => {
      window.open(url);
    },
    []
  );
  return (
    <div id={styles["bottom-wrapper"]}>
      <div className={styles["copy-bottom"]}>
        <h2>How can we help?</h2>
      </div>
      <div id={styles["bottom-section-wrapper"]}>
        {sections.map((d, i) => {
          return (
            <div key={i}>
              <div className={styles["icon-wrapper-lg"]}>
                <Icon icon={d.icon} size="xlarge" color="quaternary" />
              </div>
              <h3>{d.sectionLabel}</h3>
              <div className={styles["section-links-wrapper"]}>
                {d.sectionLinks.map((s, index) => {
                  return (
                    <div key={index} className={styles["section-link"]}>
                      <ExternalLink
                        color="white"
                        href={`https://www.metabolon.com/support/${d.sectionUrl}/${s.endpoint}/`}
                        className="transparent-link-button slide"
                      >
                        <div
                          className="flex"
                          style={{
                            justifyContent: "center",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <p className="body">{s.label}</p>
                          <Icon
                            icon="angle-right"
                            size="large"
                            color="default"
                          />
                        </div>
                      </ExternalLink>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Links;
