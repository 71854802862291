import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon.jsx';

import './RefreshIndicator.scss';

export default class RefreshIndicator extends Component {
	constructor(props) {
		super(props);
	}

	render(){
		return (
			<div data-testid="refresh-indicator" className="RefreshIndicator abs">
				<Icon icon="spinner" {... this.props} className="fa-pulse fa-3x fa-fw"/>
			</div>
		);
	}
}