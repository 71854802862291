"use strict";
import React from "react";
import { Icon } from "../index.jsx";
import { Button } from "metabux/components/Button/Button.jsx";
import classNames from "classnames";
import { useTheme } from "@mui/material";

/** A simple Button component */
const TextButton = ({
	color = "tertiary.main",
	showIcon,
	children,
	disabled,
	alt,
	onClick,
	size = "normal",
	sx,
	...props
}) => {
	const theme = useTheme();

	const clr0 = disabled ? 'gray.main' : color;
	const clr1 = 'secondary.main';
	const styles = {
		border: "none",
		color: clr0,
		//backgroundColor: "transparent",
		padding: "0px",
		cursor: "pointer",
		textAlign: "center",
		"&.disabled": {
			pointerEvents: "none",
		},
		"&.alt": {
			backgroundColor: clr1,
			color: clr0,
			boxShadow: `0px 0px 0px 3px ${clr0} inset`,
		},
	};



	return (
		<Button size={size} sx={{ ...styles, sx }} className={classNames({ disabled, alt })} variant="text" onClick={onClick} {...props}>
			{showIcon && <Icon icon={showIcon} />}
			{children}
		</Button>
	);
};

export default TextButton;
