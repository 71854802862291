import { createSlice } from '@reduxjs/toolkit';

export const getInitialState = () => ({
	searchResultsIsLoading: false,
	searchResultsHasErrored: false,
	data: [],
	authenticated: false,
	searchId: null,
});

export const searchResultsSlice = createSlice({
	name: 'searchResults',
	initialState: getInitialState(),
	reducers: {
		setSearchId: (state, action) => {
			state.searchId = action.payload;
		},
		searchResultsHasErrored: (state) => {
			state.searchResultsIsLoading = false;
			state.searchResultsHasErrored = true;
		},
		searchResultsIsLoading: (state) => {
			state.searchResultsIsLoading = true;
		},
		searchResultsFetchDataSuccess: (state, action) => {
			state.searchResultsIsLoading = false;
			state.data = action.response;
			state.authenticated = action.authenticated || false;
		}
	}
});
export const { setSearchId } = searchResultsSlice.actions;
export const searchResultsReducer = searchResultsSlice.reducer;
