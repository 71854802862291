/** View Permissions */
export const CAN_VIEW_ADMIN=['canViewAdmin'];
export const CAN_VIEW_APERTURE = ['canViewAperture'];

/** Edit Permissions */
export const CAN_EDIT_PROJECT_PERMISSIONS=['canEditProjectPermissions'];
export const CAN_EDIT_USER_PERMISSIONS=['canEditUserPermissions'];
export const CAN_ADD_USERS=['canAddUsers'];




