'use strict';
import React from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';
import Icon from './Icon.jsx';
import {Label} from '../index.jsx';

/**
 * A Checkbox component for use in forms
 */
export default class Checkbox extends React.Component {

	static getCount() {
		return Checkbox.count;
	}

	static propTypes = {
		/** The color theme of the Checkbox */
		//color: PropTypes.oneOf(Theme.colorList),
		color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'success', 'warning', 'danger', 'info', 'inactive']),

		/** Checkbox font size */
		size: PropTypes.oneOf(['small', 'normal', 'large']),

		/**  Checkbox value */
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),

		/**  Checkbox id */
		id: PropTypes.string,

		/**  Checkbox name */
		name: PropTypes.string,

		/** Whether the field is checked */
		checked: PropTypes.bool,

		/** Whether the field is checked */
		override: PropTypes.shape({
			checked: PropTypes.bool,
			lastChange: PropTypes.number
		}),

		/** Whether the field is required */
		required: PropTypes.bool,

		/** Whether the Checkbox is disabled */
		disabled: PropTypes.bool,

		/**  Checkbox label */
		label: PropTypes.string,

		/**  Whether the label is shown */
		showLabel: PropTypes.bool,

		/**  Checkbox label color */
		labelColor: PropTypes.string,

		/** Gets called when the user clicks on the checkbox */
		onChange: PropTypes.func,

		/** Displays a minus symbol if true */
		limbo: PropTypes.bool,
	};

	static defaultProps = {
		checked: false,
		color: 'primary',
		disabled: false,
		limbo:false,
		showLabel: true,
		label: null,
		labelColor: 'tertiary',
		required: false,
		size: 'normal',
	};
	
	constructor(props) {
		super(props);
		this.state = {
			id: props.id || 'Checkbox-'+Checkbox.getCount(),
			label: props.label || props.value,
			isChecked: props.checked,
			lastChange: Date.now(),
		};
		Checkbox.count++;
	}

	componentDidUpdate(prevProps) {
		const {checked, override} = this.props;
		if(prevProps.checked != checked) {
			this.isChecked = checked;
		}
		else if(override && override.lastChange > this.state.lastChange && override.checked != this.isChecked) {
			this.isChecked = override.checked;
		}
	}
	static count = 0;
	generateClassNames(base) {
		const {color, size, disabled, className} = this.props;
		let classNames = [base, color, size];
		if(className) classNames.unshift(className);
		if(disabled) classNames.push('disabled');

		return classNames;
	}

	get isChecked() {
		return this.state.isChecked;
	}
	set isChecked(value) {
		this.setState({isChecked:value});
	}

	onChange = () => {

		const { onChange, name, value } = this.props;
		this.setState({lastChange:Date.now(), isChecked: !this.isChecked}, function() {
			if(onChange) {
				onChange({target:{name:name, value:value, isChecked:this.isChecked}});
			}
		});
	}

	render() {
		let className = this.generateClassNames("Checkbox check-radio").join(' ');
		let {name, limbo, tabIndex=0, testId} = this.props;
		return (
			<div className={className} tabIndex={tabIndex}>
				<input data-testid={testId} id={this.state.id} type="checkbox"
					name={name} onChange={this.onChange}
					defaultChecked={this.isChecked} value={this.isChecked}/>
				
				<Label htmlFor={this.state.id}
					showTitle = {false}
					size={this.props.size}
					color={this.props.labelColor}
					disabled={this.props.disabled}
					required={this.props.required}>

					<Icon icon={limbo ? 'minus-square' : this.isChecked ? 'check-square' : 'square-o'}
						size={this.props.size}
						disabled={this.props.disabled}
						color={this.state.isChecked ? this.props.color : 'disabled'}/>
					{this.props.showLabel && this.state.label}
				</Label>
			</div>
		);
	}
}