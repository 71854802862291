import React, { useMemo, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Typography, Box, alpha } from "@mui/material"
import Stepper from "metabux/components/Stepper/Stepper"
import { ShipDateConfirmation } from "./components/ShipDateConfirmation"
import { ManifestStep } from "./components/ManifestStep"
import { ShippingInfo } from "./components/ShippingInfo"
import SuccessStep from "./components/SuccessStep"
import { selectCoe } from '@/views/clientOnboarding/redux/selector/coe.selector'
import { useGetOnboardingQuery, useDeliveryDateQuery } from '@/redux/services/coe.api'
import { setOnboardingState, setCurrent } from '@/views/clientOnboarding/redux/reducers/coe.reducer'
import { Gray } from "@/theme/colors"
import { LoadingSpinner } from "metabux/components/LoadingSpinner/LoadingSpinner"

export const ManifestUploadStepper = ({
	projectCode = "PROJ XY",
	projectId,
}) => {
	const dispatch = useDispatch();
	const { isLoading, data, isUninitialized } = useGetOnboardingQuery(projectId)
	const { isLoading: deliveryLoading, data: deliveryDate, isUninitialized: deliveryUninitialized } = useDeliveryDateQuery(projectId)
	const { current, manifestUpload, shipDate } = useSelector(selectCoe)

	useEffect(() => {
		if (isUninitialized || deliveryUninitialized || isLoading || deliveryLoading) return
		let stateData = data || {}
		if (!stateData.shipDate) stateData.shipDate = deliveryDate

		dispatch(setOnboardingState(stateData))
	}, [dispatch, data, isLoading, isUninitialized, deliveryUninitialized, deliveryLoading, deliveryDate])

	const onStepChange = useCallback((step) => {
		dispatch(setCurrent(step))
	}, [dispatch])

	const steps = useMemo(
		() => [
			{
				subtitle: "Sample Ship Date Confirmation",
				stepLabel: "Sample Shipping",
				pageContent: (
					<ShipDateConfirmation
						projectId={projectId}
					/>
				),
			},
			{
				subtitle: "Manifest Upload",
				stepLabel: "Manifest Upload",
				disabled: !shipDate,
				pageContent: (
					<ManifestStep
						projectCode={projectCode}
						projectId={projectId}
					/>
				),
			},
			{
				subtitle: "Shipping information",
				stepLabel: "Tracking & Receipt",
				disabled: !manifestUpload,
				pageContent:
					<ShippingInfo
						projectId={projectId}
					/>,
			},
		],
		[manifestUpload, shipDate]
	)

	return (
		<Box
			sx={{
				mt: 5,
				minHeight: "600px",
				'.step-content': {
					bgcolor: alpha(Gray[100], 0.35),
					width: "100%",
					border: `1px solid ${Gray[300]}`,
					borderRadius: 2,
					p: 8,
					mb: 8
				}
			}}
		>
			<Typography variant="h4" textAlign='center' mb={2}>{projectCode}</Typography>

			<Box sx={{ maxWidth: '600px', mx: 'auto', mb: 4 }}>
				<Stepper onChange={onStepChange} steps={steps.map((s) => {
					return ({ label: s.stepLabel, disabled: s.disabled });
				}
				)} current={current} />
			</Box>

			{!isLoading && current < 3 && steps[current].pageContent}
			{isLoading && <Box className='step-content' textAlign="center"><LoadingSpinner /></Box>}

			{current >= 3 &&
				<SuccessStep />
			}
		</Box>
	)
}

export default ManifestUploadStepper