import React, { memo } from "react";
import { useSelector } from "react-redux";
import { stack, scaleRadial, max, arc } from "d3";
import PropTypes from "prop-types";

import getDocStyle from "../../utils/getDocStyle";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { explorerSelector } from "../../redux/selectors/explorer.selector";

const Bar = ({ opacityList }) => {
  const { associationCoords } = useSelector(explorerSelector);
  const viewPort = useSelector(viewPortSelector);

  const series = stack()
    .keys(["association_table_significant_total", "association_table_total"])
    .value((d, key) => {
      return d[key];
    })(associationCoords);

  const yScaleBar = scaleRadial()
    .domain([-10, max(series, (d) => max(d, (d) => d[1]))])
    .range([viewPort.barInnerRadius, viewPort.barOuterRadius]);

  const arcBar = arc()
    .innerRadius((d) => yScaleBar(d[0]))
    .outerRadius((d) => yScaleBar(d[1]))
    .startAngle((d) => d.data.angle)
    .endAngle((d) => d.data.angle + viewPort.blockWidth * 1.2);

  return (
    <g>
      {series.map((s, index) => {
        return (
          <g key={index}>
            {s.map((d, i) => {
              return (
                <path
                  d={arcBar(d)}
                  key={i}
                  stroke={
                    opacityList.includes(d.data.association)
                      ? getDocStyle("var(--metabolon-base)")
                      : "none"
                  }
                  fill={getDocStyle(
                    s.key === "association_table_significant_total"
                      ? "var(--cold-gray-darkest)"
                      : "var(--cold-gray-dark)"
                  )}
                  className={s.key + " association-bar"}
                  opacity={
                    opacityList.includes(d.data.association) ||
                    opacityList.includes("default")
                      ? 1
                      : viewPort.notSelectedOpacity
                  }
                />
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

Bar.propTypes = {
  data: PropTypes.array,
  settings: PropTypes.object,
};

Bar.defaultProps = {
  data: [],
  settings: {},
};

export default memo(Bar);
