import { ThemeProvider } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { Blue, Red, Green, Yellow, Purple, Gray, Text } from './colors';
import React, { useState, createContext, useMemo } from 'react';

export const colorMap = {
	primary: Blue,
	error: Red,
	success: Green,
	warning: Yellow,
	info: Purple,
	gray: Gray,
	black: Text,
};

export const ColorModeContext = createContext({ mode: "light", toggleColorMode: () => { } });

export const getColor = (color) => {
	if (!color) return color;
	if (color in colorMap) return colorMap[color];
	return color;
};

export const getHue = (color, hue = 100) => {
	if (!color) return color;
	if (color in colorMap) return colorMap[color][hue];
	return color;
};

export const ThemeContextProvider = ({ children }) => {
	const [mode, setMode] = useState("light");
	const toggleColorMode = () => {
		setMode(prev => prev === "dark" ? "light" : "dark");
	};
	const theme = useMemo(() => createMuiTheme({
		spacing: 4,
		breakpoints: {
			values: {
				xs: 0,
				sm: 375,
				md: 834,
				lg: 1280,
				xl: 1536,
			},
		},
		palette: {
			mode,
			text: {
				...(mode === 'light' ?
					{
						primary: Gray[800],
						secondary: Gray[600]
					} :
					{
						primary: '#FFF',
						secondary: Gray[400]
					}
				)
			},
			primary: {
				light: Blue[300],
				main: Blue[500],
				dark: Blue[700],
				disabled: Blue[400],
				hover: Blue[600],
			},
			error: {
				light: Red[300],
				main: Red[500],
				dark: Red[700],
				disabled: Red[400],
				hover: Red[600],
			},
			success: {
				light: Green[300],
				main: Green[500],
				dark: Green[700],
				disabled: Green[400],
				hover: Green[600],
			},
			warning: {
				light: Yellow[300],
				main: Yellow[500],
				dark: Yellow[700],
				disabled: Yellow[400],
				hover: Yellow[600],
			},
			info: {
				light: Purple[300],
				main: Purple[500],
				dark: Purple[700],
				disabled: Purple[400],
				hover: Purple[600],
			},
			black: {
				light: Text[300],
				main: Text[500],
				dark: Text[700],
				disabled: Text[400],
				hover: Text[600],
			},
			gray: {
				light: Gray[300],
				main: Gray[300],
				dark: Gray[500],
				disabled: Gray[400],
				hover: Gray[600],
			},
		},
		typography: {
			fontFamily: "'Inter', sans-serif",
			h1: {
				fontSize: '3rem',
				fontWeight: 600
			},
			h2: {
				fontSize: '2.5rem',
				fontWeight: 600
			},
			h3: {
				fontSize: '2rem',
				fontWeight: 600
			},
			h4: {
				fontSize: '1.5rem',
				fontWeight: 600
			},
			h5: {
				fontSize: '1.25rem',
				fontWeight: 600
			},
			subtitle1: {
				fontSize: '1.125rem'
			},
			body1: {
				fontSize: '1rem'
			},
			body2: {
				fontSize: '.875rem'
			},
			caption: {
				fontSize: '.75rem'
			},
			button: {
				textTransform: "none"
			}
		},
		components: {
			MuiButton: {
				defaultProps: {
					disableRipple: true
				},
				variants: [
					{
						props: { variant: 'secondary' },
						style: {
							background: 'white',
							boxShadow: `0px 0px 0px 1px ${Gray[200]}`,
							'&:hover': {
								background: Gray[100]
							},
							'&:disabled': {
								color: Gray[400]
							}
						}
					},
					{
						props: { variant: 'dark' },
						style: ({ ownerState }) => ({
							color: Gray[800],
							fontWeight: 700,
							background: getHue(ownerState.color, 300),
							boxShadow: `0px 0px 0px 1px ${Gray[200]}`,
							'&:disabled': {
								color: Gray[400]
							},
							'&:hover': {
								background: getHue(ownerState.color, 400)
							},
						})
					}
				],
				styleOverrides: {
					root: ({ ownerState, theme }) => ({
						boxShadow: 'none',
						lineHeight: 1.1,
						'&.MuiButton-sizeSmall': {
							padding: `0 ${theme.spacing(2)}`,
							minHeight: "24px",
							fontSize: '.75rem',
							borderRadius: '6px',
						},

						'&.MuiButton-sizeMedium': {
							padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
							minHeight: "32px",
							fontSize: '.875rem',
							borderRadius: '8px',
						},

						'&.MuiButton-sizeLarge': {
							padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
							minHeight: "40px",
							fontSize: '1rem',
							borderRadius: '8px',
						},

						'&.MuiButton-sizeXl': {
							minHeight: "48px",
							fontSize: '1.25rem',
							padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
							borderRadius: '8px',
						},

						'&.MuiButton-contained': {
							'&:focus': {
								boxShadow: `0 0 0 4px ${getHue(ownerState.color, 100)}, 0px 0px 0px 2px ${getHue(ownerState.color, 100)}`,
								backgroundColor: getHue(ownerState.color, 500)
							},

							'&:active': {
								backgroundColor: getHue(ownerState.color, 700)
							},

							'&:hover:not(:active), &:hover:not(:focus)': {
								boxShadow: 'none',
								backgroundColor: getHue(ownerState.color, 600)
							},

							'&:disabled': {
								backgroundColor: Gray[100],
								color: Gray[400]
							}
						},

						'&.MuiButton-secondary': {
							color: getHue(ownerState.color, 500),
							'&:focus': {
								boxShadow: `0 0 0 4px ${getHue(ownerState.color, 100)}, 0px 0px 0px 2px ${getHue(ownerState.color, 100)}`,
							},
							'&:disabled': {
								color: getHue(ownerState.color, 300),
							},
						}
					})
				}
			},
			MuiCard: {
				styleOverrides: {
					root: {
						width: "14rem",
						padding: "12px 16px",
						borderRadius: "8px",
						'.MuiDivider-root': {
							marginBottom: "12px"
						},
						'.MuiCardHeader-root': {
							padding: 0,
						},
						'.MuiCardContent-root': {
							padding: 0,
							'.card-ratings-box': {
								paddingBottom: "16px",
								gap: "25px",
								display: "flex",
								'.card-rating-box': {
									width: "50%",
									'.card-rating-title': {
										display: "flex",
										gap: "3px"
									},
									'.card-rating-value': {
										padding: "4px 0"
									},
									'.card-rating-bar': {
										borderRadius: "2px",
										width: "100%",
										height: "10px"
									},
									'.card-rating-bar-fill': {
										borderRadius: "2px",
										background: Gray[400],
										height: "100%"
									}
								},
							}
						},
						'.MuiCardActions-root': {
							padding: 0,
							display: "flex",
							justifyContent: "space-between",
							'.card-actions-box': {
								display: "flex",
								gap: "10px"
							}
						},
						'.MuiCardHeader-content': {
							display: "flex",
							flexDirection: "column-reverse",
							padding: "0 0 8px 0",
						},
						'.MuiCardHeader-subheader': {
							fontSize: "12px",
							color: Gray[600],
							marginBottom: "4px",
						},
						'.MuiCardHeader-title': {
							fontWeight: 600,
							fontSize: "18px",
							color: Gray[800],
							height: "48px"
						}
					}
				}
			},
			MuiStepper: {
				styleOverrides: {
					root: ({ theme }) => ({
						'&.complete': {
							'svg': {
								border: `2px solid ${Green[600]}`
							},
							'.Mui-active .MuiStepConnector-line': {
								border: `2px solid ${Green[600]}`,
							},
							'.MuiStepIcon-text': {
								fill: Green[600]
							},
							'.Mui-completed': {
								color: Green[600]
							},
							'.Mui-completed .MuiStepConnector-line': {
								border: `2px solid ${Green[600]}`,
								color: Green[600]
							},
							'.MuiStepLabel-label, .MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed': {
								fontSize: "12px",
								color: Green[600]
							},
							'.MuiSvgIcon-root': {
								cursor: "auto"
							}
						},
						'.MuiStepConnector-root': {
							top: "24px",
							left: 'calc(-50% + 24px)',
							right: 'calc(50% + 24px)',
						},
						'svg': {
							height: "auto",
							width: theme.spacing(12),
							color: "#fff",
							border: "2px solid #2a85ff",
							borderRadius: theme.spacing(6)
						},
						'.Mui-active svg': {
							'.MuiStepIcon-text': {
								fill: "#fff"
							},
						},
						'.Mui-active .MuiStepConnector-line': {
							border: "2px solid #2a85ff",
						},
						'.MuiStepIcon-text': {
							fill: "#2a85ff"
						},
						'.Mui-completed .MuiStepConnector-line': {
							border: "2px solid #2a85ff",
							color: "#2a85ff"
						},
						'.MuiStepLabel-label, .MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed': {
							fontSize: "12px",
							color: "#2a85ff"
						},
						'.MuiStep-root': {
							minWidth: "130px"
						},
						'.MuiSvgIcon-root': {
							cursor: "pointer"
						}
					})
				}
			},
			MuiChip: {
				styleOverrides: {
					root: () =>
					({
						'&.MuiChip-badge': {
							width: "fit-content",
							minWidth: "24px",
							height: "24px",
							borderRadius: "6px",
							'.MuiChip-label': {
								display: "flex",
								padding: "0 4px",
								alignItems: "center",
							}
						},
						'&.badge-journal': {
							backgroundColor: Red[500],
							color: "#FFF"
						},
						'&.badge-active': {
							backgroundColor: Gray[100],
							color: Gray[800],
							boxShadow: `0 0 0 1px ${Gray[200]}`
						},
						'&.badge-inactive': {
							backgroundColor: "#FFF",
							color: Gray[400],
							boxShadow: `0 0 0 1px ${Gray[200]}`
						},
						'&.badge-up': {
							backgroundColor: Red[100],
							color: Red[500],
							boxShadow: `0 0 0 1px ${Red[200]}`

						},
						'&.badge-down': {
							backgroundColor: Blue[100],
							color: Blue[500],
							boxShadow: `0 0 0 1px ${Blue[200]}`
						}
					})
				}
			},
			MuiIconButton: {
				styleOverrides: {
					root: ({ ownerState, theme }) => ({
						'&.custom-icon-button': {

							background: mode === "light" ? "#FFF" : Gray[900],
							color: Gray[400],
							boxShadow: `0 0 0 1px ${mode === "light" ? Gray[200] : "#304369"}`,
							display: "flex",
							alignItems: "center",
							"&:hover": {
								backgroundColor: mode === "light" ? Gray[100] : Gray[800],
								color: mode === "light" ? Gray[500] : Gray[300],
							}, "&:focus": {
								color: mode === "light" ? Gray[800] : Gray[200],
								boxShadow: `0 0 0 4px rgba(62, 136, 234, 0.15), 0 0 0 2px #3E88EA`,
							}, "&:active": {
								color: mode === "light" ? Gray[800] : Gray[200],
							}, "&.Mui-disabled": {
								background: mode === "light" ? "#FFF" : Gray[900],
								color: mode === "light" ? Gray[300] : Gray[500],
							},
							'&.MuiIconButton-sizeSmall': {
								padding: `0 ${theme.spacing(1)}`,
								borderRadius: '4px',
								height: "24px",
								width: "24px",
								'svg': {
									strokeWidth: "1px",
									height: "16px",
									width: "16px",
								}
							},
							'&.MuiIconButton-sizeMedium': {
								padding: `0 ${theme.spacing(1.5)}`,
								borderRadius: '6px',
								height: "32px",
								width: "32px",
								'svg': {
									strokeWidth: "1.5px",
									height: "20px",
									width: "20px",
								}
							},
							'&.MuiIconButton-sizeLarge': {
								padding: `0 ${theme.spacing(3)}`,
								borderRadius: '6px',
								height: "40px",
								width: "40px",
								'svg': {
									strokeWidth: "2px",
									height: "24px",
									width: "24px",
								}
							},
						}
					})
				}
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						boxShadow: 'none',
						'.MuiButtonGroup-grouped:not(:last-of-type)': {
							borderColor: 'white !important',
							padding: '0 12px',
						}
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: ({ ownerState, theme }) => ({
						borderRadius: "4px",

						"&.MuiOutlinedInput-root": {
							border: `1px solid ${Gray[300]}`,
							boxShadow: `0 0 0 1px ${Gray[200]}`,
							"&::before, &::after": {
								content: 'unset',
								display: 'none'
							},
							"&:hover, &.Mui-focused": {
								borderColor: Gray[500]
							},
							"&.Mui-focused": {
								boxShadow: "0 0 0 4px rgba(62, 136, 234, 0.15), 0 0 0 2px #3e88ea",
								background: Gray[100],
							}
						},
						'&:hover': {
							backgroundColor: Gray[100]
						},

						"&.MuiInputBase-sizeSmall, &.MuiSelect-dropdown-small": {
							fontSize: '.75rem',
							padding: theme.spacing(0, 2),
							height: "24px",
						},
						"&.MuiInputBase-sizeMedium, &.MuiSelect-dropdown-medium": {
							fontSize: '1rem',
							padding: theme.spacing(0, 2),
							height: "32px",
						},
						"&.MuiInputBase-sizeLarge, &.MuiSelect-dropdown-large": {
							fontSize: '1.25rem',
							padding: theme.spacing(0, 2),
							height: "40px",
						},
						"&.MuiInputBase-sizeXl, &.MuiSelect-dropdown-xl": {
							fontSize: '1.5rem',
							padding: theme.spacing(0, 2),
							height: "48px",
						},

						"&.MuiInputBase-multiline": {
							height: 'auto',
							padding: theme.spacing(2),
						},

						"&.MuiSelect-dropdown": {
							boxShadow: `0 0 0 1px ${Gray[200]}`,
							".MuiSelect-select": {
								"&.MuiSelect-sizeMedium": {
									paddingLeft: 0
								}
							},
							"&.Mui-focused": {
								boxShadow: "0 0 0 4px rgba(62, 136, 234, 0.15), 0 0 0 2px #3e88ea",
								background: Gray[100],
							},
						},
						fieldSet: {
							border: 'none'
						}
					})
				}
			},
			MuiLink: {
				styleOverrides: {
					root: ({ ownerState, theme }) => ({
						fontFamily: theme.typography.fontFamily,
						fontSize: 'inherit',
						color: Gray[800],
						display: "flex",
						alignItems: "center",
						gap: "8px",
						'&:hover': {
							color: Blue[500]
						},
					})
				}
			},
			MuiSelect: {
				styleOverrides: {
					select: ({ ownerState, theme }) => ({
						boxShadow: 'none',
						fontWeight: 600,

						'&.Mui-focused': {
							backgroundColor: Gray[100]
						},

						'&:hover': {
							backgroundColor: Gray[100]
						},

						'&.MuiSelect-sizeSmall': {
							padding: `0 ${theme.spacing(2)}`,
							fontSize: '.75rem',
							borderRadius: `6px`,
							minWidth: `5rem`,
						},

						'&.MuiSelect-sizeMedium': {
							padding: `0 ${theme.spacing(3)}`,
							fontSize: '.875rem',
							borderRadius: `8px`,
							minWidth: `6rem`,
						},

						'&.MuiSelect-sizeLarge': {
							padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
							fontSize: `1rem`,
							borderRadius: `8px`,
							minWidth: `7rem`,
						},

						'&.MuiSelect-sizeXl': {
							fontSize: `1.25rem`,
							padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
							borderRadius: `12px`,
							minWidth: `8rem`,
						}
					})
				}
			},
			MuiSlider: {
				styleOverrides: {
					root: ({ ownerState }) => ({
						color: getHue(ownerState?.color?.toString(), 500),
					}),
					rail: {
						color: Gray[300],
					},
					thumb: ({ ownerState }) => ({
						color: 'white',
						border: `3px solid ${getHue(ownerState?.color?.toString(), 500)}`,
						padding: '5px'
					}),
				}
			},
			MuiTabs: {
				styleOverrides: {
					root: ({ theme, ownerState }) => ({
						'.MuiTabs-indicator': {
							backgroundColor: Gray[800],
							color: Gray[800]
						},
						minHeight: "2.5rem",
						'.MuiTab-root': {
							color: Gray[400],
							fontWeight: 600,
							padding: 0,
							marginRight: "1.5rem",
							minHeight: "2.5rem",
							minWidth: "auto",
							'&:focus': {
								outline: 'none'
							},
							'&.Mui-selected': {
								color: Gray[800],
								fontSize: "0.875rem",
								lineHeight: "20px",
							},
							'.tab-label': {
								padding: "0 1px",
								display: "flex",
								gap: "10px",
								alignItems: "center"
							},
						},
						'&.switch': {
							'.MuiTabs-flexContainer': {
								position: 'relative',
								zIndex: 0,
							},
							'.MuiTab-root': {
								color: Gray[800],
								backgroundColor: Gray[400],
								marginRight: 0,
								padding: theme.spacing(0, 2),
								paddingLeft: theme.spacing(4),
								paddingRight: theme.spacing(4),

								'&:first-of-type': {
									borderRadius: '25px 0 0 25px'
								},
								'&:last-of-type': {
									borderRadius: '0 25px 25px 0'
								},
							},
							'.MuiTabs-indicator': {
								top: 0,
								height: '100%',
								borderRadius: '25px',
								backgroundColor: 'white',
								border: `2px solid ${Gray[400]}`,
								zIndex: 1,
								display: 'flex',
								alignItems: 'center',
								'&:after': {
									content: `"${ownerState.label}"`,
									fontFamily: theme.typography.fontFamily,
									fontSize: theme.typography[ownerState.tabProps?.variant || 'body2']?.fontSize,
									fontWeight: ownerState.tabProps?.fontWeight || 600,
									width: '100%',
									textAlign: 'center',
									color: Blue[500],
									...ownerState.tabProps
								}
							},
						}
					})
				}
			},
			MuiModal: {
				styleOverrides: {
					root: {
						'.modal-box': {
							position: "absolute", top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							minWidth: "45rem",
							background: Gray[200], padding: "30px",
							borderRadius: "8px",
							'&:focus': {
								outline: "none"
							},
							'.modal-header-icons': {
								height: "40px",
								display: "flex",
								gap: "10px",
							},
							'.modal-header': {
								display: "flex",
								justifyContent: "space-between"
							},
							'.modal-content': {
								marginTop: "24px",
								background: "white",
								borderRadius: "6px",
								minHeight: "10rem",
								padding: "24px",
							},

							'&.blue': {
								padding: 0,
								background: "white",
								'.modal-content': {
									background: 'transparent'
								},
								'.modal-header': {
									background: Gray[900],
									color: 'white'
								}
							}
						}
					}
				}
			},
		}
	}), [mode]);
	return (
		<ColorModeContext.Provider value={{ mode, toggleColorMode }}>
			<ThemeProvider theme={theme}>
				{children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
};