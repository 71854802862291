'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import urljoin from 'url-join';
import { Logger } from '../../utils/Logger';
import * as ErrorLogger  from '../../utils/ErrorLogger';
import { discovery_panel } from './mocks/projectData';
import { get, post, put } from '../../middleware/requests';

const logger = new Logger();

/** Action Creators for projects */
export function projectsFetchData(query = null) {
  let apiEndpoint = config.API_ENDPOINT_PROJECT;
  if (query) apiEndpoint = urljoin(config.API_ENDPOINT_PROJECT, query);

  return {
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.PROJECTS_IS_LOADING, actionTypes.PROJECTS_FETCH_DATA_SUCCESS, actionTypes.PROJECTS_HAS_ERRORED]
    }
  };
}


export const projectsFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.PROJECTS_FETCH_DATA_SUCCESS,
  response,
  authenticated
});


export const projectsHasErrored = () => ({
  type: actionTypes.PROJECTS_HAS_ERRORED
});


export const projectsIsLoading = () => ({
  type: actionTypes.PROJECTS_IS_LOADING
});


export function discoveryPanelsFetchData(query = null) {
  let apiEndpoint = config.API_ENDPOINT_SMART_PANELS;
  if (query) apiEndpoint = urljoin(config.API_ENDPOINT_SMART_PANELS, query);

  return {
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.SMART_PANELS_IS_LOADING, actionTypes.SMART_PANELS_FETCH_DATA_SUCCESS, actionTypes.SMART_PANELS_HAS_ERRORED]
    }
  };
}

export function discoveryPanelsMockData(query = null) {
  return {
    [CALL_API]: {
      isMock: true,
      authenticated: true,
      fail: false,
      generator: () => [discovery_panel],
      timeout: 100,
      types: [actionTypes.SMART_PANELS_IS_LOADING, actionTypes.SMART_PANELS_FETCH_DATA_SUCCESS, actionTypes.SMART_PANELS_HAS_ERRORED]
    }
  };
}

export function discoveryPanelsFetchDataSuccess(projects) {
  return {
    type: actionTypes.SMART_PANELS_FETCH_DATA_SUCCESS,
    projects
  };
}

export function discoveryPanelsHasErrored(bool) {
  return {
    type: actionTypes.SMART_PANELS_HAS_ERRORED,
    hasErrored: bool
  };
}

export function discoveryPanelsIsLoading(bool) {
  return {
    type: actionTypes.SMART_PANELS_IS_LOADING,
    isLoading: bool
  };
}



export const publishSummaryHasErrored = () => ({
  type: actionTypes.PUBLISHSUMMARY_HAS_ERRORED
});


export const publishSummaryIsLoading = () => ({
  type: actionTypes.PUBLISHSUMMARY_IS_LOADING
});


export const publishSummaryFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.PUBLISHSUMMARY_FETCH_DATA_SUCCESS,
  response,
  authenticated
});


export function projectsFetchDataByProjectCode(query = null) {

  let apiEndpoint = config.API_ENDPOINT_PROJECT_BYPROJECTCODE;
  if(query)
      apiEndpoint = urljoin(config.API_ENDPOINT_PROJECT_BYPROJECTCODE, query);
  return {
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.PROJECTS_IS_LOADING, actionTypes.PROJECTS_FETCH_DATA_SUCCESS, actionTypes.PROJECTS_HAS_ERRORED]
    }
  };
}


//Admin Functions
export function projectsFetchDataAdmin(query = null) {
  let apiEndpoint = config.API_ENDPOINT_PROJECT_ADMIN;
  if(query)
      apiEndpoint = urljoin(config.API_ENDPOINT_PROJECT_ADMIN, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.PROJECTS_IS_LOADING, actionTypes.PROJECTS_FETCH_DATA_SUCCESS, actionTypes.PROJECTS_HAS_ERRORED]
    }
  };
}

export function publishSummaryFetchData(projectId) {
  if(!projectId){return;}
  let apiEndpoint = urljoin(config.API_ENDPOINT_PROJECT_PUBLISH_SUMMARY, projectId);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.PUBLISHSUMMARY_IS_LOADING, actionTypes.PUBLISHSUMMARY_FETCH_DATA_SUCCESS, actionTypes.PUBLISHSUMMARY_HAS_ERRORED]
    }
  };
}

export const projectsFetchDataAdminNoRedux = (query = null) => {
  let endpoint = query ? urljoin(config.API_ENDPOINT_PROJECT_ADMIN, query) : config.API_ENDPOINT_PROJECT_ADMIN;

  return async (dispatch) => {
    const response = await get(endpoint);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('projectsFetchDataAdminNoRedux', error.title, error.detail, error.instance, error.status, true);
      return error;
    }

    return await response.payload;
  };
};

export const projectsSaveDataAdmin = (projectData) => {
  let endpoint = config.API_ENDPOINT_PROJECT_ADMIN_SAVE;
  if (!projectData) return;

  return async (dispatch) => {
    dispatch(projectsIsLoading(true));
    const response = await put(endpoint, projectData);
    dispatch(projectsIsLoading(false));
    const { error } = response;
    if (error) {
      ErrorLogger.logError('projectsSaveDataAdmin', error.title, error.detail, error.instance, error.status, true);
    }

    return response;
  };
};

export const projectsPublishDataAdmin = (projectData) => {
  let endpoint = config.API_ENDPOINT_PROJECT_ADMIN_PUBLISH;
  if (!projectData) return;

  return async (dispatch) => {
    dispatch(projectsIsLoading(true));
    const response = await put(endpoint, projectData);
    dispatch(projectsIsLoading(false));
    const { error } = response;
    if (error) {
      ErrorLogger.logError('projectsPublishDataAdmin', error.title, error.detail, error.instance, error.status, true);
    }

    return response;
  };
};

export const fetchProjectUsers = (projectId) => {
  if (!projectId) return;
  let endpoint = urljoin(config.API_ENDPOINT_PROJECT_USERS, projectId);

  return async (dispatch) => {
    const response = await get(endpoint);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('fetchProjectUsers', error.title, error.detail, error.instance, error.status, true);
      return error;
    }

    return await response.payload;
  };
};

export const fetchUnpublishedProjectUsers = (projectId) => {
	if (!projectId) return;
	let endpoint = urljoin(config.API_ENDPOINT_PROJECT_USERS, projectId, 'queue');

	return async (dispatch) => {
		const response = await get(endpoint);
		const { error } = response;
		if (error) {
			ErrorLogger.logError('fetchUnpublishedProjectUsers', error.title, error.detail, error.instance, error.status, true);
			return error;
		}

		return await response.payload;
	};
};

export const publishUser = ({ projectId, ...body }) => {
	if (!projectId) return;
	let endpoint = urljoin(config.API_ENDPOINT_PROJECT_USERS, projectId, 'queue', 'add');

	return async (dispatch) => {
		const response = await post(endpoint, body);
		const { error } = response;
		if (error) {
			ErrorLogger.logError('publishUser', error.title, error.detail, error.instance, error.status, true);
			return error;
		}

		return await response.payload;
	};
};

export const publishNewUser = ({ projectId, ...body }) => {
	if (!projectId) return;
	let endpoint = urljoin(config.API_ENDPOINT_PROJECT_USERS, projectId, 'queue', 'new');

	return async (dispatch) => {
		const response = await post(endpoint, body);
		const { error } = response;
		if (error) {
			ErrorLogger.logError('publishNewUser', error.title, error.detail, error.instance, error.status, true);
			return error;
		}

		return await response.payload;
	};
};

export const unpublishUser = ({ projectId, email }) => {
	if (!projectId) return;
	let endpoint = urljoin(config.API_ENDPOINT_PROJECT_USERS, projectId, 'queue', 'remove', email);

	return async (dispatch) => {
		const response = await post(endpoint);
		const { error } = response;
		if (error) {
			ErrorLogger.logError('unpublishUser', error.title, error.detail, error.instance, error.status, true);
			return error;
		}

		return await response.payload;
	};
};

export const projectsFetchPublishingStatus = (projectId) => {
  if (!projectId) return;
  let endpoint = urljoin(config.API_ENDPOINT_PUBLISHING_STATUS, projectId);

  return async (dispatch) => {
    const response = await get(endpoint);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('projectsFetchPublishingStatus', error.title, error.detail, error.instance, error.status, true);
      return error;
    }

    return await response.payload;
  };
};

export const projectsFetchProjectsUpdateStatus = (projectId) => {
  if (!projectId) return;
  let endpoint = urljoin(config.API_ENDPOINT_PROJECTS_UPDATE_STATUS, projectId);

  return async (dispatch) => {
    const response = await get(endpoint);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('projectsFetchProjectsUpdateStatus', error.title, error.detail, error.instance, error.status, true);
      return error;
    }

    return await response.payload;
  };
};

export const ProjectIsEmpty = (projectId) => {
  if (!projectId) return;
  let endpoint = urljoin(config.API_ENDPOINT_PROJECTS_ISEMPTY, projectId);

  return async (dispatch) => {
    const response = await get(endpoint);
    const { error } = response;
    if (error) {
      ErrorLogger.logError('ProjectIsEmpty', error.title, error.detail, error.instance, error.status, true);
      return error;
    }

    return await response.payload;
  };
};
