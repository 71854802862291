import { useCallback, useEffect, useState } from 'react';
import { useDelayedState } from 'metabux/hooks/delayedState/delayedState.hook';

export const useClipboard = (text) => {
  const [hasCopied, setDelayedHasCopied, setHasCopied] = useDelayedState(false);

	useEffect(() => {
		if (hasCopied) setDelayedHasCopied(false);
	}, [hasCopied]);

	const copy = useCallback(() => {
		const copyFunc = async () => {
			try {
				await navigator.clipboard.writeText(text);
				setHasCopied(true);
			} catch (err) {
				setHasCopied(false);
			}
		};
		copyFunc();
  }, [text]);

  return { copy, hasCopied };
}