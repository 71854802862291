import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../../components/buttons/Button.jsx";
import Icon from "../../../../components/controls/Icon.jsx";
import * as styles from "./head.module.scss";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector.js";
import cdtImage from "../../../static/assets/images/Cdt_download_example_image.png";
import heatmapImage from "../../../static/assets/images/Heatmap_download_example_image.png";
import { fetchAndDownloadCDTData, fetchAndDownloadHeatmapData } from "../../../api.js";
import { useLocalAuditLogger } from "@/hooks/useLocalAuditLogger.js";
import { filterSelector } from "@/discovery-panel/redux/selectors/filter.selector.js";
import { discoveryPanelsSelector } from "@/discovery-panel/redux/selectors/discoveryPanels.selector.js";

const Head = ({ match }) => {
	const logger = useLocalAuditLogger();
	const { mobile } = useSelector(viewPortSelector);
	const { invertedFoldChange } = useSelector(filterSelector);
	const { productType, sampleSetId } = useSelector(discoveryPanelsSelector);
	const [cdtDownloading, setCdtDownloading] = useState(false);
	const [heatmapDownloading, setHeatmapDownloading] = useState(false);
	const projectId = useMemo(() => match.params.projectId, [match.params.projectId]);

	const isDemo = useMemo(() => 
		projectId === "7027d111-c851-45fb-af7e-3563cf38d698" || projectId === "0c243aab-1e0d-47b2-b069-e3d599b5c9dc",
	[projectId]);
	
	const downloadData = useCallback(async (type) => {
		//For now the project may have only samplset
		if (type === "cdt") {
			logger.logUserAction(`${projectId} download discovery panel cdt`);
			setCdtDownloading(true);
			await fetchAndDownloadCDTData(projectId, sampleSetId, productType);
			setCdtDownloading(false);
		} else {
			logger.logUserAction(`${projectId} download discovery panel heatmap`);
			setHeatmapDownloading(true);
			await fetchAndDownloadHeatmapData(projectId, sampleSetId, productType, invertedFoldChange);
			setHeatmapDownloading(false);
		}
	}, [invertedFoldChange, projectId, productType, sampleSetId]);

	const downloadCdt = useCallback(() => {
		downloadData("cdt");
	}, []);

	const downloadHeatmap = useCallback(() => {
		downloadData("heatmap");
	}, []);

	return (
		<>
			<div className={styles["download-buttons-and-preview"]}>
				<div className={styles["download-preview-wrapper"]} >
					<img src={cdtImage} alt="cdt-image" width={"100%"} />
					<div className={styles["button-wrapper"]}>
						<div className={styles["top-right"] + " download-base-button"}>
							<Button
								color="primary"
								disabled={cdtDownloading}
								href={isDemo ? "/static/assets/cdt_demo.xlsx" : undefined}
								onClick={isDemo ? undefined : downloadCdt}
								alt2
								alt
							>
								<Icon
									className={`no-pointer ${cdtDownloading ? "fa-pulse" : null}`}
									icon={cdtDownloading ? "spinner" : "download"}
									size={mobile ? "small" : "medium"}
								/>
								<span>
									Download Data Table
								</span>
							</Button>
						</div>
					</div>
				</div>

				<div className={styles["download-preview-wrapper"]}>
					<img src={heatmapImage} alt="heatmap-img" width={"100%"} />
					<div className={styles["button-wrapper"]}>
						<div className={styles["top-right"] + " download-base-button"}>
							<Button
								color="primary"
								disabled={heatmapDownloading}
								href={isDemo ? "/static/assets/heatmap_demo.xlsx" : undefined}
								onClick={isDemo ? undefined : downloadHeatmap}
								alt2
								alt
							>
								<Icon
									className={`no-pointer ${heatmapDownloading ? "fa-pulse" : null}`}
									icon={heatmapDownloading ? "spinner" : "download"}
									size={mobile ? "small" : "medium"}
								/>
								<span>
									Download Heatmap
								</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(Head);
