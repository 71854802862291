'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../index.jsx';
import './SearchResultItem.scss';

/** Search Result Item Component */
const SearchResultItem = (props) => {

	let { primaryText, width, secondaryText, value } = props;

	const itemClick = () => {
		return props.itemClick({ primaryText: primaryText, value: value });
	};

	let generateClassNames = (base) => {
		let classNames = [width];
		classNames.unshift(base);

		return classNames;
	};
	 
	return (
		<div onClick={itemClick} className={`${generateClassNames('ResultItemContainer').join(' ')}`}>
			<div className="ItemPrimary"><Label color="primary">{primaryText}</Label></div>
			<div className="ItemSecondary"><Label>{secondaryText}</Label></div>
		</div>
	);
	 
};

SearchResultItem.propTypes = {

	/** Primary Result Text */
	primaryText: PropTypes.string.isRequired,

	/** Secondary Result Text */
	secondaryText: PropTypes.string,

	/** Item Value */
	value: PropTypes.string.isRequired,

	/**width */
	width: PropTypes.oneOf(['small', 'normal', 'large']),

	/**itemClick function */
	itemClick: PropTypes.func
};

SearchResultItem.defaultProps = {
	secondaryText: '',
	width: 'normal',
	 
	itemClick: event => {
		//console.log('Search Result Item Event not defined!', event.target);
	}
	 
};

export default SearchResultItem;