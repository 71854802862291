import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Fuse from "fuse.js";

import DropdownContainer from "../../containers/DropdownContainer.jsx";
import Button from "../buttons/Button.jsx";
import Input from "./Input.jsx";
import Icon from "./Icon.jsx";

export const SearchDropdown = ({
  className,
  data,
  id,
  label,
  labelClass,
  placeholder,
  resultClass,
  resultColor,
  threshold,
  wrapperClass,
  searchKeys = ["metabolite"],
  keySecondaryLabel,
  ...props
}) => {
  const [query, setQuery] = useState("");
  const [clickedKey, setClicked] = useState("");
  const primaryKey = searchKeys && searchKeys.length ? searchKeys[0] : null;
  const fuse = new Fuse(data, {
    keys: searchKeys,
    threshold,
    //findAllMatches: true,
    //location: 0,
    //distance: 0,
    //
    //ignoreFieldNorm:true,
  });
  const results = fuse.search(query);
  /* const results = data.filter(item => {
    return item[primaryKey].indexOf(query) === 0;
  }); */

  const updateQueryFromParent = useEffect(() => {
    if (props.searchValue) {
      if (props.searchValue === "reset") setQuery("");
      else setQuery(props.searchValue);
    }
  }, [props.searchValue]);

  const onChange = useCallback((e) => {
    const val = e.currentTarget.value;
    setQuery(val);
  });

  const onResultClick = useCallback((result) => (e) => {
    const key = primaryKey ? result[primaryKey] : result;
    setClicked(key);
    setQuery(key);
    if (props.onResultClick) props.onResultClick(result, e);
  });

  const onResetClick = useCallback((e) => {
    if (props.onResetClick) {
      props.onResetClick(e);
      setQuery("");
    }
  });

  const renderResults = () => {
    let items = results?.length ? results : data;
    return items.map((item, n) => {
      const label = primaryKey ? item[primaryKey] : item;
      return (
        <Button
          key={n}
          onClick={onResultClick(item)}
          className={resultClass}
          color={resultColor}
        >
          {label}
          {keySecondaryLabel && (
            <span className={props.keySecondaryLabelClass}>
              {item[keySecondaryLabel]}
            </span>
          )}
        </Button>
      );
    });
  };

  let resetIcon;
  if (props.resetIcon && query !== "") {
    resetIcon = (
      <div className={props.resetClass} onClick={onResetClick}>
        <Icon icon="times" />
      </div>
    );
  }

  const triggerRenderer = useCallback(
    (isOpen, onClick, triggerProps, selection) => {
      return (
        <>
          <Input
            label={label}
            renderWrapper={false}
            labelClass={labelClass}
            onChange={onChange}
            placeholder={placeholder}
            onClick={onClick}
            value={query}
            spellCheck="false"
            {...triggerProps}
          />
          {resetIcon}
        </>
      );
    },
    [onChange]
  );

  const { color, size, children, disabled, alt, alt2, title, triggerClass } =
    props;
  let dropProps = {
    color,
    size,
    children,
    disabled,
    alt,
    alt2,
    title,
    id,
    triggerRenderer,
    triggerClass,
  };

  return (
    <>
      <DropdownContainer
        className={classnames("Select", className)}
        caret={false}
        renderLabel={false}
        type="text"
        key={clickedKey}
        {...dropProps}
      >
        {renderResults()}
      </DropdownContainer>
    </>
  );
};

SearchDropdown.propTypes = {
  /** The color of the dropdown button */
  color: Button.propTypes.color,

  /** The size of the dropdown button */
  size: PropTypes.oneOf(["small", "medium", "normal", "large"]),

  /** Whether the dropdown button is disabled */
  disabled: PropTypes.bool,

  /** Whether the dropdown button is in alt-color mode */
  alt: PropTypes.bool,

  /** Whether the dropdown button is in alt2-color mode */
  alt2: PropTypes.bool,

  /** display label when there is no query */
  placeholder: PropTypes.string,

  /** the keys by which to search the data */
  searchKeys: PropTypes.array,

  /** contextual information to show below the search value */
  keySecondaryLabel: PropTypes.array,

  /** An array data objects to search */
  data: PropTypes.array,

  /** class to apply to result buttons */
  resultClass: PropTypes.string,

  /** class to apply to result buttons */
  resultColor: Button.propTypes.color,

  threshold: PropTypes.number,
};

SearchDropdown.defaultProps = {
  size: DropdownContainer.defaultProps.size,
  color: "secondary",
  disabled: DropdownContainer.defaultProps.disabled,
  alt: DropdownContainer.defaultProps.alt,
  alt2: DropdownContainer.defaultProps.alt2,

  placeholder: "Search",
  searchKeys: ["metabolite"],
  keySecondaryLabel: [],
  threshold: 0.6,
  data: [],
};

export default SearchDropdown;
