import { current } from "@reduxjs/toolkit";
import { group } from "d3";
import { delimiter } from "@/discovery-panel/utils/constants";

import mapToArray from "../../utils/mapToArray";

const capitalize = (words) => {
  const wordSplit = words.split(" ");
  for (let i = 0; i < words.length; i++) {
    wordSplit[i]
      ? (wordSplit[i] = wordSplit[i][0].toUpperCase() + wordSplit[i].substr(1))
      : null;
  }
  return wordSplit.join(" ");
};

const parseAssociations = (associations) =>
  associations.map((data) => ({
    ...data,
    association: capitalize(data.association),
  }));

const dynamicAssociations = (
  staticAssociations,
  dynamicResults,
  totalMetabolites,
  invertedFoldChange
) => {
  const addMetabolites = staticAssociations.map((d) => {
    const data = dynamicResults.find(
      (e) => d.chemical_id === e.chemical_id
    ) || {
      detected: false,
      metabolite_significant: false,
      metabolite_upregulated: false,
      metabolite_downregulated: false,
      metabolite: "undetected",
      metaboliteInfo: {
        pathway_name: "unknown",
      },
    };
    return {
      ...d,
      metabolite_table_significant: data.detected
        ? data.metabolite_significant
        : null,
      metabolite_table_upregulated: data.detected
        ? data.metabolite_upregulated
        : null,
      metabolite_table_downregulated: data.detected
        ? data.metabolite_downregulated
        : null,

      metabolite: data.metabolite,
      pathway_name: data.metaboliteInfo.pathway_name,
    };
  });

  const groupByAssociation = mapToArray(
    group(addMetabolites, (d) => d.association + delimiter + d.type)
  );
  const mapdata = groupByAssociation.map((d, n) => {
    const significant = d.value.filter(
      (e) => e.metabolite_table_significant > 0
    ).length;
    const upregulated = d.value.filter(
      (e) => e.metabolite_table_upregulated > 0
    ).length;
    const downregulated = d.value.filter(
      (e) => e.metabolite_table_downregulated > 0
    ).length;

    const [association, type] = d.key.split(delimiter);

    return {
      association,
      type,
      significant,
      upregulated,
      downregulated,
      total: d.value.length,
    };
  });

  return addMetabolites.map((d, n) => {
    const data = mapdata.find(
      (e) => e.association === d.association && e.type === d.type
    );
    const total = totalMetabolites;
    const invertedUpRegulated = invertedFoldChange ? data.downregulated : data.upregulated;
    const invertedDownRegulated = invertedFoldChange ? data.upregulated : data.downregulated;
    return {
      ...d,
      association_table_significant: Number(
        ((data.significant / total) * 100).toFixed(2)
      ),
      association_table_upregulated: Number(
        ((invertedUpRegulated / total) * 100).toFixed(2)
      ),
      association_table_downregulated: Number(
        ((invertedDownRegulated / total) * 100).toFixed(2)
      ),
      association_table_significant_total: data.significant,
      association_table_total: total,
    };
  });
};

const associationsByMetabolite = (associations) => {
  const map = {};
  associations.forEach(a => {
    if (!(a.chemical_id in map))
      map[a.chemical_id] = [];
    map[a.chemical_id].push(a.association);
  });
  return map;
};
export const associationsSetter = (state, action) => {
  const { payload } = action;

  state.associations.raw = payload.raw;
  state.associations.static = parseAssociations(payload.raw);
  state.associations.error = payload.error;
};

export const dynamicAssociationsSetter = (state, action) => {
  const { associations, metabolites, results, filter } = current(state);
  const { invertedFoldChange } = filter;
  const associationsList = dynamicAssociations(
    associations.static,
    results.list,
    metabolites.list.length,
    invertedFoldChange
  );
  state.associationsByMetabolite = associationsByMetabolite(associationsList);
  state.associations.list = associationsList;
  state.associations.isLoaded = true;
};
