import * as d3 from "d3";
import React, { useCallback, memo, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import mapToArray from "../../../utils/mapToArray";
import Tooltip from "../../controls/Tooltip";
import * as styles from "./heatmap.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { viewPortSelector } from "../../../redux/selectors/viewPort.selector";
import { setTooltipX, setTooltipY, setShowTooltip as setShowTooltipAction, setTooltipValue, setTooltipType as setTooltipTypeAction } from "../../../redux/reducers/discoveryPanels.reducer";
import { delimiter } from "@/discovery-panel/utils/constants";


const Heatmap = ({ data, heatmapHeight, heatmapWidth }) => {
  const dispatch = useDispatch();
  const { width } = useSelector(viewPortSelector);
  const results = useMemo(() => {
    return data.allResults
      .sort((a, b) =>
        d3.ascending(
          parseInt(a.comparison_number),
          parseInt(b.comparison_number)
        )
      )
      .sort((a, b) => d3.ascending(a.comparison_groups, b.comparison_groups));
  }, [data]);

  const numberList = mapToArray(
    d3.group(results, (d) => d.comparison_number)
  ).sort((a, b) => d3.ascending(parseInt(a.key), parseInt(b.key)));

  const groupList = mapToArray(d3.group(results, (d) => d.comparison_groups));

  const resultBasedHeights = useMemo(() => {
    const minHeight = 500;
    const elementHeight = 55;
    return {
      heightOffset: 0.0023 * results.length,
      heatmapHeight: results.length > 4 ? minHeight + results.length * elementHeight : minHeight,
    };
  }, [results.length]);

  const rowScale = d3
    .scaleBand()
    .domain(groupList.map((d) => d.key))
    .range([heatmapHeight * 0.1, heatmapHeight]);
    

  const columnScale = d3
    .scaleBand()
    .domain(numberList.map((d) => d.key))
    .range([heatmapWidth * 0.1, heatmapWidth]);

  const maxPValue = d3.max(results, (d) => d.p_value);
  const minPValue = d3.min(results, (d) => d.p_value);

  const sizeScale = d3
    .scaleLinear()
    .domain([maxPValue, minPValue])
    .range([15, 43]);

  const setX = useRef((val) => dispatch(setTooltipX(val))).current;
  const setY = useRef((val) => dispatch(setTooltipY(val))).current;
  const setShowTooltip = useRef((val) => dispatch(setShowTooltipAction(val))).current;
  const setValue = useRef((val) => dispatch(setTooltipValue(val))).current;
  const setTooltipType = useRef((val) => dispatch(setTooltipTypeAction(val))).current;

  const mouseEnter = useCallback(
    (e) => {
      const d = e.target.dataset;

      const xAdj =
        e.nativeEvent.clientX < width / 4
          ? e.nativeEvent.offsetX + 230 / 2
          : e.nativeEvent.clientX + 230 > width
          ? e.nativeEvent.offsetX - 230 / 2
          : e.nativeEvent.offsetX;

      setX(xAdj);
      setY(e.nativeEvent.offsetY + 16);
      setShowTooltip(true);
      setValue(d.value + delimiter + d.comparison);
      setTooltipType("metabolite");
    },
    [setShowTooltip, setValue, setX, setY]
  );

  const mouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  return (
    <div className={styles.heatmap}>
      <Tooltip source="profile" />
      {results.map((d, i) => {
        return (
          <div
            className={styles.circlePosition}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            key={i}
          >
            <p className={styles.label}>{d.comparison}</p>
            <div
              className={styles.circle}
              style={{
                backgroundColor: d.color,
                width: sizeScale(d.p_value),
                height: sizeScale(d.p_value),
              }}
              data-value={d.chemical_id}
              data-comparison={d.comparison}
            />
          </div>
        );
      })}
    </div>
  );
};

Heatmap.propTypes = {
  allResults: PropTypes.array,
  heatmapWidth: PropTypes.number,
  heatmapHeight: PropTypes.number,
  maxPValue: PropTypes.number,
  minPValue: PropTypes.number,
};

Heatmap.defaultProps = {
  allResults: [],
  heatmapWidth: 0,
  heatmapHeight: 0,
  maxPValue: 0,
  minPValue: 0,
};

export default memo(Heatmap);
