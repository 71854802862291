'use strict';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../theme/css/font-awesome.css';
import './LandingFooter.scss';
import { Icon } from '../../components/index.jsx';

export class LandingFooter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="LandingFooter">
				<div className="wrapper">
					<div className="logoFooter">
						<img className="logo-metabolon-footer" />
					</div>
					<div className="socialLinks">

						<a title="LinkedIn" target="_blank" href="https://www.linkedin.com/company/metabolon" rel="noreferrer">
							<Icon color={"primary"} icon={"linkedin"} size="large" />
						</a>

						<a title="YouTube" target="_blank" href="https://www.youtube.com/user/MetabolonWeb" rel="noreferrer">
							<Icon color={"primary"} icon={"youtube"} size="large" />
						</a>

						<a title="Twitter" target="_blank" href="https://twitter.com/Metabolon" rel="noreferrer">
							<Icon color={"primary"} icon={"twitter"} size="large" />
						</a>

						<a title="Blog" target="_blank" href="https://www.metabolon.com/who-we-are/blog" rel="noreferrer">
							<Icon color={"primary"} icon={"newspaper-o"} size="large" />
						</a>
					</div>
					<div className="copywrite">
						© {(new Date()).getFullYear()} METABOLON, INC. | <a target="_blank" href="/terms">TERMS</a> | <a target="_blank" href="http://www.metabolon.com/utilities/privacy-policy" rel="noreferrer">PRIVACY POLICY</a> | <a target="_blank" href="/help">HELP</a>
					</div>

				</div>
			</div>
		);
	}
}

LandingFooter.propTypes = {

};

LandingFooter.defaultProps = {

};

export default withRouter(LandingFooter);