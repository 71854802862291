import React, { memo, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { arc as d3arc } from "d3";

import Defs from "./Defs";
import getDocStyle from "../../utils/getDocStyle";
import { viewPortSelector } from "../../redux/selectors/viewPort.selector";
import { downloadSelector } from "../../redux/selectors/download.selector";

const CurvedLabel = ({
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  path,
  text,
  fontSize,
  center,
  opacity,
  stroke,
  id,
  fontWeight,
  x,
  y,
}) => {
  const { mobile } = useSelector(viewPortSelector);
  const { curvedText } = useSelector(downloadSelector);
  const arc = d3arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle);

  const arrowPath = (path) => {
    let pathArray = arc(path).split(",");
    let newPath =
      pathArray[0] +
      "," +
      pathArray[1] +
      "," +
      pathArray[2] +
      " 0 0, 1 " +
      pathArray[6] +
      ", " +
      pathArray[1].split("A")[0];
    return newPath;
  };

  const startOffset = path === "arrow" || path === "circle" ? "50%" : "25%";
  const markerEnd = path === "arrow" ? "url(#arrowHeadLeft)" : "";
  const markerStart = path === "arrow" ? "url(#arrowHeadRight)" : "";

  const yPos = curvedText ? y + center : y + center - outerRadius;

  return (
    <g>
      {curvedText && <Defs />}
      <path
        d={path === "arrow" ? arrowPath(text) : arc()}
        id={id}
        fill="none"
        stroke={stroke !== "none" ? getDocStyle("cold-gray-light") : "none"}
        markerEnd={markerEnd}
        markerStart={markerStart}
        pointerEvents="none"
      />

      <text
        opacity={opacity}
        letterSpacing={mobile ? "0" : "0.02em"}
        textAnchor="middle"
        style={{
          textTransform: "uppercase",
          fontSize: fontSize,
          fontWeight: path === "arrow" ? "bold" : fontWeight,
          fill: getDocStyle("metabolon-base"),
          pointerEvents: "none",
        }}
        transform={`translate(${x},${yPos})`}
      >
        {curvedText && (
          <textPath
            href={"#" + id || "s"}
            startOffset={startOffset}
            pointerEvents="none"
            fill={getDocStyle("metabolon-base")}
          >
            {text}
          </textPath>
        )}
        {!curvedText && text}
      </text>
    </g>
  );
};

CurvedLabel.propTypes = {
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  path: PropTypes.string,
  text: PropTypes.string,
  fontSize: PropTypes.string,
  center: PropTypes.number,
  opacity: PropTypes.number,
  stroke: PropTypes.string,
  id: PropTypes.string,
  fontWeight: PropTypes.string,
};

CurvedLabel.defaultProps = {
  innerRadius: 0,
  outerRadius: 0,
  x: 0,
  y: 0,
  startAngle: -Math.PI,
  endAngle: Math.PI,
  path: "circle",
  text: "",
  fontSize: "1em",
  center: 0,
  opacity: 1,
  stroke: "none",
  id: "",
  fontWeight: "400",
};

export default memo(CurvedLabel);
