import React, { useCallback } from 'react';
import { Typography, Stack } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Gray } from '@/theme/colors';
import { Button } from "metabux/components/Button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent } from '@/views/clientOnboarding/redux/reducers/coe.reducer'
import { selectCoe } from '@/views/clientOnboarding/redux/selector/coe.selector'

export const SuccessStep = () => {
	const dispatch = useDispatch()
	const { current } = useSelector(selectCoe)

	const onPrev = useCallback(() => {
		dispatch(setCurrent(current - 1))
	}, [current, dispatch])

	const onReset = useCallback(() => {
		dispatch(setCurrent(0))
	}, [dispatch])

	return (
		<>
			<Typography textAlign="center" mb={2}>You're all set! We will notify you when your samples have been received.</Typography>
			<Stack direction="column" alignItems="center" className="step-content">
				<Typography color={Gray[300]} fontSize={160} sx={{ lineHeight: 1, transform: 'skewX(-15deg)' }}>
					<LocalShippingOutlinedIcon fontSize="160px" />
				</Typography>
				<Typography color={Gray[300]} variant='h3'>Samples in transit.</Typography>
			</Stack>
			<Stack direction="column" alignItems="flex-start" spacing={2}>
				<Button
					onClick={onPrev}
					color="gray"
					variant="dark"
				>
					Back: Tracking &amp; Receipt
				</Button>
				<Button variant="text" size="small" underline="hover" onClick={onReset}>Back to Sample Shipping</Button>
			</Stack>
		</>
	)
}


export default SuccessStep