export const getSVGLetterCoords = (cx, cy, cr, text, offset) => {
  if (!text) return;
  const charCoords = [];
  const totalChars = text.length;
  const angleStep = offset / cr;

  for (let i = 0; i < totalChars; i++) {
    let angle = -Math.PI / 2 + (i - (totalChars - 1) / 2) * angleStep;

    const x = cx + cr * Math.cos(angle);
    const y = cy + cr * Math.sin(angle);

    const rotationAngle = (angle + Math.PI / 2) * (180 / Math.PI);

    charCoords.push({
      character: text[i],
      x,
      y,
      rotation: rotationAngle,
    });
  }

  return charCoords;
};
