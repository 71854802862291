import PropTypes from "prop-types";
import React, { memo } from "react";
import getDocStyle from "../../utils/getDocStyle";

const StraightLabel = ({
  startAngle,
  endAngle,
  innerRadius,
  outerRadius,
  direction,
  fontSize,
  fontWeight,
  color,
  opacity,
  text,
  showLine,
  lineColor,
}) => {
  const flipFlag = startAngle > 3;
  const flip = !direction ? -12 : -6;
  const textAnchor = flipFlag
    ? direction === "in"
      ? "start"
      : "end"
    : direction === "in"
    ? "end"
    : "start";

  const scale = flipFlag ? "scale(-1, -1)" : "scale(1,1)";
  const xPos = flipFlag ? -innerRadius : innerRadius;
  const angle = flipFlag ? endAngle : startAngle;
  const angleReverse = flipFlag ? startAngle : endAngle;
  const shiftYLine = flipFlag ? 4 : -5;

  return (
    <g>
      {
        <g
          className={angleReverse}
          transform={"rotate(" + ((angleReverse * 180) / Math.PI - 90) + ")"}
          style={{
            fontSize: fontSize + "px",
            textTransform: !direction ? "uppercase" : "",
            letterSpacing: "var(--wide)",
          }}
        >
          {showLine && (
            <line
              x1={innerRadius * 0.987}
              x2={innerRadius * 0.76}
              y1={shiftYLine * (flipFlag ? -1.1 : -0.95)}
              y2={shiftYLine * (flipFlag ? -0.9 : -0.5)}
              stroke={lineColor}
              strokeWidth={0.75}
            />
          )}
        </g>
      }
      <g
        className={angle}
        transform={"rotate(" + ((angle * 180) / Math.PI - 90) + ")"}
        style={{
          fontSize: fontSize + "px",
          textTransform: !direction ? "uppercase" : "",
          letterSpacing: "var(--wide)",
        }}
      >
        <text
          x={xPos}
          dy={flip}
          textAnchor={textAnchor}
          fill={color}
          fontWeight={fontWeight}
          opacity={opacity}
          transform={scale}
          pointerEvents="none"
        >
          {text}
        </text>
        {showLine && (
          <line
            x1={outerRadius * 0.92}
            x2={innerRadius * 0.76}
            y1={shiftYLine}
            y2={shiftYLine}
            stroke={lineColor}
            strokeWidth={0.75}
          />
        )}
      </g>
    </g>
  );
};

StraightLabel.propTypes = {
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  direction: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  text: PropTypes.string,
  showLine: PropTypes.bool,
  lineColor: PropTypes.string,
};

StraightLabel.defaultProps = {
  startAngle: 0,
  endAngle: 0,
  innerRadius: 0,
  outerRadius: 0,
  direction: "in",
  fontSize: 12,
  fontWeight: "normal",
  color: getDocStyle("var(--metabolon-base)"),
  opacity: 1,
  text: null,
  showLine: false,
  lineColor: getDocStyle("var(--cold-gray-darkest)"),
};
export default memo(StraightLabel);
