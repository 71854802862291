import { useFetchBiovizSampleSetsQuery } from "@/redux/services/bioviz.api";
import { API_BIOVIZ } from '@/config/env.config';

export const useBiovizStatus = (projectId, sampleSetId) => {
  const { data, isLoading, error } = useFetchBiovizSampleSetsQuery(projectId, { skip: projectId === undefined});

	if (["000023c4-0000-0000-0000-000000000000","000023c2-0000-0000-0000-000000000000","000023c1-0000-0000-0000-000000000000"].includes(projectId)) return {status: true, href: API_BIOVIZ};

	if (isLoading || error || !data || data?.exists === "false") return {status: false};

  let href = API_BIOVIZ + `?projectId=${projectId}`;
  if (sampleSetId) href += `&sampleSetId=${sampleSetId}`;

	return {status: true, href};
};