import React, { useCallback } from "react";
import { Stack, Table, TableBody, TableRow, TableCell, TableHead, Typography } from "@mui/material";
import ManifestListItem from "metabux/components/ManifestListItem/ManifestListItem";
import { Blue, Gray } from '@/theme/colors';
import ForwardIcon from '@mui/icons-material/Forward';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent } from '@/views/clientOnboarding/redux/reducers/coe.reducer'
import { selectCoe } from '@/views/clientOnboarding/redux/selector/coe.selector'

export const ManifestList = ({
	testId = "manifestList",
	items = [],
	...props
}) => {

	const dispatch = useDispatch()
	const { current } = useSelector(selectCoe)

	const onPrev = useCallback(() => {
		dispatch(setCurrent(current - 1))
	}, [current, dispatch])

	const onRemove = useCallback(
		(data) => {
			props.onRemove(data);
		},
		[props.onRemove]
	);

	if (!items.length) return (
		<Stack direction="column" alignItems="center" m={-8} py={8} onClick={onPrev} sx={{ cursor: "pointer" }}>
			<Typography variant='h4' textAlign="center">No manifests have been uploaded for this project</Typography>
			<Typography color={Gray[300]} fontSize={160} sx={{ lineHeight: 1, transform: 'scaleX(-100%)' }}>
				<ForwardIcon fontSize="160px" />
			</Typography>
			<Typography variant='h4' textAlign="center">Back to Manifest Upload</Typography>
		</Stack>
	)
	return (
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead sx={{ borderBottom: `2px solid ${Blue[500]}` }}>
				<TableRow>
					<TableCell sx={{ border: "none", p: 0 }} colSpan={4}>
						<Typography
							variant="h4"
							sx={{ p: 0, color: `${Blue[600]}` }}
							id="tableTitle"
						>
							Uploaded Manifests
						</Typography>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell></TableCell>
					<TableCell sx={{ fontWeight: "bold", fontSize: "1rem" }}>Name</TableCell>
					<TableCell sx={{ fontWeight: "bold", fontSize: "1rem" }}>Shipping Provider</TableCell>
					<TableCell sx={{ fontWeight: "bold", fontSize: "1rem" }}>Tracking ID</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((data) =>
					<ManifestListItem data={data} onChange={props.onChange} onRemove={onRemove} key={`${data.fileName}-${data.manifestFileId}`} />
				)}
			</TableBody>
		</Table>
	);
};

export default ManifestList;
