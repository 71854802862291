import { call, put, take, takeLatest } from 'redux-saga/effects';
import { ascending } from 'd3';

import { fetchPathways } from '../../api';
import { 
  getPathways, 
  setPathways
} from '../reducers/discoveryPanels.reducer';
import { MICROBIOME } from '@/enums/Enums';

export function* pathwaysSaga(action) {
	const { productType=MICROBIOME } = action?.payload || {};
	
	const payload = {
    list: [],
    static: [],
    raw: [],
    isLoaded: false,
    error: null
  };

  try {
    const data = yield call(fetchPathways, productType);
    payload.raw = data;
  } catch (e) {
    payload.error = e.message;
  } finally {
    payload.isLoaded = true;
  }

  yield put(setPathways(payload));
}

export function* pathwaysSagas() {
  yield takeLatest(getPathways, pathwaysSaga);
}