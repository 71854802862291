import { Input as MuiInput } from '@mui/material'
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'

export const Input = ({ className, onChange, defaultValue = "", variant="outlined", testId = "input", size = "medium", ...props }) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])

	const handleChange = (event) => {
		setValue(event.target.value);
		if (onChange) onChange(event.target.value, event);
	};

	return (
		<MuiInput
			classes={{
				root: classNames(
					className,
					`MuiInputBase-size${size.substring(0, 1).toUpperCase()}${size.substring(1)}`,
					`Mui${variant.substring(0, 1).toUpperCase()}${variant.substring(1)}Input-root`
				),
			}}
			variant={variant}
			value={value}
			onChange={(e) => handleChange(e)}
			data-testid={testId}
			{...props}
		/>
	)
}

