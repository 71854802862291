'use strict';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DynamicTable from '../components/controls/DynamicTable.jsx';
import DynamicTableHelper from '../components/controls/DynamicTableHelper.jsx';
import DynamicTableCell from '../components/controls/DynamicTableCell.jsx';
//import './StatsTable.scss';

function StatsTable({ stats: propStats, ...props }) {
	const [stats, setStats] = useState(propStats);
	const [selection, setSelection] = useState(props.selection);

	useEffect(() => {
		if (propStats) {
			setStats(propStats);
		}
	}, [propStats]);

	/* useEffect(() => {
		const { onRowClick } = props;
		if (onRowClick) onRowClick(selection);
	}, [selection]); */

	const onItemToggle = useCallback((selections) => {
		if (props.onItemToggle) props.onItemToggle(selections);
	}, []);

	const onRowClick = useCallback((id) => {
		if (id === selection) return;
		setSelection(id);
	}, []);

	const generateClassNames = (base = 'StatsTable') => {
		const { className } = props;
		let classNames = [];
		if (base) classNames.unshift(base);
		if (className) classNames.unshift(className);

		return classNames;
	};

	const { selections, rowProps, showHidden } = props;
	let newRowProps = (statDetail) => {
		return { id: statDetail.id, ...rowProps };
	};
	let fields = StatsTable.createTableFields();
	if (!stats.length) return <p>No Stats Available</p>;
	let filteredStats = stats.filter(stat => (showHidden || stat.customerVisible));

	let label = "Statistical Tests";

	return (
		<DynamicTable
			onItemToggle={onItemToggle}
			primaryKey="statsId"
			testId="stats-table"
			selections={selections}
			selection={selection}
			sorter={null}
			sortDir={1}
			index={0}
			className={generateClassNames().join(' ')}
			dataset={filteredStats}
			fields={fields}
			rowProps={newRowProps}
			onRowClick={onRowClick}>

			<div className="table-title">
				{label}
			</div>
		</DynamicTable>
	);
}

StatsTable.createTableFields = function () {
	return [
		DynamicTableHelper.createField('---', null, { type: 'select' }),
		DynamicTableHelper.createField('reportName', 'Name', {
			renderer: (data, fieldName, n) => {
				let label, title;
				if (data[fieldName]) label = data[fieldName];//.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/');
				else if (data.foldChange) label = data.foldChange;
				else label = data.comparison;
				title = label;
				label = data.customerName || label;
				label = label ? label.replace(/[\(,\)]+/g, '').replace(/[\/\/]+/g, '/') : "Stats";

				return <DynamicTableCell className={fieldName} key={n}><span title={title}>{label}</span></DynamicTableCell>;

			}
		}),
		//DynamicTableHelper.createField('lastUpdatedOn', 'Date', {renderer:DynamicTableHelper.defaultDateRenderer}),
	];
};

StatsTable.propTypes = {
	/** files*/
	//sampleSet: PropTypes.object,
	stats: PropTypes.array,
	onItemToggle: PropTypes.func,
	showHidden: PropTypes.bool,
};

StatsTable.defaultProps = {
	//sampleSet: {},
	stats: [],
	showHidden: true,
};

export default StatsTable;