'use strict';
import * as actionTypes from './actionTypes';
import * as config from '../../config/env.config';
import { CALL_API } from '../../middleware/storeApi';
import * as ErrorLogger  from '../../utils/ErrorLogger';
import { put } from '../../middleware/requests';
import urljoin from 'url-join';


/** Action Creators for files */
export const sampleSetDataHasErrored = () => ({
  type: actionTypes.SAMPLESETDATA_HAS_ERRORED
});


export const sampleSetDataIsLoading = () => ({
  type: actionTypes.SAMPLESETDATA_IS_LOADING
});


export const sampleSetDataIsSaving = (isSaving = true) => ({
  type: actionTypes.SAMPLESETDATA_IS_SAVING,
  isSaving
});


export const sampleSetDataFetchDataSuccess = (response, authenticated = undefined) => ({
  type: actionTypes.SAMPLESETDATA_FETCH_DATA_SUCCESS,
  response,
  authenticated
});


export function sampleSetDataFetchDataByProjectID(ProjectID){
    return sampleSetDataFetchData(config.API_ENDPOINT_SAMPLESETDATA, `project/${ProjectID}`);
}

export function sampleSetDataFetchDataByProjectIDandSampleSetID(ProjectID, SampleSetID){
    return sampleSetDataFetchData(config.API_ENDPOINT_SAMPLESETDATA, `/${ProjectID}/${SampleSetID}`);
}

export function sampleSetDataFetchData(endpoint, query = null) {
    let apiEndpoint = endpoint;
    if(query)
        apiEndpoint = urljoin(apiEndpoint, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.SAMPLESETDATA_IS_LOADING, actionTypes.SAMPLESETDATA_FETCH_DATA_SUCCESS, actionTypes.SAMPLESETDATA_HAS_ERRORED]
    }
  };
}


//Admin Functions
export function sampleSetFetchDataAdmin(query = null) {
    let apiEndpoint = config.API_ENDPOINT_SAMPLESETDATA_ADMIN;
    if(query)
        apiEndpoint = urljoin(config.API_ENDPOINT_SAMPLESETDATA_ADMIN, query);
  return{
    [CALL_API]: {
      endpoint: apiEndpoint,
      authenticated: true,
      types: [actionTypes.SAMPLESETDATA_IS_SAVING, actionTypes.SAMPLESETDATA_FETCH_DATA_SUCCESS, actionTypes.SAMPLESETDATA_HAS_ERRORED]
    }
  };
}

export const putAdminSampleSetSaveData = (sampleSetData) => {
  const endpoint = config.API_ENDPOINT_SAMPLESETDATA_ADMIN_SAVE_ALL;
  return async (dispatch) => {
    dispatch(sampleSetDataIsSaving(true));
    const response = await put(endpoint, sampleSetData);
    dispatch(sampleSetDataIsSaving(false));
    const { error } = response;
    if (error) {
      ErrorLogger.logError('adminSampleSetSaveData', error.title, error.detail, error.instance, error.status, true);
    }
    return response;
  };
};
