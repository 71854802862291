import React from "react";
import ExternalLink from "../../../../components/buttons/ExternalLink.jsx";
import * as styles from "./header.module.scss";

const buttons = [
  {
    label: "Contact Sales",
    url: "mailto:sales@metabolon.com",
    color: "bg-quaternary",
  },
  {
    label: "Get Support",
    url: "https://www.metabolon.com/support/portal/",
    color: "bg-primary",
    icon: true,
    iconName: "arrow-right",
  },
];

const Head = () => {
  return (
    <div id={styles["top-wrapper"]}>
      <div className={styles["top-left"]}>
        <div className={styles["copy-top"] + " copy"}>
          <h2>Metabolon is here for you</h2>
          <p>
            Whether you're just getting started, need assistance, or want to
            learn more about our suite of insightful products, Metabolon is
            ready to help.
          </p>
        </div>
      </div>
      <div className={styles["top-right"]}>
        {buttons.map((d, i) => {
          return (
            <ExternalLink
              key={i}
              bgColor={d.color}
              color="white"
              href={d.url}
              className={"rounded-base-button"}
            >
              {d.label}
            </ExternalLink>
          );
        })}
      </div>
    </div>
  );
};

export default Head;
